import { t } from '@lingui/macro';
import { Formik, FormikHelpers } from 'formik';
import LoginForm from './LoginForm';
import SuperAdminBar from '../SuperAdmin/SuperAdminBar';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import Flex from 'src/components/Flex';
import { styled } from '@mui/material';

interface IFormValues {
  username: string;
  password: string;
  global?: string;
}

const LoginScreen = () => {
  const { login } = useContext(AuthContext);
  const handleSubmit = async (
    { username, password }: IFormValues,
    { setSubmitting, setErrors }: FormikHelpers<IFormValues>,
  ) => {
    try {
      setSubmitting(false);
      await login(username, password);
    } catch (e) {
      setErrors({
        global:
          (e as any).status === 401
            ? t`You have entered an incorrect name or password`
            : t`Something went wrong, please try again`,
        password: '',
        username: '',
      });
    }
  };

  const validate = (values: IFormValues) => {
    const errors: any = {};
    if (values.username === '') {
      errors.username = t`Enter the name`;
    }
    if (values.password === '') {
      errors.password = t`Enter the password`;
    }
    return errors;
  };

  return (
    <>
      <SuperAdminBar />
      <Container>
        <Content>
          <Formik<IFormValues>
            initialValues={{ username: '', password: '', global: undefined }}
            onSubmit={handleSubmit}
            component={LoginForm}
            validate={validate}
          />
        </Content>
      </Container>
    </>
  );
};

const Container = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled(Flex)(
  ({ theme }) => `
  width: 400px;
  height: 332px;
  flex-direction: row;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    width: 300px;
  };
`,
);

export default LoginScreen;
