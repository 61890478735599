import { useRef } from 'react';

const CSV_SEPARATOR = ';';

const useTableExport = (reportName: string = 'Report data') => {
  const tableRef = useRef<HTMLTableElement>(null);

  const handleExportClick = () => {
    const table = tableRef.current;
    if (!table) {
      return;
    }
    var csvData: string[] = [];
    const rows = table.querySelectorAll('tr');
    for (var i = 0; i < rows.length; i++) {
      const row: string[] = [];
      const cols = rows[i].querySelectorAll('td, th');

      for (var j = 0; j < cols.length; j++) {
        const cell: HTMLTableCellElement = cols[j] as HTMLTableCellElement;
        row.push(`"${cell.innerText.replaceAll(`"`, `""`)}"`);
      }

      csvData.push(row.join(CSV_SEPARATOR));
    }
    const csvContent = '\uFEFF' + csvData.join('\n');
    const csvFile = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.download = `${reportName}.csv`;
    link.href = window.URL.createObjectURL(csvFile);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
  };
  return {
    tableRef,
    handleExportClick,
  };
};

export default useTableExport;
