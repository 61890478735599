import { t } from '@lingui/macro';
import { SessionStatistics } from '../../../graphql/types';
import { linetColors } from '../../../colors';

export enum StatisticsType {
  'safetyPosition' = 'safetyPosition',
  'allSiderails' = 'allSiderails',
  'backrest30' = 'backrest30',
  'brakes' = 'brakes',
  'bedExitMonitorStatus' = 'bedExitMonitorStatus',
  'lowestPosition' = 'lowestPosition',
  'outOfBed' = 'outOfBed',
  'connectionStatus' = 'connectionStatus',
}

export const COLORS = [
  linetColors.greyMain,
  linetColors.greenLight,
  linetColors.orangeLight,
];

export interface StatisticsDataType {
  values: StatisticsDataValuesType[];
  type: StatisticsType;
}

export interface StatisticsDataValuesType {
  value: number | null;
  time: string;
}

export const getStatisticsValuesText = (
  type: StatisticsType,
  variant?: 'second' | 'third',
) => {
  switch (type) {
    case StatisticsType.safetyPosition:
      if (variant === 'second') return t`IN`;
      return t`OUT`;
    case StatisticsType.allSiderails:
      if (variant === 'second') return t`ALL UP`;
      return t` ANY DOWN`;
    case StatisticsType.backrest30:
      if (variant === 'second') return t`YES`;
      return t`NO`;
    case StatisticsType.brakes:
      if (variant === 'second') return t`ON`;
      return t`OFF`;
    case StatisticsType.bedExitMonitorStatus:
      if (variant === 'second') return t`ON - INNER`;
      if (variant === 'third') return t`ON - OUTER`;
      return t`OFF`;
    case StatisticsType.lowestPosition:
      if (variant === 'second') return t`ON`;
      return t`OFF`;
    case StatisticsType.outOfBed:
      if (variant === 'second') return t`OUT OF BED`;
      return t`IN BED`;
    case StatisticsType.connectionStatus:
      if (variant === 'second') return t`CONNECTED`;
      return t`DISCONNECTED`;
    default:
      return null;
  }
};

const getStatisticsTime = (seconds: number) => {
  const minutes = seconds / 60;
  const hours = Math.trunc(minutes / 60);
  const resultMins = Math.round(minutes % 60);
  return t`${hours} hrs ${resultMins} min`;
};

export const roundToOneDecimal = (value: number) => {
  return Math.round(value * 10) / 10;
};

const getStatisticsValueData = (
  firstValue?: number | null,
  secondValue?: number | null,
  thirdValue?: number | null,
) => {
  const firstFormatedValue = firstValue || 0;
  const minutes =
    firstFormatedValue +
    (secondValue || 0) +
    (thirdValue != null ? thirdValue : 0);
  const resultValue = (firstFormatedValue / minutes) * 100 || 0;
  const optionalResultValue = ((thirdValue || 0) / minutes) * 100 || 0;
  const result = [
    {
      value: roundToOneDecimal(
        100 - (!!thirdValue ? resultValue + optionalResultValue : resultValue),
      ),
      time: getStatisticsTime(
        minutes *
          ((100 -
            (!!thirdValue ? resultValue + optionalResultValue : resultValue)) /
            100),
      ),
    },
    {
      value: roundToOneDecimal(resultValue),
      time: getStatisticsTime((minutes * resultValue) / 100),
    },
  ];
  if (thirdValue != null) {
    const thirdCountedMinutes = minutes * (optionalResultValue / 100);
    result.push({
      value: roundToOneDecimal(optionalResultValue),
      time: getStatisticsTime(thirdCountedMinutes),
    });
  }

  return result;
};

const getStatisticsDataValues = (
  type: StatisticsType,
  data: SessionStatistics,
) => {
  switch (type) {
    case StatisticsType.safetyPosition:
      return getStatisticsValueData(
        data.statistics.safetyPosition?.onTime,
        data.statistics.safetyPosition?.offTime,
      );
    case StatisticsType.outOfBed:
      return getStatisticsValueData(
        data.statistics.outOfBed?.offTime,
        data.statistics.outOfBed?.onTime,
      );
    case StatisticsType.allSiderails:
      return getStatisticsValueData(
        data.statistics.allSiderailsCombined?.onTime,
        data.statistics.allSiderailsCombined?.offTime,
      );
    case StatisticsType.backrest30:
      return getStatisticsValueData(
        data.statistics.backrest30?.onTime,
        data.statistics.backrest30?.offTime,
      );
    case StatisticsType.brakes:
      return getStatisticsValueData(
        data.statistics.brakes?.onTime,
        data.statistics.brakes?.offTime,
      );
    case StatisticsType.bedExitMonitorStatus:
      return getStatisticsValueData(
        data.statistics.bedExitStatus?.izTime,
        data.statistics.bedExitStatus?.offTime,
        data.statistics.bedExitStatus?.ozTime,
      );
    case StatisticsType.lowestPosition:
      return getStatisticsValueData(
        data.statistics.lowestPosition?.onTime,
        data.statistics.lowestPosition?.offTime,
      );
    case StatisticsType.connectionStatus:
      return getStatisticsValueData(
        data.statistics.power?.onTime,
        data.statistics.power?.offTime,
      );
    default:
      return [];
  }
};

export const getStatisticsData = (data?: SessionStatistics) => {
  if (data) {
    const newData: StatisticsDataType[] = [
      {
        type: StatisticsType.safetyPosition,
        values: [],
      },
      {
        type: StatisticsType.allSiderails,
        values: [],
      },
      {
        type: StatisticsType.backrest30,
        values: [],
      },
      {
        type: StatisticsType.brakes,
        values: [],
      },
      {
        type: StatisticsType.bedExitMonitorStatus,
        values: [],
      },
      {
        type: StatisticsType.lowestPosition,
        values: [],
      },
      {
        type: StatisticsType.outOfBed,
        values: [],
      },
      {
        type: StatisticsType.connectionStatus,
        values: [],
      },
    ];
    newData.forEach((item) => {
      item.values = getStatisticsDataValues(item.type, data);
    });
    return newData;
  }
  return null;
};

export const getStatisticsTranslations = (type: StatisticsType) => {
  switch (type) {
    case StatisticsType.safetyPosition:
      return t`LINIS0002`;
    case StatisticsType.allSiderails:
      return t`LINIS0005`;
    case StatisticsType.backrest30:
      return t`LINIS0006`;
    case StatisticsType.brakes:
      return t`LINIS0008`;
    case StatisticsType.bedExitMonitorStatus:
      return t`LINIS0009`;
    case StatisticsType.lowestPosition:
      return t`LINIS0011`;
    case StatisticsType.outOfBed:
      return t`LINIS0012`;
    case StatisticsType.connectionStatus:
      return t`LINIS0019`;
    default:
      return null;
  }
};
