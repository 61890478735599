import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import ControlsContainer from 'src/pages/Admin/GlobalSettings/ControlsContainer';
import {
  ConnectionSettingsState,
  ControlStateType,
  Groups,
  SpEnabledGroupsType,
} from 'src/pages/Admin/GlobalSettings/globalSettingsHelper';
import ConnectionDetailTabs from './ConnectionDetailTabs';
import { useLingui } from '@lingui/react';
import ConnectionDetailTabConnectionDetailContent from './ConnectionDetailTabConnectionDetailContent';
import { ConnectionDetailFields } from 'src/pages/Admin/GlobalSettings/DataTransferSettings';
import Flex from 'src/components/Flex';
import { useDirty } from 'src/hooks';
import SaveDiscardButtons from 'src/components/SaveDiscardButtons';

export const tabsContent = (i18n: I18n) => [
  {
    type: Groups.connectionDetail,
    name: i18n._(t`Connection detail`),
  },
  {
    type: Groups.patientSafety,
    name: i18n._(t`Patient Safety`),
  },
  {
    type: Groups.respiratoryAndMobilization,
    name: i18n._(t`Respiratory & Mobilization`),
  },
  {
    type: Groups.bedManagement,
    name: i18n._(t`Bed Management`),
  },
  {
    type: Groups.utilization,
    name: i18n._(t`Utilization`),
  },
];

interface Props {
  controlState: ControlStateType[];
  setControlState: React.Dispatch<React.SetStateAction<ControlStateType[]>>;
  spEnabledGroups: SpEnabledGroupsType;
  onSave: () => null | Promise<any>;
  connectionSettings: ConnectionSettingsState | null;
  onChangeConnection: (type: ConnectionDetailFields, value: any) => void;
  setConnectionSettings: React.Dispatch<
    React.SetStateAction<ConnectionSettingsState | null>
  >;
}

const ConnectionDetail = ({
  controlState,
  setControlState,
  onChangeConnection,
  spEnabledGroups,
  onSave,
  connectionSettings,
  setConnectionSettings,
}: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { i18n } = useLingui();

  const activeContent = tabsContent(i18n)[activeTab];
  const isActiveConnectionDetail =
    activeContent.type === Groups.connectionDetail;

  const handleDiscard = () => {
    if (isActiveConnectionDetail) {
      setConnectionSettings({
        ...initValue,
        enabled: connectionSettings?.enabled,
      } as ConnectionSettingsState);
      return;
    }
    setControlState(initValue as ControlStateType[]);
  };

  const { isDirty, initValue, resetDirty } = useDirty(
    isActiveConnectionDetail
      ? { ...connectionSettings, enabled: undefined }
      : controlState,
    handleDiscard,
  );

  useEffect(() => {
    resetDirty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, connectionSettings?.id]);

  const handleSave = async () => {
    try {
      await onSave();
      resetDirty();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <ConnectionDetailTabs
          activeTab={activeTab}
          onClick={(index: number) => setActiveTab(index)}
          tabsContent={tabsContent}
          spEnabledGroups={spEnabledGroups}
        />
        <SaveDiscardButtons
          onSave={handleSave}
          onCancel={handleDiscard}
          disabled={!isDirty}
        />
      </Flex>
      <Divider sx={{ my: 2 }} />
      <Box>
        {isActiveConnectionDetail ? (
          <ConnectionDetailTabConnectionDetailContent
            connectionSettings={connectionSettings}
            onChange={onChangeConnection}
          />
        ) : (
          <ControlsContainer
            type={activeContent.type}
            setControlState={setControlState}
            data={controlState}
          />
        )}
      </Box>
    </>
  );
};

export default ConnectionDetail;
