import { useMutation } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {
  ListPageContent,
  ListPageRoot,
  TopBar,
} from 'src/components/AdminList';
import { ConfirmModal } from 'src/components/ConfirmModal';
import Flex from 'src/components/Flex';
import { deleteRoom as deleteRoomMutation } from 'src/graphql/mutations';
import {
  deleteRoomMutation as DeleteRoom,
  deleteRoomMutationVariables as DeleteRoomVariables,
} from 'src/graphql/types';
import ParkingPlaceCard from './ParkingPlaceCard';
import { useParkingPlaces } from './useParkingPlaces';

const RoomDetail = () => {
  const params = useParams<{ roomId: string }>();
  const navigate = useNavigate();
  const [confirmState, setConfirmState] = useState<{
    room: { id: string; name: string } | null;
    parkingPlace: { id: string; name: string } | null;
  }>({ room: null, parkingPlace: null });
  const {
    roomId,
    roomName,
    parkingPlaces,
    handleParkingPlaceCreate,
    handleParkingPlaceRemove: removeParkingPlace,
  } = useParkingPlaces(params.roomId);

  const [removeRoom] = useMutation<DeleteRoom, DeleteRoomVariables>(
    deleteRoomMutation,
  );

  const handleConfirmRoomRemove = async () => {
    if (!confirmState.room?.id) {
      return;
    }
    const result = await removeRoom({
      variables: {
        roomId: confirmState.room.id,
      },
    });
    if (result.data?.deleteRoom) {
      navigate('/admin/rooms');
    }
  };

  const handleConfirmParkingPlaceRemove = () => {
    if (confirmState.parkingPlace?.id) {
      removeParkingPlace(confirmState.parkingPlace.id);
    }
    setConfirmState((s) => ({ ...s, parkingPlace: null }));
  };

  const handleRoomRemove = () => {
    if (!roomId) {
      return;
    }
    setConfirmState((s) => ({
      ...s,
      room: {
        id: roomId,
        name: roomName,
      },
    }));
  };

  const handleParkingPlaceRemove = (
    parkingPlaceId: string,
    parkingPlaceName: string,
  ) => {
    if (!parkingPlaceId) {
      return;
    }
    setConfirmState((s) => ({
      ...s,
      parkingPlace: {
        id: parkingPlaceId,
        name: parkingPlaceName,
      },
    }));
  };

  return (
    <ListPageRoot>
      <TopBar>
        <Button
          color="primary"
          onClick={handleRoomRemove}
          startIcon={<DeleteIcon />}
          variant="outlined"
        >
          <Trans>Delete</Trans>
        </Button>
      </TopBar>
      <ListPageContent sx={{ paddingTop: '20px' }}>
        <Grid container spacing={2}>
          {parkingPlaces?.map((pp) => (
            <Grid item key={pp.id}>
              <ParkingPlaceCard
                id={pp.id}
                name={pp.name || ''}
                bedUnitId={pp.bed?.unitId || ''}
                bedName={pp.bed?.name || ''}
                bedState={pp.bed?.state}
                tagId={pp.tagId}
                ipAddressEthernet={pp.ipAddressEthernet}
                mode={pp.mode}
                removeParkingPlace={handleParkingPlaceRemove}
              />
            </Grid>
          ))}
          <Grid
            item
            sx={{ display: 'flex', alignItems: 'center', minHeight: 400 }}
          >
            <Flex sx={{ justifyContent: 'center' }}>
              <IconButton size="large" onClick={handleParkingPlaceCreate}>
                <AddCircleIcon fontSize="large" color="primary" />
              </IconButton>
            </Flex>
          </Grid>
        </Grid>
      </ListPageContent>

      <ConfirmModal
        open={!!confirmState.room}
        onClose={() => setConfirmState((s) => ({ ...s, room: null }))}
        title={t`Delete room`}
        message={
          <Trans>
            Do you really want to <b>delete Room {confirmState.room?.name}?</b>
          </Trans>
        }
        onConfirm={handleConfirmRoomRemove}
      />
      <ConfirmModal
        open={!!confirmState.parkingPlace}
        onClose={() => setConfirmState((s) => ({ ...s, parkingPlace: null }))}
        title={t`Delete parking place`}
        message={
          <Trans>
            Do you really want to{' '}
            <b>
              delete Parking Place {confirmState.parkingPlace?.name} in room{' '}
              {roomName}?
            </b>
          </Trans>
        }
        onConfirm={handleConfirmParkingPlaceRemove}
      />
    </ListPageRoot>
  );
};

export default RoomDetail;
