import { t } from '@lingui/macro';
import { Box, MenuItem, TextField, SxProps } from '@mui/material';
import { useWardData } from '../providers/WardDataProvider';

interface Props {
  sx?: SxProps;
  fieldSx?: SxProps;
}

const WardSelector = ({ sx, fieldSx }: Props) => {
  const {
    wardId,
    setWardId,
    workspaceId,
    setWorkspaceId,
    wardOptions,
    workspaceOptions,
  } = useWardData();

  const fieldSxWithDefault = fieldSx || {
    width: 200,
    ml: 2,
  };

  return (
    <Box display="flex" sx={sx}>
      {workspaceOptions.length ? (
        <Box key={`workspace-selector-${workspaceId}`} sx={fieldSx}>
          <TextField
            select
            value={workspaceId || ''}
            fullWidth
            variant="outlined"
            placeholder={t`Workspace`}
            id="workspace-selector"
            onChange={(event) => setWorkspaceId(event.target.value)}
            SelectProps={{
              multiple: false,
            }}
          >
            {workspaceOptions.map((workspaceItem) => (
              <MenuItem
                key={`workspace-item-${workspaceItem.id}`}
                value={workspaceItem.id}
              >
                {workspaceItem.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      ) : null}
      {wardOptions.length ? (
        <Box key={`wardId-selector-${wardId}`} sx={fieldSxWithDefault}>
          <TextField
            select
            placeholder={t`Ward`}
            id="wardId-selector"
            variant="outlined"
            fullWidth
            onChange={(event) => setWardId(event.target.value)}
            value={wardId || ''}
            SelectProps={{
              multiple: false,
            }}
          >
            {wardOptions.map((wardItem) => (
              <MenuItem key={`ward-item-${wardItem.id}`} value={wardItem.id}>
                {wardItem.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      ) : null}
    </Box>
  );
};

export default WardSelector;
