import { useQuery } from '@apollo/client';
import { upsertUser } from 'src/graphql/mutations';
import { meQuery } from 'src/graphql/queries';
import {
  me,
  upsertUserMutation,
  upsertUserMutationVariables,
  UserRole,
} from 'src/graphql/types';
import UserDetail from './UserDetail';
import { object, string } from 'yup';
import { t } from '@lingui/macro';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';

interface UserFormData {
  id: string | undefined;
  role: UserRole;
  firstName: string;
  password: string;
  lastName: string;
  email: string;
  username: string;
  wardIds: AutocompleteSelectValue[];
  reportingModuleAccess: boolean;
  workspaceId?: string;
  locale: string;
  serverIp: string;
  ssid?: string | null;
  ssidKey?: string | null;
  ssidKeyEncrypt: boolean;
  vbAccess: boolean;
  demoAccess: boolean;
}

interface AutocompleteSelectValue {
  label: string;
  value: string;
}

const userSchema = object({
  firstName: string().required(t`Required!`),
  lastName: string().required(t`Required!`),
  username: string().required(t`Required!`),
});

const MyProfile = () => {
  const { data: userData } = useQuery<me>(meQuery);
  const [updateUser] = useSnackbarMutation<
    upsertUserMutation,
    upsertUserMutationVariables
  >(upsertUser, undefined, {
    successMessage: t`Saved.`,
  });

  const handleSubmit = async (data: UserFormData) => {
    delete data.workspaceId;
    await updateUser({
      variables: {
        user: {
          ...data,
          wardIds: data.wardIds.map((ward) => ward.value),
          serverIp: data.serverIp === '' ? null : data.serverIp,
          ssid: data.ssid === '' ? null : data.ssid,
        },
      },
      refetchQueries: () => [{ query: meQuery }],
    });

    return;
  };

  const initialValues = {
    id: userData?.me?.id,
    role: userData?.me?.role || UserRole.REGULAR,
    firstName: userData?.me?.firstName || '',
    password: '',
    wardIds:
      userData?.me?.assignedWards?.map((ward) => ({
        label: ward.name,
        value: ward.id,
      })) || [],
    lastName: userData?.me?.lastName || '',
    email: userData?.me?.email || '',
    username: userData?.me?.username || '',
    locale: userData?.me?.locale || '',
    serverIp: userData?.me?.serverIp || '',
    ssid: userData?.me?.ssid,
    ssidKey: userData?.me?.ssidKey,
    ssidKeyEncrypt: Boolean(userData?.me?.ssidKeyEncrypt),
    vbAccess: Boolean(userData?.me?.vbAccess),
    demoAccess: Boolean(userData?.me?.demoAccess),
    reportingModuleAccess: Boolean(userData?.me?.reportingModuleAccess),
  };

  return (
    <UserDetail<UserFormData>
      initialValues={initialValues}
      features={{
        passwordModal: true,
        accessSwitchButtons: false,
      }}
      onSubmit={handleSubmit}
      backButton={t`Back`}
      userWards={userData?.me?.assignedWards}
      validationSchema={userSchema}
    />
  );
};

export default MyProfile;
