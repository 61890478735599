import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { statistics } from '../../../graphql/queries';
import {
  SessionStatistics,
  SessionStatisticsVariables,
} from '../../../graphql/types';
import { notEmpty } from 'src/lib/notEmpty';
import {
  getStatisticsData,
  getStatisticsTranslations,
  getStatisticsValuesText,
  StatisticsDataType,
  StatisticsType,
} from '../helpers/statistics';
import { svgToPng } from '../helpers/svgToPng';

interface Props {
  from?: Date;
  to?: Date;
  sessionId?: string;
}

export const useStatisticsData = ({ from, to, sessionId }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const { data } = useQuery<SessionStatistics, SessionStatisticsVariables>(
    statistics,
    {
      skip: !from || !to || !sessionId,
      variables: {
        id: sessionId || '',
        from,
        to,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
    },
  );
  const [statisticsData, setStatisticsData] = useState<StatisticsDataType[]>(
    [],
  );
  useEffect(() => {
    const newData = getStatisticsData(data);
    if (!newData) {
      setLoaded(false);
      return;
    }
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
    setStatisticsData(newData);
  }, [data]);

  const getStatisticsGraphData = useCallback(
    async (imageRefs: React.RefObject<Record<StatisticsType, any>>) => {
      const result = await Promise.all(
        statisticsData.map(async (item) => {
          const imgSource = await svgToPng(
            imageRefs.current && imageRefs.current[item.type].container,
          );
          if (!imgSource) {
            return null;
          }
          const itemResult = {
            img: imgSource,
            title: getStatisticsTranslations(item.type),
            values: [
              {
                text: getStatisticsValuesText(item.type),
                value: item.values[0].value,
                time: item.values?.[0]?.time,
              },
              {
                text: getStatisticsValuesText(item.type, 'second'),
                value: item.values[1]?.value,
                time: item.values?.[1]?.time,
              },
            ],
          };

          if (
            item.values?.[2]?.value != null &&
            item.values?.[2]?.time != null
          ) {
            itemResult.values.push({
              text: getStatisticsValuesText(item.type, 'third'),
              value: item.values?.[2]?.value,
              time: item.values?.[2]?.time,
            });
          }
          return itemResult;
        }),
      );
      return result.filter(notEmpty);
    },
    [statisticsData],
  );

  return {
    statisticsData,
    getStatisticsGraphData,
    statisticsLoaded: loaded,
  };
};
