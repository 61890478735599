import { I18n } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { Tabs } from '@mui/material';
import { useState } from 'react';
import DisabledDialog from 'src/pages/Admin/GlobalSettings/DisabledDialog';
import {
  Groups,
  SpEnabledGroupsType,
} from 'src/pages/Admin/GlobalSettings/globalSettingsHelper';
import { useDirtyContext } from 'src/providers/DirtyProvider';
import { ThinTab } from '../../../components/ThinTab';
import { tabsContent } from './ConnectionDetail';

interface Props {
  activeTab: number;
  onClick: (index: number) => void;
  tabsContent?: (i18n: I18n) => {
    type: Groups;
    name: string;
  }[];
  spEnabledGroups: SpEnabledGroupsType;
}

const ConnectionDetailTabs = ({
  activeTab,
  onClick,
  spEnabledGroups,
}: Props) => {
  const { i18n } = useLingui();
  const { actionGuard } = useDirtyContext();
  const [openDisabledDialog, setOpenDisabledDialog] = useState(false);

  const handleClick = (index: number) => {
    actionGuard(() => onClick(index));
  };

  return (
    <>
      <Tabs onChange={(e, index) => handleClick(index)} value={activeTab}>
        {tabsContent(i18n).map((tabContent) => (
          <ThinTab
            key={tabContent.type}
            disabled={spEnabledGroups[tabContent.type]}
            label={tabContent.name}
          />
        ))}
      </Tabs>
      <DisabledDialog
        isOpen={openDisabledDialog}
        setOpen={setOpenDisabledDialog}
      />
    </>
  );
};

export default ConnectionDetailTabs;
