import { t } from '@lingui/macro';
import { useFormik } from 'formik';
import { configQuery } from '../../graphql/queries';
import {
  ConfigQuery,
  upsertSuperConfigMutationVariables,
  upsertSuperConfigMutation,
} from '../../graphql/types';
import ConfigForm from './ConfigForm';
import { useQuery } from '@apollo/client';
import { upsertSuperConfig } from '../../graphql/mutations';
import {
  getSuperAdminInitState,
  SafetyPortFormValues,
} from './superAdminHelper';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import ContentBlock from 'src/components/ContentBlock';
import FormikForm from 'src/components/Formik/FormikForm';

const ConfigDialog = () => {
  const { data: configData, loading } = useQuery<ConfigQuery>(configQuery);
  const [updateConfig] = useSnackbarMutation<
    upsertSuperConfigMutation,
    upsertSuperConfigMutationVariables
  >(upsertSuperConfig, undefined, {
    successMessage: t`Configuration saved.`,
  });

  const superConfig = configData && configData.superConfig;

  const handleSubmit = async ({
    spDashboardLicenceExpiration,
    spEnabled,
    spVersion,
    spDashboardEnabled,
    spDashboardVersion,
    patientEnabled,
    patientLicenceExpiration,
    bedManagementEnabled,
    bedManagementLicenceExpiration,
    respiratoryMobilizationEnabled,
    respiratoryMobilizationLicenceExpiration,
    utilizationEnabled,
    utilizationLicenceExpiration,
    syncCommunication,
    hl7LogEnabled,
    reportingModuleAccess,
  }: SafetyPortFormValues) => {
    await updateConfig({
      variables: {
        config: {
          spDashboardLicenceExpiration,
          spEnabled,
          spVersion,
          spDashboardEnabled,
          spDashboardVersion,
          patientEnabled,
          patientLicenceExpiration,
          bedManagementEnabled,
          bedManagementLicenceExpiration,
          respiratoryMobilizationEnabled,
          respiratoryMobilizationLicenceExpiration,
          utilizationEnabled,
          utilizationLicenceExpiration,
          syncCommunication,
          hl7LogEnabled,
          reportingModuleAccess,
        },
      },
      refetchQueries: () => [{ query: configQuery }],
    });
  };

  const formik = useFormik<SafetyPortFormValues>({
    initialValues: getSuperAdminInitState(superConfig),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return null;
  }

  if (!superConfig) {
    return null;
  }
  return (
    <ContentBlock
      isSaveDisabled={!formik.dirty || formik.isSubmitting}
      onCancel={formik.resetForm}
      onSave={formik.submitForm}
      title=" "
    >
      <FormikForm formik={formik}>
        <ConfigForm {...formik} />
      </FormikForm>
    </ContentBlock>
  );
};

export default ConfigDialog;
