import { useState, useCallback } from 'react';
import sortIcon from 'src/images/SortIcon.svg';
import sortIconRedDown from 'src/images/SortIconRedDown.svg';
import sortIconRedUp from 'src/images/SortIconRedUp.svg';

const DEFAULT_ORDER = 'asc';

export enum SortKey {
  ID,
  NAME,
  SURNAME,
  EMAIL,
  LOGIN,
  LASTONLINE,
  WORKSPACE_AND_WARD,
  WORKSPACE,
  ASSIGNED_WARD,
  ASSIGNED_WORKSPACE,
  BEDNAME,
  UNITID,
  BEDUNITID,
  WARD,
  ROOM,
  MODE,
  PARKINGPLACES,
  BED_STATUS,
  STATUS,
  START,
  REASON,
  END,
  TIMESTAMP,
  HL7TIMESTAMP,
  PROP,
  HL7PROP,
  ACK,
  MSGID,
  DURATION,
  CHARGE,
  BED_COUNT,
}

type Comparator = (obj: any, otherObj: any) => number;
type SortingOrder = 'asc' | 'desc';

interface Sorting {
  sortFn: Comparator;
  order: SortingOrder;
  key: SortKey;
}

export const STRING_COMPARATOR: Comparator = (
  value: string,
  otherValue: string,
) => {
  return (value || '').localeCompare(otherValue || '');
};

export const NUMBER_COMPARATOR: Comparator = (value: any, otherValue: any) => {
  return Number(value) - Number(otherValue);
};

export const DATE_COMPARATOR: Comparator = (value: any, otherValue: any) => {
  return Number(new Date(value)) - Number(new Date(otherValue));
};

const keyValues: Record<SortKey, (obj: any) => string | number> = {
  [SortKey.ID]: (obj: any) => obj.id,
  [SortKey.NAME]: (obj: any) => obj.firstName,
  [SortKey.SURNAME]: (obj: any) => obj.lastName,
  [SortKey.EMAIL]: (obj: any) => obj.email,
  [SortKey.LOGIN]: (obj: any) => obj.username,
  [SortKey.CHARGE]: (obj: any) => obj?.charge || 0,
  [SortKey.LASTONLINE]: (obj: any) => obj.lastAccess,
  [SortKey.WORKSPACE_AND_WARD]: (obj: any) => (obj.ward && obj.ward.id) || 0,
  [SortKey.ASSIGNED_WARD]: (obj: any) => obj.assignedWards?.[0]?.name || 0,
  [SortKey.ASSIGNED_WORKSPACE]: (obj: any) =>
    obj.assignedWards?.[0]?.workspace?.name || 0,
  [SortKey.MODE]: (obj: any) => obj.parkingPlaces[0]?.mode || '0',
  [SortKey.WORKSPACE]: (obj: any) =>
    (obj.workspace && obj.workspace.name) || '',
  [SortKey.BEDNAME]: (obj: any) =>
    (obj.node && obj.node.bed && obj.node.bed.name) || obj.name || '',
  [SortKey.UNITID]: (obj: any) => obj.unitId,
  [SortKey.BEDUNITID]: (obj: any) =>
    (obj.node && obj.node.bedUnitId) || obj.bedUnitId,
  [SortKey.WARD]: (obj: any) => (obj.ward && obj.ward.name) || '',
  [SortKey.ROOM]: (obj: any) =>
    (obj.node && obj.node.bed && obj.node.bed.room && obj.node.bed.room.name) ||
    (obj.room && obj.room.name) ||
    obj.name ||
    '',
  [SortKey.BED_STATUS]: (obj: any) => (obj?.state === 'ONLINE' ? 1 : 0),
  [SortKey.STATUS]: (obj: any) =>
    (obj.parkingPlaces || []).filter(
      (pp: any) => pp.bed && pp.bed.state === 'ONLINE',
    ).length,
  [SortKey.START]: (obj: any) => obj.node && obj.node.start,
  [SortKey.PARKINGPLACES]: (obj: any) => obj.parkingPlaces.length,
  [SortKey.REASON]: (obj: any) => (obj.node && obj.node.type) || obj.reason,
  [SortKey.END]: (obj: any) => obj.node && obj.node.end,
  [SortKey.TIMESTAMP]: (obj: any) => obj && obj.time,
  [SortKey.PROP]: (obj: any) => obj && obj.type,
  [SortKey.HL7TIMESTAMP]: (obj: any) => obj.node && obj.node.timestamp,
  [SortKey.HL7PROP]: (obj: any) => obj.node && obj.node.prop,

  [SortKey.ACK]: (obj: any) => obj.node && obj.node.ack,
  [SortKey.MSGID]: (obj: any) => obj.node && obj.node.msgId,
  [SortKey.DURATION]: (obj: any) => obj.node && obj.node.duration,
  [SortKey.BED_COUNT]: (obj: any) => 0,
};

const keyFunctions: Record<SortKey, Comparator> = {
  [SortKey.ID]: NUMBER_COMPARATOR,
  [SortKey.NAME]: STRING_COMPARATOR,
  [SortKey.SURNAME]: STRING_COMPARATOR,
  [SortKey.EMAIL]: STRING_COMPARATOR,
  [SortKey.LOGIN]: STRING_COMPARATOR,
  [SortKey.LASTONLINE]: DATE_COMPARATOR,
  [SortKey.WORKSPACE_AND_WARD]: NUMBER_COMPARATOR,
  [SortKey.WORKSPACE]: STRING_COMPARATOR,
  [SortKey.ASSIGNED_WARD]: STRING_COMPARATOR,
  [SortKey.ASSIGNED_WORKSPACE]: STRING_COMPARATOR,
  [SortKey.BEDNAME]: STRING_COMPARATOR,
  [SortKey.UNITID]: STRING_COMPARATOR,
  [SortKey.MODE]: STRING_COMPARATOR,
  [SortKey.BEDUNITID]: STRING_COMPARATOR,
  [SortKey.WARD]: STRING_COMPARATOR,
  [SortKey.ROOM]: STRING_COMPARATOR,
  [SortKey.PARKINGPLACES]: NUMBER_COMPARATOR,
  [SortKey.STATUS]: NUMBER_COMPARATOR,
  [SortKey.BED_STATUS]: NUMBER_COMPARATOR,
  [SortKey.START]: DATE_COMPARATOR,
  [SortKey.REASON]: STRING_COMPARATOR,
  [SortKey.END]: DATE_COMPARATOR,
  [SortKey.TIMESTAMP]: DATE_COMPARATOR,
  [SortKey.PROP]: STRING_COMPARATOR,
  [SortKey.HL7TIMESTAMP]: DATE_COMPARATOR,
  [SortKey.HL7PROP]: STRING_COMPARATOR,
  [SortKey.ACK]: NUMBER_COMPARATOR,
  [SortKey.MSGID]: STRING_COMPARATOR,
  [SortKey.DURATION]: NUMBER_COMPARATOR,
  [SortKey.CHARGE]: NUMBER_COMPARATOR,
  [SortKey.BED_COUNT]: NUMBER_COMPARATOR,
};

export function useCustomSorting<T>(
  evaluators: Partial<Record<SortKey, (obj: T) => string | number>>,
  comparators: Partial<Record<SortKey, Comparator>>,
) {
  const [sorting, setSorting] = useState<Sorting | null>(null);

  const setSort = useCallback(
    (key: SortKey | null) => {
      if (key == null) {
        setSorting(null);
        return;
      }
      let newOrder: SortingOrder = DEFAULT_ORDER;
      if (sorting && sorting.key === key) {
        newOrder = sorting.order === 'asc' ? 'desc' : 'asc';
      }
      const orderConstant = newOrder === 'asc' ? -1 : 1;
      const getValue = evaluators[key];
      const sortFn = comparators[key];
      if (getValue && sortFn) {
        setSorting({
          key,
          sortFn: (obj: any, otherObj: any) => {
            return orderConstant * sortFn(getValue(obj), getValue(otherObj));
          },
          order: newOrder,
        });
      }
    },
    [sorting, setSorting, evaluators, comparators],
  );

  return {
    sorting,
    setSort,
  };
}

export function useSorting() {
  const [sorting, setSorting] = useState<Sorting | null>(null);

  const setSort = useCallback(
    (key: SortKey | null) => {
      if (key == null) {
        setSorting(null);
        return;
      }
      let newOrder: SortingOrder = DEFAULT_ORDER;
      if (sorting && sorting.key === key) {
        newOrder = sorting.order === 'asc' ? 'desc' : 'asc';
      }
      const orderConstant = newOrder === 'asc' ? -1 : 1;
      const getValue = keyValues[key];
      const sortFn = keyFunctions[key];
      setSorting({
        key,
        sortFn: (obj: any, otherObj: any) => {
          return orderConstant * sortFn(getValue(obj), getValue(otherObj));
        },
        order: newOrder,
      });
    },
    [sorting, setSorting],
  );

  return {
    sorting,
    setSort,
  };
}

export const getSortState = (
  sorting: Sorting | null,
  key: SortKey,
): 'unsorted' | 'desc' | 'asc' => {
  if (!sorting || sorting.key !== key) {
    return 'unsorted';
  }
  return sorting.order;
};

export const getSortIcon = (sorting: Sorting | null, key: SortKey) => {
  if (!sorting || sorting.key !== key) {
    return sortIcon;
  }
  if (sorting.order === 'asc') {
    return sortIconRedDown;
  }
  return sortIconRedUp;
};
