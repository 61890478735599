import { t } from '@lingui/macro';
import { Box } from '@mui/system';
import * as React from 'react';
import SuperAdminBar from '../SuperAdmin/SuperAdminBar';

const LicenceExpiration = () => {
  return (
    <>
      <SuperAdminBar />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '400px',
            height: '332px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {t`Your licence has not been renewed. Please contact your provider.`}
        </Box>
      </Box>
    </>
  );
};

export default LicenceExpiration;
