import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { Autocomplete, TextField } from '@mui/material';
import { indexBy } from 'ramda';
import React, { useMemo } from 'react';
import Flex from 'src/components/Flex';
import {
  beds as bedsQuery,
  wardsQuery,
  workspacesQuery,
} from 'src/graphql/queries';
import {
  bedsQuery as BedsQuery,
  LocationFilter,
  ReportResultField,
  WardsQuery,
  WorkspacesQuery,
} from 'src/graphql/types';

interface Props {
  resultField: ReportResultField;
  value?: LocationFilter | null;
  onChange: (value: LocationFilter) => void;
}

interface Option {
  label: string;
  value: string;
}

const getLocationOptions = (resultField: ReportResultField) => {
  if (
    resultField === ReportResultField.BACKREST ||
    resultField === ReportResultField.WEIGHT
  ) {
    return {
      unitIds: true,
      wardIds: false,
      workspaceIds: false,
    };
  }
  return {
    unitIds: true,
    wardIds: true,
    workspaceIds: true,
  };
};

const ReportLocationFilter = ({
  value: valTmp,
  resultField,
  onChange,
}: Props) => {
  const value: LocationFilter = valTmp || {};
  const { data: bedData } = useQuery<BedsQuery>(bedsQuery, {
    fetchPolicy: 'cache-first',
  });

  const { data: wardData } = useQuery<WardsQuery>(wardsQuery, {
    fetchPolicy: 'cache-first',
  });

  const { data: workspaceData } = useQuery<WorkspacesQuery>(workspacesQuery, {
    fetchPolicy: 'cache-first',
  });

  const locationFilterOptions = getLocationOptions(resultField);

  const workspaceOptions: Option[] = useMemo(
    () =>
      workspaceData?.workspaces
        .filter((w) => !!w.name)
        .map<Option>((workspace) => ({
          value: workspace.id,
          label: workspace.name!,
        })) || [],
    [workspaceData],
  );
  const workspaceMap = indexBy((o) => o.value, workspaceOptions);

  const workspacesWards = wardData?.wards?.filter((ward) =>
    value?.workspaceIds?.length
      ? value?.workspaceIds?.includes(ward.workspace.id!)
      : true,
  );

  const wardOptions: Option[] = useMemo(
    () =>
      workspacesWards
        ?.filter((w) => !!w.name)
        .map<Option>((ward) => ({ value: ward.id, label: ward.name! })) || [],
    [workspacesWards],
  );
  const wardMap = indexBy((o) => o.value, wardOptions);

  const wardsBeds = bedData?.beds?.filter((bed) => {
    if (value?.wardIds?.length) {
      return value?.wardIds?.includes(bed.ward?.id!);
    }
    if (value?.workspaceIds?.length) {
      return value?.workspaceIds?.includes(bed?.workspace?.id!);
    }
    return true;
  });

  const bedOptions: Option[] = useMemo(
    () =>
      wardsBeds
        ?.filter((b) => !!b.name)
        .map<Option>((bed) => ({ value: bed.unitId, label: bed.name! })) || [],
    [wardsBeds],
  );
  const bedMap = indexBy((o) => o.value, bedOptions);

  const handleFacilityChange = (_: React.SyntheticEvent, values: Option[]) => {
    const selectedValues = values.map((v) => v.value);
    onChange({
      ...value,
      workspaceIds: selectedValues,
      wardIds:
        selectedValues.length > (value.workspaceIds?.length || 0)
          ? value.wardIds
          : [],
      unitIds:
        selectedValues.length > (value.workspaceIds?.length || 0)
          ? value.unitIds
          : [],
    });
  };

  const handleWardChange = (_: React.SyntheticEvent, values: Option[]) => {
    const selectedValues = values.map((v) => v.value);
    onChange({
      ...value,
      wardIds: selectedValues,
      unitIds:
        selectedValues.length > (value.wardIds?.length || 0)
          ? value.unitIds
          : [],
    });
  };

  const handleUnitChange = (_: React.SyntheticEvent, values: Option[]) => {
    const selectedValues = values.map((v) => v.value);
    onChange({ ...value, unitIds: selectedValues });
  };

  return (
    <Flex>
      {locationFilterOptions.workspaceIds ? (
        <Autocomplete<Option, true>
          multiple
          value={value?.workspaceIds?.map((v) => workspaceMap[v]) || []}
          options={workspaceOptions}
          getOptionLabel={(option) => option?.label || ''}
          filterSelectedOptions
          onChange={handleFacilityChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                value?.workspaceIds?.length ? t`Facilities` : t`All Facilities`
              }
              variant="outlined"
              sx={{ mr: 2, minWidth: '25ch', width: 'auto' }}
            />
          )}
        />
      ) : null}

      {locationFilterOptions.wardIds ? (
        <Autocomplete<Option, true>
          multiple
          value={value?.wardIds?.map((v) => wardMap[v]) || []}
          options={wardOptions}
          getOptionLabel={(option) => option?.label || ''}
          filterSelectedOptions
          onChange={handleWardChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={value?.wardIds?.length ? t`Units` : t`All Units`}
              variant="outlined"
              sx={{ mr: 2, minWidth: '25ch', width: 'auto' }}
            />
          )}
        />
      ) : null}
      {locationFilterOptions.unitIds ? (
        <Autocomplete<Option, true>
          multiple
          value={value?.unitIds?.map((v) => bedMap[v]) || []}
          options={bedOptions}
          getOptionLabel={(option) => option?.label || ''}
          filterSelectedOptions
          onChange={handleUnitChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={value?.wardIds?.length ? t`Beds` : t`All Beds`}
              variant="outlined"
              sx={{ minWidth: '25ch', width: 'auto' }}
            />
          )}
        />
      ) : null}
    </Flex>
  );
};
export default ReportLocationFilter;
