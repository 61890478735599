import gql from 'graphql-tag';
import {
  reportingReportFragment,
  parkingPlaceSmallFragment,
  safetyPositionPresetFragment,
  wardSafetyPositionFragment,
  reportingDashboardListItemFragment,
} from './fragments';

// WORKSPACES
export const createWorkspace = gql`
  mutation createWorkspaceMutation($workspace: WorkspaceInput) {
    createWorkspace(workspace: $workspace)
  }
`;
export const deleteWorkspace = gql`
  mutation deleteWorkspaceMutation($workspaceId: ID!) {
    deleteWorkspace(workspaceId: $workspaceId)
  }
`;

// USERS
export const upsertUser = gql`
  mutation upsertUserMutation($user: UserInput!) {
    upsertUser(user: $user)
  }
`;

export const deleteUser = gql`
  mutation deleteUserMutation($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

// CONFIG
export const upsertConfig = gql`
  mutation upsertConfigMutation($config: ConfigInput!) {
    upsertConfig(config: $config) {
      hospitalName
    }
  }
`;

// SUPERCONFIG
export const upsertSuperConfig = gql`
  mutation upsertSuperConfigMutation($config: SuperConfigInput!) {
    upsertSuperConfig(config: $config) {
      spEnabled
      spVersion
      spDashboardVersion
      spDashboardLicenceExpiration
      patientEnabled
      spDashboardEnabled
      respiratoryMobilizationEnabled
      bedManagementEnabled
      patientLicenceExpiration
      bedManagementLicenceExpiration
      respiratoryMobilizationLicenceExpiration
      utilizationEnabled
      utilizationLicenceExpiration
      syncCommunication
      hl7LogEnabled
      passwordFlags
      passwordMinLength
      adminPasswordFlags
      adminPasswordMinLength
    }
  }
`;

export const addConnectionMutation = gql`
  mutation AddConnection($name: String!) {
    addConnection(name: $name) {
      id
      name
      enabled
      ip
      port
      synchronous
      syncPeriod
      props {
        key
        enabled
        threshold
      }
    }
  }
`;

export const editConnectionMutation = gql`
  mutation EditConnection($connection: ConnectionInput!) {
    editConnection(connection: $connection) {
      id
      name
      enabled
      ip
      port
      synchronous
      syncPeriod
      props {
        key
        enabled
        threshold
      }
    }
  }
`;

export const deleteConnectionMutation = gql`
  mutation DeleteConnection($id: ID!) {
    deleteConnection(id: $id)
  }
`;

// SAVE SETTINGS

// export const saveSettings = gql`
//   mutation saveSettingsMutation(
//     $configInput: ConfigInput!
//     $sppInput: [SafetyPortPropInput!]!
//   ) {
//     upsertConfig(config: $configInput) {
//       hospitalName
//     }
//     setSafetyPortProps(input: $sppInput)
//   }
// `;

// // SAFETYPORTPROPS
// export const setSafetyPortProps = gql`
//   mutation setSafetyPortPropsMutation($input: [SafetyPortPropInput!]!) {
//     setSafetyPortProps(input: $input)
//   }
// `;

// WARDS
export const createWard = gql`
  mutation createWardMutation($ward: WardInput) {
    createWard(ward: $ward) {
      id
      name
    }
  }
`;
export const deleteWard = gql`
  mutation deleteWardMutation($wardId: ID!) {
    deleteWard(wardId: $wardId)
  }
`;
export const updateWard = gql`
  mutation updateWardMutation($ward: WardInput) {
    updateWard(ward: $ward)
  }
`;
export const changeUserSetting = gql`
  mutation ChangeUserSetting($input: UserSettingInput) {
    changeUserSetting(input: $input) {
      userId
      svTheme
      dashboardOrder
      dashboardOrder2
      dashboardPageSize
      dashboardFontSize
      showOwnedBeds
      anonymized
      singleLineLayout
    }
  }
`;

// ROOMS
export const createRoom = gql`
  mutation createRoomMutation($room: RoomInput!) {
    createRoom(room: $room) {
      id
      name
    }
  }
`;
export const deleteRoom = gql`
  mutation deleteRoomMutation($roomId: ID!) {
    deleteRoom(roomId: $roomId)
  }
`;
export const updateRoom = gql`
  mutation updateRoomMutation($room: UpdateRoomInput!) {
    updateRoom(room: $room)
  }
`;

// BEDS
export const removeParkingPlace = gql`
  mutation removeParkingPlaceMutation($parkingPlaceId: ID!) {
    removeParkingPlace(parkingPlaceId: $parkingPlaceId)
  }
`;
export const unassignBed = gql`
  mutation unassignBedMutation($bedUnitId: ID!) {
    unassignBed(bedUnitId: $bedUnitId)
  }
`;
export const updateBed = gql`
  mutation updateBedMutation($bed: BedEditInput!) {
    updateBed(bed: $bed) {
      unitId
      name
      ident
      type
      ownerWardId
    }
  }
`;

export const deleteBed = gql`
  mutation deleteBedMutation($bedUnitId: ID!) {
    deleteBed(bedUnitId: $bedUnitId)
  }
`;

export const deleteBedsMutation = gql`
  mutation DeleteBedsMutation($bedUnitIds: [ID!]!) {
    deleteBeds(bedUnitIds: $bedUnitIds)
  }
`;

export const setDevice = gql`
  mutation setDevice($bedUnitIds: [ID!], $operation: Int!, $deviceId: Int!) {
    setDevice(
      bedUnitIds: $bedUnitIds
      operation: $operation
      deviceId: $deviceId
    )
  }
`;

export const updateWardSafetyPreset = gql`
  mutation UpdateWardSafetyPreset($input: [WardSafetyPresetInput!]!) {
    updateWardSafetyPreset(input: $input) {
      ...WardSafetyPositionFragment
    }
  }
  ${wardSafetyPositionFragment}
`;

export const setWardSetting = gql`
  mutation SetWardSetting(
    $wardId: ID!
    $breaksAlertSound: Boolean
    $heightAlertSound: Boolean
    $backrestAlertSound: Boolean
    $siderailAlertSound: Boolean
  ) {
    setWardSetting(
      wardId: $wardId
      breaksAlertSound: $breaksAlertSound
      heightAlertSound: $heightAlertSound
      backrestAlertSound: $backrestAlertSound
      siderailAlertSound: $siderailAlertSound
    ) {
      breaksAlertSound
      heightAlertSound
      backrestAlertSound
      siderailAlertSound
    }
  }
`;

export const createSafetyPositionPreset = gql`
  mutation CreateSafetyPositionPreset {
    createSafetyPositionPreset {
      ...SafetyPositionPresetFragment
    }
  }
  ${safetyPositionPresetFragment}
`;

export const editSafetyPositionPreset = gql`
  mutation EditSafetyPositionPreset($input: EditSafetyPositionPresetInput!) {
    editSafetyPositionPreset(input: $input) {
      ...SafetyPositionPresetFragment
    }
  }
  ${safetyPositionPresetFragment}
`;

export const deleteSafetyPositionPreset = gql`
  mutation DeleteSafetyPositionPreset($id: ID!) {
    deleteSafetyPositionPreset(id: $id)
  }
`;

export const setState = gql`
  mutation setState($bedUnitId: ID!, $state: Int!, $pause: Int) {
    setState(bedUnitId: $bedUnitId, state: $state, pause: $pause)
  }
`;

export const pauseTimer = gql`
  mutation pauseTimer($bedUnitId: ID!, $seconds: Int!) {
    pauseTimer(bedUnitId: $bedUnitId, seconds: $seconds)
  }
`;

export const addVirtualBed = gql`
  mutation addVirtualBed {
    addVirtualBed {
      unitId
    }
  }
`;

export const resetPause = gql`
  mutation ResetPauseMutation($virtualBedId: ID!) {
    resetPause(virtualBedId: $virtualBedId)
  }
`;

export const updateVirtualBed = gql`
  mutation updateVirtualBed($input: UpdateVirtualBedInput!) {
    updateVirtualBed(input: $input) {
      unitId
      smPause
    }
  }
`;

export const addCareNoteMutation = gql`
  mutation AddCareNoteMutation(
    $sessionId: ID!
    $note: String!
    $date: DateTime!
  ) {
    addNote(sessionId: $sessionId, note: $note, date: $date) {
      id
      note
      dateCreated
      dateConfirmed
    }
  }
`;

export const deleteCareNoteMutation = gql`
  mutation DeleteCareNoteMutation($id: ID!) {
    deleteNote(id: $id)
  }
`;

export const editCareNoteMutation = gql`
  mutation EditCareNoteMutation($id: ID!, $note: String!) {
    editNote(id: $id, note: $note) {
      id
      note
    }
  }
`;

export const startPatientSession = gql`
  mutation StartPatientSession($input: StartSessionInput!) {
    startSession(input: $input) {
      id
      name
      note
      fallRisk
      sessionStart
    }
  }
`;

export const updatePatientSession = gql`
  mutation EditPatientSession($input: EditPatientSessionInput!) {
    editSession(input: $input) {
      id
      name
      note
      sessionStart
      fallRisk
      safetyPositionPresetId
    }
  }
`;

export const endSession = gql`
  mutation EndSession($id: ID!, $end: DateTime!) {
    endSession(id: $id, end: $end)
  }
`;

export const changePwEndpointOrder = gql`
  mutation ChangePwEndpointOrder($orderString: String!) {
    changePwEndpointOrder(orderString: $orderString) {
      userId
      pwEndpointOrder
    }
  }
`;

export const pinBedMutation = gql`
  mutation PinBed($unitId: ID!) {
    pinBed(unitId: $unitId) {
      unitId
      pinned
    }
  }
`;

export const unpinBedMutation = gql`
  mutation UnpinBed($unitId: ID!) {
    unpinBed(unitId: $unitId) {
      unitId
      pinned
    }
  }
`;

export const uploadLogoMutation = gql`
  mutation UploadLogoMutation($file: Upload!) {
    uploadLogo(file: $file)
  }
`;

export const deleteLogoMutation = gql`
  mutation DeleteLogoMutation {
    deleteLogo
  }
`;

export const copyWorkspaceMutation = gql`
  mutation CopyWorkspaceMutation($workspaceId: ID!) {
    copyWorkspace(workspaceId: $workspaceId) {
      id
    }
  }
`;

export const createParkingPlaceMutation = gql`
  mutation CreateParkingPlace($roomId: ID!) {
    createParkingPlace(roomId: $roomId) {
      id
      name
    }
  }
`;

export const changeParkingPlaceModeMutation = gql`
  mutation ChangeParkingPlaceMode(
    $parkingPlaceId: ID!
    $mode: ParkingPlaceMode!
  ) {
    changeParkingPlaceMode(parkingPlaceId: $parkingPlaceId, mode: $mode) {
      ...ParkingPlaceSmallFragment
    }
  }
  ${parkingPlaceSmallFragment}
`;

export const changeParkingPlaceTagMutation = gql`
  mutation ChangeParkingPlaceTag($parkingPlaceId: ID!, $tagId: ID) {
    changeParkingPlaceTag(parkingPlaceId: $parkingPlaceId, tagId: $tagId) {
      ...ParkingPlaceSmallFragment
    }
  }
  ${parkingPlaceSmallFragment}
`;

export const changeParkingPlaceNameMutation = gql`
  mutation ChangeParkingPlaceNameMutation(
    $parkingPlaceId: ID!
    $name: String!
  ) {
    changeParkingPlaceName(parkingPlaceId: $parkingPlaceId, name: $name) {
      ...ParkingPlaceSmallFragment
    }
  }
  ${parkingPlaceSmallFragment}
`;

export const changeParkingPlaceIpAddressEthernetMutation = gql`
  mutation ChangeParkingPlaceIpAddressEthernetMutation(
    $parkingPlaceId: ID!
    $ipAddressEthernet: String
  ) {
    changeParkingPlaceIpAddressEthernet(
      parkingPlaceId: $parkingPlaceId
      ipAddressEthernet: $ipAddressEthernet
    ) {
      ...ParkingPlaceSmallFragment
    }
  }
  ${parkingPlaceSmallFragment}
`;

export const changeParkingPlaceBedMutation = gql`
  mutation ChangeParkingPlaceBed($parkingPlaceId: ID!, $bedUnitId: ID) {
    changeParkingPlaceBed(
      parkingPlaceId: $parkingPlaceId
      bedUnitId: $bedUnitId
    ) {
      ...ParkingPlaceSmallFragment
    }
  }
  ${parkingPlaceSmallFragment}
`;

export const updateUserPasswordMutation = gql`
  mutation UpdateUserPassword(
    $userId: ID!
    $oldPassword: String!
    $newPassword: String!
  ) {
    updateUserPassword(
      userId: $userId
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      id
    }
  }
`;

export const createReportingReportMutation = gql`
  mutation CreateReportingReport($input: CreateReportingReportInput!) {
    createReportingReport(input: $input) {
      ...ReportingReportFragment
    }
  }
  ${reportingReportFragment}
`;

export const createReportingGoalMutation = gql`
  mutation CreateReportingGoal($input: CreateReportingGoalInput!) {
    createReportingGoal(input: $input) {
      ...ReportingReportFragment
    }
  }
  ${reportingReportFragment}
`;

export const editReportingReportMutation = gql`
  mutation EditReportingReport($input: EditReportingReportInput!) {
    editReportingReport(input: $input) {
      ...ReportingReportFragment
    }
  }
  ${reportingReportFragment}
`;

export const editReportingGoalMutation = gql`
  mutation EditReportingGoal($input: EditReportingGoalInput!) {
    editReportingGoal(input: $input) {
      ...ReportingReportFragment
    }
  }
  ${reportingReportFragment}
`;

export const deleteReportingReportMutation = gql`
  mutation DeleteReportingReport($id: ID!) {
    deleteReportingReport(id: $id)
  }
`;

export const createReportingDashboardMutation = gql`
  mutation CreateReportingDashboard($input: CreateReportingDashboardInput!) {
    createReportingDashboard(input: $input) {
      ...ReportingDashboardListItemFragment
    }
  }
  ${reportingDashboardListItemFragment}
`;

export const editReportingDashboardMutation = gql`
  mutation EditReportingDashboard($input: EditReportingDashboardInput!) {
    editReportingDashboard(input: $input) {
      ...ReportingDashboardListItemFragment
    }
  }
  ${reportingDashboardListItemFragment}
`;

export const deleteReportingDashboardMutation = gql`
  mutation DeleteReportingDashboard($id: ID!) {
    deleteReportingDashboard(id: $id)
  }
`;

export const removeReportingReportFromDashboard = gql`
  mutation RemoveReportingReportFromDashboard(
    $input: RemoveReportingReportFromDashboardInput!
  ) {
    removeReportingReportFromDashboard(input: $input)
  }
`;

export const addReportingReportToDashboard = gql`
  mutation AddReportingReportToDashboard(
    $input: AddReportingReportToDashboardInput!
  ) {
    addReportingReportToDashboard(input: $input) {
      id
      reportingDashboards {
        id
        name
      }
    }
  }
`;

export const updateReportingDashboardReportPosition = gql`
  mutation UpdateReportingDashboardReportPosition(
    $input: UpdateReportingDashboardReportPositionInput!
  ) {
    updateReportingDashboardReportPosition(input: $input)
  }
`;

export const deleteTagMutation = gql`
  mutation DeleteTag($tagId: ID!) {
    deleteTag(tagId: $tagId)
  }
`;

export const clearDataMutation = gql`
  mutation ClearDataMutation($input: ClearDataInput!) {
    clearDataHistory(input: $input)
  }
`;

export const saveSimulationPresetMutation = gql`
  mutation SaveSimulationPreset($input: SaveSimulationPresetInput!) {
    saveSimulationPreset(input: $input) {
      id
      name
      config
    }
  }
`;

export const deleteSimulationPresetMutation = gql`
  mutation DeleteSimulationPreset($id: ID!) {
    deleteSimulationPreset(id: $id)
  }
`;

export const simulateDataQuery = gql`
  mutation SimulateData($input: SimulateDataInput!) {
    simulateData(input: $input)
  }
`;
