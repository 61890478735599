import { useField } from 'formik';
import { ReactNode } from 'react';
import SwitchBtn from '../SwitchBtn';

interface Props {
  name: string;
  id?: string;
  disabled?: boolean;
  label?: string | ReactNode;
  onChanged?: (value: boolean) => void;
}

const FormikSwitch = ({ name, id, disabled, label, onChanged }: Props) => {
  const [inputProps] = useField<boolean>(name);
  const { value, name: inputName, onChange } = inputProps;

  const handleChange = (checked: boolean) => {
    onChange({ target: { value: checked, name: inputName } });
    onChanged?.(checked);
  };

  return (
    <SwitchBtn
      name={inputName}
      disabled={disabled}
      label={label}
      state={value}
      id={id || inputName}
      onChange={handleChange}
    />
  );
};

export default FormikSwitch;
