import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteValue,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useField } from 'formik';
import React from 'react';

type Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = {
  name: string;
  textFieldProps: TextFieldProps;
  renderInput?: AutocompleteRenderInputParams;
} & Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput'
>;

const FormikAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textFieldProps,
  ...autocompleteProps
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  const [inputProps, metaProps] = useField(name);

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: AutocompleteValue<T, Multiple | boolean, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
  ) => {
    inputProps.onChange({
      ...e,
      target: { ...e.target, name: inputProps.name, value },
    });
    autocompleteProps.onChange?.(e, value as any, reason);
  };

  const { error, touched } = metaProps;
  return (
    <Autocomplete
      {...autocompleteProps}
      {...inputProps}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          name={name}
          error={touched && !!error}
          helperText={touched && !!error && error}
        />
      )}
    />
  );
};

export default FormikAutocomplete;
