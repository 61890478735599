import {
  Box,
  Tooltip,
  styled,
  useTheme,
  Theme,
  Typography,
} from '@mui/material';
import {
  graphLenght,
  PatientDataValuesType,
  GraphType,
} from '../../helpers/patientView';
import { useLingui } from '@lingui/react';
import { notEmpty } from 'src/lib/notEmpty';
import { useMemo } from 'react';

interface Props {
  type: GraphType;
  data: PatientDataValuesType;
  handleClick?: (from: Date, to: Date) => void;
}

interface GraphPoint {
  value: null | number;
  from: Date;
  to: Date;
  count: number;
  barWidth: number;
}

const getBarColor = (theme: Theme, type: GraphType, value?: number) => {
  if (type === GraphType.bedExitAlarm) {
    if (value === 1 || value === 2) {
      return theme.palette.error.main;
    }
    return 'transparent';
  }
  return theme.palette.common.datapointViewGraph;
};

const mergeDatapoints = (data: PatientDataValuesType): GraphPoint[] => {
  let count = 1;
  return data
    .map((item, index) => {
      const nextValue = data[index + 1]?.value;
      if (item?.value === nextValue) {
        count += 1;
      } else {
        const barWidth = count * (graphLenght / data.length);
        const firstIndex = index - (count - 1);
        const datapoint = data[firstIndex];
        if (!datapoint?.time || !item?.time) {
          console.warn('Unexpected datapoint value', {
            datapoint,
            item,
            firstIndex,
            index,
            count,
          });
          return null;
        }
        const from = new Date(datapoint.time);
        const to = new Date(item.time);
        const result = {
          value: item.value ?? null,
          from,
          to,
          count,
          barWidth,
        };
        count = 1;
        return result;
      }
      return null;
    })
    .filter(notEmpty);
};

const Bars = ({ data, type, handleClick }: Props) => {
  const { i18n } = useLingui();
  const theme = useTheme();
  const graphPoints = useMemo(() => data && mergeDatapoints(data), [data]);

  const tooltip = (graphPoint: GraphPoint) =>
    `${
      graphPoint.count > 1
        ? `${i18n.date(graphPoint.from, {
            hour: 'numeric',
            minute: 'numeric',
          })} - `
        : ``
    } ${i18n.date(graphPoint.to, {
      hour: 'numeric',
      minute: 'numeric',
    })}`;

  return (
    <Box height="100%" width={graphLenght} display="flex" alignItems="center">
      {graphPoints
        ? graphPoints.map((graphPoint) => (
            <Tooltip
              title={<Typography>{tooltip(graphPoint)}</Typography>}
              key={graphPoint.from?.toString()}
              PopperProps={{
                sx: {
                  '&& .MuiTooltip-tooltip': {
                    backgroundColor: (theme) => theme.palette.common.tooltipBg,
                    color: (theme) => theme.palette.common.tooltipText,
                  },
                },
              }}
              followCursor
            >
              <ValueField
                height={
                  graphPoint.value === 1 && type !== GraphType.bedExitAlarm
                    ? 39
                    : 13
                }
                top={graphPoint.value === 2 ? '-13px' : undefined}
                bottom={graphPoint.value === 0 ? '-13px' : undefined}
                key={`bar-${
                  graphPoint.value
                }-${graphPoint.from?.toISOString()}`}
                sx={
                  graphPoint.value !== null
                    ? {
                        backgroundColor: getBarColor(
                          theme,
                          type,
                          graphPoint.value,
                        ),
                        cursor: 'default',
                      }
                    : { visibility: 'hidden' }
                }
                width={graphPoint.barWidth}
                onClick={() => {
                  handleClick && handleClick(graphPoint.from, graphPoint.to);
                }}
              />
            </Tooltip>
          ))
        : null}
    </Box>
  );
};

export default Bars;

const ValueField = styled(Box)`
  position: relative;
  background-color: ${(props) => props.theme.palette.common.datapointViewGraph};
  border-radius: 3px;
`;
