import { Trans } from '@lingui/macro';
import { Box, Typography, AccordionDetails, styled } from '@mui/material';
import SwitchBtn from '../../components/SwitchBtn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SafetyPortFormValues } from './superAdminHelper';
import { AccordionSectionHeader } from './components/AccordionSectionHeader';
import { ThinTextField } from './components/ThinTextField';
import { FormikProps } from 'formik';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

interface Props
  extends Pick<
    FormikProps<SafetyPortFormValues>,
    'values' | 'setFieldValue' | 'handleChange' | 'handleBlur'
  > {
  name: string;
  enabledKey: keyof SafetyPortFormValues;
  expirationKey: keyof SafetyPortFormValues;
}

const getSwitchValue = (
  enabledKey: keyof SafetyPortFormValues,
  values: SafetyPortFormValues,
) => {
  if (values[enabledKey]) {
    return values[enabledKey] as boolean;
  }
  return false;
};

const getFieldValue = (
  expirationKey: keyof SafetyPortFormValues,
  values: SafetyPortFormValues,
) => {
  if (values[expirationKey]) {
    return values[expirationKey] as string;
  }
  return '';
};

const DataTransferConfigItem = ({
  setFieldValue,
  handleChange,
  values,
  name,
  enabledKey,
  expirationKey,
}: Props) => {
  const onFieldChange = (fieldKey: keyof SafetyPortFormValues) => {
    setFieldValue(fieldKey, !values[fieldKey]);
  };
  return (
    <Accordion>
      <AccordionSectionHeader
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          display="flex"
          alignItems="center"
          ml={2}
          width={240}
          justifyContent="space-between"
        >
          <Typography>{name}</Typography>
          <div onClick={(e) => e.stopPropagation()}>
            <SwitchBtn
              small
              id={`data-transfer-${enabledKey}`}
              state={getSwitchValue(enabledKey, values)}
              onChange={() => onFieldChange(enabledKey)}
            />
          </div>
        </Box>
      </AccordionSectionHeader>
      <AccordionDetails>
        <Box sx={{ marginLeft: '-16px' }}>
          <Box ml={3}>
            <Typography sx={{ marginLeft: '12px' }}>
              <Trans>Licence expiration date</Trans>
            </Typography>
            <ThinTextField
              type="date"
              name={expirationKey}
              value={getFieldValue(expirationKey, values)}
              onChange={handleChange}
              variant="outlined"
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default DataTransferConfigItem;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));
