import { t } from '@lingui/macro';
import { differenceInDays } from 'date-fns';
import { useMemo, useState } from 'react';
import {
  LocationType,
  NumberComparator,
  ReportBedAggregation,
  ReportLocationAggregation,
  ReportResultField,
  TimeUnitType,
} from 'src/graphql/types';
import { DateOptionPreset } from 'src/lib/datePresetHelper';
import {
  FilterParameter,
  RawReportFilter,
  ReportAggregation,
  ReportFieldFilter,
  ReportFilterDateOption,
  ReportFilterParams,
} from '../types';
import { getAggregationOptions } from './useAggregationOptions';
import { dateSelectionType } from './useDateSelectOptions';

export const mapFilterParametersToTranslates = () => ({
  [FilterParameter.OCCUPANCY]: t`Bed occupancy`,
  [FilterParameter.BED_EXIT_COUNT]: t`Number of bed exit events`,
  [FilterParameter.WEIGHT]: t`Weight`,
  [FilterParameter.BED_EXIT_REACTION]: t`Reaction time on bed exit event`,
  [FilterParameter.ONLINE]: t`Bed is online (connectivity)`,
  [FilterParameter.ALOS]: t`ALOS (sessions)`,
  [FilterParameter.BED_EXIT_MONITORING_ON]: t`Bed exit monitoring on`,
  [FilterParameter.SAFETY_POSITION]: t`Safety position`,
  [FilterParameter.PATIENT_PRESENCE]: t`Patient presence (Out of Bed Status)`,
  [FilterParameter.LOWEST_POSTITION]: t`Lowest position`,
  [FilterParameter.BACKREST_ANGLE]: t`Backrest Angle`,
  [FilterParameter.BACKREST]: t`Backrest`,
  [FilterParameter.BACKREST_30]: t`Backrest 30`,
  [FilterParameter.BACKREST_45]: t`Backrest 45`,
  [FilterParameter.LATERAL_TILT]: t`Lateral tilt`,
  [FilterParameter.CALFREST]: t`Calfrest`,
  [FilterParameter.ALT_PHASE]: t`ALT Function`,
  [FilterParameter.TRENDELENBURG]: t`Trendelenburg`,
  [FilterParameter.ALL_SIDERAILS]: t`All Siderails Combined`,
  [FilterParameter.RIGHT_HEAD_SIDERAIL]: t`Right Head Siderail Position`,
  [FilterParameter.LEFT_HEAD_SIDERAIL]: t`Left Head Siderail Position`,
  [FilterParameter.RIGHT_SIDERAIL]: t`Right Siderail Position`,
  [FilterParameter.LEFT_SIDERAIL]: t`Left Siderail Position`,
});

export type UpdateFilterFunction = (
  partialFilter: Partial<RawReportFilter>,
) => void;
export type UpdateFieldFilterFunction = (
  callback: (f: ReportFieldFilter[]) => ReportFieldFilter[],
) => void;

const init: RawReportFilter = {
  parameter: FilterParameter.OCCUPANCY,
  graphType: 'bar-horizontal',
  aggregation: ReportAggregation.ABSOLUTE_LAST,
  period: {
    preset: DateOptionPreset.today,
    from: null,
    to: null,
  },
  fieldFilters: [],
  viewByLocation: LocationType.WORKSPACE,
  viewByTime: {
    value: 1,
    unit: TimeUnitType.DAY,
  },
};

const recalculateFilter = (rawFilter: RawReportFilter): ReportFilterParams => {
  return {
    ...rawFilter,
    variant: 'SINGLE_VALUE',
    type: 'general',
    bedAggregation: getBedAggregation(rawFilter.aggregation),
    locationAggregation: getLocationAggregation(rawFilter.aggregation),
    mainFilter: getMainFilter(rawFilter),
    resultField: getResultField(rawFilter),
  };
};

const useReportFilterParams = () => {
  const [rawfilter, setFilter] = useState<RawReportFilter>(init);
  const filter = useMemo(() => {
    return recalculateFilter(rawfilter);
  }, [rawfilter]);

  const updateFilter = (partialFilter: Partial<RawReportFilter>) => {
    setFilter((prev) => validateFilter(partialFilter, prev));
  };

  const loadFilter = (filterParams: any) => {
    const {
      bedAggregation,
      locationAggregation,
      resultField,
      mainFilter,
      ...rest
    } = filterParams;
    setFilter(rest);
  };

  const updateFieldFilters = (
    callback: (f: ReportFieldFilter[]) => ReportFieldFilter[],
  ) => {
    setFilter((filter) => {
      return {
        ...filter,
        fieldFilters: callback(filter.fieldFilters),
      };
    });
  };

  return {
    rawfilter,
    updateFilter,
    filter,
    loadFilter,
    updateFieldFilters,
  };
};

export default useReportFilterParams;

const validateFilter = (
  filterUpdate: Partial<RawReportFilter>,
  prev: RawReportFilter,
): RawReportFilter => {
  const updatedFilter = {
    ...prev,
    ...filterUpdate,
  };

  if (
    prev.period.preset === DateOptionPreset.now &&
    dateSelectionType[updatedFilter.parameter] === 'withoutNow'
  ) {
    updatedFilter.period = {
      preset: DateOptionPreset.today,
      to: null,
      from: null,
    };
  }
  if (
    updatedFilter.period.preset !== DateOptionPreset.now &&
    updatedFilter.graphType === 'scorecard'
  ) {
    updatedFilter.graphType = 'bar-horizontal';
  }
  if (filterUpdate.period?.preset === DateOptionPreset.now) {
    return {
      ...updatedFilter,
      graphType: 'scorecard',
      aggregation: getAggregation(updatedFilter),
      viewByLocation: getViewByLocation(updatedFilter),
      viewByTime: getViewByTime(updatedFilter),
    };
  }

  if (filterUpdate.period?.preset) {
    return {
      ...updatedFilter,
      viewByTime: getViewByTime(updatedFilter),
    };
  }

  if (filterUpdate.parameter != null) {
    return {
      ...updatedFilter,
      aggregation: getAggregation(updatedFilter),
      viewByLocation: getViewByLocation(updatedFilter),
      viewByTime: getViewByTime(updatedFilter),
    };
  }

  if (updatedFilter.graphType === 'pie') {
    updatedFilter.viewByTime.value = 0;
  } else {
    updatedFilter.viewByTime.value = 1;
  }
  return updatedFilter;
};

const getBedAggregation = (
  aggregation: ReportAggregation,
): ReportBedAggregation => {
  if (aggregation === ReportAggregation.ABSOLUTE_LAST) {
    return ReportBedAggregation.LAST;
  }
  if (aggregation === ReportAggregation.AVERAGE_LAST) {
    return ReportBedAggregation.LAST;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_COUNT) {
    return ReportBedAggregation.COUNT;
  }
  if (aggregation === ReportAggregation.AVERAGE_COUNT) {
    return ReportBedAggregation.COUNT;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_MEAN) {
    return ReportBedAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.AVERAGE_MEAN) {
    return ReportBedAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_DURATION) {
    return ReportBedAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_DURATION) {
    return ReportBedAggregation.SUM;
  }
  if (aggregation === ReportAggregation.LIST) {
    return ReportBedAggregation.NONE;
  }
  return ReportBedAggregation.MEAN;
};

const getLocationAggregation = (
  aggregation: ReportAggregation,
): ReportLocationAggregation => {
  if (aggregation === ReportAggregation.ABSOLUTE_LAST) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_LAST) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_COUNT) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_COUNT) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_MEAN) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_MEAN) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_DURATION) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_DURATION) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.LIST) {
    return ReportLocationAggregation.MEAN;
  }
  return ReportLocationAggregation.MEAN;
};

export const getTimeUnits = (period: ReportFilterDateOption) => {
  const days =
    period.preset === 'custom' && period.to && period.from
      ? differenceInDays(period.to, period.from)
      : -1;
  if (period.preset === DateOptionPreset.now) {
    return [TimeUnitType.DAY];
  }
  if (
    period.preset === DateOptionPreset.thisYear ||
    period.preset === DateOptionPreset.lastYear ||
    days > 90
  ) {
    return [TimeUnitType.MONTH];
  }
  if (
    period.preset === DateOptionPreset.lastQuarter ||
    period.preset === DateOptionPreset.thisQuarter ||
    (days <= 90 && days > 7)
  ) {
    return [TimeUnitType.MONTH, TimeUnitType.WEEK];
  }
  if (
    period.preset === DateOptionPreset.thisMonth ||
    period.preset === DateOptionPreset.lastMonth ||
    period.preset === DateOptionPreset.thisWeek ||
    period.preset === DateOptionPreset.lastWeek ||
    period.preset === DateOptionPreset.last7Days ||
    period.preset === DateOptionPreset.last14Days ||
    (days <= 7 && days > 1)
  ) {
    return [TimeUnitType.WEEK, TimeUnitType.DAY];
  }
  if (
    period.preset === DateOptionPreset.today ||
    period.preset === DateOptionPreset.yesterday ||
    days <= 1
  ) {
    return [TimeUnitType.DAY, TimeUnitType.HOUR];
  }
  return [TimeUnitType.DAY];
};

export const getLocationUnits = (filter: RawReportFilter) => {
  if (filter.period.preset === DateOptionPreset.now) {
    return [LocationType.BED];
  }
  if (filter.parameter === FilterParameter.OCCUPANCY) {
    return [
      LocationType.BED,
      LocationType.ROOM,
      LocationType.WARD,
      LocationType.WORKSPACE,
      LocationType.BED_TYPE,
    ];
  }
  if (
    filter.parameter === FilterParameter.BED_EXIT_COUNT ||
    filter.parameter === FilterParameter.BED_EXIT_MONITORING_ON ||
    filter.parameter === FilterParameter.BED_EXIT_REACTION
  ) {
    return [
      LocationType.BED,
      LocationType.ROOM,
      LocationType.WARD,
      LocationType.WORKSPACE,
    ];
  }
  if (
    filter.parameter === FilterParameter.WEIGHT ||
    filter.parameter === FilterParameter.BACKREST_ANGLE
  ) {
    return [LocationType.BED];
  }
  if (filter.parameter === FilterParameter.ALOS) {
    return [
      LocationType.BED,
      LocationType.ROOM,
      LocationType.WARD,
      LocationType.WORKSPACE,
    ];
  }
  return [
    LocationType.BED,
    LocationType.ROOM,
    LocationType.WARD,
    LocationType.WORKSPACE,
  ];
};
const getAggregation = (filter: RawReportFilter) => {
  const aggregations = getAggregationOptions(filter.parameter);
  const aggregation = aggregations.some(
    (aggr) => aggr.id === filter.aggregation,
  );
  if (aggregation) {
    return filter.aggregation;
  }
  return aggregations[0].id;
};
const getViewByLocation = (filter: RawReportFilter) => {
  const newLocations = getLocationUnits(filter);
  if (newLocations.some((location) => location === filter.viewByLocation)) {
    return filter.viewByLocation;
  }
  return newLocations[0];
};

const getViewByTime = (filter: RawReportFilter) => {
  const isPie = filter.graphType === 'pie';
  const newTimeUnits = getTimeUnits(filter.period);

  if (newTimeUnits.some((tu) => tu === filter.viewByTime.unit)) {
    return {
      ...filter.viewByTime,
      value: isPie ? 0 : 1,
    };
  }
  return {
    value: isPie ? 0 : 1,
    unit: newTimeUnits[0],
  };
};

const getResultField = (filter: RawReportFilter): ReportResultField => {
  if (
    filter.parameter === FilterParameter.BED_EXIT_COUNT ||
    filter.parameter === FilterParameter.BED_EXIT_REACTION
  ) {
    return ReportResultField.BED_EXIT;
  }
  if (filter.parameter === FilterParameter.OCCUPANCY) {
    return ReportResultField.OCCUPANCY;
  }
  if (filter.parameter === FilterParameter.ALOS) {
    return ReportResultField.SESSION;
  }
  if (filter.parameter === FilterParameter.WEIGHT) {
    return ReportResultField.WEIGHT;
  }
  if (filter.parameter === FilterParameter.BACKREST_ANGLE) {
    return ReportResultField.BACKREST;
  }
  return ReportResultField.DURATION;
};

const getMainFilter = (filter: RawReportFilter): ReportFieldFilter => {
  if (filter.parameter === FilterParameter.ALL_SIDERAILS) {
    return {
      field: 'allSiderailsCombined',
      numberComparator: null,
      value: true,
    };
  }
  if (filter.parameter === FilterParameter.RIGHT_HEAD_SIDERAIL) {
    return {
      field: 'siderailHeadRUp',
      numberComparator: null,
      value: true,
    };
  }
  if (filter.parameter === FilterParameter.RIGHT_SIDERAIL) {
    return {
      field: 'siderailMiddleRUp',
      numberComparator: null,
      value: true,
    };
  }
  if (filter.parameter === FilterParameter.LEFT_HEAD_SIDERAIL) {
    return {
      field: 'siderailHeadLUp',
      numberComparator: null,
      value: true,
    };
  }
  if (filter.parameter === FilterParameter.LEFT_SIDERAIL) {
    return {
      field: 'siderailMiddleLUp',
      numberComparator: null,
      value: true,
    };
  }
  if (filter.parameter === FilterParameter.BACKREST) {
    return {
      field: 'backrest',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 0,
    };
  }
  if (filter.parameter === FilterParameter.BACKREST_30) {
    return {
      field: 'backrest',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 29,
    };
  }
  if (filter.parameter === FilterParameter.BACKREST_45) {
    return {
      field: 'backrest',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 44,
    };
  }
  if (filter.parameter === FilterParameter.CALFREST) {
    return {
      field: 'calfrest',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 0,
    };
  }
  if (filter.parameter === FilterParameter.ALT_PHASE) {
    return {
      field: 'ALTPhase',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 0,
    };
  }
  if (filter.parameter === FilterParameter.TRENDELENBURG) {
    return {
      field: 'trendelenburgDeg',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 0,
    };
  }
  if (filter.parameter === FilterParameter.LATERAL_TILT) {
    return {
      field: 'lateralTiltDeg',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 0,
    };
  }

  if (filter.parameter === FilterParameter.LOWEST_POSTITION) {
    return {
      field: 'posIsLowest',
      numberComparator: NumberComparator.NOT_EQUALS,
      value: 0,
    };
  }

  if (filter.parameter === FilterParameter.PATIENT_PRESENCE) {
    return {
      field: 'outOfBed',
      numberComparator: null,
      value: false,
    };
  }
  if (filter.parameter === FilterParameter.SAFETY_POSITION) {
    return {
      field: 'safetyPosition',
      numberComparator: null,
      value: true,
    };
  }
  if (filter.parameter === FilterParameter.BED_EXIT_MONITORING_ON) {
    return {
      field: 'bedExitOn',
      numberComparator: null,
      value: true,
    };
  }
  return {
    field: 'connectionStatus',
    numberComparator: null,
    value: 1,
  };
};
