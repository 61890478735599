import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { FilterValue } from 'src/components/Filters';
import { BedFilters, BedFiltersVariables } from 'src/graphql/types';
import { notEmpty } from 'src/lib/notEmpty';
import { bedFiltersQuery } from '../../../graphql/queries';

export const BED_FILTER = 'bedFilter';

export type FilterProps = {
  bedId: FilterValue | null;
  bedName: FilterValue | null;
  workspace: FilterValue | null;
  mode: FilterValue | null;
  ward: FilterValue | null;
  room: FilterValue | null;
  status: FilterValue | null;
};

interface Bed {
  unitId: string;
  name?: string | null;
}

export const useBedFilterOption = (filter: FilterProps, beds?: Bed[]) => {
  const { data } = useQuery<BedFilters, BedFiltersVariables>(bedFiltersQuery, {
    variables: {
      workspaceIds: filter.workspace?.id ? [filter.workspace.id] : undefined,
    },
  });

  const statusOptions = [
    { id: 'ONLINE', name: t`ONLINE` },
    { id: 'OFFLINE', name: t`OFFLINE` },
  ];

  const locationSourceOptions = [
    { id: 'MANUAL', name: t`MANUAL` },
    { id: 'ETHERNT', name: t`ETHERNET` },
    { id: 'TAG', name: t`TAG` },
  ];

  const bedNameOptions =
    beds
      ?.map((bed) =>
        bed.name != null ? { id: bed.name, name: bed.name } : null,
      )
      .filter(notEmpty) || [];

  const bedIdOptions =
    beds
      ?.map((bed) => ({ id: bed.unitId, name: bed.unitId }))
      .filter(notEmpty) || [];

  const wardOptions =
    data?.wards
      ?.map((ward) => ({ id: ward.id, name: ward.name }))
      .filter(notEmpty) || [];

  const roomOptions =
    data?.rooms
      ?.map((room) => ({ id: room.id, name: room.name }))
      .filter(notEmpty) || [];

  const workspaceOptions =
    data?.workspaces
      ?.map((workspace) => ({ id: workspace.id, name: workspace.name }))
      .filter(notEmpty) || [];

  return {
    bedIdOptions,
    bedNameOptions,
    statusOptions,
    locationSourceOptions,
    roomOptions,
    wardOptions,
    workspaceOptions,
  };
};
