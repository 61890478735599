import { Box, Button } from '@mui/material';
import { useMemo } from 'react';
import { DatapointRow } from './DatapointRow';
import { Datapoint, DATAPOINTS, DATAPOINT_TYPE } from './helper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { t } from '@lingui/macro';
import { FormikHelpers } from 'formik';
import { DataSimulationForm } from './DataSimulation';

interface Props
  extends Pick<
    FormikHelpers<DataSimulationForm>,
    'setFieldValue' | 'setFieldTouched'
  > {
  datapoints: Datapoint[];
  setDatapoints: React.Dispatch<React.SetStateAction<Datapoint[]>>;
}

export const DatapointData = ({
  setFieldValue,
  setFieldTouched,
  datapoints,
  setDatapoints,
}: Props) => {
  const unusedDatapoints = useMemo(
    () =>
      DATAPOINTS.filter((udp) => !datapoints.some((dp) => dp.id === udp.id)),
    [datapoints],
  );

  const handleChangeDatapoint = (dp: Datapoint, idx: number) => {
    setDatapoints((prev) => {
      if (prev[idx]?.id === 'connected') {
        return prev;
      }
      const newState = prev.slice();
      newState[idx] = dp;
      return newState;
    });
  };

  const handleRemoveDatapoint = (idx: number) => {
    const dp = datapoints[idx];
    if (dp.id === 'connected') {
      return;
    }
    if (dp) {
      if (dp.type === DATAPOINT_TYPE.LOCATION) {
        setFieldValue('parkingPlace', undefined);
        setFieldValue('ward', undefined);
        setFieldValue('workspace', undefined);
      } else {
        setFieldValue(dp.id, undefined);
        setFieldValue(`${dp.id}Alt`, undefined);
      }
    }
    setDatapoints((prev) => {
      const newState = prev.slice();
      newState.splice(idx, 1);
      return newState;
    });
  };

  const addNewDatapoint = () => {
    const nextDp = unusedDatapoints[0];
    setDatapoints((prev) => {
      return prev.concat([nextDp]);
    });
  };

  return (
    <Box sx={{ mb: 10 }}>
      {datapoints.map((dp, idx) => (
        <DatapointRow
          key={dp.id}
          datapoint={dp}
          datapointIdx={idx}
          setFieldTouched={setFieldTouched}
          changeDatapoint={handleChangeDatapoint}
          removeDatapoint={handleRemoveDatapoint}
          unusedDatapoints={unusedDatapoints}
        />
      ))}
      {unusedDatapoints.length > 0 ? (
        <Box mt={3}>
          <Button
            startIcon={<AddCircleIcon />}
            onClick={() => addNewDatapoint()}
          >{t`Add datapoint`}</Button>
        </Box>
      ) : null}
    </Box>
  );
};
