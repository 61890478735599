interface Props {
  content: any;
}

export const NurseCallSystem = ({ content }: Props) => {
  return (
    <div>
      <img
        alt="Nurse Call System"
        style={{ width: 600 }}
        src={require('src/images/nurse_call_system.png').default}
      />
      <div style={styles.content}>{content}</div>
    </div>
  );
};

const styles = {
  content: {
    position: 'absolute' as 'absolute',
    overflow: 'scroll' as 'scroll',
    top: 70,
    left: 160,
    width: 355,
    height: 245,
  },
};
