import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { generateReportQuery } from 'src/graphql/queries';
import {
  BoolFilter,
  GenerateReportQuery,
  GenerateReportQueryVariables,
  NumberFilter,
  ReportResultField,
} from 'src/graphql/types';
import { getDatesByPreset } from 'src/lib/datePresetHelper';
import {
  getAltPhase,
  getErrorCodes,
  getMattressType,
} from 'src/pages/Admin/VirtualBed/AdditionalDatapoints';
import { getButtonOptions } from 'src/pages/Admin/VirtualBed/ControllerDatapoints';
import { ReportFilterField, ReportFilterParams } from '../types';

export const useReportFilter = (filter: ReportFilterParams) => {
  const { i18n } = useLingui();

  const { type, fieldFilters, mainFilter, locationFilter } = filter;

  const dates = useMemo(
    () =>
      filter.period.preset !== 'custom'
        ? getDatesByPreset(filter.period.preset, i18n)
        : { from: filter.period.from, to: filter.period.to },
    [filter, i18n],
  );

  const mainFilterAppend = useMemo(() => {
    return filter.resultField === ReportResultField.DURATION
      ? [mainFilter]
      : [];
  }, [filter.resultField, mainFilter]);

  const boolFilters = useMemo(() => {
    const filters: BoolFilter[] = mainFilterAppend
      .concat(fieldFilters)
      .filter((val) => REPORT_FILTER_FIELDS[val.field]?.type === 'boolean')
      .map<BoolFilter>((val) => ({
        prop: REPORT_FILTER_FIELDS[val.field].fieldName,
        value: !!val.value,
      }));
    return filters;
  }, [fieldFilters, mainFilterAppend]);

  const numberFilters = useMemo(() => {
    const filters: NumberFilter[] = mainFilterAppend
      .concat(fieldFilters)
      .filter(
        (val) =>
          (REPORT_FILTER_FIELDS[val.field]?.type === 'number' ||
            REPORT_FILTER_FIELDS[val.field]?.type === 'option') &&
          !!val.numberComparator,
      )
      .map<NumberFilter>((val) => ({
        prop: REPORT_FILTER_FIELDS[val.field].fieldName,
        value: Number(val.value),
        comparator: val.numberComparator!,
      }));
    return filters;
  }, [fieldFilters, mainFilterAppend]);

  const viewByTime = useMemo(
    () => ({
      unit: filter.viewByTime.unit,
      value: filter.viewByTime.value,
    }),
    [filter.viewByTime.unit, filter.viewByTime.value],
  );

  const { data, loading } = useQuery<
    GenerateReportQuery,
    GenerateReportQueryVariables
  >(generateReportQuery, {
    variables: {
      input: {
        ...dates,
        resultField: filter.resultField,
        bedAggregation: filter.bedAggregation,
        locationAggregation: filter.locationAggregation,
        locationFilter: locationFilter,
        numberFilters: numberFilters,
        boolFilters: boolFilters,
        viewByTime: viewByTime,
        viewByLocation: filter.viewByLocation,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    skip: type !== 'general',
  });

  return {
    data: data?.generateReport || [],
    loading,
  };
};

//taken from scheme2.ts
export const REPORT_FILTER_FIELDS: { [k: string]: ReportFilterField } = {
  weight: {
    type: 'number',
    fieldName: 'weight',
    label: () => t`Weight`,
  },
  lateralTiltDeg: {
    type: 'number',
    fieldName: 'lateralTiltDeg',
    label: () => t`Lateral tilt`,
    min: -30,
    max: 30,
  },
  trendelenburgDeg: {
    type: 'number',
    fieldName: 'trendelenburgDeg',
    label: () => t`Trendelenburg`,
    min: -13,
    max: 16,
  },
  calfrest: {
    type: 'number',
    fieldName: 'calfrest',
    label: () => t`Calfrest`,
    min: -60,
    max: 40,
  },
  backrest: {
    type: 'number',
    fieldName: 'backrest',
    label: () => t`Backrest`,
    min: -15,
    max: 75,
  },
  ALTPhase: {
    type: 'option',
    fieldName: 'ALTPhase',
    label: () => t`ALT Phase`,
    options: () => getAltPhase(),
  },
  outOfBed: {
    type: 'boolean',
    fieldName: 'outOfBed',
    label: () => t`Out of bed`,
    yes: () => t`In`,
    no: () => t`Out`,
  },
  safetyPosition: {
    type: 'boolean',
    fieldName: 'safetyPosition',
    label: () => t`Safety position`,
  },
  posIsLowest: {
    type: 'boolean',
    fieldName: 'posIsLowest',
    label: () => t`Lowest position`,
  },
  bedExitOn: {
    type: 'boolean',
    fieldName: 'bedExitOn',
    label: () => t`Bed exit monitoring status`,
    yes: () => t`On`,
    no: () => t`Off`,
  },
  bedExitAlarm: {
    type: 'boolean',
    fieldName: 'bedExitAlarm',
    label: () => t`Bed exit event`,
    yes: () => t`On`,
    no: () => t`Off`,
  },
  isZoneExit: {
    type: 'boolean',
    fieldName: 'isZoneExit',
    label: () => t`Bed exit zone`,
    yes: () => t`Inner`,
    no: () => t`Outer`,
  },
  brakesOff: {
    type: 'boolean',
    fieldName: 'brakesOff',
    label: () => t`Brakes`,
  },
  mattressType: {
    type: 'option',
    fieldName: 'mattressType',
    label: () => t`Mattress type`,
    options: () => getMattressType(),
  },
  errorOrStopCode: {
    type: 'option',
    fieldName: 'errorOrStopCode',
    label: () => t`Error or Stop code`,
    options: () => getErrorCodes(),
  },
  pressedBtnAndUsedController: {
    type: 'option',
    fieldName: 'pressedBtnAndUsedController',
    label: () => t`Pressed button and used controller`,
    options: () => getButtonOptions(),
  },
  ALTError: {
    type: 'option',
    fieldName: 'ALTError',
    label: () => t`ALT error`,
    options: () => getAltPhase(),
  },
  siderailBothRUp: {
    type: 'boolean',
    fieldName: 'siderailBothRUp',
    label: () => t`Both right side rails up`,
  },
  siderailBothLUp: {
    type: 'boolean',
    fieldName: 'siderailBothLUp',
    label: () => t`Both left Side rail Up`,
  },
  siderailMiddleRUp: {
    type: 'boolean',
    fieldName: 'siderailMiddleRUp',
    label: () => t`Right side rail up`,
  },
  siderailMiddleLUp: {
    type: 'boolean',
    fieldName: 'siderailMiddleLUp',
    label: () => t`Left side rail middle up`,
  },
  siderailHeadRUp: {
    type: 'boolean',
    fieldName: 'siderailHeadRUp',
    label: () => t`Right head side rail up`,
  },
  siderailHeadLUp: {
    type: 'boolean',
    fieldName: 'siderailHeadLUp',
    label: () => t`Left head side rail up`,
  },
  allSiderailsCombined: {
    type: 'boolean',
    fieldName: 'allSiderailsCombined',
    label: () => t`All side rails combined`,
    yes: () => t`Up`,
    no: () => t`Down`,
  },
  calfrestLock: {
    type: 'boolean',
    fieldName: 'calfrestLock',
    label: () => t`Calfrest lock`,
    yes: () => t`On`,
    no: () => t`Off`,
  },
  backrestLock: {
    type: 'boolean',
    fieldName: 'backrestLock',
    label: () => t`Backrest Lock`,
    yes: () => t`On`,
    no: () => t`Off`,
  },
  heightLock: {
    type: 'boolean',
    fieldName: 'heightLock',
    label: () => t`Height lock`,
    yes: () => t`On`,
    no: () => t`Off`,
  },
  thighrestLock: {
    type: 'boolean',
    fieldName: 'thighrestLock',
    label: () => t`Thighrest lock`,
    yes: () => t`On`,
    no: () => t`Off`,
  },
  transfer: {
    type: 'boolean',
    fieldName: 'transfer',
    label: () => t`Transfer mode`,
    yes: () => t`On`,
    no: () => t`Off`,
  },
  mobiliftRight: {
    type: 'option',
    fieldName: 'mobiliftRight',
    label: () => t`Mobilift right`,
    options: () => [
      {
        id: 0,
        name: t`No button pressed`,
      },
      {
        id: 20,
        name: t`Up`,
      },
      {
        id: 22,
        name: t`Down`,
      },
    ],
  },
  mobiliftLeft: {
    type: 'option',
    fieldName: 'mobiliftLeft',
    label: () => t`Mobilift left`,
    options: () => [
      {
        id: 0,
        name: t`No button pressed`,
      },
      {
        id: 20,
        name: t`Up`,
      },
      {
        id: 22,
        name: t`Down`,
      },
    ],
  },
  bedExtension: {
    type: 'boolean',
    fieldName: 'bedExtension',
    label: () => t`Bed extension`,
  },
} as const;
