import { useQuery } from '@apollo/client';
import { add, startOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { sessionData } from 'src/graphql/queries';
import { SessionDataQuery, SessionDataQueryVariables } from 'src/graphql/types';
import { notEmpty } from 'src/lib/notEmpty';
import { getPatientViewTimelineData } from '../helpers/export';
import {
  getGraphRangeTexts,
  getPatientData,
  getPatientViewTrans,
  GraphType,
  PatientDataType,
} from '../helpers/patientView';
import { svgToPng } from '../helpers/svgToPng';

interface Props {
  date?: Date;
  sessionId?: string;
}

export const usePatientViewData = ({ date, sessionId }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const { data: viewData } = useQuery<
    SessionDataQuery,
    SessionDataQueryVariables
  >(sessionData, {
    skip: !date || !sessionId,
    variables: {
      id: sessionId || '',
      date: date ? new Date(date).toISOString() : null,
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  const [viewPatientData, setViewPatientData] = useState<PatientDataType[]>([]);
  useEffect(() => {
    if (!viewData) {
      setLoaded(false);
      return;
    }
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
    setViewPatientData(
      getPatientData(
        viewData.sessionData,
        viewData.me?.userSetting?.pwEndpointOrder,
      ),
    );
  }, [viewData]);

  const getViewPatientGraphData = useCallback(
    async (imageRefs: React.RefObject<Record<GraphType, any>>) => {
      const result = await Promise.all(
        viewPatientData.map(async (item) => {
          const imgSource = await svgToPng(
            imageRefs.current && imageRefs.current[item.type].container,
          );
          if (!imgSource) {
            return null;
          }
          return {
            img: imgSource,
            title: getPatientViewTrans(item.type),
            type: item.type,
            range: getGraphRangeTexts(item.type),
          };
        }),
      );
      return {
        data: result.filter(notEmpty),
        timelineDates: getPatientViewTimelineData(
          add(startOfDay(new Date()), { days: 1 }),
        ),
      };
    },
    [viewPatientData],
  );

  return {
    viewPatientData,
    getViewPatientGraphData,
    viewPatientLoaded: loaded,
  };
};
