import { Box } from '@mui/material';
import { useContext } from 'react';
import { BedMaybeHighligth } from '../../helpers/dashboard';
import MobileBed from './MobileBed/MobileBed';
import { DashboardSettingContext } from '../../Setting/DashboardSettingProvider';

interface Props {
  bedsList: BedMaybeHighligth[];
  refetchBeds: () => Promise<void>;
}
export const MobileBedlist = ({ bedsList, refetchBeds }: Props) => {
  const { userSetting } = useContext(DashboardSettingContext);
  const firstBedInAnotherWard = bedsList.findIndex((bed) => bed.inAnotherWard);
  return (
    <Box
      mx={2}
      boxShadow={(theme) => theme.shadows[3]}
      borderRadius="10px"
      overflow="hidden"
    >
      {bedsList.map((bed, idx) => (
        <MobileBed
          key={
            bed.unitId ? `bedUnitId-${bed.unitId}` : `location-${bed.location}`
          }
          singleLineLayout={userSetting.singleLineLayout}
          refetchBeds={refetchBeds}
          order={userSetting.selectedDashboardOrder}
          anonymized={userSetting.anonymized}
          bed={bed}
          alertFrom={bed.startedAt}
          withDivider={firstBedInAnotherWard === idx}
        />
      ))}
    </Box>
  );
};
