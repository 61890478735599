import React, { useState } from 'react';

interface MobileDashboardProviderType {
  pinningEnabled: boolean;
  searchQuery: string | undefined;
  setSearchQuery: (value: string) => void;
  setPinningEnabled: (value: boolean) => void;
}

interface Props {
  children?: React.ReactNode;
}

export const MobileDashboardContext =
  React.createContext<MobileDashboardProviderType>({
    pinningEnabled: false,
    searchQuery: undefined,
    setPinningEnabled: () => {},
    setSearchQuery: () => {},
  });

const MOBILE_SESSION_STORAGE_PINNING = 'MOB_SESSION_PINNIG';
const MOBILE_SESSION_STORAGE_SEARCH = 'MOB_SESSION_SEARCH';

const resolveBoolean = (val: string) => {
  return val.toLocaleLowerCase() === 'true';
};
const resolveString = (val: string) => val;

const getSessionStorageValue = <T,>(
  key: string,
  resovleValue: (val: string) => T,
) => {
  const item = sessionStorage.getItem(key);
  return resovleValue(item || '');
};

const setSessionStorageValue = (key: string, value: string) => {
  if (value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    sessionStorage.removeItem(key);
  }
};

const MobileDashboardProvider = ({ children }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>(
    getSessionStorageValue(MOBILE_SESSION_STORAGE_SEARCH, resolveString),
  );
  const [pinningEnabled, setPinningEnabled] = useState(
    getSessionStorageValue(MOBILE_SESSION_STORAGE_SEARCH, resolveBoolean),
  );

  const handleSetSearchQuery = (value: string | undefined) => {
    setSessionStorageValue(MOBILE_SESSION_STORAGE_SEARCH, value || '');
    setSearchQuery(value || '');
  };

  const handleSetPinningEnabled = (value: boolean) => {
    setSessionStorageValue(MOBILE_SESSION_STORAGE_PINNING, String(value));
    setPinningEnabled(value);
  };

  const context = {
    searchQuery,
    pinningEnabled,
    setPinningEnabled: handleSetPinningEnabled,
    setSearchQuery: handleSetSearchQuery,
  };

  return (
    <MobileDashboardContext.Provider value={context}>
      {children}
    </MobileDashboardContext.Provider>
  );
};
export default MobileDashboardProvider;
