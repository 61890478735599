import { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import {
  AddCareNoteMutation,
  AddCareNoteMutationVariables,
} from 'src/graphql/types';
import { addCareNoteMutation } from 'src/graphql/mutations';
import { patientCareNotes } from 'src/graphql/queries';
import { parseDate, timeIsValid } from 'src/helpers';
import DateTimeInput from 'src/components/DateTimeInput';

interface CaraNoteProps {
  time: Date;
  note: string;
}

interface Props {
  patientSessionId: string;
  sessionStart?: string | null;
}

const MobileAddCareNote = ({ patientSessionId, sessionStart }: Props) => {
  const [addNote] = useMutation<
    AddCareNoteMutation,
    AddCareNoteMutationVariables
  >(addCareNoteMutation);
  const [newCareNote, setNewCareNote] = useState<CaraNoteProps>({
    time: new Date(),
    note: '',
  });

  const addNoteHandler = () => {
    addNote({
      variables: {
        sessionId: patientSessionId,
        note: newCareNote.note,
        date: new Date(newCareNote.time).toISOString(),
      },
      refetchQueries: [
        {
          query: patientCareNotes,
          variables: {
            id: patientSessionId,
          },
        },
      ],
    });
    setNewCareNote({ time: new Date(), note: '' });
  };

  const setCareNoteDate = (date: Date) => {
    const currentTime = new Date();
    if (timeIsValid(date)) {
      if (date <= currentTime) {
        setNewCareNote((prevState) => ({ ...prevState, time: date }));
      } else {
        setNewCareNote((prevState) => ({ ...prevState, time: currentTime }));
      }
    }
  };

  return (
    <Box mb={2} display="flex">
      <Box
        component="form"
        sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}
      >
        <DateTimeInput
          label={t`Date`}
          value={newCareNote.time}
          max={new Date()}
          min={parseDate(sessionStart)}
          onChange={setCareNoteDate}
          fullWidth
          size="small"
          variant="outlined"
        />
        <TextField
          label={t`Note`}
          variant="outlined"
          size="small"
          sx={{ my: 2 }}
          fullWidth
          onChange={({ target }) =>
            setNewCareNote((prevState) => ({
              ...prevState,
              note: target.value,
            }))
          }
          value={newCareNote.note}
        />
        <Button fullWidth onClick={addNoteHandler} variant="contained">
          <Trans>Add care note</Trans>
        </Button>
      </Box>
    </Box>
  );
};

export default MobileAddCareNote;
