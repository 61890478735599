import { t } from '@lingui/macro';
import { ModalType } from './MobileModalProvider';

export const getMobileOverlayTitle = (type: ModalType) => {
  switch (type) {
    case 'settings':
      return t`Settings`;
    case 'export':
      return t`Export`;
    case 'insight':
      return t`Insight`;
    case 'notes':
      return t`Notes`;
    case 'datapoints':
      return t`Datapoints`;
    default:
      return null;
  }
};
