import {
  Dialog,
  Box,
  Button,
  TextField,
  Typography,
  styled,
  DialogActions,
} from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { useFormik } from 'formik';
import { useLingui } from '@lingui/react';
import { createSessionName } from './helpers';

export interface StartSessionProps {
  name: string;
  note: string;
  start: Date | null;
}
interface StartSessionFormProps {
  firstName: string;
  lastName: string;
  note: string;
}

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSessionStart: (props: StartSessionProps) => Promise<void>;
  stretch?: boolean;
}

const NewSessionDialog = ({
  open,
  setOpen,
  onSessionStart,
  stretch,
}: Props) => {
  const { i18n } = useLingui();
  const formik = useFormik<StartSessionFormProps>({
    initialValues: {
      firstName: '',
      lastName: '',
      note: '',
    },
    onSubmit: async (values) => {
      if (!values.firstName) {
        return;
      }

      await onSessionStart({
        name: createSessionName(values.firstName, values.lastName),
        note: values.note,
        start: new Date(),
      });
      formik.resetForm();
      setOpen(false);
    },
  });
  const startDate = new Date();

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="new-session-dialog"
      open={open}
      PaperProps={
        stretch
          ? {
              sx: {
                width: '100%',
              },
            }
          : {
              sx: {
                minWidth: '360px',
              },
            }
      }
    >
      <Box p={2}>
        <HeaderText>{t`TIME`}</HeaderText>
        <form onSubmit={formik.handleSubmit}>
          <Typography>
            {i18n.date(startDate, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </Typography>
          <HeaderText>{t`Details`}</HeaderText>
          <Box
            sx={(theme) => ({
              color: theme.palette.error.main,
              maxWidth: '480px',
              mb: 2,
            })}
          >
            <Trans>
              SafetyPort system is not intended, nor secured, to store sensitive
              patient data. No patient data should be included in the text
              fields.
            </Trans>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
            }}
          >
            <TextField
              label={t`Initials`}
              name="firstName"
              size="small"
              sx={{
                width: '10ch',
              }}
              inputProps={{
                maxLength: 1,
              }}
              variant="outlined"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              required
            />
            <Box
              sx={{
                ml: 0.25,
                mr: 1,
              }}
            >
              .
            </Box>
            <TextField
              name="lastName"
              size="small"
              sx={{
                width: '10ch',
              }}
              inputProps={{
                maxLength: 1,
              }}
              variant="outlined"
              value={formik.values.lastName}
              onChange={formik.handleChange}
            />
            <Box
              sx={{
                ml: 0.25,
                mr: 1,
              }}
            >
              .
            </Box>
          </Box>
          <TextField
            label={t`Note`}
            name="note"
            size="small"
            fullWidth
            variant="outlined"
            value={formik.values.note}
            onChange={formik.handleChange}
            sx={{ margin: '0.5rem 0' }}
          />
          <DialogActions
            sx={{
              mt: 1,
              display: 'flex',
              gap: 2,
              padding: 0,
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{ flex: stretch ? 1 : undefined, whiteSpace: 'nowrap' }}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button
              sx={{ flex: stretch ? 1 : undefined, whiteSpace: 'nowrap' }}
              variant="contained"
              type="submit"
            >
              <Trans>Start Session</Trans>
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default NewSessionDialog;

const HeaderText = styled(Typography)`
  margin: 1rem 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.secondary};
`;
