import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import {
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { cs, fr, de, es, ptBR } from 'date-fns/locale';

const locales = { cs, fr, de, es, 'pt-br': ptBR };

export enum DateOptionPreset {
  now = 'now',
  today = 'today',
  yesterday = 'yesterday',

  last24Hours = 'last24Hours',
  last7Days = 'last7Days',
  last14Days = 'last14Days',

  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  thisQuarter = 'thisQuarter',
  lastQuarter = 'lastQuarter',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
}

export const mapDateIntervalsToTranslates = () => ({
  [DateOptionPreset.now]: t`Now`,
  [DateOptionPreset.today]: t`Today`,
  [DateOptionPreset.yesterday]: t`Yesterday`,
  [DateOptionPreset.last24Hours]: t`Last 24 Hours`,
  [DateOptionPreset.last7Days]: t`Last 7 Days`,
  [DateOptionPreset.last14Days]: t`Last 14 Days`,
  [DateOptionPreset.thisWeek]: t`This week`,
  [DateOptionPreset.lastWeek]: t`Last week`,
  [DateOptionPreset.thisMonth]: t`This month`,
  [DateOptionPreset.lastMonth]: t`Last month`,
  [DateOptionPreset.thisQuarter]: t`This quarter`,
  [DateOptionPreset.lastQuarter]: t`Last querter`,
  [DateOptionPreset.thisYear]: t`This year`,
  [DateOptionPreset.lastYear]: t`Last year`,
});

interface DateOptions {
  from: Date;
  to: Date;
}

export const getDatesByPreset = (
  preset: DateOptionPreset,
  i18n: I18n,
): DateOptions => {
  const now = new Date();
  const locale = locales[i18n.locale];
  const todayEnd = endOfToday();
  switch (preset) {
    case DateOptionPreset.now:
      return {
        from: startOfYesterday(),
        to: endOfToday(),
      };
    case DateOptionPreset.today:
      return {
        from: startOfToday(),
        to: endOfToday(),
      };
    case DateOptionPreset.yesterday:
      return {
        from: startOfYesterday(),
        to: endOfYesterday(),
      };
    case DateOptionPreset.last24Hours:
      return {
        from: subDays(now, 1),
        to: now,
      };
    case DateOptionPreset.last7Days:
      return {
        from: subDays(todayEnd, 7),
        to: todayEnd,
      };
    case DateOptionPreset.last14Days:
      return {
        from: subDays(todayEnd, 14),
        to: todayEnd,
      };
    case DateOptionPreset.thisWeek:
      return {
        from: startOfWeek(now, { locale }),
        to: endOfWeek(now, { locale }),
      };
    case DateOptionPreset.lastWeek:
      const lastWeek = subDays(now, 7);
      return {
        from: startOfWeek(lastWeek, { locale }),
        to: endOfWeek(lastWeek, { locale }),
      };
    case DateOptionPreset.thisMonth:
      return {
        from: startOfMonth(now),
        to: endOfMonth(now),
      };
    case DateOptionPreset.lastMonth:
      return {
        from: startOfMonth(subMonths(now, 1)),
        to: endOfMonth(subMonths(now, 1)),
      };
    case DateOptionPreset.thisQuarter:
      return {
        from: startOfQuarter(now),
        to: endOfQuarter(now),
      };
    case DateOptionPreset.lastQuarter:
      return {
        from: startOfQuarter(subMonths(now, 3)),
        to: endOfQuarter(subMonths(now, 3)),
      };
    case DateOptionPreset.thisYear:
      return {
        from: startOfYear(now),
        to: endOfYear(now),
      };
    case DateOptionPreset.lastYear:
      return {
        from: startOfYear(subYears(now, 1)),
        to: endOfYear(subYears(now, 1)),
      };
  }
};
