import { Trans } from '@lingui/macro';
import { ReactNode } from 'react';
import { SvDashboardSortingType } from '../../Setting/DashboardSettingProvider';
import { BedExitIcon } from './BedExitIcon';
import { BedExitMonitoring } from './BedExitMonitoring';
import { ErrorIcon } from './ErrorIcon';
import { OkIcon } from './OkIcon';
import { Box } from '@mui/material';
import { ReactComponent as Lock } from 'src/images/safetyView/dashboard/lock.svg';
import { ReactComponent as Unlock } from 'src/images/safetyView/dashboard/unlock.svg';
import { BatteryStatus } from './BatteryStatus';
import { ReactComponent as Plugg } from 'src/images/safetyView/dashboard/plugg.svg';
import { ReactComponent as Unplugg } from 'src/images/safetyView/dashboard/unplugg.svg';
import { Mobilift } from '../../Dashboard/DashboardDatapoint';
import { Charging } from './Charging';

type InfoDatapointType =
  | 'outOfBedStatus'
  | 'brakes'
  | 'posIsLowest'
  | 'bedExitMonitoring'
  | 'safetyPosition'
  | 'footLockControl'
  | 'transfer'
  | 'bedExtension'
  | 'mobiliftRight'
  | 'mobiliftLeft'
  | 'charging'
  | 'batteryFailure'
  | 'powerStatus';

export interface DatapointInfo {
  title: ReactNode;
  text: ReactNode;
  options?: DatapointInfoOption[];
}

export interface DatapointInfoOption {
  icon: ReactNode;
  text: ReactNode;
}

const mobiliftOptions: DatapointInfoOption[] = [
  {
    icon: (
      <Box width="6rem">
        <Mobilift mobiliftStatus={20} />
      </Box>
    ),
    text: (
      <Trans id="dashboardInfo.option.mobilift.up">
        Mobi-Lift is activated, hight up
      </Trans>
    ),
  },
  {
    icon: (
      <Box width="6rem">
        <Mobilift mobiliftStatus={22} />
      </Box>
    ),
    text: (
      <Trans id="dashboardInfo.option.mobilift.down">
        Mobi-Lift is activated, hight down
      </Trans>
    ),
  },
  {
    icon: (
      <Box width="6rem">
        <Mobilift mobiliftStatus={0} />
      </Box>
    ),
    text: (
      <Trans id="dashboardInfo.option.mobiliftRight.off">
        Mobi-Lift is not activated
      </Trans>
    ),
  },
];

const infoOptions: Record<InfoDatapointType, DatapointInfoOption[]> = {
  outOfBedStatus: [
    {
      icon: (
        <Box width="3rem">
          <BedExitIcon state="on" patientInBed />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.outOfBedStatus.on">
          Patient is in bed. Bed exit monitoriing is On.
        </Trans>
      ),
    },
    {
      icon: (
        <Box width="3rem">
          <BedExitIcon state="off" patientInBed />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.outOfBedStatus.inBedOff">
          Patient is in bed. Bed exit monitoriing is Off.
        </Trans>
      ),
    },
    {
      icon: (
        <Box width="3rem">
          <BedExitIcon state="warning" patientInBed />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.outOfBedStatus.warning">
          Patient is in bed. Bed exit monitoriing is Off. Patient with high fall
          risk.
        </Trans>
      ),
    },
    {
      icon: (
        <Box width="3rem">
          <BedExitIcon state="error" />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.outOfBedStatus.alert">
          Patient is out of bed. Bed exit monitoring is On
        </Trans>
      ),
    },
    {
      icon: (
        <Box width="3rem">
          <BedExitIcon state="off" />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.outOfBedStatus.outOfBedOff">
          Patient is out of bed. Bed exit monitoring is Off.
        </Trans>
      ),
    },
  ],
  bedExitMonitoring: [
    {
      icon: (
        <Box minWidth="6rem">
          <BedExitMonitoring bedExitOn isZoneExit />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.bedExitMonitoring.outer">
          Outer bed exit monitoring is active.
        </Trans>
      ),
    },
    {
      icon: (
        <Box minWidth="6rem">
          <BedExitMonitoring bedExitOn />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.bedExitMonitoring.inner">
          Inner bed exit monitoring is active.
        </Trans>
      ),
    },
    {
      icon: (
        <Box minWidth="6rem">
          <BedExitMonitoring showFallRiskAlert />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.bedExitMonitoring.fallRiskOff">
          Bed exit not activated and patient is High Fall risk.
        </Trans>
      ),
    },
    {
      icon: (
        <Box minWidth="6rem">
          <BedExitMonitoring />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.bedExitMonitoring.off">
          Bed exit not activated and patient is Low Fall risk.
        </Trans>
      ),
    },
  ],
  brakes: [
    {
      icon: <OkIcon sx={{ mr: 1 }} />,
      text: <Trans id="dashboardInfo.option.brakes.ok">Bed is braked.</Trans>,
    },
    {
      icon: <ErrorIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.brakes.alert">Brakes not active.</Trans>
      ),
    },
  ],
  posIsLowest: [
    {
      icon: <OkIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.posIsLowest.ok">
          Bed frame is in the lowest position.
        </Trans>
      ),
    },
    {
      icon: <ErrorIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.posIsLowest.alert">
          Not in the lowest position.
        </Trans>
      ),
    },
  ],
  safetyPosition: [
    {
      icon: <OkIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.safetyPosition.ok">
          Bed is in a safe position.
        </Trans>
      ),
    },
    {
      icon: <ErrorIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.safetyPosition.alert">
          Not in a safe position.
        </Trans>
      ),
    },
  ],
  footLockControl: [
    {
      icon: (
        <Lock
          height="1rem"
          style={{ marginLeft: '7px', marginRight: '10px' }}
        />
      ),
      text: (
        <Trans id="dashboardInfo.option.footLockControl.locked">
          Foot control lock activated.
        </Trans>
      ),
    },
    {
      icon: <Unlock height="1.5rem" />,
      text: (
        <Trans id="dashboardInfo.option.footLockControl.unlocked">
          Foot control lock is not activated.
        </Trans>
      ),
    },
  ],
  transfer: [
    {
      icon: <OkIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.transfer.on">
          Transfer mode activated
        </Trans>
      ),
    },
    {
      icon: <ErrorIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.transfer.off">
          Transfer mode not activated
        </Trans>
      ),
    },
  ],
  bedExtension: [
    {
      icon: <OkIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.bedExtension.on">Bed extended</Trans>
      ),
    },
    {
      icon: <ErrorIcon sx={{ mr: 1 }} />,
      text: (
        <Trans id="dashboardInfo.option.bedExtension.off">
          Default lenght of the bed
        </Trans>
      ),
    },
  ],
  mobiliftRight: mobiliftOptions,
  mobiliftLeft: mobiliftOptions,
  charging: [
    {
      icon: (
        <Box width="3rem">
          <Charging charging={true} />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.charging.on">
          Bed battery is charging
        </Trans>
      ),
    },
    {
      icon: (
        <Box width="3rem">
          <Charging charging={false} />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.charging.off">
          Bed battery is not charging
        </Trans>
      ),
    },
  ],
  powerStatus: [
    {
      icon: (
        <Box width="3rem">
          <Plugg />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.powerStatus.plugged">
          Bed is plugged into outlet
        </Trans>
      ),
    },
    {
      icon: (
        <Box width="3rem">
          <Unplugg />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.powerStatus.unplugged">
          Bed is not plugged into outlet
        </Trans>
      ),
    },
  ],
  batteryFailure: [
    {
      icon: (
        <Box width="3rem">
          <BatteryStatus status={false} />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.batteryFailure.ok">Battery is OK</Trans>
      ),
    },
    {
      icon: (
        <Box width="3rem">
          <BatteryStatus status={true} />
        </Box>
      ),
      text: (
        <Trans id="dashboardInfo.option.batteryFailure.warning">
          Battery absence or failure condition
        </Trans>
      ),
    },
  ],
};

export const infoTexts: Record<SvDashboardSortingType, DatapointInfo> = {
  name: {
    title: '',
    text: '',
  }, // datapoint not displayed in datapoint settings
  location: {
    title: '',
    text: '',
  }, // datapoint not displayed in datapoint settings
  outOfBed: {
    title: (
      <Trans id="dashboardInfo.title.outOfBedStatus">Out Of Bed Status</Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.outOfBedStatus">
        Sensor monitors patient's presence in the bed. It can have five possible
        states:
      </Trans>
    ),
    options: infoOptions.outOfBedStatus,
  },
  brakes: {
    title: <Trans id="dashboardInfo.title.brakes">Brakes</Trans>,
    text: (
      <Trans id="dashboardInfo.text.brakes">
        Reduces risk of injuries related to an unsecured bed.
      </Trans>
    ),
    options: infoOptions.brakes,
  },
  lowestPosition: {
    title: <Trans id="dashboardInfo.title.posIsLowest">Lowest Position</Trans>,
    text: (
      <Trans id="dashboardInfo.text.posIsLowest">
        Reduction of possible fall consequences.
      </Trans>
    ),
    options: infoOptions.posIsLowest,
  },
  bedExitMonitoringStatus: {
    title: (
      <Trans id="dashboardInfo.title.bedExitMonitoring">
        Bed Exit Monitoring
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.bedExitMonitoring">
        Monitors if the bed exit alarm is active.{' '}
      </Trans>
    ),
    options: infoOptions.bedExitMonitoring,
  },
  spAndLock: {
    title: (
      <Trans id="dashboardInfo.title.safetyPosition">Safety Position</Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.safetyPosition">
        The inbuilt sensors constantly monitor the bed position to ensure the
        patient's safety.
      </Trans>
    ),
    options: infoOptions.safetyPosition,
  },
  thighrestLock: {
    title: (
      <Trans id="dashboardInfo.title.footLockControl">Foot Lock Control</Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.footLockControl">
        Contributes to patient's safety and prevents incidental movements.
      </Trans>
    ),
    options: infoOptions.footLockControl,
  },
  backrestAndLock: {
    title: <Trans id="dashboardInfo.title.backrestAngle">Backrest Angle</Trans>,
    text: (
      <Trans id="dashboardInfo.text.backrestAngle">
        Contribution to the patient's comfort. Angle between floor and back rest
        between -15 and +45.
      </Trans>
    ),
  },
  calfrestAngleAndLock: {
    title: <Trans id="dashboardInfo.title.calfrestAngle">Calfrest Angle</Trans>,
    text: (
      <Trans id="dashboardInfo.text.calfrestAngle">
        Helps you to identify and document bed position in a given moment. Angle
        between calfrest and ground between -60 - +40.
      </Trans>
    ),
  },
  bedName: {
    title: <Trans id="dashboardInfo.title.bedName">Bed Name</Trans>,
    text: (
      <Trans id="dashboardInfo.text.bedName">
        Provides bed identification.
      </Trans>
    ),
  },
  bedType: {
    title: <Trans id="dashboardInfo.title.bedType">Bed Type</Trans>,
    text: (
      <Trans id="dashboardInfo.text.bedType">
        Identifies type of bed (e.g. E4, E5).
      </Trans>
    ),
  },
  laterailTiltAndAlt: {
    title: <Trans id="dashboardInfo.title.lateralTilt">Lateral Tilt</Trans>,
    text: (
      <Trans id="dashboardInfo.text.lateralTilt">
        Can help accelerate recovery and to advance patient mobility. Degrees
        between -30 and +30. When the value is below 0 the bed's tilt is on the
        left side.
      </Trans>
    ),
  },

  mattressType: {
    title: (
      <Trans id="dashboardInfo.title.integratedMattress">
        Integrated Mattress
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.integratedMattress">
        Identifies whether an active mattress is connected or not and if so,
        which type is being used at the moment.
      </Trans>
    ),
  },

  trendelenburgAngle: {
    title: (
      <Trans id="dashboardInfo.title.trendelenburgAngle">
        Trendelenburg Angle
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.trendelenburgAngle">
        Position used in emergency situations to prevent or decrease the risk of
        air embolism during insertion of a central venous catheter. Degrees
        between -13 and +16. When the value is below 0, the foot part is higher
        than the head part.
      </Trans>
    ),
  },
  sessionStart: {
    title: (
      <Trans id="dashboardInfo.title.sessionStarted">Session Started</Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.sessionStarted">
        Date when the patient's stay started.
      </Trans>
    ),
  },
  sessionId: {
    title: <Trans id="dashboardInfo.title.sessionId">Session ID</Trans>,
    text: (
      <Trans id="dashboardInfo.text.sessionId">
        Helps to identify the patient's stay.
      </Trans>
    ),
  },
  note: {
    title: <Trans id="dashboardInfo.title.note">Note</Trans>,
    text: (
      <Trans id="dashboardInfo.text.note">
        Information about care provided to the patient or his state.
      </Trans>
    ),
  },
  weight: {
    title: <Trans id="dashboardInfo.title.weight">Patient Weight</Trans>,
    text: (
      <Trans id="dashboardInfo.text.weight">
        Patient's weight based on primary display scale value, if the weight is
        stable.{' '}
      </Trans>
    ),
  },
  siderails: {
    title: <Trans id="dashboardInfo.title.siderails">Siderails</Trans>,
    text: (
      <Trans id="dashboardInfo.text.siderails">
        Enhances patient safety. Green color signals a siderail is up, grey that
        it is down.
      </Trans>
    ),
  },
  tagCharge: {
    title: <Trans id="dashboardInfo.title.tagCharge">Tag Charge</Trans>,
    text: (
      <Trans id="dashboardInfo.text.tagCharge">
        Tag charge status indication in %
      </Trans>
    ),
  },
  charging: {
    title: <Trans id="dashboardInfo.title.charging">Charging</Trans>,
    text: (
      <Trans id="dashboardInfo.text.charging">
        Indicates that bed battery is charging.
      </Trans>
    ),
    options: infoOptions.charging,
  },
  powerStatus: {
    title: <Trans id="dashboardInfo.title.power">Bed Power Status</Trans>,
    text: (
      <Trans id="dashboardInfo.text.power">Helps prevent battery damage.</Trans>
    ),
    options: infoOptions.powerStatus,
  },
  transfer: {
    title: <Trans id="dashboardInfo.title.transfer">Transfer</Trans>,
    text: (
      <Trans id="dashboardInfo.text.transfer">
        Patient trasfer setting contributes to patient safety by preventing
        potentially dangerous bed settings.
      </Trans>
    ),
    options: infoOptions.transfer,
  },
  heightOfRLegColumn: {
    title: (
      <Trans id="dashboardInfo.title.heightOfRLegColumn">
        Height of Right Leg Column
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.heightOfRLegColumn">
        Height of the column, the higher the number, the higher the bed is. Zero
        means the bed is in lowest position, the value of highest position
        varies depending on a specific bed.
      </Trans>
    ),
  },
  heightOfLLegColumn: {
    title: (
      <Trans id="dashboardInfo.title.heightOfLLegColumn">
        Height of Left Leg Column
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.heightOfLLegColumn">
        Height of the column, the higher the number, the higher the bed is. Zero
        means the bed is in lowest position, the value of highest position
        varies depending on a specific bed.
      </Trans>
    ),
  },
  heightOfHeadColumn: {
    title: (
      <Trans id="dashboardInfo.title.heightOfHeadColumn">
        Height of Head Column
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.heightOfHeadColumn">
        Height of the column, the higher the number, the higher the bed is. Zero
        means the bed is in lowest position, the value of highest position
        varies depending on a specific bed.
      </Trans>
    ),
  },
  mobiliftRight: {
    title: (
      <Trans id="dashboardInfo.title.mobiliftRight">Mobi-Lift Right</Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.mobiliftRight">
        Documents the use of Mobi-Lift support.
      </Trans>
    ),
    options: infoOptions.mobiliftRight,
  },
  mobiliftLeft: {
    title: <Trans id="dashboardInfo.title.mobiliftLeft">Mobi-Lift Left</Trans>,
    text: (
      <Trans id="dashboardInfo.text.mobiliftLeft">
        Documents the use of Mobi-Lift support.
      </Trans>
    ),
    options: infoOptions.mobiliftLeft,
  },
  bedExtension: {
    title: <Trans id="dashboardInfo.title.bedExtension">Bed Extension</Trans>,
    text: (
      <Trans id="dashboardInfo.text.bedExtension">
        Motorised bed extension helps to adjust the bed frame to patients
        individual needs.
      </Trans>
    ),
    options: infoOptions.bedExtension,
  },
  pressedBtnAndUsedController: {
    title: (
      <Trans id="dashboardInfo.title.pressedBtnAndUsedController">
        Function Pressed
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.pressedBtnAndUsedController">
        To know what function or feature is being used.
      </Trans>
    ),
  },
  rightLCDButtonPressed: {
    title: (
      <Trans id="dashboardInfo.title.rightLCDButtonPressed">
        Right LCD Controller
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.rightLCDButtonPressed">
        To know what function or feature is being used on a specific controller.
      </Trans>
    ),
  },
  leftLCDButtonPressed: {
    title: (
      <Trans id="dashboardInfo.title.leftLCDButtonPressed">
        Left LCD Controller
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.leftLCDButtonPressed">
        To know what function or featur is being used on a specific controller.
      </Trans>
    ),
  },
  legsControllerButtonPressed: {
    title: (
      <Trans id="dashboardInfo.title.legsControllerButtonPressed">
        Foot Controller
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.legsControllerButtonPressed">
        To know what function or featur is being used on a specific controller.
      </Trans>
    ),
  },
  acpAndRoControllerButtonPressed: {
    title: (
      <Trans id="dashboardInfo.title.acpAndRoControllerButtonPressed">
        Acp/Ro Controller
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.acpAndRoControllerButtonPressed">
        To know what function or feature is being used on a specific controller.
      </Trans>
    ),
  },
  digitalSiderailRightButtonPressed: {
    title: (
      <Trans id="dashboardInfo.title.digitalSiderailRightButtonPressed">
        Right Digital Siderail Controller
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.digitalSiderailRightButtonPressed">
        To know what function or feature is being used on a specific controller.
      </Trans>
    ),
  },
  digitalSiderailLeftButtonPressed: {
    title: (
      <Trans id="dashboardInfo.title.digitalSiderailLeftButtonPressed">
        Left Digital Siderail Controller
      </Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.digitalSiderailLeftButtonPressed">
        To know what function or feature is being used on a specific controller.
      </Trans>
    ),
  },
  ALTError: {
    title: <Trans id="dashboardInfo.title.ALTError">ALT Error</Trans>,
    text: (
      <Trans id="dashboardInfo.text.ALTError">
        Errors that occured during ALT.
      </Trans>
    ),
  },
  errorOrStopCode: {
    title: (
      <Trans id="dashboardInfo.title.errorOrStopCode">Error or Stop Code</Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.errorOrStopCode">
        Errors and warnings inform about the cause of bed malfunction indicating
        either improper usage or technical problems.
      </Trans>
    ),
  },
  batteryFailure: {
    title: (
      <Trans id="dashboardInfo.title.batteryFailure">Battery Failure</Trans>
    ),
    text: (
      <Trans id="dashboardInfo.text.batteryFailure">
        Identifies faulty or absent battery that might result in incorrect
        functioning of the bed.
      </Trans>
    ),
    options: infoOptions.batteryFailure,
  },
};
