import { t } from '@lingui/macro';
import { MenuItem, TextField } from '@mui/material';
import Flex from 'src/components/Flex';
import { LocationType, TimeUnitType } from 'src/graphql/types';
import { ReportFilterParams } from '../types';
import { UpdateFilterFunction } from './useReportFilterParams';
import useViewByOptions from './useViewByOptions';

interface Props {
  filter: ReportFilterParams;
  updateFilter: UpdateFilterFunction;
}

const ReportGraphViewBy = ({ filter, updateFilter }: Props) => {
  const { viewByLocationOptions, viewByTimeOptions } = useViewByOptions(filter);
  const handleViewByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter({ viewByLocation: e.target.value as LocationType });
  };

  const handleGroupByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter({
      viewByTime: { value: 1, unit: e.target.value as TimeUnitType },
    });
  };

  return (
    <Flex justifyContent="center" pt={2}>
      <TextField
        select
        value={filter.viewByLocation}
        label={t`View by`}
        variant="outlined"
        sx={{ ml: 2 }}
        inputProps={{ sx: { py: '8px' } }}
        onChange={handleViewByChange}
      >
        {viewByLocationOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      {filter.graphType !== 'pie' ? (
        <TextField
          select
          value={filter.viewByTime.unit}
          label={t`Group by`}
          variant="outlined"
          sx={{ ml: 2 }}
          inputProps={{ sx: { py: '8px' } }}
          onChange={handleGroupByChange}
        >
          {viewByTimeOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      ) : null}
    </Flex>
  );
};

export default ReportGraphViewBy;
