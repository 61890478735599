import { Dialog, Box, Typography, Button } from '@mui/material';
import { Trans } from '@lingui/macro';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CareNoteDeleteDialog = ({ open, setOpen, onDelete }: Props) => {
  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="discharge-dialog"
      open={open}
    >
      <Box padding="2rem">
        <Typography
          sx={{
            maxWidth: '350px',
            textAlign: 'center',
          }}
        >
          <Trans>Are you sure to delete this Care Note ?</Trans>
        </Typography>
        <Box mt={4} display="flex" justifyContent="space-between">
          <Button variant="outlined" onClick={() => setOpen(false)}>
            <Trans>Cancel</Trans>
          </Button>
          <Button variant="contained" onClick={onDelete}>
            <Trans>Confirm</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CareNoteDeleteDialog;
