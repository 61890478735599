import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  Image,
  Font,
} from '@react-pdf/renderer';
import { t, Trans } from '@lingui/macro';
import {
  exportMobilizationHeight,
  exportMobilizationWidth,
  MobilizationExportDataType,
} from '../helpers/export';
import altImg from 'src/images/safetyView/export/alt.png';
import matressImg from 'src/images/safetyView/export/matress.png';
import mobiliftImg from 'src/images/safetyView/export/mobilift.png';
import trendelenburgImg from 'src/images/safetyView/export/trendelenburg.png';
import lateralImg from 'src/images/safetyView/export/lateral.png';
import backrest30Img from 'src/images/safetyView/export/backrest30.png';
import backrest45Img from 'src/images/safetyView/export/backrest45.png';
import { format } from 'date-fns';
import { dateTimePickerFormat } from 'src/helpers';

interface Props {
  graphs: MobilizationExportDataType;
  sessionName: string | null;
  sessionId: string;
  exportedTime: Date;
  exportedDay: Date;
}

Font.register({
  family: 'GTAmerica',
  src: require('../../../font/GT-America-Standard-Regular.otf').default,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    padding: '16px 4px',
    fontFamily: 'GTAmerica',
  },
  content: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    alignContent: 'flex-end',
  },
  graphsBox: {
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    right: -30,
  },
  titleBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryContainer: {
    width: '100%',
    marginTop: 16,
    display: 'flex',
    minHeight: 80,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: 8,
    position: 'relative',
    right: -10,
    padding: 8,
    backgroundColor: '#DAE3F3',
  },
  summaryBox: {
    width: 80,
    textAlign: 'center',
  },
  summaryText: {
    fontSize: 6,
    textAlign: 'left',
  },
  noDataText: {
    fontSize: 6,
    fontWeight: 500,
    textAlign: 'center',
  },
  linesBox: {
    position: 'absolute',
    top: 0,
    right: 20,
    width: exportMobilizationWidth * 7 - 5,
  },
  timelineContainer: {
    position: 'absolute',
    left: -25,
    top: 4,
    width: 100,
  },
  timelineText: {
    position: 'relative',
    top: -7,
    fontSize: 6,
  },
  daysBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    right: -30,
    marginBottom: 16,
  },
  dayBox: {
    width: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 16,
  },
  legendBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  legendImg: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
  legendText: {
    fontSize: 8,
  },
});

const MobilizationExport = ({
  graphs,
  sessionName,
  sessionId,
  exportedTime,
  exportedDay,
}: Props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text
            style={{
              fontSize: 8,
              textAlign: 'right',
              marginRight: 8,
              marginBottom: 8,
            }}
          >
            {`${t`Session ID`}: ${sessionId}, ${t`Session name`}: ${sessionName}, ${t`Export day`}: ${format(
              exportedDay,
              'MM/dd/yyyy',
            )}, ${t`Export time`}: ${format(
              exportedTime,
              dateTimePickerFormat,
            )}`}
          </Text>
          <Text style={{ textAlign: 'center', marginBottom: 48 }}>
            <Trans>Mobilization</Trans>
          </Text>
          <View style={styles.content}>
            <View style={styles.legendContainer}>
              <View style={styles.legendBox}>
                <Image style={styles.legendImg} source={altImg} />
                <Text style={styles.legendText}>
                  <Trans>ALT</Trans>
                </Text>
              </View>
              <View style={styles.legendBox}>
                <Image style={styles.legendImg} source={matressImg} />
                <Text style={styles.legendText}>
                  <Trans>Matress</Trans>
                </Text>
              </View>
              <View style={styles.legendBox}>
                <Image style={styles.legendImg} source={mobiliftImg} />
                <Text style={styles.legendText}>
                  <Trans>Mobi-Lift</Trans>
                </Text>
              </View>
              <View style={styles.legendBox}>
                <Image style={styles.legendImg} source={trendelenburgImg} />
                <Text style={styles.legendText}>
                  <Trans>Trendelenburg</Trans>
                </Text>
              </View>
              <View style={styles.legendBox}>
                <Image style={styles.legendImg} source={lateralImg} />
                <Text style={styles.legendText}>
                  <Trans>Lateral</Trans>
                </Text>
              </View>
              <View style={styles.legendBox}>
                <Image style={styles.legendImg} source={backrest30Img} />
                <Text style={styles.legendText}>
                  <Trans>Backrest 30°</Trans>
                </Text>
              </View>
              <View style={styles.legendBox}>
                <Image style={styles.legendImg} source={backrest45Img} />
                <Text style={styles.legendText}>
                  <Trans>Backrest 45°</Trans>
                </Text>
              </View>
            </View>
            <View style={styles.daysBox}>
              {graphs?.days?.map((day) => (
                <View key={`day-label-${day.day}`} style={styles.dayBox}>
                  <Text style={{ fontSize: 8 }}>{day.day}</Text>
                </View>
              ))}
            </View>
            <View style={styles.graphsBox}>
              <MobilizationLines />
              <MobilizationTimeline dates={graphs.timeline} />
              {graphs?.days.map((day) => (
                <View key={`mobilization-export-item-${day.day}`}>
                  <Image
                    style={{
                      height: exportMobilizationHeight,
                      width: exportMobilizationWidth,
                    }}
                    source={day.img}
                  />
                </View>
              ))}
            </View>
            <View style={styles.summaryContainer}>
              {graphs?.summaryData?.map((day, index) => (
                <View style={styles.summaryBox} key={`summaryDay-${index}`}>
                  {day.length > 0 ? (
                    day?.map((data, index) => (
                      <Text
                        wrap={false}
                        key={`summaryValue-${index}`}
                        style={styles.summaryText}
                      >
                        {data.time}
                        {` ${data.text}`}
                      </Text>
                    ))
                  ) : (
                    <Text style={styles.noDataText}>
                      <Trans>No Data</Trans>
                    </Text>
                  )}
                </View>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MobilizationExport;

const MobilizationLines = () => {
  return (
    <View style={styles.linesBox}>
      {new Array(25).fill(0).map((_, idx) => (
        <View
          key={`line-${idx}`}
          style={{ height: exportMobilizationHeight / 24 }}
        >
          <View
            style={{
              backgroundColor: '#000',
              height: 0.5,
              width: exportMobilizationWidth * 7 - 5,
            }}
          />
        </View>
      ))}
    </View>
  );
};

interface MobilizationTimelineProps {
  dates: string[];
}

const MobilizationTimeline = ({ dates }: MobilizationTimelineProps) => {
  return (
    <View style={styles.timelineContainer}>
      {dates.map((item, idx) => (
        <View
          key={`timeline-item-${idx}`}
          style={{ height: exportMobilizationHeight / 24 }}
        >
          <Text style={styles.timelineText}>{item}</Text>
        </View>
      ))}
    </View>
  );
};
