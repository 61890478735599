interface Props {
  content: any;
}

export const TabletTiles = ({ content }: Props) => {
  return (
    <div>
      <img
        alt="Tablet"
        style={{ width: 280 }}
        src={require('src/images/tablet.png').default}
      />
      <div style={styles.content}>{content}</div>
    </div>
  );
};

const styles = {
  content: {
    position: 'absolute' as 'absolute',
    top: 15,
    left: 26,
    width: 209,
    height: 126,
    padding: 8,
  },
};
