import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import {
  getDatapointColor,
  getDatapointSelectorItemText,
  SelectedDatapointsEnum,
  SelectedDatapointsType,
} from '../../helpers/mobilization';
import { ReactComponent as CheckedIcon } from 'src/images/safetyView/dashboard/checked.svg';
import { ReactComponent as UncheckedIcon } from 'src/images/safetyView/dashboard/unchecked.svg';

interface Props {
  selectedDatapoints: SelectedDatapointsType;
  setSelectedDatapoints: React.Dispatch<
    React.SetStateAction<SelectedDatapointsType>
  >;
}

const DataPointsSelector = ({
  selectedDatapoints,
  setSelectedDatapoints,
}: Props) => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-around"
      alignItems="end"
    >
      <DatapointsSelectorItem
        value={selectedDatapoints.alt}
        setSelectedDatapoints={setSelectedDatapoints}
        type={SelectedDatapointsEnum.alt}
      />
      <DatapointsSelectorItem
        value={selectedDatapoints.matress}
        setSelectedDatapoints={setSelectedDatapoints}
        type={SelectedDatapointsEnum.matress}
      />
      <DatapointsSelectorItem
        value={selectedDatapoints.mobilift}
        setSelectedDatapoints={setSelectedDatapoints}
        type={SelectedDatapointsEnum.mobilift}
      />
      <DatapointsSelectorItem
        value={selectedDatapoints.trendelenburg}
        setSelectedDatapoints={setSelectedDatapoints}
        type={SelectedDatapointsEnum.trendelenburg}
      />
      <DatapointsSelectorItem
        value={selectedDatapoints.lateral}
        setSelectedDatapoints={setSelectedDatapoints}
        type={SelectedDatapointsEnum.lateral}
      />
      <DatapointsSelectorItem
        value={selectedDatapoints.backrest30}
        setSelectedDatapoints={setSelectedDatapoints}
        type={SelectedDatapointsEnum.backrest30}
      />
      <DatapointsSelectorItem
        value={selectedDatapoints.backrest45}
        setSelectedDatapoints={setSelectedDatapoints}
        type={SelectedDatapointsEnum.backrest45}
      />
    </Box>
  );
};

export default DataPointsSelector;

interface DatapointsSelectorItemPops {
  value: boolean;
  setSelectedDatapoints: React.Dispatch<
    React.SetStateAction<SelectedDatapointsType>
  >;
  type: SelectedDatapointsEnum;
}

const DatapointsSelectorItem = ({
  value,
  setSelectedDatapoints,
  type,
}: DatapointsSelectorItemPops) => {
  const setDataPoints = (type: SelectedDatapointsEnum, checked: boolean) => {
    switch (type) {
      case SelectedDatapointsEnum.alt:
        setSelectedDatapoints((prevState) => ({ ...prevState, alt: checked }));
        break;
      case SelectedDatapointsEnum.matress:
        setSelectedDatapoints((prevState) => ({
          ...prevState,
          matress: checked,
        }));
        break;
      case SelectedDatapointsEnum.mobilift:
        setSelectedDatapoints((prevState) => ({
          ...prevState,
          mobilift: checked,
        }));
        break;
      case SelectedDatapointsEnum.trendelenburg:
        setSelectedDatapoints((prevState) => ({
          ...prevState,
          trendelenburg: checked,
        }));
        break;
      case SelectedDatapointsEnum.lateral:
        setSelectedDatapoints((prevState) => ({
          ...prevState,
          lateral: checked,
        }));
        break;
      case SelectedDatapointsEnum.backrest30:
        setSelectedDatapoints((prevState) => ({
          ...prevState,
          backrest30: checked,
        }));
        break;
      case SelectedDatapointsEnum.backrest45:
        setSelectedDatapoints((prevState) => ({
          ...prevState,
          backrest45: checked,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          checkedIcon={<CheckedIcon color={getDatapointColor(type)} />}
          icon={<UncheckedIcon color={getDatapointColor(type)} />}
          onChange={(_, checked) => setDataPoints(type, checked)}
        />
      }
      label={
        <Typography color="textSecondary" sx={{ fontSize: '0.875rem' }}>
          {getDatapointSelectorItemText(type)}
        </Typography>
      }
    />
  );
};
