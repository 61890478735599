import { t } from '@lingui/macro';
import {
  Card,
  CardContent,
  CardProps,
  Divider,
  styled,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import Flex from 'src/components/Flex';
import {
  ReportAggregation,
  ReportData,
  ReportFilterParams,
  ReportGraphType,
} from '../types';
import ReportGraphBarHorizontal from './ReportGraphBarHorizontal';
import ReportGraphBarVertical from './ReportGraphBarVertical';
import ReportGraphLine from './ReportGraphLine';
import ReportGraphPie from './ReportGraphPie';
import ReportGraphScoreCard from './ReportGraphScoreCard';
import ReportGraphTypeSelect from './ReportGraphTypeSelect';
import ReportGraphViewBy from './ReportGraphViewBy';
import { UpdateFilterFunction } from './useReportFilterParams';
import { hasNotFoundError } from './useReportTagNames';

interface Props {
  data?: ReportData[];
  filter: ReportFilterParams;
  updateFilter?: UpdateFilterFunction;
  sx?: CardProps['sx'];
  title?: string;
  subTitle?: string;
  goal?: number | null;
}

const ReportGraphCard = ({
  data,
  filter,
  updateFilter,
  sx,
  title,
  subTitle,
  goal,
}: Props) => {
  const notFoundError = hasNotFoundError(data?.[0]?.tags);
  const tooMuchDataError = hasTooMuchData(data, filter.graphType);
  const showGraphTypeSwitch = updateFilter && goal == null;
  const showGroupBySwitch =
    updateFilter &&
    filter.graphType !== 'scorecard' &&
    filter.aggregation !== ReportAggregation.LIST;

  return (
    <Card
      sx={{
        overflow: 'visible',
        ...sx,
      }}
    >
      <GraphContent>
        {(title || subTitle) && (
          <TopBar marginBottom={3}>
            {title && <Title variant="h5">{title}</Title>}
            {subTitle && <Subtitle>{subTitle}</Subtitle>}
          </TopBar>
        )}
        {showGraphTypeSwitch && (
          <ReportGraphTypeSelect filter={filter} updateFilter={updateFilter} />
        )}
        {showGraphTypeSwitch && <Divider sx={{ mt: 1, mb: 2 }} />}
        {notFoundError || !data?.length ? (
          <Flex
            sx={{
              my: 2,
              justifyContent: 'center',
            }}
          >
            <Typography>{t`No data found.`}</Typography>
          </Flex>
        ) : tooMuchDataError ? (
          <Flex
            sx={{
              my: 2,
              justifyContent: 'center',
            }}
          >
            <Typography>{t`The report cannot be displayed due to data overload. Please reduce data range or change view by parameter.`}</Typography>
          </Flex>
        ) : (
          <>
            {filter.graphType === 'bar-horizontal' && (
              <ReportGraphBarHorizontal
                data={data}
                filter={filter}
                goal={goal}
              />
            )}
            {filter.graphType === 'bar-vertical' && (
              <ReportGraphBarVertical data={data} filter={filter} />
            )}
            {filter.graphType === 'line' && (
              <ReportGraphLine data={data} filter={filter} />
            )}
            {filter.graphType === 'pie' && (
              <ReportGraphPie data={data} filter={filter} />
            )}
            {filter.graphType === 'scorecard' && (
              <ReportGraphScoreCard data={data} filter={filter} />
            )}
          </>
        )}
        {showGroupBySwitch && (
          <ReportGraphViewBy filter={filter} updateFilter={updateFilter} />
        )}
      </GraphContent>
    </Card>
  );
};

export default ReportGraphCard;

const TopBar = styled(Box)`
  padding-right: 70px;
`;

const Title = styled(Typography)`
  font-weight: 300;
  text-transform: uppercase;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const Subtitle = styled(Typography)`
  font-weight: 300;
  text-transform: uppercase;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const GraphContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MAX_DATAPOINTS: Record<ReportGraphType, number> = {
  'bar-vertical': 30,
  'bar-horizontal': 120,
  pie: 15,
  line: 500,
  scorecard: 10000,
};

const hasTooMuchData = (
  data: ReportData[] | undefined,
  graphType: ReportGraphType,
) => {
  const datapoints = getDatapointCount(data, graphType);
  return datapoints > MAX_DATAPOINTS[graphType];
};

const getDatapointCount = (
  data: ReportData[] | undefined,
  graphType: ReportGraphType,
) => {
  if (!data) {
    return 0;
  }
  if (graphType === 'pie') {
    return data.length;
  }
  return data.reduce((sum, row) => row.values.length + sum, 0);
};
