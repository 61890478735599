export const filterObjectsByQueryString =
  <T>(prop: keyof T, queryRaw: string) =>
  (data: T) => {
    const query = (queryRaw || '').trim().toLocaleLowerCase();
    if (!query) {
      return true;
    }
    const value = data[prop];
    if (!value) return false;
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }
    const valueString = String(value).toLocaleLowerCase();

    return valueString.includes(query);
  };
