import { useMutation, useQuery } from '@apollo/client';
import {
  createSafetyPositionPreset,
  deleteSafetyPositionPreset,
  editSafetyPositionPreset,
} from 'src/graphql/mutations';
import { safetyPositionPresets } from 'src/graphql/queries';
import {
  CreateSafetyPositionPreset,
  DeleteSafetyPositionPreset,
  DeleteSafetyPositionPresetVariables,
  EditSafetyPositionPreset,
  EditSafetyPositionPresetVariables,
  SafetyPositionPresets,
} from 'src/graphql/types';

export const useSafetyPositionPresets = () => {
  const {
    data: presets,
    loading: presetsLoading,
    refetch: refetchPresets,
  } = useQuery<SafetyPositionPresets>(safetyPositionPresets);

  const [createPresetMutation] = useMutation<CreateSafetyPositionPreset>(
    createSafetyPositionPreset,
    { onCompleted: refetchPresets },
  );

  const [editPresetMutation] = useMutation<
    EditSafetyPositionPreset,
    EditSafetyPositionPresetVariables
  >(editSafetyPositionPreset);

  const [deletePresetMutation] = useMutation<
    DeleteSafetyPositionPreset,
    DeleteSafetyPositionPresetVariables
  >(deleteSafetyPositionPreset, { onCompleted: refetchPresets });

  return {
    presets,
    presetsLoading,
    createPresetMutation,
    editPresetMutation,
    deletePresetMutation,
  };
};
