import { t, Trans } from '@lingui/macro';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GridViewIcon from '@mui/icons-material/GridView';
import HotelIcon from '@mui/icons-material/Hotel';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { Shadows } from '@mui/material/styles/shadows';
import { MouseEvent } from 'react';
import Flex from 'src/components/Flex';
import TextFieldAutosave from 'src/components/TextFieldAutosave/TextFieldAutosave';

interface Values {
  name: string;
  bedsCount: number;
  wardsCount?: number;
  roomsCount?: number;
}

interface Props<T extends Values> {
  data: T;
  selected: boolean;
  onClick: () => void;
  onNameChange: (name: string) => void;
  onRemoveClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onCopyClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const HospitalLayoutNameCard = <T extends Values>({
  data,
  selected,
  onClick,
  onNameChange,
  onRemoveClick,
  onCopyClick,
}: Props<T>) => {
  const { palette } = useTheme();

  const handleSaveNameChange = (name: string) => {
    if (name && name !== data.name) {
      onNameChange(name);
    }
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemoveClick(e);
  };

  const handleCopy = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCopyClick && onCopyClick(e);
  };

  return (
    <HospitalLayoutNameCardStyle onClick={onClick} selected={selected}>
      <CardActions>
        <Flex flexGrow={1} justifyContent="end">
          {onCopyClick && (
            <IconButton onClick={handleCopy} title={t`Duplicate`}>
              <ContentCopyIcon htmlColor={palette.grey[300]} />
            </IconButton>
          )}
          <IconButton onClick={handleRemove} title={t`Delete`}>
            <DeleteOutlineIcon htmlColor={palette.grey[300]} />
          </IconButton>
        </Flex>
      </CardActions>
      <CardContent>
        <TextFieldAutosave
          placeholder={t`Name`}
          value={data.name}
          onAutoSave={handleSaveNameChange}
          variant="outlined"
          fullWidth
          onClick={(e) => e.stopPropagation()}
        ></TextFieldAutosave>
        <Divider sx={{ my: 2 }} />
        <HospitalLayoutNameCardInfoTable>
          <tbody>
            {Number.isInteger(data.wardsCount) && (
              <tr>
                <td>
                  <GridViewIcon />
                </td>
                <td>
                  <Typography>
                    <Trans>Units</Trans>:
                  </Typography>
                </td>
                <td className={classes.value}>
                  <Typography>{data.wardsCount}</Typography>
                </td>
              </tr>
            )}
            {Number.isInteger(data.roomsCount) && (
              <tr>
                <td>
                  <MeetingRoomOutlinedIcon />
                </td>
                <td>
                  <Typography>
                    <Trans>Rooms</Trans>:
                  </Typography>
                </td>
                <td className={classes.value}>
                  <Typography>{data.roomsCount}</Typography>
                </td>
              </tr>
            )}
            {Number.isInteger(data.bedsCount) && (
              <tr>
                <td>
                  <HotelIcon />
                </td>
                <td>
                  <Typography>
                    <Trans>Beds</Trans>:
                  </Typography>
                </td>
                <td className={classes.value}>
                  <Typography>{data.bedsCount}</Typography>
                </td>
              </tr>
            )}
          </tbody>
        </HospitalLayoutNameCardInfoTable>
      </CardContent>
    </HospitalLayoutNameCardStyle>
  );
};

export default HospitalLayoutNameCard;

const HospitalLayoutNameCardStyle = styled(Card)<{ selected: boolean }>(
  ({ selected, theme: { palette, spacing, shadows, insetShadows } }) => `
    min-width: 180px;
    cursor: pointer;
    margin: ${spacing(1)};

    &:hover {
        ${selected ? null : `box-shadow: ${(shadows as Shadows)[7]}`};
    }

    & .MuiTextField-root .MuiOutlinedInput-root {
      background-color: ${palette.background.default}
    }

    ${
      selected
        ? `
            background-color: ${palette.grey[200]};
            box-shadow: ${insetShadows[1]};
        `
        : ``
    }
`,
);

export const HospitalLayoutNameCardPlaceholder = styled(Flex)(
  ({ theme: { spacing } }) => `
    flex-shrink: 0;
    width: 180px;
    margin: ${spacing(1)};
`,
);

//Overrides from App.css where it is stupidly inportant
const classes = {
  value: `HospitalLayoutNameCard-table-value`,
};
const HospitalLayoutNameCardInfoTable = styled('table')(
  ({ theme: { spacing } }) => `
    
    &, tr, td, tr:hover > td {
        border: none !important;
        border-spacing: 0 !important;
        border-collapse: initial !important;
        height: ${spacing(4)};
        padding: 0 !important;
    }
    & svg {
        margin-right: ${spacing(1)};
    }

    & .${classes.value}, & tr:hover >td.${classes.value} {
        padding-left: ${spacing(1)} !important;
        text-align: right;
    }
`,
);
