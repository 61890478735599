import { t } from '@lingui/macro';
import { IconButton, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import {
  Datapoint,
  datapointTypeLabel,
  datapointTypeSecondaryLabel,
  DATAPOINT_TYPE,
  getDatapointInfo,
  getDatapointLabel,
  getSecondaryUnit,
  getUnit,
} from './helper';
import { CircularButtonContainer } from '../components/CircularButtonContainer';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { InfoPopover } from 'src/pages/SafetyView/components/InfoPopover';
import { LocationSelector } from './LocationSelector';
import { FormikHelpers } from 'formik';
import { DataSimulationForm } from './DataSimulation';

interface Props
  extends Pick<FormikHelpers<DataSimulationForm>, 'setFieldTouched'> {
  datapoint: Datapoint;
  datapointIdx: number;
  changeDatapoint: (dp: Datapoint, idx: number) => void;
  removeDatapoint: (idx: number) => void;
  unusedDatapoints: Datapoint[];
}

const preventE = (
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  if (e.key === 'e' || e.key === 'E') {
    e.preventDefault();
  }
};

export const DatapointRow = ({
  datapoint,
  datapointIdx,
  changeDatapoint,
  removeDatapoint,
  unusedDatapoints,
  setFieldTouched,
}: Props) => {
  const datapointInfo = getDatapointInfo(datapoint.id);
  const handleFieldChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const newDatapoint = datapointOptions.find(
      (dp) => dp.id === event.target.value,
    );
    if (newDatapoint) {
      changeDatapoint(newDatapoint, datapointIdx);
    }
  };

  const datapointOptions = useMemo(() => {
    return unusedDatapoints.concat([datapoint]);
  }, [unusedDatapoints, datapoint]);

  return (
    <Box display="flex" alignItems="start" gap={4} mt={2}>
      <TextField
        value={datapoint.id}
        label={
          <Box display="flex" alignItems="center" height="1.5rem">
            {t`Datapoint`}
          </Box>
        }
        variant="outlined"
        select
        onChange={handleFieldChange}
        disabled={datapoint.id === 'connected'}
        sx={{ width: '25ch', minWidth: '25ch' }}
      >
        {datapointOptions.map((dp) => (
          <MenuItem key={dp.id} value={dp.id}>
            {getDatapointLabel(dp.id)}
          </MenuItem>
        ))}
      </TextField>
      {datapoint.type !== DATAPOINT_TYPE.LOCATION ? (
        <FormikTextField
          name={datapoint.id}
          variant="outlined"
          sx={{ width: '25ch', minWidth: '25ch' }}
          InputProps={{
            type: 'number',
            onKeyDown: preventE,
            endAdornment: getUnit(datapoint),
          }}
          onBlur={() => setFieldTouched(datapoint.id, true)}
          label={
            <Box display="flex" alignItems="center" height="1.5rem">
              <Box component="span" mr={0.5}>
                {datapointTypeLabel(datapoint)}
              </Box>
              {datapointInfo ? (
                <InfoPopover forceTheme="light" description={datapointInfo} />
              ) : null}
            </Box>
          }
        />
      ) : null}
      {datapoint.type !== DATAPOINT_TYPE.LOCATION &&
      datapoint.type !== DATAPOINT_TYPE.PERCENTAGE ? (
        <FormikTextField
          name={`${datapoint.id}Alt`}
          sx={{ width: '25ch', minWidth: '25ch' }}
          variant="outlined"
          InputProps={{
            type: 'number',
            onKeyDown: preventE,
            endAdornment: getSecondaryUnit(datapoint),
          }}
          onBlur={() => setFieldTouched(`${datapoint.id}Alt`, true)}
          label={
            <Box display="flex" alignItems="center" height="1.5rem">
              {datapointTypeSecondaryLabel(datapoint.type)}
            </Box>
          }
        />
      ) : null}
      {datapoint.type === DATAPOINT_TYPE.LOCATION ? <LocationSelector /> : null}
      <Box display="flex" alignItems="end" height="82px">
        <Box display="flex" alignItems="center" height="56px">
          {datapoint.id !== 'connected' ? (
            <CircularButtonContainer>
              <IconButton
                color="primary"
                size="small"
                onClick={() => removeDatapoint(datapointIdx)}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </CircularButtonContainer>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
