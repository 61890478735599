import { TextField, TextFieldProps } from '@mui/material';
import { isValid } from 'date-fns';
import { getDateInputFormat } from 'src/helpers';

type Props = Omit<TextFieldProps, 'onChange' | 'defaultValue'> & {
  label: string;
  min?: string | Date | null;
  max?: string | Date | null;
  value?: string | Date | null;
  defaultValue?: string | Date | null;
  onChange: (date: Date) => void;
};

const DateInput = ({
  label,
  min,
  max,
  value,
  onChange,
  defaultValue,
  ...textFieldProps
}: Props) => {
  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const date = new Date(value);
    isValid(date) && onChange(date);
  };
  return (
    <TextField
      {...textFieldProps}
      label={label}
      type="date"
      defaultValue={getDateInputFormat(defaultValue) ?? undefined}
      inputProps={{
        ...textFieldProps.inputProps,
        min: getDateInputFormat(min) ?? undefined,
        max: getDateInputFormat(max) ?? undefined,
      }}
      value={getDateInputFormat(value) ?? undefined}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        flexShrink: 0,
        ...textFieldProps.sx,
      }}
    />
  );
};

export default DateInput;
