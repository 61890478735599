import { useState } from 'react';
import { Box, styled } from '@mui/material';
import { useQuery } from '@apollo/client';
import { statistics } from 'src/graphql/queries';
import {
  SessionStatistics,
  SessionStatisticsVariables,
} from 'src/graphql/types';
import StatisticsItem from './StatisticsItem';
import { t } from '@lingui/macro';
import { getStatisticsData } from '../../helpers/statistics';
import { parseDate } from '../../../../helpers';
import { sub } from 'date-fns';
import DateInput from 'src/components/DateInput';

interface Props {
  patientSessionId: string;
  sessionEnd?: string | null;
  sessionStart?: string | null;
}

const Statistics = ({ patientSessionId, sessionEnd, sessionStart }: Props) => {
  const [to, setTo] = useState(parseDate(sessionEnd) ?? new Date());
  const [from, setFrom] = useState(sub(to, { days: 1 }));

  const { data } = useQuery<SessionStatistics, SessionStatisticsVariables>(
    statistics,
    {
      variables: {
        id: patientSessionId,
        from,
        to,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
    },
  );
  const editedData = getStatisticsData(data);
  return (
    <Box width="100%">
      <Box mb={2} display="flex" justifyContent="flex-end">
        <DateInput
          label={t`From`}
          value={from}
          disabled={!sessionStart}
          min={sessionStart}
          max={to}
          onChange={setFrom}
          variant="outlined"
          size="small"
          sx={{
            mr: 2,
          }}
        />
        <DateInput
          label={t`To`}
          min={from}
          max={sessionEnd}
          disabled={!sessionStart}
          value={to}
          onChange={setTo}
          variant="outlined"
          size="small"
        />
      </Box>
      <Container>
        {editedData?.map((item) => (
          <StatisticsItem key={`statistics-${item.type}`} pieData={item} />
        ))}
      </Container>
    </Box>
  );
};

export default Statistics;

const Container = styled(Box)({
  overflowY: 'scroll',
  paddingRight: '16px',
  height: '332px',
  display: 'flex',
  flexWrap: 'wrap',
});
