import { SvgIcon, SvgIconProps } from '@mui/material';

export const PinIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path
          d="M15.5471 2.64453L10.3858 7.80582L5.22455 9.74131L3.28906 11.6768L12.3213 20.709L14.2568 18.7736L16.1923 13.6123L21.3536 8.45098"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.80645 16.1934L2 21.9998"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9023 2L21.9991 9.09677"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
