import { t, Trans } from '@lingui/macro';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Divider, styled, Tabs } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useMemo } from 'react';
import Flex from 'src/components/Flex';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikRadioSelect from 'src/components/Formik/FormikRadioSelect';
import FormikSwitch from 'src/components/Formik/FormikSwitch';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SaveDiscardButtons from 'src/components/SaveDiscardButtons';
import SimpleTableStyle from 'src/components/Table/SimpleTableStyle';
import {
  BedExitStatus,
  SafetyPositionPresetFragment,
  SiderailsPosition,
} from 'src/graphql/types';
import { useDirtyFormik } from 'src/hooks';
import { normalizeNumberInt } from 'src/lib/normalize';
import { ThinTab } from 'src/components/ThinTab';
import SiderailPositionTrans from './PresetTrans';

interface Props {
  presetData: SafetyPositionPresetFragment;
  disabled?: boolean;
  onSave: (
    id: string,
    data: Partial<SafetyPositionPresetFragment>,
  ) => Promise<void>;
}

interface FormData {
  name: string;
  backrestAngleEnabled: boolean;
  backrestAngleMin: string;
  backrestAngleMax: string;

  posIsLowestEnabled: boolean;
  brakesEnabled: boolean; // gui asks for brakesEngaged but backend flag is breakesOff
  siderailPositionEnabled: boolean;

  siderailPosition: SiderailsPosition;
  bedExitEnabled: boolean;
  bedExitStatus: BedExitStatus;
}

const SpDashboardPresetDetail = ({ presetData, onSave, ...others }: Props) => {
  const disabled = others.disabled || !presetData.editable;

  const onSubmit = async (data: FormData, helpers: FormikHelpers<FormData>) => {
    await onSave(presetData.id, {
      ...data,
      backrestAngleMin: Number.parseInt(data.backrestAngleMin),
      backrestAngleMax: Number.parseInt(data.backrestAngleMax),
    });
  };

  const formik = useFormik<FormData>({
    initialValues: {
      name: presetData.name,
      backrestAngleEnabled: !!presetData.backrestAngleEnabled,
      backrestAngleMin: `${presetData.backrestAngleMin}`,
      backrestAngleMax: `${presetData.backrestAngleMax}`,
      posIsLowestEnabled: !!presetData.posIsLowestEnabled,
      brakesEnabled: !!presetData.brakesEnabled,
      siderailPositionEnabled: !!presetData.siderailPositionEnabled,
      siderailPosition: presetData.siderailPosition,
      bedExitEnabled: !!presetData.bedExitEnabled,
      bedExitStatus: presetData.bedExitStatus,
    },
    enableReinitialize: true,
    onSubmit,
  });
  useDirtyFormik(formik);
  const { values } = formik;

  const bedExitStatusOptions = useMemo(
    () => [
      { label: t`outer`, value: BedExitStatus.OUTER },
      { label: t`inner`, value: BedExitStatus.INNER },
    ],
    [],
  );

  const sideRailPositionOptions = useMemo(
    () => [
      {
        label: <SiderailPositionTrans val={SiderailsPosition.ALL_UP} />,
        value: SiderailsPosition.ALL_UP,
      },
      {
        label: <SiderailPositionTrans val={SiderailsPosition.ANY_DOWN} />,
        value: SiderailsPosition.ANY_DOWN,
      },
      {
        label: <SiderailPositionTrans val={SiderailsPosition.ONE_HEAD_DOWN} />,
        value: SiderailsPosition.ONE_HEAD_DOWN,
      },
      {
        label: (
          <SiderailPositionTrans val={SiderailsPosition.ONE_BOTTOM_DOWN} />
        ),
        value: SiderailsPosition.ONE_BOTTOM_DOWN,
      },
      {
        label: <SiderailPositionTrans val={SiderailsPosition.ONE_SIDE_DOWN} />,
        value: SiderailsPosition.ONE_SIDE_DOWN,
      },
    ],
    [],
  );

  const renderInput = (name: string, label: string, disabled: boolean) => {
    return (
      <FormikTextField
        name={name}
        placeholder={label}
        variant="outlined"
        inputProps={{
          style: {
            paddingTop: '8px',
            paddingBottom: '8px',
            textAlign: 'right',
          },
        }}
        InputProps={{ endAdornment: '°' }}
        normalize={normalizeNumberInt}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          width: '9ch',
          mr: 2,
          '& .MuiOutlinedInput-root': {
            marginTop: 0,
          },
        }}
        disabled={disabled}
      />
    );
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <Tabs value="settings">
          <ThinTab label={t`Preset settings`} value="settings" />
        </Tabs>
        {!disabled && (
          <SaveDiscardButtons
            onSave={formik.submitForm}
            onCancel={formik.resetForm}
            disabled={!formik.dirty}
          />
        )}
      </Flex>
      <Divider sx={{ my: 2 }} />

      <FormikForm formik={formik}>
        <FormikTextField
          name="name"
          variant="outlined"
          label={t`Preset name`}
          disabled={disabled}
        />

        <Divider sx={{ my: 2 }} />

        <SimpleTableStyle>
          <thead>
            <tr>
              <th>
                <Trans>Value name</Trans>
              </th>
              <th>
                <Trans>Employ</Trans>
              </th>
              <th>
                <Trans>Safety position value</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Trans>Backrest Angle</Trans>
              </td>
              <td>
                <FormikSwitch name="backrestAngleEnabled" disabled={disabled} />
              </td>
              <td style={{ paddingTop: 0, paddingBottom: 0 }}>
                {renderInput(
                  'backrestAngleMin',
                  t`From`,
                  disabled || !values.backrestAngleEnabled,
                )}
                {renderInput(
                  'backrestAngleMax',
                  t`To`,
                  disabled || !values.backrestAngleEnabled,
                )}
              </td>
            </tr>
            <tr>
              <td>
                <Trans>Lowest Position</Trans>
              </td>
              <td>
                <FormikSwitch name="posIsLowestEnabled" disabled={disabled} />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Trans>Brakes Engaged</Trans>
              </td>
              <td>
                <FormikSwitch name="brakesEnabled" disabled={disabled} />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <Trans>Position of Siderails</Trans>
              </td>
              <td>
                <FormikSwitch
                  name="siderailPositionEnabled"
                  disabled={disabled}
                />
              </td>
              <td>
                <FormikRadioSelect
                  row
                  name="siderailPosition"
                  options={sideRailPositionOptions}
                  disabled={disabled || !values.siderailPositionEnabled}
                />
                <InfoTextStyled>
                  <InfoOutlinedIcon />
                  <Trans>
                    E4 reads only two conditions, ANY siderail down or all
                    siderails up.
                  </Trans>
                </InfoTextStyled>
              </td>
            </tr>
            <tr>
              <td>
                <Trans>Bed Exit Monitoring</Trans>
              </td>
              <td>
                <FormikSwitch name="bedExitEnabled" disabled={disabled} />
              </td>
              <td>
                <FormikRadioSelect
                  row
                  name="bedExitStatus"
                  options={bedExitStatusOptions}
                  disabled={disabled || !values.bedExitEnabled}
                />
              </td>
            </tr>
          </tbody>
        </SimpleTableStyle>
      </FormikForm>
    </>
  );
};

export default SpDashboardPresetDetail;

const InfoTextStyled = styled('span')(
  ({ theme: { spacing, palette } }) => `
  line-height: ${spacing(5)};
  font-size: 0.85em;
  white-space: nowrap; 
  color: ${palette.text.secondary};
  svg {
    vertical-align: middle;
  }
`,
);
