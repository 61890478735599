import { useQuery } from '@apollo/client';
import { upsertUser } from 'src/graphql/mutations';
import { userQuery, usersQuery, wardsQuery } from 'src/graphql/queries';
import {
  upsertUserMutation,
  upsertUserMutationVariables,
  UserQuery,
  UserQueryVariables,
  UserRole,
  WardsQuery,
} from 'src/graphql/types';
import UserDetail from './UserDetail';
import { useParams } from 'react-router-dom';
import { object, string, ref as yupRef } from 'yup';
import { t } from '@lingui/macro';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import { useMemo } from 'react';
import { usePasswordValidator } from '../../../auth/passwordValidation';

interface UserFormData {
  id: string | undefined;
  role: UserRole;
  firstName: string;
  password: string;
  repeatPassword?: string;
  lastName: string;
  email: string;
  username: string;
  wardIds: AutocompleteSelectValue[];
  reportingModuleAccess: boolean;
  workspaceId?: string;
  locale: string;
  serverIp: string;
  ssid: string;
  ssidKey: string;
  ssidKeyEncrypt: boolean;
  vbAccess: boolean;
  demoAccess: boolean;
}

interface AutocompleteSelectValue {
  label: string;
  value: string;
}

const CreateUser = () => {
  const params = useParams<{ userId: string }>();
  const { data: userData } = useQuery<UserQuery, UserQueryVariables>(
    userQuery,
    {
      variables: {
        userId: params.userId || '',
      },
      skip: params.userId == null,
    },
  );

  const { data: wardsData } = useQuery<WardsQuery>(wardsQuery);
  const [updateUser] = useSnackbarMutation<
    upsertUserMutation,
    upsertUserMutationVariables
  >(upsertUser, undefined, {
    successMessage: t`User was created.`,
  });
  const { passwordValidator, passwordRequirementsMessage } =
    usePasswordValidator();
  const userSchema = useMemo(
    () =>
      object({
        firstName: string().required(t`Required!`),
        lastName: string().required(t`Required!`),
        username: string().required(t`Required!`),
        role: string(),
        password: string()
          .required(t`Required!`)
          .when('role', (role: UserRole, schema: any) => {
            return schema.test(
              'strength',
              passwordRequirementsMessage(role),
              function (password: string) {
                return passwordValidator(password || '', role);
              },
            );
          }),
        repeatPassword: string()
          .required(t`Required!`)
          .test('equal', t`Passwords do not match!`, function (v) {
            const ref = yupRef('password');
            return v === this.resolve(ref);
          }),
      }),
    [passwordValidator, passwordRequirementsMessage],
  );
  const handleSubmit = async (data: UserFormData) => {
    delete data.workspaceId;
    delete data.repeatPassword;
    delete data.id;
    await updateUser({
      variables: {
        user: {
          ...data,
          wardIds: data.wardIds.map((ward) => ward.value),
          serverIp: data.serverIp === '' ? null : data.serverIp,
          ssid: data.ssid === '' ? null : data.ssid,
          ssidKey: data.ssidKey === '' ? null : data.ssidKey,
        },
      },
      refetchQueries: () => [{ query: usersQuery }],
    });
    return;
  };

  const userWards = userData?.user?.assignedWards || [];
  const allWards = wardsData?.wards || [];
  const selectedWorkspaceId =
    (userWards.length ? userWards : allWards)[0]?.workspace.id || '';
  const initialValues = {
    id: '',
    role: UserRole.ADMIN,
    firstName: '',
    password: '',
    repeatPassword: '',
    workspaceId: selectedWorkspaceId,
    wardIds: [],
    lastName: '',
    email: '',
    username: '',
    locale: '',
    serverIp: '',
    ssid: '',
    ssidKey: '',
    ssidKeyEncrypt: false,
    vbAccess: false,
    demoAccess: false,
    reportingModuleAccess: false,
  };

  return (
    <UserDetail<UserFormData>
      initialValues={initialValues}
      features={{
        editRole: true,
        accessSwitchButtons: true,
      }}
      onSubmit={handleSubmit}
      resetOnSubmit
      backButton={t`Cancel`}
      validationSchema={userSchema}
    />
  );
};

export default CreateUser;
