import { useState } from 'react';
import antitrendelenburgSvg from 'src/images/demo/Antitrendelenburg.svg';
import antitrendelenburgOnSvg from 'src/images/demo//AntitrendelenburgOn.svg';
import trendelenburgSvg from 'src/images/demo//Trendelenburg.svg';
import trendelenburgOnSvg from 'src/images/demo/TrendelenburgOn.svg';
import mobilizationPositionSvg from 'src/images/demo/Mobilization-position.svg';
import mobilizationPositionOnSvg from 'src/images/demo/Mobilization-position-on.svg';
import cardiacChairSvg from 'src/images/demo/Cardiac-chair.svg';
import cardiacChairOnSvg from 'src/images/demo/Cardiac-chair-on.svg';
import { ReactComponent as CprIcon } from 'src/images/demo/CPR.svg';
import { ReactComponent as CprOnIcon } from 'src/images/demo/cprOn.svg';
import { ReactComponent as GoIcon } from 'src/images/demo/GO.svg';
import { ReactComponent as GoOnIcon } from 'src/images/demo/goOn.svg';
import power from 'src/images/demo/power.svg';
import powerOn from 'src/images/demo/powerOn.svg';
import autocontour from 'src/images/demo/Autocontour.svg';
import backrest from 'src/images/demo/Backrest.svg';
import lateral from 'src/images/demo/Lateral.svg';
import bedHeight from 'src/images/demo/bedHeight.svg';
import { ReactComponent as Arrow } from 'src/images/demo/arrow.svg';
import { Box, Button, IconButton, styled } from '@mui/material';

interface Props {
  disabled: boolean;
  demoState: string;
  setDemoState: (state: string) => void;
}

const ControlPanel = ({ demoState, setDemoState, disabled }: Props) => {
  const [autocontourTopLight, setAutocontourTopLight] = useState(false);
  const [autocontourBottomLight, setAutocontourBottomLight] = useState(false);
  const [backrestTopLight, setBackrestTopLight] = useState(false);
  const [backrestBottomLight, setBackrestBottomLight] = useState(false);
  const [lateralLeftLight, setLateralLeftLight] = useState(false);
  const [lateralRightLight, setLateralRightLight] = useState(false);
  const [bedHeightTopLight, setBedHeightTopLight] = useState(false);
  const [bedHeightBottomLight, setBedHeightBottomLight] = useState(false);

  const [antitrendelenburgOn, setAntitrendelenburgOn] = useState(false);
  const [trendelenburgOn, setTrendelenburgOn] = useState(false);
  const [mobilizationPositionOn, setMobilizationPositionOn] = useState(false);
  const [cardiacChairOn, setCardiacChairOn] = useState(false);
  const [cprOn, setCprOn] = useState(false);

  const [resetButton, setResetButton] = useState(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 80 }}>
      <Box
        sx={{
          width: '455px',
          margin: '0 auto',
        }}
      >
        <Panel>
          <Box
            sx={{
              paddingTop: '16px',
              paddingBottom: '4px',
              backgroundColor: '#AEC5E6',
              borderTopLeftRadius: '14px',
              borderTopRightRadius: '14px',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'flex-end',
            }}
          >
            <Box style={{ position: 'relative' }}>
              <TopIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('autocontour');
                  setAutocontourTopLight(true);
                  setTimeout(() => {
                    setAutocontourTopLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={autocontourTopLight ? { color: 'white' } : undefined}
                />
              </TopIconButton>
              <BottomIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('base');
                  setAutocontourBottomLight(true);
                  setTimeout(() => {
                    setAutocontourBottomLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={
                    autocontourBottomLight ? { color: 'white' } : undefined
                  }
                />
              </BottomIconButton>
              <img src={autocontour} alt="autocontour" />
            </Box>
            <Box style={{ position: 'relative' }}>
              <TopIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('backrest30');
                  setBackrestTopLight(true);
                  setTimeout(() => {
                    setBackrestTopLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={backrestTopLight ? { color: 'white' } : undefined}
                />
              </TopIconButton>
              <BottomIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('backrest0');
                  setBackrestBottomLight(true);
                  setTimeout(() => {
                    setBackrestBottomLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={backrestBottomLight ? { color: 'white' } : undefined}
                />
              </BottomIconButton>
              <img src={backrest} alt="backrest" />
            </Box>
            <Box style={{ position: 'relative' }}>
              <LeftIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('lateralLeft');
                  setLateralLeftLight(true);
                  setTimeout(() => {
                    setLateralLeftLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={lateralLeftLight ? { color: 'white' } : undefined}
                />
              </LeftIconButton>
              <RightIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('lateralRight');
                  setLateralRightLight(true);
                  setTimeout(() => {
                    setLateralRightLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={lateralRightLight ? { color: 'white' } : undefined}
                />
              </RightIconButton>
              <img src={lateral} alt="lateral" />
            </Box>
            <Box style={{ position: 'relative' }}>
              <TopIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('bedHighest');
                  setBedHeightTopLight(true);
                  setTimeout(() => {
                    setBedHeightTopLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={bedHeightTopLight ? { color: 'white' } : undefined}
                />
              </TopIconButton>
              <BottomIconButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('bedLowest');
                  setBedHeightBottomLight(true);
                  setTimeout(() => {
                    setBedHeightBottomLight(false);
                  }, 500);
                }}
              >
                <Arrow
                  style={bedHeightBottomLight ? { color: 'white' } : undefined}
                />
              </BottomIconButton>
              <img src={bedHeight} alt="bedHeight" />
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: '#FCBD57',
              borderBottomLeftRadius: '14px',
              borderBottomRightRadius: '14px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box style={{ display: 'flex', marginLeft: '16px' }}>
              <img
                width="35px"
                src={demoState === 'unplugged' ? power : powerOn}
                alt="power"
              />
            </Box>
            <BottomBarButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                setDemoState('antitrendelenburg');
                setAntitrendelenburgOn(true);
                setTimeout(() => {
                  setAntitrendelenburgOn(false);
                }, 500);
              }}
            >
              <img
                src={
                  !antitrendelenburgOn
                    ? antitrendelenburgSvg
                    : antitrendelenburgOnSvg
                }
                alt="antitrendelenburg"
              />
            </BottomBarButton>
            <BottomBarButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                setDemoState('trendelenburg');
                setTrendelenburgOn(true);
                setTimeout(() => {
                  setTrendelenburgOn(false);
                }, 500);
              }}
            >
              <img
                src={trendelenburgOn ? trendelenburgOnSvg : trendelenburgSvg}
                alt="trendelenburg"
              />
            </BottomBarButton>
            <BottomBarButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                setDemoState('mobilisation');
                setMobilizationPositionOn(true);
                setTimeout(() => {
                  setMobilizationPositionOn(false);
                }, 500);
              }}
            >
              <img
                src={
                  !mobilizationPositionOn
                    ? mobilizationPositionSvg
                    : mobilizationPositionOnSvg
                }
                alt="mobilizationPosition"
              />
            </BottomBarButton>
            <BottomBarButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                setDemoState('cardiacChair');
                setCardiacChairOn(true);
                setTimeout(() => {
                  setCardiacChairOn(false);
                }, 500);
              }}
            >
              <img
                src={!cardiacChairOn ? cardiacChairSvg : cardiacChairOnSvg}
                alt="cardiacChair"
              />
            </BottomBarButton>
            <BottomBarButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                setDemoState('cpr');
                setCprOn(true);
                setTimeout(() => {
                  setCprOn(false);
                }, 500);
              }}
            >
              {!cprOn ? <CprIcon /> : <CprOnIcon />}
            </BottomBarButton>
            <Box
              sx={{
                backgroundColor: '#007E7B',
                borderBottomRightRadius: '14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '12px',
                paddingRight: '24px',
              }}
            >
              <BottomBarButton
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setDemoState('base');
                  setResetButton(true);
                  setTimeout(() => {
                    setResetButton(false);
                  }, 500);
                }}
              >
                {!resetButton ? <GoOnIcon /> : <GoIcon />}
              </BottomBarButton>
            </Box>
          </Box>
        </Panel>
        <Panel>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <PanelButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (demoState !== 'patientOnBedNS') {
                  setDemoState('patientOnBedNS');
                } else {
                  setDemoState('bedExit');
                }
              }}
              sx={{ width: 100 }}
            >
              {demoState === 'patientOnBedNS' ? 'Bed exit' : 'Patient on bed'}
            </PanelButton>
            <PanelButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (demoState !== 'plugged') {
                  setDemoState('plugged');
                } else {
                  setDemoState('unplugged');
                }
              }}
              sx={{ width: 74 }}
            >
              {demoState === 'plugged' ? 'Unplugg' : 'Plugg'}
            </PanelButton>
            <PanelButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (demoState !== 'legSiderailDown') {
                  setDemoState('legSiderailDown');
                } else {
                  setDemoState('base');
                }
              }}
              sx={{ width: 110 }}
            >
              {demoState === 'legSiderailDown'
                ? 'Leg siderail up'
                : 'Leg siderail down'}
            </PanelButton>
            <PanelButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (demoState !== 'unbraked') {
                  setDemoState('unbraked');
                } else {
                  setDemoState('base');
                }
              }}
              style={{ width: 74 }}
            >
              {demoState === 'unbraked' ? 'Brake' : 'Unbrake'}
            </PanelButton>
            <PanelButton
              onClick={() => {
                if (disabled) {
                  return;
                }
                setDemoState('patientOnBedS');
              }}
              size="small"
            >
              Weight
            </PanelButton>
          </Box>
        </Panel>
      </Box>
    </Box>
  );
};

export default ControlPanel;

const PanelButton = styled(Button)`
  background: #aec5e6;
  border-radius: 14px;
  color: white;
  padding-top: 6px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 8px;
  font-weight: bold;
  line-height: normal;
  :hover {
    background: #aec5e6;
  }
`;

PanelButton.defaultProps = {
  size: 'small',
};

const Panel = styled(Box)`
  width: 100%;
  margin-top: 16px;
  padding: 14px;
  border-radius: 14px;
  background-color: white;
  box-shadow: 5px 30px 35px rgba(0, 0, 0, 0.21386);
`;

const BottomBarButton = styled(IconButton)`
  width: 37px;
  height: 37px;
  margin-left: 8px;
  margin-right: 8px;
`;

const TopIconButton = styled(IconButton)`
  position: absolute;
  width: 50%;
  margin: 0 25%;
`;

const BottomIconButton = styled(IconButton)`
  position: absolute;
  width: 50%;
  margin: 0 25%;
  bottom: 5px;
  transform: rotate(180deg);
`;

const RightIconButton = styled(IconButton)`
  position: absolute;
  right: 0px;
  bottom: 5px;
  transform: rotate(90deg);
`;

const LeftIconButton = styled(IconButton)`
  position: absolute;
  left: 0px;
  bottom: 5px;
  transform: rotate(-90deg);
`;
