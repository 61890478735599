import { useMutation } from '@apollo/client';
import { differenceInMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  deleteCareNoteMutation,
  editCareNoteMutation,
} from 'src/graphql/mutations';
import { patientCareNotes } from 'src/graphql/queries';
import {
  DeleteCareNoteMutation,
  DeleteCareNoteMutationVariables,
  EditCareNoteMutation,
  EditCareNoteMutationVariables,
  PatientCareNotes,
  PatientCareNotesVariables,
} from 'src/graphql/types';

interface Props {
  disable?: boolean;
  patientSessionId: string;
  data: CareNoteItemDataProps;
}

export interface CareNoteItemProps {
  note?: string | null;
  setNote: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  setEditNote: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  data: CareNoteItemDataProps;
  editNote: boolean;
  disable?: boolean;
  editable: boolean;
  saveNote: () => void;
}

export interface CareNoteItemDataProps {
  date: Date;
  note?: string | null;
  id: string;
  cofirmDate: Date;
}

export const useCareNoteData = ({ disable, patientSessionId, data }: Props) => {
  const [editNote, setEditNote] = useState(false);
  const [note, setNote] = useState(data.note);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (data.cofirmDate) {
      const diff = differenceInMilliseconds(data.cofirmDate, new Date());
      if (diff > 0) {
        setEditable(true);
        setTimeout(() => {
          setEditable(false);
        }, diff);
      }
    }
  }, [data.cofirmDate]);
  const [deleteCareNote] = useMutation<
    DeleteCareNoteMutation,
    DeleteCareNoteMutationVariables
  >(deleteCareNoteMutation);
  const [editCareNote] = useMutation<
    EditCareNoteMutation,
    EditCareNoteMutationVariables
  >(editCareNoteMutation);
  const deleteNote = () => {
    deleteCareNote({
      variables: {
        id: data.id,
      },
      update(store) {
        const queryData = store.readQuery<
          PatientCareNotes,
          PatientCareNotesVariables
        >({
          query: patientCareNotes,
          variables: {
            id: patientSessionId,
          },
        });
        if (!!(queryData && queryData.patientSession)) {
          const newData = {
            ...queryData,
            patientSession: {
              ...queryData.patientSession,
              careNotes: queryData.patientSession.careNotes.filter(
                (item) => item?.id !== data?.id,
              ),
            },
          };
          store.writeQuery<PatientCareNotes, PatientCareNotesVariables>({
            query: patientCareNotes,
            variables: {
              id: patientSessionId,
            },
            data: newData,
          });
        }
      },
    });
  };

  const saveNote = () => {
    editCareNote({
      variables: {
        id: data.id,
        note: note ?? '',
      },
    });
    setEditNote(false);
  };
  return {
    note,
    setNote,
    setEditNote,
    deleteNote,
    data,
    editNote,
    disable,
    editable,
    saveNote,
  };
};
