import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { t } from '@lingui/macro';
import { useFormik } from 'formik';
import { ModalHeader } from 'src/components/BaseModal/ModalHeader';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { object, string } from 'yup';

export interface PresetProps {
  id?: string;
  name: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  id?: string;
  name: string;
  onSave: (props: PresetProps) => Promise<void>;
}

const SavePresetDialog = ({ open, onClose, onSave, id, name }: Props) => {
  const schema = object({
    name: string()
      .required(t`Name cannot be empty`)
      .max(100, t`Name have exceeded maximum characters.`),
  });

  const formik = useFormik<{ name: string }>({
    initialValues: {
      name: name || '',
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!values.name) {
        return;
      }

      await onSave({
        id,
        name: values.name,
      });

      formik.resetForm();
      onClose();
    },
  });
  const handleClose = () => {
    formik.resetForm();
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <FormikForm formik={formik}>
        <DialogTitle>
          <ModalHeader
            title={id ? t`Edit data preset` : t`New data preset`}
            onClose={handleClose}
          />
        </DialogTitle>
        <DialogContent>
          <FormikTextField
            name="name"
            fullWidth
            sx={{ minWidth: '440px' }}
            variant="outlined"
            label={t`Preset name`}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleClose()}
          >
            {t`Cancel`}
          </Button>
          <Button color="primary" variant="contained" type="submit">
            {t`Save`}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
};

export default SavePresetDialog;
