import React from 'react';
import {
  SelectedExportType,
  ExportType,
  SelectedExportDayType,
  SelectedExportPeriodType,
  getExportTypeData,
} from '../../helpers/export';
import DesktopExportDataItem from './DesktopExportDataItem';
import { isMobile } from '../../../../lib/mobileCheck';
import MobileExportDataItem from '../../mobile/Export/MobileExportDataItem';
import { add, min, isValid } from 'date-fns';

export interface ExportDataDialogItemSharedProps {
  type: ExportType;
  sessionStart?: Date;
  sessionEnd?: Date;
  textColor?: string;
}

export interface ExportDataDialogItemProps
  extends ExportDataDialogItemSharedProps {
  setEndOfPeriod: (date: Date) => void;
  visibleHandler: () => void;
  data: SelectedExportDayType | SelectedExportPeriodType | null;
  setAllDay: (date: Date) => void;
  changeFromDate: (date: Date | null, maxDate: Date) => void;
  setStartofPeriod: (date: Date) => void;
  allDay: boolean;
}

interface Props extends ExportDataDialogItemSharedProps {
  setSelectedExportData: React.Dispatch<
    React.SetStateAction<SelectedExportType>
  >;
  selectedExportData: SelectedExportType;
}

const ExportDataItem = ({
  setSelectedExportData,
  selectedExportData,
  type,
  textColor,
  sessionStart,
  sessionEnd,
}: Props) => {
  const allDay = type === ExportType.view || type === ExportType.mobilization;
  const data: SelectedExportDayType | SelectedExportPeriodType | null =
    getExportTypeData(type, selectedExportData);
  const visibleHandler = () => {
    switch (type) {
      case ExportType.view:
        setSelectedExportData((prevState) => ({
          ...prevState,
          view: {
            ...selectedExportData.view,
            show: !selectedExportData.view.show,
          },
        }));
        break;
      case ExportType.statistics:
        setSelectedExportData((prevState) => ({
          ...prevState,
          statistics: {
            ...selectedExportData.statistics,
            show: !selectedExportData.statistics.show,
          },
        }));
        break;
      case ExportType.careNotes:
        setSelectedExportData((prevState) => ({
          ...prevState,
          careNotes: {
            ...selectedExportData.careNotes,
            show: !selectedExportData.careNotes.show,
          },
        }));
        break;
      case ExportType.mobilization:
        setSelectedExportData((prevState) => ({
          ...prevState,
          mobilization: {
            ...selectedExportData.mobilization,
            show: !selectedExportData.mobilization.show,
          },
        }));
        break;
      case ExportType.insight:
        setSelectedExportData((prevState) => ({
          ...prevState,
          insight: {
            ...selectedExportData.insight,
            show: !selectedExportData.insight.show,
          },
        }));
        break;
      default:
        break;
    }
  };

  const setStartofPeriod = (date: Date) => {
    switch (type) {
      case ExportType.careNotes:
        setSelectedExportData((prevState) => ({
          ...prevState,
          careNotes: { ...selectedExportData.careNotes, from: date },
        }));
        break;
      case ExportType.statistics:
        setSelectedExportData((prevState) => ({
          ...prevState,
          statistics: { ...selectedExportData.statistics, from: date },
        }));
        break;
      case ExportType.insight:
        setSelectedExportData((prevState) => ({
          ...prevState,
          insight: { ...selectedExportData.insight, from: date },
        }));
        break;
      default:
        break;
    }
  };

  const setEndOfPeriod = (date: Date) => {
    switch (type) {
      case ExportType.careNotes:
        setSelectedExportData((prevState) => ({
          ...prevState,
          careNotes: { ...selectedExportData.careNotes, to: date },
        }));
        break;
      case ExportType.statistics:
        setSelectedExportData((prevState) => ({
          ...prevState,
          statistics: { ...selectedExportData.statistics, to: date },
        }));
        break;
      case ExportType.insight:
        setSelectedExportData((prevState) => ({
          ...prevState,
          insight: { ...selectedExportData.insight, to: date },
        }));
        break;
      default:
        break;
    }
  };

  const setAllDay = (date: Date) => {
    switch (type) {
      case ExportType.view:
        setSelectedExportData((prevState) => ({
          ...prevState,
          view: { ...selectedExportData.view, date: date },
        }));
        break;
      case ExportType.insight:
        setSelectedExportData((prevState) => ({
          ...prevState,
          dataHistory: { ...selectedExportData.insight, date: date },
        }));
        break;
      case ExportType.mobilization:
        setSelectedExportData((prevState) => ({
          ...prevState,
          mobilization: { ...selectedExportData.mobilization, date: date },
        }));
        break;

      default:
        break;
    }
  };

  const changeFromDate = (date: Date | null, maxDate: Date) => {
    if (date && isValid(date)) {
      setSelectedExportData((prevState) => ({
        ...prevState,
        insight: {
          ...prevState.insight,
          from: date,
          to: min([add(date, { days: 1 }), maxDate]),
        },
      }));
    }
  };

  const desktopExportDataDialogBoxProps = {
    data,
    setEndOfPeriod,
    visibleHandler,
    setAllDay,
    changeFromDate,
    setStartofPeriod,
    type,
    sessionEnd,
    textColor,
    allDay,
    sessionStart,
  };

  return (
    <>
      {isMobile ? (
        <MobileExportDataItem {...desktopExportDataDialogBoxProps} />
      ) : (
        <DesktopExportDataItem {...desktopExportDataDialogBoxProps} />
      )}
    </>
  );
};

export default ExportDataItem;
