import { useLingui } from '@lingui/react';
import { Box, Typography, useTheme } from '@mui/material';
import WeightFormat from 'src/components/WeightFormat';
import { InsightQuery_insight_data, SafetyPortProps } from 'src/graphql/types';
import { propTranslationTabel } from 'src/helpers';
import { PropsIcons } from 'src/components/PropsIcon';

interface Props {
  data: InsightQuery_insight_data;
}

const MobileInsightItem = ({ data }: Props) => {
  const theme = useTheme();
  const { i18n } = useLingui();
  return (
    <Box my={1.5}>
      <Typography variant="caption" color="text.secondary">
        {data.time &&
          i18n.date(data.time, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
      </Typography>
      <Box color="text.primary" display="flex">
        <Box display="flex" alignItems="center" flex={1}>
          {data.type && (
            <Box display="flex" alignItems="center" sx={{ marginRight: 1 }}>
              <PropsIcons
                prop={data.type as SafetyPortProps}
                color={theme.palette.text.primary}
                size="1.6rem"
              />
            </Box>
          )}
          {propTranslationTabel(data.type)}
        </Box>
        {data.type === 'LINIS0001' ? (
          <WeightFormat weight={parseInt(data.value)} />
        ) : (
          data.value
        )}
      </Box>
    </Box>
  );
};

export default MobileInsightItem;
