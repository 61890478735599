import { t, Trans } from '@lingui/macro';
import BuildIcon from '@mui/icons-material/Build';
import Flex from 'src/components/Flex';
import {
  Box,
  Checkbox,
  Fab,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BedStatus } from 'src/components/BedStatus';
import { bedsQuery_beds as Bed, ParkingPlaceMode } from 'src/graphql/types';

interface Props {
  bed: Bed;
  canAdjustVirtual?: boolean;
  waypoint: React.ReactNode | null;
  selected: boolean;
  onSelectionChanged: () => void;
}

const mapLocationSourceTranslate = {
  [ParkingPlaceMode.IP_ETHERNET]: t`Static IP`,
  [ParkingPlaceMode.IP]: t`Static IP`,
  [ParkingPlaceMode.MANUAL]: t`Manual`,
  [ParkingPlaceMode.TAG]: t`IR Tag`,
};

const BedItem = ({
  bed,
  canAdjustVirtual,
  waypoint,
  selected,
  onSelectionChanged,
}: Props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/admin/beds/${bed.unitId}`);
  };

  const editVirtualBed = (e: any) => {
    preventDefault(e);
    navigate(`/admin/beds/${bed.unitId}/edit`);
  };

  const preventDefault = (e: any) => {
    e.stopPropagation();
  };

  const locationSource = bed.parkingPlaces?.[0]?.mode;
  return (
    <TableRow
      key={bed.unitId}
      onClick={handleClick}
      hover
      sx={{
        '&:hover': {
          '& img': {
            visibility: 'visible',
          },
        },
      }}
    >
      <TableCell>
        <Checkbox
          checked={selected}
          onClick={preventDefault}
          onChange={onSelectionChanged}
        />
      </TableCell>
      <TableCell>{bed.name}</TableCell>
      <TableCell>{bed.workspace ? bed.workspace.name : ''}</TableCell>
      <TableCell>{bed.ward ? bed.ward.name : ''}</TableCell>
      <TableCell>
        {!!bed.room && (
          <Tooltip title={t`Go to Room`}>
            <CustomLink
              to={`/admin/rooms/${bed.room.id}`}
              onClick={preventDefault}
            >
              {bed.room.name}
            </CustomLink>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Flex justifyContent="center">
          {locationSource && (
            <LocationSourceContainer>
              {mapLocationSourceTranslate[locationSource]}
            </LocationSourceContainer>
          )}
        </Flex>
      </TableCell>
      <TableCell>{bed.unitId}</TableCell>
      <TableCell>
        <CellItem>
          <BedStatus status={bed.state} ethernet={!!bed.ipAddressEthernet} />
        </CellItem>
        {waypoint}
      </TableCell>
      <TableCell sx={{ width: 110 }}>
        <Tooltip title={t`Go to Hl7 Log`}>
          <CustomLink
            to={`/admin/hl7-log?unitId=${bed.unitId}`}
            onClick={preventDefault}
          >
            <Trans>Last 2 hours</Trans>
          </CustomLink>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ width: 110 }}>
        <Tooltip title={t`Go to Insight Log`}>
          <CustomLink
            to={`/admin/insight?unitId=${bed.unitId}`}
            onClick={preventDefault}
          >
            <Trans>Last 4 hours</Trans>
          </CustomLink>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        {bed.isVirtual && canAdjustVirtual ? (
          <FabButton onClick={editVirtualBed} size="small" color="primary">
            <BuildIcon sx={{ fontSize: '16px' }} />
          </FabButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const CustomLink = styled(Link)(
  ({ theme: { palette } }) => `
  text-decoration: underline;
  color: currentColor;

  :hover {
    font-weight: 700;
    color: ${palette.primary.main};
  }
`,
);

const FabButton = styled(Fab)`
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  margin-left: 24px;
  position: initial;
`;

const LocationSourceContainer = styled(Box)(
  ({ theme: { spacing, palette } }) => `
  border-radius: 16px;
  border: 2px solid ${palette.primary.main};
  padding: ${spacing(0.5)} ${spacing(2)};
  color: ${palette.primary.main};
  align-self: center;
  display: table-cell;
`,
);

const CellItem = styled(Box)`
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default BedItem;
