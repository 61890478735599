import { Trans } from '@lingui/macro';
import { Button, ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';
import Flex from 'src/components/Flex';
import TopNavigator from 'src/components/TopNavigator';
import { adminTheme } from 'src/themes';
import { ReactComponent as LinisTextLogo } from 'src/images/linis-text-logo.svg';

export const ErrorPage = () => {
  return (
    <ThemeProvider theme={adminTheme}>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              backgroundColor: theme.palette.grey[200],
              padding: '0 32px',
              height: '64px',
            })}
          >
            <LinisTextLogo />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '0 8px',
              backgroundColor: theme.palette.grey[200],
              color: 'white',
              height: '64px',
            })}
          />
          <TopNavigator />
          <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Trans>Unexpected error occured.</Trans>
            <Box sx={{ marginTop: 4 }}>
              <Button
                onClick={() => window.location.reload()}
                variant="outlined"
              >
                Reload
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
