import { t } from '@lingui/macro';
import { Formik, FormikHelpers } from 'formik';
import AdminLoginForm, { AdminLoginFormValues } from './AdminLoginForm';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  hide: () => void;
}

const SUPERADMIN_ACCOUNT = 'superadmin';

const AdminLoginDialog = ({ hide }: Props) => {
  const { login } = useContext(AuthContext);

  const handleSubmit = async (
    { password }: AdminLoginFormValues,
    { setSubmitting, setErrors }: FormikHelpers<AdminLoginFormValues>,
  ) => {
    try {
      setSubmitting(false);
      await login(SUPERADMIN_ACCOUNT, password);
    } catch (e) {
      setErrors({
        password:
          (e as any).status === 401
            ? t`You have entered an incorrect name or password`
            : t`Something went wrong, please try again`,
      });
    }
  };

  const validate = (values: AdminLoginFormValues): { password?: string } => {
    if (values.password === '') {
      return { password: t`Enter the password` };
    }
    return {};
  };

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          top: '90px',
          right: '40px',
        }}
        onClick={hide}
      >
        <CloseIcon />
      </IconButton>
      <Formik<AdminLoginFormValues>
        initialValues={{ password: '' }}
        onSubmit={handleSubmit}
        component={AdminLoginForm}
        validate={validate}
      />
    </>
  );
};

export default AdminLoginDialog;
