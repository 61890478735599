import { t } from '@lingui/macro';
import { addDays, addHours, addMinutes } from 'date-fns';
import { ReportBedAggregation, TimeUnitType } from 'src/graphql/types';
import useDateFormat from 'src/lib/useDateFormat';
import { FilterParameter, ReportFilterParams } from '../types';

const useGraphTimeAxisFormat = () => {
  const { formatDateShort, formatTimeShort, formatMonth } = useDateFormat();

  const getTimeAxisLabel = (filter: ReportFilterParams) => {
    if (filter.viewByTime.unit === TimeUnitType.MINUTE) {
      return t`Minute`;
    }
    if (filter.viewByTime.unit === TimeUnitType.HOUR) {
      return t`Hour`;
    }
    if (filter.viewByTime.unit === TimeUnitType.DAY) {
      return t`Date`;
    }
    if (filter.viewByTime.unit === TimeUnitType.WEEK) {
      return t`Week`;
    }
    if (filter.viewByTime.unit === TimeUnitType.MONTH) {
      return t`Month`;
    }
    return undefined;
  };

  const formatTimeAxisLabel = (date: string, filter: ReportFilterParams) => {
    const {
      parameter,
      bedAggregation,
      viewByTime: { unit, value: count },
    } = filter;
    if (parameter === FilterParameter.ALOS) {
      return `${formatDateShort(date)} ${formatTimeShort(date)}`;
    }
    if (bedAggregation === ReportBedAggregation.NONE) {
      return `${formatDateShort(date)} ${formatTimeShort(date)}`;
    }
    switch (unit) {
      case TimeUnitType.DAY:
        if (count > 1) {
          return `${formatDateShort(date)} - ${formatDateShort(
            addDays(new Date(date), count),
          )}`;
        } else {
          return formatDateShort(date);
        }
      case TimeUnitType.HOUR:
        if (count > 1) {
          return `${formatTimeShort(date)} - ${formatTimeShort(
            addHours(new Date(date), count),
          )}`;
        } else {
          return formatTimeShort(date);
        }
      case TimeUnitType.MINUTE:
        if (count > 1) {
          return `${formatTimeShort(date)} - ${formatTimeShort(
            addMinutes(new Date(date), count),
          )}`;
        } else {
          return formatTimeShort(date);
        }
      case TimeUnitType.WEEK:
        return `${formatDateShort(date)} - ${formatDateShort(
          addDays(new Date(date), count * 7),
        )}`;
      case TimeUnitType.MONTH:
        return `${formatMonth(date)}`;
      default:
        return date;
    }
  };
  return {
    formatTimeAxisLabel,
    getTimeAxisLabel,
  };
};

export default useGraphTimeAxisFormat;
