import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createReportingReportMutation,
  deleteReportingReportMutation,
  editReportingReportMutation,
} from 'src/graphql/mutations';
import {
  reportingReportQuery,
  reportingReportsQuery,
} from 'src/graphql/queries';
import {
  CreateReportingReport,
  CreateReportingReportVariables,
  DeleteReportingReport,
  DeleteReportingReportVariables,
  EditReportingReport,
  EditReportingReportVariables,
  LocationType,
  ReportingReportQuery,
  ReportingReportQueryVariables,
  ReportBedAggregation,
  ReportLocationAggregation,
  ReportResultField,
  TimeUnitType,
} from 'src/graphql/types';
import { useDirty } from 'src/hooks';
import { DateOptionPreset } from 'src/lib/datePresetHelper';
import ReportGraphCard from './components/ReportGraphCard';
import ReportSaveAsModal from './components/ReportSaveAsModal';
import ReportTableCard from './components/ReportTableCard';
import { useReportFilter } from './components/useReportFilter';
import ReportingPageWrapper from './ReportingPageWrapper';
import {
  FilterParameter,
  ReportAggregation,
  ReportFilterParams,
} from './types';
import ReportDetailActionButtons from './components/ReportDetailActionButtons';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { t, Trans } from '@lingui/macro';
import ReportFilterFinal from './components/ReportFilterFinal';
import useReportFilterParams from './components/useReportFilterParams';

const initialParams: ReportFilterParams = {
  variant: 'SINGLE_VALUE',
  graphType: 'bar-horizontal',
  type: 'general',
  parameter: FilterParameter.OCCUPANCY,
  resultField: ReportResultField.OCCUPANCY,
  mainFilter: {
    field: 'connectionStatus',
    numberComparator: null,
    value: 1,
  },
  aggregation: ReportAggregation.ABSOLUTE_LAST,
  locationAggregation: ReportLocationAggregation.SUM,
  period: {
    preset: DateOptionPreset.today,
    from: null,
    to: null,
  },
  fieldFilters: [],
  bedAggregation: ReportBedAggregation.LAST,
  viewByLocation: LocationType.WORKSPACE,
  viewByTime: {
    value: 1,
    unit: TimeUnitType.DAY,
  },
};

interface Props {
  id?: string;
}

const ReportingReportPage = ({ id }: Props) => {
  const navigate = useNavigate();
  const { filter, updateFilter, loadFilter, updateFieldFilters } =
    useReportFilterParams();
  const [lastReportId, setLastReportId] = useState<string>();
  const { data } = useReportFilter(filter);

  const { isDirty, resetDirty, setInitValue, initValue } = useDirty(filter);

  const { data: reportingReportData, loading } = useQuery<
    ReportingReportQuery,
    ReportingReportQueryVariables
  >(reportingReportQuery, {
    variables: { id: id! },
    skip: !id,
  });
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfimOpen] = useState(false);
  const reportingReport = reportingReportData?.reportingReport;

  useEffect(() => {
    if (reportingReport && lastReportId !== id && reportingReport.id === id) {
      const value = JSON.parse(reportingReport.filterConfig);
      setInitValue(value);
      loadFilter(value);
      setLastReportId(id);
    } else if (lastReportId && !id) {
      setInitValue(initialParams);
      loadFilter(initialParams);
      setLastReportId(id);
    }
  }, [reportingReport, id, setInitValue, loadFilter, lastReportId]);

  const handleCreateCompleted = (data: CreateReportingReport) => {
    resetDirty();
    setSaveAsOpen(false);
    navigate(`/reporting/reports/${data.createReportingReport.id}`);
  };

  const handleDeleteCompleted = () => {
    resetDirty();
    setDeleteConfimOpen(false);
    navigate(`/reporting/`);
  };

  const handleEditCompleted = (data: EditReportingReport) => {
    resetDirty();
  };

  const [createMutation] = useMutation<
    CreateReportingReport,
    CreateReportingReportVariables
  >(createReportingReportMutation, {
    onCompleted: handleCreateCompleted,
    refetchQueries: [{ query: reportingReportsQuery }],
  });

  const [deleteMutation] = useMutation<
    DeleteReportingReport,
    DeleteReportingReportVariables
  >(deleteReportingReportMutation, {
    onCompleted: handleDeleteCompleted,
    refetchQueries: [{ query: reportingReportsQuery }],
  });

  const [editMutation] = useMutation<
    EditReportingReport,
    EditReportingReportVariables
  >(editReportingReportMutation, {
    onCompleted: handleEditCompleted,
    refetchQueries: [{ query: reportingReportsQuery }],
  });

  const handleSaveAsNew = async ({ name }: { name: string }) => {
    await createMutation({
      variables: {
        input: {
          name: name,
          filterConfig: JSON.stringify(filter),
        },
      },
    });
  };

  const handleNameChange = (name: string) => {
    if (!!id) {
      editMutation({
        variables: {
          input: {
            id: id,
            name: name,
          },
        },
      });
    }
  };

  const handleSave = () => {
    if (!!id) {
      editMutation({
        variables: {
          input: {
            id: id,
            filterConfig: JSON.stringify(filter),
          },
        },
      });
    } else {
      setSaveAsOpen(true);
    }
  };

  if (loading) return null;

  return (
    <ReportingPageWrapper
      title={reportingReport?.name || t`Create report`}
      onNameChanged={id ? handleNameChange : undefined}
      actions={
        <ReportDetailActionButtons
          isDirty={isDirty}
          onSave={handleSave}
          reportId={reportingReport?.id}
          onDiscard={() => updateFilter(initValue)}
          reportDashboards={reportingReport?.reportingDashboards}
          onOpenDeleteConfirm={() => setDeleteConfimOpen(true)}
          onOpenSaveAs={() => setSaveAsOpen(true)}
        />
      }
    >
      <ReportFilterFinal
        filter={filter}
        updateFilter={updateFilter}
        updateFieldFilter={updateFieldFilters}
        defaultExpanded={true}
        type="report"
      />
      <ReportGraphCard
        data={data}
        filter={filter}
        updateFilter={updateFilter}
      />
      <ReportTableCard
        data={data}
        filter={filter}
        sx={{ mt: 2 }}
        reportName={reportingReport?.name}
      />
      <ReportSaveAsModal
        open={saveAsOpen}
        onClose={() => setSaveAsOpen(false)}
        onConfirm={handleSaveAsNew}
        initialName={reportingReport?.name}
      />
      <ConfirmModal
        title={t`Delete report`}
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfimOpen(false)}
        message={
          <Trans>
            Do you really want to <b>delete report {reportingReport?.name}?</b>
          </Trans>
        }
        onConfirm={() =>
          deleteMutation({ variables: { id: reportingReport?.id! } })
        }
      />
    </ReportingPageWrapper>
  );
};

export default ReportingReportPage;
