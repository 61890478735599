import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material';
import Flex from 'src/components/Flex';

const Loading = () => {
  return (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default Loading;
