import { indexBy } from 'ramda';
import Flex from 'src/components/Flex';
import { FilterParameter, ReportData, ReportFilterParams } from '../types';
import { getReportTag, getReportValueForTime } from './graphHelpers';
import useGraphTimeAxisFormat from './useGraphTimeAxisFormat';
import useReportTagNames from './useReportTagNames';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NoChangeIcon from '@mui/icons-material/Remove';
import { Typography, Divider } from '@mui/material';
import { t } from '@lingui/macro';
import { convertToPounds } from 'src/lib/weightConverter';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import { WeightUnits } from 'src/graphql/types';

interface Props {
  data: ReportData[];
  filter: ReportFilterParams;
}

const HEIGHT = 300;

const ReportGraphScoreCard = ({ data, filter }: Props) => {
  const { formatTimeAxisLabel } = useGraphTimeAxisFormat();
  const { resolveName } = useReportTagNames();
  const { config } = useContext(AuthContext);
  const isImperial = config?.config?.unitSystem === WeightUnits.IMPERIAL;

  const groupTimes = data
    .map((row) => row.values.map((val) => val.time))
    .flat()
    .sort(
      (time, otherTime) =>
        new Date(time).getTime() - new Date(otherTime).getTime(),
    )
    .filter((val, idx, self) => self.indexOf(val) === idx);

  const tags = indexBy((row) => getReportTag(row.tags) || '', data);

  const graphData = groupTimes.map((groupTime) =>
    Object.keys(tags)
      .filter((key) => !!key)
      .map((tag) => ({
        tag: tag,
        name: `${formatTimeAxisLabel(groupTime, filter)} - ${resolveName(
          tags[tag].tags,
        )}`,
        value: getReportValueForTime(groupTime, 'value')(tags[tag]),
        unit: getReportValueForTime(groupTime, 'unit')(tags[tag]),
      })),
  );

  if (!graphData[0]) {
    return (
      <Flex
        height={HEIGHT}
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex>{t`No data found.`}</Flex>
      </Flex>
    );
  }
  const firstData = graphData[0];
  const lastData = graphData[graphData.length - 1];
  const resolved = getData(firstData, lastData, filter.parameter, isImperial);
  return (
    <Flex
      height={HEIGHT}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Typography sx={{ fontSize: '2rem', mx: 2 }}>
          {resolved.value}
        </Typography>
        <Divider
          sx={{ my: 2, width: '100%', backgroundColor: 'common.black' }}
        />
        {resolved.trend != null ? (
          <Flex alignItems="center">
            <TrendIndicator trend={resolved.trend} />
            <Flex>{resolved.trend}%</Flex>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};

const paramMap: Record<FilterParameter, string | 'onoff' | 'value'> = {
  [FilterParameter.ALL_SIDERAILS]: 'onoff',
  [FilterParameter.BACKREST]: 'onoff',
  [FilterParameter.BACKREST_30]: 'onoff',
  [FilterParameter.BACKREST_45]: 'onoff',
  [FilterParameter.BED_EXIT_MONITORING_ON]: 'onoff',
  [FilterParameter.CALFREST]: 'onoff',
  [FilterParameter.ALT_PHASE]: 'onoff',
  [FilterParameter.LATERAL_TILT]: 'onoff',
  [FilterParameter.LEFT_HEAD_SIDERAIL]: 'onoff',
  [FilterParameter.LEFT_SIDERAIL]: 'onoff',
  [FilterParameter.RIGHT_HEAD_SIDERAIL]: 'onoff',
  [FilterParameter.RIGHT_SIDERAIL]: 'onoff',
  [FilterParameter.LOWEST_POSTITION]: 'onoff',
  [FilterParameter.OCCUPANCY]: 'onoff',
  [FilterParameter.ONLINE]: 'onoff',
  [FilterParameter.PATIENT_PRESENCE]: 'onoff',
  [FilterParameter.SAFETY_POSITION]: 'onoff',
  [FilterParameter.TRENDELENBURG]: 'onoff',

  [FilterParameter.BACKREST_ANGLE]: 'value',
  [FilterParameter.WEIGHT]: 'value',

  [FilterParameter.ALOS]: '',

  [FilterParameter.BED_EXIT_COUNT]: '',
  [FilterParameter.BED_EXIT_REACTION]: '',
};

interface DataPoint {
  tag: string;
  name: string;
  value: any;
  unit: any;
}

const countValue = (acc: any, val: DataPoint) => {
  return val.value > 0 ? acc + 1 : acc;
};

const calcTrend = (firstValue?: number, lastValue?: number) => {
  if (lastValue == null) {
    return null;
  }
  if (!firstValue) {
    if (lastValue === 0) {
      return 0;
    } else {
      return 100;
    }
  }
  if (lastValue === 0) {
    return -100;
  }
  return Math.round(((lastValue - firstValue) / lastValue) * 100);
};

const getValue = (
  value: number,
  total: number,
  unit: any,
  parameter: FilterParameter,
) => {
  if (total > 1) {
    return `${value}/${total}`;
  }
  if (paramMap[parameter] === 'onoff') {
    return value ? t`on` : t`off`;
  }
  if (paramMap[parameter] === 'value') {
    if (unit === 'lb') {
      return `${convertToPounds(value)} lb`;
    }
    return `${value} ${unit}`;
  }
  return null;
};

const getData = (
  firstData: DataPoint[],
  lastData: DataPoint[],
  parameter: FilterParameter,
  isImperial: boolean,
) => {
  const total = lastData.length;
  const unit =
    lastData[0].unit === 'Kg' && isImperial ? 'lb' : lastData[0].unit;

  if (total > 1) {
    const firstVal = firstData.reduce(
      (acc, dataPoint) => countValue(acc, dataPoint),
      0,
    );
    const lastVal = lastData.reduce(
      (acc, dataPoint) => countValue(acc, dataPoint),
      0,
    );

    return {
      value: getValue(lastVal, total, unit, parameter),
      trend: calcTrend(firstVal, lastVal),
    };
  }

  const firstVal = firstData[0].value;
  const lastVal = lastData[0].value;

  return {
    value: getValue(lastVal, total, unit, parameter),
    trend: calcTrend(firstVal, lastVal),
  };
};

const TrendIndicator = ({ trend }: { trend: number }) => {
  if (trend > 0) {
    return <NorthEastIcon color="success" />;
  }
  if (trend < 0) {
    return <SouthEastIcon color="error" />;
  }
  return <NoChangeIcon />;
};

export default ReportGraphScoreCard;
