import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { logo } from '../graphql/queries';
import { Badge, Box, BoxProps, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { deleteLogoMutation } from '../graphql/mutations';

interface Props extends BoxProps {
  logoURL?: string;
  deletable?: boolean;
}

const Logo = (props: Props) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const { data } = useQuery(logo, { skip: !!props.logoURL });
  const [deleteLogo] = useMutation(deleteLogoMutation, {
    refetchQueries: [{ query: logo }],
  });

  const handleSetHovered = (hovered: boolean) => () => setHovered(hovered);

  const handleDeleteLogoClick = () => deleteLogo();

  const logoURL = data?.config?.logoURL ?? props.logoURL;
  if (logoURL) {
    const image = (
      <img
        src={logoURL}
        alt="custom-logo"
        style={{ maxWidth: '200px', maxHeight: '60px' }}
      />
    );
    return (
      <Box {...props}>
        {props.deletable ? (
          <Badge
            onMouseEnter={handleSetHovered(true)}
            onMouseLeave={handleSetHovered(false)}
            badgeContent={
              hovered ? (
                <IconButton onClick={handleDeleteLogoClick}>
                  <HighlightOffIcon style={{ cursor: 'pointer' }} />
                </IconButton>
              ) : undefined
            }
          >
            {image}
          </Badge>
        ) : (
          image
        )}
      </Box>
    );
  }

  return <></>;
};

export default Logo;
