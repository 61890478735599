import { Box } from '@mui/material';
import { useContext } from 'react';
import { MobileDashboardBeds } from './Dashboard/MobileDashboardBeds';
import MobileDashbordOverlay from './ModalOverlay/MobileDashbordOverlay';
import { MobileTopBar } from './Dashboard/MobileTopBar';
import { MobileModalContext } from './ModalOverlay/MobileModalProvider';
import { MobileSearchBar } from './Dashboard/SearchBar';

const MobileSafetyPortDashboard = () => {
  const { modal } = useContext(MobileModalContext);

  return (
    <Box width="100%" pb="2rem">
      {modal.type ? (
        <MobileDashbordOverlay
          data={{
            type: modal.type,
            bedId: modal.bedId,
          }}
        />
      ) : (
        <>
          <MobileTopBar />
          <MobileSearchBar />
          <MobileDashboardBeds />
        </>
      )}
    </Box>
  );
};

export default MobileSafetyPortDashboard;
