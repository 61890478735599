import { t } from '@lingui/macro';
import { Box, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AuthContext } from 'src/auth/AuthProvider';
import Flex from 'src/components/Flex';
import LogoutIcon from '@mui/icons-material/Logout';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Logo from 'src/images/safetyView/linet_avatar_rbg.svg';
import TuneIcon from '@mui/icons-material/Tune';

export const NAVIGATION_BAR_WIDTH = '80px';
interface Props {
  isAdmin?: boolean;
  reportingAccess?: boolean;
}

const NavigationBar = ({ isAdmin, reportingAccess }: Props) => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const location = useLocation();

  return (
    <Container>
      <Box width="100%">
        <LogoBox>
          <img src={Logo} width={50} height={50} alt="linis logo" />
        </LogoBox>
        <Box>
          <MenuItem
            active={location.pathname === '/safety-port/dashboard'}
            onClick={() => navigate('/safety-port/dashboard')}
            text={t`All beds`}
          >
            <ListAltIcon sx={{ fontSize: '1.75rem' }} />
          </MenuItem>
          <MenuItem
            active={location.pathname === '/safety-port/history'}
            onClick={() => navigate('/safety-port/history')}
            text={t`History`}
          >
            <ScheduleIcon sx={{ fontSize: '1.75rem' }} />
          </MenuItem>
          {reportingAccess && (
            <MenuItem
              active={location.pathname?.startsWith('/reporting')}
              onClick={() => navigate('/reporting')}
              text={t`Reporting`}
            >
              <AssessmentOutlinedIcon sx={{ fontSize: '1.75rem' }} />
            </MenuItem>
          )}
        </Box>
      </Box>
      <Box width="100%" mb={2}>
        <MenuItem
          onClick={() => navigate(isAdmin ? '/' : '/my-profile')}
          active={false}
          text={isAdmin ? t`Admin` : t`My Profile`}
        >
          <TuneIcon sx={{ transform: 'rotate(90deg)', fontSize: '1.75rem' }} />
        </MenuItem>
        <MenuItem onClick={logout} active={false} text={t`Logout`}>
          <LogoutIcon sx={{ fontSize: '1.75rem' }} />
        </MenuItem>
      </Box>
    </Container>
  );
};

export default NavigationBar;

interface MenuItemProps {
  active: boolean;
  text: string;
  children: any;
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

const MenuItem = ({ active, text, children, onClick }: MenuItemProps) => {
  return (
    <MenuContainer
      onClick={onClick}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[100]}`,
        color: active ? theme.palette.text.primary : theme.palette.grey[100],
        backgroundColor: active
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
      })}
    >
      {children}
      <Typography mt={0.5} fontSize="0.625rem">
        {text}
      </Typography>
    </MenuContainer>
  );
};

const Container = styled(Flex)(
  ({ theme: { palette } }) => `
  background-color: ${palette.grey[500]};
  width: 80px;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`,
);

const LogoBox = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 80px;
`;

const MenuContainer = styled('button')`
  padding: 6px 0;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4px;
  width: 72px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  &:hover {
    /* background-color: rgba(213, 213, 213, 0.5); */
  }
`;
