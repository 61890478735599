import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import {
  currentBedExitEventsQuery,
  safetyViewQuery,
  safetyViewWardBeds,
} from 'src/graphql/queries';
import {
  CurrentBedExitEvents,
  CurrentBedExitEventsVariables,
  SafetyViewQuery,
  SafetyViewQueryVariables,
  SafetyViewWardBeds,
  SafetyViewWardBedsVariables,
} from 'src/graphql/types';
import { notEmpty } from 'src/lib/notEmpty';
import { useWardData } from '../providers/WardDataProvider';

interface Options {
  withBedExitStart: boolean;
}

export const useSafetyViewData = ({ withBedExitStart }: Options) => {
  const { wardId } = useWardData();
  const [page, setPage] = useState(1);
  const [bedIds, setBedIds] = useState<string[] | null>(null);

  const { data: beeData, refetch: refetchBee } = useQuery<
    CurrentBedExitEvents,
    CurrentBedExitEventsVariables
  >(currentBedExitEventsQuery, {
    variables: {
      wardId,
    },
    skip: !wardId,
  });

  const { data, refetch: refetchParkingPlaces } = useQuery<
    SafetyViewQuery,
    SafetyViewQueryVariables
  >(safetyViewQuery, {
    variables: {
      wardId,
    },
    skip: !wardId,
    pollInterval: 30000,
    onCompleted: () => {
      setPage(1);
    },
  });

  const showOwnedBeds = data?.me?.userSetting?.showOwnedBeds;

  const { data: wardBedsData, refetch: refetchBeds } = useQuery<
    SafetyViewWardBeds,
    SafetyViewWardBedsVariables
  >(safetyViewWardBeds, {
    variables: {
      wardId: wardId as string,
    },
    skip: !wardId || !showOwnedBeds,
    pollInterval: 30000,
    onCompleted: () => {
      setPage(1);
    },
  });

  useEffect(() => {
    const incomingUnitIds = (data?.wardParkingPlaces || [])
      .map((pp) => pp.bed?.unitId)
      .filter(notEmpty)
      .sort();
    setBedIds(incomingUnitIds);
    if (withBedExitStart) {
      refetchBee();
    }
  }, [data?.wardParkingPlaces, refetchBee, setBedIds, withBedExitStart]);

  return {
    page,
    setPage,
    bee: beeData,
    bedIds,
    wardBeds: showOwnedBeds ? wardBedsData?.wardBeds : null,
    wardParkingPlaces: data?.wardParkingPlaces,
    me: data?.me,
    refetchBeds,
    refetchParkingPlaces,
  };
};
