import { Box, Button, styled } from '@mui/material';
import WardSelector from './WardSelector';
import Logo from '../../../components/Logo';
import Flex from 'src/components/Flex';
import { SettingsIcon } from './Icons/SettingsIcon';
import { t } from '@lingui/macro';
import AppVersion from 'src/components/AppVersion';
import { useState } from 'react';
import DashboardSettingsDialog from '../Setting/DashboardSettingsDialog';
import DatapointSettingsDialog from '../Setting/DatapointSettingsDialog';
import { CheckboxIcon } from './Icons/CheckboxIcon';

const TopBar = () => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [datapointsOpen, setDatapointsOpen] = useState(false);

  const onSettingsClose = () => {
    setSettingsOpen(false);
  };

  const onDatapointsClose = () => {
    setDatapointsOpen(false);
  };

  return (
    <Container px={2}>
      <WardSelector sx={{ px: 2 }} />
      <Flex alignItems="center" px={2} gap={2}>
        <AppVersion type="dashboard" />
        <Logo />
        <Button
          variant="text"
          sx={{ color: 'text.primary', fontSize: '1rem' }}
          startIcon={<CheckboxIcon />}
          onClick={() => setDatapointsOpen(true)}
        >
          {t`Datapoints`}
        </Button>
        <Button
          variant="text"
          sx={{ color: 'text.primary', fontSize: '1rem' }}
          startIcon={<SettingsIcon />}
          onClick={() => setSettingsOpen(true)}
        >
          {t`Settings`}
        </Button>
      </Flex>
      <DashboardSettingsDialog open={settingsOpen} onClose={onSettingsClose} />
      <DatapointSettingsDialog
        open={datapointsOpen}
        onClose={onDatapointsClose}
      />
    </Container>
  );
};

export default TopBar;

const Container = styled(Box)(
  ({ theme: { palette } }) => `
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${palette.background.default}; 
`,
);
