import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { MenuItem } from '@mui/material';
import { useField } from 'formik';
import { useMemo } from 'react';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  parkingPlaceNamesQuery,
  wardNamesQuery,
  workspacesQuery,
} from 'src/graphql/queries';
import {
  ParkingPlaceNamesQuery,
  ParkingPlaceNamesQueryVariables,
  ParkingPlaceNamesQuery_parkingPlaces,
  WardNamesQuery,
  WardNamesQueryVariables,
  WorkspacesQuery,
} from 'src/graphql/types';

interface Props {
  onParkingPlaceSelection?: () => void;
}

export const LocationSelector = ({ onParkingPlaceSelection }: Props) => {
  const [inputWorkspaceProps] = useField('workspace');
  const [inputWardProps, , inputWardHelpers] = useField('ward');
  const [, , inputParkingPlaceHelpers] = useField('parkingPlace');

  const { data: workspaceData } = useQuery<WorkspacesQuery>(workspacesQuery, {
    fetchPolicy: 'cache-first',
  });

  const { data: wardData } = useQuery<WardNamesQuery, WardNamesQueryVariables>(
    wardNamesQuery,
    {
      variables: {
        workspaceId: inputWorkspaceProps.value,
      },
      fetchPolicy: 'cache-first',
    },
  );

  const { data: parkingPlaceData } = useQuery<
    ParkingPlaceNamesQuery,
    ParkingPlaceNamesQueryVariables
  >(parkingPlaceNamesQuery, {
    variables: {
      wardId: inputWardProps.value,
    },
    fetchPolicy: 'cache-first',
  });

  const workspaceOptions = useMemo(() => {
    return (
      workspaceData?.workspaces.map((ws) => ({
        id: ws.id,
        name: ws.name,
      })) || []
    );
  }, [workspaceData?.workspaces]);

  const wardOptions = useMemo(() => {
    return (
      wardData?.wards.map((w) => ({
        id: w.id,
        name: w.name,
      })) || []
    );
  }, [wardData?.wards]);

  const getRoomName = (pp: ParkingPlaceNamesQuery_parkingPlaces) => {
    const ppName = pp.name && pp.name.trim();
    if (!ppName) {
      return pp.room.name;
    }
    return `${pp.room.name} [${ppName}]`;
  };
  const parkingPlacesOptions = useMemo(() => {
    return (
      parkingPlaceData?.parkingPlaces.map((pp) => ({
        id: pp.id,
        name: getRoomName(pp),
      })) || []
    );
  }, [parkingPlaceData?.parkingPlaces]);

  return (
    <>
      <FormikTextField
        name="workspace"
        select
        variant="outlined"
        label={t`Workspace`}
        sx={{ minWidth: '220px' }}
        onChange={() => {
          inputWardHelpers.setValue(undefined);
          inputParkingPlaceHelpers.setValue(undefined);
        }}
      >
        {workspaceOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </FormikTextField>
      {inputWorkspaceProps.value ? (
        <FormikTextField
          name="ward"
          select
          variant="outlined"
          label={t`Ward`}
          sx={{ minWidth: '220px' }}
          onChange={() => {
            inputParkingPlaceHelpers.setValue(undefined);
          }}
        >
          {wardOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </FormikTextField>
      ) : null}
      {inputWardProps.value ? (
        <FormikTextField
          name="parkingPlace"
          select
          variant="outlined"
          label={t`Parking Place`}
          sx={{ minWidth: '220px' }}
        >
          {parkingPlacesOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </FormikTextField>
      ) : null}
    </>
  );
};
