import {
  Collapse,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableRowProps,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

export interface Column<T> {
  header: string;
  key: keyof T | 'actions' | string;
  renderValue?: (rowData: T) => React.ReactNode;
  cellProps?: TableCellProps;
  headerCellProps?: TableCellProps;
}

interface Props<T> {
  rowKey: keyof T;
  data: T[];
  columns: Column<T>[];
  rowProps?: TableRowProps | ((rowData: T) => TableRowProps);
  renderExpanded?: (rowData: T) => React.ReactNode;
}

const TableOddEven = <T,>({
  rowKey,
  data,
  columns,
  rowProps = {},
  renderExpanded,
}: Props<T>) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map(
            ({ key, header, cellProps = {}, headerCellProps = {} }) => (
              <TableCell
                key={`head-${key}`}
                {...cellProps}
                {...headerCellProps}
              >
                {header}
              </TableCell>
            ),
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, idx) => {
          const props =
            typeof rowProps === 'function' ? rowProps(row) : rowProps;
          return (
            <React.Fragment key={`row-${row[rowKey]}`}>
              <TableRowStyled {...props} odd={idx % 2 === 0} type="header">
                {columns.map(({ renderValue, key, cellProps }) => (
                  <TableCell
                    key={`row-${row[rowKey]}-${key}`}
                    {...cellProps}
                    sx={{
                      ...(cellProps?.sx || {}),
                      cursor: props.onClick ? 'pointer' : 'cursor',
                    }}
                  >
                    {renderValue ? renderValue(row) : row[key as string]}
                  </TableCell>
                ))}
              </TableRowStyled>
              {renderExpanded && (
                <TableRowStyled
                  {...props}
                  onClick={undefined}
                  odd={idx % 2 === 0}
                  type="content"
                  style={{ height: 'unset' }}
                >
                  <TableCell colSpan={columns.length} style={{ padding: 0 }}>
                    <Collapse
                      in={!!props.selected}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box
                        sx={(theme) => ({
                          margin: 2,
                          padding: 2,
                          backgroundColor: theme.palette.background.paper,
                          color: theme.palette.text.primary,
                          borderRadius: '5px',
                          cursor: 'default',
                          fontSize: '1rem',
                        })}
                      >
                        {renderExpanded(row)}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRowStyled>
              )}
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableOddEven;

const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (name) => name !== 'odd',
})<{
  odd: boolean;
  type: 'header' | 'content';
}>(
  ({ theme: { palette }, odd, type }) => `
  & > td, 
  &:nth-of-type(odd) > td {
    background-color: ${odd ? palette.grey[200] : 'transparent'};
    color: ${palette.text.primary};
  }
 
  &.Mui-selected > td {
    background-color: ${palette.grey[500]} !important;
    color: ${palette.common.white};
    border-top-left-radius: ${type === 'content' ? 0 : 4};
    border-top-right-radius:  ${type === 'content' ? 0 : 4};
    border-bottom-left-radius:  ${type === 'content' ? 4 : 0};
    border-bottom-right-radius:  ${type === 'content' ? 4 : 0};
  }

`,
);
