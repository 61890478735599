import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createReportingGoalMutation,
  deleteReportingReportMutation,
  editReportingGoalMutation,
} from 'src/graphql/mutations';
import {
  reportingReportQuery,
  reportingReportsQuery,
} from 'src/graphql/queries';
import {
  DeleteReportingReport,
  DeleteReportingReportVariables,
  LocationType,
  ReportBedAggregation,
  ReportLocationAggregation,
  ReportResultField,
  TimeUnitType,
  CreateReportingGoal,
  CreateReportingGoalVariables,
  ReportingReportQuery,
  ReportingReportQueryVariables,
  EditReportingGoal,
  EditReportingGoalVariables,
} from 'src/graphql/types';
import { useDirty } from 'src/hooks';
import { DateOptionPreset } from 'src/lib/datePresetHelper';
import ReportGraphCard from './components/ReportGraphCard';
import ReportSaveAsModal from './components/ReportSaveAsModal';
import ReportTableCard from './components/ReportTableCard';
import { useReportFilter } from './components/useReportFilter';
import ReportingPageWrapper from './ReportingPageWrapper';
import {
  FilterParameter,
  ReportAggregation,
  ReportFilterParams,
} from './types';
import ReportDetailActionButtons from './components/ReportDetailActionButtons';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { t, Trans } from '@lingui/macro';
import ReportFilterFinal from './components/ReportFilterFinal';
import useReportFilterParams from './components/useReportFilterParams';
import { ReportGoal } from './components/ReportGoal';

const initialParams: ReportFilterParams = {
  variant: 'SINGLE_VALUE',
  graphType: 'bar-horizontal',
  type: 'general',
  parameter: FilterParameter.OCCUPANCY,
  resultField: ReportResultField.OCCUPANCY,
  mainFilter: {
    field: 'connectionStatus',
    numberComparator: null,
    value: 1,
  },
  aggregation: ReportAggregation.ABSOLUTE_LAST,
  locationAggregation: ReportLocationAggregation.SUM,
  period: {
    preset: DateOptionPreset.today,
    from: null,
    to: null,
  },
  fieldFilters: [],
  bedAggregation: ReportBedAggregation.LAST,
  viewByLocation: LocationType.WORKSPACE,
  viewByTime: {
    value: 1,
    unit: TimeUnitType.DAY,
  },
};

interface Props {
  id?: string;
}

const ReportingGoalPage = ({ id }: Props) => {
  const navigate = useNavigate();
  const { filter, updateFilter, loadFilter, updateFieldFilters } =
    useReportFilterParams();
  const [goal, setGoal] = useState<number>(0);
  const [lastReportId, setLastReportId] = useState<string>();
  const { data } = useReportFilter(filter);
  const { isDirty, resetDirty, setInitValue, initValue } = useDirty({
    ...filter,
    goal,
  });
  const { data: reportingReportData, loading } = useQuery<
    ReportingReportQuery,
    ReportingReportQueryVariables
  >(reportingReportQuery, {
    variables: { id: id! },
    skip: !id,
  });
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfimOpen] = useState(false);
  const reportingReport = reportingReportData?.reportingReport;
  useEffect(() => {
    if (reportingReport && lastReportId !== id && reportingReport.id === id) {
      const value = JSON.parse(reportingReport.filterConfig);
      setGoal(reportingReport?.goal || 0);
      setInitValue({
        ...value,
        goal: reportingReport?.goal || 0,
      });
      loadFilter(value);
      setLastReportId(id);
    } else if (lastReportId && !id) {
      setGoal(0);
      setInitValue({
        ...initialParams,
        goal: 0,
      });
      loadFilter(initialParams);
      setLastReportId(id);
    }
  }, [reportingReport, id, setInitValue, setGoal, loadFilter, lastReportId]);

  const handleCreateCompleted = (data: CreateReportingGoal) => {
    resetDirty();
    setSaveAsOpen(false);
    navigate(`/reporting/goals/${data.createReportingGoal.id}`);
  };

  const handleDeleteCompleted = () => {
    resetDirty();
    setDeleteConfimOpen(false);
    navigate(`/reporting/`);
  };

  const handleEditCompleted = (_: EditReportingGoal) => {
    resetDirty();
  };

  const [createMutation] = useMutation<
    CreateReportingGoal,
    CreateReportingGoalVariables
  >(createReportingGoalMutation, {
    onCompleted: handleCreateCompleted,
    refetchQueries: [{ query: reportingReportsQuery }],
  });

  const [deleteMutation] = useMutation<
    DeleteReportingReport,
    DeleteReportingReportVariables
  >(deleteReportingReportMutation, {
    onCompleted: handleDeleteCompleted,
    refetchQueries: [{ query: reportingReportsQuery }],
  });

  const [editMutation] = useMutation<
    EditReportingGoal,
    EditReportingGoalVariables
  >(editReportingGoalMutation, {
    onCompleted: handleEditCompleted,
    refetchQueries: [{ query: reportingReportsQuery }],
  });

  const handleSaveAsNew = async ({ name }: { name: string }) => {
    if (goal == null) {
      return;
    }
    await createMutation({
      variables: {
        input: {
          name: name,
          goal,
          filterConfig: JSON.stringify(filter),
        },
      },
    });
  };

  const handleNameChange = (name: string) => {
    if (!!id) {
      editMutation({
        variables: {
          input: {
            id: id,
            name: name,
          },
        },
      });
    }
  };

  const handleSave = () => {
    if (!!id && goal != null) {
      editMutation({
        variables: {
          input: {
            id: id,
            goal: goal,
            filterConfig: JSON.stringify(filter),
          },
        },
      });
    } else {
      setSaveAsOpen(true);
    }
  };

  if (loading) return null;

  return (
    <ReportingPageWrapper
      title={reportingReport?.name || t`Create goal`}
      onNameChanged={id ? handleNameChange : undefined}
      actions={
        <ReportDetailActionButtons
          isDirty={isDirty}
          onSave={handleSave}
          reportId={reportingReport?.id}
          onDiscard={() => {
            updateFilter(initValue);
            setGoal(initValue.goal);
          }}
          reportDashboards={reportingReport?.reportingDashboards}
          onOpenDeleteConfirm={() => setDeleteConfimOpen(true)}
          onOpenSaveAs={() => setSaveAsOpen(true)}
        />
      }
    >
      <ReportFilterFinal
        filter={filter}
        updateFilter={updateFilter}
        updateFieldFilter={updateFieldFilters}
        onKeyParameterChange={() => setGoal(0)}
        defaultExpanded={true}
        type="goal"
      />
      <ReportGoal
        goal={goal}
        parameter={filter.parameter}
        setGoal={setGoal}
        updateFilter={updateFilter}
        goalType={filter.goalType}
      />
      <ReportGraphCard
        data={data}
        filter={filter}
        updateFilter={updateFilter}
        goal={goal}
      />
      <ReportTableCard
        data={data}
        filter={filter}
        sx={{ mt: 2 }}
        reportName={reportingReport?.name}
      />
      <ReportSaveAsModal
        open={saveAsOpen}
        onClose={() => setSaveAsOpen(false)}
        onConfirm={handleSaveAsNew}
        type="goal"
        initialName={reportingReport?.name}
      />
      <ConfirmModal
        title={t`Delete report`}
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfimOpen(false)}
        message={
          <Trans>
            Do you really want to <b>delete report {reportingReport?.name}?</b>
          </Trans>
        }
        onConfirm={() =>
          deleteMutation({ variables: { id: reportingReport?.id! } })
        }
      />
    </ReportingPageWrapper>
  );
};

export default ReportingGoalPage;
