/**
 * useSubscription cases rerender on each message recieved.
 * To mitigate rerender cost on each message recieved, i have moved subscription to its own component
 */

import { useSubscription } from '@apollo/client';
import { threeFF } from 'src/graphql/subscriptions';
import { ThreeFF, ThreeFF_threeFF } from 'src/graphql/types';

interface Props {
  bedIds: string[] | null;
  skip: boolean;
  onData: (data: ThreeFF_threeFF) => void;
}

export const BedSubscription = ({ bedIds, skip, onData }: Props) => {
  useSubscription<ThreeFF>(threeFF, {
    variables: {
      bedIds,
    },
    skip,
    fetchPolicy: 'no-cache',
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.threeFF) {
        onData(data.subscriptionData.data.threeFF);
      }
    },
  });
  return null;
};
