import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { add, differenceInHours, isValid, sub } from 'date-fns';
import { cs, de, es, fr, ptBR } from 'date-fns/locale';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import {
  ListPageContent,
  ListPageRoot,
  TableWrapper,
  TopBar,
} from 'src/components/AdminList';
import { ActiveFilters, FilterValue, useFilter } from 'src/components/Filters';
import { useInsight } from 'src/components/Insight/useInsight';
import { TableHeader } from 'src/components/Table';
import { DateTableHeader } from 'src/components/Table/DateTableHeader';
import WeightFormat from 'src/components/WeightFormat';
import { SafetyPortProps } from 'src/graphql/types';
import { propTranslationTabel, timeIsValid } from 'src/helpers';
import { PropsIcons } from 'src/components/PropsIcon';
import {
  FilterProps,
  INSIGHT_FILTER,
  useInsightFilterOption,
} from './filterOptions';

const locales = { cs, fr, de, es, 'pt-br': ptBR };

const WAYPOINT_OFFSET = 5;

const Insight = () => {
  const { i18n } = useLingui();
  const [searchParams] = useSearchParams();
  const paramLoaded = useRef(false);
  const [queryParams, setQueryParams] = useState<
    Record<string, any | null | undefined>
  >({});
  const { filter, activeFilters, setFilterValue, clear, clearAll } =
    useFilter<FilterProps>({
      name: INSIGHT_FILTER,
      types: {
        bedId: t`INTEGRATION MODULE ID`,
        prop: t`BED NAME`,
        from: t`FROM`,
      },
    });
  const { bedIdOptions, propOptions } = useInsightFilterOption();
  const searchUnitId = searchParams.get('unitId');
  useEffect(() => {
    if (paramLoaded.current) {
      return;
    }
    paramLoaded.current = true;
    if (searchUnitId) {
      clearAll();
      setFilterValue('bedId', { id: searchUnitId, name: searchUnitId });
    }
  }, [searchUnitId, setFilterValue, clearAll]);
  const nowRef = useRef(new Date());
  useEffect(() => {
    const fromDate = filter.from?.id && new Date(filter.from?.id);
    setQueryParams({
      bedId: filter.bedId?.id,
      from:
        fromDate && timeIsValid(fromDate)
          ? fromDate
          : sub(new Date(), { hours: 4 }),
      prop: (filter.prop?.id && SafetyPortProps[filter.prop?.id]) || null,
    });
  }, [filter.bedId?.id, filter.from?.id, filter.prop?.id]);

  const { entries, canLoadMore, refetch, loadMore } = useInsight({
    unitId: queryParams.bedId,
    from: queryParams.from,
    prop: queryParams.prop,
  });

  const hasMore = () => {
    const from = filter.from?.id ? new Date(filter.from?.id) : new Date();
    if (!isValid(from)) {
      return false;
    }
    return differenceInHours(nowRef.current, from) > 3;
  };

  const goPrev = () => {
    const prevFrom = sub(queryParams.from, { hours: 4 });
    setFilterValue('from', {
      id: prevFrom.toISOString(),
      name: prevFrom.toLocaleString(),
    });
  };

  const goNext = () => {
    const nextFrom = add(queryParams.from, { hours: 4 });
    setFilterValue('from', {
      id: nextFrom.toISOString(),
      name: nextFrom.toLocaleString(),
    });
  };

  const handleRefetch = () => {
    refetch();
  };

  return (
    <>
      <ActiveFilters
        filters={activeFilters}
        clear={clear}
        clearAll={clearAll}
      />
      <ListPageRoot>
        <TopBar>
          <IconButton onClick={handleRefetch} sx={{ marginRight: '10px' }}>
            <RefreshIcon />
          </IconButton>

          <IconButton onClick={goPrev} sx={{ marginRight: '10px' }}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            onClick={goNext}
            disabled={!hasMore()}
            sx={{ marginRight: '10px' }}
          >
            <ChevronRightIcon />
          </IconButton>
        </TopBar>

        <ListPageContent>
          <TableWrapper sx={{ position: 'relative' }}>
            <Table stickyHeader sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableHeader
                      id="bedId"
                      label={t`INTEGRATION MODULE ID`}
                      options={bedIdOptions}
                      value={filter.bedId}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('bedId', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="prop"
                      label={t`SAFETY PORT PROP`}
                      options={propOptions}
                      value={filter.prop}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('prop', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Trans>VALUE</Trans>{' '}
                  </TableCell>
                  <TableCell>
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      locale={locales[i18n.locale]}
                    >
                      <DateTableHeader
                        id="timestamp"
                        label={t`TIMESTAMP`}
                        value={filter.from}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('from', value)
                        }
                      />
                    </LocalizationProvider>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries != null && entries.insight?.data
                  ? entries.insight.data.map((edge, index, insightData) => {
                      const value = edge.value;
                      return (
                        <TableRow key={index}>
                          <TableCell>{edge.unitId}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {edge.type !== null && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  style={{ marginRight: 8 }}
                                >
                                  <PropsIcons
                                    prop={edge.type as SafetyPortProps}
                                    size="1.6rem"
                                  />
                                </Box>
                              )}
                              {propTranslationTabel(edge.type)}
                            </div>
                            {Math.max(
                              0,
                              insightData.length - WAYPOINT_OFFSET,
                            ) === index && canLoadMore ? (
                              <Waypoint onEnter={loadMore} />
                            ) : null}
                          </TableCell>
                          <TableCell
                            title={value.length > 10 ? value : undefined}
                          >
                            {edge.type === 'LINIS0001' ? (
                              <WeightFormat weight={parseInt(value)} />
                            ) : value.length > 10 ? (
                              `...${value.substring(
                                value.length - 8,
                                value.length,
                              )}`
                            ) : (
                              value
                            )}
                          </TableCell>
                          <TableCell>
                            {edge.time
                              ? i18n.date(new Date(edge.time), {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                })
                              : null}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
            {!entries?.insight.data.length ? (
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography style={{ fontWeight: 'bold' }}>
                  {filter.bedId?.id ? (
                    <Trans>No results found</Trans>
                  ) : (
                    <Trans>Please select a bed</Trans>
                  )}
                </Typography>
              </Box>
            ) : null}
          </TableWrapper>
        </ListPageContent>
      </ListPageRoot>
    </>
  );
};

export default Insight;
