import { SettingsState } from 'src/pages/Admin/GlobalSettings/globalSettingsHelper';
import SpDashboardCareNotes from './SpDashboardCareNotes';
import SpDashboardPresetAssignments from './SpDashboardPresetAssignments';
import SpDashboardPresets from '../../../components/SafetyPositionPresetSettings';
import { Divider } from '@mui/material';

interface NCPSettings {
  noteConfirmationPeriod: number;
  setSettings: React.Dispatch<React.SetStateAction<SettingsState>>;
  onSave: () => null | Promise<any>;
}

interface Props {
  npcSettingProps: NCPSettings;
}

const SpDashboardContent = ({ npcSettingProps }: Props) => {
  return (
    <>
      <SpDashboardPresets disabled />
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
      <SpDashboardPresetAssignments />
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
      <SpDashboardCareNotes {...npcSettingProps} />
    </>
  );
};

export default SpDashboardContent;
