import { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';
import { useQuery } from '@apollo/client';
import { trends } from 'src/graphql/queries';
import {
  getDaysData,
  getMobilizationWeekDates,
  getSummaryData,
  mobilizationdayHeight,
  selectedDatapointsInitState,
  SelectedDatapointsType,
} from '../../helpers/mobilization';
import DataPointsSelector from './DatapointsSelector';
import { t } from '@lingui/macro';
import SummaryBox from './SummaryBox';
import { SessionTrends, SessionTrendsVariables } from 'src/graphql/types';
import MobilizationDay from './MobilizationDay';
import { parseDate } from 'src/helpers';
import MobilizationTimeline from './MobilizationTimeline';
import MobilizationLines from './MobilizationLines';
import MobilizationDateLine from './MobilizationDateLine';
import { getMinutes, startOfDay } from 'date-fns';
import DateInput from 'src/components/DateInput';

interface Props {
  patientSessionId: string;
  sessionEnd?: string | null;
  sessionStart?: string | null;
}

const Mobilization = ({
  patientSessionId,
  sessionEnd,
  sessionStart,
}: Props) => {
  const [selectedDatapoints, setSelectedDatapoints] =
    useState<SelectedDatapointsType>(selectedDatapointsInitState);
  const mobilizationContainer = useRef(null);
  const [date, setDate] = useState(
    startOfDay(parseDate(sessionEnd) || new Date()),
  );
  const dates = getMobilizationWeekDates(date);
  const { data: days } = useQuery<SessionTrends, SessionTrendsVariables>(
    trends,
    {
      skip: !patientSessionId || !dates,
      variables: {
        id: patientSessionId,
        timezoneOffset: new Date().getTimezoneOffset(),
        firstDate: dates[0],
        secondDate: dates[1],
        thirdDate: dates[2],
        fourthDate: dates[3],
        fifthDate: dates[4],
        sixthDate: dates[5],
        seventhDate: dates[6],
      },
    },
  );

  useEffect(() => {
    const element: any = mobilizationContainer.current;
    if (element) {
      const view = mobilizationdayHeight / 96;
      const scrollValue = Math.floor(getMinutes(date) / 15) - 24;
      if (!!element) element.scrollTop = scrollValue * view;
    }
  }, [date]);

  const summaryData = days ? getSummaryData(selectedDatapoints, days) : null;
  const daysData = days ? getDaysData(selectedDatapoints, days) : null;
  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between">
        <DataPointsSelector
          setSelectedDatapoints={setSelectedDatapoints}
          selectedDatapoints={selectedDatapoints}
        />
        <DateInput
          label={t`Last day of the week`}
          max={sessionEnd || new Date()}
          min={sessionStart}
          value={date}
          onChange={(date) => setDate(startOfDay(date))}
          variant="outlined"
          size="small"
        />
      </Box>
      <MobilizationDateLine
        minDate={sessionStart ? new Date(sessionStart) : new Date()}
        maxDate={sessionEnd ? new Date(sessionEnd) : new Date()}
        date={date}
        setDate={setDate}
        dates={dates}
      />
      <Container ref={mobilizationContainer}>
        <Box display="flex" position="relative" sx={{ right: '-16px' }}>
          {daysData &&
            daysData.map((dayData, index) => (
              <MobilizationDay
                key={`mobilization-day-${index}`}
                selectedDatapoints={selectedDatapoints}
                dayData={dayData}
              />
            ))}
        </Box>
        <MobilizationLines />
        <MobilizationTimeline />
      </Container>
      <SummaryBox summaryData={summaryData} />
    </Box>
  );
};

export default Mobilization;

const Container = styled(Box)({
  height: '184px',
  paddingTop: '4px',
  display: 'flex',
  justifyContent: 'flex-end',
  overflowY: 'scroll',
  overflowX: 'visible',
  position: 'relative',
  paddingRight: '16px',
});
