import { Box } from '@mui/material';
import DataTransferConfigItem from './DataTransferConfigItem';
import SwVersion from './SwVersion';
import { SubHeaderText } from './components/SubHeaderText';
import { Trans } from '@lingui/macro';
import { ThinTextField } from './components/ThinTextField';
import { FormikProps } from 'formik';
import { SafetyPortFormValues } from './superAdminHelper';

type Props = Pick<
  FormikProps<SafetyPortFormValues>,
  'values' | 'setFieldValue' | 'handleChange' | 'handleBlur'
>;

const DataTransferConfig = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
}: Props) => {
  return (
    <Box>
      <Box display="flex" ml={2} width={250} justifyContent="space-between">
        <SubHeaderText>
          <Trans>Data Transfer</Trans>
        </SubHeaderText>
      </Box>
      <Box>
        <Box ml={2} display="flex">
          <ThinTextField
            type="text"
            name="spVersion"
            value={values.spVersion || ''}
            onChange={handleChange}
            variant="outlined"
          />
          <SwVersion />
        </Box>
        <Box sx={{ marginLeft: '-16px' }}>
          <DataTransferConfigItem
            enabledKey="patientEnabled"
            expirationKey="patientLicenceExpiration"
            name="Patient Safety"
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <DataTransferConfigItem
            enabledKey="respiratoryMobilizationEnabled"
            expirationKey="respiratoryMobilizationLicenceExpiration"
            name="Respiratory & Mobilization"
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <DataTransferConfigItem
            enabledKey="bedManagementEnabled"
            expirationKey="bedManagementLicenceExpiration"
            name="Bed Management"
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <DataTransferConfigItem
            enabledKey="utilizationEnabled"
            expirationKey="utilizationLicenceExpiration"
            name="Utilization"
            setFieldValue={setFieldValue}
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DataTransferConfig;
