import { ThemeProvider } from '@mui/material';
import { adminTheme } from 'src/themes';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
  return (
    <ThemeProvider theme={adminTheme}>
      <Outlet />
    </ThemeProvider>
  );
};

export default DefaultLayout;
