import { Trans } from '@lingui/macro';
import { Box, Button, TextField, styled, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { PasswordToggleAdornment } from 'src/components/PasswordInput/PasswordToggleAdornment';

export interface LoginFormValues {
  username: string;
  password: string;
}

interface Props
  extends Pick<
    FormikProps<LoginFormValues>,
    | 'errors'
    | 'handleSubmit'
    | 'isSubmitting'
    | 'values'
    | 'touched'
    | 'handleChange'
    | 'handleBlur'
  > {}

const LoginForm = ({
  handleSubmit,
  values,
  isSubmitting,
  handleChange,
  handleBlur,
  touched,
  errors,
}: Props) => {
  const [inputType, setInputType] = useState<'text' | 'password'>('password');

  const toggle = (event: any) => {
    event.preventDefault();
    setInputType((oldState) => (oldState === 'password' ? 'text' : 'password'));
  };
  return (
    <Box sx={{ flex: 1 }}>
      <HeaderTitle>
        <Trans>Hey, welcome back.</Trans>
      </HeaderTitle>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 4 }}>
          <LabelField>
            <Trans>Login</Trans>
          </LabelField>
          <TextField
            sx={{ mb: 1 }}
            name="username"
            autoFocus={true}
            onBlur={handleBlur}
            value={values.username}
            onChange={handleChange}
            variant="outlined"
            helperText={(touched.username && errors.username) || ''}
            error={touched.username && errors.username !== undefined}
          />
          <LabelField>
            <Trans>Password</Trans>
          </LabelField>
          <TextField
            type={inputType}
            name="password"
            onBlur={handleBlur}
            value={values.password}
            onChange={handleChange}
            variant="outlined"
            helperText={(touched.password && errors.password) || ''}
            error={touched.password && errors.password !== undefined}
            InputProps={{
              endAdornment: (
                <PasswordToggleAdornment toggle={toggle} type={inputType} />
              ),
            }}
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={isSubmitting}
        >
          <Trans>Log in</Trans>
        </Button>
      </form>
    </Box>
  );
};

export default LoginForm;

const HeaderTitle = styled(Typography)(
  ({ theme: { spacing } }) => `
  text-align: center;
  width: 100%;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: ${spacing(6)};
  margin-top: ${spacing(12)};
`,
);

const LabelField = styled(Box)(
  ({ theme: { spacing } }) => `
  font-weight: 900;
  font-size: 1rem;
  margin: ${spacing(0.5)};
`,
);
