import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { meLocale } from '../graphql/types';
import { isSpDataEnabled, isSpDashboardEnabled } from '../lib/configHelper';
import { superConfigFragment } from '../graphql/fragments';
import { en, cs, fr, es, de, pt } from 'make-plural/plurals';
import { useQuery } from '@apollo/client';
import { messages as enMessages } from '../i18n/en/messages';
import { messages as csMessages } from '../i18n/cs/messages';
import { messages as frMessages } from '../i18n/fr/messages';
import { messages as esMessages } from '../i18n/es/messages';
import { messages as deMessages } from '../i18n/de/messages';
import { messages as ptBrMessages } from '../i18n/pt-br/messages';

export const availableLocales = {
  en: 'English',
  cs: 'Čeština',
  fr: 'Français',
  de: 'Deutsch',
  es: 'Español',
  'pt-br': 'Português (BR)',
};

export const limitedLocales = {
  en: 'English',
  cs: 'Čeština',
};

const getDefaultLocale = () => {
  // ts doesn't know ie11 proprietary userLanguage property obviously because ie is ***
  let candidate: string | null =
    navigator.language || (navigator as any).userLanguage;
  if (candidate == null) {
    return process.env.REACT_APP_DEFAULT_LOCALE || 'en';
  }

  if (availableLocales[candidate] != null) {
    return candidate;
  }

  candidate = candidate.split('-')[0];
  if (availableLocales[candidate] != null) {
    return candidate;
  }
  return process.env.REACT_APP_DEFAULT_LOCALE || 'en';
};

const defaultlocale = getDefaultLocale();

type Props = {
  children?: React.ReactNode;
};

i18n.loadLocaleData({
  en: { plurals: en },
  cs: { plurals: cs },
  fr: { plurals: fr },
  es: { plurals: es },
  de: { plurals: de },
  'pt-br': { plurals: pt },
});
i18n.load({
  en: enMessages,
  cs: csMessages,
  fr: frMessages,
  de: deMessages,
  es: esMessages,
  'pt-br': ptBrMessages,
});
i18n.activate(defaultlocale);

const I18nLoader = ({ children }: Props) => {
  const { data, loading } = useQuery<meLocale>(localeQuery, {
    fetchPolicy: 'cache-first',
  });
  const locale = data?.me?.locale;
  const spDataEnabled = isSpDataEnabled(data as any);
  const spDashboardEnabled = isSpDashboardEnabled(data as any);
  const actualLocale = loading ? null : locale || defaultlocale;
  const selectedLocale =
    spDataEnabled || spDashboardEnabled ? actualLocale : 'en';
  useEffect(() => {
    if (!selectedLocale) {
      return;
    }

    i18n.activate(selectedLocale);
  }, [selectedLocale]);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

const localeQuery = gql`
  query meLocale {
    me {
      id
      locale
    }
    superConfig {
      ...SuperConfigFragment
    }
  }
  ${superConfigFragment}
`;

export default I18nLoader;
