import { t } from '@lingui/macro';
import { add, format, startOfDay, sub } from 'date-fns';
import { SessionTrends } from '../../../graphql/types';
import {
  getMobilizationSummaryItemTime,
  IncommingDayDataType,
  SelectedDatapointsEnum,
  SelectedDatapointsType,
} from './mobilization';
import { GraphType } from './patientView';

export enum ExportType {
  'view' = 'view',
  'careNotes' = 'careNotes',
  'mobilization' = 'mobilization',
  'insight' = 'insight',
  'statistics' = 'statistics',
}

export interface SelectedExportDayType {
  show: boolean;
  date: Date;
}

export interface SelectedExportPeriodType {
  show: boolean;
  from: Date;
  to: Date;
}

export interface ChartsExportDataValuesType {
  value: number | string | null;
  text: string | null;
  time: string | null;
}
export interface ChartsExportDataType {
  img: string;
  title: string | null;
  values: ChartsExportDataValuesType[];
}

export interface GraphsExportType {
  timelineDates: (string | null)[];
  data: GraphsExportDataTyp[];
}

export interface GraphsExportDataTyp {
  img: string;
  title: string | null;
  type: GraphType;
  range: string[];
}

export interface SummaryExportDataType {
  time: string;
  type: SelectedDatapointsEnum;
  text: string;
}

export interface MobilizationExportDataType {
  days: {
    day: string;
    img: string;
  }[];
  timeline: string[];
  summaryData: SummaryExportDataType[][] | null;
}

export interface SelectedExportType {
  view: SelectedExportDayType;
  careNotes: SelectedExportPeriodType;
  mobilization: SelectedExportDayType;
  statistics: SelectedExportPeriodType;
  insight: SelectedExportPeriodType;
}
export const getExportTypeData = (
  type: ExportType,
  data: SelectedExportType,
) => {
  switch (type) {
    case ExportType.statistics:
      return data.statistics;
    case ExportType.view:
      return data.view;
    case ExportType.careNotes:
      return data.careNotes;
    case ExportType.mobilization:
      return data.mobilization;
    case ExportType.insight:
      return data.insight;
    default:
      return null;
  }
};

export const getExportTypeText = (type: ExportType) => {
  switch (type) {
    case ExportType.statistics:
      return `${t`Statistics`} (pdf)`;
    case ExportType.view:
      return `${t`24 Hours view`} (pdf)`;
    case ExportType.careNotes:
      return `${t`Care Notes`} (xls)`;
    case ExportType.mobilization:
      return `${t`Mobilization`} (pdf)`;
    case ExportType.insight:
      return `${t`Insight`} (xls)`;
    default:
      return null;
  }
};

export const exportMobilizationWidth = 80;
export const exportMobilizationHeight = 540;

export const getMobilizationExportTimelineValues = () => {
  const date = startOfDay(new Date());
  const timelineArray = new Array(25).fill(0);
  const result = timelineArray.map((_, index) => {
    if (index === 0) {
      return format(date, 'h:mm a');
    }
    const editedDate = add(date, { hours: index });
    return format(editedDate, 'h:mm a');
  });
  return result;
};

export interface SummaryExportDataType {
  time: string;
  type: SelectedDatapointsEnum;
}

export const getExportSummaryData = (
  selected: SelectedDatapointsType,
  daysData: SessionTrends,
) => {
  const data: SummaryExportDataType[][] = [
    getSummaryDataValues(selected, daysData.firstDay),
    getSummaryDataValues(selected, daysData.secondDay),
    getSummaryDataValues(selected, daysData.thirdDay),
    getSummaryDataValues(selected, daysData.fourthDay),
    getSummaryDataValues(selected, daysData.fifthDay),
    getSummaryDataValues(selected, daysData.sixthDay),
    getSummaryDataValues(selected, daysData.seventhDay),
  ];
  return data;
};

export const getSummaryDataValues = (
  selected: SelectedDatapointsType,
  data?: IncommingDayDataType,
) => {
  if (!!data) {
    const result: SummaryExportDataType[] = [];
    if (!!selected.alt && data.alt?.duration != null) {
      result.push({
        time: getMobilizationSummaryItemTime(data.alt?.duration),
        type: SelectedDatapointsEnum.alt,
        text: t`Alt`,
      });
    }
    if (!!selected.matress && data.mattressType?.duration != null) {
      result.push({
        time: getMobilizationSummaryItemTime(data.mattressType?.duration),
        type: SelectedDatapointsEnum.matress,
        text: t`Matress`,
      });
    }
    if (!!selected.mobilift && data.mobilift?.duration != null) {
      result.push({
        time: getMobilizationSummaryItemTime(data.mobilift?.duration),
        type: SelectedDatapointsEnum.mobilift,
        text: t`Mobi-Lift`,
      });
    }
    if (!!selected.trendelenburg && data.trendelenburg?.duration != null) {
      result.push({
        time: getMobilizationSummaryItemTime(data.trendelenburg?.duration),
        type: SelectedDatapointsEnum.trendelenburg,
        text: t`Trendelenburg`,
      });
    }
    if (!!selected.lateral && data.lateralTilt?.duration != null) {
      result.push({
        time: getMobilizationSummaryItemTime(data.lateralTilt?.duration),
        type: SelectedDatapointsEnum.lateral,
        text: t`Lateral`,
      });
    }
    if (!!selected.backrest30 && data.backrest30?.duration != null) {
      result.push({
        time: getMobilizationSummaryItemTime(data.backrest30?.duration),
        type: SelectedDatapointsEnum.backrest30,
        text: t`Backrest 30°`,
      });
    }
    if (!!selected.backrest45 && data.backrest45?.duration != null) {
      result.push({
        time: getMobilizationSummaryItemTime(data.backrest45?.duration),
        type: SelectedDatapointsEnum.backrest45,
        text: t`Backrest 45°`,
      });
    }
    return result;
  } else return [];
};

export const getExportMobilizationWeekDates = (date: Date) => {
  const result: string[] = new Array(7).fill(0).map((_, index) => {
    if (index === 0) {
      return format(date, 'dd/MM/yyyy');
    } else {
      const editedDate = sub(date, { days: index });
      return format(editedDate, 'dd/MM/yyyy');
    }
  });
  return result.reverse();
};

export const getPatientViewTimelineData = (date: Date) => {
  const dates = Array(25)
    .fill(0)
    .map((_, index) => {
      if (index % 2 === 0) {
        return format(sub(date, { hours: index }), ' h:mm a');
      } else {
        return null;
      }
    });
  return dates.reverse();
};
