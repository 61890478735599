import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
  Pagination,
  Button,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { OpenModalType } from '../BedDetailModal/modalTypes';
import { t, Trans } from '@lingui/macro';
import {
  HistorySessions,
  HistorySessionsVariables,
  HistorySessions_patientSessions_edges_node,
  Order,
  PatientSessionOrderBy,
} from 'src/graphql/types';
import { differenceInMinutes } from 'date-fns';
import { TableHeader } from 'src/components/Table';
import {
  DATE_COMPARATOR,
  getSortState,
  SortKey,
  STRING_COMPARATOR,
  useCustomSorting,
} from 'src/lib/sortingHook';
import { ActiveFilters, FilterValue, useFilter } from 'src/components/Filters';
import {
  FilterProps,
  SESSION_FILTER,
  useSessionFilterOption,
} from './filterOptions';
import { WardDataContext } from '../providers/WardDataProvider';
import { DateTableHeader } from 'src/components/Table/DateTableHeader';
import { useQuery } from '@apollo/client';
import { historySessions } from 'src/graphql/queries';
import Flex from 'src/components/Flex';
import { SearchTableHeader } from 'src/components/Table/SearchTableHeader';
import { useLingui } from '@lingui/react';
import ExportDataDialog from '../Export/components/ExportDataDialog';

interface Props {
  setOpenModal: React.Dispatch<React.SetStateAction<OpenModalType>>;
}

export interface OpenModalDataType {
  id: string | null;
  note: string | null;
  name: string | null;
  sessionStart: string | null;
  sessionEnd: string | null;
  safetyPositionPresetId: string | null;
}

const PAGE_SIZE = 20;

const evaluators = {
  [SortKey.NAME]: (session: HistorySessions_patientSessions_edges_node) =>
    session?.name || '',

  [SortKey.START]: (session: HistorySessions_patientSessions_edges_node) =>
    session.sessionStart || 0,
  [SortKey.END]: (session: HistorySessions_patientSessions_edges_node) =>
    session.sessionEnd || 0,
};

const comparators = {
  [SortKey.NAME]: STRING_COMPARATOR,
  [SortKey.START]: DATE_COMPARATOR,
  [SortKey.END]: DATE_COMPARATOR,
};

const mapSortOrderBy = {
  [SortKey.NAME]: PatientSessionOrderBy.NAME,
  [SortKey.START]: PatientSessionOrderBy.SESSION_START,
  [SortKey.END]: PatientSessionOrderBy.SESSION_END,
};

const mapSortOrder = {
  desc: Order.DESC,
  asc: Order.ASC,
};

const NewSessionHistoryTable = ({ setOpenModal }: Props) => {
  const { sorting, setSort } =
    useCustomSorting<HistorySessions_patientSessions_edges_node>(
      evaluators,
      comparators,
    );
  const [sessionToExport, setSessionToExport] =
    useState<HistorySessions_patientSessions_edges_node | null>(null);

  const getSortingState = (key: SortKey) => getSortState(sorting, key);
  const { i18n } = useLingui();
  const { wardId } = useContext(WardDataContext);
  const prevWardId = useRef<string | null>(null);
  const { filter, activeFilters, setFilterValue, clear, clearAll } =
    useFilter<FilterProps>({
      name: SESSION_FILTER,
      types: {
        sessionName: t`SESSION NAME`,
        room: t`ROOM`,
        sessionStarted: `SESSION STARTED`,
        sessionEnded: t`SESSION ENDED`,
      },
    });
  const [queryParams, setQueryParams] = useState<{
    sessionName?: string;
    room?: string;
    sessionStarted?: string;
    sessionEnded?: string;
    offset: number;
  }>({
    offset: 0,
  });

  // reset filter is ward is changed
  useEffect(() => {
    if (wardId !== prevWardId.current) {
      prevWardId.current = wardId;
      clearAll();
    }
  }, [wardId, clearAll]);

  const page = useMemo(() => {
    return queryParams.offset / PAGE_SIZE + 1;
  }, [queryParams.offset]);

  const ordering = useMemo(() => {
    if (!sorting?.key || !sorting.order) {
      return null;
    }
    return {
      orderBy: mapSortOrderBy[sorting.key],
      order: mapSortOrder[sorting.order],
    };
  }, [sorting?.key, sorting?.order]);

  useEffect(() => {
    setQueryParams({
      sessionName: filter.sessionName?.id || '',
      room: filter.room?.id || '',
      sessionStarted: filter.sessionStarted?.id || '',
      sessionEnded: filter.sessionEnded?.id || '',
      offset: 0,
    });
  }, [
    filter.sessionName?.id,
    filter.room?.id,
    filter.sessionStarted?.id,
    filter.sessionEnded?.id,
  ]);

  const { data: sessionData, loading } = useQuery<
    HistorySessions,
    HistorySessionsVariables
  >(historySessions, {
    variables: {
      input: {
        wardId,
        name: queryParams.sessionName,
        roomId: queryParams.room ?? null,
        dateFrom: queryParams.sessionStarted ?? null,
        dateTo: queryParams.sessionEnded ?? null,
        first: PAGE_SIZE,
        offset: queryParams.offset,
        orderBy: ordering?.orderBy,
        order: ordering?.order,
      },
    },
  });

  const pageCount = useMemo(() => {
    return Math.floor(
      Math.max(0, (sessionData?.patientSessions.totalCount || 0) - 1) /
        PAGE_SIZE +
        1,
    );
  }, [sessionData?.patientSessions.totalCount]);

  const sessions =
    sessionData?.patientSessions.edges.map((edge) => edge.node) || [];

  const { roomOptions } = useSessionFilterOption(wardId);

  const changePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setQueryParams((prev) => ({
      ...prev,
      offset: Math.max(0, newPage - 1) * PAGE_SIZE,
    }));
  };

  const closeExportDialog = () => {
    setSessionToExport(null);
  };
  const exportHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    session: HistorySessions_patientSessions_edges_node,
  ) => {
    e.stopPropagation();
    setSessionToExport(session);
  };
  return (
    <>
      <ActiveFilters
        sx={{ ml: 0 }}
        filters={activeFilters}
        clear={clear}
        clearAll={clearAll}
      />
      <TableContainer
        sx={{
          borderRadius: '10px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
          marginBottom: 4,
          overflow: 'hidden',
        }}
        component={Paper}
      >
        <Table
          sx={{
            minWidth: 650,
          }}
          aria-label="session history"
        >
          <TableHead>
            <TableRow
              sx={{
                '&&& .MuiButtonIcon-root': {
                  color: (theme) => theme.palette.primary.contrastText,
                },
              }}
            >
              <TableCell
                align="left"
                sx={{
                  width: '15%',
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.common.divider}`,
                }}
              >
                <SearchTableHeader
                  id="name"
                  sort={() => setSort(SortKey.NAME)}
                  sortState={() => getSortingState(SortKey.NAME)}
                  value={filter.sessionName}
                  setValue={(value: FilterValue | null) =>
                    setFilterValue('sessionName', value)
                  }
                  label={t`SESSION NAME`}
                />
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  width: '15%',
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.common.divider}`,
                }}
              >
                <TableHeader
                  id="room"
                  label={t`ROOM NAME`}
                  options={roomOptions}
                  value={filter.room}
                  setValue={(value: FilterValue | null) =>
                    setFilterValue('room', value)
                  }
                />
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  width: '12%',
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.common.divider}`,
                }}
              >
                <Typography>{t`Note`}</Typography>
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  width: '15%',
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.common.divider}`,
                }}
              >
                <DateTableHeader
                  id="sessionStart"
                  sort={() => setSort(SortKey.START)}
                  sortState={() => getSortingState(SortKey.START)}
                  label={t`SESSION START`}
                  value={filter.sessionStarted}
                  setValue={(value: FilterValue | null) =>
                    setFilterValue('sessionStarted', value)
                  }
                />
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  width: '15%',
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.common.divider}`,
                }}
              >
                <DateTableHeader
                  id="sessionEnd"
                  sort={() => setSort(SortKey.END)}
                  sortState={() => getSortingState(SortKey.END)}
                  label={t`SESSION END`}
                  value={filter.sessionEnded}
                  setValue={(value: FilterValue | null) =>
                    setFilterValue('sessionEnded', value)
                  }
                />
              </TableCell>
              <TableCell
                align="left"
                colSpan={2}
                sx={{
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.common.divider}`,
                }}
              >
                <Typography>{t`Duration`}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session) => (
              <React.Fragment key={`${session.id}`}>
                <BedTableRow
                  sx={{
                    cursor: session.unitId ? 'pointer' : undefined,
                  }}
                  onClick={() => {
                    if (!session.unitId) {
                      return;
                    }
                    setOpenModal({
                      open: true,
                      bedId: session.unitId,
                      data: {
                        id: session.id,
                        name: session.name,
                        fallRisk: session.fallRisk,
                        note: session.note,
                        sessionEnd: session.sessionEnd,
                        sessionStart: session.sessionStart,
                        safetyPositionPresetId: null,
                      },
                    });
                  }}
                >
                  <TableCell>{session.name}</TableCell>
                  <TableCell>{session.locationHistory}</TableCell>
                  <TableCell>{session.note}</TableCell>
                  <TableCell>
                    {session.sessionStart
                      ? i18n.date(session.sessionStart, {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                        })
                      : null}
                  </TableCell>
                  <TableCell>
                    {session.sessionEnd
                      ? i18n.date(session.sessionEnd, {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                        })
                      : null}
                  </TableCell>
                  <TableCell>
                    {getDurationTime(session.sessionStart, session.sessionEnd)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Button
                      disabled={!session.sessionStart}
                      sx={{ marginRight: '1rem', whiteSpace: 'nowrap' }}
                      onClick={(e) => exportHandler(e, session)}
                      size="small"
                      variant="contained"
                    >
                      {t`Export data`}
                    </Button>
                  </TableCell>
                </BedTableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {sessionData?.patientSessions.edges.length ? (
        <Flex>
          <Pagination
            sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}
            count={pageCount}
            page={page}
            onChange={changePage}
          />
        </Flex>
      ) : !loading ? (
        <Flex minHeight="400px" justifyContent="center" alignItems="center">
          <Typography sx={{ color: 'text.secondary' }}>
            <Trans>
              To display data from History, please use any filter to specify
              what you are looking for
            </Trans>
          </Typography>
        </Flex>
      ) : null}

      {sessionToExport?.sessionStart && (
        <ExportDataDialog
          sessionName={sessionToExport.name}
          sessionId={sessionToExport.id}
          unitId={sessionToExport.unitId}
          open={!!sessionToExport}
          close={closeExportDialog}
          sessionEnd={
            sessionToExport.sessionEnd
              ? new Date(sessionToExport.sessionEnd)
              : new Date()
          }
          sessionStart={new Date(sessionToExport.sessionStart)}
        />
      )}
    </>
  );
};

export default NewSessionHistoryTable;

const getDurationTime = (from: string, to: string) => {
  const minutes = Math.max(
    differenceInMinutes(new Date(to), new Date(from)),
    1,
  );
  const days = (minutes / 1440).toFixed(0);
  const hours = ((minutes % 1440) / 60).toFixed(0);
  const minsInHour = ((minutes % 1440) % 60).toFixed(0);

  return t`${days} days ${hours} hours ${minsInHour} minutes`;
};

const BedTableRow = styled(TableRow)(
  ({ theme }) => `
  border-top: 1px solid ${theme.palette.common.divider};
`,
);
