import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import DatapointView from './DatapointView';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useQuery, useMutation } from '@apollo/client';
import {
  SessionDataQuery,
  SessionDataQueryVariables,
  ChangePwEndpointOrder,
  ChangePwEndpointOrderVariables,
} from 'src/graphql/types';
import { sessionData } from 'src/graphql/queries';
import { changePwEndpointOrder } from 'src/graphql/mutations';
import { getPatientData, PatientDataType } from '../../helpers/patientView';
import { t } from '@lingui/macro';
import NoData from '../../components/NoData';
import Timeline from './Timeline';
import DateInput from 'src/components/DateInput';

interface Props {
  patientSessionId: string;
  sessionStart?: string | null;
  sessionEnd?: string | null;
  showDetail?: (type: string, from: Date, to: Date) => void;
}

const reorder = (
  list: PatientDataType[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const PatientView = ({
  patientSessionId,
  sessionEnd,
  sessionStart,
  showDetail,
}: Props) => {
  const [visibleTimeline, setVisibleTimeline] = useState<number>(13);
  const [sortedData, setSortedData] = useState<PatientDataType[]>([]);
  const [date, setDate] = useState<Date | null>(
    sessionEnd ? new Date(sessionEnd) : null,
  );
  const { data } = useQuery<SessionDataQuery, SessionDataQueryVariables>(
    sessionData,
    {
      skip: !patientSessionId,
      variables: {
        id: patientSessionId,
        date: !!date ? date : null,
        timezoneOffset: new Date().getTimezoneOffset(),
      },
    },
  );
  const [changeOrder] = useMutation<
    ChangePwEndpointOrder,
    ChangePwEndpointOrderVariables
  >(changePwEndpointOrder);
  const dragEnded = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortedData,
      result.source.index,
      result.destination.index,
    );
    setSortedData(items);

    const newOrder = items.map((item) => item.type).join(',');
    await changeOrder({
      variables: {
        orderString: newOrder,
      },
    });
  };

  useEffect(() => {
    if (data) {
      setSortedData(
        getPatientData(
          data?.sessionData,
          data?.me?.userSetting?.pwEndpointOrder,
        ),
      );
    }
  }, [data]);

  return (
    <Box
      pb={3}
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {sortedData && patientSessionId ? (
        <Box position="relative">
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mb={1}
            >
              <DateInput
                label={t`Date`}
                value={date || new Date()}
                size="small"
                variant="outlined"
                onChange={setDate}
              />
            </Box>
            <DragDropContext onDragEnd={dragEnded}>
              <Droppable droppableId="droppable">
                {(droppableProvided) => (
                  <Content ref={droppableProvided.innerRef}>
                    <div>
                      {sortedData.map((data, index) => (
                        <DatapointView
                          visibleTimeline={visibleTimeline}
                          key={`view-${data.type}`}
                          index={index}
                          data={data.values}
                          type={data.type}
                          showDetail={showDetail}
                        />
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  </Content>
                )}
              </Droppable>
            </DragDropContext>
          </Box>

          {setVisibleTimeline && (
            <Box position="absolute" bottom={-35} right={70}>
              <Timeline
                currentTime={new Date()}
                sessionStart={sessionStart}
                setVisibleTimeline={setVisibleTimeline}
                visibleTimeline={visibleTimeline}
                date={date}
              />
            </Box>
          )}
        </Box>
      ) : (
        <NoData />
      )}
    </Box>
  );
};

export default PatientView;

const Content = styled(Box)`
  overflow-y: auto;
  height: 314px;
  padding-right: 16px;
  margin-bottom: 10px;
`;
