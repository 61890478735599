import { styled, Tab } from '@mui/material';

const ThinTab = styled(Tab)`
  margin-right: 12px;
  margin-left: 12px;
  padding-right: 0;
  padding-left: 0;
  min-width: 50px;
  font-stretch: expanded;
`;

export { ThinTab };
