import { t } from '@lingui/macro';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { FormikErrors } from 'formik';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { ResetIcon } from './ResetIcon';
import { VirtualBedForm } from './VirtualBedForm';

const getControllerOptions = () => [
  {
    id: 0,
    name: t`Right LCD controller`,
  },
  {
    id: 1,
    name: t`Left LCD controller`,
  },
  {
    id: 4,
    name: t`Legs controller`,
  },
  {
    id: 5,
    name: t`ACP and RO controller`,
  },
  {
    id: 6,
    name: t`Digital siderail right controller`,
  },
  {
    id: 7,
    name: t`Digital siderail left controller`,
  },
];

export const getButtonOptions = () => [
  {
    id: 0,
    name: t`No function is activated`,
  },
  {
    id: 1,
    name: t`Calfrest - Up`,
  },
  {
    id: 2,
    name: t`Autocontour - Down`,
  },
  {
    id: 3,
    name: t`Lock Button`,
  },
  {
    id: 4,
    name: t`Examination Position`,
  },
  {
    id: 5,
    name: t`Leg Lock`,
  },
  {
    id: 6,
    name: t`Central Lock`,
  },
  {
    id: 7,
    name: t`Backrest Lock`,
  },
  {
    id: 8,
    name: t`Height Adjustment Lock`,
  },
  {
    id: 9,
    name: t`Foot Control Lock`,
  },
  {
    id: 10,
    name: t`Thighrest - Up`,
  },
  {
    id: 11,
    name: t`Calfrest - Down`,
  },
  {
    id: 12,
    name: t`Thighrest - Down`,
  },
  {
    id: 13,
    name: t`Trendelenburg`,
  },
  {
    id: 14,
    name: t`Backrest to 30°`,
  },
  {
    id: 15,
    name: t`Backrest - Up`,
  },
  {
    id: 16,
    name: t`CPR Position`,
  },
  {
    id: 17,
    name: t`Backrest - Down`,
  },
  {
    id: 18,
    name: t`Anti-Trendelenburg`,
  },
  {
    id: 19,
    name: t`Mobilisation Position`,
  },
  {
    id: 20,
    name: t`Height Adjustment - Up`,
  },
  {
    id: 21,
    name: t`Cardiac Chair Position`,
  },
  {
    id: 22,
    name: t`Height Adjustment - Down`,
  },
  {
    id: 23,
    name: t`GO`,
  },
  {
    id: 24,
    name: t`GO`,
  },
  {
    id: 25,
    name: t`Lateral Tilt - Left`,
  },
  {
    id: 26,
    name: t`Emergency Trendelenburg`,
  },
  {
    id: 27,
    name: t`Lateral Tilt - Right`,
  },
  {
    id: 28,
    name: t`Mattress Platform - Extension`,
  },
  {
    id: 29,
    name: t`Mattress Platform - Shortening`,
  },
  {
    id: 30,
    name: t`Autocontour - Up`,
  },
  {
    id: 31,
    name: t`Ineffective function`,
  },
  {
    id: 32,
    name: t`Simultaneous GO`,
  },
  {
    id: 33,
    name: t`Straightening therapy`,
  },
  {
    id: 34,
    name: t`ALT Therapy test`,
  },
];

interface Props {
  values: VirtualBedForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<VirtualBedForm>>;
}

export const ControllerDatapoitns = ({ values, setFieldValue }: Props) => {
  const controllerOptions = getControllerOptions();
  const buttonOptions = getButtonOptions();

  const reset = () => {
    setFieldValue('buttonPressed', buttonOptions[0].id);
    setFieldValue('controller', controllerOptions[0].id);
  };
  const showReset =
    values.buttonPressed !== buttonOptions[0].id ||
    values.controller !== controllerOptions[0].id;

  return (
    <Box sx={{ mb: 4 }}>
      <Typography sx={{ mb: 3 }}>Pressed Button and Used Controller</Typography>
      <Box display="flex" alignItems="end">
        <FormikTextField
          name="buttonPressed"
          select
          variant="outlined"
          label={t`Pressed button`}
          sx={{ mr: 4 }}
        >
          {buttonOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
            >{`${option.id} (${option.name})`}</MenuItem>
          ))}
        </FormikTextField>
        <FormikTextField
          name="controller"
          select
          variant="outlined"
          label={t`Used controller`}
        >
          {controllerOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
            >{`${option.id} (${option.name})`}</MenuItem>
          ))}
        </FormikTextField>
        {showReset ? (
          <Button
            variant="text"
            sx={{ ml: 2 }}
            onClick={reset}
            size="large"
            startIcon={<ResetIcon />}
          >{t`Reset`}</Button>
        ) : null}
      </Box>
    </Box>
  );
};
