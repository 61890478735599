import { useContext } from 'react';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  Typography,
  styled,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { t } from '@lingui/macro';
import Flex from 'src/components/Flex';
import { DashboardSettingContext } from './DashboardSettingProvider';
import { ModalHeader } from 'src/components/BaseModal/ModalHeader';
import { useQuery } from '@apollo/client';
import { FontSize, SafetyPositionPresets } from 'src/graphql/types';
import { useWardData } from '../providers/WardDataProvider';
import { safetyPositionPresets } from 'src/graphql/queries';
import { useField, useFormik } from 'formik';
import FormikForm from 'src/components/Formik/FormikForm';

interface Props {
  open: boolean;
  onClose: () => void;
}

const MIN_PAGE = 5;
const MAX_PAGE = 100;

interface DashboardSettingsFormValue {
  pageSize: number;
  anonymized: boolean;
  fontSize: FontSize;
  showOwnedBeds: boolean;
  darkMode: boolean;
  safetyPositionPresetId: string | null | undefined;
}

const DashboardSettingsDialog = ({ open, onClose }: Props) => {
  const { userSetting, changeUserSettings } = useContext(
    DashboardSettingContext,
  );
  const { selectedWard, updateSafetyPosition } = useWardData();

  const handleSubmit = (values: DashboardSettingsFormValue) => {
    changeUserSettings({
      anonymized: values.anonymized,
      dashboardFontSize: values.fontSize,
      dashboardPageSize: values.pageSize,
      showOwnedBeds: values.showOwnedBeds,
      svTheme: values.darkMode ? 'dark' : 'light',
    });
    updateSafetyPosition(values.safetyPositionPresetId ?? null);
    onClose();
  };

  const formik = useFormik<DashboardSettingsFormValue>({
    initialValues: {
      pageSize: Math.min(
        MAX_PAGE,
        Math.max(MIN_PAGE, userSetting.dashboardPageSize),
      ),
      anonymized: Boolean(userSetting.anonymized),
      fontSize: userSetting.dashboardFontSize || FontSize.MEDIUM,
      showOwnedBeds: Boolean(userSetting.showOwnedBeds),
      darkMode: userSetting.svTheme === 'dark',
      safetyPositionPresetId: selectedWard?.safetyPositionPresetId ?? null,
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const { data: safetyPositionPresetsData } = useQuery<SafetyPositionPresets>(
    safetyPositionPresets,
  );

  const safetyPortOptions = [
    {
      id: '',
      name: t`Not set`,
    },
  ].concat(
    safetyPositionPresetsData?.safetyPositionPresets.map((spp) => ({
      id: spp.id,
      name: spp.name,
    })) || [],
  );

  const fontSizeOptions = [
    {
      id: FontSize.SMALL,
      name: t`Small`,
    },
    {
      id: FontSize.MEDIUM,
      name: t`Medium`,
    },
    {
      id: FontSize.LARGE,
      name: t`Large`,
    },
  ];
  const handleClose = () => {
    formik.resetForm();
    onClose();
  };
  return (
    <Dialog onClose={handleClose} maxWidth="xl" open={open}>
      <FormikForm formik={formik}>
        <DialogTitle>
          <ModalHeader
            title={t`SETTINGS`}
            sx={{ border: 'none', paddingBottom: 0 }}
            onClose={() => handleClose()}
          />
        </DialogTitle>
        <DialogContent>
          <Flex flexDirection="column" flex={1}>
            <ToggleSettingsItem name="darkMode" label={t`Dark Mode`} />
            <RadioGroupSettingsItem
              name="fontSize"
              label={t`Size Of Font On Dashboard`}
              options={fontSizeOptions}
            />
            <ToggleSettingsItem
              name="anonymized"
              label={t`Anonymized Patient Weight`}
              description={t`Hide weight column name and units`}
            />
            <ToggleSettingsItem
              name="showOwnedBeds"
              label={t`Show All Beds`}
              description={t`Show all beds belonging to selected unit`}
            />
            <SelectSettingsItem
              name="safetyPositionPresetId"
              label={t`Safety Position Preset`}
              description={t`Choose preset of safety position for your unit`}
              options={safetyPortOptions}
            />
          </Flex>
        </DialogContent>
        <DialogActions
          sx={{
            minHeight: '50px',
            px: (theme) => theme.spacing(3),
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={handleClose}
          >{t`Cancel`}</Button>
          <Button variant="contained" type="submit">{t`Save`}</Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
};

export default DashboardSettingsDialog;

interface SettingsFormItemProps {
  label: string;
  name: keyof DashboardSettingsFormValue;
  description?: string;
}

const ToggleSettingsItem = ({
  label,
  name,
  description,
}: SettingsFormItemProps) => {
  const [inputProps] = useField<boolean>(name);
  const { value, name: inputName, onChange } = inputProps;

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange({ target: { value: checked, name: inputName } });
  };

  return (
    <SettingsFormItemWrapper>
      <Flex flexDirection="column">
        <SettingsFormLabel>{label}</SettingsFormLabel>
        <SettingsFormDescription>{description}</SettingsFormDescription>
      </Flex>
      <Switch
        id={inputName}
        name={inputName}
        checked={value || false}
        onChange={handleChange}
      />
    </SettingsFormItemWrapper>
  );
};

interface SettingsFormItemWithOptionsProps extends SettingsFormItemProps {
  options: { id: string; name: string }[];
}

const SelectSettingsItem = ({
  label,
  name,
  description,
  options,
}: SettingsFormItemWithOptionsProps) => {
  const [inputProps] = useField(name);
  const { value, name: inputName, onChange } = inputProps;
  return (
    <SettingsFormItemWrapper
      sx={{
        alignItems: 'start',
        flexDirection: 'column',
      }}
    >
      <SettingsFormLabel>{label}</SettingsFormLabel>
      <SettingsFormDescription pb={1}>{description}</SettingsFormDescription>
      <TextField
        select
        variant="outlined"
        fullWidth
        id={inputName}
        name={inputName}
        value={value || ''}
        onChange={onChange}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
    </SettingsFormItemWrapper>
  );
};

const RadioGroupSettingsItem = ({
  label,
  name,
  description,
  options,
}: SettingsFormItemWithOptionsProps) => {
  const [inputProps] = useField(name);
  const { value, name: inputName, onChange } = inputProps;
  return (
    <SettingsFormItemWrapper
      sx={{
        alignItems: 'start',
        flexDirection: 'column',
      }}
    >
      <SettingsFormLabel>{label}</SettingsFormLabel>
      <SettingsFormDescription pb={1}>{description}</SettingsFormDescription>
      <RadioGroup
        value={value}
        id={inputName}
        name={inputName}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id || FontSize.MEDIUM}
            control={<Radio />}
            label={option.name}
          />
        ))}
      </RadioGroup>
    </SettingsFormItemWrapper>
  );
};

const SettingsFormLabel = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.primary};
`;

const SettingsFormDescription = styled(Typography)`
  font-size: 0.875;
  color: ${(props) => props.theme.palette.text.primary};
`;

const SettingsFormItemWrapper = styled(Flex)`
  flex: 1;
  justify-content: space-between;
  min-width: 440px;
  align-items: center;
  border-top: ${(props) => `2px solid ${props.theme.palette.grey[400]}`};
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
`;
