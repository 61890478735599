import gql from 'graphql-tag';

export const bedFragment = gql`
  fragment BedFragment on Bed {
    unitId
    name
    state
    unitSn
    bedSn
    power
    ipAddress
    ipAddressEthernet
    macAddress
    macAddressEthernet
    isVirtual
    ident
    type
    tagId
    ownerWardId
    workspace {
      id
      name
      image
      bedsCount
      roomsCount
      wards {
        id
      }
    }
    ward {
      id
      name
    }
    room {
      id
      name
      id
    }
    parkingPlaces {
      id
      tagId
      name
      mode
      ipAddressEthernet
    }
  }
`;

export const dashboardBedFragment = gql`
  fragment DashboardBedFragment on Bed {
    unitId
    name
    state
    bedSn
    unitSn
    power
    ipAddress
    macAddress
    ident
    type
    tagId
    breaksAlertSoundMuted
    heightAlertSoundMuted
    backrestAlertSoundMuted
    siderailAlertSoundMuted
    workspace {
      id
      name
      image
      bedsCount
      roomsCount
      wards {
        id
      }
    }
    ward {
      id
      name
      setting {
        breaksAlertSound
        heightAlertSound
        backrestAlertSound
        siderailAlertSound
      }
    }
    room {
      id
      name
      id
    }
    parkingPlaces {
      id
      tagId
      name
    }
  }
`;

export const parkingPlaceSmallFragment = gql`
  fragment ParkingPlaceSmallFragment on ParkingPlace {
    id
    tagId
    name
    mode
    ipAddressEthernet
    bed {
      unitId
      name
    }
  }
`;

export const wardSafetyPositionFragment = gql`
  fragment WardSafetyPositionFragment on Ward {
    id
    name
    safetyPositionPresetId
  }
`;

export const parkingPlaceFragment = gql`
  fragment ParkingPlaceFragment on ParkingPlace {
    id
    tagId
    name
    ipAddressEthernet
    mode
    bed {
      tagId
      unitId
      name
      bedSn
      unitSn
      state
      tagId
      ownerWardId
      isVirtual
      power
      ipAddress
      macAddress
      ident
      type
      room {
        id
        name
      }
      ward {
        id
        name
      }
      parkingPlaces {
        id
        tagId
        name
      }
      workspace {
        id
        name
        image
        wards {
          id
        }
        bedsCount
        roomsCount
      }
    }
  }
`;

export const dashboardParkingPlaceFragment = gql`
  fragment DashboardParkingPlaceFragment on ParkingPlace {
    id
    tagId
    name
    bed {
      tagId
      unitId
      name
      bedSn
      unitSn
      state
      tagId
      power
      ipAddress
      macAddress
      ident
      type
      breaksAlertSoundMuted
      heightAlertSoundMuted
      backrestAlertSoundMuted
      siderailAlertSoundMuted
      room {
        id
        name
      }
      ward {
        id
        name
        setting {
          breaksAlertSound
          heightAlertSound
          backrestAlertSound
          siderailAlertSound
        }
      }
      parkingPlaces {
        id
        tagId
        name
      }
      workspace {
        id
        name
        image
        wards {
          id
        }
        bedsCount
        roomsCount
      }
    }
  }
`;

export const userFragment = gql`
  fragment UserFragment on User {
    id
    role
    accessibleWards {
      id
      name
      workspace {
        id
        name
      }
    }
    assignedWards {
      id
      name
      workspace {
        id
        name
      }
    }
    username
    firstName
    lastName
    locale
    email
    lastAccess
    vbAccess
    demoAccess
    serverIp
    ssid
    ssidKey
    ssidKeyEncrypt
    reportingModuleAccess
  }
`;

export const configFragment = gql`
  fragment ConfigFragment on Config {
    hospitalName
    defaultPause
    unitSystem
    noteConfirmationPeriod
    logoURL
    serverIp
  }
`;

export const safetyPositionPresetFragment = gql`
  fragment SafetyPositionPresetFragment on SafetyPositionPreset {
    id
    name
    editable
    backrestAngleEnabled
    backrestAngleMin
    backrestAngleMax
    posIsLowestEnabled
    brakesEnabled
    siderailPositionEnabled
    siderailPosition
    bedExitEnabled
    bedExitStatus
  }
`;

export const superConfigFragment = gql`
  fragment SuperConfigFragment on SuperConfig {
    spEnabled
    spVersion
    spDashboardVersion
    patientEnabled
    spDashboardEnabled
    spDashboardLicenceExpiration
    respiratoryMobilizationEnabled
    bedManagementEnabled
    patientLicenceExpiration
    bedManagementLicenceExpiration
    respiratoryMobilizationLicenceExpiration
    utilizationEnabled
    utilizationLicenceExpiration
    syncCommunication
    hl7LogEnabled
    reportingModuleAccess
    passwordFlags
    passwordMinLength
    adminPasswordFlags
    adminPasswordMinLength
  }
`;

export const trendsFragment = gql`
  fragment TrendsFragment on SessionTrends {
    backrest30 {
      data {
        time
        value
      }
      duration
    }
    backrest45 {
      data {
        time
        value
      }
      duration
    }
    alt {
      data {
        time
        value
      }
      duration
    }
    trendelenburg {
      data {
        time
        value
      }
      duration
    }
    lateralTilt {
      data {
        time
        value
      }
      duration
    }
    mobilift {
      data {
        time
        value
      }
      duration
    }
    mattressType {
      data {
        time
        value
      }
      duration
    }
  }
`;

export const reportTagFragment = gql`
  fragment ReportTagFragment on Tags {
    unitId
    workspaceId
    wardId
    roomId
    parkingPlaceId
    type
    shift
    custom
  }
`;

export const reportingReportFragment = gql`
  fragment ReportingReportFragment on ReportingReport {
    id
    name
    goal
    filterConfig
    reportingDashboards {
      id
      name
    }
  }
`;

export const reportingDashboardDetailFragment = gql`
  fragment ReportingDashboardDetailFragment on ReportingDashboard {
    id
    name
    reports {
      reportingReportId
      x
      y
    }
  }
`;

export const reportingDashboardListItemFragment = gql`
  fragment ReportingDashboardListItemFragment on ReportingDashboard {
    id
    name
  }
`;

export const userNameFragment = gql`
  fragment UserNameFragment on User {
    id
    firstName
    lastName
  }
`;

export const bedNameFragment = gql`
  fragment BedNameFragment on Bed {
    unitId
    name
  }
`;

export const roomNameFragment = gql`
  fragment RoomNameFragment on Room {
    id
    name
  }
`;
