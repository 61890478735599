import { Trans } from '@lingui/macro';
import { Box, Button } from '@mui/material';
import { ExecutionResult } from 'graphql';
import {
  ControlStateType,
  SpEnabledGroupsType,
  ConnectionSettingsState,
} from './globalSettingsHelper';
import {
  ConnectionsQuery_connections,
  AddConnection,
  DeleteConnection,
} from '../../../graphql/types';
import ConnectionsTable from 'src/pages/Admin/DataTransferSettings/ConnectionsTable';
import AddIcon from '@mui/icons-material/Add';
import Flex from 'src/components/Flex';

interface Props {
  controlState: ControlStateType[];
  setControlState: React.Dispatch<React.SetStateAction<ControlStateType[]>>;
  spEnabledGroups: SpEnabledGroupsType;
  connections: ConnectionsQuery_connections[];
  connectionSettings: ConnectionSettingsState | null;
  onSave: (connSettings?: ConnectionSettingsState) => null | Promise<any>;
  setConnectionSettings: React.Dispatch<
    React.SetStateAction<ConnectionSettingsState | null>
  >;
  selectedConnectionId: string | null;
  setSelectedConnectionId: React.Dispatch<React.SetStateAction<string | null>>;
  addConnection: (name: string) => Promise<ExecutionResult<AddConnection>>;
  deleteConnection: (id: string) => Promise<ExecutionResult<DeleteConnection>>;
}

export type ConnectionDetailFields =
  | 'name'
  | 'ip'
  | 'syncPeriod'
  | 'synchronous'
  | 'enabled'
  | 'port';

const DataTransferSettings = ({
  spEnabledGroups,
  controlState,
  setControlState,
  setConnectionSettings,
  connections,
  connectionSettings,
  selectedConnectionId,
  setSelectedConnectionId,
  addConnection,
  deleteConnection,
  onSave,
}: Props) => {
  const handleChangeConnection = (
    type: ConnectionDetailFields,
    value: any,
    saveImmediately?: boolean,
  ) => {
    setConnectionSettings((prev) => {
      const newValues = prev && { ...prev, [type]: value };
      if (saveImmediately && newValues) {
        onSave(newValues);
      }
      return newValues;
    });
  };

  const handleChangeEnable = (connectionId: string, enabled: boolean) => {
    const foundConnection = connections.find((c) => c.id === connectionId);
    if (foundConnection) {
      onSave({
        ...foundConnection,
        enabled,
      });
    }
  };

  const handleDeleteConnection = async (id: string) =>
    deleteConnection(id).then(() => {
      if (id !== selectedConnectionId) {
        return;
      }
      const newConn = connections.find(
        (conn) => conn.id !== selectedConnectionId,
      );
      setSelectedConnectionId(newConn?.id ?? null);
    });

  const handleAddConnection = () => {
    addConnection('')
      .then(({ data }) => {
        data?.addConnection?.id &&
          setSelectedConnectionId(data.addConnection.id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Flex>
      <Box width="100%">
        <ConnectionsTable
          deleteConnection={handleDeleteConnection}
          connections={connections}
          onConnectionClick={setSelectedConnectionId}
          onChangeEnabled={handleChangeEnable}
          controlState={controlState}
          spEnabledGroups={spEnabledGroups}
          connectionSettings={connectionSettings}
          onChangeConnection={handleChangeConnection}
          onSave={onSave}
          setControlState={setControlState}
          setConnectionSettings={setConnectionSettings}
        />
        <Button
          variant="contained"
          sx={{
            mt: 4,
          }}
          startIcon={<AddIcon />}
          onClick={handleAddConnection}
        >
          <Trans>Add connection</Trans>
        </Button>
      </Box>
    </Flex>
  );
};

export default DataTransferSettings;
