import { t } from '@lingui/macro';
import { Button } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { BaseModal } from 'src/components/BaseModal';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikTextField from 'src/components/Formik/FormikTextField';

interface FormData {
  name: string;
}

interface Props {
  open: boolean;
  initialName?: string;
  onClose: () => void;
  onConfirm: (formData: FormData) => void | Promise<void>;
  type?: 'goal' | 'report';
}

const ReportSaveAsModal = ({
  open,
  onClose,
  initialName,
  onConfirm,
  type = 'report',
}: Props) => {
  const onSubmit = async (data: FormData, helpers: FormikHelpers<FormData>) => {
    if (data.name.trim().length < 3) {
      helpers.setFieldError('name', t`Name is required`);
      return;
    }
    await onConfirm(data);
  };

  const formik = useFormik<FormData>({
    initialValues: {
      name: initialName || '',
    },
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title={type === 'report' ? t`Save new report` : t`Save new goal`}
      actions={
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
          >{t`Cancel`}</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => formik.submitForm()}
            disabled={formik.isSubmitting}
          >{t`Save`}</Button>
        </>
      }
    >
      <FormikForm formik={formik}>
        <FormikTextField
          name="name"
          required
          label={t`Name`}
          variant="outlined"
          sx={{ mt: 1, minWidth: '35ch' }}
          fullWidth
        />
      </FormikForm>
    </BaseModal>
  );
};

export default ReportSaveAsModal;
