import { t, Trans } from '@lingui/macro';
import React, { useContext, useRef, useState } from 'react';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { usePrompt } from 'src/hooks';

interface SettingsProviderType {
  isPageDirty: boolean;
  setIsPageDirty: React.Dispatch<React.SetStateAction<boolean>>;
  actionGuard: (callback: Function) => void;
  discardFncRef: React.MutableRefObject<Function | undefined | null>;
}

export const DirtyContext = React.createContext<SettingsProviderType>({
  isPageDirty: false,
  setIsPageDirty: () => {},
  actionGuard: (callback: Function) => {},
  discardFncRef: { current: undefined },
});

const DirtyProvider = ({ children }: { children: React.ReactNode }) => {
  const [isPageDirty, setIsPageDirty] = useState(false);
  const [isConfirmDialogVisible, setIsConfirmDialogVisible] = useState(false);

  const callbackRef = useRef<Function | null>();
  const discardFncRef = useRef<Function | null>();

  const actionGuard = (callback: Function) => {
    callbackRef.current = callback;
    if (isPageDirty) {
      setIsConfirmDialogVisible(true);
      return;
    }
    callback();
  };

  const handleConfirm = () => {
    discardFncRef.current?.();
    callbackRef.current?.();
    callbackRef.current = null;
    discardFncRef.current = null;
    setIsPageDirty(false);
    setIsConfirmDialogVisible(false);
  };

  usePrompt(
    t`If you leave before saving, your changes will be lost.`,
    isPageDirty,
  );

  const context = {
    isPageDirty,
    setIsPageDirty,
    actionGuard,
    discardFncRef,
  };

  return (
    <DirtyContext.Provider value={context}>
      {children}
      <ConfirmModal
        open={isConfirmDialogVisible}
        onClose={() => setIsConfirmDialogVisible(false)}
        onConfirm={handleConfirm}
        message={
          <Trans>
            Do you really <b>want to leave</b> before saving changes ?
          </Trans>
        }
        confirmButtonText={t`Yes, i want to leave`}
        title={t`Leave Section`}
      />
    </DirtyContext.Provider>
  );
};
export default DirtyProvider;

export const useDirtyContext = () => {
  const context = useContext(DirtyContext);

  return context;
};
