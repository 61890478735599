import gql from 'graphql-tag';
import {
  bedFragment,
  configFragment,
  dashboardParkingPlaceFragment,
  reportingReportFragment,
  parkingPlaceFragment,
  reportTagFragment,
  safetyPositionPresetFragment,
  superConfigFragment,
  trendsFragment,
  userFragment,
  wardSafetyPositionFragment,
  reportingDashboardDetailFragment,
} from './fragments';

export const insightQuery = gql`
  query InsightQuery(
    $unitId: ID
    $dateFrom: DateTime!
    $limit: Int!
    $skipFirst: Boolean
    $prop: String
  ) {
    insight(
      unitId: $unitId
      dateFrom: $dateFrom
      prop: $prop
      skipFirst: $skipFirst
      limit: $limit
    ) {
      data {
        unitId
        type
        value
        time
      }
      hasMore
    }
  }
`;

export const userQuery = gql`
  query UserQuery($userId: ID!) {
    user(userId: $userId) {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const usersQuery = gql`
  query UsersQuery {
    users {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export const tagIdsQuery = gql`
  query TagIdsQuery {
    tags {
      id
    }
  }
`;

export const tagsQuery = gql`
  query TagsQuery {
    tags {
      id
      charge
      bed {
        unitId
        name
      }
      parkingPlace {
        id
        name
        room {
          id
          name
          ward {
            id
            name
            workspace {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const bed = gql`
  query BedQuery($unitId: ID!) {
    bed(unitId: $unitId) {
      ...BedFragment
      ownerWorkspace {
        id
      }
    }
  }
  ${bedFragment}
`;

export const beds = gql`
  query bedsQuery($roomId: ID) {
    beds(roomId: $roomId) {
      ...BedFragment
    }
  }
  ${bedFragment}
`;

export const workspacesQuery = gql`
  query WorkspacesQuery {
    workspaces {
      id
      name
      image
      roomsCount
      bedsCount
      wardsCount
    }
  }
`;

export const wardsQuery = gql`
  query WardsQuery($workspaceIds: [ID!]) {
    wards(workspaceIds: $workspaceIds) {
      id
      name
      roomsCount
      bedsCount
      workspace {
        id
        name
      }
    }
  }
`;

export const wardNamesQuery = gql`
  query WardNamesQuery($workspaceId: ID) {
    wards(workspaceId: $workspaceId) {
      id
      name
    }
  }
`;

export const wardsa = gql`
  query wardsQuery($workspaceIds: [ID!]) {
    wards(workspaceIds: $workspaceIds) {
      id
      name
      setting {
        breaksAlertSound
        heightAlertSound
        backrestAlertSound
        siderailAlertSound
      }
      rooms {
        id
        name
        parkingPlaces {
          id
          bed {
            unitId
            name
          }
        }
      }
      workspace {
        id
      }
    }
  }
`;

export const roomsQuery = gql`
  query RoomsQuery($wardId: ID) {
    rooms(wardId: $wardId) {
      id
      name
      bedsCount
    }
  }
`;

export const roomNamesQuery = gql`
  query RoomNamesQuery($wardId: ID) {
    rooms(wardId: $wardId) {
      id
      name
    }
  }
`;

export const configQuery = gql`
  query ConfigQuery {
    config {
      ...ConfigFragment
    }
    superConfig {
      ...SuperConfigFragment
    }
  }
  ${configFragment}
  ${superConfigFragment}
`;

export const connectionsQuery = gql`
  query ConnectionsQuery {
    connections {
      id
      name
      enabled
      ip
      port
      synchronous
      syncPeriod
      props {
        key
        enabled
        threshold
      }
    }
  }
`;

export const connectionNamesQuery = gql`
  query ConnectionNamesQuery {
    connections {
      id
      name
    }
  }
`;

export const safetyPortProps = gql`
  query safetyPortPropsQuery {
    safetyPortProps {
      id
      enabled
      threshold
    }
  }
`;

export const virtualBedQuery = gql`
  query VirtualBed($unitId: ID!) {
    virtualBed(unitId: $unitId) {
      weight
      tag
      id
      unitId
      trendelenburg
      smPause
      lateralTilt
      backrestAngle
      bedExitStatus
      bedExitAlarm
      brakes
      lowestPosition
      serverConnection
      powerStatus
      leftHeadSiderail
      rightHeadSiderail
      leftSiderail
      rightSiderail

      mobiliftRight
      mobiliftLeft
      calfrestAngle

      leftLegColumn
      rightLegColumn
      headColumn

      mattressType
      altPhase
      errorCode

      backrestLock
      heightLock
      calfrestLock
      thighrestLock
      bedExtension
      transfer
      batteryFailure

      controller
      buttonPressed
    }
  }
`;

export const room = gql`
  query roomQuery($roomId: ID) {
    room(roomId: $roomId) {
      id
      name
      workspace {
        id
        name
        image
      }
      ward {
        id
        name
      }
      parkingPlaces {
        ...ParkingPlaceFragment
      }
    }
  }
  ${parkingPlaceFragment}
`;

export const parkingPlaceNamesQuery = gql`
  query ParkingPlaceNamesQuery($roomId: ID, $wardId: ID) {
    parkingPlaces(roomId: $roomId, wardId: $wardId) {
      id
      name
      room {
        id
        name
      }
    }
  }
`;

export const parkingPlacesQuery = gql`
  query ParkingPlacesQuery($roomId: ID) {
    room(roomId: $roomId) {
      id
      name
      parkingPlaces {
        ...ParkingPlaceFragment
      }
    }
  }
  ${parkingPlaceFragment}
`;

export const allRooms = gql`
  query allRoomsQuery($wardId: ID) {
    rooms(wardId: $wardId) {
      id
      name
      parkingPlaces {
        id
        tagId
        bed {
          unitId
          state
        }
      }
      workspace {
        id
        name
        image
      }
      ward {
        id
        name
      }
    }
  }
`;

export const meWithSetting = gql`
  query MeWithSetting {
    me {
      ...UserFragment
      userSetting {
        userId
        svTheme
        anonymized
        dashboardOrder
        dashboardOrder2
        dashboardPageSize
        dashboardFontSize
        showOwnedBeds
        singleLineLayout
      }
    }
    superConfig {
      spDashboardVersion
    }
  }
  ${userFragment}
`;

export const currentBedExitEventsQuery = gql`
  query CurrentBedExitEvents($wardId: ID) {
    currentBedExitEvents(wardId: $wardId) {
      unitId
      duration
      startedAt
    }
  }
`;

export const meQuery = gql`
  query me {
    me {
      ...UserFragment
      userSetting {
        userId
        svTheme
        anonymized
        dashboardOrder
        dashboardOrder2
        dashboardPageSize
        dashboardFontSize
        showOwnedBeds
        singleLineLayout
      }
    }
  }
  ${userFragment}
`;

export const parkingPlaces = gql`
  query parkingPlacesQuery($roomId: ID!) {
    parkingPlaces(roomId: $roomId) {
      ...ParkingPlaceFragment
    }
  }
  ${parkingPlaceFragment}
`;

export const dashboardParkingPlaces = gql`
  query DashboardParkingPlacesQuery($roomId: ID!) {
    parkingPlaces(roomId: $roomId) {
      ...DashboardParkingPlaceFragment
    }
  }
  ${dashboardParkingPlaceFragment}
`;

export const safetyViewWardBeds = gql`
  query SafetyViewWardBeds($wardId: ID!) {
    wardBeds(wardId: $wardId, awayOnly: true) {
      unitId
      bedSn
      name
      ident
      state
      type
      isVirtual
      pinned
      ipAddressEthernet
      currentSession {
        id
        name
        note
        sessionStart
        safetyPositionPresetId
        fallRisk
      }
      parkingPlaces {
        id
        name
        room {
          id
          name
          ward {
            id
            name
          }
        }
      }
    }
  }
`;

export const safetyViewQuery = gql`
  query SafetyViewQuery($wardId: ID) {
    wardParkingPlaces(wardId: $wardId) {
      id
      name
      room {
        id
        name
      }
      bed {
        unitId
        bedSn
        name
        ident
        state
        type
        isVirtual
        ipAddressEthernet
        pinned
        currentSession {
          id
          name
          note
          sessionStart
          safetyPositionPresetId
          fallRisk
        }
        previousSession {
          id
          name
          note
          sessionStart
          sessionEnd
          safetyPositionPresetId
          fallRisk
        }
      }
    }
    me {
      id
      userSetting {
        userId
        dashboardOrder
        dashboardOrder2
        dashboardPageSize
        dashboardFontSize
        showOwnedBeds
        anonymized
        singleLineLayout
      }
    }
  }
`;

export const statistics = gql`
  query SessionStatistics(
    $id: ID!
    $from: DateTime!
    $to: DateTime!
    $timezoneOffset: Int!
  ) {
    statistics(id: $id, from: $from, to: $to, timezoneOffset: $timezoneOffset) {
      outOfBed {
        onTime
        offTime
      }
      safetyPosition {
        onTime
        offTime
      }
      allSiderailsCombined {
        onTime
        offTime
      }
      lowestPosition {
        onTime
        offTime
      }
      brakes {
        onTime
        offTime
      }
      backrest30 {
        onTime
        offTime
      }
      bedExitStatus {
        izTime
        ozTime
        offTime
      }
      power {
        onTime
        offTime
      }
    }
  }
`;

export const trends = gql`
  query SessionTrends(
    $id: ID!
    $timezoneOffset: Int!
    $firstDate: DateTime!
    $secondDate: DateTime!
    $thirdDate: DateTime!
    $fourthDate: DateTime!
    $fifthDate: DateTime!
    $sixthDate: DateTime!
    $seventhDate: DateTime!
  ) {
    firstDay: trends(
      id: $id
      date: $firstDate
      timezoneOffset: $timezoneOffset
    ) {
      ...TrendsFragment
    }
    secondDay: trends(
      id: $id
      date: $secondDate
      timezoneOffset: $timezoneOffset
    ) {
      ...TrendsFragment
    }
    thirdDay: trends(
      id: $id
      date: $thirdDate
      timezoneOffset: $timezoneOffset
    ) {
      ...TrendsFragment
    }
    fourthDay: trends(
      id: $id
      date: $fourthDate
      timezoneOffset: $timezoneOffset
    ) {
      ...TrendsFragment
    }
    fifthDay: trends(
      id: $id
      date: $fifthDate
      timezoneOffset: $timezoneOffset
    ) {
      ...TrendsFragment
    }
    sixthDay: trends(
      id: $id
      date: $sixthDate
      timezoneOffset: $timezoneOffset
    ) {
      ...TrendsFragment
    }
    seventhDay: trends(
      id: $id
      date: $seventhDate
      timezoneOffset: $timezoneOffset
    ) {
      ...TrendsFragment
    }
  }
  ${trendsFragment}
`;

export const sessionData = gql`
  query SessionDataQuery($id: ID!, $date: DateTime, $timezoneOffset: Int!) {
    sessionData(id: $id, date: $date, timezoneOffset: $timezoneOffset) {
      maxWeight
      minWeight
      maxBackrestAngle
      minBackrestAngle
      maxTrendelenburg
      minTrendelenburg
      brakes
      maxLateralTilt
      minLateralTilt
      bedExitStatus
      bedExitAlarm
      srbrUp
      srblUp
      isZoneExit
      lowestPosition
      outOfBed
      srlhUp
      srrhUp
      srlUp
      srrUp
      maxCalfrestAngle
      minCalfrestAngle
      calfrestLock
      backrestLock
      heightLock
      thighrestLock
      safetyPosition
      maxMattressType
      maxALTPhase
      time
    }
    me {
      id
      userSetting {
        userId
        pwEndpointOrder
      }
    }
  }
`;

export const bedHeaderModalData = gql`
  query BedHeaderModalData($unitId: ID!) {
    bed(unitId: $unitId) {
      unitId
      type
      name
      ident
      bedSn
      parkingPlaces {
        id
        name
        room {
          id
          name
        }
      }
      currentSession {
        id
        name
        note
        sessionStart
        sessionEnd
        fallRisk
        safetyPositionPresetId
      }
    }
  }
`;

export const mobileBedOverlay = gql`
  query MobileBedOverlay($unitId: ID!) {
    bed(unitId: $unitId) {
      unitId
      parkingPlaces {
        name
        room {
          name
        }
      }
      currentSession {
        id
        name
        sessionStart
        fallRisk
        sessionEnd
        safetyPositionPresetId
      }
    }
  }
`;

export const safetyPositionPresets = gql`
  query SafetyPositionPresets {
    safetyPositionPresets {
      ...SafetyPositionPresetFragment
    }
  }
  ${safetyPositionPresetFragment}
`;

export const historySessions = gql`
  query HistorySessions($input: PatientSessionsInput!) {
    patientSessions(input: $input) {
      edges {
        node {
          id
          name
          note
          unitId
          sessionStart
          fallRisk
          sessionEnd
          safetyPositionPresetId
          locationHistory
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

export const patientCareNotes = gql`
  query PatientCareNotes($id: ID!) {
    patientSession(id: $id) {
      id
      careNotes {
        id
        note
        dateCreated
        dateConfirmed
      }
    }
  }
`;

export const bedFiltersQuery = gql`
  query BedFilters($workspaceIds: [ID!], $wardId: ID) {
    tags {
      id
    }
    wards(workspaceIds: $workspaceIds) {
      id
      name
    }
    workspaces {
      id
      name
    }
    rooms(workspaceIds: $workspaceIds, wardId: $wardId) {
      id
      name
    }
  }
`;

export const hl7Log = gql`
  query Hl7LogQuery(
    $bedUnitId: ID
    $connectionId: ID
    $first: Int
    $after: String
    $filter: SafetyPortProps
  ) {
    dataHistory(
      bedUnitId: $bedUnitId
      connectionId: $connectionId
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          msgId
          bedUnitId
          prop
          value
          timestamp
          connectionId
          ack
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const logo = gql`
  query LogoQuery {
    config {
      logoURL
    }
  }
`;

export const wardSelectQuery = gql`
  query WardSelectQuery {
    workspaces {
      id
      name
      image
      wards {
        id
        name
        workspace {
          id
          name
        }
      }
    }
  }
`;

export const wardSafetyPositionQuery = gql`
  query WardSafetyPositionQuery {
    workspaces {
      id
      name
      image
      wards {
        ...WardSafetyPositionFragment
      }
    }
  }
  ${wardSafetyPositionFragment}
`;

export const generateReportQuery = gql`
  query GenerateReportQuery($input: ReportInput) {
    generateReport(input: $input) {
      tags {
        ...ReportTagFragment
      }
      columns
      values {
        time
        value
        total
        unit
      }
    }
  }
  ${reportTagFragment}
`;

export const reportingReportsQuery = gql`
  query ReportingReportsQuery {
    reportingReports {
      id
      name
      goal
    }
  }
`;

export const reportingDashboardsQuery = gql`
  query ReportingDashboardsQuery {
    reportingDashboards {
      id
      name
    }
  }
`;

export const reportingReportQuery = gql`
  query ReportingReportQuery($id: ID!) {
    reportingReport(id: $id) {
      ...ReportingReportFragment
    }
  }
  ${reportingReportFragment}
`;

export const reportingDashboardQuery = gql`
  query ReportingDashboardQuery($id: ID!) {
    reportingDashboard(id: $id) {
      ...ReportingDashboardDetailFragment
    }
  }
  ${reportingDashboardDetailFragment}
`;

export const reportDataQuery = gql`
  query ReportDataQuery($filterConfig: String!) {
    reportData(filterConfig: $filterConfig) {
      tags {
        ...ReportTagFragment
      }
      columns
      values {
        time
        value
        total
        unit
      }
    }
  }
  ${reportTagFragment}
`;

export const wardQuery = gql`
  query WardQuery($id: ID!) {
    ward(id: $id) {
      id
      name
      safetyPositionPresetId
    }
  }
`;

export const simulationPresetsQuery = gql`
  query SimulationPresetQuery {
    simulationPresets {
      id
      name
      config
    }
  }
`;
