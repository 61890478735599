import { useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Waypoint } from 'react-waypoint';
import AddIcon from '@mui/icons-material/Add';
import {
  ListPageContent,
  ListPageRoot,
  TableWrapper,
  TopBar,
} from 'src/components/AdminList';
import { ActiveFilters, FilterValue, useFilter } from 'src/components/Filters';
import { TableHeader } from 'src/components/Table';
import { usersQuery } from 'src/graphql/queries';
import { UsersQuery, UsersQuery_users } from 'src/graphql/types';
import { getSortState, SortKey, useSorting } from 'src/lib/sortingHook';
import { FilterProps, USER_FILTER, useUserFilterOption } from './filterOptions';
import { Link } from 'react-router-dom';

const WAYPOINT_OFFSET = 5;
const ROWS_INCREMENT = 30;

const Users = () => {
  const navigate = useNavigate();
  const { sorting, setSort } = useSorting();
  const getSortingState = (key: SortKey) => getSortState(sorting, key);

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_INCREMENT);
  const { i18n } = useLingui();
  const { data } = useQuery<UsersQuery>(usersQuery);

  const { filter, activeFilters, setFilterValue, clear, clearAll } =
    useFilter<FilterProps>({
      name: USER_FILTER,
      types: {
        name: t`NAME`,
        surname: t`SURNAME`,
        login: t`LOGIN`,
        email: t`EMAIL`,
        ward: t`WARD`,
        workspace: t`WORKSPACE`,
      },
    });

  const {
    nameOptions,
    surnameOptions,
    loginOptions,
    emailOptions,
    workspaceOptions,
    wardOptions,
  } = useUserFilterOption(filter, data?.users || []);

  const getSortedUsers = (users?: UsersQuery_users[]) => {
    if (!users) {
      return [];
    }
    const filteredUsers = users.filter(
      (user) =>
        (filter.name ? filter.name.id === user.firstName : true) &&
        (filter.surname ? filter.surname.id === user.lastName : true) &&
        (filter.login ? filter.login.id === user.username : true) &&
        (filter.email ? filter.email.id === user.email : true) &&
        (filter.ward
          ? user.assignedWards?.map((ward) => ward.id).includes(filter.ward.id)
          : true) &&
        (filter.workspace
          ? filter.workspace.id === user.assignedWards?.[0]?.workspace?.id
          : true),
    );
    if (sorting) {
      return filteredUsers.sort(sorting.sortFn);
    }
    return filteredUsers;
  };

  const handleClick = (user: UsersQuery_users) => {
    navigate({
      pathname: `/admin/users/${user.id}`,
    });
  };
  const sortedUsers = getSortedUsers(data && data.users);

  const loadMore = () => {
    setRowsPerPage((prev) => prev + ROWS_INCREMENT);
  };

  return (
    <>
      <ActiveFilters
        filters={activeFilters}
        clear={clear}
        clearAll={clearAll}
      />
      <ListPageRoot>
        <TopBar>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            component={Link}
            to="/admin/users/new"
          >
            <Trans>Add user</Trans>
          </Button>
        </TopBar>
        <ListPageContent>
          <TableWrapper>
            <Table stickyHeader sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableHeader
                      id="id"
                      sort={() => setSort(SortKey.ID)}
                      sortState={() => getSortingState(SortKey.ID)}
                      label={t`ID`}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="name"
                      sort={() => setSort(SortKey.NAME)}
                      sortState={() => getSortingState(SortKey.NAME)}
                      label={t`NAME`}
                      options={nameOptions}
                      value={filter.name}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('name', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="surname"
                      sort={() => setSort(SortKey.SURNAME)}
                      sortState={() => getSortingState(SortKey.SURNAME)}
                      label={t`SURNAME`}
                      options={surnameOptions}
                      value={filter.surname}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('surname', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="login"
                      sort={() => setSort(SortKey.LOGIN)}
                      sortState={() => getSortingState(SortKey.LOGIN)}
                      label={t`LOGIN`}
                      options={loginOptions}
                      value={filter.login}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('login', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="email"
                      sort={() => setSort(SortKey.EMAIL)}
                      sortState={() => getSortingState(SortKey.EMAIL)}
                      label={t`E-MAIL`}
                      options={emailOptions}
                      value={filter.email}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('email', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="lastOnline"
                      sort={() => setSort(SortKey.LASTONLINE)}
                      sortState={() => getSortingState(SortKey.LASTONLINE)}
                      label={t`LAST ONLINE`}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="workspace"
                      sort={() => setSort(SortKey.ASSIGNED_WORKSPACE)}
                      sortState={() =>
                        getSortingState(SortKey.ASSIGNED_WORKSPACE)
                      }
                      label={t`WORKSPACE`}
                      options={workspaceOptions}
                      value={filter.workspace}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('workspace', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="ward"
                      sort={() => setSort(SortKey.ASSIGNED_WARD)}
                      sortState={() => getSortingState(SortKey.ASSIGNED_WARD)}
                      label={t`WARD`}
                      options={wardOptions}
                      value={filter.ward}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('ward', value)
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUsers.slice(0, rowsPerPage).map((user, idx, users) => (
                  <TableRow
                    key={user.id}
                    hover
                    sx={{
                      '&:hover': {
                        '& img': {
                          visibility: 'visible',
                        },
                      },
                    }}
                    onClick={() => handleClick(user)}
                  >
                    <TableCell>{user.id}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>
                      {user.lastName}
                      {users.length - WAYPOINT_OFFSET === idx &&
                      users.length < sortedUsers.length ? (
                        <Waypoint onEnter={loadMore} />
                      ) : null}
                    </TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.lastAccess !== null
                        ? i18n.date(new Date(user.lastAccess), {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          })
                        : null}
                    </TableCell>
                    <TableCell>
                      {user.assignedWards?.[0]?.workspace?.name}
                    </TableCell>
                    <TableCell>
                      {user.assignedWards?.map((ward) => ward.name).join(' ')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </ListPageContent>
      </ListPageRoot>
    </>
  );
};

export default Users;
