import { t, Trans } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  IconButton,
  TableRowProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { ConfirmModal } from 'src/components/ConfirmModal';
import ContentBlock from 'src/components/ContentBlock';
import { Column, TableOddEven } from 'src/components/TableOddEven';
import { SafetyPositionPresetFragment } from 'src/graphql/types';
import { useDirtyContext } from 'src/providers/DirtyProvider';
import SafetyPositionPresetDetail from './PresetDetail';
import SiderailPositionTrans from './PresetTrans';
import { useSafetyPositionPresets } from './hooks';
import { CircularButtonContainer } from 'src/pages/Admin/components/CircularButtonContainer';

interface Props {
  disabled?: boolean;
}

const SpDashboardPresets = ({ disabled }: Props) => {
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  const { actionGuard } = useDirtyContext();
  const { palette } = useTheme();
  const {
    presets,
    createPresetMutation,
    editPresetMutation,
    deletePresetMutation,
  } = useSafetyPositionPresets();
  const [toDelete, setToDelete] = useState<SafetyPositionPresetFragment | null>(
    null,
  );

  const renderBoolean = (val: boolean) =>
    val ? (
      <CheckCircleIcon
        sx={{ verticalAlign: 'middle', mx: 1, color: 'success.light' }}
      />
    ) : (
      <CancelIcon color="error" sx={{ verticalAlign: 'middle', mx: 1 }} />
    );

  const handleDeleteClick = async (preset: SafetyPositionPresetFragment) => {
    await deletePresetMutation({
      variables: {
        id: preset.id,
      },
    });
    setToDelete(null);
  };

  const handleAddClick = () => {
    createPresetMutation();
  };

  const handleSave = async (
    id: string,
    data: Partial<SafetyPositionPresetFragment>,
  ) => {
    await editPresetMutation({
      variables: {
        input: { ...data, id: id },
      },
    });
  };

  const columns: Column<SafetyPositionPresetFragment>[] = [
    {
      key: 'name',
      header: t`Preset`,
      cellProps: {
        sx: {
          fontWeight: 'bold',
        },
      },
    },
    {
      key: 'backrestAngleEnabled',
      header: t`Backrest Angle`,
      cellProps: {
        align: 'center',
      },
      renderValue: (row) =>
        row.backrestAngleEnabled ? (
          <>
            {row.backrestAngleMin}° - {row.backrestAngleMax}°
          </>
        ) : (
          renderBoolean(row.backrestAngleEnabled)
        ),
    },
    {
      key: 'posIsLowestEnabled',
      header: t`Lowest position`,
      cellProps: {
        align: 'center',
      },
      renderValue: (row) => renderBoolean(row.posIsLowestEnabled),
    },
    {
      key: 'brakesEnabled',
      header: t`Brakes engaged`,
      cellProps: {
        align: 'center',
      },
      renderValue: (row) => renderBoolean(row.brakesEnabled),
    },
    {
      key: 'siderailPositionEnabled',
      header: t`Position of siderails`,
      cellProps: {
        align: 'center',
      },
      renderValue: (row) =>
        row.siderailPositionEnabled ? (
          <SiderailPositionTrans val={row.siderailPosition} />
        ) : (
          renderBoolean(row.siderailPositionEnabled)
        ),
    },
    {
      key: 'bedExitEnabled',
      header: t`Bed exit monitoring`,
      cellProps: {
        align: 'center',
      },
      renderValue: (row) => (
        <>
          {renderBoolean(row.bedExitEnabled)}
          <Typography
            component="span"
            color={palette.success.light}
            sx={{ verticalAlign: 'middle', textTransform: 'capitalize' }}
          >
            {row.bedExitEnabled && (
              <Trans id={row.bedExitStatus}>
                {row.bedExitStatus?.toLocaleLowerCase()}
              </Trans>
            )}
          </Typography>
        </>
      ),
    },
    {
      key: 'actions',
      header: '',
      cellProps: {
        align: 'right',
      },
      renderValue: (row) =>
        row.editable && !disabled ? (
          <CircularButtonContainer>
            <IconButton
              color="primary"
              aria-label="delete button"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setToDelete(row);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </CircularButtonContainer>
        ) : (
          ''
        ),
    },
  ];

  const handleRowClick = (row: SafetyPositionPresetFragment) => {
    actionGuard(() =>
      setSelectedPreset(row.id !== selectedPreset ? row.id : null),
    );
  };

  const rowProps = (row: SafetyPositionPresetFragment): TableRowProps => {
    return {
      hover: true,
      selected: selectedPreset === row.id,
      onClick: () => handleRowClick(row),
      sx: {
        cursor: 'pointer',
      },
    };
  };

  const renderExpanded = (row: SafetyPositionPresetFragment) => {
    return (
      <SafetyPositionPresetDetail
        presetData={row}
        onSave={handleSave}
        disabled={disabled}
      />
    );
  };

  return (
    <ContentBlock title={t`Safety Position Preset Settings`}>
      <TableOddEven<SafetyPositionPresetFragment>
        rowKey="id"
        data={presets?.safetyPositionPresets || []}
        columns={columns}
        rowProps={rowProps}
        renderExpanded={renderExpanded}
      />
      {!disabled && (
        <Box py={4}>
          <Button
            onClick={handleAddClick}
            startIcon={<AddIcon />}
            size="large"
            color="primary"
            variant="contained"
          >
            <Trans>Add new preset</Trans>
          </Button>
        </Box>
      )}
      <ConfirmModal
        open={!!toDelete}
        onClose={() => setToDelete(null)}
        message={
          <Trans>
            Do you really want to <b>delete Preset {toDelete?.name}?</b>
          </Trans>
        }
        onConfirm={() => toDelete && handleDeleteClick(toDelete)}
      />
    </ContentBlock>
  );
};

export default SpDashboardPresets;
