import { Box, Button, IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import Flex from 'src/components/Flex';
import { CheckboxIcon } from '../../components/Icons/CheckboxIcon';
import { PinIcon } from '../../components/Icons/PinIcon';
import { SettingsIcon } from '../../components/Icons/SettingsIcon';
import { UnpinIcon } from '../../components/Icons/UnpinIcon';
import { useWardData } from '../../providers/WardDataProvider';
import { WardSelectDialog } from '../components/WardSelectDialog';
import { MobileModalContext } from '../ModalOverlay/MobileModalProvider';
import { MobileDashboardContext } from '../Settings/MobilDashboardProvider';

export const MobileTopBar = () => {
  const [wardSelectOpen, setWardSelectOpen] = useState(false);
  const { selectedWard } = useWardData();
  const { showModal } = useContext(MobileModalContext);
  const { pinningEnabled, setPinningEnabled } = useContext(
    MobileDashboardContext,
  );

  return (
    <>
      <Box px={3} pt={3} display="flex" justifyContent="space-between" mb={1}>
        <Button
          variant="outlined"
          sx={{ color: 'text.primary', borderColor: 'text.primary' }}
          onClick={() => setWardSelectOpen(true)}
        >
          {selectedWard?.name}
        </Button>

        <Flex gap={1}>
          <IconButton
            onClick={() => {
              showModal('datapoints');
            }}
          >
            <CheckboxIcon sx={{ color: 'text.primary' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              showModal('settings');
            }}
          >
            <SettingsIcon sx={{ color: 'text.primary' }} />
          </IconButton>
          {pinningEnabled ? (
            <IconButton onClick={() => setPinningEnabled(false)}>
              <UnpinIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => setPinningEnabled(true)}>
              <PinIcon />
            </IconButton>
          )}
        </Flex>
      </Box>
      <WardSelectDialog open={wardSelectOpen} setOpen={setWardSelectOpen} />
    </>
  );
};
