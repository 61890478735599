import { useField } from 'formik';
import { RadioSelect, RadioSelectProps } from '../RadioSelect';

interface Props extends Omit<RadioSelectProps, 'onChange'> {
  name: string;
}

const FormikRadioSelect = ({ name, ...radioSelectProps }: Props) => {
  const [inputProps] = useField({ name });

  return <RadioSelect {...radioSelectProps} {...inputProps} />;
};

export default FormikRadioSelect;
