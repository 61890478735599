import { t } from '@lingui/macro';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import Flex from 'src/components/Flex';

interface Props {
  charge?: number | null;
}

export const BatteryStatus = ({ charge }: Props) => {
  const status = getBatteryStatus(charge);
  if (status === 'unknown') {
    return (
      <Flex
        alignItems="center"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        <Battery5BarIcon sx={{ transform: 'rotate(90deg)', mr: 0.5 }} />
        {transBatteryStatus(status)}
      </Flex>
    );
  }
  if (status === 'high') {
    return (
      <Flex
        alignItems="center"
        sx={{ color: (theme) => theme.palette.success.light }}
      >
        <Battery6BarIcon sx={{ transform: 'rotate(90deg)', mr: 0.5 }} />
        {transBatteryStatus(status)}
      </Flex>
    );
  }
  if (status === 'medium') {
    return (
      <Flex
        alignItems="center"
        sx={{ color: (theme) => theme.palette.warning.main }}
      >
        <Battery3BarIcon sx={{ transform: 'rotate(90deg)', mr: 0.5 }} />
        {transBatteryStatus(status)}
      </Flex>
    );
  }
  return (
    <Flex
      alignItems="center"
      sx={{ color: (theme) => theme.palette.error.main }}
    >
      <Battery1BarIcon sx={{ transform: 'rotate(90deg)', mr: 0.5 }} />
      {transBatteryStatus(status)}
    </Flex>
  );
};

export type BatteryStatusCode = 'unknown' | 'low' | 'medium' | 'high';

export const getBatteryStatus = (charge?: number | null): BatteryStatusCode => {
  if (!charge) {
    return 'unknown';
  }
  if (charge >= 70) {
    return 'high';
  }
  if (charge >= 30) {
    return 'medium';
  }
  return 'low';
};

export const transBatteryStatus = (status: BatteryStatusCode) => {
  switch (status) {
    case 'unknown': {
      return t`Unknown`;
    }
    case 'high': {
      return t`High`;
    }
    case 'medium': {
      return t`Medium`;
    }
    case 'low': {
      return t`Low - Replace!`;
    }
  }
};
