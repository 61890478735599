import { t } from '@lingui/macro';
import { TableRowProps } from '@mui/material';
import { useMemo, useState } from 'react';
import ContentBlock from 'src/components/ContentBlock';
import { Column, TableOddEven } from 'src/components/TableOddEven';
import { WardSafetyPositionQuery_workspaces as WorkSpace } from 'src/graphql/types';
import SpDashboardPresetWardAssignments from './SpDashboardPresetWardAssignments';
import useSafetyPositionAssignments from './useSafetyPositionAssignments';
import { useSafetyPositionPresets } from './useSafetyPositionPresets';

const SpDashboardPresetAssignments = () => {
  const { workspaces } = useSafetyPositionAssignments();
  const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>();
  const { presets } = useSafetyPositionPresets();

  const presetOptions = useMemo(() => {
    return (
      presets?.safetyPositionPresets.map((p) => ({
        label: p.name,
        value: p.id,
      })) || []
    );
  }, [presets]);

  const columns: Column<WorkSpace>[] = [
    {
      header: t`Facility`,
      key: 'name',
      cellProps: {
        sx: {
          fontWeight: 'bold',
        },
      },
    },
  ];

  const renderDetail = (row: WorkSpace) => {
    return (
      <SpDashboardPresetWardAssignments
        workspace={row}
        presets={presetOptions}
      />
    );
  };

  const rowProps = (row: WorkSpace): TableRowProps => {
    return {
      hover: true,
      sx: { cursor: 'pointer' },
      onClick: () =>
        setSelectedWorkspace(row.id === selectedWorkspace ? null : row.id),
      selected: selectedWorkspace === row.id,
    };
  };

  return (
    <ContentBlock title={t`Safety Position Preset Selection`}>
      <TableOddEven
        rowKey="id"
        data={workspaces}
        columns={columns}
        rowProps={rowProps}
        renderExpanded={renderDetail}
      ></TableOddEven>
    </ContentBlock>
  );
};

export default SpDashboardPresetAssignments;
