import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  BedProps,
  DATAPOINT_PREVIEW_COUNT,
  MAX_DATAPOINTS_COUNT,
} from './MobileBedCellValue';
import {
  BedState,
  EndSession,
  EndSessionVariables,
  PinBed,
  PinBedVariables,
  StartPatientSession,
  StartPatientSessionVariables,
  UnpinBed,
  UnpinBedVariables,
} from 'src/graphql/types';
import DischargeDialog from '../../../BedDetailModal/components/DischargeDialog';
import NewSessionDialog, {
  StartSessionProps,
} from '../../../BedDetailModal/NewSessionDialog';
import SessionEndedDialog from '../../../BedDetailModal/SessionEndedDialog';
import { useMutation } from '@apollo/client';
import {
  endSession,
  pinBedMutation,
  startPatientSession,
  unpinBedMutation,
} from 'src/graphql/mutations';
import MobileBedHeader from './MobileBedHeader';
import MobileBedCell from './MobileBedCell';
import { MobileButtonBar } from '../MobileButtonBar';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';

interface Props extends BedProps {
  alertFrom?: Date | null;
  singleLineLayout?: boolean;
  refetchBeds: () => Promise<void>;
  withDivider?: boolean;
}

const MobileBed = ({
  bed,
  order,
  anonymized,
  alertFrom,
  singleLineLayout,
  refetchBeds,
  withDivider,
}: Props) => {
  const [discharge, setDischarge] = useState(false);
  const [newSession, setNewSession] = useState(false);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [pinBed, { loading: pinInprogress }] = useMutation<
    PinBed,
    PinBedVariables
  >(pinBedMutation);
  const [unpinBed, { loading: unpinInprogress }] = useMutation<
    UnpinBed,
    UnpinBedVariables
  >(unpinBedMutation);
  const [alertDuration, setAlertDuration] = useState<number | null>(null);
  const bedIsOffine = bed.state === BedState.OFFLINE;
  const bedIsActive = !bedIsOffine && !!bed.unitId;

  useEffect(() => {
    if (alertFrom) {
      const now = Date.now();
      const diffSec = Math.ceil((now - new Date(alertFrom).getTime()) / 1000);
      setAlertDuration(diffSec);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      timerRef.current = setInterval(() => {
        setAlertDuration((prev) => (prev ? prev + 1 : 1));
      }, 1000);
    } else {
      setAlertDuration(null);
      timerRef.current && clearInterval(timerRef.current);
    }

    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, [alertFrom, timerRef, setAlertDuration]);

  const togglePin = async () => {
    if (!bed.unitId || unpinInprogress || pinInprogress) {
      return;
    }
    if (bed.pinned) {
      await unpinBed({ variables: { unitId: bed.unitId } });
    } else {
      await pinBed({ variables: { unitId: bed.unitId } });
    }
  };

  const [endSessionMutation] = useSnackbarMutation<
    EndSession,
    EndSessionVariables
  >(endSession);

  const [startSessionMutation] = useSnackbarMutation<
    StartPatientSession,
    StartPatientSessionVariables
  >(startPatientSession);

  const onDischarge = async (date: Date) => {
    if (bed.patientId) {
      await endSessionMutation({
        variables: {
          id: bed.patientId || '',
          end: date,
        },
        onCompleted: async () => {
          await refetchBeds();
        },
      });
      setSessionEnded(true);
    }
  };

  const openNewSessionModal = () => {
    if (sessionEnded) {
      setSessionEnded(false);
    }
    setNewSession(true);
  };

  const onSessionStart = async ({ name, note, start }: StartSessionProps) => {
    if (!!bed?.unitId) {
      await startSessionMutation({
        variables: {
          input: {
            name,
            note,
            unitId: bed.unitId,
            sessionStart: start,
          },
        },
        onCompleted: async () => {
          await refetchBeds();
        },
      });
    }
  };

  const isHighligthed = !!alertFrom;

  const contentDatapoints = useMemo(() => {
    if (isHighligthed || singleLineLayout) {
      return order.slice(0, MAX_DATAPOINTS_COUNT);
    }
    return order.slice(DATAPOINT_PREVIEW_COUNT, MAX_DATAPOINTS_COUNT);
  }, [order, isHighligthed, singleLineLayout]);

  return (
    <>
      <Box
        id={bed.unitId}
        sx={{
          '&:not(:first-of-type)': {
            borderTop: (theme) =>
              `solid ${withDivider ? 4 : 1}px ${theme.palette.common.divider}`,
          },
        }}
      >
        <Accordion
          disabled={!bedIsActive || bed.inAnotherWard}
          expanded={expanded}
          onChange={() => setExpanded((prev) => !prev)}
          sx={{
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0212249)',
            '&:last-of-type': {
              borderRadius: 0,
            },
          }}
        >
          <AccordionSummary
            aria-controls={`${bed.bedName}-content`}
            id={`${bed.bedName}-header`}
            sx={{
              alignItems: 'start',
              backgroundColor: isHighligthed ? 'error.main' : undefined,
              color: isHighligthed ? 'error.contrastText' : undefined,
              '& .MuiAccordionSummary-content.Mui-expanded': {
                marginBottom: isHighligthed ? undefined : 0,
              },
            }}
          >
            <MobileBedHeader
              bed={bed}
              togglePin={() => togglePin()}
              anonymized={anonymized}
              order={order}
              bedIsActive={bedIsActive}
              bedIsOffine={bedIsOffine}
              expanded={expanded}
              alertDuration={alertDuration}
              singleLineLayout={singleLineLayout}
            />
          </AccordionSummary>
          {bedIsActive && !bed.inAnotherWard && (
            <AccordionDetails
              sx={{
                display: 'block',
                mt: !isHighligthed && singleLineLayout ? 0 : 3,
              }}
            >
              <Grid container rowSpacing={4} columnSpacing={2} mb={4}>
                {contentDatapoints.map((datapoint) => (
                  <Grid item xs={4} key={`${bed.unitId}-${datapoint.type}`}>
                    <MobileBedCell
                      key={`${bed.unitId}-${datapoint.type}`}
                      anonymized={anonymized}
                      cellType={datapoint.type}
                      bed={bed}
                    />
                  </Grid>
                ))}
              </Grid>
              <MobileButtonBar
                bed={bed}
                openNewSessionDailog={openNewSessionModal}
                openDischargeDialog={() => setDischarge(true)}
              />
            </AccordionDetails>
          )}
        </Accordion>
      </Box>
      <DischargeDialog
        setOpen={setDischarge}
        open={discharge}
        sessionStart={bed?.sessionStart?.toString() || new Date().toString()}
        onDischarge={onDischarge}
        stretchButtons
      />
      <NewSessionDialog
        setOpen={setNewSession}
        open={newSession}
        onSessionStart={onSessionStart}
        stretch
      />
      <SessionEndedDialog
        onNewSession={openNewSessionModal}
        setOpen={setSessionEnded}
        open={sessionEnded}
        onGoToDashboard={() => setSessionEnded(false)}
      />
    </>
  );
};

export default MobileBed;
