import { styled, IconButton, ClickAwayListener, Box } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import Flex from 'src/components/Flex';
import { FilterValue } from '../Filters';
import EventIcon from '@mui/icons-material/Event';
import DateTimeInput from '../DateTimeInput';
import { SortingState } from '../Sorting/SortingState';

interface Props {
  id: string;
  label: string;
  value?: FilterValue | null;
  setValue?: (value: FilterValue | null) => void;
  sort?: () => void;
  sortState?: () => any;
}

export const DateTableHeader = ({
  id,
  label,
  value,
  setValue,
  sort,
  sortState,
}: Props) => {
  const initValue = value ? new Date(value.id) : null;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dateRef = useRef(initValue);
  const sortEnabled = !!sort && !!sortState;
  const filterEnabled = !!setValue;

  const updateFilterValue = useCallback(() => {
    if (!filterEnabled) {
      return;
    }
    if (dateRef.current) {
      setValue({
        id: dateRef.current.toISOString(),
        name: dateRef.current.toLocaleString(),
      });
    } else {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEnabled]);

  useEffect(() => {
    if (isCalendarOpen) {
      inputRef.current?.focus();
    } else {
      updateFilterValue();
    }
  }, [isCalendarOpen, updateFilterValue]);

  const handleChange = (date: Date) => {
    dateRef.current = date;
  };

  return (
    <Flex alignItems="center">
      <Flex position="relative" flexShrink={1} flexGrow={0} alignItems="center">
        <Flex
          alignItems="center"
          onClick={sort}
          sx={{ cursor: sortEnabled ? 'pointer' : null }}
        >
          {label}
          {sortEnabled && sortState ? (
            <SortingState state={sortState()} />
          ) : null}
        </Flex>
        <ClickAwayListener onClickAway={() => setIsCalendarOpen(false)}>
          <Box ml={1}>
            <EventButton
              onClick={() => setIsCalendarOpen(true)}
              state={value ? 'selected' : 'unselected'}
            >
              <EventIcon />
            </EventButton>
            {filterEnabled && isCalendarOpen && (
              <AbsolutePositionInput sx={{ boxShadow: 4 }}>
                <DateTimeInput
                  id={id}
                  type="datetime-local"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      marginTop: 0,
                    },
                  }}
                  variant="outlined"
                  defaultValue={initValue || new Date()}
                  onChange={handleChange}
                  max={new Date()}
                  inputProps={{
                    ref: inputRef,
                  }}
                />
              </AbsolutePositionInput>
            )}
          </Box>
        </ClickAwayListener>
      </Flex>
    </Flex>
  );
};

const EventButton = styled(IconButton)<{ state: 'selected' | 'unselected' }>(
  ({ state, theme: { palette } }) => `
    ${
      state === 'selected'
        ? `
      background-color: ${palette.primary.main};
      color: ${palette.primary.contrastText};
      :hover {
        background-color: ${palette.primary.dark};
      }
    `
        : null
    }
  }
`,
);

const AbsolutePositionInput = styled(Box)(
  ({ theme: { palette } }) => `
  position: absolute;
  border-radius: 10px;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, 0);
  background-color: ${palette.background.paper};
`,
);
