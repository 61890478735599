import { AppBar, Box, Toolbar, styled } from '@mui/material';
import { ReactComponent as LinisTextLogo } from 'src/images/linis-text-logo.svg';

interface Props {
  handleButtonPress?: () => void;
  handleButtonRelease?: () => void;
}

export const SuperAdminTopBar = ({
  handleButtonPress,
  handleButtonRelease,
}: Props) => {
  return (
    <AppBar position="static">
      <Toolbar
        sx={(theme) => ({
          justifyContent: 'space-between',
          backgroundColor: theme.palette.grey[200],
        })}
      >
        <LogoWrapper>
          <Box
            onTouchStart={handleButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleButtonPress}
            onMouseUp={handleButtonRelease}
            onMouseLeave={handleButtonRelease}
          >
            <LinisTextLogo />
          </Box>
        </LogoWrapper>
      </Toolbar>
    </AppBar>
  );
};

const LogoWrapper = styled(Box)`
  display: flex;
  width: 100%;
`;
