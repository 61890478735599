import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  Image,
  Font,
} from '@react-pdf/renderer';
import { t, Trans } from '@lingui/macro';
import {
  ChartsExportDataType,
  ChartsExportDataValuesType,
} from '../helpers/export';
import { format } from 'date-fns';
import { dateTimePickerFormat } from '../../../helpers';
import { linetColors } from 'src/colors';

interface Props {
  charts: ChartsExportDataType[];
  sessionName: string | null;
  sessionId: string;
  exportedTime: Date;
  exportedFrom: Date;
  exportedTo: Date;
}

Font.register({
  family: 'GTAmerica',
  src: require('../../../font/GT-America-Standard-Regular.otf').default,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    padding: 16,
    fontFamily: 'GTAmerica',
  },
  chartBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: 150,
    maxHeight: 150,
    marginTop: 16,
    marginBottom: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  titleBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  valuesBox: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
});

const StatisticsExport = ({
  charts,
  sessionName,
  sessionId,
  exportedFrom,
  exportedTo,
  exportedTime,
}: Props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <Text
            style={{
              fontSize: 8,
              textAlign: 'right',
              marginRight: 8,
              marginBottom: 8,
            }}
          >{`${t`Session ID`}: ${sessionId}, ${t`Session Name`}: ${sessionName}, ${t`Exported from`}: ${format(
            exportedFrom,
            'MM/dd/yyyy',
          )}, ${t`Exported to`}: ${format(
            exportedTo,
            'MM/dd/yyyy',
          )}, ${t`Export time`}: ${format(
            exportedTime,
            dateTimePickerFormat,
          )}`}</Text>
          <Text style={{ textAlign: 'center', marginBottom: 48 }}>
            <Trans>Statistics</Trans>
          </Text>
          <View style={styles.content}>
            {charts?.map((item) => (
              <View
                wrap={false}
                key={`statistics-export-item-${item.title}`}
                style={styles.chartBox}
              >
                <ValueBox data={item.values[0]} />
                <ValueBox data={item.values[1]} variant="second" />
                {item.values.length > 2 && (
                  <ValueBox data={item.values[2]} variant="third" />
                )}
                <View style={styles.titleBox}>
                  <Text
                    style={{ fontSize: 12, width: 120, textAlign: 'center' }}
                  >
                    {item.title}
                  </Text>
                </View>
                <Image style={{ height: 150, width: 150 }} source={item.img} />
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StatisticsExport;

interface ValueBoxProps {
  data: ChartsExportDataValuesType;
  variant?: 'second' | 'third';
}

const ValueBox = ({ data, variant }: ValueBoxProps) => {
  const color =
    variant === 'second'
      ? linetColors.greenLight
      : variant === 'third'
      ? linetColors.orangeLight
      : linetColors.greyMain;
  return (
    <>
      {data && (
        <View
          style={{
            width: 100,
            position: 'absolute',
            right: !!variant ? 30 : undefined,
            left: !variant ? 30 : undefined,
            bottom: variant === 'third' ? 0 : undefined,
            top: variant == null || variant === 'second' ? 0 : undefined,
            textAlign: !!variant ? 'left' : 'right',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'left',
              justifyContent: !!variant ? 'flex-start' : 'flex-end',
            }}
          >
            <View style={{ width: 13, height: 11, backgroundColor: color }} />
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 10,
                fontWeight: 300,
                marginLeft: 8,
              }}
            >
              {data.text}
            </Text>
          </View>
          <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
            {`${data.value} %`}
          </Text>
          <Text style={{ fontSize: 8, fontWeight: 500 }}>{data.time}</Text>
        </View>
      )}
    </>
  );
};
