import { Box, styled } from '@mui/material';

export const CircularButtonContainer = styled(Box)(
  ({ theme: { palette } }) => `
  background: transparent;
  border: 2px solid ${palette.primary.main};
  color: ${palette.primary.main};
  border-radius: 50%;
  display: inline-block;
`,
);
