import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { MenuItem, TextField } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import Flex from 'src/components/Flex';
import { RadioSelect } from 'src/components/RadioSelect';
import TextFieldAutosave from 'src/components/TextFieldAutosave/TextFieldAutosave';
import { NumberComparator } from 'src/graphql/types';
import { normalizeNumberInt } from 'src/lib/normalize';
import { ReportFieldFilter, ReportFilterField as FieldData } from '../types';
import { REPORT_FILTER_FIELDS } from './useReportFilter';

interface Props {
  filter: ReportFieldFilter;
  inline?: boolean;
  type?: 'boolean' | 'number';
  exclude?: keyof typeof REPORT_FILTER_FIELDS;
  onChange: (filter: ReportFieldFilter) => void;
}
const ReportFilterField = ({
  filter,
  inline,
  type,
  exclude,
  onChange,
}: Props) => {
  const field = REPORT_FILTER_FIELDS[filter.field];
  const { type: fieldType } = field;
  const { value, numberComparator } = filter;
  const options = useMemo(
    () => (field.type === 'option' ? field.options() : []),
    [field],
  );

  useEffect(() => {
    if (fieldType === 'boolean' && typeof value !== 'boolean') {
      onChange({ ...filter, value: true });
    } else if (fieldType === 'number' && typeof value !== 'number') {
      onChange({
        ...filter,
        value: 0,
        numberComparator: NumberComparator.EQUALS,
      });
    } else if (fieldType === 'option') {
      const optionValues = options.map((o) => o.id);
      if (
        typeof value !== 'number' ||
        !optionValues.includes(value) ||
        numberComparator !== NumberComparator.EQUALS
      ) {
        onChange({
          ...filter,
          value: optionValues[0] || 0,
          numberComparator: NumberComparator.EQUALS,
        });
      }
    } else if (fieldType === 'number' && !numberComparator) {
      onChange({ ...filter, numberComparator: NumberComparator.EQUALS });
    }
  }, [fieldType, options, value, onChange, filter, numberComparator]);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newField: FieldData = REPORT_FILTER_FIELDS[e.target.value];
    if (!newField) return;
    const newFilter = { ...filter, field: e.target.value };
    onChange(newFilter);
  };

  const handleNumberFieldSave = (val: string) => {
    if (!val && typeof val !== 'number') return;
    const newFilter = { ...filter, value: Number.parseInt(val) };
    onChange(newFilter);
  };

  const handleNumberComparatorChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value as NumberComparator;
    if (!value) return;
    const newFilter = { ...filter, numberComparator: value };
    onChange(newFilter);
  };

  const handleBooleanValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    onChange({ ...filter, value: value === 'true' });
  };

  return (
    <Flex alignItems="end" sx={inline ? { display: 'inline-flex' } : undefined}>
      <TextField
        value={field.fieldName}
        label={t`Field`}
        variant="outlined"
        select
        onChange={handleFieldChange}
        sx={{ minWidth: '25ch' }}
      >
        {Object.entries(REPORT_FILTER_FIELDS)
          .filter(([fieldName]) => fieldName !== exclude)
          .filter(([, field]) => !type || field.type === type)
          .map(([fieldName, field]) => (
            <MenuItem key={field.fieldName} value={fieldName}>
              {field.label()}
            </MenuItem>
          ))}
      </TextField>
      {fieldType === 'boolean' && (
        <RadioSelect
          row
          value={`${value}`}
          onChange={handleBooleanValueChange}
          options={[
            { label: field.yes ? field.yes() : t`Yes`, value: 'true' },
            { label: field.no ? field.no() : t`No`, value: 'false' },
          ]}
          sx={{ ml: 2, py: 1 }}
        />
      )}
      {fieldType === 'number' && (
        <Flex>
          <TextField
            select
            variant="outlined"
            value={numberComparator}
            sx={{ ml: 2, minWidth: '20ch' }}
            onChange={handleNumberComparatorChange}
          >
            <MenuItem value={NumberComparator.EQUALS}>{t`Equals`}</MenuItem>
            <MenuItem
              value={NumberComparator.GREATER_THEN}
            >{t`Greater then`}</MenuItem>
            <MenuItem
              value={NumberComparator.LESSER_THEN}
            >{t`Lesser then`}</MenuItem>
            <MenuItem
              value={NumberComparator.NOT_EQUALS}
            >{t`Not equals`}</MenuItem>
          </TextField>
          <TextFieldAutosave
            variant="outlined"
            sx={{ ml: 2 }}
            value={`${value}`}
            type="number"
            inputProps={{
              min: field.min,
              max: field.max,
            }}
            onAutoSave={handleNumberFieldSave}
            normalizeValue={(value) =>
              normalizeNumberInt(value, field.min, field.max)
            }
          ></TextFieldAutosave>
        </Flex>
      )}
      {fieldType === 'option' && (
        <Flex>
          <TextField
            select
            variant="outlined"
            value={value}
            sx={{ ml: 2, minWidth: '20ch' }}
            onChange={(e) => handleNumberFieldSave(e.target.value)}
          >
            {options.map((o) => (
              <MenuItem key={o.id} value={o.id}>
                {i18n._(o.name)}
              </MenuItem>
            ))}
          </TextField>
        </Flex>
      )}
    </Flex>
  );
};

export default ReportFilterField;
