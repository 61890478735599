import { Box, IconButton, Typography, Grid } from '@mui/material';
import { BedProps, DATAPOINT_PREVIEW_COUNT } from './MobileBedCellValue';
import { BedMaybeHighligth } from '../../../helpers/dashboard';
import MobileBedCell from './MobileBedCell';
import { Trans, t } from '@lingui/macro';
import Flex from 'src/components/Flex';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PinIcon } from '../../../components/Icons/PinIcon';
import { UnpinIcon } from '../../../components/Icons/UnpinIcon';
import { useContext, useMemo } from 'react';
import { MobileDashboardContext } from '../../Settings/MobilDashboardProvider';
import {
  SvDashboardSortingDataType,
  SvDashboardSortingType,
} from 'src/pages/SafetyView/Setting/DashboardSettingProvider';

interface BedHeaderProps extends BedProps {
  bed: BedMaybeHighligth;
  order: SvDashboardSortingDataType[];
  togglePin: () => Promise<void>;
  anonymized: boolean;
  bedIsActive: boolean;
  alertDuration: number | null;
  bedIsOffine: boolean;
  singleLineLayout?: boolean;
  expanded: boolean;
}

export const getShortTitle = (bed: BedMaybeHighligth) => {
  if (bed.inAnotherWard) {
    return bed.location || t`Unknown`;
  }
  if (bed.ppName && bed.roomName && bed.sessionName) {
    return `${bed.ppName} ${bed.roomName} - ${bed.sessionName}`;
  }
  if (bed.roomName && bed.sessionName) {
    return `${bed.roomName} - ${bed.sessionName}`;
  }
  if (bed.roomName && !bed.sessionName) {
    return `${bed.ppName} ${bed.roomName}`;
  }
  if (!bed.roomName && bed.sessionName) {
    return `${bed.ppName} - ${bed.sessionName}`;
  }
  return bed.ppName;
};

const MobileBedHeader = ({
  bed,
  order,
  anonymized,
  singleLineLayout,
  bedIsActive,
  bedIsOffine,
  alertDuration,
  togglePin,
  expanded,
}: BedHeaderProps) => {
  const { pinningEnabled } = useContext(MobileDashboardContext);
  const isHighligthed = !!alertDuration;

  const previewDatapoints = useMemo(() => {
    return order.slice(0, DATAPOINT_PREVIEW_COUNT);
  }, [order]);

  if (isHighligthed) {
    if (singleLineLayout) {
      return (
        <SingleLineHighlightedHeader
          pinningEnabled={pinningEnabled}
          bed={bed}
          alertDuration={alertDuration}
          expanded={expanded}
          togglePin={togglePin}
          bedIsActive={bedIsActive}
          bedIsOffine={bedIsOffine}
        />
      );
    }
    return (
      <HighlightedHeader
        pinningEnabled={pinningEnabled}
        bed={bed}
        bedIsActive={bedIsActive}
        bedIsOffine={bedIsOffine}
        alertDuration={alertDuration}
        expanded={expanded}
        togglePin={togglePin}
      />
    );
  }
  if (singleLineLayout) {
    return (
      <SingleLineNormalHeader
        pinningEnabled={pinningEnabled}
        bed={bed}
        expanded={expanded}
        togglePin={togglePin}
      />
    );
  }
  return (
    <NormalHeader
      previewDatapoints={previewDatapoints}
      pinningEnabled={pinningEnabled}
      bed={bed}
      expanded={expanded}
      togglePin={togglePin}
      anonymized={anonymized}
      bedIsActive={bedIsActive}
      bedIsOffine={bedIsOffine}
    />
  );
};

export default MobileBedHeader;

interface SingleLineHighlightedHeaderProps {
  bed: BedMaybeHighligth;
  expanded: boolean;
  togglePin: () => Promise<void>;
  alertDuration: number | null;
  bedIsActive: boolean;
  bedIsOffine: boolean;
  pinningEnabled: boolean;
}

const SingleLineHighlightedHeader = ({
  bed,
  expanded,
  togglePin,
  alertDuration,
  bedIsActive,
  bedIsOffine,
  pinningEnabled,
}: SingleLineHighlightedHeaderProps) => {
  const formattedTime = getFormatedDate(alertDuration);

  return (
    <Box width={1}>
      <Box display="flex">
        <Flex
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography maxWidth={expanded ? '45vw' : '35vw'} noWrap>
            {getShortTitle(bed)}
          </Typography>
          {!expanded ? (
            <Typography noWrap>
              {`${t`BED EXIT`} | ${formattedTime}`}
            </Typography>
          ) : null}
        </Flex>
        <Flex sx={{ marginLeft: 2 }}>
          {pinningEnabled && expanded ? (
            <IconButton
              size="small"
              sx={{ color: 'currentColor', pointerEvents: 'all' }}
              onClick={async (event) => {
                event.stopPropagation();
                await togglePin();
              }}
            >
              {bed.pinned ? <UnpinIcon /> : <PinIcon />}
            </IconButton>
          ) : null}
          <IconButton size="small" sx={{ color: 'currentColor' }}>
            <ExpandMoreIcon
              sx={{
                transitionDuration: '0.5s',
                transitionProperty: 'transform',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton>
        </Flex>
      </Box>
      {expanded && bedIsActive ? (
        <Flex mt={2}>{`${t`BED EXIT EVENT`} | ${formattedTime}`}</Flex>
      ) : null}
      {expanded && !bedIsActive ? (
        <Typography mt={2} align="center" sx={{ width: '100%' }}>
          {bedIsOffine ? <Trans>Disconnected</Trans> : <Trans>No bed</Trans>}
        </Typography>
      ) : null}
    </Box>
  );
};

interface HighlightedHeaderProps {
  bed: BedMaybeHighligth;
  expanded: boolean;
  alertDuration: number | null;
  togglePin: () => Promise<void>;
  bedIsActive: boolean;
  bedIsOffine: boolean;
  pinningEnabled: boolean;
}

const getAlertMinutes = (time: number) => {
  return String(Math.min(99, Math.floor(time / 60))).padStart(2, '0');
};

const getAlertSeconds = (time: number) => {
  return String(time % 60).padStart(2, '0');
};

const getFormatedDate = (alertDuration: number | null) => {
  if (!alertDuration) {
    return '00:00';
  }
  return `${getAlertMinutes(alertDuration)}:${getAlertSeconds(alertDuration)}`;
};

const HighlightedHeader = ({
  togglePin,
  bed,
  expanded,
  alertDuration,
  bedIsActive,
  bedIsOffine,
  pinningEnabled,
}: HighlightedHeaderProps) => {
  const formattedTime = getFormatedDate(alertDuration);
  return (
    <Box width={1}>
      <TopHeader
        pinningEnabled={pinningEnabled}
        shortTitle={getShortTitle(bed) || ''}
        pinned={bed.pinned}
        togglePin={togglePin}
        expanded={expanded}
      />
      {bedIsActive && !bed.inAnotherWard ? (
        <Flex>{`${t`BED EXIT EVENT`} | ${formattedTime}`}</Flex>
      ) : null}
      {!bedIsActive && !bed.inAnotherWard ? (
        <Typography align="center" sx={{ width: '100%' }}>
          {bedIsOffine ? <Trans>Disconnected</Trans> : <Trans>No bed</Trans>}
        </Typography>
      ) : null}
    </Box>
  );
};

interface SingleLineCollapsedNormalHeaderProps {
  bed: BedMaybeHighligth;
  expanded: boolean;
  togglePin: () => Promise<void>;
  pinningEnabled: boolean;
}

const SingleLineNormalHeader = ({
  bed,
  expanded,
  togglePin,
  pinningEnabled,
}: SingleLineCollapsedNormalHeaderProps) => {
  return (
    <Box width={1}>
      <Box display="flex">
        <Flex
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography maxWidth={expanded ? '45vw' : '35vw'} noWrap>
            {getShortTitle(bed)}
          </Typography>
          <Flex>
            {!expanded && bed.inAnotherWard ? (
              <Typography sx={{ width: '150px', textAlign: 'center' }}>
                <Trans>Bed is used by another unit</Trans>
              </Typography>
            ) : null}
            {!expanded && !bed.inAnotherWard ? (
              <MobileBedCell
                cellType={SvDashboardSortingType.outOfBed}
                anonymized={true}
                bed={bed}
              />
            ) : null}
            {pinningEnabled && expanded ? (
              <IconButton
                size="small"
                sx={{ color: 'currentColor', pointerEvents: 'all' }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await togglePin();
                }}
              >
                {bed.pinned ? <UnpinIcon /> : <PinIcon />}
              </IconButton>
            ) : null}
            <IconButton size="small">
              <ExpandMoreIcon
                sx={{
                  transitionDuration: '0.5s',
                  transitionProperty: 'transform',
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </IconButton>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

interface NormalHeaderProps {
  previewDatapoints: SvDashboardSortingDataType[];
  bed: BedMaybeHighligth;
  expanded: boolean;
  anonymized: boolean;
  togglePin: () => Promise<void>;
  bedIsActive: boolean;
  bedIsOffine: boolean;
  pinningEnabled: boolean;
}

const NormalHeader = ({
  previewDatapoints,
  togglePin,
  bed,
  expanded,
  anonymized,
  bedIsActive,
  bedIsOffine,
  pinningEnabled,
}: NormalHeaderProps) => {
  return (
    <Box width={1}>
      <TopHeader
        pinningEnabled={pinningEnabled}
        shortTitle={getShortTitle(bed) || ''}
        pinned={bed.pinned}
        togglePin={togglePin}
        expanded={expanded}
      />
      <Grid container rowSpacing={1} columnSpacing={2}>
        {bedIsActive &&
          !bed.inAnotherWard &&
          previewDatapoints.map((datapoint) => {
            return (
              <Grid item xs={4} key={`${bed.unitId}-${datapoint.type}`}>
                <MobileBedCell
                  anonymized={anonymized}
                  cellType={datapoint.type}
                  bed={bed}
                />
              </Grid>
            );
          })}
        {!bedIsActive && !bed.inAnotherWard ? (
          <Typography align="center" sx={{ width: '100%' }}>
            {bedIsOffine ? <Trans>Disconnected</Trans> : <Trans>No bed</Trans>}
          </Typography>
        ) : null}
        {bed.inAnotherWard ? (
          <Typography align="center" sx={{ width: '100%' }}>
            <Trans>Bed is used by another unit</Trans>
          </Typography>
        ) : null}
      </Grid>
    </Box>
  );
};

interface TopHeaderProps {
  shortTitle: string;
  togglePin: () => Promise<void>;
  pinned: boolean;
  expanded: boolean;
  pinningEnabled: boolean;
}

const TopHeader = ({
  shortTitle,
  togglePin,
  pinned,
  expanded,
  pinningEnabled,
}: TopHeaderProps) => {
  return (
    <Box mb={2} display="flex">
      <Flex
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography maxWidth={expanded ? '45vw' : '35vw'} noWrap>
          {shortTitle}
        </Typography>
        <Flex>
          {pinningEnabled ? (
            <IconButton
              size="small"
              sx={{ color: 'currentColor', pointerEvents: 'all' }}
              onClick={async (event) => {
                event.stopPropagation();
                await togglePin();
              }}
            >
              {pinned ? <UnpinIcon /> : <PinIcon />}
            </IconButton>
          ) : null}
          <IconButton size="small" sx={{ color: 'currentColor' }}>
            <ExpandMoreIcon
              sx={{
                transitionDuration: '0.5s',
                transitionProperty: 'transform',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton>
        </Flex>
      </Flex>
    </Box>
  );
};
