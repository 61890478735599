import { t } from '@lingui/macro';
import { Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import WardSelector from '../../components/WardSelector';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const WardSelectDialog = ({ open, setOpen }: Props) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box minWidth={240} p={4}>
        <Typography sx={{ mb: 2 }}>{t`Select unit`}</Typography>
        <WardSelector
          sx={{
            flexDirection: 'column',
            gap: 2,
          }}
          fieldSx={{
            width: '100%',
          }}
        />
      </Box>
    </Dialog>
  );
};
