import { useTheme } from '@mui/material';
import { ReactComponent as BedSvgIcon } from 'src/images/safetyView/dashboard/bed.svg';
import { ReactComponent as Exclamation } from 'src/images/safetyView/dashboard/exclamation.svg';
import { ReactComponent as Men } from 'src/images/safetyView/dashboard/men.svg';

interface Props {
  state: 'on' | 'off' | 'warning' | 'error';
  patientInBed?: boolean;
  useEmFontSize?: boolean;
}

export const BedExitIcon = ({ state, patientInBed, useEmFontSize }: Props) => {
  const theme = useTheme();
  const color =
    state === 'on'
      ? theme.palette.success.light
      : state === 'warning'
      ? theme.palette.warning.main
      : state === 'error'
      ? theme.palette.error.main
      : theme.palette.grey[600];

  const fontSize15 = useEmFontSize ? '1.5em' : '1.5rem';
  const fontSize1 = useEmFontSize ? '1em' : '1rem';
  const fontSize025 = useEmFontSize ? '0.25em' : '0.25rem';
  if (state === 'error') {
    return (
      <>
        <Men color={color} height={fontSize15} width={fontSize1} />{' '}
        <Exclamation color={color} height={fontSize15} width={fontSize025} />
      </>
    );
  }
  if (!patientInBed) {
    return <Men color={color} height={fontSize15} width={fontSize1} />;
  }
  return <BedSvgIcon color={color} height={fontSize1} width={fontSize15} />;
};
