import CableIcon from '@mui/icons-material/Cable';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';

interface Props {
  status: 'ONLINE' | 'OFFLINE';
  ethernet?: boolean;
}

export const BedStatus = ({ status, ethernet }: Props) => {
  if (status === 'OFFLINE') {
    return <SignalWifiOffIcon color="error" />;
  }
  if (ethernet) {
    return <CableIcon sx={{ color: 'success.light' }} />;
  }
  return <WifiIcon sx={{ color: 'success.light' }} />;
};
