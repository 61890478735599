import { useMutation, useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  styled,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Flex from 'src/components/Flex';
import { SearchField } from 'src/components/SearchField';
import { createReportingDashboardMutation } from 'src/graphql/mutations';
import {
  reportingDashboardsQuery,
  reportingReportsQuery,
} from 'src/graphql/queries';
import {
  CreateReportingDashboard,
  CreateReportingDashboardVariables,
  ReportingDashboardsQuery,
  ReportingReportsQuery,
} from 'src/graphql/types';
import { filterObjectsByQueryString } from 'src/lib/filterHelper';
import { ReportingPathParams } from './types';

export const REPORTING_MENU_WIDTH = '300px';

const ReportingMenu = (props: BoxProps) => {
  const { section, id } = useParams<ReportingPathParams>();
  const navigate = useNavigate();
  const [dashboardsOpen, setDashboardsOpen] = useState(true);
  const [reportsOpen, setReportsOpen] = useState(true);
  const [goalsOpen, setGoalsOpen] = useState(true);
  const [query, setQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleNewClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNewClose = () => {
    setAnchorEl(null);
  };

  const { data: reportsData } = useQuery<ReportingReportsQuery>(
    reportingReportsQuery,
  );

  const { data: dashboardsData } = useQuery<ReportingDashboardsQuery>(
    reportingDashboardsQuery,
  );

  const [createNewDashboard] = useMutation<
    CreateReportingDashboard,
    CreateReportingDashboardVariables
  >(createReportingDashboardMutation);

  const dashboardsFiltered = dashboardsData?.reportingDashboards?.filter(
    filterObjectsByQueryString('name', query),
  );

  const handleNewBoard = () => {
    handleNewClose();
    createNewDashboard({
      variables: {
        input: {
          name: t`New Board`,
        },
      },
      refetchQueries: [{ query: reportingDashboardsQuery }],
      onCompleted: (data) => {
        navigate(`/reporting/dashboards/${data.createReportingDashboard.id}`);
      },
    });
  };

  const reportsFiltered =
    reportsData?.reportingReports
      ?.filter((report) => report.goal == null)
      .filter(filterObjectsByQueryString('name', query)) || [];
  const goalsFiltered =
    reportsData?.reportingReports
      ?.filter((report) => report.goal != null)
      .filter(filterObjectsByQueryString('name', query)) || [];

  return (
    <MenuWrapper {...props}>
      <Flex px={2} py={3} alignItems="flex-end">
        <SearchField
          value={query}
          onValueChange={setQuery}
          variant="outlined"
          placeholder={t`Board,report,goal`}
        />
        <Button
          variant="outlined"
          color="primary"
          sx={{ ml: 1 }}
          startIcon={<AddIcon />}
          onClick={handleNewClick}
        >{t`New`}</Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleNewClose}>
          <MenuItem component={Button} onClick={handleNewBoard}>
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              sx={{ textTransform: 'capitalize' }}
            >{t`Board`}</ListItemText>
          </MenuItem>
          <LinkMenuItem
            component={Link}
            to="reports/create"
            onClick={handleNewClose}
          >
            <ListItemIcon>
              <InsertChartOutlinedRoundedIcon />
            </ListItemIcon>
            <ListItemText>{t`Report`}</ListItemText>
          </LinkMenuItem>
          <LinkMenuItem
            component={Link}
            to="goals/create"
            onClick={handleNewClose}
          >
            <ListItemIcon>
              <FlagOutlinedIcon />
            </ListItemIcon>
            <ListItemText>{t`Goal`}</ListItemText>
          </LinkMenuItem>
        </Menu>
      </Flex>
      <MenuList sx={{ fontWeight: 'bold' }}>
        <MenuItem onClick={() => setDashboardsOpen((e) => !e)}>
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <RootItemText>{t`Boards`}</RootItemText>
          <ListItemIcon>
            {dashboardsOpen ? (
              <ExpandLessOutlinedIcon />
            ) : (
              <ExpandMoreOutlinedIcon />
            )}
          </ListItemIcon>
        </MenuItem>
        <Collapse
          component="li"
          in={dashboardsOpen}
          timeout="auto"
          title="text"
        >
          {dashboardsFiltered?.map((d) => (
            <LinkMenuItem
              key={d.id}
              component={Link}
              to={`dashboards/${d.id}`}
              selected={section === 'dashboards' && id === String(d.id)}
            >
              <ListItemIcon></ListItemIcon>
              <MenuListItemText>{d.name}</MenuListItemText>
            </LinkMenuItem>
          ))}
        </Collapse>
        <MenuItem onClick={() => setReportsOpen((e) => !e)}>
          <ListItemIcon>
            <InsertChartOutlinedRoundedIcon />
          </ListItemIcon>
          <RootItemText>{t`Reports`}</RootItemText>
          <ListItemIcon>
            {reportsOpen ? (
              <ExpandLessOutlinedIcon />
            ) : (
              <ExpandMoreOutlinedIcon />
            )}
          </ListItemIcon>
        </MenuItem>
        <Collapse component="li" in={reportsOpen} timeout="auto" title="text">
          {reportsFiltered.map((d) => (
            <LinkMenuItem
              key={d.id}
              component={Link}
              to={`reports/${d.id}`}
              selected={section === 'reports' && id === String(d.id)}
            >
              <ListItemIcon></ListItemIcon>
              <MenuListItemText>{d.name}</MenuListItemText>
            </LinkMenuItem>
          ))}
        </Collapse>
        <MenuItem onClick={() => setGoalsOpen((e) => !e)}>
          <ListItemIcon>
            <FlagOutlinedIcon />
          </ListItemIcon>
          <RootItemText>{t`Goals`}</RootItemText>
          <ListItemIcon>
            {goalsOpen ? (
              <ExpandLessOutlinedIcon />
            ) : (
              <ExpandMoreOutlinedIcon />
            )}
          </ListItemIcon>
        </MenuItem>
        <Collapse component="li" in={goalsOpen} timeout="auto" title="text">
          {goalsFiltered.map((d) => (
            <LinkMenuItem
              key={d.id}
              component={Link}
              to={`goals/${d.id}`}
              selected={section === 'goals' && id === String(d.id)}
            >
              <ListItemIcon></ListItemIcon>
              <MenuListItemText>{d.name}</MenuListItemText>
            </LinkMenuItem>
          ))}
        </Collapse>
      </MenuList>
    </MenuWrapper>
  );
};

export default ReportingMenu;

const MenuWrapper = styled(Box)(
  ({ theme: { palette } }) => `
    flex-shrink: 0;
    height: 100%;
    width: ${REPORTING_MENU_WIDTH};
    background-color: ${palette.background.paper};
    overflow: auto;
    color: ${palette.text.secondary};
`,
);

const LinkMenuItem = styled(MenuItem)<{ component: any; to: string }>(
  ({ theme: { palette } }) => `
  &.Mui-selected {
    color: ${palette.primary.main};
    background-color: ${palette.grey[200]};
  }
`,
);

const RootItemText = styled(ListItemText)(
  ({ theme: { palette } }) => `
    .MuiTypography-root {
        font-size: 1rem;
        font-weight: bold;
        text-transform: capitalize;
        font-stretch: expanded;
        color: ${palette.text.primary};
    }
`,
);

const MenuListItemText = styled(ListItemText)`
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
