import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  ReportingDashboardQuery_reportingDashboard_reports,
  UpdateReportingDashboardReportPosition,
  UpdateReportingDashboardReportPositionVariables,
} from 'src/graphql/types';
import ReportDraggableGraphItem from './ReportDraggableGraphItem';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useMemo, useState } from 'react';
import { updateReportingDashboardReportPosition } from 'src/graphql/mutations';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

const ResponsiveGridLayout = WidthProvider(Responsive);

interface Props {
  items?: ReportingDashboardQuery_reportingDashboard_reports[] | null;
}

const ReportDraggableGraphsList = ({ items }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [isDraggable, setIsDraggable] = useState(false);
  const [updatePosition] = useMutation<
    UpdateReportingDashboardReportPosition,
    UpdateReportingDashboardReportPositionVariables
  >(updateReportingDashboardReportPosition);

  const handleUpdatePosition = (
    reportPositions: {
      w: number;
      h: number;
      x: number;
      y: number;
      i: string;
    }[],
  ) => {
    updatePosition({
      variables: {
        input: {
          dashboardId: id as string,
          reportPositions: reportPositions.map((pos) => ({
            x: `${pos.x}-${pos.w}`,
            y: `${pos.y}-${pos.h}`,
            reportId: pos.i,
          })),
        },
      },
    });
  };

  const children = useMemo(
    () =>
      items?.map(({ x, y, reportingReportId }) => {
        const [startAtX, width] = x.split('-');
        const [startAtY, height] = y.split('-');
        return (
          <div
            key={reportingReportId}
            data-grid={{
              x: Number(startAtX),
              y: Number(startAtY),
              w: Number(width),
              h: Number(height),
            }}
          >
            <ReportDraggableGraphItem
              reportId={reportingReportId}
              setDraggable={setIsDraggable}
            />
          </div>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items?.length, isDraggable],
  );

  return (
    <ResponsiveGridLayout
      className="layout"
      style={{ margin: -16 }}
      cols={{ lg: 4, md: 4, sm: 4, xs: 4, xxs: 4 }}
      rowHeight={440}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      margin={[16, 16]}
      onDragStop={handleUpdatePosition}
      onResizeStop={handleUpdatePosition}
      isDraggable={isDraggable}
      isBounded
    >
      {children}
    </ResponsiveGridLayout>
  );
};

export default ReportDraggableGraphsList;
