import { Box, IconButton, TextField, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { useLingui } from '@lingui/react';
import Flex from 'src/components/Flex';
import { useState } from 'react';
import {
  CareNoteItemDataProps,
  useCareNoteData,
} from '../../BedDetailModal/CareNotes/useCareNoteData';
import CareNoteDeleteDialog from '../../BedDetailModal/CareNotes/CareNoteDeleteDialog';

interface Props {
  disable?: boolean;
  patientSessionId: string;
  data: CareNoteItemDataProps;
}

const MobileCareNoteItem = ({ data, patientSessionId, disable }: Props) => {
  const { i18n } = useLingui();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    note,
    setNote,
    setEditNote,
    deleteNote,
    editNote,
    editable,
    saveNote,
  } = useCareNoteData({
    disable,
    patientSessionId,
    data,
  });

  return (
    <>
      <Box my={1} display="flex">
        <Box width="100%">
          <Typography variant="caption" color="text.secondary">
            {data.date &&
              i18n.date(data.date, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
          </Typography>
          <Box display="flex" flex={1} flexDirection="row">
            {!editNote ? (
              <Typography
                variant="inherit"
                sx={{
                  display: '-webkit-box',
                  '-webkit-line-clamp': '2',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-word',
                }}
              >
                {data.note}
              </Typography>
            ) : (
              <>
                <TextField
                  fullWidth
                  value={note}
                  size="small"
                  onChange={({ target }) => setNote(target.value)}
                  variant="outlined"
                />
                <IconButton onClick={saveNote}>
                  <SaveIcon sx={{ color: 'primary.main' }} />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
        <Box
          ml={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {!editNote ? (
            <>
              {!disable && editable && (
                <Flex>
                  <IconButton onClick={() => setEditNote(true)}>
                    <CreateIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                  <IconButton onClick={() => setOpenDeleteDialog(true)}>
                    <DeleteIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                </Flex>
              )}
            </>
          ) : null}
        </Box>
      </Box>
      <CareNoteDeleteDialog
        setOpen={setOpenDeleteDialog}
        open={openDeleteDialog}
        onDelete={deleteNote}
      />
    </>
  );
};

export default MobileCareNoteItem;
