import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import FormikSwitch from '../../../components/Formik/FormikSwitch';
import FormikTextField from '../../../components/Formik/FormikTextField';

export interface PasswordSettingFormikProps {
  alfaRequired: boolean;
  alfaLowerRequired: boolean;
  alfaUpperRequired: boolean;
  numericRequired: boolean;
  symbolRequired: boolean;
  minLength: number;
}

export const PasswordForm = () => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
    >
      <FormikSwitch name="alfaRequired" label={t`Letter required`} />
      <FormikSwitch
        name="alfaLowerRequired"
        label={t`Lowercase letter required`}
      />
      <FormikSwitch
        name="alfaUpperRequired"
        label={t`Uppercase letter required`}
      />
      <FormikSwitch name="numericRequired" label={t`Number required`} />
      <FormikSwitch name="symbolRequired" label={t`Symbol required`} />
      <FormikTextField
        sx={{
          '.MuiOutlinedInput-input': {
            padding: '12px',
          },
        }}
        name="minLength"
        variant="outlined"
        type="number"
        label={t`Minimum length required`}
      />
    </Box>
  );
};
