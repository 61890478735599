import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment, IconButton } from '@mui/material';
import { MouseEventHandler } from 'react';

interface Props {
  toggle: MouseEventHandler<HTMLButtonElement>;
  type: 'password' | 'text';
}

export const PasswordToggleAdornment = ({ toggle, type }: Props) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={toggle}>
        {type === 'password' ? (
          <VisibilityIcon color="primary" />
        ) : (
          <VisibilityOffIcon color="primary" />
        )}
      </IconButton>
    </InputAdornment>
  );
};
