import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import {
  createParkingPlaceMutation,
  removeParkingPlace as removeParkingPlaceMutation,
} from 'src/graphql/mutations';
import { parkingPlacesQuery } from 'src/graphql/queries';
import {
  CreateParkingPlace,
  CreateParkingPlaceVariables,
  ParkingPlacesQuery,
  ParkingPlacesQueryVariables,
  removeParkingPlaceMutation as RemoveParkingPlace,
  removeParkingPlaceMutationVariables as RemoveParkingPlaceVariables,
} from 'src/graphql/types';

export const useParkingPlaces = (roomId?: string | null) => {
  const { data, refetch } = useQuery<
    ParkingPlacesQuery,
    ParkingPlacesQueryVariables
  >(parkingPlacesQuery, {
    skip: !roomId,
    variables: {
      roomId: roomId || null,
    },
  });
  const room = roomId ? data?.room : null;

  useEffect(() => {
    if (roomId) {
      refetch({ roomId: roomId });
    }
  }, [roomId, refetch]);

  const [createParkingPlace] = useMutation<
    CreateParkingPlace,
    CreateParkingPlaceVariables
  >(createParkingPlaceMutation);
  const [removeParkingPlace] = useMutation<
    RemoveParkingPlace,
    RemoveParkingPlaceVariables
  >(removeParkingPlaceMutation);

  const handleParkingPlaceCreate = async () => {
    const roomId = room?.id;
    if (!roomId) {
      return;
    }
    await createParkingPlace({
      variables: {
        roomId,
      },
      refetchQueries: [{ query: parkingPlacesQuery, variables: { roomId } }],
    });
  };

  const handleParkingPlaceRemove = async (parkingPlaceId?: string | null) => {
    const roomId = room?.id;
    if (!roomId) {
      return;
    }
    if (!parkingPlaceId) {
      return;
    }
    await removeParkingPlace({
      variables: {
        parkingPlaceId,
      },
      refetchQueries: [{ query: parkingPlacesQuery, variables: { roomId } }],
    });
  };

  return {
    roomId: room?.id,
    roomName: room?.name || '',
    parkingPlaces: room?.parkingPlaces || [],
    handleParkingPlaceCreate,
    handleParkingPlaceRemove,
  };
};
