import { useSubscription } from '@apollo/client';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { IconButton, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ConfirmModal } from 'src/components/ConfirmModal';
import SwitchBtn from 'src/components/SwitchBtn';
import { Column, TableOddEven } from 'src/components/TableOddEven';
import { hl7ConnectionStatus } from 'src/graphql/subscriptions';
import {
  ConnectionsQuery_connections,
  HL7ConnectionStatus,
} from 'src/graphql/types';
import { ConnectionDetailFields } from 'src/pages/Admin/GlobalSettings/DataTransferSettings';
import {
  ConnectionSettingsState,
  ControlStateType,
  SpEnabledGroupsType,
} from 'src/pages/Admin/GlobalSettings/globalSettingsHelper';
import ConnectionDetail from './ConnectionDetail';
import { initConnectionMap, ConnectionStatus } from './helpers';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDirtyContext } from 'src/providers/DirtyProvider';
import { CircularButtonContainer } from '../components/CircularButtonContainer';

export const COLUMN_WIDTHS = [180, 100, 100, 100];

interface Props {
  connections: ConnectionsQuery_connections[];
  onConnectionClick: (connectionId: string | null) => void;
  deleteConnection: (id: string) => Promise<void>;
  onChangeEnabled: (connectionId: string, enabled: boolean) => void;
  controlState: ControlStateType[];
  setControlState: React.Dispatch<React.SetStateAction<ControlStateType[]>>;
  spEnabledGroups: SpEnabledGroupsType;
  connectionSettings: ConnectionSettingsState | null;
  onChangeConnection: (
    type: ConnectionDetailFields,
    value: any,
    saveImmediately?: boolean | undefined,
  ) => void;
  onSave: (
    connSettings?: ConnectionSettingsState | undefined,
  ) => null | Promise<any>;
  setConnectionSettings: React.Dispatch<
    React.SetStateAction<ConnectionSettingsState | null>
  >;
}

const ConnectionsTable = ({
  connections,
  onChangeEnabled,
  onConnectionClick,
  connectionSettings,
  deleteConnection,
  controlState,
  onChangeConnection,
  onSave,
  spEnabledGroups,
  setControlState,
  setConnectionSettings,
}: Props) => {
  const [connectionToDelete, setConnectionToDelete] = useState<string | null>(
    null,
  );
  const { actionGuard } = useDirtyContext();
  const [connectionStatusMap, setConnectionStatusMap] = useState<
    Record<string, ConnectionStatus>
  >(initConnectionMap({}, connections));
  const { i18n } = useLingui();

  const columns: Column<ConnectionsQuery_connections>[] = [
    {
      header: t`Connection`,
      key: 'name',
      cellProps: {
        sx: {
          fontWeight: 'bold',
        },
      },
    },
    {
      header: t`Enabled`,
      key: 'enabled',
      cellProps: {
        sx: {
          fontWeight: 'bold',
        },
      },
      renderValue: (row) => (
        <Box width={50}>
          <SwitchBtn
            id={`connection-table-${row.id}`}
            state={row.enabled}
            preventDefault
            onChange={() => onChangeEnabled(row.id, !row.enabled)}
          />
        </Box>
      ),
    },
    {
      header: t`Status`,
      key: 'status',
      cellProps: {
        sx: {
          fontWeight: 'bold',
        },
      },
      renderValue: (row) => {
        const status = connectionStatusMap[row.id]?.status || 0;
        switch (status) {
          case 0:
            return t`DISCONNECTED`;
          case 1:
            return t`CONNECTING`;
          case 2:
            return t`CONNECTED`;
        }
        return t`DISCONNECTED`;
      },
    },
    {
      header: t`Queue`,
      key: 'queueLength',
      cellProps: {
        sx: {
          fontWeight: 'bold',
        },
      },
      renderValue: (row) => connectionStatusMap[row.id]?.length || 0,
    },
    {
      header: '',
      key: 'remove',
      cellProps: {
        sx: {
          textAlign: 'right',
        },
      },
      renderValue: (row) => (
        <CircularButtonContainer>
          <IconButton
            sx={{ color: 'currentColor' }}
            aria-label="delete button"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setConnectionToDelete(row.id);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </CircularButtonContainer>
      ),
    },
  ];

  useEffect(() => {
    setConnectionStatusMap((prevState) =>
      initConnectionMap(prevState, connections),
    );
  }, [connections]);

  const handleConfig = () => {
    if (connectionToDelete) {
      deleteConnection(connectionToDelete);
    }
    setConnectionToDelete(null);
  };

  useSubscription<HL7ConnectionStatus>(hl7ConnectionStatus, {
    onSubscriptionData: (data) => {
      const connectionStatus = data.subscriptionData.data?.hl7ConnectionStatus;
      if (connectionStatus) {
        setConnectionStatusMap((prevState) => ({
          ...prevState,
          [connectionStatus.id]: {
            id: connectionStatus.id,
            status: connectionStatus.status,
            length: connectionStatus.length,
            date: new Date(),
          },
        }));
      }
    },
  });

  const renderExpanded = (_: ConnectionsQuery_connections) => (
    <ConnectionDetail
      spEnabledGroups={spEnabledGroups}
      setControlState={setControlState}
      controlState={controlState}
      onSave={onSave}
      onChangeConnection={onChangeConnection}
      connectionSettings={connectionSettings}
      setConnectionSettings={setConnectionSettings}
    />
  );

  const handleConnectionClick = (connectionId: string) => {
    actionGuard(() => {
      onConnectionClick(
        connectionSettings?.id === connectionId ? null : connectionId,
      );
    });
  };

  const rowProps = (connection: ConnectionsQuery_connections) => ({
    onClick: () => handleConnectionClick(connection.id),
    selected: connection.id === connectionSettings?.id,
    hover: true,
  });

  return (
    <>
      <TableOddEven
        rowKey="id"
        renderExpanded={renderExpanded}
        data={connections}
        columns={columns}
        rowProps={rowProps}
      />
      <ConfirmModal
        title={i18n._(t`Delete connection`)}
        message={i18n._(
          t`Are you sure you want to delete connection ${connectionToDelete}?`,
        )}
        confirmButtonText={i18n._(t`Delete connection`)}
        cancelButtonText={i18n._(t`Cancel`)}
        onConfirm={handleConfig}
        open={!!connectionToDelete}
        onClose={() => setConnectionToDelete(null)}
      />
    </>
  );
};

export default ConnectionsTable;
