import { Box, SxProps } from '@mui/system';
import { FormikContextType, FormikProvider } from 'formik';

interface Props<T = any> {
  formik: FormikContextType<T>;
  children: React.ReactNode;
  name?: string;
  sx?: SxProps;
}

const FormikForm = ({ sx, name, formik, children }: Props) => {
  return (
    <FormikProvider value={formik}>
      <Box component="form" sx={sx} name={name} onSubmit={formik.handleSubmit}>
        {children}
      </Box>
    </FormikProvider>
  );
};

export default FormikForm;
