import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { keys } from 'ramda';
import { useCallback, useContext } from 'react';
import { UserRole } from '../graphql/types';
import { AuthContext } from './AuthProvider';

export interface ValidationOptions {
  symbolRequired?: boolean;
  alfaLowerRequired?: boolean;
  alfaUpperRequired?: boolean;
  alfaRequired?: boolean;
  numericRequired?: boolean;
  minLength?: number;
}

type LiteralRequirement = Omit<ValidationOptions, 'minLength'>;
export const SYMBOLS = '#?!@$%^&*-';

const ALFA_BIT = 0b1;
const ALFA_LOWER_BIT = 0b10;
const ALFA_UPPER_BIT = 0b100;
const NUMERIC_BIT = 0b1000;
const SYMBOL_BIT = 0b10000;

const passwordValidationRegex = (options: ValidationOptions) => {
  let regex = '^';
  if (options.alfaRequired) {
    regex += '(?=.*?[a-zA-Z])';
  }
  if (options.alfaLowerRequired) {
    regex += '(?=.*?[a-z])';
  }
  if (options.alfaUpperRequired) {
    regex += '(?=.*?[A-Z])';
  }
  if (options.numericRequired) {
    regex += '(?=.*?[0-9])';
  }
  if (options.symbolRequired) {
    regex += `(?=.*?[${SYMBOLS}])`;
  }
  regex += options.minLength ? `.{${options.minLength},}` : '.*';
  regex += '$';

  return new RegExp(regex);
};

export const getFlagsFromOptions = (
  options: Omit<ValidationOptions, 'minLength'>,
): number => {
  let flags = 0;
  if (options.alfaRequired) {
    flags += ALFA_BIT;
  }
  if (options.alfaLowerRequired) {
    flags += ALFA_LOWER_BIT;
  }
  if (options.alfaUpperRequired) {
    flags += ALFA_UPPER_BIT;
  }
  if (options.numericRequired) {
    flags += NUMERIC_BIT;
  }
  if (options.symbolRequired) {
    flags += SYMBOL_BIT;
  }

  return flags;
};

export const getOptionsFromFlag = (
  flags: number | null | undefined,
): LiteralRequirement => {
  if (flags == null) {
    return {};
  }
  const alfaRequired = Boolean(flags & ALFA_BIT);
  const alfaLowerRequired = Boolean(flags & ALFA_LOWER_BIT);
  const alfaUpperRequired = Boolean(flags & ALFA_UPPER_BIT);
  const numericRequired = Boolean(flags & NUMERIC_BIT);
  const symbolRequired = Boolean(flags & SYMBOL_BIT);
  return {
    alfaRequired,
    alfaLowerRequired,
    alfaUpperRequired,
    numericRequired,
    symbolRequired,
  };
};

export const usePasswordValidator = () => {
  const { config } = useContext(AuthContext);
  const { i18n } = useLingui();
  const userPasswordOptions = getOptionsFromFlag(
    config?.superConfig?.passwordFlags,
  );
  const userPasswordMinLength =
    config?.superConfig?.passwordMinLength ?? undefined;
  const adminPasswordOptions = getOptionsFromFlag(
    config?.superConfig?.adminPasswordFlags,
  );
  const adminPasswordMinLength =
    config?.superConfig?.adminPasswordMinLength ?? undefined;

  const passwordValidator = useCallback(
    (password: string, role: UserRole) => {
      const regex =
        role === UserRole.ADMIN
          ? passwordValidationRegex({
              ...adminPasswordOptions,
              minLength: adminPasswordMinLength,
            })
          : passwordValidationRegex({
              ...userPasswordOptions,
              minLength: userPasswordMinLength,
            });
      return regex.test(password);
    },
    [
      adminPasswordOptions,
      adminPasswordMinLength,
      userPasswordOptions,
      userPasswordMinLength,
    ],
  );

  // todo
  const passwordRequirementsMessage = useCallback(
    (userRole: UserRole) => {
      let passwordOptions = userPasswordOptions;
      let requiredLength = userPasswordMinLength;
      if (userRole === UserRole.ADMIN) {
        passwordOptions = adminPasswordOptions;
        requiredLength = adminPasswordMinLength;
      }
      const requirements = keys(passwordOptions).filter(
        (key) => passwordOptions[key],
      );
      console.log({ passwordOptions, requirements });
      let requirementsText = '';
      if (requirements.length) {
        const TRANS_MAP: Record<keyof LiteralRequirement, string> = {
          alfaRequired: t`letter`,
          alfaLowerRequired: t`lowercase letter`,
          alfaUpperRequired: t`uppercase letter`,
          numericRequired: t`number`,
          symbolRequired: t`special character (${SYMBOLS})`,
        };
        requirementsText = t`atleast one ${requirements
          .map((req) => TRANS_MAP[req])
          .join(', ')}`;
      }
      let requirementsMinLength = '';
      if (requiredLength) {
        requirementsMinLength = t`minimum length ${requiredLength}`;
      }
      return t`Password requirements are ${[
        requirementsText,
        requirementsMinLength,
      ]
        .filter(Boolean)
        .join(', ')}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      adminPasswordOptions,
      adminPasswordMinLength,
      userPasswordOptions,
      userPasswordMinLength,
      i18n.locale,
    ],
  );

  return {
    passwordValidator,
    passwordRequirementsMessage,
  };
};
