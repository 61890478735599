export const createSessionName = (
  firstNameInital: string,
  lastNameInital: string,
): string => {
  if (firstNameInital && lastNameInital) {
    return `${firstNameInital[0].toUpperCase()}. ${lastNameInital[0].toUpperCase()}.`;
  }
  if (lastNameInital) {
    return `${lastNameInital[0].toUpperCase()}.`;
  }
  if (firstNameInital) {
    return `${firstNameInital[0].toUpperCase()}.`;
  }
  return '';
};

export const parseInitials = (name: string) => {
  const [firstName, lastName] = name.split(' ');
  return [firstName?.[0], lastName?.[0]];
};
