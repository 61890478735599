import { Box, Typography, Switch } from '@mui/material';

export const MobileSwitch = ({
  value,
  label,
  onChange,
}: {
  value: boolean;
  label: string;
  onChange: (checked: boolean) => void;
}) => {
  return (
    <Box
      pb={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography>{label}</Typography>
      <Switch
        checked={value}
        onChange={(event, checked) => onChange(checked)}
      />
    </Box>
  );
};
