import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

type DateLike = string | number | Date | null;

const fixFloat = (val: number, precision = 16) => {
  const result = Math.ceil(val * 10 ** precision) / 10 ** precision;
  return result;
};

const useDateFormat = () => {
  const { i18n } = useLingui();

  const formatDuration = (
    durationMs: number,
    options = { hideSeconds: false },
  ) => {
    let h, m, s, d;
    d = Math.floor(fixFloat(durationMs / 1000 / 60 / 60 / 24));
    h = Math.floor(fixFloat(durationMs / 1000 / 60 / 60 / 24 - d) * 24);
    m = Math.floor(
      (fixFloat(durationMs / 1000 / 60 / 60 / 24 - d) * 24 - h) * 60,
    );
    s = Math.floor(
      ((fixFloat(durationMs / 1000 / 60 / 60 / 24 - d) * 24 - h) * 60 - m) * 60,
    );

    let result: string[] = [];
    if (d) {
      result.push(t`${d}d`);
    }

    if (h) {
      result.push(t`${h}h`);
    }

    if (m) {
      result.push(t`${m}m`);
    }
    if (s && !options.hideSeconds) {
      result.push(
        t`${s.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
        })}s`,
      );
    }
    if (!result.length) {
      return options.hideSeconds ? '0m' : '0s';
    }

    return result.join('\u00a0');
  };

  const formatDate = (date?: DateLike) => {
    if (!date) return '';
    return i18n.date(new Date(date), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  const formatDateShort = (date?: DateLike) => {
    if (!date) return '';
    return i18n.date(new Date(date), {
      month: 'numeric',
      day: 'numeric',
    });
  };

  const formatTimeShort = (date?: DateLike) => {
    if (!date) return '';
    return i18n.date(new Date(date), {
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const formatMonth = (date?: DateLike) => {
    if (!date) return '';
    return i18n.date(new Date(date), {
      month: 'long',
    });
  };
  return {
    formatDateShort,
    formatDate,
    formatTimeShort,
    formatDuration,
    formatMonth,
  };
};

export default useDateFormat;
