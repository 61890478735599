interface Props {
  text: string;
  color: string;
}

export const BlockLabel = ({ text, color }: Props) => {
  return <div style={{ ...styles.box, backgroundColor: color }}>{text}</div>;
};

const styles = {
  box: {
    padding: 18,
    color: '#fff',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    width: '9em',
    textAlign: 'center' as 'center',
  },
};
