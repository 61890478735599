import { SafetyPortProps } from 'src/graphql/types';
import { ReactComponent as Backrest } from 'src/images/safetyPortPropsIcons/Backrest.svg';
import { ReactComponent as BedExitAlarm } from 'src/images/safetyPortPropsIcons/Bed_exit_alarm.svg';
import { ReactComponent as BedExitMonitoring } from 'src/images/safetyPortPropsIcons/Bed_exit_monitoring.svg';
import { ReactComponent as BrakesStatus } from 'src/images/safetyPortPropsIcons/Brakes_status.svg';
import { ReactComponent as Height } from 'src/images/safetyPortPropsIcons/Height.svg';
import { ReactComponent as LateralTiltAngle } from 'src/images/safetyPortPropsIcons/Lateral_tilt_angle.svg';
import { ReactComponent as Location } from 'src/images/safetyPortPropsIcons/Location.svg';
import { ReactComponent as PatientOnBed } from 'src/images/safetyPortPropsIcons/Patient_on_bed.svg';
import { ReactComponent as PatientWeight } from 'src/images/safetyPortPropsIcons/Patient_weight.svg';
import { ReactComponent as SafetyPosition } from 'src/images/safetyPortPropsIcons/Safety_position.svg';
import { ReactComponent as SiderailsPosition } from 'src/images/safetyPortPropsIcons/Siderails_position.svg';
import { ReactComponent as TrendelenburgAngle } from 'src/images/safetyPortPropsIcons/Trendelenburg_angle.svg';
import { ReactComponent as PressedButtonUsedController } from 'src/images/safetyPortPropsIcons/Pressed_button_used_controller.svg';
import { ReactComponent as CalfrestLock } from 'src/images/safetyPortPropsIcons/Calfrest_lock.svg';
import { ReactComponent as CalfrestAngle } from 'src/images/safetyPortPropsIcons/Calfrest_angle.svg';
import { ReactComponent as HeightLock } from 'src/images/safetyPortPropsIcons/Height_lock.svg';
import { ReactComponent as BackrestLock } from 'src/images/safetyPortPropsIcons/Backrest_lock.svg';
import { ReactComponent as MobiliftRight } from 'src/images/safetyPortPropsIcons/Mobilift_right.svg';
import { ReactComponent as MobiliftLeft } from 'src/images/safetyPortPropsIcons/Mobilift_left.svg';
import { ReactComponent as MattressType } from 'src/images/safetyPortPropsIcons/Mattress_type.svg';
import { ReactComponent as Transfer } from 'src/images/safetyPortPropsIcons/Transfer.svg';
import { ReactComponent as Alt } from 'src/images/safetyPortPropsIcons/Alt.svg';
import { ReactComponent as ErrorImg } from 'src/images/safetyPortPropsIcons/Error.svg';
import { ReactComponent as BatteryFailure } from 'src/images/safetyPortPropsIcons/Battery_failure.svg';
import { ReactComponent as BedExtension } from 'src/images/safetyPortPropsIcons/Bed_extension.svg';
import { ReactComponent as Lock } from 'src/images/safetyView/dashboard/lock.svg';
import { useTheme } from '@mui/material';

interface Props {
  prop: SafetyPortProps;
  color?: string;
  size?: number | string;
}
export const PropsIcons = ({ prop, color, size }: Props) => {
  const theme = useTheme();
  const iconProps = {
    color: color ?? theme.palette.primary.main,
    width: size || 'auto',
    height: size || 'auto',
  };
  switch (prop) {
    case SafetyPortProps.LINIS0001:
      return <PatientWeight {...iconProps} />;
    case SafetyPortProps.LINIS0002:
    case SafetyPortProps.LINIS0045:
      return <SafetyPosition {...iconProps} />;
    case SafetyPortProps.LINIS0003:
      return <TrendelenburgAngle {...iconProps} />;
    case SafetyPortProps.LINIS0004:
      return <LateralTiltAngle {...iconProps} />;
    case SafetyPortProps.LINIS0005:
    case SafetyPortProps.LINIS0014:
    case SafetyPortProps.LINIS0015:
    case SafetyPortProps.LINIS0016:
    case SafetyPortProps.LINIS0017:
    case SafetyPortProps.LINIS0020:
    case SafetyPortProps.LINIS0021:
      return <SiderailsPosition {...iconProps} />;
    case SafetyPortProps.LINIS0006:
    case SafetyPortProps.LINIS0007:
      return <Backrest {...iconProps} />;
    case SafetyPortProps.LINIS0008:
      return <BrakesStatus {...iconProps} />;
    case SafetyPortProps.LINIS0009:
      return <BedExitMonitoring {...iconProps} />;
    case SafetyPortProps.LINIS0010:
      return <BedExitAlarm {...iconProps} />;
    case SafetyPortProps.LINIS0011:
    case SafetyPortProps.LINIS0030:
    case SafetyPortProps.LINIS0031:
    case SafetyPortProps.LINIS0032:
      return <Height {...iconProps} />;
    case SafetyPortProps.LINIS0012:
      return <PatientOnBed {...iconProps} />;
    case SafetyPortProps.LINIS0013:
    case SafetyPortProps.LINIS0018:
    case SafetyPortProps.LINIS0019:
      return <Location {...iconProps} />;
    case SafetyPortProps.LINIS0022:
      return <CalfrestAngle {...iconProps} />;
    case SafetyPortProps.LINIS0023:
      return <CalfrestLock {...iconProps} />;
    case SafetyPortProps.LINIS0024:
      return <BackrestLock {...iconProps} />;
    case SafetyPortProps.LINIS0025:
      return <HeightLock {...iconProps} />;
    case SafetyPortProps.LINIS0026: //here
      return (
        <Lock
          style={{ width: 12, position: 'relative', top: -1 }}
          {...iconProps}
        />
      );
    case SafetyPortProps.LINIS0028:
      return <Transfer {...iconProps} />;
    case SafetyPortProps.LINIS0033:
      return <MobiliftRight {...iconProps} />;
    case SafetyPortProps.LINIS0034:
      return <MobiliftLeft {...iconProps} />;
    case SafetyPortProps.LINIS0035:
      return <MattressType {...iconProps} />;
    case SafetyPortProps.LINIS0036:
      return <BedExtension {...iconProps} />;
    case SafetyPortProps.LINIS0037:
      return <PressedButtonUsedController {...iconProps} />;
    case SafetyPortProps.LINIS0039:
      return <Alt {...iconProps} />;
    case SafetyPortProps.LINIS0041:
      return <ErrorImg {...iconProps} />;
    case SafetyPortProps.LINIS0042:
      return <BatteryFailure {...iconProps} />;
    default:
      return null;
  }
};
