import { Trans } from '@lingui/macro';
import SaveIcon from '@mui/icons-material/Save';
import { Button, styled } from '@mui/material';
import Flex from './Flex';

interface Props {
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
}

const SaveDiscardButtons = ({ disabled, onCancel, onSave }: Props) => {
  return (
    <Flex>
      {onCancel && (
        <StyledButton
          color="primary"
          variant="outlined"
          onClick={onCancel}
          disabled={disabled}
        >
          <Trans>Discard</Trans>
        </StyledButton>
      )}
      {onSave && (
        <StyledButton
          onClick={onSave}
          variant="contained"
          disabled={disabled}
          color="primary"
          startIcon={<SaveIcon />}
        >
          <Trans>Save changes</Trans>
        </StyledButton>
      )}
    </Flex>
  );
};

export default SaveDiscardButtons;

const StyledButton = styled(Button)({
  marginLeft: 16,
  whiteSpace: 'nowrap',
});
