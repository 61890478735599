import { t } from '@lingui/macro';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HotelIcon from '@mui/icons-material/Hotel';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import WarningOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LinisTextLogo } from 'src/images/linis-text-logo.svg';

interface Props {
  isAdmin?: boolean;
  demoAccess?: boolean;
  spDashboardAccess?: boolean;
  hl7LogEnabled?: boolean;
}

const Menu = ({
  isAdmin,
  demoAccess,
  spDashboardAccess,
  hl7LogEnabled,
}: Props) => {
  const location = useLocation();
  const [dataOpen, setDataOpen] = useState(false);
  const toggleDataOpen = () => {
    setDataOpen((prev) => !prev);
  };

  const isSelected = (path: string) => {
    return (location.pathname || '').indexOf(path) === 0;
  };

  return (
    <MenuDrawer variant="permanent">
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 8px',
          height: '64px',
          backgroundColor: theme.palette.grey[200],
        })}
      >
        <LinisTextLogo />
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: isAdmin ? 'space-between' : 'flex-end',
          flexDirection: 'column',
          flex: 1,
          overflowX: 'hidden',
          paddingLeft: '9px',
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        {isAdmin && (
          <>
            <List>
              <MenuLink to="/admin/hospital-layout">
                <MenuListItem selected={isSelected('/admin/hospital-layout')}>
                  <MenuItemIcon sx={{ color: 'currentColor' }}>
                    <HomeOutlinedIcon />
                  </MenuItemIcon>
                  <ListItemText primary={t`Hospital Layout`} />
                </MenuListItem>
              </MenuLink>
              <Divider />
              <MenuLink to="/admin/beds">
                <MenuListItem selected={isSelected('/admin/beds')}>
                  <MenuItemIcon sx={{ color: 'currentColor' }}>
                    <HotelIcon />
                  </MenuItemIcon>
                  <ListItemText primary={t`Beds`} />
                </MenuListItem>
              </MenuLink>
              <MenuLink to="/admin/rooms">
                <MenuListItem
                  selected={
                    location.pathname.substring(0, 12) === '/admin/rooms'
                  }
                >
                  <MenuItemIcon sx={{ color: 'currentColor' }}>
                    <MeetingRoomOutlinedIcon />
                  </MenuItemIcon>
                  <ListItemText primary={t`Rooms`} />
                </MenuListItem>
              </MenuLink>
              <MenuLink to="/admin/tags">
                <MenuListItem
                  selected={
                    location.pathname.substring(0, 11) === '/admin/tags'
                  }
                >
                  <MenuItemIcon sx={{ color: 'currentColor' }}>
                    <LocationOnOutlinedIcon />
                  </MenuItemIcon>
                  <ListItemText primary={t`Tags`} />
                </MenuListItem>
              </MenuLink>
              {spDashboardAccess ? (
                <>
                  <MenuExpandable onClick={toggleDataOpen}>
                    <ListItem button={true} key="data">
                      <MenuItemIcon sx={{ color: 'currentColor' }}>
                        <WarningOutlinedIcon />
                      </MenuItemIcon>
                      <ListItemText primary={t`Data`} />
                      {dataOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                  </MenuExpandable>
                  {dataOpen ? (
                    <MenuLink to="/admin/insight">
                      <MenuListItem
                        key="insight"
                        selected={isSelected('/admin/insight')}
                      >
                        <SubListItemText primary={t`Insight`} />
                      </MenuListItem>
                    </MenuLink>
                  ) : null}
                  {dataOpen && hl7LogEnabled ? (
                    <MenuLink to="/admin/hl7-log">
                      <MenuListItem selected={isSelected('/admin/hl7-log')}>
                        <SubListItemText primary={t`Hl7 Log`} />
                      </MenuListItem>
                    </MenuLink>
                  ) : null}
                </>
              ) : null}
              <MenuLink to="/admin/users">
                <MenuListItem selected={isSelected('/admin/users')}>
                  <MenuItemIcon sx={{ color: 'currentColor' }}>
                    <AccountCircleOutlinedIcon />
                  </MenuItemIcon>
                  <ListItemText primary={t`User`} />
                </MenuListItem>
              </MenuLink>
            </List>
          </>
        )}
        <div>
          {demoAccess ? (
            <MenuLink to="/demo">
              <MenuListItem selected={location.pathname === '/demo'}>
                <MenuItemIcon sx={{ color: 'currentColor' }}>
                  <SpeedOutlinedIcon sx={{ color: 'currentColor' }} />
                </MenuItemIcon>
                <MenuItemText primary={t`Go to DEMO`} />
              </MenuListItem>
            </MenuLink>
          ) : null}
          {spDashboardAccess && (
            <MenuLink to="/safety-port/dashboard">
              <MenuListItem
                selected={location.pathname === '/safety-port/dashboard'}
              >
                <MenuItemIcon sx={{ color: 'currentColor' }}>
                  <SpeedOutlinedIcon sx={{ color: 'currentColor' }} />
                </MenuItemIcon>
                <MenuItemText primary={t`Go to Dashboard`} />
              </MenuListItem>
            </MenuLink>
          )}
        </div>
      </Box>
    </MenuDrawer>
  );
};

export default Menu;

const MenuExpandable = styled(Box)`
  text-decoration: none;
  color: currentColor;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: currentColor;
`;

const MenuListItem = styled(ListItemButton)(
  ({ selected, theme }) => `
  ${
    selected
      ? `
    color: ${theme.palette.primary.main};
  `
      : null
  }
`,
);

const MenuItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`;

const MenuItemText = styled(ListItemText)`
  display: flex;
  margin-left: 12px;
  white-space: break-spaces;
`;

const MenuDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    position: relative;
    white-space: nowrap;
    width: 240px;
    box-shadow: 4px 0px 10px rgb(0 0 0 / 10%);
    background: white;
    z-index: 1200;
  }
`;

const SubListItemText = styled(ListItemText)`
  padding-left: 32px;
  span {
    font-size: 0.9rem;
  }
`;
