import { useTheme } from '@mui/material';
import { indexBy } from 'ramda';
import { useContext, useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AuthContext } from 'src/auth/AuthProvider';
import { WeightUnits } from 'src/graphql/types';
import useDateFormat from 'src/lib/useDateFormat';
import { convertToPounds } from 'src/lib/weightConverter';
import { GoalType, ReportData, ReportFilterParams } from '../types';
import {
  getReportTag,
  getReportValueForTime,
  prepareGraphTimeTicks,
} from './graphHelpers';
import useGraphColors from './useGraphColors';
import useGraphTimeAxisFormat from './useGraphTimeAxisFormat';
import useReportTagNames from './useReportTagNames';

interface Props {
  data: ReportData[];
  filter: ReportFilterParams;
  goal?: number | null;
}

const ReportGraphBarHorizontal = ({ data, filter, goal }: Props) => {
  const { formatDuration } = useDateFormat();
  const { getColor } = useGraphColors();
  const { formatTimeAxisLabel, getTimeAxisLabel } = useGraphTimeAxisFormat();
  const { config } = useContext(AuthContext);
  const isImperial = config?.config?.unitSystem === WeightUnits.IMPERIAL;
  const { resolveName } = useReportTagNames();
  const theme = useTheme();

  const groupTimes = data
    .map((row) => row.values.map((val) => val.time))
    .flat()
    .sort(
      (time, otherTime) =>
        new Date(time).getTime() - new Date(otherTime).getTime(),
    )
    .filter((val, idx, self) => self.indexOf(val) === idx);
  const unit = data?.[0]?.values?.[0]?.unit || '';
  const tags = indexBy((row) => getReportTag(row.tags) || '', data);

  const graphData = groupTimes.map((time) => ({
    time: formatTimeAxisLabel(time, filter),
    ...Object.keys(tags)
      .filter((key) => !!key)
      .reduce(
        (result, tag) => ({
          ...result,
          unit: getReportValueForTime(time, 'unit')(tags[tag]),
          [tag]: getReportValueForTime(time, 'value')(tags[tag]),
        }),
        {},
      ),
  }));

  const maxValue = Math.max(
    ...graphData.map((val: Record<string, number | string>) =>
      Math.max(
        ...Object.values(val).map((val) => (typeof val === 'number' ? val : 0)),
      ),
    ),
  );

  const ticks = useMemo(
    () =>
      unit === 'seconds'
        ? prepareGraphTimeTicks(maxValue)
        : unit === 'count' && maxValue < 4
        ? [0, 1, 2, 3, 4]
        : undefined,
    [maxValue, unit],
  );

  const formatValue = (val: string | number) => {
    if (unit === 'seconds') {
      return formatDuration(Number(val) * 1000);
    }
    if (!unit || unit === 'count') {
      return `${val}`;
    }
    if (unit === 'Kg' && isImperial) {
      return `${convertToPounds(val)} lb`;
    }
    return `${val} ${unit}`;
  };

  return (
    <ResponsiveContainer minHeight={320} height="100%" width="100%">
      <BarChart
        layout="horizontal"
        data={graphData}
        margin={{ left: 50, right: 50, bottom: 16 }}
        stackOffset="expand"
      >
        <XAxis
          dataKey="time"
          label={{
            value: getTimeAxisLabel(filter),
            dy: 16,
            fill: theme.palette.text.primary,
          }}
        />
        {goal != null ? <ReferenceLine y={goal} stroke="black" /> : null}
        <YAxis
          type="number"
          scale="linear"
          ticks={ticks}
          tickFormatter={formatValue}
          padding={{ top: 10 }}
        />
        <Legend
          align="right"
          verticalAlign="top"
          layout="vertical"
          iconType="circle"
          wrapperStyle={{
            paddingLeft: 10,
            maxHeight: '260px',
            maxWidth: '180px',
            overflowY: 'auto',
          }}
        />
        <CartesianGrid vertical={false} strokeWidth={1} strokeDasharray="3" />
        <Tooltip
          allowEscapeViewBox={{ x: true }}
          formatter={formatValue}
          wrapperStyle={{ zIndex: 1000 }}
        />
        {Object.keys(tags).map((tag, idx) => (
          <Bar
            key={tag}
            dataKey={tag}
            fill={getColor(idx)}
            name={resolveName(tags[tag].tags)}
            shape={(props) => (
              <ReportBarWithGoal
                {...props}
                goal={goal}
                goalType={filter.goalType}
              />
            )}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ReportGraphBarHorizontal;

const ReportBarWithGoal = (props: any) => {
  const color =
    props.goal == null
      ? props.fill
      : (
          props.goalType === GoalType.UNDER
            ? props.value <= props.goal
            : props.value >= props.goal
        )
      ? 'green'
      : 'grey';
  return <Rectangle {...props} fill={color} />;
};
