import { styled, Box, IconButton, ModalProps, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  title?: string | null;
  sx?: SxProps;
  onClose: NonNullable<ModalProps['onClose']>;
}

export const ModalHeader = ({ title, sx, onClose }: Props) => {
  return (
    <HeaderBox sx={sx}>
      {title || `\u00a0`}
      <IconButton
        aria-label="close"
        onClick={() => onClose({}, 'escapeKeyDown')}
        sx={{ color: 'text.secondary' }}
      >
        <CloseIcon />
      </IconButton>
    </HeaderBox>
  );
};

const HeaderBox = styled(Box)(
  ({ theme: { palette, spacing } }) => `
  display: flex;
  flex: 1;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.875rem;
  padding-bottom: ${spacing(1.5)};
  font-stretch: expanded;
  border-bottom: 2px solid ${palette.grey[300]};
  align-items: center;
  color: ${palette.text.secondary};
  justify-content: space-between;
  gap: 1.5rem;
`,
);
