import { useCallback, useState } from 'react';
import { FilterValue } from './filters';

interface Props<T> {
  name: string;
  types: Record<keyof T, string>;
}

const getSessionStorageKey = (
  filterName: string,
  type: string | number | symbol,
) => `${filterName}:${String(type)}`;

const getFilterValueFromSessionStorage = (
  filterName: string,
  type: string | number | symbol,
) => {
  const key = getSessionStorageKey(filterName, type);
  const item = sessionStorage.getItem(key);
  if (!item) {
    return null;
  }
  return JSON.parse(item) as FilterValue;
};

export const setFilterValueFromSessionStorage = (
  filterName: string,
  type: string | number | symbol,
  value: FilterValue,
) => {
  const key = getSessionStorageKey(filterName, type);
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const useFilter = <T extends Record<string, FilterValue | null>>({
  name,
  types,
}: Props<T>) => {
  const [filter, setFilter] = useState<Record<keyof T, FilterValue | null>>(
    Object.keys(types).reduce(
      (prev, type) => ({
        ...prev,
        [type]: getFilterValueFromSessionStorage(name, type),
      }),
      {} as Record<keyof T, FilterValue | null>,
    ),
  );

  const clear = useCallback(
    (type: keyof T) => {
      setFilter((prev) => ({ ...prev, [type]: null }));
      sessionStorage.removeItem(getSessionStorageKey(name, type));
    },
    [name],
  );

  const setFilterValue = useCallback(
    (type: keyof T, value: FilterValue | null) => {
      if (value) {
        setFilter((prev) => ({ ...prev, [type]: value }));
        setFilterValueFromSessionStorage(name, type, value);
      } else {
        clear(type);
      }
    },
    [name, clear],
  );

  const clearAll = useCallback(() => {
    Object.keys(filter).forEach((type) => {
      sessionStorage.removeItem(getSessionStorageKey(name, type));
    });
    setFilter({} as any);
  }, [name, filter]);

  const activeFilters = Object.keys(filter).reduce((prev, type) => {
    const value = filter[type];
    return value ? [...prev, { type, typeName: types[type], value }] : prev;
  }, [] as { type: keyof T; typeName: string; value: FilterValue }[]);

  return {
    filter: filter as T,
    activeFilters,
    setFilterValue,
    clearAll,
    clear,
  };
};
