import { Box, styled, SxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as Info } from 'src/images/safetyView/info.svg';

interface InfoBoxProps {
  text: string;
  color: string;
  sx?: SxProps<Theme>;
}

const InfoBox = ({ text, color, sx = {} }: InfoBoxProps) => {
  return (
    <Box my={1} display="flex" alignItems="center">
      <InfoIcon color={color} />
      <Typography
        sx={{
          fontSize: '0.75rem',
          fontWeight: 300,
          color: color,
          ...sx,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default InfoBox;

const InfoIcon = styled(Info)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 16px;
`;
