import { useTheme } from '@mui/material';
import { ReactComponent as SortIcon } from 'src/images/SortIcon.svg';
import { ReactComponent as SortIconRedDown } from 'src/images/SortIconRedDown.svg';
import { ReactComponent as SortIconRedUp } from 'src/images/SortIconRedUp.svg';

interface Props {
  state: 'unsorted' | 'desc' | 'asc';
}

export const SortingState = ({ state }: Props) => {
  const theme = useTheme();
  if (state === 'desc') {
    return (
      <SortIconRedUp
        width="1rem"
        height="0.75rem"
        color={theme.palette.primary.main}
      />
    );
  }
  if (state === 'asc') {
    return (
      <SortIconRedDown
        width="1rem"
        height="0.75rem"
        color={theme.palette.primary.main}
      />
    );
  }
  return <SortIcon width="1rem" height="0.75rem" color="currentColor" />;
};
