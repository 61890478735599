import { t, Trans } from '@lingui/macro';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { ConfirmModal } from 'src/components/ConfirmModal';
import Flex from 'src/components/Flex';
import {
  ParkingPlacesQuery_room_parkingPlaces as ParkingPlace,
  RoomsQuery_rooms as Room,
  WardsQuery_wards as Ward,
  WorkspacesQuery_workspaces as Workspace,
} from 'src/graphql/types';
import HospitalLayoutColumn from './HospitalLayoutColumn';
import HospitalLayoutNameCard from './HospitalLayoutNameCard';
import HospitalLayoutParkingPlaceCard from './HospitalLayoutParkingPlaceCard';
import HospitalSelectionBreadcrumbs from './HospitalSelectionBreadcrumbs';
import { useHospitalLayoutData } from './useHospitalLayoutData';

type Columns = 'workspaces' | 'wards' | 'rooms' | 'parkingPlaces';

interface ConfirmRemoveState {
  workspace: Workspace | null;
  ward: Ward | null;
  room: Room | null;
  parkingPlace: ParkingPlace | null;
  copyWorkspace: Workspace | null;
}

const HospitalLayoutPage = () => {
  const {
    workspaces,
    handleWorkspaceSelection,
    handleWorkspaceNameChange,
    handleNewWorkspace,
    handleWorkspaceRemove,
    handleCopyWorkspace,
    wards,
    handleWardSelection,
    handleWardNameChange,
    handleWardCreate,
    handleWardRemove,
    rooms,
    handleRoomSelection,
    handleRoomNameChange,
    handleRoomCreate,
    handleRoomRemove,
    parkingPlaces,
    handleParkingPlaceCreate,
    handleParkingPlaceRemove,
    selection,
  } = useHospitalLayoutData();

  const [confirmState, setConfirmState] = useState<ConfirmRemoveState>({
    workspace: null,
    ward: null,
    room: null,
    parkingPlace: null,
    copyWorkspace: null,
  });

  const [parkingPlacesExpanded, setParkingPlacesExpanded] = useState(false);

  const handleConfirmWorkspaceRemove = () => {
    if (confirmState.workspace) {
      handleWorkspaceRemove(confirmState.workspace.id);
      setConfirmState((s) => ({ ...s, workspace: null }));
    }
  };

  const handleConfirmWardRemove = () => {
    if (confirmState.ward) {
      handleWardRemove(confirmState.ward.id);
      setConfirmState((s) => ({ ...s, ward: null }));
    }
  };

  const handleConfirmRoomRemove = () => {
    if (confirmState.room) {
      handleRoomRemove(confirmState.room.id);
      setConfirmState((s) => ({ ...s, room: null }));
    }
  };

  const handleConfirmParkingPlaceRemove = () => {
    if (confirmState.parkingPlace) {
      handleParkingPlaceRemove(confirmState.parkingPlace.id);
      setConfirmState((s) => ({ ...s, parkingPlace: null }));
    }
  };

  const handleConfirmCopyWorkspace = () => {
    if (confirmState.copyWorkspace) {
      handleCopyWorkspace(confirmState.copyWorkspace.id);
      setConfirmState((s) => ({ ...s, copyWorkspace: null }));
    }
  };

  const activeColumn: Columns = !selection.workspaceId
    ? 'workspaces'
    : !selection.wardId
    ? 'wards'
    : !selection.roomId
    ? 'rooms'
    : 'parkingPlaces';

  return (
    <Flex px={5} height="calc( 100% - 180px )" flexDirection="column">
      <HospitalSelectionBreadcrumbs
        selection={selection}
        workspaces={workspaces}
        wards={wards}
        rooms={rooms}
      />
      <Flex flexDirection="row" flexGrow={1} minHeight="1px">
        {!parkingPlacesExpanded && (
          <>
            <HospitalLayoutColumn
              title={t`Facilities`}
              active={activeColumn === 'workspaces'}
              onAddClick={handleNewWorkspace}
            >
              {workspaces.map((workspace) => (
                <HospitalLayoutNameCard
                  key={workspace.id}
                  data={workspace}
                  selected={selection.workspaceId === workspace.id}
                  onClick={() =>
                    handleWorkspaceSelection(
                      selection.workspaceId === workspace.id
                        ? null
                        : workspace.id,
                    )
                  }
                  onNameChange={(name) =>
                    handleWorkspaceNameChange(workspace.id, name)
                  }
                  onRemoveClick={() =>
                    setConfirmState((s) => ({ ...s, workspace }))
                  }
                  onCopyClick={() =>
                    setConfirmState((s) => ({ ...s, copyWorkspace: workspace }))
                  }
                />
              ))}
            </HospitalLayoutColumn>

            <HospitalLayoutColumn
              title={t`Units`}
              active={activeColumn === 'wards'}
              onAddClick={selection.workspaceId ? handleWardCreate : undefined}
            >
              {wards.map((ward) => (
                <HospitalLayoutNameCard
                  key={ward.id}
                  data={ward}
                  selected={selection.wardId === ward.id}
                  onClick={() =>
                    handleWardSelection(
                      selection.wardId === ward.id ? null : ward.id,
                    )
                  }
                  onNameChange={(name) => handleWardNameChange(ward.id, name)}
                  onRemoveClick={() => setConfirmState((s) => ({ ...s, ward }))}
                />
              ))}
            </HospitalLayoutColumn>

            <HospitalLayoutColumn
              title={t`Rooms`}
              active={activeColumn === 'rooms'}
              onAddClick={selection.wardId ? handleRoomCreate : undefined}
              sx={{ minWidth: { xxl: '550px' } }}
            >
              {rooms.map((room) => (
                <HospitalLayoutNameCard
                  key={room.id}
                  data={room}
                  selected={selection.roomId === room.id}
                  onClick={() =>
                    handleRoomSelection(
                      selection.roomId === room.id ? null : room.id,
                    )
                  }
                  onNameChange={(name) => handleRoomNameChange(room.id, name)}
                  onRemoveClick={() => setConfirmState((s) => ({ ...s, room }))}
                />
              ))}
            </HospitalLayoutColumn>
          </>
        )}
        <Flex alignItems="center" marginLeft={-2}>
          <IconButton
            size="large"
            onClick={() => setParkingPlacesExpanded((s) => !s)}
            disabled={activeColumn !== 'parkingPlaces'}
            color="primary"
          >
            <HospitalLayoutPageExpanderIcon
              fontSize="large"
              className={parkingPlacesExpanded ? 'expanded' : ''}
            />
          </IconButton>
        </Flex>
        <HospitalLayoutColumn
          title={t`Parking places`}
          active={activeColumn === 'parkingPlaces'}
          onAddClick={selection.roomId ? handleParkingPlaceCreate : undefined}
          sx={{ minWidth: '400px' }}
          expanded={parkingPlacesExpanded}
        >
          {parkingPlaces.map((parkingPlace) => (
            <HospitalLayoutParkingPlaceCard
              key={parkingPlace.id}
              data={parkingPlace}
              onRemoveClick={async () =>
                setConfirmState((s) => ({ ...s, parkingPlace }))
              }
            />
          ))}
        </HospitalLayoutColumn>
      </Flex>
      <ConfirmModal
        open={!!confirmState.workspace}
        onClose={() => setConfirmState((s) => ({ ...s, workspace: null }))}
        title={t`Delete facility`}
        message={
          <Trans>
            Do you really want to{' '}
            <b>delete Facility {confirmState.workspace?.name}?</b>
          </Trans>
        }
        onConfirm={handleConfirmWorkspaceRemove}
      />
      <ConfirmModal
        open={!!confirmState.ward}
        onClose={() => setConfirmState((s) => ({ ...s, ward: null }))}
        title={t`Delete unit`}
        message={
          <Trans>
            Do you really want to <b>delete Unit {confirmState.ward?.name}?</b>
          </Trans>
        }
        onConfirm={handleConfirmWardRemove}
      />
      <ConfirmModal
        open={!!confirmState.room}
        onClose={() => setConfirmState((s) => ({ ...s, room: null }))}
        title={t`Delete room`}
        message={
          <Trans>
            Do you really want to <b>delete Room {confirmState.room?.name}?</b>
          </Trans>
        }
        onConfirm={handleConfirmRoomRemove}
      />
      <ConfirmModal
        open={!!confirmState.parkingPlace}
        onClose={() => setConfirmState((s) => ({ ...s, parkingPlace: null }))}
        title={t`Delete parking place`}
        message={
          <Trans>
            Do you really want to{' '}
            <b>delete Parking place {confirmState.parkingPlace?.name}?</b>
          </Trans>
        }
        onConfirm={handleConfirmParkingPlaceRemove}
      />
      <ConfirmModal
        open={!!confirmState.copyWorkspace}
        onClose={() => setConfirmState((s) => ({ ...s, copyWorkspace: null }))}
        title={t`Duplicate facility`}
        message={
          <Trans>
            Do you really want to{' '}
            <b>duplicate Facility {confirmState.copyWorkspace?.name}?</b>
          </Trans>
        }
        onConfirm={handleConfirmCopyWorkspace}
      />
    </Flex>
  );
};

export default HospitalLayoutPage;

const HospitalLayoutPageExpanderIcon = styled(ExpandCircleDownIcon)`
  transform: rotate(90deg);
  transition: all 0.25s linear;

  &.expanded {
    transform: rotate(-90deg);
  }
`;
