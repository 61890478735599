import { t } from '@lingui/macro';
import { useState } from 'react';
import { Column, TableOddEven } from 'src/components/TableOddEven';
import ConfigDialog from '../ConfigDialog';

interface Setting {
  id: SettingType;
  label: string;
}

enum SettingType {
  'SafetyPort' = 'safety_port',
}

const columns: Column<Setting>[] = [
  {
    header: t``,
    key: 'id',
    cellProps: {
      sx: {
        fontWeight: 'bold',
      },
    },
    renderValue: (row) => row.label,
  },
];

const OtherSettings = () => {
  const [selectedSetting, setSelectedSetting] = useState<string | null>(null);

  const rowProps = (item: Setting) => ({
    onClick: () =>
      setSelectedSetting(selectedSetting === item.id ? null : item.id),
    selected: item.id === selectedSetting,
    hover: true,
  });

  const renderExpanded = ({ id }: Setting) => {
    if (id === SettingType.SafetyPort) {
      return <ConfigDialog />;
    }
    return null;
  };

  const data = [
    {
      id: SettingType.SafetyPort,
      label: t`SafetyPort`,
    },
  ];
  return (
    <TableOddEven
      rowKey="id"
      renderExpanded={renderExpanded}
      data={data}
      columns={columns}
      rowProps={rowProps}
    />
  );
};

export default OtherSettings;
