import React, { useContext, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ChangeUserSetting,
  ChangeUserSettingVariables,
  FontSize,
  UserSettingInput,
} from 'src/graphql/types';
import { changeUserSetting } from 'src/graphql/mutations';
import { AuthContext } from 'src/auth/AuthProvider';

interface UserSetting {
  svTheme: 'dark' | 'light' | string;
  anonymized: boolean;
  dashboardOrder: DashboardItem[];
  dashboardOrder2: DashboardItem[];
  selectedDashboardOrder: DashboardItem[];
  dashboardPageSize: number;
  dashboardFontSize: FontSize;
  showOwnedBeds: boolean;
  singleLineLayout: boolean;
}

type UserSettingsUpdateInput = Omit<UserSettingInput, 'userId'>;

interface DashboardSettingProviderType {
  userSetting: UserSetting;
  currentDashboardOrder: DashboardOrderType;
  changeUserSettings: (input: UserSettingsUpdateInput) => Promise<void>;
  changeDashboardOrder: (value: string) => void;
}

const defaultUserSetting: UserSetting = {
  anonymized: true,
  dashboardFontSize: FontSize.SMALL,
  dashboardPageSize: 10,
  dashboardOrder: [],
  dashboardOrder2: [],
  selectedDashboardOrder: [],
  showOwnedBeds: false,
  singleLineLayout: false,
  svTheme: 'light',
};

export enum SvDashboardSortingType {
  'powerStatus' = 'powerStatus',
  'location' = 'location',
  'name' = 'name',
  'note' = 'note',
  'bedExitMonitoringStatus' = 'bedExitMonitoringStatus',
  'outOfBed' = 'outOfBed',
  'spAndLock' = 'spAndLock',
  'siderails' = 'siderails',
  'brakes' = 'brakes',
  'lowestPosition' = 'lowestPosition',
  'backrestAndLock' = 'backrestAndLock',
  'trendelenburgAngle' = 'trendelenburgAngle',
  'weight' = 'weight',
  'laterailTiltAndAlt' = 'laterailTiltAndAlt',
  'calfrestAngleAndLock' = 'calfrestAngleAndLock',
  'thighrestLock' = 'thighrestLock',
  'mattressType' = 'mattressType',
  'bedType' = 'bedType',
  'sessionId' = 'sessionId',
  'sessionStart' = 'sessionStart',
  'bedName' = 'bedName',
  'tagCharge' = 'tagCharge',

  'charging' = 'charging',
  'transfer' = 'transfer',
  'heightOfRLegColumn' = 'heightOfRLegColumn',
  'heightOfLLegColumn' = 'heightOfLLegColumn',
  'heightOfHeadColumn' = 'heightOfHeadColumn',
  'mobiliftRight' = 'mobiliftRight',
  'mobiliftLeft' = 'mobiliftLeft',
  'bedExtension' = 'bedExtension',
  'pressedBtnAndUsedController' = 'pressedBtnAndUsedController',
  'rightLCDButtonPressed' = 'rightLCDButtonPressed',
  'leftLCDButtonPressed' = 'leftLCDButtonPressed',
  'legsControllerButtonPressed' = 'legsControllerButtonPressed',
  'acpAndRoControllerButtonPressed' = 'acpAndRoControllerButtonPressed',
  'digitalSiderailRightButtonPressed' = 'digitalSiderailRightButtonPressed',
  'digitalSiderailLeftButtonPressed' = 'digitalSiderailLeftButtonPressed',
  'ALTError' = 'ALTError',
  'errorOrStopCode' = 'errorOrStopCode',
  'batteryFailure' = 'batteryFailure',
}

export type OrderAlign = 'left' | 'center' | 'right' | undefined;

export interface SvDashboardSortingDataType {
  type: SvDashboardSortingType;
  width: number;
  padding?: string;
  align?: 'left' | 'center' | 'right';
}

export interface SvDashboardSortChoice extends SvDashboardSortingDataType {
  show: boolean;
}

interface Props {
  children?: React.ReactNode;
}

const CURRENT_DASHBOARD_ORDER_KEY = 'CURRENT_DASHBOARD_ORDER_KEY';

const getSessionStorageOrderValue = () => {
  const item = sessionStorage.getItem(CURRENT_DASHBOARD_ORDER_KEY);
  return item === 'dashboardOrder2' ? 'dashboardOrder2' : 'dashboardOrder';
};

const setSessionStorageOrderValue = (value: DashboardOrderType) => {
  sessionStorage.setItem(CURRENT_DASHBOARD_ORDER_KEY, value);
};

const getAdditinalData = (type: SvDashboardSortingType) => {
  if (
    type === SvDashboardSortingType.powerStatus ||
    type === SvDashboardSortingType.brakes ||
    type === SvDashboardSortingType.lowestPosition ||
    type === SvDashboardSortingType.spAndLock
  ) {
    return {
      width: 60,
    };
  }
  if (
    type === SvDashboardSortingType.batteryFailure ||
    type === SvDashboardSortingType.charging
  ) {
    return {
      width: 80,
    };
  }
  if (
    type === SvDashboardSortingType.backrestAndLock ||
    type === SvDashboardSortingType.calfrestAngleAndLock ||
    type === SvDashboardSortingType.laterailTiltAndAlt ||
    type === SvDashboardSortingType.trendelenburgAngle
  ) {
    return {
      width: 80,
    };
  }
  if (
    type === SvDashboardSortingType.thighrestLock ||
    type === SvDashboardSortingType.siderails
  ) {
    return {
      width: 80,
      padding: '0.5rem',
    };
  }
  if (type === SvDashboardSortingType.outOfBed) {
    return {
      width: 100,
      padding: '0.5rem',
    };
  }
  if (type === SvDashboardSortingType.note) {
    return {
      width: 100,
      align: 'left' as OrderAlign,
    };
  }
  if (type === SvDashboardSortingType.location) {
    return {
      width: 150,
      align: 'left' as OrderAlign,
    };
  }
  if (type === SvDashboardSortingType.name) {
    return {
      width: 120,
      align: 'left' as OrderAlign,
    };
  }
  return {
    width: 110,
  };
};

const EXCLUDED_DATAPOINTS = [
  SvDashboardSortingType.location,
  SvDashboardSortingType.name,
];

export interface Choices {
  type: SvDashboardSortingType;
  show: boolean;
  width: number;
  align?: OrderAlign;
  padding?: string;
}

export const getChoicesFromOrder = (order: string[]): Choices[] => {
  return Object.values(SvDashboardSortingType)
    .filter((type) => EXCLUDED_DATAPOINTS.indexOf(type) === -1)
    .map((type) => {
      return {
        type,
        show: order.indexOf(type) > -1,
        ...getAdditinalData(type),
      };
    });
};

export interface DashboardItem {
  width: number;
  padding?: string;
  align?: OrderAlign;
  type: any;
}

const getSvDashboardOrder = (input: string[]): DashboardItem[] => {
  return input
    .filter(
      (type) =>
        EXCLUDED_DATAPOINTS.indexOf(SvDashboardSortingType[type]) === -1,
    )
    .map((code) => ({
      type: SvDashboardSortingType[code],
      ...getAdditinalData(SvDashboardSortingType[code]),
    }));
};

export const DashboardSettingContext =
  React.createContext<DashboardSettingProviderType>({
    changeUserSettings: async () => {},
    changeDashboardOrder: () => {},
    userSetting: defaultUserSetting,
    currentDashboardOrder: 'dashboardOrder',
  });

export type DashboardOrderType = 'dashboardOrder' | 'dashboardOrder2';

const DashboardSettingProvider = ({ children }: Props) => {
  const { user } = useContext(AuthContext);

  const [currentDashboardOrder, setCurrentDashboardOrder] =
    useState<DashboardOrderType>(getSessionStorageOrderValue());

  const [changeSetting] = useMutation<
    ChangeUserSetting,
    ChangeUserSettingVariables
  >(changeUserSetting);

  const changeDashboardOrder = (value: string) => {
    const newValue =
      value === 'dashboardOrder2' ? 'dashboardOrder2' : 'dashboardOrder';
    setCurrentDashboardOrder(newValue);
    setSessionStorageOrderValue(newValue);
  };

  const changeUserSettings = async (input: UserSettingsUpdateInput) => {
    if (
      input.dashboardOrder2 === '' &&
      currentDashboardOrder === 'dashboardOrder2'
    ) {
      setCurrentDashboardOrder('dashboardOrder');
      setSessionStorageOrderValue('dashboardOrder');
    }
    await changeSetting({
      variables: {
        input: {
          userId: user?.id,
          ...input,
        },
      },
    });
  };

  const userSetting = useMemo(() => {
    const selectedDashboardOrder =
      currentDashboardOrder === 'dashboardOrder2' &&
      user?.userSetting?.dashboardOrder2
        ? user?.userSetting?.dashboardOrder2
        : user?.userSetting?.dashboardOrder;
    return {
      anonymized:
        user?.userSetting?.anonymized != null
          ? user.userSetting.anonymized
          : defaultUserSetting.anonymized,
      dashboardOrder: getSvDashboardOrder(
        user?.userSetting?.dashboardOrder || [],
      ),
      dashboardOrder2: getSvDashboardOrder(
        user?.userSetting?.dashboardOrder2 || [],
      ),
      selectedDashboardOrder: getSvDashboardOrder(selectedDashboardOrder || []),
      showOwnedBeds:
        user?.userSetting?.showOwnedBeds != null
          ? user.userSetting.showOwnedBeds
          : defaultUserSetting.showOwnedBeds,
      singleLineLayout:
        user?.userSetting?.singleLineLayout != null
          ? user.userSetting.singleLineLayout
          : defaultUserSetting.singleLineLayout,
      svTheme: user?.userSetting?.svTheme || defaultUserSetting.svTheme,
      dashboardFontSize:
        user?.userSetting?.dashboardFontSize ||
        defaultUserSetting.dashboardFontSize,
      dashboardPageSize:
        user?.userSetting?.dashboardPageSize ||
        defaultUserSetting.dashboardPageSize,
    };
  }, [
    user?.userSetting?.anonymized,
    user?.userSetting?.dashboardFontSize,
    user?.userSetting?.dashboardOrder,
    user?.userSetting?.dashboardOrder2,
    user?.userSetting?.dashboardPageSize,
    user?.userSetting?.showOwnedBeds,
    user?.userSetting?.singleLineLayout,
    user?.userSetting?.svTheme,
    currentDashboardOrder,
  ]);

  const context = {
    changeUserSettings,
    changeDashboardOrder,
    userSetting,
    currentDashboardOrder,
  };

  return (
    <DashboardSettingContext.Provider value={context}>
      {children}
    </DashboardSettingContext.Provider>
  );
};
export default DashboardSettingProvider;
