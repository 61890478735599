import { useContext, useState } from 'react';
import { AppBar, Box, Toolbar, Button, styled } from '@mui/material';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { BlockLabel } from './BlockLabel';
import colors from 'src/colors';
import { Arrow } from './Arrow';
import { demoStates, getPrevState, getNextState } from './bedStates';
import { Messages } from './Messages';
import { Systems } from './Systems';
import { Reporting } from './Reporting';
import ControlPanel from './ControlPanel';
import { useQuery } from '@apollo/client';
import { me, UserRole } from 'src/graphql/types';
import { meQuery } from 'src/graphql/queries';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from 'src/auth/AuthProvider';
import { ReactComponent as LinisTextLogo } from 'src/images/linis-text-logo.svg';

export const DemoScreen = () => {
  const [autoMode, setAutoMode] = useState(true);
  const [currentState, setCurrentState] = useState<string>('base');
  const { logout } = useContext(AuthContext);
  const meData = useQuery<me>(meQuery);
  const isDemoUser =
    meData.data && meData.data.me && meData.data.me.role === UserRole.DEMO;

  const prevState = () => {
    const newState = getPrevState(currentState);
    if (newState) {
      setCurrentState(newState);
    }
  };
  const nextState = () => {
    const newState = getNextState(currentState);
    if (newState && demoStates[newState]) {
      setCurrentState(newState);
    }
  };
  const hasPrevState = () => {
    const prevState = getPrevState(currentState);
    return !!prevState && demoStates[prevState];
  };
  const hasNextState = () => {
    const nextState = getNextState(currentState);
    return !!nextState && demoStates[nextState];
  };

  const changeMode = () => {
    setCurrentState('base');
    setAutoMode((mode) => !mode);
  };
  return (
    <Box>
      <AppBarHeader position="static">
        <Toolbar sx={{ justifyContent: 'space-bewteen' }}>
          <LogoContainer>
            {isDemoUser ? (
              <LinisTextLogo />
            ) : (
              <Link to="/">
                <LinisTextLogo />
              </Link>
            )}
          </LogoContainer>
          <LogoutWrapper>
            <LogoutButton variant="text" onClick={logout}>
              <LogoutIcon />
              <Trans>Log out</Trans>
            </LogoutButton>
          </LogoutWrapper>
        </Toolbar>
      </AppBarHeader>
      <Container>
        <Content sx={{ backgroundColor: '#F5F6FA' }}>
          <ContentTopBar>
            <BlockLabel color={colors.demoRed} text="Linet side" />
            <Switch
              checked={autoMode}
              onChange={changeMode}
              onColor={colors.demoRed}
              offColor={colors.demoRed}
              onHandleColor="#FFFFFF"
              handleDiameter={30}
              uncheckedIcon={
                <SwitchContent
                  sx={{
                    position: 'absolute',
                    right: 0,
                  }}
                >
                  Manual
                </SwitchContent>
              }
              checkedIcon={<SwitchContent>Presentation</SwitchContent>}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={30}
              width={150}
              className="react-switch"
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                visibility: autoMode ? undefined : 'hidden',
              }}
            >
              <Arrow
                type={'left'}
                handleClick={prevState}
                disabled={!hasPrevState()}
              />
              <Arrow
                type={'right'}
                handleClick={nextState}
                disabled={!hasNextState()}
              />
            </Box>
            <Button
              color="primary"
              style={{
                textTransform: 'none',
                visibility: currentState === 'base' ? 'hidden' : undefined,
              }}
              onClick={() => setCurrentState('base')}
            >
              Reset
            </Button>
          </ContentTopBar>
          <Box sx={{ textAlign: 'center', marginTop: '0.75rem' }}>
            {demoStates[currentState].name}
          </Box>
          <Box
            sx={{
              height: '500px',
              marginBottom: '0.75rem',
            }}
          >
            <BedPositionImage
              alt="Bed position"
              src={demoStates[currentState].image.default}
            />
          </Box>
          <ControlPanel
            demoState={currentState}
            setDemoState={setCurrentState}
            disabled={autoMode}
          />
        </Content>
        <Content sx={{ backgroundColor: '#F9F9FC' }}>
          <ContentTopBar>
            <BlockLabel color={colors.demoBlue} text="Hospital Side" />
          </ContentTopBar>
          <Messages demoState={demoStates[currentState]} />
          <Systems demoState={demoStates[currentState]} />
          <Reporting />
        </Content>
      </Container>
    </Box>
  );
};

const SwitchContent = styled(Box)`
  width: 120px;
  line-height: 30px;
  text-align: center;
  color: #fff;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const Content = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 36px;
`;

const ContentTopBar = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const AppBarHeader = styled(AppBar)`
  background-color: #e8e8e8;
  box-shadow: none;
`;

const LogoContainer = styled(Box)`
  display: flex;
  width: 100%;
`;

const LogoutWrapper = styled(Box)`
  background-color: black;
  height: 65px;
  width: 170px;
`;

const BedPositionImage = styled('img')`
  width: 613px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const LogoutButton = styled(Button)(
  ({ theme: { spacing } }) => `
  height: 65px;
  width: 170px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 1.125rem;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};
`,
);
