import { LineChart, Line, Tooltip, YAxis, TooltipProps } from 'recharts';
import {
  graphLenght,
  PatientDataValuesType,
  GraphType,
} from '../../helpers/patientView';
import { styled, useTheme } from '@mui/material';
import { preparedData } from '../../helpers/patientView';
import WeightFormat from 'src/components/WeightFormat';
import { useLingui } from '@lingui/react';
import Flex from 'src/components/Flex';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';

interface Props {
  data: PatientDataValuesType;
  type: GraphType;
  handleClick?: (time: string) => void;
}

const Curve = ({ data, type, handleClick }: Props) => {
  const theme = useTheme();
  return (
    <>
      <LineChart width={graphLenght} height={48} data={preparedData(data)}>
        <Tooltip
          content={(item) => <CustomTooltip item={item} type={type} />}
          itemStyle={{ fontSize: '0.875rem' }}
          contentStyle={{ padding: 0 }}
          labelStyle={{ display: 'none' }}
        />
        {type !== GraphType.weight && (
          <YAxis
            hide
            type="number"
            domain={
              type === GraphType.backrestAngle
                ? [-65, 65]
                : type === GraphType.trendelenburg
                ? [-20, 20]
                : [-30, 30]
            }
          />
        )}
        <Line
          isAnimationActive={false}
          dot={false}
          type="monotone"
          dataKey="value"
          stroke={theme.palette.grey[500]}
          strokeWidth={2}
          activeDot={{
            onClick: (event: any, { payload }: any) => {
              handleClick && handleClick(payload.time);
            },
          }}
        />
      </LineChart>
    </>
  );
};

export default Curve;

interface CustomTooltipProps {
  item: TooltipProps<ValueType, NameType>;
  type: GraphType;
}

const CustomTooltip = ({ item, type }: CustomTooltipProps) => {
  const { i18n } = useLingui();

  const payload = item.payload?.[0]?.payload;
  const timeString = payload?.time;
  const value = payload?.value;
  const time = timeString ? new Date(timeString) : null;
  if (time == null) {
    return null;
  }
  return (
    <Container>
      {type === GraphType.weight ? (
        <WeightFormat weight={parseInt(value)} />
      ) : (
        value
      )}
      {` - `}
      {i18n.date(time, {
        hour: 'numeric',
        minute: 'numeric',
      })}
    </Container>
  );
};

const Container = styled(Flex)`
  border-radius: 4px;
  padding: 4px 8px;
  background-color: ${(props) => props.theme.palette.common.tooltipBg};
  color: ${(props) => props.theme.palette.common.tooltipText};
`;
