import { useState, useEffect, useContext, useMemo } from 'react';
import {
  BedSubscription,
  useThrottleBedData,
} from '../components/BedSubscription';
import { OpenModalType } from '../BedDetailModal/modalTypes';
import { useSafetyViewData } from './useSafetyViewData';
import NewBedTable from './NewBedTable';
import BedDetailModal from '../BedDetailModal/BedDetailModal';
import { useWardData } from '../providers/WardDataProvider';
import { Box } from '@mui/system';
import { BottomBar } from './BottomBar';
import { DashboardSettingContext } from '../Setting/DashboardSettingProvider';
import { mapFontSizeToPx } from 'src/helpers';

const NewSafetyPortDashboard = () => {
  const { bedsData, updateBedsData, throttleIncomingBedData } =
    useThrottleBedData(1000);
  const [openModal, setOpenModal] = useState<OpenModalType>({
    open: false,
    bedId: null,
  });
  const { selectedWard } = useWardData();

  const { page, setPage, wardBeds, wardParkingPlaces, bedIds } =
    useSafetyViewData({
      withBedExitStart: true,
    });

  const { userSetting, changeUserSettings } = useContext(
    DashboardSettingContext,
  );

  useEffect(() => {
    if (wardParkingPlaces) {
      updateBedsData(wardParkingPlaces, wardBeds);
    }
  }, [wardBeds, wardParkingPlaces, updateBedsData]);

  const contentFontSize = useMemo(() => {
    return mapFontSizeToPx[userSetting.dashboardFontSize];
  }, [userSetting.dashboardFontSize]);

  const pageSize = userSetting?.dashboardPageSize
    ? userSetting.dashboardPageSize
    : bedsData.length >= 11
    ? bedsData.length
    : 12;

  const pageCount = Math.floor((bedsData.length - 1) / pageSize) + 1;

  const changePageSize = (pageSize: number) => {
    changeUserSettings({
      dashboardPageSize: pageSize,
    });
  };

  const bedsOnPage = useMemo(() => {
    const pageIdx = Math.max(0, page - 1) * pageSize;
    return bedsData.slice(pageIdx, pageIdx + pageSize);
  }, [bedsData, page, pageSize]);

  return (
    <>
      <BedSubscription
        bedIds={bedIds}
        skip={openModal.open || !bedIds?.length}
        onData={throttleIncomingBedData}
      />

      <Box px={4}>
        <NewBedTable
          bedsData={bedsOnPage}
          contentFontSize={contentFontSize}
          dashboardOrder={userSetting.selectedDashboardOrder}
          setOpenModal={setOpenModal}
        />
        <BottomBar
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          pageSize={pageSize}
          changePageSize={changePageSize}
        />
      </Box>
      {openModal.open && (
        <BedDetailModal
          openModal={openModal}
          setOpen={setOpenModal}
          dashboardSafetyPositionPresetId={selectedWard?.safetyPositionPresetId}
        />
      )}
    </>
  );
};

export default NewSafetyPortDashboard;
