import { Trans } from '@lingui/macro';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, ThemeProvider } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Outlet, useNavigate } from 'react-router-dom';
import TopNavigator from 'src/components/TopNavigator';
import SnackbarProvider from 'src/providers/SnackbarProvider';
import { adminTheme } from 'src/themes';
import DirtyProvider from '../../providers/DirtyProvider';
import AdminBar from './AdminBar';
import Menu from './Menu';
import { cs, fr, de, es, ptBR } from 'date-fns/locale';
import { useLingui } from '@lingui/react';
import { ReactComponent as LinisTextLogo } from 'src/images/linis-text-logo.svg';

const locales = { cs, fr, de, es, 'pt-br': ptBR };

interface Props {
  isAdmin?: boolean;
  demoAccess?: boolean;
  spDashboardAccess?: boolean;
  hl7LogEnabled?: boolean;
  hospitalName: string;
  children?: any;
}

const AdminLayout = ({
  isAdmin,
  demoAccess,
  spDashboardAccess,
  hl7LogEnabled,
  hospitalName,
}: Props) => {
  const navigate = useNavigate();
  const { i18n } = useLingui();
  if (isMobile) {
    return (
      <ThemeProvider theme={adminTheme}>
        <SnackbarProvider>
          <Box>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.grey[500],
                padding: '16px 32px',
              })}
            >
              <LinisTextLogo />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              height: '100vh',
              overflow: 'hidden',
              position: 'relative',
              top: '-55px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {spDashboardAccess ? (
              <Box>
                <Button
                  style={{ marginBottom: 16 }}
                  onClick={() => {
                    navigate('/safety-port/dashboard');
                  }}
                >
                  <Trans>Go to Dashboard</Trans>
                </Button>
              </Box>
            ) : null}
            <Box>
              <Button style={{ marginBottom: 32 }} onClick={() => {}}>
                <Trans>Logout</Trans>
              </Button>
            </Box>
          </Box>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={adminTheme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={locales[i18n.locale]}
      >
        <DirtyProvider>
          <Box
            sx={{
              display: 'flex',
              height: '100vh',
              overflow: 'hidden',
            }}
          >
            <AdminBar isAdmin={isAdmin} hospitalName={hospitalName} />
            <Menu
              isAdmin={isAdmin}
              demoAccess={demoAccess}
              spDashboardAccess={spDashboardAccess}
              hl7LogEnabled={hl7LogEnabled}
            />
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                width: 'calc( 100% - 240px )', //240px from Menu and MenuDrawer
                color: theme.palette.text.primary,
              })}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: '0 8px',
                  height: '64px',
                }}
              />
              <TopNavigator />
              <Outlet />
            </Box>
          </Box>
        </DirtyProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default AdminLayout;
