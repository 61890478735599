import { useState } from 'react';
import { Box } from '@mui/material';
import { OpenModalType } from '../BedDetailModal/modalTypes';
import BedDetailModal from '../BedDetailModal/BedDetailModal';

import NewSessionHistoryTable from './NewSessionHistoryTable';

const NewSessionHistory = () => {
  const [openModal, setOpenModal] = useState<OpenModalType>({
    open: false,
    data: {
      id: null,
      note: null,
      name: null,
      sessionEnd: null,
      fallRisk: null,
      sessionStart: null,
      safetyPositionPresetId: null,
    },
  });

  return (
    <>
      <Box px={4}>
        <NewSessionHistoryTable setOpenModal={setOpenModal} />
      </Box>
      {openModal.open && !!openModal.bedId && (
        <BedDetailModal openModal={openModal} setOpen={setOpenModal} />
      )}
    </>
  );
};

export default NewSessionHistory;
