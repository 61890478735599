import { useState, useEffect, useContext } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  styled,
} from '@mui/material';
import { t } from '@lingui/macro';
import Flex from 'src/components/Flex';
import {
  DashboardItem,
  DashboardSettingContext,
  getChoicesFromOrder,
  SvDashboardSortChoice,
} from './DashboardSettingProvider';
import { getAllBedsHeaderTranslation } from '../helpers/dashboard';
import { ModalHeader } from 'src/components/BaseModal/ModalHeader';
import { ThinTab } from 'src/components/ThinTab';
import { DatapointItem } from './DatapointItem';
import { DatapointOrderingWrapper } from './DatapointOrderingWrapper';
import { DraggableDatapointItem } from './DraggableDatapointItem';
import { infoTexts } from '../components/Icons/datapointInfo';

interface Props {
  open: boolean;
  onClose: () => void;
}

const DatapointSettingsDialog = ({ open, onClose }: Props) => {
  const { userSetting, currentDashboardOrder, changeUserSettings } = useContext(
    DashboardSettingContext,
  );
  const [currentTab, setCurrentTab] = useState<
    'dashboardOrder' | 'dashboardOrder2'
  >(currentDashboardOrder);
  const dashboardOrder =
    currentTab === 'dashboardOrder2'
      ? userSetting.dashboardOrder2
      : userSetting.dashboardOrder;
  const [choices, setChoices] = useState(
    getChoicesFromOrder(dashboardOrder.map((order) => order.type)),
  );
  const [draggableOrder, setDraggableOrder] = useState(dashboardOrder);

  useEffect(() => {
    setDraggableOrder(dashboardOrder);
    setChoices(getChoicesFromOrder(dashboardOrder.map((order) => order.type)));
  }, [dashboardOrder, setDraggableOrder, setChoices, open]);

  const removeDashboardItem = (dashboardItem: DashboardItem) => {
    const choice = choices.find((choice) => choice.type === dashboardItem.type);
    if (choice) {
      changeVisibleStatus(choice);
    }
  };

  const changeVisibleStatus = (choice: SvDashboardSortChoice) => {
    if (choice.show) {
      setDraggableOrder((prev) =>
        prev.filter((order) => order.type !== choice.type),
      );
    } else {
      setDraggableOrder((prev) =>
        prev.concat({
          type: choice.type,
          width: choice.width,
          align: choice.align,
        }),
      );
    }
    setChoices((prev) =>
      prev.map((ch) =>
        ch.type !== choice.type
          ? ch
          : {
              ...ch,
              show: !ch.show,
            },
      ),
    );
  };

  const handleSave = () => {
    const input =
      currentTab === 'dashboardOrder2'
        ? {
            dashboardOrder2: draggableOrder.map((item) => item.type).join(','),
          }
        : {
            dashboardOrder: draggableOrder.map((item) => item.type).join(','),
          };
    changeUserSettings(input);
    onClose();
  };

  const changeTab = (_: any, value: string) => {
    setCurrentTab(
      value === 'dashboardOrder2' ? 'dashboardOrder2' : 'dashboardOrder',
    );
  };

  const selected = choices.filter((choice) => choice.show).length;
  const total = choices.length;

  return (
    <Dialog
      onClose={onClose}
      maxWidth="xl"
      open={open}
      PaperProps={{
        sx: {
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle>
        <ModalHeader
          title={t`DATAPOINTS`}
          sx={{ border: 'none' }}
          onClose={() => onClose()}
        />
      </DialogTitle>
      <DialogContent sx={{ overflow: 'visible', paddingBottom: 0 }}>
        <Flex flexDirection="column" flex={1}>
          <Typography>{t`Select and reorder datapoints`}</Typography>

          <Tabs
            value={currentTab}
            onChange={changeTab}
            aria-label="settings-tabs"
            sx={{
              '& button:first-of-type': {
                marginLeft: '0',
              },
            }}
          >
            <ThinTab
              value="dashboardOrder"
              label={t`View 1`}
              {...a11yProps('dashboardOrder')}
            />
            <ThinTab
              value="dashboardOrder2"
              label={t`View 2`}
              {...a11yProps('dashboardOrder2')}
            />
            <Box
              sx={{
                alignSelf: 'center',
                flex: 1,
                textAlign: 'right',
              }}
            >
              <Typography>{t`Selected ${selected}/${total}`}</Typography>
            </Box>
          </Tabs>
          <DatapointSelection>
            <DatapointColumn
              sx={{
                pr: 1,
                borderRight: (theme) => `2px solid ${theme.palette.grey[400]}`,
              }}
            >
              <DatapointColumnTitleWrapper>
                <DatapointColumnTitle>{t`Available datapoints`}</DatapointColumnTitle>
              </DatapointColumnTitleWrapper>
              <DatapointList>
                {choices
                  .filter((choice) => !choice.show)
                  .sort((choice, otherChoice) =>
                    choice.type.localeCompare(otherChoice.type),
                  )
                  .map((choice) => (
                    <DatapointItem
                      key={choice.type}
                      info={infoTexts[choice.type]}
                      label={getAllBedsHeaderTranslation(choice.type)}
                      add={() => changeVisibleStatus(choice)}
                    />
                  ))}
              </DatapointList>
            </DatapointColumn>
            <DatapointColumn>
              <DatapointColumnTitleWrapper>
                <CheckCircleIcon
                  color="primary"
                  fontSize="small"
                  sx={{ mr: 0.5 }}
                />
                <DatapointColumnTitle>{t`Selected datapoints`}</DatapointColumnTitle>
              </DatapointColumnTitleWrapper>
              <DatapointList>
                <DatapointOrderingWrapper
                  draggableDatapointsOrder={draggableOrder}
                  setDraggableDatapointsOrder={setDraggableOrder}
                >
                  <>
                    {draggableOrder.map((item, index) => (
                      <DraggableDatapointItem
                        id={item.type}
                        key={item.type}
                        index={index}
                        info={infoTexts[item.type]}
                        label={getAllBedsHeaderTranslation(item.type)}
                        remove={() => removeDashboardItem(item)}
                      />
                    ))}
                  </>
                </DatapointOrderingWrapper>
              </DatapointList>
            </DatapointColumn>
          </DatapointSelection>
        </Flex>
      </DialogContent>
      <DialogActions
        sx={{
          minHeight: '50px',
          px: (theme) => theme.spacing(3),
        }}
      >
        <Button variant="outlined" onClick={onClose}>{t`Cancel`}</Button>
        <Button variant="contained" onClick={handleSave}>{t`Save`}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DatapointSettingsDialog;

const a11yProps = (tab: string) => {
  return {
    id: `dashboard-datapoint-tab-${tab}`,
    'aria-controls': `dashboard-datapoint-tabpanel-${tab}`,
  };
};

const DatapointSelection = styled(Flex)(
  ({ theme: { spacing, palette } }) => `
  margin-top: ${spacing(3)};
  border-top: 2px solid ${palette.grey[400]};
  border-bottom: 2px solid ${palette.grey[400]};
`,
);

const DatapointList = styled(Flex)`
  overflow: auto;
  flex-direction: column;
  height: 40vh;
`;

const DatapointColumnTitleWrapper = styled(Flex)(
  ({ theme: { spacing } }) => `
  padding-right: ${spacing(2)};
  padding-left: ${spacing(2)};
  margin-bottom: ${spacing(2)};
  align-items: center;
`,
);

const DatapointColumnTitle = styled(Typography)(
  ({ theme: { palette, spacing } }) => `
  white-space: nowrap;
  text-transform: uppercase;
  color: ${palette.primary.main};
`,
);

const DatapointColumn = styled(Flex)(
  ({ theme: { spacing } }) => `
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(3)};
  flex-direction: column;
`,
);
