import { t, Trans } from '@lingui/macro';
import { Box, Button, Divider, Typography } from '@mui/material';
import { sub } from 'date-fns';
import { useState } from 'react';
import { SafetyPortProps } from 'src/graphql/types';
import { parseDate } from 'src/helpers';
import MobileInsightItem from './MobileInsightItem';
import { useInsight } from 'src/components/Insight/useInsight';
import { Waypoint } from 'react-waypoint';
import { MobileInsightFilter } from './MobileInsightFilter';

interface Props {
  bedId?: string;
  sessionStart?: string | null;
  sessionEnd?: string | null;
}

const MobileInsight = ({ bedId, sessionEnd, sessionStart }: Props) => {
  const initDate = sub(parseDate(sessionEnd) || new Date(), { hours: 4 });
  const [fromDate, setFromDate] = useState(initDate);
  const [dataHistoryFilter, setDataHistoryFilter] = useState<string | null>(
    null,
  );
  const [selectedFilter, setSelectedFilter] = useState<{
    from: Date;
    prop: SafetyPortProps | null;
  }>({
    from: fromDate,
    prop: null,
  });

  const { entries, canLoadMore, loadMore } = useInsight({
    unitId: bedId || '',
    from: selectedFilter.from,
    prop: selectedFilter.prop,
  });

  const search = () => {
    setSelectedFilter({
      from: fromDate,
      prop: dataHistoryFilter ? SafetyPortProps[dataHistoryFilter] : null,
    });
  };

  const reset = () => {
    setFromDate(initDate);
    setDataHistoryFilter(null);
    setSelectedFilter({
      from: initDate,
      prop: null,
    });
  };

  return (
    <>
      <Box
        mb={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="caption">
          <Trans>filter insight</Trans>
        </Typography>
      </Box>
      <Box mb={2}>
        <MobileInsightFilter
          fromDate={fromDate}
          sxDate={{
            mb: 1,
          }}
          setFromDate={setFromDate}
          sessionStart={sessionStart}
          dataHistoryFilter={dataHistoryFilter}
          setDataHistoryFilter={setDataHistoryFilter}
        />
        <Box display="flex" flex={1} mt={2}>
          <Button variant="contained" sx={{ flex: 1, mr: 1 }} onClick={search}>
            {t`Search`}
          </Button>
          <Button variant="outlined" sx={{ flex: 1, ml: 1 }} onClick={reset}>
            {t`Reset`}
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <Typography variant="caption">
          <Trans>insight</Trans>
        </Typography>
        <Box>
          {entries?.insight.data.map((insightItem, index, insightData) => (
            <>
              <MobileInsightItem
                key={`${insightItem.type}-${insightItem.time}`}
                data={insightItem}
              />
              {Math.max(0, insightData.length - 5) === index && canLoadMore ? (
                <Waypoint onEnter={loadMore} />
              ) : null}
            </>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default MobileInsight;
