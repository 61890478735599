import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { useSnackbar } from 'src/providers/SnackbarProvider';

interface Messages {
  errorMessage?: string;
  successMessage?: string;
}

export const useSnackbarMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext>,
  messages?: Messages,
): MutationTuple<TData, TVariables, TContext, TCache> => {
  const { setSnackbar } = useSnackbar();
  return useMutation(mutation, {
    ...options,
    onCompleted: (data) => {
      options?.onCompleted?.(data);
      messages?.successMessage &&
        setSnackbar({ message: messages.successMessage, type: 'success' });
    },
    onError: (error) => {
      options?.onError?.(error);
      setSnackbar({
        message: messages?.errorMessage || error.message,
        type: 'error',
      });
    },
  });
};
