import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { differenceInSeconds } from 'date-fns';
import { indexBy } from 'ramda';
import { useContext, useMemo } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { AuthContext } from 'src/auth/AuthProvider';
import {
  ReportLocationAggregation,
  ReportResultField,
  WeightUnits,
} from 'src/graphql/types';
import { getDatesByPreset } from 'src/lib/datePresetHelper';
import useDateFormat from 'src/lib/useDateFormat';
import { convertToPounds } from 'src/lib/weightConverter';
import {
  FilterParameter,
  ReportData,
  ReportDataValue,
  ReportFilterParams,
} from '../types';
import { getReportTag } from './graphHelpers';
import useGraphColors, { OFFLINE_COLOR } from './useGraphColors';
import useReportTagNames from './useReportTagNames';

interface Props {
  data: ReportData[];
  filter: ReportFilterParams;
}

const HEIGHT = 300;
const CENTER_RADIUS = 30;
const SEPARATOR_WIDTH = 2;

const resolveStateName = (filter: ReportFilterParams) => {
  if (filter.parameter === FilterParameter.ONLINE) {
    return [t`ONLINE`, t`OFFLINE`];
  }
  if (filter.parameter === FilterParameter.OCCUPANCY) {
    return [t`ON BED`, t`OUT OF BED`];
  }
  return [t`ON`, t`OFF`];
};

const durationSum = (duration: number) => (value: ReportDataValue) => {
  return duration * (value.total || 1) - value.value;
};

const durationAvg = (duration: number) => (value: ReportDataValue) => {
  return duration - value.value;
};
const count = (value: ReportDataValue) => {
  return (value.total || 1) - value.value;
};

const ReportGraphPie = ({ data, filter }: Props) => {
  const { formatDuration } = useDateFormat();
  const { getColor } = useGraphColors();
  const { config } = useContext(AuthContext);
  const isImperial = config?.config?.unitSystem === WeightUnits.IMPERIAL;
  const { resolveName } = useReportTagNames();
  const { i18n } = useLingui();
  const duration = useMemo(() => {
    const dates =
      filter.period.preset !== 'custom'
        ? getDatesByPreset(filter.period.preset, i18n)
        : { from: filter.period.from!, to: filter.period.to! };
    return differenceInSeconds(dates.to, dates.from);
  }, [filter, i18n]);
  const tags = indexBy((row) => getReportTag(row.tags) || '', data);

  const tagLength = Object.keys(tags).length;
  const durationType =
    filter.resultField === ReportResultField.DURATION ||
    filter.parameter === FilterParameter.BED_EXIT_REACTION;
  const [onlineState, offlineState] = resolveStateName(filter);

  const getSecondaryValue = durationType
    ? filter.locationAggregation === ReportLocationAggregation.SUM
      ? durationSum(duration)
      : durationAvg(duration)
    : count;

  const getPercentage = (reportDataValue: ReportDataValue) => {
    const primary = reportDataValue.value;
    const secondary = Math.max(getSecondaryValue(reportDataValue), 0);
    const total = primary + secondary;
    if (total <= 0) {
      return {
        primary: 0,
        secondary: 0,
      };
    }
    const primaryPercentage = Math.ceil((primary / total) * 100);
    return {
      primary: primaryPercentage,
      secondary: Math.max(0, 100 - primaryPercentage),
    };
  };
  const graphData = Object.keys(tags)
    .filter((key) => !!key)
    .map((tag) => [
      {
        tag: tag,
        name: `${resolveName(tags[tag].tags)} - ${onlineState}`,
        color: getColor(Object.keys(tags).indexOf(tag)),
        value: tags[tag].values[0].value,
        unit: tags[tag].values[0].unit,
        precentage: getPercentage(tags[tag].values[0]).primary,
      },
      {
        tag: tag,
        name: `${resolveName(tags[tag].tags)} - ${offlineState}`,
        color: OFFLINE_COLOR,
        value: Math.max(getSecondaryValue(tags[tag].values[0]), 0),
        unit: tags[tag].values[0].unit,
        precentage: getPercentage(tags[tag].values[0]).secondary,
      },
    ]);

  const formatValue = (val: string | number, label: string, data: any) => {
    const unit = data.payload.unit;
    const precentage = data.payload.precentage;
    if (unit === 'seconds') {
      return `${formatDuration(Number(val) * 1000)} (${precentage}%)`;
    }
    if (!unit || unit === 'count') {
      return `${val}`;
    }
    if (unit === 'Kg' && isImperial) {
      return `${convertToPounds(val)} lb`;
    }
    return `${val} ${unit}`;
  };

  const availableRadius = HEIGHT / 2 - CENTER_RADIUS;
  const radiusStep = availableRadius / tagLength;

  return (
    <ResponsiveContainer height={HEIGHT} width="100%">
      <PieChart margin={{ left: 50, right: 50 }}>
        <Tooltip
          allowEscapeViewBox={{ x: true }}
          formatter={formatValue}
          wrapperStyle={{ zIndex: 1000 }}
        />
        <Legend
          align="right"
          verticalAlign="top"
          layout="vertical"
          iconType="circle"
          wrapperStyle={{
            paddingLeft: 10,
            maxWidth: '180px',
            maxHeight: '260px',
            overflowY: 'auto',
          }}
          payload={Object.keys(tags).map((tag, idx) => ({
            value: resolveName(tags[tag].tags),
            color: getColor(idx),
          }))}
        />

        {Object.keys(tags).map((tag, idx) => (
          <Pie
            key={tag}
            data={graphData[idx]}
            dataKey="value"
            cx="50%"
            cy="50%"
            name={tag}
            startAngle={180}
            endAngle={-180}
            innerRadius={CENTER_RADIUS + radiusStep * idx}
            outerRadius={
              CENTER_RADIUS + radiusStep * (idx + 1) - SEPARATOR_WIDTH
            }
          >
            {graphData[idx].map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        ))}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ReportGraphPie;
