import { t } from '@lingui/macro';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { Button, ButtonProps, styled } from '@mui/material';
import Flex from './Flex';
import { useNavigate } from 'react-router-dom';

export type ActionButtonType = {
  id: string;
  label: React.ReactNode;
  showWhen?: 'dirty' | 'notDirty' | 'all';
} & ButtonProps;

interface Props {
  isDirty?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  onDiscard?: () => void;
  leftButtons?: ActionButtonType[] | null;
  rightButtons?: ActionButtonType[] | null;
  backButton?: string;
  backButtonVisibility?: 'always' | 'default' | 'none';
  saveButtonLabel?: string;
}

const ActionButtons = ({
  isDirty,
  onSave,
  onDelete,
  onDiscard,
  leftButtons,
  rightButtons,
  saveButtonLabel,
  backButton,
  backButtonVisibility,
}: Props) => {
  const navigate = useNavigate();
  const renderAdditionalButtons = (buttons?: ActionButtonType[] | null) => {
    return buttons?.map((button) => {
      const { label, showWhen = 'all', ...buttonProps } = button;
      if (
        showWhen === 'all' ||
        (showWhen === 'dirty' && isDirty) ||
        (showWhen === 'notDirty' && !isDirty)
      ) {
        return (
          <StyledButton key={button.id} {...buttonProps}>
            {button.label}
          </StyledButton>
        );
      }
      return null;
    });
  };

  return (
    <Flex>
      {((backButton && !isDirty) || backButtonVisibility === 'always') && (
        <StyledButton
          color="primary"
          variant="outlined"
          onClick={() => navigate(-1)}
        >
          {backButton}
        </StyledButton>
      )}
      {renderAdditionalButtons(leftButtons)}
      {onDelete && !isDirty && (
        <StyledButton
          onClick={onDelete}
          variant="outlined"
          color="primary"
          startIcon={<DeleteIcon />}
        >
          {t`Delete`}
        </StyledButton>
      )}
      {onDiscard && isDirty && (
        <StyledButton color="primary" variant="outlined" onClick={onDiscard}>
          {t`Discard`}
        </StyledButton>
      )}
      {onSave && isDirty && (
        <StyledButton
          color="primary"
          variant="contained"
          onClick={onSave}
          startIcon={<SaveIcon />}
        >
          {saveButtonLabel || t`Save changes`}
        </StyledButton>
      )}
      {renderAdditionalButtons(rightButtons)}
    </Flex>
  );
};

export default ActionButtons;

const StyledButton = styled(Button)`
  margin-left: 16px;
  white-space: nowrap;
`;
