import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { indexBy } from 'ramda';
import { useMemo } from 'react';
import {
  parkingPlaceNamesQuery,
  roomNamesQuery,
  wardsQuery,
  workspacesQuery,
} from 'src/graphql/queries';
import {
  ReportTagFragment,
  WardsQuery,
  WorkspacesQuery,
  RoomNamesQuery,
  ParkingPlaceNamesQuery,
} from 'src/graphql/types';

const useReportTagNames = () => {
  const { data: workspacesData } = useQuery<WorkspacesQuery>(workspacesQuery, {
    fetchPolicy: 'cache-first',
  });
  const workspaces = useMemo(
    () => indexBy((w) => w.id, workspacesData?.workspaces || []),
    [workspacesData],
  );
  const { data: wardsData } = useQuery<WardsQuery>(wardsQuery, {
    fetchPolicy: 'cache-first',
  });
  const wards = useMemo(
    () => indexBy((w) => w.id, wardsData?.wards || []),
    [wardsData],
  );
  const { data: roomData } = useQuery<RoomNamesQuery>(roomNamesQuery, {
    fetchPolicy: 'cache-first',
  });
  const rooms = useMemo(
    () => indexBy((r) => r.id, roomData?.rooms || []),
    [roomData],
  );
  const { data: parkingPlaceData } = useQuery<ParkingPlaceNamesQuery>(
    parkingPlaceNamesQuery,
    {
      fetchPolicy: 'cache-first',
    },
  );
  const parkingPlaces = useMemo(
    () => indexBy((pp) => pp.id, parkingPlaceData?.parkingPlaces || []),
    [parkingPlaceData],
  );
  const resolveName = (tags: ReportTagFragment) => {
    if (hasUnassigned(tags)) {
      return t`Unassigned`;
    }
    if (hasUnknown(tags)) {
      return t`Unknown`;
    }
    if (tags.workspaceId) {
      const workspace = workspaces[tags.workspaceId];
      return workspace?.name || t`Unknown`;
    }
    if (tags.wardId) {
      const ward = wards[tags.wardId];
      return ward?.name || t`Unknown`;
    }
    if (tags.parkingPlaceId) {
      const parkingPlace = parkingPlaces[tags.parkingPlaceId];
      return parkingPlace?.name || parkingPlace?.room?.name || t`Unknown`;
    }
    if (tags.roomId) {
      const room = rooms[tags.roomId];
      return room?.name || t`Unknown`;
    }
    if (tags.shift) {
      return tags.shift;
    }
    if (tags.type) {
      return tags.type;
    }
    if (tags.custom) {
      return tags.custom;
    }
    return tags.unitId || t`Unknown`;
  };
  return {
    resolveName,
  };
};

export default useReportTagNames;

export const hasNotFoundError = (tags?: ReportTagFragment) => {
  return tags && Object.values(tags).some((val) => val === 'notFound');
};

export const hasUnassigned = (tags?: ReportTagFragment) => {
  return tags && Object.values(tags).some((val) => val === 'unassigned');
};

export const hasUnknown = (tags?: ReportTagFragment) => {
  return tags && Object.values(tags).some((val) => val === 'unknown');
};
