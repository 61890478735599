import React, { useEffect } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { add, differenceInMinutes, sub, startOfDay, endOfDay } from 'date-fns';
import { graphLenght, graphView } from '../../helpers/patientView';
import { parseDate } from 'src/helpers';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const getTimeline = (isDate: boolean) => {
  const date = isDate ? startOfDay(new Date()) : new Date();
  const timelineArray = new Array(25).fill(0);
  return timelineArray.map((item, index) => {
    if (index === 0) {
      return date;
    }
    return add(date, { hours: index });
  });
};

interface Props {
  date: Date | null;
  setVisibleTimeline: React.Dispatch<React.SetStateAction<number>>;
  visibleTimeline: number;
  sessionStart?: string | null;
  currentTime: Date;
}

const Timeline = ({
  date,
  setVisibleTimeline,
  visibleTimeline,
  sessionStart,
  currentTime,
}: Props) => {
  const timelineValues = getTimeline(!!date);
  const { i18n } = useLingui();

  useEffect(() => {
    const element = document.getElementById('timeline');
    const view = graphLenght / 24;
    if (!!element) element.scrollLeft = visibleTimeline * view;
  }, [visibleTimeline]);

  const moveLeft = () => {
    const sessionStartTime = parseDate(sessionStart);
    if (sessionStartTime) {
      const selectedDate = date ? endOfDay(date) : currentTime;
      const diff = differenceInMinutes(
        sub(selectedDate, { minutes: 13 - visibleTimeline }),
        sessionStartTime,
      );
      if (diff > 720) {
        if (visibleTimeline > 0) setVisibleTimeline(visibleTimeline - 1);
      }
    }
  };

  const moveRight = () => {
    if (visibleTimeline < 12) setVisibleTimeline(visibleTimeline + 1);
  };
  return (
    <Box>
      <IconButton
        onClick={moveLeft}
        sx={{
          position: 'absolute',
          left: -35,
          top: 0,
          width: 40,
          display: 'flex',
          alignItems: 'center',
        }}
        size="small"
      >
        <KeyboardArrowLeftIcon color="primary" />
      </IconButton>
      <Box
        id="timeline"
        width={graphView + graphLenght / 25}
        sx={{ overflowX: 'hidden' }}
      >
        <Container>
          {timelineValues.map((timelineDate, index) => (
            <TimelineBox key={`timeline-item-${timelineDate.valueOf()}`}>
              {date == null && timelineValues.length - 1 === index ? (
                <Trans>now</Trans>
              ) : timelineDate ? (
                i18n.date(timelineDate, {
                  hour: 'numeric',
                  minute: 'numeric',
                })
              ) : null}
            </TimelineBox>
          ))}
        </Container>
      </Box>
      <IconButton
        onClick={moveRight}
        sx={{
          position: 'absolute',
          right: -40,
          top: 0,
          width: 40,
          display: 'flex',
          alignItems: 'center',
        }}
        size="small"
      >
        <KeyboardArrowRightIcon color="primary" />
      </IconButton>
    </Box>
  );
};

export default Timeline;

const Container = styled(Box)`
  font-size: 0.625rem;
  display: flex;
  justify-content: space-between;
  width: ${graphLenght}px;
  position: relative;
`;

const TimelineBox = styled(Typography)`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  font-size: inherit;
  max-width: ${graphLenght / 24}px;
  min-width: ${graphLenght / 24}px;
  text-align: center;
`;
