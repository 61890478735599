import Backrest from 'src/images/safetyPortPropsIcons/Backrest.svg';
import BedExitAlarm from 'src/images/safetyPortPropsIcons/Bed_exit_alarm.svg';
import BedExitMonitoring from 'src/images/safetyPortPropsIcons/Bed_exit_monitoring.svg';
import BrakesStatus from 'src/images/safetyPortPropsIcons/Brakes_status.svg';
import Height from 'src/images/safetyPortPropsIcons/Height.svg';
import LateralTiltAngle from 'src/images/safetyPortPropsIcons/Lateral_tilt_angle.svg';
import Location from 'src/images/safetyPortPropsIcons/Location.svg';
import PatientOnBed from 'src/images/safetyPortPropsIcons/Patient_on_bed.svg';
import PatientWeight from 'src/images/safetyPortPropsIcons/Patient_weight.svg';
import SafetyPosition from 'src/images/safetyPortPropsIcons/Safety_position.svg';
import SiderailsPosition from 'src/images/safetyPortPropsIcons/Siderails_position.svg';
import TrendelenburgAngle from 'src/images/safetyPortPropsIcons/Trendelenburg_angle.svg';
import { useLingui } from '@lingui/react';

interface Props {
  unitId: string;
  msgId: string;
  prop: string;
  value: string;
  date: Date;
}
const propsImage = {
  LINIS0001: PatientWeight,
  LINIS0002: SafetyPosition,
  LINIS0003: TrendelenburgAngle,
  LINIS0004: LateralTiltAngle,
  LINIS0005: SiderailsPosition,
  LINIS0006: Backrest,
  LINIS0007: Backrest,
  LINIS0008: BrakesStatus,
  LINIS0009: BedExitMonitoring,
  LINIS0010: BedExitAlarm,
  LINIS0011: Height,
  LINIS0012: PatientOnBed,
  LINIS0013: Location,
  LINIS0014: SiderailsPosition,
  LINIS0015: SiderailsPosition,
  LINIS0016: SiderailsPosition,
  LINIS0017: SiderailsPosition,
  LINIS0018: Location,
  LINIS0019: Location,
};

export const DataItem = ({ unitId, msgId, prop, value, date }: Props) => {
  const { i18n } = useLingui();

  return (
    <div style={{ ...styles.row }}>
      <span style={styles.column}>{unitId}</span>
      <span style={styles.column}>{msgId}</span>
      <span style={styles.column}>
        <img
          alt=""
          src={propsImage[prop]}
          style={{ marginRight: 4, height: 14 }}
        />
        {prop}
      </span>
      <span style={{ ...styles.column, width: 140 }}>{value}</span>
      <span style={{ ...styles.column, width: 180 }}>
        {date
          ? i18n.date(date, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })
          : null}
      </span>
    </div>
  );
};

const styles = {
  row: {
    padding: '0 4px',
    margin: '4px 0',
    border: 'black solid 1px',
    borderRadius: 4,
  },
  column: {
    display: 'inline-block' as 'inline-block',
    width: 100,
    overflow: 'hidden' as 'hidden',
    textOverflow: 'ellipsis' as 'ellipsis',
    whiteSpace: 'nowrap' as 'nowrap',
  },
};
