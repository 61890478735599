import { styled, Typography, TypographyProps } from '@mui/material';

interface Props {
  label: React.ReactNode;
  value?: string | null;
  placeholder?: string;
  labelSx?: TypographyProps['sx'];
  valueSx?: TypographyProps['sx'];
}

const ValueInfo = ({ value, label, placeholder, labelSx, valueSx }: Props) => {
  return (
    <>
      <Label color="textSecondary" sx={labelSx}>
        {label}
      </Label>
      <Value sx={valueSx}>{value || placeholder}</Value>
    </>
  );
};

export default ValueInfo;

const Label = styled(Typography)`
  font-size: 0.8rem;
  text-transform: uppercase; ;
`;

const Value = styled(Typography)`
  font-weight: 500;
  font-size: 0.775rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 40px;
  margin-top: 0.25rem;
`;
