import { Box, Button, Dialog, Typography } from '@mui/material';
import { t, Trans } from '@lingui/macro';

interface Props {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const DisabledDialog = ({ isOpen, setOpen }: Props) => {
  return (
    <Dialog open={isOpen} onClose={() => setOpen(false)}>
      <Box p={4}>
        <Typography>
          {t`You do not have access to this module. To activate it, contact your reseller.`}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            <Trans>Close</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DisabledDialog;
