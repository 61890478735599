import { SxProps } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { DatapointInfo } from '../components/Icons/datapointInfo';
import { DatapointItem } from './DatapointItem';

interface Props {
  id: string;
  index: number;
  label: string | null;
  add?: () => void;
  remove?: () => void;
  info?: DatapointInfo;
  sx?: SxProps;
}

export const DraggableDatapointItem = ({
  id,
  index,
  label,
  add,
  remove,
  info,
  sx,
}: Props) => {
  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <DatapointItem
            draggableProps={draggableProvided.dragHandleProps}
            label={label}
            add={add}
            remove={remove}
            info={info}
            sx={sx}
          />
        </div>
      )}
    </Draggable>
  );
};
