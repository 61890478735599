import { t } from '@lingui/macro';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { format } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import { FormikContextType } from 'formik';
import { DataSimulationForm } from './DataSimulation';
import { CircularButtonContainer } from '../components/CircularButtonContainer';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { SimulationPresetQuery_simulationPresets } from 'src/graphql/types';

interface Props {
  formik: FormikContextType<DataSimulationForm>;
  preset?: SimulationPresetQuery_simulationPresets | null;
  selectPreset: (id: string) => void;
  deletePreset: (id: string) => void;
  presetOptions: { id: string; name: string }[];
  clearAll: () => void;
}

export const BasicData = ({
  clearAll,
  presetOptions,
  preset,
  selectPreset,
  deletePreset,
  formik,
}: Props) => {
  return (
    <Box display="flex" flex={1} gap={4} alignItems="start">
      <Box sx={{ minWidth: '200px' }}>
        <FormikTextField
          name="unitId"
          label={t`IM ID`}
          variant="outlined"
          disabled
        />
      </Box>
      <Box sx={{ minWidth: '200px' }}>
        <FormikTextField
          name="dateFrom"
          type="date"
          fullWidth
          variant="outlined"
          label={t`Date from`}
          InputProps={{
            inputProps: {
              max: format(new Date(), 'yyyy-MM-dd'),
            },
          }}
          onBlur={() => formik.setFieldTouched('dateFrom', true)}
        />
      </Box>
      <Box sx={{ minWidth: '200px' }}>
        <FormikTextField
          name="dateTo"
          type="date"
          fullWidth
          variant="outlined"
          label={t`Date to`}
          InputProps={{
            inputProps: {
              max: format(new Date(), 'yyyy-MM-dd'),
            },
          }}
          onBlur={() => formik.setFieldTouched('dateTo', true)}
        />
      </Box>
      <Box sx={{ minWidth: '200px' }}>
        <Autocomplete
          id="preset-select"
          options={presetOptions}
          value={preset ? { id: preset.id, name: preset.name } : null}
          getOptionLabel={(val) => val.name}
          onChange={(_, value) => {
            selectPreset(value?.id || '');
          }}
          renderOption={(props, option) => {
            return (
              <Box
                component="li"
                {...props}
                key={option.id}
                sx={{ wordBreak: 'break-word' }}
              >
                {option.name}
              </Box>
            );
          }}
          sx={{
            '&&& .MuiInputLabel-root': {
              marginBottom: '0.25rem',
            },
          }}
          renderInput={(params) => (
            <TextField variant="outlined" {...params} label={t`Data preset`} />
          )}
        />
      </Box>
      {preset ? (
        <Box sx={{ display: 'flex', alignItems: 'end', height: '80px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '56px' }}>
            <CircularButtonContainer>
              <IconButton
                color="primary"
                size="small"
                onClick={() => deletePreset(preset.id)}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </CircularButtonContainer>
          </Box>
        </Box>
      ) : null}
      <Box
        display="flex"
        alignItems="end"
        flex={1}
        justifyContent="end"
        height="56px"
      >
        <Button
          variant="outlined"
          onClick={() => clearAll()}
          startIcon={<ClearIcon />}
          disabled={!formik.values.dateFrom || !formik.values.dateTo}
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {t`Clear generated data all`}
        </Button>
      </Box>
    </Box>
  );
};
