import { DemoState } from './bedStates';

interface Props {
  demoState: DemoState;
}

export const Messages = ({ demoState }: Props) => {
  return (
    <div style={styles.container}>
      <div style={styles.title}>HL7 Message Transmitted to Endpoint</div>
      <div style={styles.box}>
        {demoState.messages.map((message) => (
          <div key={message}>{message}</div>
        ))}
      </div>
      <div style={styles.title}>Translation of HL7 Message</div>
      <div style={styles.box}>
        {demoState.legend.map((legend) => (
          <div key={legend}>{legend}</div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: 12,
  },
  title: {
    padding: '12px 0',
  },
  box: {
    backgroundColor: '#fff',
    borderRadius: 4,
    fontSize: 14,
    padding: 12,
    overflow: 'scroll' as 'scroll',
    height: 64,
  },
};
