import { Divider, styled } from '@mui/material';
import {
  deleteUserMutation,
  deleteUserMutationVariables,
  me_me_assignedWards,
  UserQuery_user_assignedWards,
  UserRole,
  WardsQuery,
} from 'src/graphql/types';
import { t, Trans } from '@lingui/macro';
import {
  ListPageContent,
  ListPageRoot,
  TopBar,
} from 'src/components/AdminList';
import PasswordModal from './PasswordModal';
import { useContext, useState } from 'react';
import { useDirtyFormik } from 'src/hooks';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import { usersQuery, wardsQuery } from 'src/graphql/queries';
import UserForm, { UserFormFeatures } from './UserForm';
import { AnyObjectSchema } from 'yup';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import { deleteUser } from 'src/graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { AuthContext } from 'src/auth/AuthProvider';
import ActionButtons from 'src/components/ActionButtons';

interface AutocompleteSelectValue {
  label: string;
  value: string;
}

interface Props<T> {
  userId?: string;
  features?: UserFormFeatures;
  onSubmit: (values: T) => Promise<void>;
  initialValues: T;
  userWards?: me_me_assignedWards[] | UserQuery_user_assignedWards[] | null;
  validationSchema?: AnyObjectSchema;
  resetOnSubmit?: boolean;
  backButton?: string;
}

const UserDetail = <
  T extends {
    wardIds: AutocompleteSelectValue[];
    id?: string;
    workspaceId?: string;
    role: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    repeatPassword?: string;
  },
>({
  features,
  onSubmit,
  resetOnSubmit = false,
  initialValues,
  userWards,
  validationSchema,
  backButton,
}: Props<T>) => {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { user } = useContext(AuthContext);
  const { data: wardsData } = useQuery<WardsQuery>(wardsQuery);
  const allWards = wardsData?.wards || [];
  const navigate = useNavigate();
  const selectedWorkspaceId =
    (userWards?.length ? userWards : allWards)[0]?.workspace.id || '';

  const [removeUser] = useSnackbarMutation<
    deleteUserMutation,
    deleteUserMutationVariables
  >(deleteUser, undefined, {
    successMessage: t`User was removed.`,
  });

  const handleSubmit = async (values: T) => {
    try {
      await onSubmit(values);
      resetOnSubmit && formik.resetForm();
    } catch (e) {}
  };
  const formik = useFormik<T>({
    initialValues: {
      workspaceId: selectedWorkspaceId,
      ...initialValues,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema,
  });

  const { id: userId, firstName, lastName } = formik.values;

  const handleRemoveUser = () => {
    if (userId) {
      removeUser({
        variables: {
          userId,
        },
        refetchQueries: () => [{ query: usersQuery }],
        onCompleted: () => {
          // setTimeout is neccessary here for setting dirty=false on formik, so we can leave page
          setTimeout(() => navigate('/users'), 300);
        },
      });
    }
  };

  const isEdittingUserRegular = user?.role === UserRole.REGULAR;
  const isSamePerson = user?.id === userId;
  const lowAuthority = isEdittingUserRegular || isSamePerson;
  const userRole = initialValues.role as UserRole;
  useDirtyFormik(formik);
  return (
    <ListPageRoot pb={4}>
      <TopBar>
        <ActionButtons
          onSave={formik.submitForm}
          onDiscard={formik.resetForm}
          backButton={backButton}
          isDirty={formik.dirty}
          saveButtonLabel={userId === '' ? t`Create user` : undefined}
          onDelete={
            features?.userDelete ? () => setShowDeleteModal(true) : undefined
          }
        />
      </TopBar>
      <ListPageContent>
        <StyledDivider />
        <UserForm<T>
          formik={formik}
          features={features}
          lowAuthority={lowAuthority}
          openSetPasswordModal={() => setShowPasswordModal(true)}
        />
      </ListPageContent>
      {userId && userRole && (
        <PasswordModal
          lowAuthority={lowAuthority}
          open={showPasswordModal}
          onClose={() => setShowPasswordModal(false)}
          userId={userId}
          userRole={userRole}
        />
      )}
      {features?.userDelete && (
        <ConfirmModal
          title={t`Delete User`}
          message={
            <Trans>
              Do you really want to{' '}
              <b>
                delete User {firstName} {lastName}?
              </b>
            </Trans>
          }
          confirmButtonText={t`Yes, I want to delete`}
          cancelButtonText={t`Cancel`}
          onConfirm={handleRemoveUser}
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </ListPageRoot>
  );
};

export default UserDetail;

const StyledDivider = styled(Divider)`
  margin: 24px 0 32px 0;
`;
