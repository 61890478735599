import { TableRow, TableCell, Box, useTheme } from '@mui/material';
import { InsightQuery_insight_data, SafetyPortProps } from 'src/graphql/types';
import { propTranslationTabel } from 'src/helpers';
import { useLingui } from '@lingui/react';
import { PropsIcons } from 'src/components/PropsIcon';
import WeightFormat from 'src/components/WeightFormat';

interface Props {
  data: InsightQuery_insight_data;
}

const InsightItem = ({ data }: Props) => {
  const theme = useTheme();
  const { i18n } = useLingui();

  return (
    <TableRow>
      <TableCell>
        {data.time
          ? i18n.date(new Date(data.time), {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })
          : null}
      </TableCell>
      <TableCell>
        {data.time
          ? i18n.date(new Date(data.time), {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })
          : null}
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          {data.type && (
            <Box display="flex" alignItems="center" sx={{ marginRight: '8px' }}>
              <PropsIcons
                prop={data.type as SafetyPortProps}
                color={theme.palette.text.primary}
                size="1.6rem"
              />
            </Box>
          )}
          {propTranslationTabel(data.type)}
        </Box>
      </TableCell>
      <TableCell>
        {data.type === 'LINIS0001' ? (
          <WeightFormat weight={parseInt(data.value)} />
        ) : (
          data.value
        )}
      </TableCell>
    </TableRow>
  );
};

export default InsightItem;
