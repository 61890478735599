import { Box, IconButton, Typography } from '@mui/material';
import { add, format, startOfDay, sub } from 'date-fns';
import { getWeekDay } from 'src/helpers';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { mobilizationViewWidth } from '../../helpers/mobilization';
interface Props {
  dates: Date[];
  date: Date;
  minDate: Date;
  maxDate: Date;
  setDate: (value: Date) => void;
}

const MobilizationDateLine = ({
  dates,
  minDate,
  maxDate,
  date,
  setDate,
}: Props) => {
  const leftArrowHandler = () => {
    const startOfView = sub(date, { days: 1 });
    if (startOfDay(startOfView) >= startOfDay(minDate)) {
      setDate(sub(date, { days: 1 }));
    }
  };

  const rightArrowHandler = () => {
    const endOfView = add(date, { days: 1 });
    if (endOfView <= maxDate) {
      setDate(add(date, { days: 1 }));
    }
  };
  return (
    <Box mt={1} mb={0.5} display="flex" alignItems="center" position="relative">
      <IconButton
        onClick={leftArrowHandler}
        sx={{
          position: 'absolute',
          left: '-11px',
          width: '40px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <KeyboardArrowLeftIcon color="primary" />
      </IconButton>
      <Box
        display="flex"
        width={mobilizationViewWidth}
        marginLeft="62px"
        alignItems="center"
        height="40px"
      >
        {dates.map((date) => (
          <Typography
            key={`mobilization-date-line-${date}`}
            sx={{ fontSize: '0.625rem', flex: 1, textAlign: 'center' }}
            color="textSecondary"
          >
            {`${getWeekDay(date)} `}
            {format(date, 'MM/dd/yyyy')}
          </Typography>
        ))}
      </Box>
      <IconButton
        onClick={rightArrowHandler}
        sx={{
          width: '40px',
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          right: '-15px',
        }}
      >
        <KeyboardArrowRightIcon color="primary" />
      </IconButton>
    </Box>
  );
};

export default MobilizationDateLine;
