import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { BedData, BedWithHighlight } from '../../helpers/dashboard';
import { notEmpty } from 'src/lib/notEmpty';
import {
  BedSubscription,
  useThrottleBedData,
  updateHighlightedBeds,
  sortBedsWithHighlight,
} from '../../components/BedSubscription';
import { useSafetyViewData } from '../../Dashboard/useSafetyViewData';
import { MobileDashboardContext } from '../Settings/MobilDashboardProvider';
import { MobileBedlist } from './MobileBedList';
import { Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { MobileModalContext } from '../ModalOverlay/MobileModalProvider';
import Flex from 'src/components/Flex';
import { getShortTitle } from './MobileBed/MobileBedHeader';

export const MobileDashboardBeds = () => {
  const { searchQuery, pinningEnabled } = useContext(MobileDashboardContext);
  const { modal } = useContext(MobileModalContext);
  const [highlightedBeds, setHighlightedBeds] = useState<BedWithHighlight[]>(
    [],
  );
  const { bedsData, updateBedsData, throttleIncomingBedData } =
    useThrottleBedData(1000);

  const { wardBeds, wardParkingPlaces, bedIds, refetchParkingPlaces, bee } =
    useSafetyViewData({
      withBedExitStart: false,
    });

  useEffect(() => {
    setHighlightedBeds((prevBedsWithHighlight) => {
      const newBeesMap = prevBedsWithHighlight.reduce((res, bed) => {
        if (!bed.unitId) {
          return res;
        }
        return { ...res, [bed.unitId]: bed.startedAt };
      }, {} as Record<string, Date | null>);
      if (bee?.currentBedExitEvents) {
        bee.currentBedExitEvents.forEach((b) => {
          if (!b.unitId) {
            return;
          }
          const newBee = newBeesMap[b.unitId];
          if (!newBee || newBee > b.startedAt) {
            newBeesMap[b.unitId] = b.startedAt;
          }
        });
      }
      const updatedHighlightedBeds = Object.keys(newBeesMap)
        .map((unitId) => {
          const newBee = newBeesMap[unitId];
          if (!newBee) {
            return null;
          }
          const bed = bedsData.find((bed) => bed.unitId === unitId);
          if (!bed) {
            return null;
          }
          return {
            ...bed,
            startedAt: newBee,
          } as BedWithHighlight;
        })
        .filter(notEmpty)
        .sort(sortBedsWithHighlight);
      return updateHighlightedBeds(bedsData, updatedHighlightedBeds);
    });
  }, [bee, setHighlightedBeds, bedsData]);

  useEffect(() => {
    if (wardParkingPlaces) {
      updateBedsData(wardParkingPlaces, wardBeds);
    }
  }, [wardBeds, wardParkingPlaces, updateBedsData]);

  const getBedsWithoutAlarm = (
    bedsData: BedData[],
    highlightedBeds: BedWithHighlight[],
  ) => {
    return bedsData.filter(
      (bed) => !highlightedBeds.find((hb) => bed.unitId === hb.unitId),
    );
  };

  const doRefetchBeds = async () => {
    await refetchParkingPlaces();
  };

  const searchQueryFilter = useCallback(
    <T extends BedData>(bed: T) => {
      return searchQuery
        ? bed.bedName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase().trim()) ||
            bed.location
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase().trim()) ||
            getShortTitle(bed)
              ?.toLowerCase()
              .includes(searchQuery.toLowerCase().trim())
        : true;
    },
    [searchQuery],
  );

  const filteredBedsWithHighlight = useMemo(() => {
    return highlightedBeds.filter(searchQueryFilter);
  }, [highlightedBeds, searchQueryFilter]);

  const bedsWithoutHighlight = useMemo(() => {
    return getBedsWithoutAlarm(bedsData, highlightedBeds).filter(
      searchQueryFilter,
    );
  }, [bedsData, highlightedBeds, searchQueryFilter]);

  const unpinnedBeds = useMemo(() => {
    return bedsWithoutHighlight.filter((bed) => !bed.pinned);
  }, [bedsWithoutHighlight]);

  const pinnedBeds = useMemo(() => {
    return bedsWithoutHighlight.filter((bed) => bed.pinned);
  }, [bedsWithoutHighlight]);

  return (
    <>
      <BedSubscription
        bedIds={bedIds}
        skip={!!modal.type || !bedIds?.length}
        onData={throttleIncomingBedData}
      />
      {pinningEnabled ? (
        <>
          <MobileBedlist
            bedsList={filteredBedsWithHighlight}
            refetchBeds={doRefetchBeds}
          />
          {pinnedBeds.length ? (
            <>
              <Flex justifyContent="center" py={2}>
                <Typography>{t`PINNED`}</Typography>
              </Flex>
              <MobileBedlist
                bedsList={pinnedBeds}
                refetchBeds={doRefetchBeds}
              />
            </>
          ) : null}
          {unpinnedBeds.length ? (
            <>
              <Flex justifyContent="center" py={2}>
                <Typography>{t`UNPINNED`}</Typography>
              </Flex>
              <MobileBedlist
                bedsList={unpinnedBeds}
                refetchBeds={doRefetchBeds}
              />
            </>
          ) : null}
        </>
      ) : (
        <>
          <MobileBedlist
            bedsList={filteredBedsWithHighlight}
            refetchBeds={doRefetchBeds}
          />
          <Flex pt={3} />
          <MobileBedlist
            bedsList={bedsWithoutHighlight}
            refetchBeds={doRefetchBeds}
          />
        </>
      )}
    </>
  );
};
