import { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import {
  AddCareNoteMutation,
  AddCareNoteMutationVariables,
} from 'src/graphql/types';
import { addCareNoteMutation } from 'src/graphql/mutations';
import { patientCareNotes } from 'src/graphql/queries';
import { parseDate, timeIsValid } from 'src/helpers';
import { isMobile } from 'src/lib/mobileCheck';
import DateTimeInput from 'src/components/DateTimeInput';

interface CaraNoteProps {
  time: Date;
  note: string;
}

interface Props {
  patientSessionId: string;
  sessionStart?: string | null;
}

const AddCareNote = ({ patientSessionId, sessionStart }: Props) => {
  const [addNote] = useMutation<
    AddCareNoteMutation,
    AddCareNoteMutationVariables
  >(addCareNoteMutation);
  const [newCareNote, setNewCareNote] = useState<CaraNoteProps>({
    time: new Date(),
    note: '',
  });

  const addNoteHandler = () => {
    addNote({
      variables: {
        sessionId: patientSessionId,
        note: newCareNote.note,
        date: new Date(newCareNote.time).toISOString(),
      },
      refetchQueries: [
        {
          query: patientCareNotes,
          variables: {
            id: patientSessionId,
          },
        },
      ],
    });
    setNewCareNote({ time: new Date(), note: '' });
  };

  const setCareNoteDate = (date: Date) => {
    const currentTime = new Date();
    if (timeIsValid(date)) {
      if (date <= currentTime) {
        setNewCareNote((prevState) => ({ ...prevState, time: date }));
      } else {
        setNewCareNote((prevState) => ({ ...prevState, time: currentTime }));
      }
    }
  };

  return (
    <Box mb={2} display="flex">
      <Box component="form" sx={{ display: 'flex', alignItems: 'end' }}>
        <DateTimeInput
          label={t`Date`}
          value={newCareNote.time}
          max={new Date()}
          min={parseDate(sessionStart)}
          onChange={setCareNoteDate}
          size="small"
          variant="outlined"
        />
        <Box my={isMobile ? 2 : 0} mx={isMobile ? 0 : 2}>
          <TextField
            label={t`Note`}
            id="outlined-basic"
            variant="outlined"
            size="small"
            onChange={({ target }) =>
              setNewCareNote((prevState) => ({
                ...prevState,
                note: target.value,
              }))
            }
            value={newCareNote.note}
          />
        </Box>
        <Button
          fullWidth={isMobile}
          onClick={addNoteHandler}
          variant="contained"
        >
          <Trans>Add care note</Trans>
        </Button>
      </Box>
    </Box>
  );
};

export default AddCareNote;
