const colors = {
  mobileThemeBackground: '#FAFAFA',
  mobileThemeRed: '#FD434B',
  demoRed: '#DD1B25',
  demoBlue: '#2F54E9',
  safetyView: {
    white: '#fff',
    black: '#000',
    blue: '#2163CE',
    darkBlue: '#2F54E9',
    curve: '#979797',
    graphLine: '#979797',
    navigation: '#1E1E1E',
    navigatonItem: '#444444',
    navigationActive: '#B3C9E9',
    barsPrimary: '#767676',
    barsSecondary: '#616161',
    lightBlue: '#A4C2F3',
    green: '#02C303',
    red: '#FF2800',
    red2: '#E91A22',
    dark: '#55565A',
    yellow: '#EBBF61',
    warning: '#FFA026',
    line: '#DAE3F3',
    gray: '#7F7F7F',
    gray1: '#737373',
    gray2: '#363636',
    gray3: '#D4D4D4',
    gray4: '#424242',
    gray5: '#4D4D4D',
    hover: '#d5d5d5',
    lightGray: '#E2E2E2',
    lightGray2: '#FEFEFE',
    darkMode: {
      primary: '#252526',
      secondary: '#3A3A3D',
      dark: '#717073',
      white: '#504D4D',
    },
    mobilization: {
      alt: '#9555C0',
      matress: '#198CFC',
      mobilift: '#F25D21',
      trendelenburg: '#E68483',
      lateral: '#CFA10A',
      backrest30: '#24BCD1',
      backrest45: '#0E808F',
    },
  },
};

export const linetColors = {
  red: '#E10600',
  redDark: '#DB4437',
  greenLight: '#02C303',
  green: '#61D9B5',
  greenDark: '#54CA68',
  greyDarker: '#999999',
  greyDark: '#606060',
  greyTableHeaderDark: '#2B2C34',
  greySecondaryTextDark: '#D4D4D4',
  greyMainDark: '#7F7F7F',
  greyMain: '#888B8D',
  greyLight: '#D0D0CE',
  greyLighter: '#D9D9D9',
  bgGreyLight: '#F1F1F0',
  bgGreyVeryLight: '#FBFBFB',
  bgDarkBackground: '#212228',
  bgGreyDark: '#3A3A3D',
  bgGreyDark2: '#47474B',
  pink: '#FF8C96',
  purple: '#AC4A9C',
  brown: '#A0605A',
  orange: '#FFA026',
  orangeLight: '#FF7A00',
  blue: '#3FACFF',
  blueDark: '#2C78B2',
  white: '#FFFFFF',
  black: '#000000',

  slider: '#E2E2E2',

  mobilization: {
    alt: '#9555C0',
    matress: '#198CFC',
    mobilift: '#F25D21',
    trendelenburg: '#E68483',
    lateral: '#CFA10A',
    backrest30: '#24BCD1',
    backrest45: '#0E808F',
  },
};

export default colors;
