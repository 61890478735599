import { useApolloClient, useQuery } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import React, { PropsWithChildren } from 'react';
import { configQuery, meQuery } from 'src/graphql/queries';
import { ConfigQuery, me, me_me } from 'src/graphql/types';
import { useSnackbar } from 'src/providers/SnackbarProvider';

interface AuthState {
  user?: me_me | null;
  userLoading?: boolean;
  isUnauthenticated?: boolean;
  config?: ConfigQuery | null;
  configLoading?: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

const USER_KEY = '@linet/user';

export const clearAccessToken = () => {
  localStorage.removeItem(USER_KEY);
};

export const getAccessToken = (): string | null => {
  const accessTokenString = localStorage.getItem(USER_KEY);
  if (accessTokenString) {
    const parsedAccessToken = JSON.parse(accessTokenString);
    return parsedAccessToken?.accessToken;
  }
  return null;
};

export const AuthContext = React.createContext<AuthState>({
  login: async () => {},
  logout: async () => {},
});

AuthContext.displayName = 'AuthContext';

const checkUnauthenticatedError = (errors?: GraphQLErrors) => {
  return (
    errors?.some((error) => error?.extensions?.code === 'UNAUTHENTICATED') ||
    false
  );
};

const AuthProvider: React.FC = ({ children }: PropsWithChildren<{}>) => {
  const client = useApolloClient();
  const { setSnackbar } = useSnackbar();
  const { data: configData, loading: configLoading } =
    useQuery<ConfigQuery>(configQuery);
  const { data: meData, loading: meLoading, error } = useQuery<me>(meQuery);

  const unauthenticated = !!checkUnauthenticatedError(error?.graphQLErrors);

  const login = async (username: string, password: string) => {
    const response = await fetch('/api/auth/session', {
      body: JSON.stringify({ username, password }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });
    const userData = await response.json();

    const error = userData?.errors?.[0];
    if (error) {
      setSnackbar({
        type: 'error',
        message: error?.message,
      });
      return;
    }
    localStorage.setItem(
      USER_KEY,
      JSON.stringify({ accessToken: userData.accessToken }),
    );

    client.reFetchObservableQueries();
  };

  const logout = async () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return;
    }
    localStorage.removeItem(USER_KEY);
    sessionStorage.removeItem('notActiavedDialodShowed');

    await fetch('/api/auth/session', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }).catch(() => {
      // We don't care about the result. If this fails, server will have stale session but
      // it's servers bussiness only and client doesn't care
    });
    client.resetStore();
  };

  return (
    <AuthContext.Provider
      value={{
        user: meData?.me,
        userLoading: meLoading,
        isUnauthenticated: unauthenticated,
        config: configData,
        configLoading: configLoading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
