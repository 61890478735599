import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { trends } from 'src/graphql/queries';
import { SessionTrends, SessionTrendsVariables } from 'src/graphql/types';
import { notEmpty } from 'src/lib/notEmpty';
import {
  getExportMobilizationWeekDates,
  getExportSummaryData,
  getMobilizationExportTimelineValues,
  SummaryExportDataType,
} from '../helpers/export';
import {
  DayDataType,
  getMobilizationWeekDates,
  getDaysData,
  selectedDatapointsInitState,
} from '../helpers/mobilization';
import { svgToPng } from '../helpers/svgToPng';

interface Props {
  date: Date;
  sessionId?: string;
}

export interface MobilizationDataType {
  day: string;
  dayData: DayDataType[];
}

export const useMobilizationData = ({ date, sessionId }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const [days, setDays] = useState<Date[]>(getMobilizationWeekDates(date));
  const [mobilizationData, setMobilizationData] = useState<
    MobilizationDataType[]
  >([]);
  const [summaryData, setSummaryData] = useState<
    SummaryExportDataType[][] | null
  >([]);
  const [exportDates, setExportDates] = useState<string[]>(
    getExportMobilizationWeekDates(date),
  );

  const { data } = useQuery<SessionTrends, SessionTrendsVariables>(trends, {
    skip: !sessionId || !days,
    variables: {
      id: sessionId || '',
      timezoneOffset: new Date().getTimezoneOffset(),
      firstDate: days[0],
      secondDate: days[1],
      thirdDate: days[2],
      fourthDate: days[3],
      fifthDate: days[4],
      sixthDate: days[5],
      seventhDate: days[6],
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }
    const sdata = getExportSummaryData(selectedDatapointsInitState, data);
    setSummaryData(sdata);
  }, [data]);

  useEffect(() => {
    setDays(getMobilizationWeekDates(date));
    setExportDates(getExportMobilizationWeekDates(date));
  }, [date]);

  useEffect(() => {
    if (!data) {
      setLoaded(false);
      return;
    }
    const daysData = getDaysData(selectedDatapointsInitState, data);
    setMobilizationData(
      exportDates.map((day, idx) => ({
        day,
        dayData: daysData[idx],
      })),
    );
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, [exportDates, data]);

  const getMobilizationGraphData = useCallback(
    async (imageRefs: React.RefObject<Record<string, any>>) => {
      const timelines = getMobilizationExportTimelineValues();

      const result = await Promise.all(
        mobilizationData.map(async (item) => {
          const imgSource = await svgToPng(
            imageRefs.current && imageRefs.current[item.day].container,
          );
          if (!imgSource) {
            return null;
          }
          return {
            day: item.day,
            img: imgSource,
          };
        }),
      );
      return {
        summaryData: summaryData,
        timeline: timelines,
        days: result.filter(notEmpty),
      };
    },
    [mobilizationData, summaryData],
  );

  return {
    mobilizationData,
    getMobilizationGraphData,
    mobilizationLoaded: loaded,
  };
};
