import { t } from '@lingui/macro';
import {
  Box,
  FormControl,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
} from '@mui/material';
import { FormikErrors } from 'formik';
import FormikSwitch from 'src/components/Formik/FormikSwitch';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  BedExitStatus,
  Position,
  SafetyPortProps,
  ServerConnection,
} from 'src/graphql/types';
import { SiderailIndicator } from './SiderailIndicator';
import { Label } from './ToggleButtonLabel';
import { VirtualBedForm } from './VirtualBedForm';
import { ReactComponent as PersonImg } from 'src/images/person2.svg';
import { PropsIcons } from 'src/components/PropsIcon';

interface Props {
  values: VirtualBedForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<VirtualBedForm>>;
  setFieldTouched: (
    field: string,
    value: boolean,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<VirtualBedForm>>;
}

const WEIGHT_LIMIT = 35;

export const BasicDatapoitns = ({
  values,
  setFieldValue,
  setFieldTouched,
}: Props) => {
  const handleBedExitStatus = (newStatus?: BedExitStatus | null) => {
    if (newStatus) {
      setFieldValue('bedExitStatus', newStatus);
      const weight = Number(values.weight);
      if (newStatus === BedExitStatus.NA) {
        setFieldValue('bedExitAlarm', false);
      } else if (weight < WEIGHT_LIMIT) {
        setFieldValue('bedExitAlarm', true);
      }
    }
  };

  const onBedExitAlarmChanged = (newValue: boolean) => {
    if (newValue && values.bedExitStatus === BedExitStatus.NA) {
      setFieldValue('bedExitStatus', BedExitStatus.INNER);
    }
    const weight = Number(values.weight);
    if (newValue && weight > WEIGHT_LIMIT) {
      setFieldValue('weight', 0);
    } else if (!newValue && weight <= WEIGHT_LIMIT) {
      setFieldValue('weight', 60);
    }
  };

  const onWeightChange = (newValue: string) => {
    const weight = Number(newValue);
    if (weight < WEIGHT_LIMIT && values.bedExitStatus !== BedExitStatus.NA) {
      setFieldValue('bedExitAlarm', true);
    } else {
      setFieldValue('bedExitAlarm', false);
    }
    setFieldTouched('weight', true);
  };

  return (
    <>
      <Box display="flex" minWidth="550px" maxWidth="650px">
        <Grid container columnSpacing={6}>
          <Grid item xs={7}>
            <FormControl sx={{ display: 'flex' }}>
              <Label>
                <LabelWithIcon
                  prop={SafetyPortProps.LINIS0009}
                  label={t`Bed exit monitoring`}
                />
              </Label>
              <ToggleButtonGroup
                color="primary"
                value={values.bedExitStatus}
                exclusive
                onChange={(event, newStatus) => {
                  handleBedExitStatus(newStatus);
                }}
              >
                <ToggleButton
                  sx={{ flex: 1 }}
                  value={BedExitStatus.INNER}
                >{t`Inner`}</ToggleButton>
                <ToggleButton
                  sx={{ flex: 1 }}
                  value={BedExitStatus.OUTER}
                >{t`Outer`}</ToggleButton>
                <ToggleButton
                  sx={{ flex: 1 }}
                  value={BedExitStatus.NA}
                >{t`N/A`}</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Grid>

          <Grid item xs={5}>
            <FormikSwitch
              name="bedExitAlarm"
              label={
                <LabelWithIcon
                  prop={SafetyPortProps.LINIS0010}
                  label={t`Bed exit event`}
                />
              }
              onChanged={onBedExitAlarmChanged}
            />
          </Grid>
          <Grid item xs={7}>
            <FormikTextField
              name="weight"
              fullWidth
              variant="outlined"
              onBlur={(event) => onWeightChange(event.target.value)}
              label={
                <LabelWithIcon
                  prop={SafetyPortProps.LINIS0001}
                  label={t`Patient weight`}
                />
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{t`kg`}</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormikSwitch
              name="brakes"
              label={
                <LabelWithIcon
                  prop={SafetyPortProps.LINIS0008}
                  label={t`Brakes status`}
                />
              }
            />
          </Grid>
          <Grid item xs={7}>
            <FormikTextField
              name="backrestAngle"
              fullWidth
              variant="outlined"
              label={
                <LabelWithIcon
                  prop={SafetyPortProps.LINIS0007}
                  label={t`Backrest angle`}
                />
              }
              onBlur={() => {
                setFieldTouched('backrestAngle', true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{t`°`}</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <FormikSwitch
              name="lowestPosition"
              label={
                <LabelWithIcon
                  prop={SafetyPortProps.LINIS0011}
                  label={t`Lowest position`}
                />
              }
            />
          </Grid>
          <Grid item xs={7}>
            <FormControl sx={{ display: 'flex' }}>
              <Label>
                <LabelWithIcon
                  prop={SafetyPortProps.LINIS0019}
                  label={t`Server connection`}
                />
              </Label>
              <ToggleButtonGroup
                color="primary"
                value={values.serverConnection}
                exclusive
                onChange={(event, newStatus) => {
                  if (newStatus) {
                    setFieldValue('serverConnection', newStatus);
                  }
                }}
              >
                <ToggleButton
                  sx={{ flex: 1 }}
                  value={ServerConnection.ON}
                >{t`Connected`}</ToggleButton>
                <ToggleButton
                  sx={{ flex: 1 }}
                  value={ServerConnection.OFF}
                >{t`Offline`}</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        minWidth={440}
        flexDirection="column"
        alignItems="center"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mb: 2,
          }}
        >
          <FormControl>
            <Label>
              <LabelWithIcon
                prop={SafetyPortProps.LINIS0017}
                label={t`Left head siderail`}
              />
            </Label>
            <ToggleButtonGroup
              color="primary"
              value={values.leftHeadSiderail}
              exclusive
              onChange={(event, newPosition) => {
                if (newPosition) {
                  setFieldValue('leftHeadSiderail', newPosition);
                }
              }}
            >
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.UP}
              >{t`Up`}</ToggleButton>
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.DOWN}
              >{t`Down`}</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          <FormControl>
            <Label>
              <LabelWithIcon
                prop={SafetyPortProps.LINIS0017}
                label={t`Left siderail`}
              />
            </Label>
            <ToggleButtonGroup
              color="primary"
              value={values.leftSiderail}
              exclusive
              onChange={(event, newPosition) => {
                if (newPosition) {
                  setFieldValue('leftSiderail', newPosition);
                }
              }}
            >
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.UP}
              >{t`Up`}</ToggleButton>
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.DOWN}
              >{t`Down`}</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="column" width="382px">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mb={2}
          >
            <SiderailIndicator position={values.leftHeadSiderail} />
            <SiderailIndicator position={values.leftSiderail} />
          </Box>
          <PersonImg />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={2}
          >
            <SiderailIndicator position={values.rightHeadSiderail} />
            <SiderailIndicator position={values.rightSiderail} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mt: 2,
          }}
        >
          <FormControl>
            <Label>
              <LabelWithIcon
                prop={SafetyPortProps.LINIS0017}
                label={t`Right head siderail`}
              />
            </Label>
            <ToggleButtonGroup
              color="primary"
              value={values.rightHeadSiderail}
              exclusive
              onChange={(event, newPosition) => {
                if (newPosition) {
                  setFieldValue('rightHeadSiderail', newPosition);
                }
              }}
            >
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.UP}
              >{t`Up`}</ToggleButton>
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.DOWN}
              >{t`Down`}</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          <FormControl>
            <Label>
              <LabelWithIcon
                prop={SafetyPortProps.LINIS0017}
                label={t`Right siderail`}
              />
            </Label>
            <ToggleButtonGroup
              color="primary"
              value={values.rightSiderail}
              exclusive
              onChange={(event, newPosition) => {
                if (newPosition) {
                  setFieldValue('rightSiderail', newPosition);
                }
              }}
            >
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.UP}
              >{t`Up`}</ToggleButton>
              <ToggleButton
                sx={{ minWidth: '90px' }}
                value={Position.DOWN}
              >{t`Down`}</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

const LabelWithIcon = ({
  prop,
  label,
}: {
  prop: SafetyPortProps;
  label: string;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <PropsIcons prop={prop} size="1.25rem" />
      <Box sx={{ ml: 0.5 }} component="span">
        {label}
      </Box>
    </Box>
  );
};
