import { t } from '@lingui/macro';
import { Box } from '@mui/material';
import DateInput from 'src/components/DateInput';
import DateTimeInput from 'src/components/DateTimeInput';
import { ExportDataDialogItemProps } from '../../Export/components/ExportDataItem';
import {
  ExportType,
  getExportTypeText,
  SelectedExportDayType,
  SelectedExportPeriodType,
} from '../../helpers/export';
import { MobileSwitch } from '../components/MobileSwitch';

interface Props extends ExportDataDialogItemProps {}

const MobileExportDataItem = ({
  type,
  data,
  visibleHandler,
  allDay,
  sessionStart,
  sessionEnd,
  changeFromDate,
  setAllDay,
  setEndOfPeriod,
  setStartofPeriod,
}: Props) => {
  return (
    <>
      <MobileSwitch
        value={!!data?.show}
        onChange={visibleHandler}
        label={getExportTypeText(type) || ''}
      />
      {data?.show && (
        <Box mb={2}>
          {allDay ? (
            <DateInput
              variant="outlined"
              fullWidth
              size="small"
              label={type === ExportType.mobilization ? t`Week` : t`All Day`}
              min={sessionStart || new Date()}
              max={sessionEnd || new Date()}
              value={(data as SelectedExportDayType).date || new Date()}
              onChange={setAllDay}
            />
          ) : type === ExportType.insight ? (
            <>
              <Box mb={1}>
                <DateTimeInput
                  label={t`From`}
                  fullWidth
                  min={sessionStart}
                  max={sessionEnd || new Date()}
                  variant="outlined"
                  size="small"
                  value={(data as SelectedExportPeriodType).from || new Date()}
                  onChange={(date) =>
                    changeFromDate(date, sessionEnd || new Date())
                  }
                />
              </Box>

              <DateTimeInput
                label={t`To`}
                variant="outlined"
                size="small"
                fullWidth
                min={(data as SelectedExportPeriodType).from}
                max={(data as SelectedExportPeriodType).to || new Date()}
                value={(data as SelectedExportPeriodType).to || new Date()}
                onChange={setEndOfPeriod}
              />
            </>
          ) : (
            <>
              <Box mb={1}>
                <DateTimeInput
                  label={t`From`}
                  fullWidth
                  variant="outlined"
                  size="small"
                  min={sessionStart || new Date()}
                  max={(data as SelectedExportPeriodType).to || new Date()}
                  value={(data as SelectedExportPeriodType).from || new Date()}
                  onChange={setStartofPeriod}
                />
              </Box>
              <DateTimeInput
                label={t`To`}
                variant="outlined"
                fullWidth
                size="small"
                min={(data as SelectedExportPeriodType).from || new Date()}
                max={sessionEnd || new Date()}
                value={(data as SelectedExportPeriodType).to || new Date()}
                onChange={setEndOfPeriod}
              />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default MobileExportDataItem;
