import { t } from '@lingui/macro';
import {
  Box,
  FormControl,
  TextField,
  styled,
  useTheme,
  Typography,
} from '@mui/material';
import ContentBlock from 'src/components/ContentBlock';
import Flex from 'src/components/Flex';
import SwitchBtn from 'src/components/SwitchBtn';
import { ConnectionDetailFields } from 'src/pages/Admin/GlobalSettings/DataTransferSettings';
import { ConnectionSettingsState } from 'src/pages/Admin/GlobalSettings/globalSettingsHelper';
import InfoBox from 'src/components/InfoBox';
import { DEFAULT_SYNC_PERIOD } from './helpers';

interface Props {
  connectionName?: string;
  connectionSettings: ConnectionSettingsState | null;
  onChange: (type: ConnectionDetailFields, value: any) => void;
}

const ConnectionDetailTabConnectionDetailContent = ({
  connectionSettings,
  onChange,
}: Props) => {
  const theme = useTheme();
  const syncPeriod = connectionSettings?.syncPeriod
    ? connectionSettings?.syncPeriod.toString()
    : null;

  return (
    <StyledBox>
      <Flex mx={-2} flexWrap="wrap" flexDirection="row" pb={3}>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.input}
        >
          <TextField
            fullWidth
            label={t`Connection name`}
            id="outlined-connectionName"
            value={connectionSettings?.name}
            onChange={({ target }) => onChange('name', target.value)}
            margin="normal"
            variant="outlined"
          />
        </FormControl>
        <div className={classes.input} />
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.input}
        >
          <TextField
            fullWidth
            label={t`IP address`}
            id="outlined-ipAddress"
            value={connectionSettings?.ip}
            onChange={({ target }) => onChange('ip', target.value)}
            margin="normal"
            variant="outlined"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.input}
        >
          <TextField
            fullWidth
            label={t`Port`}
            id="outlined-port"
            value={connectionSettings?.port || ''}
            onChange={({ target }) => onChange('port', parseInt(target.value))}
            margin="normal"
            variant="outlined"
          />
        </FormControl>
      </Flex>
      <ContentBlock isBorderTop>
        <Flex mt={2}>
          <Flex width="50%" alignItems="center">
            <FormControl
              fullWidth={true}
              variant="outlined"
              sx={{
                width: '170px',
              }}
            >
              <TextField
                fullWidth
                label={t`Sync period / min`}
                id="outlined-syncPeriod"
                value={syncPeriod}
                defaultValue={DEFAULT_SYNC_PERIOD}
                onChange={({ target }) =>
                  onChange('syncPeriod', parseInt(target.value))
                }
                margin="normal"
                variant="outlined"
              />
            </FormControl>
            <Box px={2} pt={4}>
              <InfoBox
                color={theme.palette.text.secondary}
                text={t`LINIS SafetyPort will send all enabled parameters when CHANGED, or if the last value was sent ${
                  syncPeriod || DEFAULT_SYNC_PERIOD
                } minutes ago`}
              />
            </Box>
          </Flex>
          <Flex width="50%" alignItems="center" ml={3}>
            <SwitchBtn
              id={`sync-mode-${connectionSettings?.id}`}
              state={!!connectionSettings?.synchronous}
              onChange={() =>
                onChange('synchronous', !connectionSettings?.synchronous)
              }
              label={
                <Typography component="span" noWrap>
                  {t`Synchronous Mode`}
                </Typography>
              }
            />
            <Box px={2} pt={4}>
              <InfoBox
                color={theme.palette.text.secondary}
                text={t`You get acknowledgement from 3rd party system of receiving one message before you send next message, thus there is queue of messages growing in the background and data gets delayed.`}
              />
            </Box>
          </Flex>
        </Flex>
      </ContentBlock>
    </StyledBox>
  );
};

export default ConnectionDetailTabConnectionDetailContent;

const PREFIX = 'ConnectionDetailTabConnectionDetailContent';

const classes = {
  input: `${PREFIX}-inputBlock`,
};

const StyledBox = styled(Box)(({ theme: { spacing } }) => {
  return {
    [`& .${classes.input}`]: {
      padding: `0 ${spacing(2)}`,
      width: '50%',
      boxSizing: 'border-box',
    },
  };
});
