import { Box, Typography, SxProps, styled } from '@mui/material';
import Flex from './Flex';
import SaveDiscardButtons from './SaveDiscardButtons';

interface Props {
  title?: React.ReactNode;
  isBorderBottom?: boolean;
  isBorderTop?: boolean;
  headerLine?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  isSaveDisabled?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
}

const ContentBlock = ({
  title,
  isBorderBottom,
  isBorderTop,
  children,
  isSaveDisabled,
  onCancel,
  onSave,
  headerLine,
  sx,
}: Props) => {
  return (
    <Container
      sx={sx}
      bottomBorder={isBorderBottom ? 'visible' : undefined}
      topBorder={isBorderTop ? 'visible' : undefined}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        sx={(theme) => ({
          borderBottom: headerLine
            ? `1px solid ${theme.palette.text.primary}`
            : 'none',
        })}
      >
        {title &&
          (typeof title === 'string' ? (
            <Title>{title ? title : `\u00a0`}</Title>
          ) : (
            title
          ))}
        <SaveDiscardButtons
          disabled={isSaveDisabled}
          onCancel={onCancel}
          onSave={onSave}
        />
      </Flex>
      {children && (
        <Flex flexDirection="column" width="100%">
          {children}
        </Flex>
      )}
    </Container>
  );
};

export default ContentBlock;

interface StyleProps {
  topBorder?: 'visible';
  bottomBorder?: 'visible';
}

const Container = styled(Box)<StyleProps>(
  ({ topBorder, bottomBorder, theme: { palette } }) => `
  width: 100%;
  border-top: ${
    topBorder === 'visible' ? `1px solid ${palette.grey[300]}` : 'none'
  };
  border-bottom: ${
    bottomBorder === 'visible' ? `1px solid ${palette.grey[300]}` : 'none'
  };
`,
);

const Title = styled(Typography)(
  ({ theme: { spacing } }) => `
  margin: ${spacing(2)} 0;
`,
);

Title.defaultProps = {
  variant: 'h3',
};
