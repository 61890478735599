import { useState } from 'react';

const colorPresets = [
  '#2163ce',
  '#e91a22',
  '#f7b321',
  '#509f00',
  '#ff7e21',
  '#1ec1e4',
];

export const OFFLINE_COLOR = '#888';

const useGraphColors = () => {
  const [colors, setColors] = useState(colorPresets);
  const getColor = (idx: number) => {
    if (colors.length > idx && !!colors[idx]) {
      return colors[idx];
    }
    const newColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    setColors((c) => {
      if (c.length > idx) {
        c[idx] = newColor;
        return c;
      } else {
        const newArray = [
          ...c,
          ...new Array<string>(idx + 1 - c.length).fill(''),
        ];
        newArray[idx] = newColor;
        return newArray;
      }
    });
    return newColor;
  };
  return {
    getColor,
  };
};

export default useGraphColors;
