import html2canvas from 'html2canvas';

export const svgToPng = async (container?: {
  children: Element[];
}): Promise<string | null> => {
  if (!container) {
    return null;
  }
  const svg = container.children[0] as SVGSVGElement;
  const height = svg.height.baseVal.value;
  const width = svg.width.baseVal.value;

  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.fillStyle = 'transparent';
      ctx.fillRect(0, 0, width, height);

      const xml = new XMLSerializer().serializeToString(svg);
      const dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
      const img = new Image(width, height);

      img.src = dataUrl;

      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        const imageData = canvas.toDataURL('image/png', 1.0);
        resolve(imageData);
      };

      img.onerror = () => reject();
    } else {
      reject();
    }
  });
};

export const svgToPng2 = async (container: HTMLElement) => {
  return html2canvas(container, {
    logging: false,
    backgroundColor: null,
  }).then((canvas) => {
    return canvas.toDataURL('image/png', 1.0);
  });
};
