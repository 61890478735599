import { FilterValue } from 'src/components/Filters';
import { bedsQuery } from 'src/graphql/types';
import { useQuery } from '@apollo/client';
import { beds } from 'src/graphql/queries';
import { getFilterPropertyNames } from 'src/helpers';

export const INSIGHT_FILTER = 'insightFilter';

export type FilterProps = {
  bedId: FilterValue | null;
  prop: FilterValue | null;
  from: FilterValue | null;
};

export const useInsightFilterOption = () => {
  const { data: bedData } = useQuery<bedsQuery>(beds);
  const propOptions = getFilterPropertyNames().map((prop) => ({
    id: prop.value,
    name: prop.name,
  }));

  return {
    bedIdOptions:
      bedData?.beds.map((bed) => ({ id: bed.unitId, name: bed.unitId })) || [],
    propOptions,
  };
};
