import { forwardRef } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { COLORS, StatisticsDataType } from '../helpers/statistics';

interface Props {
  item: StatisticsDataType;
}

const PrerenderedChart = ({ item }: Props, ref: any) => {
  return (
    <PieChart
      key={`exportChart-${item.type}`}
      ref={ref}
      width={700}
      height={700}
    >
      <Pie
        data={item.values}
        cx="50%"
        startAngle={90}
        endAngle={450}
        cy="50%"
        isAnimationActive={false}
        innerRadius={300}
        outerRadius={350}
        dataKey="value"
      >
        {item.values.map((_, index) => (
          <Cell
            stroke="none"
            key={`cell-${index}`}
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
    </PieChart>
  );
};

export default forwardRef(PrerenderedChart);
