import Flex from '../Flex';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Chip, Typography, SxProps } from '@mui/material';
import { Trans } from '@lingui/macro';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import { FilterValue } from './filters';

const LinkButton = ({ onPress }: { onPress: () => void }) => {
  return (
    <Button sx={{ textTransform: 'none', height: '40px' }} onClick={onPress}>
      <Typography
        ml="8px"
        mr="8px"
        sx={{ textDecoration: 'underline' }}
        component="span"
      >
        <Trans>Clear All</Trans>
      </Typography>
      <ClearIcon />
    </Button>
  );
};

interface Props<T> {
  filters: {
    type: keyof T;
    typeName: string;
    value: FilterValue;
  }[];
  clearAll: () => void;
  clear: (type: keyof T) => void;
  sx?: SxProps;
}

export const ActiveFilters = <T,>({
  filters,
  clearAll,
  clear,
  sx,
}: Props<T>) => {
  if (!filters.length) {
    return null;
  }
  return (
    <Flex
      sx={{
        alignItems: 'center',
        ml: '40px',
        mt: '16px',
        mb: '16px',
        flexWrap: 'wrap',
        ...sx,
      }}
    >
      <Typography display="flex" mr={1} fontWeight={600} alignItems="center">
        <FilterIcon />
        <Trans>Active Filters</Trans>:
      </Typography>
      {filters.map((filter) => (
        <Chip
          key={String(filter.type)}
          label={`${String(filter.typeName)}: ${filter.value.name}`}
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: '16px',
            marginRight: theme.spacing(1),
            '& .MuiChip-deleteIcon': {
              color: 'currentColor',
              ':hover': {
                color: 'currentColor',
              },
            },
          })}
          deleteIcon={<ClearIcon />}
          onDelete={() => clear(filter.type)}
        />
      ))}
      <LinkButton onPress={clearAll} />
    </Flex>
  );
};
