import { ConfigQuery_superConfig } from '../graphql/types';

interface SuperConfig {
  superConfig?: ConfigQuery_superConfig | null;
}

export const isSpDataEnabled = (configData?: SuperConfig | null) => {
  const config = configData?.superConfig;
  if (config) {
    if (
      config.spEnabled &&
      ((config.patientLicenceExpiration &&
        new Date(config.patientLicenceExpiration) > new Date()) ||
        (config.respiratoryMobilizationLicenceExpiration &&
          new Date(config.respiratoryMobilizationLicenceExpiration) >
            new Date()) ||
        (config.bedManagementLicenceExpiration &&
          new Date(config.bedManagementLicenceExpiration) > new Date()) ||
        (config.utilizationLicenceExpiration &&
          new Date(config.utilizationLicenceExpiration) > new Date()))
    ) {
      return true;
    }
  }
  return false;
};

export const isSpDashboardEnabled = (configData?: SuperConfig | null) => {
  return (
    configData?.superConfig?.spEnabled &&
    configData?.superConfig.spDashboardEnabled &&
    configData?.superConfig?.spDashboardLicenceExpiration &&
    new Date(configData?.superConfig.spDashboardLicenceExpiration) > new Date()
  );
};

export const isPatientEnabled = (configData?: SuperConfig | null) => {
  return Boolean(
    configData?.superConfig?.patientEnabled &&
      configData?.superConfig?.spEnabled &&
      configData.superConfig.patientLicenceExpiration &&
      new Date(configData.superConfig.patientLicenceExpiration) > new Date(),
  );
};
export const isRespiratoryMobilizationEnabled = (
  configData?: SuperConfig | null,
) => {
  return Boolean(
    configData?.superConfig?.respiratoryMobilizationEnabled &&
      configData?.superConfig?.spEnabled &&
      configData.superConfig.respiratoryMobilizationLicenceExpiration &&
      new Date(
        configData.superConfig.respiratoryMobilizationLicenceExpiration,
      ) > new Date(),
  );
};
export const isUtilizationEnabled = (configData?: SuperConfig | null) => {
  return Boolean(
    configData?.superConfig?.utilizationEnabled &&
      configData?.superConfig?.spEnabled &&
      configData.superConfig.utilizationLicenceExpiration &&
      new Date(configData.superConfig.utilizationLicenceExpiration) >
        new Date(),
  );
};
export const isBedManagementEnabled = (configData?: SuperConfig | null) => {
  return Boolean(
    configData?.superConfig?.bedManagementEnabled &&
      configData?.superConfig?.spEnabled &&
      configData.superConfig.bedManagementLicenceExpiration &&
      new Date(configData.superConfig.bedManagementLicenceExpiration) >
        new Date(),
  );
};
