import { t } from '@lingui/macro';
import { parseISO, isValid, format } from 'date-fns';
import { FontSize } from './graphql/types';

export interface PropertyNameFilterType {
  value: string;
  name: string;
}

export const mapFontSizeToPx = {
  [FontSize.SMALL]: '16px',
  [FontSize.MEDIUM]: '20px',
  [FontSize.LARGE]: '24px',
};

export const dateTimePickerFormat = 'dd/MM/yyyy hh:mm a..aaa';

export const getFilterPropertyNames = () => {
  const propertyNameFilter: PropertyNameFilterType[] = [
    { value: 'LINIS0001', name: t`LINIS0001` },
    { value: 'LINIS0002', name: t`LINIS0002` },
    { value: 'LINIS0003', name: t`LINIS0003` },
    { value: 'LINIS0004', name: t`LINIS0004` },
    { value: 'LINIS0005', name: t`LINIS0005` },
    { value: 'LINIS0006', name: t`LINIS0006` },
    { value: 'LINIS0007', name: t`LINIS0007` },
    { value: 'LINIS0008', name: t`LINIS0008` },
    { value: 'LINIS0009', name: t`LINIS0009` },
    { value: 'LINIS0010', name: t`LINIS0010` },
    { value: 'LINIS0011', name: t`LINIS0011` },
    { value: 'LINIS0012', name: t`LINIS0012` },
    { value: 'LINIS0013', name: t`LINIS0013` },
    { value: 'LINIS0014', name: t`LINIS0014` },
    { value: 'LINIS0015', name: t`LINIS0015` },
    { value: 'LINIS0016', name: t`LINIS0016` },
    { value: 'LINIS0017', name: t`LINIS0017` },
    { value: 'LINIS0018', name: t`LINIS0018` },
    { value: 'LINIS0019', name: t`LINIS0019` },
    { value: 'LINIS0020', name: t`LINIS0020` },
    { value: 'LINIS0021', name: t`LINIS0021` },
    { value: 'LINIS0022', name: t`LINIS0022` },
    { value: 'LINIS0023', name: t`LINIS0023` },
    { value: 'LINIS0024', name: t`LINIS0024` },
    { value: 'LINIS0025', name: t`LINIS0025` },
    { value: 'LINIS0026', name: t`LINIS0026` },
    { value: 'LINIS0028', name: t`LINIS0028` },
    { value: 'LINIS0030', name: t`LINIS0030` },
    { value: 'LINIS0031', name: t`LINIS0031` },
    { value: 'LINIS0032', name: t`LINIS0032` },
    { value: 'LINIS0033', name: t`LINIS0033` },
    { value: 'LINIS0034', name: t`LINIS0034` },
    { value: 'LINIS0035', name: t`LINIS0035` },
    { value: 'LINIS0036', name: t`LINIS0036` },
    { value: 'LINIS0037', name: t`LINIS0037` },
    { value: 'LINIS0039', name: t`LINIS0039` },
    { value: 'LINIS0041', name: t`LINIS0041` },
    { value: 'LINIS0042', name: t`LINIS0042` },
    { value: 'LINIS0045', name: t`LINIS0045` },
  ];
  return propertyNameFilter;
};

export const propTranslationTabel = (prop: string | null) => {
  switch (prop) {
    case 'LINIS0001':
      return t`LINIS0001`;
    case 'LINIS0002':
      return t`LINIS0002`;
    case 'LINIS0003':
      return t`LINIS0003`;
    case 'LINIS0004':
      return t`LINIS0004`;
    case 'LINIS0005':
      return t`LINIS0005`;
    case 'LINIS0006':
      return t`LINIS0006`;
    case 'LINIS0007':
      return t`LINIS0007`;
    case 'LINIS0008':
      return t`LINIS0008`;
    case 'LINIS0009':
      return t`LINIS0009`;
    case 'LINIS0010':
      return t`LINIS0010`;
    case 'LINIS0011':
      return t`LINIS0011`;
    case 'LINIS0012':
      return t`LINIS0012`;
    case 'LINIS0013':
      return t`LINIS0013`;
    case 'LINIS0014':
      return t`LINIS0014`;
    case 'LINIS0015':
      return t`LINIS0015`;
    case 'LINIS0016':
      return t`LINIS0016`;
    case 'LINIS0017':
      return t`LINIS0017`;
    case 'LINIS0018':
      return t`LINIS0018`;
    case 'LINIS0019':
      return t`LINIS0019`;
    case 'LINIS0020':
      return t`LINIS0020`;
    case 'LINIS0021':
      return t`LINIS0021`;
    case 'LINIS0022':
      return t`LINIS0022`;
    case 'LINIS0023':
      return t`LINIS0023`;
    case 'LINIS0024':
      return t`LINIS0024`;
    case 'LINIS0025':
      return t`LINIS0025`;
    case 'LINIS0026':
      return t`LINIS0026`;
    case 'LINIS0028':
      return t`LINIS0028`;
    case 'LINIS0030':
      return t`LINIS0030`;
    case 'LINIS0031':
      return t`LINIS0031`;
    case 'LINIS0032':
      return t`LINIS0032`;
    case 'LINIS0033':
      return t`LINIS0033`;
    case 'LINIS0034':
      return t`LINIS0034`;
    case 'LINIS0035':
      return t`LINIS0035`;
    case 'LINIS0036':
      return t`LINIS0036`;
    case 'LINIS0037':
      return t`LINIS0037`;
    case 'LINIS0039':
      return t`LINIS0039`;
    case 'LINIS0041':
      return t`LINIS0041`;
    case 'LINIS0042':
      return t`LINIS0042`;
    case 'LINIS0045':
      return t`LINIS0045`;

    default:
      return null;
  }
};

export const timeIsValid = (date: Date) => {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    if (isNaN(date.getTime())) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const parseDate = (dateStr?: string | null) => {
  const date = dateStr && parseISO(dateStr);
  return date && isValid(date) ? date : null;
};

export const getWeekDay = (date: Date) => {
  const weekdays = [t`Sun`, t`Mon`, t`Tues`, t`Wed`, t`Thurs`, t`Fri`, t`Sat`];
  const day = date.getDay();
  return weekdays[day];
};

export const getUniqueItems = <T extends { id: string }>(data: T[]) => {
  return data.reduce((uniqueData, item) => {
    if (!uniqueData.find((data) => data.id === item.id)) {
      uniqueData.push(item);
    }
    return uniqueData;
  }, [] as T[]);
};

export const getDateInputFormat = (date?: Date | null | string) => {
  if (date == null) return null;
  const d = typeof date === 'string' ? new Date(date) : date;
  return format(d, 'yyyy-MM-dd');
};

export const getDateForDatetimeLocalInput = (date?: Date | null | string) => {
  if (date == null) return null;
  const d = typeof date === 'string' ? new Date(date) : date;
  return format(d, "yyyy-MM-dd'T'HH:mm");
};
