import { Box, Fab, Tooltip, styled, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { t, Trans } from '@lingui/macro';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import { SuperAdminTopBar } from './SuperAdminTopBar';
import SafetyPositionPresetSettings from 'src/components/SafetyPositionPresetSettings';
import DirtyProvider from 'src/providers/DirtyProvider';
import ContentBlock from 'src/components/ContentBlock';
import OtherSettings from './OtherSettings';
import { doBedExport } from '../Admin/Bed/bedExport';
import { PasswordSettings } from './PasswordSettings/PasswordSettings';

const ProductSetting = () => {
  const { logout } = useContext(AuthContext);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.grey[100],
        minHeight: '100vh',
        maxHeight: '100%',
        height: '100%',
      })}
    >
      <DirtyProvider>
        <SuperAdminTopBar />
        <LogoutWrapper>
          <Tooltip title={t`Close`}>
            <LogoutButton onClick={logout} size="small">
              <CloseIcon />
            </LogoutButton>
          </Tooltip>
        </LogoutWrapper>
        <Container px={4}>
          <SafetyPositionPresetSettings />
          <ContentBlock title={t`Other Setting`} isBorderTop>
            <OtherSettings />
          </ContentBlock>
          <ContentBlock title={t`Password settings`} isBorderTop>
            <PasswordSettings />
          </ContentBlock>
          <ContentBlock title={t`Beds`} isBorderTop sx={{ mt: 4 }}>
            <Box>
              <Button
                color="primary"
                variant="outlined"
                fullWidth={false}
                onClick={doBedExport}
              >
                <Trans>Export</Trans>
              </Button>
            </Box>
          </ContentBlock>
        </Container>
      </DirtyProvider>
    </Box>
  );
};

export default ProductSetting;

const LogoutWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  bottom: -64px;
`;

const LogoutButton = styled(Fab)(
  ({ theme: { spacing, palette } }) => `
  margin-right: ${spacing(8)};
  background-color: ${palette.background.paper};
  border: 1px solid ${palette.grey[300]};
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  color: #${palette.text.secondary};
  :hover: {
    background-color: ${palette.grey[400]};
  }
`,
);

const Container = styled(Box)(
  ({ theme: { spacing } }) => `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: ${spacing(5.5)};
  margin-top: ${spacing(5)};
`,
);
