import { Box } from '@mui/material';
import { mobilizationViewWidth } from '../../helpers/mobilization';

const MobilizationLines = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: mobilizationViewWidth - 3,
        top: '4px',
        right: '5px',
      }}
    >
      {new Array(25).fill(0).map((_, index) => (
        <Box key={`Mobilization-line-${index}`} height={30}>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.common.mobilizationLine,
              height: '2px',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default MobilizationLines;
