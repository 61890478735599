import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useWeightFormatter } from 'src/components/WeightFormat';
import { BedState, BedType, FallRisk } from 'src/graphql/types';
import { ReactComponent as Done } from 'src/images/safetyView/dashboard/done.svg';
import { ReactComponent as Error } from 'src/images/safetyView/dashboard/error.svg';
import { ReactComponent as Lock } from 'src/images/safetyView/dashboard/lock.svg';
import { ReactComponent as Unlock } from 'src/images/safetyView/dashboard/unlock.svg';
import { ReactComponent as Unplugg } from 'src/images/safetyView/dashboard/unplugg.svg';
import { BatteryStatus } from 'src/pages/SafetyView/components/Icons/BatteryStatus';
import { Charging } from 'src/pages/SafetyView/components/Icons/Charging';
import {
  ButtonPressed,
  Mobilift,
} from 'src/pages/SafetyView/Dashboard/DashboardDatapoint';
import {
  SvDashboardSortingDataType,
  SvDashboardSortingType,
} from 'src/pages/SafetyView/Setting/DashboardSettingProvider';
import { BedExitIcon } from '../../../components/Icons/BedExitIcon';
import { BedExitMonitoring } from '../../../components/Icons/BedExitMonitoring';
import SiderailsIcon from '../../../components/SiderailsIcon';
import { BedData, getBedTypeName } from '../../../helpers/dashboard';

export const DATAPOINT_PREVIEW_COUNT = 3;
export const MAX_DATAPOINTS_COUNT = 9;

export type BedDetailDialog =
  | 'discharge'
  | 'newSession'
  | 'sessionEnded'
  | null;

export interface BedProps {
  bed: BedData;
  order: SvDashboardSortingDataType[];
  anonymized: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined;
}

const getMattressType = (type?: number | null) => {
  switch (type) {
    case 0:
      return '-';
    case 50:
    case 51:
      return 'Symbioso';
    case 52:
      return 'Opticare';
    case 53:
      return 'OptiCare-X';
    default:
      return;
  }
};

const withoutTrendelenburg = (bed: BedData) => {
  return (
    bed.type === BedType.E3XC_NO_TRX ||
    bed.type === BedType.E4_NO_TRX ||
    bed.type === BedType.E3XC_NO_TRX ||
    bed.type === BedType.E2
  );
};

interface Props {
  type: SvDashboardSortingType;
  bed: BedData;
  anonymized: boolean;
}

export const MobileBedCellValue = ({ type, bed, anonymized }: Props) => {
  const { i18n } = useLingui();
  const { palette } = useTheme();
  const bothLeftSiderails =
    bed.data?.siderailBothLUp ||
    (bed.data?.siderailHeadLUp && bed.data.siderailMiddleLUp);
  const bothRightSiderails =
    bed.data?.siderailBothRUp ||
    (bed.data?.siderailHeadRUp && bed.data.siderailMiddleRUp);
  const patientIsOnBed = Boolean(bed.data?.patientOnBed);
  const paused = Boolean(bed.data?.paused);
  const { formatWeight } = useWeightFormatter();

  if (bed.inAnotherWard && type !== SvDashboardSortingType.location) {
    return null;
  }

  const highFallRisk = bed.fallRisk === FallRisk.HIGH;
  const showFallRiskAlert =
    highFallRisk && !bed.data?.bedExitOn && patientIsOnBed;

  switch (type) {
    case SvDashboardSortingType.powerStatus:
      return !bed.data?.power &&
        bed.unitId &&
        bed.state !== BedState.OFFLINE ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Unplugg color={palette.text.primary} />
        </Box>
      ) : null;
    case SvDashboardSortingType.location:
      return <BoldText>{bed.location || <Trans>Unknown</Trans>}</BoldText>;
    case SvDashboardSortingType.name:
      return bed.patientId ? (
        <BoldText>{bed.sessionName}</BoldText>
      ) : (
        <RegularText sx={{ color: palette.common.gray }}>
          <Trans>Not assigned</Trans>
        </RegularText>
      );
    case SvDashboardSortingType.note:
      return <NoteText>{bed.note}&nbsp;</NoteText>;
    case SvDashboardSortingType.bedExitMonitoringStatus:
      return (
        <BedExitMonitoring
          bedExitOn={bed.data?.bedExitOn}
          isZoneExit={bed.data?.isZoneExit}
          showFallRiskAlert={showFallRiskAlert}
          paused={paused}
        />
      );
    case SvDashboardSortingType.outOfBed:
      const bea = bed.data?.bedExitAlarm;
      const iconState = bea
        ? 'error'
        : !bed.data?.bedExitOn
        ? //bedExitOff
          showFallRiskAlert
          ? 'warning'
          : 'off'
        : //bedExitOn
        paused
        ? 'warning'
        : patientIsOnBed
        ? 'on'
        : 'error';
      return (
        <Box display="flex" alignItems="center">
          <BedExitIcon
            state={iconState}
            patientInBed={patientIsOnBed}
            useEmFontSize
          />
        </Box>
      );
    case SvDashboardSortingType.spAndLock:
      const safetyPosition = !!bed.data?.safetyPosition;
      return <>{safetyPosition ? <DoneIcon /> : <ErrorIcon />}</>;
    case SvDashboardSortingType.siderails:
      return (
        <SiderailsIcon
          leftSiderailsUp={!!bothLeftSiderails}
          rightSiderailsUp={!!bothRightSiderails}
          leftTop={!!bed.data?.siderailHeadLUp}
          rightTop={!!bed.data?.siderailHeadRUp}
          leftBottom={!!bed.data?.siderailMiddleLUp}
          rightBottom={!!bed.data?.siderailMiddleRUp}
          bedType={bed.type}
          downColor={palette.grey[600]}
          upColor={palette.success.light}
        />
      );
    case SvDashboardSortingType.brakes:
      return <>{bed.data?.brakesOff ? <ErrorIcon /> : <DoneIcon />}</>;
    case SvDashboardSortingType.lowestPosition:
      return (
        <>
          {bed.data?.heightLock && (
            <Lock color={palette.text.primary} style={{ margin: '0 4px' }} />
          )}
          {bed.data?.posIsLowest ? <DoneIcon /> : <ErrorIcon />}
        </>
      );
    case SvDashboardSortingType.backrestAndLock:
      return (
        <>
          {bed.data?.backrestLock && (
            <Lock color={palette.text.primary} style={{ margin: '0 4px' }} />
          )}
          <RegularText>
            {bed.data?.zAxisDeg != null ? bed.data.zAxisDeg : '--'} °
          </RegularText>
        </>
      );
    case SvDashboardSortingType.trendelenburgAngle:
      if (withoutTrendelenburg(bed)) {
        return <RegularText>--</RegularText>;
      }
      return (
        <Box display="flex">
          {bed.data?.trendelenburgDeg != null &&
            bed.data?.trendelenburgDeg !== 0 && (
              <RegularText sx={{ margin: '0 4px' }}>
                {bed.data.trendelenburgDeg > 0 ? (
                  <Trans>ATX</Trans>
                ) : (
                  <Trans>TX</Trans>
                )}
              </RegularText>
            )}
          <RegularText>
            {`${
              bed.data?.trendelenburgDeg != null
                ? Math.abs(bed.data.trendelenburgDeg)
                : '--'
            } °`}
          </RegularText>
        </Box>
      );
    case SvDashboardSortingType.weight:
      return (
        <RegularText>{formatWeight(bed.data?.weight, anonymized)}</RegularText>
      );
    case SvDashboardSortingType.laterailTiltAndAlt:
      return (
        <Box display="flex">
          <RegularText>
            {!!bed.data?.lateralTiltDeg &&
              (bed.data.lateralTiltDeg > 0 ? (
                <Trans>R</Trans>
              ) : (
                <Trans>L</Trans>
              ))}{' '}
          </RegularText>
          <RegularText sx={{ margin: '0 4px' }}>
            {`${
              bed.data?.lateralTiltDeg != null
                ? Math.abs(bed.data.lateralTiltDeg)
                : '--'
            } °`}
          </RegularText>
          {bed.data?.ALTPhase && bed.data.ALTPhase > 3 ? (
            <RegularText>ALT</RegularText>
          ) : (
            ''
          )}
        </Box>
      );
    case SvDashboardSortingType.calfrestAngleAndLock:
      return (
        <>
          {bed.data?.calfrestLock && (
            <Lock color={palette.text.primary} style={{ margin: '0 4px' }} />
          )}
          <RegularText>
            {`${bed.data?.lAxisDeg != null ? bed.data.lAxisDeg : '--'} °`}
          </RegularText>
        </>
      );
    case SvDashboardSortingType.thighrestLock:
      return (
        <>
          {bed.data?.thighrestLock ? (
            <Lock color={palette.text.primary} />
          ) : (
            <Unlock color={palette.text.primary} height={24} />
          )}
        </>
      );
    case SvDashboardSortingType.mattressType:
      return (
        <RegularText>{getMattressType(bed.data?.mattressType)}</RegularText>
      );
    case SvDashboardSortingType.bedType:
      return <RegularText>{getBedTypeName(bed.type) ?? '--'}</RegularText>;
    case SvDashboardSortingType.sessionId:
      return <RegularText>{bed.patientId ?? '--'}</RegularText>;
    case SvDashboardSortingType.sessionStart:
      return (
        <RegularText>
          {bed.sessionStart
            ? i18n.date(bed.sessionStart, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })
            : '--'}
        </RegularText>
      );
    case SvDashboardSortingType.bedName:
      return <RegularText>{bed.bedName ?? '--'}</RegularText>;
    case SvDashboardSortingType.tagCharge:
      return (
        <RegularText>
          {bed.data?.tagCharge ? `${bed.data.tagCharge}%` : '--'}
        </RegularText>
      );
    case SvDashboardSortingType.charging:
      return <Charging charging={!!bed.data?.charging} />;
    case SvDashboardSortingType.transfer:
      return (
        <Box display="flex" alignItems="center">
          {bed.data?.transfer ? <DoneIcon /> : <ErrorIcon />}
        </Box>
      );
    case SvDashboardSortingType.heightOfRLegColumn:
      return <RegularText>{bed.data?.heightOfRLegColumn || '--'}</RegularText>;
    case SvDashboardSortingType.heightOfLLegColumn:
      return <RegularText>{bed.data?.heightOfLLegColumn || '--'}</RegularText>;
    case SvDashboardSortingType.heightOfHeadColumn:
      return <RegularText>{bed.data?.heightOfHeadColumn || '--'}</RegularText>;
    case SvDashboardSortingType.mobiliftRight:
      return <Mobilift mobiliftStatus={bed.data?.mobiliftRight} />;
    case SvDashboardSortingType.mobiliftLeft:
      return <Mobilift mobiliftStatus={bed.data?.mobiliftLeft} />;
    case SvDashboardSortingType.bedExtension:
      return (
        <Box display="flex" alignItems="center">
          {bed.data?.bedExtension ? <DoneIcon /> : <ErrorIcon />}
        </Box>
      );
    case SvDashboardSortingType.pressedBtnAndUsedController:
      return (
        <ButtonPressed buttonPressed={bed.data?.pressedBtnAndUsedController} />
      );
    case SvDashboardSortingType.rightLCDButtonPressed:
      return <ButtonPressed buttonPressed={bed.data?.rightLCDButtonPressed} />;
    case SvDashboardSortingType.leftLCDButtonPressed:
      return <ButtonPressed buttonPressed={bed.data?.leftLCDButtonPressed} />;
    case SvDashboardSortingType.legsControllerButtonPressed:
      return (
        <ButtonPressed buttonPressed={bed.data?.legsControllerButtonPressed} />
      );
    case SvDashboardSortingType.acpAndRoControllerButtonPressed:
      return (
        <ButtonPressed
          buttonPressed={bed.data?.acpAndRoControllerButtonPressed}
        />
      );
    case SvDashboardSortingType.digitalSiderailLeftButtonPressed:
      return (
        <ButtonPressed
          buttonPressed={bed.data?.digitalSiderailLeftButtonPressed}
        />
      );
    case SvDashboardSortingType.digitalSiderailRightButtonPressed:
      return (
        <ButtonPressed
          buttonPressed={bed.data?.digitalSiderailRightButtonPressed}
        />
      );
    case SvDashboardSortingType.ALTError:
      return <RegularText>{bed.data?.ALTError || '--'}</RegularText>;
    case SvDashboardSortingType.errorOrStopCode:
      return <RegularText>{bed.data?.errorOrStopCode || '--'}</RegularText>;
    case SvDashboardSortingType.batteryFailure:
      return <BatteryStatus status={!!bed.data?.batteryFailure} />;
  }
  return null;
};

const BoldText = styled(Typography)({
  fontWeight: 500,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1rem',
});

const RegularText = styled(Typography)({
  fontSize: '0.85rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const NoteText = styled(Typography)({
  fontStyle: 'italic',
  fontSize: '0.775rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const DoneIcon = styled(Done)`
  width: 1rem;
  height: 1rem;
`;

const ErrorIcon = styled(Error)`
  width: 1rem;
  height: 1rem;
`;
