import { useEffect, useState } from 'react';
import { Box, IconButton, Popover, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FilterValue } from './filters';
import { styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  id: string;
  value: FilterValue | null;
  setValue: (value: FilterValue | null) => void;
}

export const FilterSearch = ({ value, setValue }: Props) => {
  const [filterAnchor, setFilterAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [search, setSearch] = useState(value?.id || '');

  useEffect(() => {
    setSearch(value?.id || '');
  }, [value?.id]);

  const clear = () => {
    setSearch('');
    setValue(null);
  };

  const handleBlur = () => {
    setValue(
      search
        ? {
            id: search,
            name: search,
          }
        : null,
    );
  };

  return (
    <>
      <SelectedButton
        size="small"
        onClick={(event) => setFilterAnchor(event.currentTarget)}
        selected={!!value}
      >
        <SearchIcon />
      </SelectedButton>
      <Popover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={(event) => {
          setFilterAnchor(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        <Box sx={{ width: 200 }}>
          <TextField
            variant="outlined"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            onBlur={handleBlur}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleBlur();
              }
            }}
            InputProps={{
              endAdornment: value?.id ? (
                <IconButton size="small" onClick={clear}>
                  <ClearIcon />
                </IconButton>
              ) : null,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                mt: 0,
                width: '200px',
              },
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

const SelectedButton = styled(IconButton)<{ selected?: boolean }>(
  ({ theme: { spacing, palette }, selected }) => `
  color: ${palette.text.primary};
  ${
    selected
      ? `
    background-color: ${palette.primary.main};
    color: ${palette.primary.contrastText};
    :hover {
      background-color: ${palette.primary.dark};
    }
  `
      : null
  }
  margin-left: ${spacing(1)};
`,
);
