import { Box, IconButton, TextField } from '@mui/material';
import { useContext } from 'react';
import { MobileDashboardContext } from '../Settings/MobilDashboardProvider';
import { t } from '@lingui/macro';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export const MobileSearchBar = () => {
  const { searchQuery, setSearchQuery } = useContext(MobileDashboardContext);

  return (
    <>
      <Box px={3} pt={1} display="flex" mb={3}>
        <TextField
          variant="outlined"
          placeholder={t`Find room / name`}
          fullWidth
          value={searchQuery || ''}
          onChange={(event) => setSearchQuery(event.target.value)}
          InputProps={{
            endAdornment: (
              <>
                {searchQuery ? (
                  <IconButton onClick={() => setSearchQuery('')}>
                    <ClearIcon sx={{ color: 'error.main' }} />
                  </IconButton>
                ) : null}
                <SearchIcon sx={{ color: 'common.infoIcon' }} />
              </>
            ),
          }}
        />
      </Box>
    </>
  );
};
