import { useState } from 'react';
import { Button } from '@mui/material';
import { Trans } from '@lingui/macro';
import ExportDataDialog from '../../Export/components/ExportDataDialog';
import { ContentType } from '../BedDetailModal';
interface Props {
  sessionId?: string;
  sessionStart?: Date;
  sessionEnd?: Date;
  content: ContentType;
  sessionName: string | null;
  unitId?: string | null;
}

const ExportButton = ({
  sessionId,
  sessionStart,
  sessionEnd,
  content,
  unitId,
  sessionName,
}: Props) => {
  const [openExportDialog, setOpenExportDialog] = useState(false);
  return (
    <>
      <Button
        disabled={!sessionStart || !unitId}
        onClick={() => setOpenExportDialog(true)}
        variant="contained"
      >
        <Trans>Export</Trans>
      </Button>
      {openExportDialog && unitId && sessionStart && (
        <ExportDataDialog
          unitId={unitId}
          sessionName={sessionName}
          content={content}
          sessionId={sessionId}
          open={openExportDialog}
          sessionStart={new Date(sessionStart)}
          sessionEnd={sessionEnd ? new Date(sessionEnd) : new Date()}
          close={() => setOpenExportDialog(false)}
        />
      )}
    </>
  );
};

export default ExportButton;
