import { t } from '@lingui/macro';
import { DateOptionPreset } from 'src/lib/datePresetHelper';
import { FilterParameter, ReportDatePreset } from '../types';

const mapDateSelectToTranslates = () => ({
  [DateOptionPreset.now]: t`Now`,
  [DateOptionPreset.today]: t`Today`,
  [DateOptionPreset.yesterday]: t`Yesterday`,
  [DateOptionPreset.last24Hours]: t`Last 24 Hours`,
  [DateOptionPreset.last7Days]: t`Last 7 Days`,
  [DateOptionPreset.last14Days]: t`Last 14 Days`,
  [DateOptionPreset.thisWeek]: t`This week`,
  [DateOptionPreset.lastWeek]: t`Last week`,
  [DateOptionPreset.thisMonth]: t`This month`,
  [DateOptionPreset.lastMonth]: t`Last month`,
  [DateOptionPreset.thisQuarter]: t`This quarter`,
  [DateOptionPreset.lastQuarter]: t`Last quarter`,
  [DateOptionPreset.thisYear]: t`This year`,
  [DateOptionPreset.lastYear]: t`Last year`,
  custom: t`Custom`,
});

export const dateSelectionType: Record<
  FilterParameter,
  'withNow' | 'withoutNow'
> = {
  [FilterParameter.OCCUPANCY]: 'withNow',
  [FilterParameter.ALL_SIDERAILS]: 'withoutNow',
  [FilterParameter.ALOS]: 'withoutNow',
  [FilterParameter.BACKREST]: 'withoutNow',
  [FilterParameter.BACKREST_30]: 'withoutNow',
  [FilterParameter.BACKREST_45]: 'withoutNow',
  [FilterParameter.BACKREST_ANGLE]: 'withNow',
  [FilterParameter.BED_EXIT_COUNT]: 'withoutNow',
  [FilterParameter.BED_EXIT_MONITORING_ON]: 'withoutNow',
  [FilterParameter.BED_EXIT_REACTION]: 'withoutNow',
  [FilterParameter.CALFREST]: 'withoutNow',
  [FilterParameter.ALT_PHASE]: 'withoutNow',
  [FilterParameter.LATERAL_TILT]: 'withoutNow',
  [FilterParameter.LEFT_HEAD_SIDERAIL]: 'withoutNow',
  [FilterParameter.LEFT_SIDERAIL]: 'withoutNow',
  [FilterParameter.RIGHT_HEAD_SIDERAIL]: 'withoutNow',
  [FilterParameter.RIGHT_SIDERAIL]: 'withoutNow',
  [FilterParameter.LOWEST_POSTITION]: 'withoutNow',
  [FilterParameter.ONLINE]: 'withoutNow',
  [FilterParameter.PATIENT_PRESENCE]: 'withoutNow',
  [FilterParameter.SAFETY_POSITION]: 'withoutNow',
  [FilterParameter.TRENDELENBURG]: 'withoutNow',
  [FilterParameter.WEIGHT]: 'withNow',
};

export const getDateSelectOptions = (parameter: FilterParameter) => {
  const result: (ReportDatePreset | 'divider')[] = [];
  if (dateSelectionType[parameter] === 'withoutNow') {
    result.push(
      ...[
        DateOptionPreset.today,
        DateOptionPreset.yesterday,
        'divider' as 'divider',
        DateOptionPreset.last24Hours,
        DateOptionPreset.last7Days,
        DateOptionPreset.last14Days,
        'divider' as 'divider',
        DateOptionPreset.thisWeek,
        DateOptionPreset.lastWeek,
        DateOptionPreset.thisMonth,
        DateOptionPreset.lastMonth,
        DateOptionPreset.thisQuarter,
        DateOptionPreset.lastQuarter,
        DateOptionPreset.thisYear,
        DateOptionPreset.lastYear,
        'divider' as 'divider',
        'custom' as 'custom',
      ],
    );
  } else {
    result.push(
      ...[
        DateOptionPreset.now,
        'divider' as 'divider',
        DateOptionPreset.today,
        DateOptionPreset.yesterday,
        'divider' as 'divider',
        DateOptionPreset.last24Hours,
        DateOptionPreset.last7Days,
        DateOptionPreset.last14Days,
        'divider' as 'divider',
        DateOptionPreset.thisWeek,
        DateOptionPreset.lastWeek,
        DateOptionPreset.thisMonth,
        DateOptionPreset.lastMonth,
        DateOptionPreset.thisQuarter,
        DateOptionPreset.lastQuarter,
        DateOptionPreset.thisYear,
        DateOptionPreset.lastYear,
        'divider' as 'divider',
        'custom' as 'custom',
      ],
    );
  }
  const dateSelectTranslates = mapDateSelectToTranslates();
  return result.map((dateOption, idx) => ({
    id: dateOption === 'divider' ? `divider-${idx}` : dateOption,
    type: dateOption === 'divider' ? 'divider' : 'option',
    name: dateSelectTranslates[dateOption],
  }));
};
