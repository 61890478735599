import { Trans, t } from '@lingui/macro';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  TextField,
  styled,
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import {
  WeightUnits,
  UploadLogoMutation,
  UploadLogoMutationVariables,
} from '../../../graphql/types';
import { SettingsState } from './globalSettingsHelper';
import { useMutation } from '@apollo/client';
import { uploadLogoMutation } from '../../../graphql/mutations';
import { logo } from '../../../graphql/queries';
import Logo from '../../../components/Logo';
import { useState } from 'react';
import ContentBlock from 'src/components/ContentBlock';
import { useDirty } from 'src/hooks';

interface Props {
  unitSystem: WeightUnits;
  hospitalName: string;
  logoURL?: string;
  serverIp?: string | null;
  setSettings: React.Dispatch<React.SetStateAction<SettingsState>>;
  onSave: () => null | Promise<any>;
}

interface DirtyValues {
  hospitalName: string;
  unitSystem: string;
}

const GeneralSettings = ({
  hospitalName,
  unitSystem,
  logoURL,
  serverIp,
  onSave,
  setSettings,
}: Props) => {
  const [imageHash, setImageHash] = useState<number>(Date.now());

  const handleDiscard = () => {
    handleChangeHospitalName(initValue.hospitalName);
    handleChangeUnitSystem(initValue.unitSystem);
  };

  const { isDirty, resetDirty, initValue } = useDirty<DirtyValues>(
    { hospitalName, unitSystem },
    handleDiscard,
  );

  const handleChangeUnitSystem = (value: string) => {
    setSettings((prev) => ({
      ...prev,
      unitSystem: WeightUnits[value],
    }));
  };

  const handleChangeHospitalName = (value: string) => {
    setSettings((prev) => ({
      ...prev,
      hospitalName: value,
    }));
  };

  const [uploadLogo, { loading: uploading }] = useMutation<
    UploadLogoMutation,
    UploadLogoMutationVariables
  >(uploadLogoMutation, {
    refetchQueries: [{ query: logo }],
    onCompleted: () => setImageHash(Date.now()),
  });

  const handleLogoFileChange = async (e: any) => {
    const file = e.target.files[0];
    await uploadLogo({
      variables: { file },
      context: {
        useMultipart: true,
      },
    });
  };

  const handleSave = async () => {
    try {
      await onSave();
      resetDirty();
    } catch (e) {}
  };

  return (
    <StyledBox>
      <ContentBlock
        onSave={handleSave}
        onCancel={handleDiscard}
        isSaveDisabled={!isDirty}
        title={t`General`}
      >
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.input}
        >
          <TextField
            fullWidth
            label={t`Hospital name`}
            id="outlined-hospitalName"
            value={hospitalName}
            onChange={({ target }) => handleChangeHospitalName(target.value)}
            margin="normal"
            variant="outlined"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.input}
        >
          <TextField
            select
            value={unitSystem}
            name={t`unitSystem`}
            label={t`Unit System`}
            id="outlined-unit-system"
            variant="outlined"
            onChange={({ target }) =>
              handleChangeUnitSystem(target.value as string)
            }
            fullWidth
          >
            <MenuItem value={WeightUnits.METRIC}>
              <Trans>Metric</Trans>
            </MenuItem>
            <MenuItem value={WeightUnits.IMPERIAL}>
              <Trans>Imperial</Trans>
            </MenuItem>
          </TextField>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.input}
        >
          <TextField
            fullWidth
            label={t`Server IP`}
            id="outlined-serverIP"
            value={serverIp || t`Unknown`}
            disabled
            margin="normal"
            variant="outlined"
          />
        </FormControl>
      </ContentBlock>
      <ContentBlock title={t`Custom Logo`} isBorderTop>
        <Box>
          <FormControl fullWidth={true} variant="outlined">
            {logoURL && (
              <Logo logoURL={`${logoURL}?${imageHash}`} deletable={true} />
            )}
            <Box sx={{ width: '100%', height: 'auto', marginTop: 1 }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="company-image-upload"
                name="logoSelector"
                type="file"
                onChange={handleLogoFileChange}
              />
              <label htmlFor="company-image-upload">
                <Button
                  disabled={uploading}
                  variant="contained"
                  component="span"
                  startIcon={<UploadIcon />}
                >
                  <Trans>Upload Image</Trans>
                </Button>
              </label>
            </Box>
          </FormControl>
        </Box>
      </ContentBlock>
    </StyledBox>
  );
};

export default GeneralSettings;

const PREFIX = 'GeneralSettings';

const classes = {
  input: `${PREFIX}-inputBlock`,
};

const StyledBox = styled(Box)(({ theme: { spacing } }) => {
  return {
    [`& .${classes.input}`]: {
      marginBottom: spacing(4),
      width: '50%',
    },
  };
});
