import { ParkingPlaceMode } from 'src/graphql/types';
import { styled } from '@mui/material';
import Flex from 'src/components/Flex';

interface Props {
  onChangeMode: (type: ParkingPlaceMode) => void;
  mode: ParkingPlaceMode;
  active?: boolean;
  children: React.ReactNode;
}

const LocationSourceButton = ({
  active = false,
  children,
  onChangeMode,
  mode,
}: Props) => {
  return (
    <LocationSourceWrapper>
      <LocationSource active={active} onClick={() => onChangeMode(mode)}>
        {children}
      </LocationSource>
    </LocationSourceWrapper>
  );
};

export default LocationSourceButton;

export const LocationSourceWrapper = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
  min-height: 62px;
`;

const LocationSource = styled(Flex)<{ active: Boolean }>(
  ({ active, theme: { palette } }) => `
      flex: 1;
      padding: 0 8px;
      border-radius: 10px;
      background-color: ${
        active ? palette.background.paper : palette.grey[200]
      };
      border: solid 2px ${
        active ? palette.text.primary : palette.text.disabled
      };
      color: ${active ? palette.text.primary : palette.text.disabled};
      cursor: pointer;
      align-items: center;
      min-height: 54px;
  `,
);
