import { Box, Typography } from '@mui/material';
import { i18n } from '@lingui/core';
import { getMobilizationTimelineValues } from '../../helpers/mobilization';

const MobilizationTimeline = () => {
  const timelineData = getMobilizationTimelineValues();

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
      }}
    >
      {timelineData.map((date) => (
        <Box key={`mobilization-timeline-item-${date}`} height={30}>
          <Typography
            color="textSecondary"
            sx={{
              position: 'relative',
              top: '-7px',
              fontSize: '10px',
              whiteSpace: 'nowrap',
            }}
            key={`mobilization-timeline-item-${date}`}
          >
            {date
              ? i18n.date(date, {
                  hour: 'numeric',
                  minute: 'numeric',
                })
              : null}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MobilizationTimeline;
