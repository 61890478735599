import { Trans } from '@lingui/macro';
import { Box, Divider, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { patientCareNotes } from 'src/graphql/queries';
import { PatientCareNotes, PatientCareNotesVariables } from 'src/graphql/types';
import MobileAddCareNote from './MobileAddCareNote';
import MobileCareNoteItem from './MobileCareNoteItem';

interface Props {
  patientSessionId: string;
  sessionStart?: string | null;
}

const MobileCareNotes = ({ patientSessionId, sessionStart }: Props) => {
  const { data } = useQuery<PatientCareNotes, PatientCareNotesVariables>(
    patientCareNotes,
    {
      variables: {
        id: patientSessionId,
      },
    },
  );
  return (
    <>
      <Box mb={1}>
        <Typography variant="caption">
          <Trans>Add Care Note</Trans>
        </Typography>
        <Box mt={1}>
          <MobileAddCareNote patientSessionId={patientSessionId} />
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="caption">
          <Trans>Care notes</Trans>
        </Typography>
        {data?.patientSession?.careNotes.map(
          (careNote) =>
            careNote && (
              <MobileCareNoteItem
                patientSessionId={patientSessionId}
                key={`careNote-${careNote?.id}`}
                data={{
                  date: careNote?.dateCreated,
                  note: careNote?.note,
                  cofirmDate: new Date(careNote.dateConfirmed),
                  id: careNote.id,
                }}
              />
            ),
        )}
      </Box>
    </>
  );
};

export default MobileCareNotes;
