import { withI18n, withI18nProps } from '@lingui/react';
import { Box, AccordionDetails, styled } from '@mui/material';
import React from 'react';
import {
  ControlStateType,
  getGroupDatapoints,
  Groups,
} from './globalSettingsHelper';
import Datapoint from './Datapoint';

interface Props extends withI18nProps {
  setControlState: React.Dispatch<React.SetStateAction<ControlStateType[]>>;
  type: Groups;
  data: ControlStateType[];
}

const ControlsContainer = ({ data, setControlState, type }: Props) => {
  const groupDatapoints: ControlStateType[] = getGroupDatapoints(data, type);
  return (
    <AccordionDetails sx={{ padding: 0 }}>
      <DataPointsContainer>
        {groupDatapoints.map((datapoint) => (
          <Datapoint
            key={datapoint.type}
            controlState={data}
            setControlState={setControlState}
            datapointData={datapoint}
          />
        ))}
      </DataPointsContainer>
    </AccordionDetails>
  );
};

export default withI18n()(React.memo(ControlsContainer));

const DataPointsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;
