import { Alert, AlertColor, Snackbar } from '@mui/material';
import { createContext, useContext, useState } from 'react';

interface SnackbarContextType {
  setSnackbar: React.Dispatch<React.SetStateAction<SnackbarInput | null>>;
}

export const SnackbarContext = createContext<SnackbarContextType>({
  setSnackbar: () => {},
});

interface SnackbarInput {
  type: AlertColor;
  message: string;
}

interface Props {
  children: React.ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
  const [snackbar, setSnackbar] = useState<SnackbarInput | null>(null);

  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(null);
  };

  return (
    <SnackbarContext.Provider
      value={{
        setSnackbar,
      }}
    >
      {children}
      <Snackbar open={!!snackbar} autoHideDuration={3500} onClose={handleClose}>
        <Alert
          onClose={() => setSnackbar(null)}
          severity={snackbar?.type || 'success'}
          sx={{ width: '100%' }}
        >
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  return context;
};
