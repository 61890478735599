import { useQuery } from '@apollo/client';
import { wardSafetyPositionQuery } from 'src/graphql/queries';
import {
  WardSafetyPositionQuery,
  WardSafetyPositionQuery_workspaces as WorkSpace,
} from 'src/graphql/types';

const useSafetyPositionAssignments = () => {
  const { data: workspacesData, loading: workspacesLoading } =
    useQuery<WardSafetyPositionQuery>(wardSafetyPositionQuery);
  const workspaces: WorkSpace[] = workspacesData?.workspaces || [];

  return {
    loading: workspacesLoading,
    workspaces,
  };
};

export default useSafetyPositionAssignments;
