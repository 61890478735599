import { GraphType } from '../helpers/patientView';
import { ReactComponent as Lateral } from 'src/images/safetyView/dataPoints/lateral.svg';
import { ReactComponent as Weight } from 'src/images/safetyView/dataPoints/weight.svg';
import { ReactComponent as Siderails } from 'src/images/safetyView/dataPoints/siderails.svg';
import { ReactComponent as Backrest } from 'src/images/safetyView/dataPoints/backrest.svg';
import { ReactComponent as CalfrestAngle } from 'src/images/safetyView/dataPoints/calfrest_angle.svg';
import { ReactComponent as Trendelenburg } from 'src/images/safetyView/dataPoints/trendelenburg.svg';
import { ReactComponent as Brakes } from 'src/images/safetyView/dataPoints/brakes.svg';
import { ReactComponent as BedExitMonitorStatus } from 'src/images/safetyView/dataPoints/bed_exit_monitor.svg';
import { ReactComponent as BedExitAlarm } from 'src/images/safetyView/dataPoints/bed_exit_alarm.svg';
import { ReactComponent as Height } from 'src/images/safetyView/dataPoints/height.svg';
import { ReactComponent as PatienOnBed } from 'src/images/safetyView/dataPoints/patient_on_bed.svg';
import { ReactComponent as BackrestLock } from 'src/images/safetyView/dataPoints/backrest_lock.svg';
import { ReactComponent as ThighrestLock } from 'src/images/safetyView/dashboard/lock.svg';
import { ReactComponent as HeightLock } from 'src/images/safetyView/dataPoints/height.svg';
import { ReactComponent as MattressType } from 'src/images/safetyView/dataPoints/mattress_type.svg';
import { ReactComponent as Alt } from 'src/images/safetyView/dataPoints/alt.svg';
import { ReactComponent as SafetyPosition } from 'src/images/safetyView/dataPoints/safety_position.svg';

interface Props {
  type: GraphType;
  color: string;
}

export const DataPointIcon = ({ type, color }: Props) => {
  const iconProps = {
    width: '100%',
    height: '100%',
    color,
  };
  switch (type) {
    case GraphType.weight:
      return <Weight {...iconProps} />;
    case GraphType.leftBothSiderails:
      return <Siderails {...iconProps} />;
    case GraphType.rightBothSiderails:
      return <Siderails {...iconProps} />;
    case GraphType.backrestAngle:
      return <Backrest {...iconProps} />;
    case GraphType.trendelenburg:
      return <Trendelenburg {...iconProps} />;
    case GraphType.brakes:
      return <Brakes {...iconProps} />;
    case GraphType.lateral:
      return <Lateral {...iconProps} />;
    case GraphType.bedExitStatus:
      return <BedExitMonitorStatus {...iconProps} />;
    case GraphType.bedExitAlarm:
      return <BedExitAlarm {...iconProps} />;
    case GraphType.isZoneExit:
      return <BedExitMonitorStatus {...iconProps} />;
    case GraphType.lowestPosition:
      return <Height {...iconProps} />;
    case GraphType.outOfBed:
      return <PatienOnBed {...iconProps} />;
    case GraphType.leftHeadSiderail:
      return <Siderails {...iconProps} />;
    case GraphType.rightHeadSiderail:
      return <Siderails {...iconProps} />;
    case GraphType.leftSiderail:
      return <Siderails {...iconProps} />;
    case GraphType.rightSiderail:
      return <Siderails {...iconProps} />;
    case GraphType.calfrestAngle:
      return <CalfrestAngle {...iconProps} />;
    case GraphType.calfrestLock:
      return <CalfrestAngle {...iconProps} />;
    case GraphType.backrestLock:
      return <BackrestLock {...iconProps} />;
    case GraphType.thighrestLock:
      return (
        <ThighrestLock
          style={{
            width: 14,
            position: 'relative',
            left: 5,
          }}
          {...iconProps}
        />
      );
    case GraphType.heightLock:
      return <HeightLock {...iconProps} />;
    case GraphType.safetyPosition:
      return <SafetyPosition {...iconProps} />;
    case GraphType.maxMattressType:
      return <MattressType {...iconProps} />;
    case GraphType.maxALTPhase:
      return <Alt {...iconProps} />;
    default:
      return null;
  }
};
