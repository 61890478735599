import Backrest from 'src/images/safetyPortPropsIcons/Backrest.svg';
import BedExitAlarm from 'src/images/safetyPortPropsIcons/Bed_exit_alarm.svg';
import BedExitMonitoring from 'src/images/safetyPortPropsIcons/Bed_exit_monitoring.svg';
import BrakesStatus from 'src/images/safetyPortPropsIcons/Brakes_status.svg';
import Height from 'src/images/safetyPortPropsIcons/Height.svg';
import LateralTiltAngle from 'src/images/safetyPortPropsIcons/Lateral_tilt_angle.svg';
import Location from 'src/images/safetyPortPropsIcons/Location.svg';
import PatientOnBed from 'src/images/safetyPortPropsIcons/Patient_on_bed.svg';
import PatientWeight from 'src/images/safetyPortPropsIcons/Patient_weight.svg';
import SafetyPosition from 'src/images/safetyPortPropsIcons/Safety_position.svg';
import SiderailsPosition from 'src/images/safetyPortPropsIcons/Siderails_position.svg';
import TrendelenburgAngle from 'src/images/safetyPortPropsIcons/Trendelenburg_angle.svg';
import PressedButtonUsedController from 'src/images/safetyPortPropsIcons/Pressed_button_used_controller.svg';
import ThighrestLock from 'src/images/safetyPortPropsIcons/Thighrest_lock.svg';
import CalfrestLock from 'src/images/safetyPortPropsIcons/Calfrest_lock.svg';
import CalfrestAngle from 'src/images/safetyPortPropsIcons/Calfrest_angle.svg';
import HeightLock from 'src/images/safetyPortPropsIcons/Height_lock.svg';
import BackrestLock from 'src/images/safetyPortPropsIcons/Backrest_lock.svg';
import MobiliftRight from 'src/images/safetyPortPropsIcons/Mobilift_right.svg';
import MobiliftLeft from 'src/images/safetyPortPropsIcons/Mobilift_left.svg';
import MattressType from 'src/images/safetyPortPropsIcons/Mattress_type.svg';
import Transfer from 'src/images/safetyPortPropsIcons/Transfer.svg';
import Alt from 'src/images/safetyPortPropsIcons/Alt.svg';
import Error from 'src/images/safetyPortPropsIcons/Error.svg';
import BatteryFailure from 'src/images/safetyPortPropsIcons/Battery_failure.svg';
import BedExtension from 'src/images/safetyPortPropsIcons/Bed_extension.svg';
import { ControlStateType, Groups } from './globalSettingsHelper';

export const controlInitState: ControlStateType[] = [
  {
    type: 'LINIS0002',
    icon: SafetyPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0004',
    icon: LateralTiltAngle,
    switchState: false,
    thresholdAllowed: true,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0005',
    icon: SiderailsPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0014',
    icon: SiderailsPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0015',
    icon: SiderailsPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0016',
    icon: SiderailsPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0017',
    icon: SiderailsPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0007',
    icon: Backrest,
    thresholdAllowed: true,
    switchState: true,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0003',
    icon: TrendelenburgAngle,
    switchState: false,
    thresholdAllowed: true,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0001',
    icon: PatientWeight,
    switchState: false,
    thresholdAllowed: true,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0006',
    icon: Backrest,
    switchState: false,
    threshold: 0,
    thresholdAllowed: true,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0008',
    icon: BrakesStatus,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0010',
    icon: BedExitAlarm,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0011',
    icon: Height,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0009',
    icon: BedExitMonitoring,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0012',
    icon: PatientOnBed,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0013',
    icon: Location,
    switchState: false,
    threshold: 0,
    group: Groups.bedManagement,
  },
  {
    type: 'LINIS0018',
    icon: Location,
    switchState: false,
    threshold: 0,
    group: Groups.bedManagement,
  },
  {
    type: 'LINIS0019',
    icon: Location,
    switchState: false,
    threshold: 0,
    group: Groups.bedManagement,
  },
  {
    type: 'LINIS0020',
    icon: SiderailsPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0021',
    icon: SiderailsPosition,
    switchState: false,
    threshold: 0,
    group: Groups.patientSafety,
  },
  {
    type: 'LINIS0022',
    icon: CalfrestAngle,
    switchState: false,
    thresholdAllowed: true,
    threshold: 1,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0023',
    icon: CalfrestLock,
    switchState: false,
    threshold: 0,
    group: Groups.utilization,
  },
  {
    type: 'LINIS0024',
    icon: BackrestLock,
    switchState: false,
    threshold: 0,
    group: Groups.utilization,
  },
  {
    type: 'LINIS0025',
    icon: HeightLock,
    switchState: false,
    threshold: 0,
    group: Groups.utilization,
  },
  {
    type: 'LINIS0026',
    icon: ThighrestLock,
    switchState: false,
    threshold: 0,
    group: Groups.utilization,
  },
  {
    type: 'LINIS0028',
    icon: Transfer,
    switchState: false,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0030',
    icon: Height,
    switchState: false,
    thresholdAllowed: true,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0031',
    icon: Height,
    switchState: false,
    thresholdAllowed: true,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0032',
    icon: Height,
    switchState: false,
    thresholdAllowed: true,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0033',
    icon: MobiliftRight,
    switchState: false,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0034',
    icon: MobiliftLeft,
    switchState: false,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0035',
    icon: MattressType,
    switchState: false,
    threshold: 0,
    group: Groups.bedManagement,
  },
  {
    type: 'LINIS0036',
    icon: BedExtension,
    switchState: false,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0037',
    icon: PressedButtonUsedController,
    switchState: false,
    threshold: 0,
    group: Groups.utilization,
  },
  {
    type: 'LINIS0039',
    icon: Alt,
    switchState: false,
    threshold: 0,
    group: Groups.respiratoryAndMobilization,
  },
  {
    type: 'LINIS0041',
    icon: Error,
    switchState: false,
    threshold: 0,
    group: Groups.bedManagement,
  },
  {
    type: 'LINIS0042',
    icon: BatteryFailure,
    switchState: false,
    threshold: 0,
    group: Groups.bedManagement,
  },
  {
    type: 'LINIS0045',
    icon: SafetyPosition,
    switchState: false,
    threshold: 0,
    group: Groups.bedManagement,
  },
];
