import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Popover, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/private-theming';
import { useState, MouseEvent, ReactNode, useContext, useMemo } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import Flex from 'src/components/Flex';
import { isMobile } from 'src/lib/mobileCheck';
import {
  darkTheme,
  lightTheme,
  mobileDarkTheme,
  mobileLightTheme,
} from '../themes';
import { DatapointInfoOption } from './Icons/datapointInfo';

type ThemeOption = 'dark' | 'light';

interface Props {
  title?: ReactNode | string;
  description: ReactNode | string;
  options?: DatapointInfoOption[];
  forceTheme?: ThemeOption;
}

const resolveTheme = (
  theme?: ThemeOption | null,
  svTheme?: string | null,
): ThemeOption => {
  if (theme != null) {
    return theme;
  }
  return svTheme === 'dark' ? 'dark' : 'light';
};

export const InfoPopover = ({
  title,
  forceTheme,
  description,
  options,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const { user } = useContext(AuthContext);
  const theme = resolveTheme(forceTheme, user?.userSetting?.svTheme);

  let reverseTheme = useMemo(() => {
    if (isMobile) {
      return theme === 'dark' ? mobileLightTheme : mobileDarkTheme;
    }
    return theme === 'dark' ? lightTheme : darkTheme;
  }, [theme]);

  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const hasOptions = options != null && options.length > 0;
  return (
    <>
      <InfoOutlinedIcon
        aria-owns={open ? 'info-popover' : undefined}
        aria-haspopup="true"
        sx={(theme) => ({ color: theme.palette.common.infoIcon })}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          sx: {
            borderRadius: '10px',
            padding: 2,
            minWidth: '300px',
            maxWidth: '400px',
            backgroundColor: reverseTheme.palette.background.paper,
            color: reverseTheme.palette.text.primary,
          },
        }}
      >
        <ThemeProvider theme={reverseTheme}>
          {title ? <Typography sx={{ pb: 1 }}>{title}</Typography> : null}
          <Typography>{description}</Typography>
          {hasOptions ? (
            <Flex sx={{ pt: 1, flexDirection: 'column' }}>
              {options.map((option, idx) => (
                <Flex
                  key={`info-options-${idx}`}
                  sx={{
                    flexDirection: 'right',
                    alignItems: 'center',
                  }}
                >
                  {option.icon}
                  <Box flex={1}>{option.text}</Box>
                </Flex>
              ))}
            </Flex>
          ) : null}
        </ThemeProvider>
      </Popover>
    </>
  );
};
