import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import ActionButtons from 'src/components/ActionButtons';
import { ListPageRoot, TopBar } from 'src/components/AdminList';
import { ConfirmModal } from 'src/components/ConfirmModal';
import FormikTextField from 'src/components/Formik/FormikTextField';
import {
  BedExitStatus,
  Position,
  PowerStatus,
  ResetPauseMutation,
  ResetPauseMutationVariables,
  ServerConnection,
  VirtualBed as VirtualBedData,
  VirtualBedVariables,
  pauseTimer as PauseTimerMutation,
  pauseTimerVariables as PauseTimerMutationVariables,
  deleteBedMutation,
  deleteBedMutationVariables,
  Mobilift,
  updateVirtualBed,
  updateVirtualBedVariables,
} from 'src/graphql/types';
import { useDirty } from 'src/hooks';
import { bed, beds, virtualBedQuery } from '../../../graphql/queries';
import SaveIcon from '@mui/icons-material/Save';
import FormikForm from 'src/components/Formik/FormikForm';
import { differenceInSeconds } from 'date-fns';
import {
  updateVirtualBed as updateVirtualBedQuery,
  resetPause as resetPauseQuery,
  pauseTimer,
  deleteBed,
} from 'src/graphql/mutations';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import { BasicDatapoitns } from './BasicDatapoints';
import { VirtualBedForm } from './VirtualBedForm';
import { ControllerDatapoitns } from './ControllerDatapoints';
import { AdditionalDatapoints } from './AdditionalDatapoints';
import { AdditionalSwitchDatapoints } from './AdditionalSwitchDatapoints';
import { object, string } from 'yup';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';

const getInitValue = (data: VirtualBedData | undefined) => {
  return {
    unitId: data?.virtualBed.unitId || '',
    tag: data?.virtualBed.tag || '',
    weight: data?.virtualBed.weight || 0,
    trendelenburg: data?.virtualBed.trendelenburg || 0,
    lateralTilt: data?.virtualBed.lateralTilt || 0,
    backrestAngle: data?.virtualBed.backrestAngle || 0,
    bedExitStatus: data?.virtualBed.bedExitStatus || BedExitStatus.NA,
    bedExitAlarm: data?.virtualBed.bedExitAlarm || false,
    brakes: data?.virtualBed.brakes || false,
    lowestPosition: data?.virtualBed.lowestPosition || false,
    powerStatus: data?.virtualBed.powerStatus || PowerStatus.OFF,
    serverConnection: data?.virtualBed.serverConnection || ServerConnection.OFF,
    leftHeadSiderail: data?.virtualBed.leftHeadSiderail || Position.DOWN,
    rightHeadSiderail: data?.virtualBed.rightHeadSiderail || Position.DOWN,
    leftSiderail: data?.virtualBed.leftSiderail || Position.DOWN,
    rightSiderail: data?.virtualBed.rightSiderail || Position.DOWN,

    mobiliftRight: data?.virtualBed.mobiliftRight || Mobilift.NA,
    mobiliftLeft: data?.virtualBed.mobiliftLeft || Mobilift.NA,
    calfrestAngle: data?.virtualBed.calfrestAngle || 0,

    leftLegColumn: data?.virtualBed.leftLegColumn || 0,
    rightLegColumn: data?.virtualBed.rightLegColumn || 0,
    headColumn: data?.virtualBed.headColumn || 0,

    mattressType: data?.virtualBed.mattressType || 0,
    altPhase: data?.virtualBed.altPhase || 0,
    errorCode: data?.virtualBed.errorCode || 0,

    backrestLock: data?.virtualBed.backrestLock || false,
    heightLock: data?.virtualBed.heightLock || false,
    calfrestLock: data?.virtualBed.calfrestLock || false,
    thighrestLock: data?.virtualBed.thighrestLock || false,
    bedExtension: data?.virtualBed.bedExtension || false,
    transfer: data?.virtualBed.transfer || false,
    batteryFailure: data?.virtualBed.batteryFailure || false,

    controller: data?.virtualBed.controller || 0,
    buttonPressed: data?.virtualBed.buttonPressed || 0,
  };
};

const ANGLE_REGEX = /^-?[0-9]{1,2}$/g;
const COLUMN_REGEX = /^[0-9]{1,4}$/g;

const vbSchema = object({
  tag: string().matches(/^[0-9]{0,15}$/g, t`Must have up to 15 digits.`),
  weight: string().matches(/^[0-9]{1,3}$/g, t`Value between 0 and 999.`),
  backrestAngle: string().matches(ANGLE_REGEX, t`Value between -99 and 99.`),
  lateralTilt: string().matches(ANGLE_REGEX, t`Value between -99 and 99.`),
  trendelenburg: string().matches(ANGLE_REGEX, t`Value between -99 and 99.`),
  calfrestAngle: string().matches(ANGLE_REGEX, t`Value between -99 and 99.`),

  leftLegColumn: string().matches(COLUMN_REGEX, t`Value between 0 and 9999.`),
  rightLegColumn: string().matches(COLUMN_REGEX, t`Value between 0 and 9999.`),
  headColumn: string().matches(COLUMN_REGEX, t`Value between 0 and 9999.`),
});

export const VirtualBed = () => {
  const params = useParams<{ unitId: string }>();
  const navigate = useNavigate();
  const [isPaused, setPaused] = useState(false);

  const { data } = useQuery<VirtualBedData, VirtualBedVariables>(
    virtualBedQuery,
    {
      variables: {
        unitId: params.unitId!,
      },
      skip: !params.unitId,
    },
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateVirtualBed] = useSnackbarMutation<
    updateVirtualBed,
    updateVirtualBedVariables
  >(updateVirtualBedQuery, {
    onCompleted: (data) => {
      setPaused(
        data?.updateVirtualBed.smPause
          ? differenceInSeconds(
              new Date(data.updateVirtualBed.smPause),
              new Date(),
            ) > 0
          : false,
      );
    },
    refetchQueries: () => [
      {
        query: bed,
        variables: {
          unitId: params.unitId,
        },
      },
      {
        query: virtualBedQuery,
        variables: {
          unitId: params.unitId!,
        },
      },
    ],
  });

  const [resetPause] = useMutation<
    ResetPauseMutation,
    ResetPauseMutationVariables
  >(resetPauseQuery, {
    variables: {
      virtualBedId: data?.virtualBed.id || '',
    },
  });
  const [pause] = useMutation<PauseTimerMutation, PauseTimerMutationVariables>(
    pauseTimer,
    {
      variables: {
        bedUnitId: data?.virtualBed.unitId || '',
        seconds: 60 * 60 * 24 * 14,
      },
      refetchQueries: () => [
        { query: virtualBedQuery, variables: { unitId: params.unitId! } },
      ],
    },
  );

  const [removeVirtualBed] = useSnackbarMutation<
    deleteBedMutation,
    deleteBedMutationVariables
  >(deleteBed, undefined, {
    successMessage: t`Removed.`,
  });

  useEffect(() => {
    setPaused(
      data?.virtualBed.smPause
        ? differenceInSeconds(new Date(data.virtualBed.smPause), new Date()) > 0
        : false,
    );
  }, [data]);

  const onSubmit = async (
    values: VirtualBedForm,
    formikHelpers: FormikHelpers<VirtualBedForm>,
  ) => {
    const vbId = data?.virtualBed.id;
    if (!vbId) {
      return;
    }
    if (!formik.isValid) {
      return;
    }

    await updateVirtualBed({
      variables: {
        input: {
          ...values,
          id: vbId,
          weight: Number(values.weight || 0),
          backrestAngle: Number(values.backrestAngle || 0),
          trendelenburg: Number(values.trendelenburg || 0),
          lateralTilt: Number(values.lateralTilt || 0),
          calfrestAngle: Number(values.calfrestAngle || 0),
          leftLegColumn: Number(values.leftLegColumn || 0),
          rightLegColumn: Number(values.rightLegColumn || 0),
          headColumn: Number(values.headColumn || 0),
          mattressType: Number(values.mattressType || 0),
          altPhase: Number(values.altPhase || 0),
          errorCode: Number(values.errorCode || 0),
          controller: Number(values.controller || 0),
          buttonPressed: Number(values.buttonPressed || 0),
        },
      },
    });
  };

  const formik = useFormik<VirtualBedForm>({
    initialValues: getInitValue(data),
    enableReinitialize: true,
    onSubmit,
    validationSchema: vbSchema,
    validateOnBlur: true,
  });
  const { isDirty, setInitValue, resetDirty } = useDirty(formik.values);

  useEffect(() => {
    if (data?.virtualBed) {
      setInitValue(getInitValue(data));
    }
  }, [data, setInitValue]);

  if (data == null || data.virtualBed == null) {
    return null;
  }

  const handleRemoveBed = () => {
    if (data?.virtualBed.unitId) {
      removeVirtualBed({
        variables: {
          bedUnitId: data.virtualBed.unitId,
        },
        onCompleted: () => {
          navigate('/admin/beds');
        },
        refetchQueries: () => [
          {
            query: beds,
          },
        ],
      });
    }
  };

  const save = async () => {
    await formik.submitForm();
    if (formik.isValid) {
      resetDirty();
    }
  };
  const saveAndClose = async () => {
    await formik.submitForm();
    if (formik.isValid) {
      resetDirty();
      setTimeout(() => navigate(`/admin/beds`), 300);
    }
  };

  return (
    <ListPageRoot>
      <TopBar>
        <ActionButtons
          onSave={save}
          onDiscard={() => formik.setValues(getInitValue(data))}
          onDelete={() => setShowDeleteModal(true)}
          backButton={t`Back`}
          leftButtons={[
            {
              id: 'data-simulation',
              label: t`Data simulator`,
              color: 'primary',
              variant: 'contained',
              showWhen: 'notDirty',
              startIcon: <AnalyticsOutlinedIcon />,
              onClick: () => navigate(`/admin/beds/${params.unitId}/simulate`),
            },
          ]}
          rightButtons={[
            {
              id: 'save-and-close',
              label: t`Save changes and close`,
              color: 'primary',
              variant: 'contained',
              showWhen: 'dirty',
              startIcon: <SaveIcon />,
              onClick: saveAndClose,
            },
          ]}
          isDirty={isDirty}
        />
      </TopBar>
      <FormikForm formik={formik} sx={{ flexDirection: 'column' }}>
        <Section
          sx={{
            gap: 2,
          }}
        >
          <FormikTextField
            name="unitId"
            variant="outlined"
            label={t`Integration Modul ID`}
            disabled
          />
          <FormikTextField
            name="tag"
            variant="outlined"
            label={t`Tag`}
            onBlur={() => formik.setFieldTouched('tag', true)}
          />
          <Box sx={{ marginTop: '39.5px' }}>
            {isPaused ? (
              <Button
                size="large"
                variant="contained"
                onClick={async () => {
                  await resetPause();
                  setPaused(false);
                }}
                color="primary"
              >
                {t`Unpause`}
              </Button>
            ) : (
              <Button
                variant="contained"
                size="large"
                onClick={async () => {
                  await pause();
                  const newValues = getInitValue(data);
                  formik.setFieldValue('bedExitAlarm', newValues.bedExitAlarm);
                  formik.setFieldValue(
                    'bedExitStatus',
                    newValues.bedExitStatus,
                  );
                  formik.setFieldValue('weight', newValues.weight);
                  setPaused(true);
                }}
                color="primary"
              >
                {t`Pause`}
              </Button>
            )}
          </Box>
        </Section>
        <Divider sx={{ mx: 5 }} />
        <Section>
          <SectionTitle>{t`Basic datapoints`}</SectionTitle>
        </Section>
        <Section
          sx={{
            gap: 2,
          }}
        >
          <BasicDatapoitns
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
          />
        </Section>
        <Divider sx={{ mx: 5 }} />
        <Section>
          <SectionTitle>{t`additional datapoints`}</SectionTitle>
        </Section>
        <Section
          sx={{
            gap: 4,
            flexDirection: 'column',
          }}
        >
          <AdditionalSwitchDatapoints />
          <AdditionalDatapoints
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
          />
          <ControllerDatapoitns
            values={formik.values}
            setFieldValue={formik.setFieldValue}
          />
        </Section>
      </FormikForm>

      <ConfirmModal
        title={t`Delete Bed`}
        message={
          <Trans>
            Do you really want to <b>delete Bed {data?.virtualBed.unitId}?</b>
          </Trans>
        }
        confirmButtonText={t`Yes, I want to delete`}
        cancelButtonText={t`Cancel`}
        onConfirm={handleRemoveBed}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </ListPageRoot>
  );
};

const SectionTitle = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: ${(props) => props.theme.spacing(4)};
  margin-bottom: 0;
`;

SectionTitle.defaultProps = {
  variant: 'h3',
};

const Section = styled(Box)`
  display: flex;
  gap: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  margin-left: ${(props) => props.theme.spacing(5)};
  margin-right: ${(props) => props.theme.spacing(5)};
`;
