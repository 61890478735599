import { DemoState, NurseCallState } from './bedStates';
import { NurseCallSystem } from './NurseCallSystem';
import { MobileApp } from './MobileApp';
import { TabletTiles } from './TabletTiles';
import { DataItem } from './DataItem';
import { BigTile } from './BigTile';
import { SmallTile } from './SmallTile';

interface Props {
  demoState: DemoState;
}

const getType = (state: NurseCallState) => {
  if (state.bedExitAlarm) {
    return 'bed-exit';
  }
  if (state.safetyPositionWarning) {
    return 'safety-position';
  }
  if (state.codeBlue) {
    return 'code-blue';
  }
  if (state.unplugged) {
    return 'unplugged';
  }
  return 'inactive';
};

export const Systems = ({ demoState }: Props) => {
  return (
    <div style={styles.container}>
      <div style={styles.title}>{'Electronic Medical Record & Nurse Call'}</div>
      <div style={styles.tablet}>
        <TabletTiles
          content={
            <>
              <div style={{ position: 'absolute', top: 8, left: 8 }}>
                <BigTile
                  type={getType(demoState.state)}
                  topLeft="333"
                  topRight="All"
                />
              </div>
              <div style={{ position: 'absolute', top: 8, right: 8 }}>
                <BigTile type="code-blue" topLeft="332" topRight="All" />
              </div>
              <div style={{ position: 'absolute', bottom: 8, left: 8 }}>
                <BigTile type="staff-request" topLeft="315" topRight="All" />
              </div>
              <div style={{ position: 'absolute', bottom: 8, right: 8 }}>
                <BigTile type="routine" topLeft="312" topRight="All" />
              </div>
            </>
          }
        />
      </div>
      <div style={styles.nurseCallSystem}>
        <NurseCallSystem
          content={
            <div style={styles.dataTable}>
              <div style={styles.dataTitle}>Data history</div>
              <div style={styles.dataHeader}>
                <span style={styles.headerItem}>Bed Unit Id</span>
                <span style={styles.headerItem}>Message Id</span>
                <span style={styles.headerItem}>SP Prop</span>
                <span style={{ ...styles.headerItem, width: 140 }}>Value</span>
                <span style={{ ...styles.headerItem, width: 140 }}>
                  Timestamp
                </span>
              </div>
              {Object.keys(demoState.values).map((prop) => (
                <DataItem
                  key={prop}
                  unitId={demoState.unitId}
                  msgId={demoState.msgId}
                  date={demoState.date}
                  prop={prop}
                  value={demoState.values[prop]}
                />
              ))}
            </div>
          }
        />
      </div>
      <div style={styles.mobile}>
        <MobileApp
          content={
            <div style={{ paddingTop: 16 }}>
              <SmallTile
                type={getType(demoState.state)}
                topLeft="333"
                topRight="All"
              />
              <SmallTile type="code-blue" topLeft="332" topRight="All" />
              <SmallTile type="staff-request" topLeft="315" topRight="All" />
              <SmallTile type="routine" topLeft="312" topRight="All" />
            </div>
          }
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative' as 'relative',
    width: '100%',
    height: 630,
  },
  title: {
    marginTop: 64,
    paddingLeft: 12,
  },
  mobile: {
    position: 'absolute' as 'absolute',
    top: 270,
    left: 480,
  },
  nurseCallSystem: {
    position: 'absolute' as 'absolute',
    top: 130,
  },
  tablet: {
    position: 'absolute' as 'absolute',
    left: 400,
    top: 0,
  },
  dataTable: {
    width: 650,
    fontSize: 10,
  },
  dataTitle: {
    fontSize: 12,
  },
  dataHeader: {
    padding: '0 4px',
  },
  headerItem: {
    display: 'inline-block' as 'inline-block',
    width: 100,
    color: 'rgba(0, 0, 0, 0.54)',
  },
};
