import { Paper, styled } from '@mui/material';

const ListPageContent = styled(Paper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  box-shadow: none;
  position: relative;
`;

ListPageContent.defaultProps = {
  elevation: 2,
};

export default ListPageContent;
