/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import ActionButtons from 'src/components/ActionButtons';
import {
  Checkbox,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { t } from '@lingui/macro';
import { useState } from 'react';
import Flex from 'src/components/Flex';
import GetAppIcon from '@mui/icons-material/GetApp';
import html2canvas from 'html2canvas';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import {
  AddReportingReportToDashboard,
  AddReportingReportToDashboardVariables,
  RemoveReportingReportFromDashboard,
  RemoveReportingReportFromDashboardVariables,
  ReportingReportsQuery,
} from 'src/graphql/types';
import {
  addReportingReportToDashboard,
  removeReportingReportFromDashboard,
} from 'src/graphql/mutations';
import {
  reportingDashboardQuery,
  reportingReportsQuery,
} from 'src/graphql/queries';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

interface Props {
  onOpenDeleteConfirm: () => void;
  dashboardId: string;
  addedReportIds?: string[] | null;
}

const ReportDetailDashboardActionButtons = ({
  onOpenDeleteConfirm,
  dashboardId,
  addedReportIds,
}: Props) => {
  const [moreMenuAnchor, setMoreMenuAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [reportMenuEl, setReportMenuEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const { data: reportData } = useQuery<ReportingReportsQuery>(
    reportingReportsQuery,
  );

  const [addReportToDashboard] = useSnackbarMutation<
    AddReportingReportToDashboard,
    AddReportingReportToDashboardVariables
  >(addReportingReportToDashboard, undefined, {
    successMessage: t`Succesfully added`,
  });

  const [removeReportFromDashboard] = useSnackbarMutation<
    RemoveReportingReportFromDashboard,
    RemoveReportingReportFromDashboardVariables
  >(removeReportingReportFromDashboard, undefined, {
    successMessage: t`Succesfully removed`,
  });

  const handleExportAsJpg = () => {
    const el = document.getElementById('reports-layout');
    if (el) {
      html2canvas(el).then((canvas) => {
        var img = canvas.toDataURL('image/png');
        const link = document.getElementById('export-link');
        if (link) {
          link.setAttribute('download', 'report.jpg');
          link.setAttribute('href', img);
          link.click();
        }
      });
    }
  };

  const handleAddReportToDashboard = (reportId: string) => {
    if (addedReportIds?.includes(reportId)) {
      removeReportFromDashboard({
        variables: {
          input: {
            reportId: reportId,
            dashboardId,
          },
        },
        refetchQueries: [
          {
            query: reportingDashboardQuery,
            variables: { id: dashboardId },
          },
        ],
      });
      return;
    }

    addReportToDashboard({
      variables: {
        input: {
          reportId,
          dashboardId,
        },
      },
      refetchQueries: [
        {
          query: reportingDashboardQuery,
          variables: { id: dashboardId },
        },
      ],
    });
  };

  return (
    <Flex alignItems="center">
      <a id="export-link" />
      <ActionButtons
        rightButtons={[
          {
            id: 'add-report',
            onClick: (e) => setReportMenuEl(e.currentTarget),
            variant: 'outlined',
            label: t`Report Selection`,
            endIcon: <KeyboardArrowDownIcon />,
          },
          {
            id: 'show-more',
            onClick: (e) => setMoreMenuAnchor(e.currentTarget),
            variant: 'outlined',
            label: <MoreHorizIcon />,
            sx: { px: 1, minWidth: 'auto' },
          },
        ]}
      />
      <Menu
        anchorEl={reportMenuEl}
        open={!!reportMenuEl}
        onClose={() => setReportMenuEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {reportData?.reportingReports?.map((report) => (
          <MenuItem
            key={report.id}
            onClick={() => handleAddReportToDashboard(report.id)}
          >
            <Checkbox checked={addedReportIds?.includes(report.id)} />
            {report.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem component={Link} to="/reporting/reports/create">
          {t`New Report`}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={moreMenuAnchor}
        open={!!moreMenuAnchor}
        onClose={() => setMoreMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleExportAsJpg}>
          <ListItemIcon>
            <GetAppIcon />
          </ListItemIcon>
          <ListItemText>{t`Export as JPG`}</ListItemText>
        </MenuItem>
        <MenuItem onClick={onOpenDeleteConfirm}>
          <ListItemIcon>
            <DeleteForeverIcon />
          </ListItemIcon>
          <ListItemText>{t`Delete`}</ListItemText>
        </MenuItem>
      </Menu>
    </Flex>
  );
};

export default ReportDetailDashboardActionButtons;
