import { Box, styled } from '@mui/material';
import CareNoteItem from './CareNoteItem';
import { PatientCareNotes, PatientCareNotesVariables } from 'src/graphql/types';
import { useQuery } from '@apollo/client';
import { patientCareNotes } from 'src/graphql/queries';
import AddCareNote from './AddCareNote';

interface Props {
  patientSessionId: string;
  disable?: boolean;
  sessionStart?: string | null;
}

const CareNotes = ({ patientSessionId, disable, sessionStart }: Props) => {
  const { data } = useQuery<PatientCareNotes, PatientCareNotesVariables>(
    patientCareNotes,
    {
      variables: {
        id: patientSessionId,
      },
    },
  );
  return (
    <Box width="100%">
      {!disable && (
        <AddCareNote
          sessionStart={sessionStart}
          patientSessionId={patientSessionId}
        />
      )}
      <Container height={disable ? 398 : 330}>
        {data?.patientSession?.careNotes.map(
          (careNote) =>
            careNote && (
              <CareNoteItem
                patientSessionId={patientSessionId}
                disable={disable}
                key={`careNote-${careNote?.id}`}
                data={{
                  date: careNote?.dateCreated,
                  note: careNote?.note,
                  cofirmDate: new Date(careNote.dateConfirmed),
                  id: careNote.id,
                }}
              />
            ),
        )}
      </Container>
    </Box>
  );
};

export default CareNotes;

const Container = styled(Box)({
  overflowY: 'auto',
  paddingRight: '16px',
});
