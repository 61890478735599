import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckboxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <circle
          cx="12"
          cy="12"
          r="11.25"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M11.3309 15.052L10.778 14.5452L11.3309 15.052ZM8.38228 15.052L7.82941 15.5588L8.38228 15.052ZM17.1609 7.58207L10.778 14.5452L11.8838 15.5588L18.2666 8.59566L17.1609 7.58207ZM8.93514 14.5452L6.83802 12.2574L5.73229 13.271L7.82941 15.5588L8.93514 14.5452ZM10.778 14.5452C10.2826 15.0856 9.43056 15.0856 8.93514 14.5452L7.82941 15.5588C8.91934 16.7478 10.7938 16.7478 11.8838 15.5588L10.778 14.5452Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
