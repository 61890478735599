import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, styled, ThemeProvider, Typography } from '@mui/material';
import { cs, de, es, fr, ptBR } from 'date-fns/locale';
import { Outlet } from 'react-router-dom';
import Flex from 'src/components/Flex';
import { UserRole } from 'src/graphql/types';
import { isMobile } from 'src/lib/mobileCheck';
import DirtyProvider from 'src/providers/DirtyProvider';
import { adminTheme } from 'src/themes';
import { useNavigate } from 'react-router';
import NavigationBar, {
  NAVIGATION_BAR_WIDTH,
} from '../SafetyView/components/NavigationBar';
import ReportingMenu from './ReportingMenu';
import AppVersion from 'src/components/AppVersion';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';

const locales = { cs, fr, de, es, 'pt-br': ptBR };

const ReportingLayout = () => {
  const { user, config: configData } = useContext(AuthContext);
  const { i18n } = useLingui();
  const navigate = useNavigate();
  if (isMobile) {
    navigate('/safety-port/dashboard');
  }
  return (
    <ThemeProvider theme={adminTheme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={locales[i18n.locale]}
      >
        <DirtyProvider>
          <LayoutWrapper>
            <NavigationBar
              isAdmin={user?.role === UserRole.ADMIN}
              reportingAccess={
                user?.reportingModuleAccess &&
                configData?.superConfig?.reportingModuleAccess
              }
            />
            <Flex
              flexDirection="column"
              sx={{
                width: `calc( 100% - ${NAVIGATION_BAR_WIDTH})`,
                height: '100%',
              }}
            >
              <TopBar>
                <Typography variant="h1">{t`Reporting`}</Typography>
                <AppVersion type="reporting" />
              </TopBar>
              <Flex flexGrow={1} flexShrink={0} minHeight={0} height={0}>
                <ReportingMenu boxShadow="inset 0px 8px 9px -9px rgba(0,0,0,0.25)" />
                <Container boxShadow="inset 0px 0px 9px 0px rgba(0,0,0,0.25)">
                  <Outlet />
                </Container>
              </Flex>
            </Flex>
          </LayoutWrapper>
        </DirtyProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default ReportingLayout;

const Container = styled(Box)(
  ({ theme: { spacing, palette } }) => `
  height: 100%;
  flex-grow: 1;
  padding: ${spacing(2)};
  overflow: auto;
  color: ${palette.text.primary};
`,
);

const LayoutWrapper = styled(Flex)(
  ({ theme: { palette } }) => `
    min-height: 0;
    min-width: 0;
    height: 100vh;
    width: 100%;
    background-color: ${palette.grey[200]};
    
    box-sizing: border-box;
    & *, & *:before, & *:after {
        box-sizing: inherit;
    }
  `,
);

const TopBar = styled(Flex)(
  ({ theme: { palette, spacing } }) => `
    width: 100%;
    padding: ${spacing(2)} ${spacing(3)};
    background-color: ${palette.background.paper};
    justify-content: space-between;
    color: ${palette.text.primary};
  `,
);
