const NON_NUMBER_REGEXP = /[^\d]/g;

export const normalizeNumberInt = (
  value?: string,
  min?: number,
  max?: number,
) => {
  if (!value) return '';
  const signMultiplicator = value[0] === '-' ? -1 : 1;
  let newValue =
    Number(value.replaceAll(NON_NUMBER_REGEXP, '')) * signMultiplicator;

  if (min != null) {
    newValue = Math.max(newValue, min);
  }
  if (max != null) {
    newValue = Math.min(newValue, max);
  }
  return newValue.toString();
};
