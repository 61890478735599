import snoozeSvg from 'src/images/snooze-demo.svg';
import pauseSvg from 'src/images/pause-demo.svg';

interface Props {
  topRight: string;
  topLeft: string;
  type:
    | 'safety-position'
    | 'bed-exit'
    | 'code-blue'
    | 'unplugged'
    | 'inactive'
    | 'staff-request'
    | 'routine';
}
export const BigTile = ({ type, topLeft, topRight }: Props) => {
  return (
    <div style={styles.container}>
      <div style={styles.top}>
        <span>{topLeft}</span>
        <span style={{ color: 'lightgrey' }}>{topRight}</span>
      </div>
      <div style={styles[type]}>
        <span style={{ lineHeight: '16px' }}>{texts[type]}</span>
        {type === 'safety-position' && (
          <img alt="Snooze" width={10} height={10} src={snoozeSvg} />
        )}
        {type === 'bed-exit' && (
          <img alt="Pause snooze" width={10} height={10} src={pauseSvg} />
        )}
      </div>
    </div>
  );
};

const texts = {
  'safety-position': 'Not in safety position',
  'bed-exit': 'Bed Exit Alarm',
  'code-blue': 'Code blue',
  unplugged: 'Bed unplugged',
  inactive: 'Bed in safe position',
  'staff-request': 'Staff request',
  routine: 'Routine',
};

const styles = {
  container: {
    width: 96,
    fontSize: 8,
  },
  top: {
    margin: '0 8px',
    border: 'lightgrey solid 1px',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: 'flex' as 'flex',
    justifyContent: 'space-between' as 'space-between',
    padding: '0 4px',
  },
  inactive: {
    borderRadius: 4,
    backgroundColor: '#007E7B',
    marginTop: -1,
    height: 40,
    color: '#fff',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    padding: 4,
  },
  'safety-position': {
    borderRadius: 4,
    backgroundColor: '#F7B321',
    marginTop: -1,
    height: 40,
    color: '#fff',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    padding: 4,
  },
  'bed-exit': {
    borderRadius: 4,
    backgroundColor: '#FD434B',
    marginTop: -1,
    height: 40,
    color: '#fff',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    padding: 4,
  },
  unplugged: {
    borderRadius: 4,
    backgroundColor: '#7F7F7F',
    marginTop: -1,
    height: 40,
    color: '#fff',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    padding: 4,
  },
  'code-blue': {
    borderRadius: 4,
    backgroundColor: '#2F54E9',
    marginTop: -1,
    height: 40,
    color: '#fff',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    padding: 4,
  },
  'staff-request': {
    borderRadius: 4,
    backgroundColor: '#F7B321',
    marginTop: -1,
    height: 40,
    color: '#fff',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    padding: 4,
  },
  routine: {
    borderRadius: 4,
    backgroundColor: '#7F7F7F',
    marginTop: -1,
    height: 40,
    color: '#fff',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    padding: 4,
  },
};
