import { useMutation, useQuery } from '@apollo/client';
import { Box, Tabs, styled } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import Flex from 'src/components/Flex';
import SpDashboardContent from 'src/pages/Admin/SpDashboard/SpDashboardContent';
import {
  addConnectionMutation,
  deleteConnectionMutation,
  editConnectionMutation,
  upsertConfig,
} from 'src/graphql/mutations';
import { configQuery, connectionsQuery } from 'src/graphql/queries';
import {
  AddConnection,
  AddConnectionVariables,
  ConnectionsQuery,
  DeleteConnection,
  DeleteConnectionVariables,
  EditConnection,
  EditConnectionVariables,
  SafetyPortProps,
  upsertConfigMutation,
  upsertConfigMutationVariables,
  WeightUnits,
} from 'src/graphql/types';
import {
  isBedManagementEnabled,
  isPatientEnabled,
  isRespiratoryMobilizationEnabled,
  isSpDashboardEnabled,
  isSpDataEnabled,
  isUtilizationEnabled,
} from 'src/lib/configHelper';
import { useDirtyContext } from 'src/providers/DirtyProvider';
import { controlInitState } from './controlInitState';
import DataTransferSettings from './DataTransferSettings';
import GeneralSettings from './GeneralSettings';
import {
  ConnectionSettingsState,
  ControlStateType,
  initSettingsState,
  SettingsState,
} from './globalSettingsHelper';
import { t } from '@lingui/macro';
import { AuthContext } from 'src/auth/AuthProvider';
import { ThinTab } from '../../../components/ThinTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`setting-tabpanel-${index}`}
      aria-labelledby={`setting-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const GlobalSettings = () => {
  const { config: configData } = useContext(AuthContext);
  const { data: connectionsData } =
    useQuery<ConnectionsQuery>(connectionsQuery);

  const [addConnection] = useMutation<AddConnection, AddConnectionVariables>(
    addConnectionMutation,
    {
      refetchQueries: () => [{ query: connectionsQuery }],
      awaitRefetchQueries: true,
    },
  );

  const [editConnection] = useMutation<EditConnection, EditConnectionVariables>(
    editConnectionMutation,
    {
      refetchQueries: () => [{ query: connectionsQuery }],
      awaitRefetchQueries: true,
    },
  );
  const [editConfig] = useMutation<
    upsertConfigMutation,
    upsertConfigMutationVariables
  >(upsertConfig, {
    refetchQueries: () => [{ query: configQuery }],
  });
  const [deleteConnection] = useMutation<
    DeleteConnection,
    DeleteConnectionVariables
  >(deleteConnectionMutation, {
    refetchQueries: () => [{ query: connectionsQuery }],
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [settings, setSettings] = useState<SettingsState>({
    hospitalName:
      configData?.config?.hospitalName || initSettingsState.hospitalName,
    defaultPause:
      configData?.config?.defaultPause || initSettingsState.defaultPause,
    unitSystem: configData?.config?.unitSystem || initSettingsState.unitSystem,
    noteConfirmationPeriod:
      configData?.config?.noteConfirmationPeriod ||
      initSettingsState.noteConfirmationPeriod,
    logoURL: configData?.config?.logoURL || undefined,
  });
  const [connectionSettings, setConnectionSettings] =
    useState<ConnectionSettingsState | null>(null);
  const [selectedConnectionId, setSelectedConnectionId] = useState<
    string | null
  >(null);
  const [controlState, setControlState] =
    useState<ControlStateType[]>(controlInitState);

  const getSelectedConnection = useCallback(() => {
    return connectionsData?.connections.find(
      (cfg) => cfg.id === selectedConnectionId,
    );
  }, [connectionsData, selectedConnectionId]);

  const spDataEnabled = isSpDataEnabled(configData);
  const spDashboardEnabled = isSpDashboardEnabled(configData);
  const { actionGuard } = useDirtyContext();

  // setup conneciton (including props)
  useEffect(() => {
    const selectedConnection = getSelectedConnection();
    if (!selectedConnection) {
      setConnectionSettings(null);
      return;
    }
    setConnectionSettings(selectedConnection);
    const data = controlInitState.map((cell) => {
      const dataPackage = selectedConnection.props.find((dataPack) => {
        return (dataPack as any).key === cell.type;
      });
      return {
        ...cell,
        switchState: dataPackage?.enabled ?? false,
        threshold: dataPackage?.threshold ?? null,
      };
    });
    setControlState(data);
  }, [getSelectedConnection, setConnectionSettings, setControlState]);

  useEffect(() => {
    const data = {
      hospitalName: configData?.config?.hospitalName || '',
      defaultPause: configData?.config?.defaultPause || 0,
      unitSystem: configData?.config?.unitSystem || WeightUnits.METRIC,
      noteConfirmationPeriod: configData?.config?.noteConfirmationPeriod || 5,
      logoURL: configData?.config?.logoURL || undefined,
    };
    setSettings(data);
  }, [configData]);

  const spEnabledGroups = {
    patientEnabled: isPatientEnabled(configData),
    bedManagementEnabled: isBedManagementEnabled(configData),
    respiratoryMobilizationEnabled:
      isRespiratoryMobilizationEnabled(configData),
    utilizationEnabled: isUtilizationEnabled(configData),
  };

  const getThreshold = (item: ControlStateType) => {
    if (
      item.threshold != null &&
      item.thresholdAllowed &&
      Number(item.threshold) > 0
    ) {
      return Number(item.threshold);
    }
    return null;
  };

  const addNewConnection = async (name: string) => {
    return addConnection({
      variables: {
        name,
      },
    });
  };
  const deleteConnectionById = async (id: string) => {
    return deleteConnection({
      variables: {
        id,
      },
    });
  };

  const {
    hospitalName,
    unitSystem,
    logoURL,
    noteConfirmationPeriod,
    defaultPause,
  } = settings;

  const saveSettingsHandler = (conSettings?: ConnectionSettingsState) => {
    if (tabIndex === 0 || tabIndex === 2) {
      return editConfig({
        variables: {
          config: {
            hospitalName,
            defaultPause,
            unitSystem,
            noteConfirmationPeriod,
          },
        },
      });
    }
    const correctConnectionState = conSettings || connectionSettings;
    if (tabIndex === 1 && correctConnectionState) {
      const data = controlState.map((item) => ({
        id: item.type as SafetyPortProps,
        enabled: item.switchState,
        threshold: getThreshold(item),
      }));

      const connectionToUpdate = {
        id: correctConnectionState.id,
        enabled: correctConnectionState.enabled,
        name: correctConnectionState.name,
        ip: correctConnectionState.ip,
        port: correctConnectionState.port,
        synchronous: correctConnectionState.synchronous,
        syncPeriod: correctConnectionState.syncPeriod,
        props: data,
      };
      return editConnection({
        variables: {
          connection: connectionToUpdate,
        },
      });
    }
    return null;
  };

  const a11yProps = (idx: number) => {
    return {
      id: `simple-tab-${idx}`,
      'aria-controls': `simple-tabpanel-${idx}`,
    };
  };

  const changeTab = (_: any, value: number) => {
    actionGuard(() => setTabIndex(value));
  };

  return (
    <Container>
      <TabsContainer>
        <SettingsTabs
          value={tabIndex}
          onChange={changeTab}
          aria-label="settings-tabs"
        >
          <ThinTab label={t`General`} {...a11yProps(0)} />
          <ThinTab label={t`SP Data Transfer`} {...a11yProps(1)} />
          {spDashboardEnabled && (
            <ThinTab label={t`SP Dashboard`} {...a11yProps(2)} />
          )}
        </SettingsTabs>
      </TabsContainer>
      <TabContent>
        <TabPanel value={tabIndex} index={0}>
          <FormPaper>
            <GeneralSettings
              setSettings={setSettings}
              hospitalName={hospitalName}
              unitSystem={unitSystem}
              serverIp={configData?.config?.serverIp}
              onSave={saveSettingsHandler}
              logoURL={logoURL}
            />
          </FormPaper>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <FormPaper>
            {spDataEnabled && (
              <DataTransferSettings
                setControlState={setControlState}
                controlState={controlState}
                spEnabledGroups={spEnabledGroups}
                connections={connectionsData?.connections || []}
                onSave={saveSettingsHandler}
                connectionSettings={connectionSettings}
                setConnectionSettings={setConnectionSettings}
                selectedConnectionId={selectedConnectionId}
                setSelectedConnectionId={setSelectedConnectionId}
                addConnection={addNewConnection}
                deleteConnection={deleteConnectionById}
              />
            )}
          </FormPaper>
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <FormPaper>
            {spDashboardEnabled && (
              <SpDashboardContent
                npcSettingProps={{
                  setSettings: setSettings,
                  noteConfirmationPeriod: settings.noteConfirmationPeriod,
                  onSave: saveSettingsHandler,
                }}
              />
            )}
          </FormPaper>
        </TabPanel>
      </TabContent>
    </Container>
  );
};

const Container = styled(Box)`
  width: 100%;
  flex: 1;
  overflow: auto;
`;

const TabsContainer = styled(Flex)(
  ({ theme: { palette } }) => `
  border-bottom: 1px solid ${palette.grey[300]};
  position: absolute;
  left: 0;
  right: 0;
  top: 84px;
  justify-content: center;
`,
);

const TabContent = styled(Box)(
  ({ theme: { spacing } }) => `
  padding: ${spacing(4)} ${spacing(5)} ${spacing(2.5)};
`,
);

const SettingsTabs = styled(Tabs)(
  ({ theme: { spacing } }) => `
  border-bottom: 1px solid transparent;
  margin: 0 ${spacing(5)};
  padding-bottom: ${spacing(2)};
`,
);

const FormPaper = styled(Box)(
  ({ theme: { spacing } }) => `
  width: 100%;
  padding-right: ${spacing(4.5)};
`,
);

export default GlobalSettings;
