import { AccordionSummary, styled } from '@mui/material';

export const AccordionSectionHeader = styled(AccordionSummary)`
  flex-direction: row-reverse;
  & .MuiAccordionSummary-expandIcon {
    margin-left: -24px;
    & .MuiIconButton-edgeEnd {
      margin-right: 0px;
    }
  }
`;
