import React, { useState } from 'react';

export interface Modal {
  type: ModalType | null;
  bedId?: string;
}

export type ModalType =
  | 'settings'
  | 'datapoints'
  | 'notes'
  | 'insight'
  | 'export';

interface MobileModalProviderType {
  modal: Modal;
  showModal: (type: ModalType, bedId?: string) => void;
  hideModal: () => void;
}

interface Props {
  children?: React.ReactNode;
}

export const MobileModalContext = React.createContext<MobileModalProviderType>({
  modal: {
    type: null,
  },
  showModal: () => {},
  hideModal: () => {},
});

const MobileModalProvider = ({ children }: Props) => {
  const [currentModal, setCurrentModal] = useState<Modal>({
    type: null,
  });

  const showModal = (type: ModalType, bedId?: string) => {
    setCurrentModal({
      type,
      bedId: bedId,
    });
  };

  const hideModal = () => {
    setCurrentModal({
      type: null,
      bedId: undefined,
    });
  };

  const context = {
    modal: currentModal,
    showModal,
    hideModal,
  };

  return (
    <MobileModalContext.Provider value={context}>
      {children}
    </MobileModalContext.Provider>
  );
};
export default MobileModalProvider;
