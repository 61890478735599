import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import {
  copyWorkspaceMutation,
  createRoom,
  createWard,
  createWorkspace,
  deleteRoom,
  deleteWard,
  deleteWorkspace,
  updateRoom,
  updateWard,
} from 'src/graphql/mutations';
import { roomsQuery, wardsQuery, workspacesQuery } from 'src/graphql/queries';
import {
  CopyWorkspaceMutation,
  CopyWorkspaceMutationVariables,
  createRoomMutation,
  createRoomMutationVariables,
  createWardMutation,
  createWardMutationVariables,
  createWorkspaceMutation,
  createWorkspaceMutationVariables,
  deleteRoomMutation,
  deleteRoomMutationVariables,
  deleteWardMutation,
  deleteWardMutationVariables,
  deleteWorkspaceMutation,
  deleteWorkspaceMutationVariables,
  RoomsQuery,
  RoomsQuery_rooms as Room,
  updateRoomMutation,
  updateRoomMutationVariables,
  updateWardMutation,
  updateWardMutationVariables,
  WardsQuery,
  WardsQuery_wards as Ward,
  WorkspacesQuery,
  WorkspacesQuery_workspaces as WorkSpace,
} from 'src/graphql/types';
import { useParkingPlaces } from '../RoomNew/useParkingPlaces';

export interface WorkspaceSelection {
  workspaceId: string | null;
  wardId: string | null;
  roomId: string | null;
}

export const useHospitalLayoutData = () => {
  const [selection, setSelection] = useState<WorkspaceSelection>({
    workspaceId: null,
    wardId: null,
    roomId: null,
  });
  const {
    data: workspacesData,
    loading: workspacesLoading,
    refetch: refetchWorkspaces,
  } = useQuery<WorkspacesQuery>(workspacesQuery);
  const workspaces: WorkSpace[] = workspacesData?.workspaces || [];

  const {
    data: wardsData,
    loading: wardsLoading,
    refetch: refetchWards,
  } = useQuery<WardsQuery>(wardsQuery, {
    variables: { workspaceIds: [selection.workspaceId] },
    skip: !selection.workspaceId,
  });
  const wards: Ward[] =
    selection.workspaceId && wardsData?.wards ? wardsData.wards : [];

  const {
    data: roomsData,
    loading: roomsLoading,
    refetch: refetchRooms,
  } = useQuery<RoomsQuery>(roomsQuery, {
    variables: { wardId: selection.wardId },
    skip: !selection.wardId,
  });
  const rooms: Room[] =
    selection.wardId && roomsData?.rooms ? roomsData.rooms : [];

  const handleWardsChanged = () => {
    refetchWorkspaces();
    refetchWards({ workspaceIds: [selection.workspaceId] });
  };
  const handleRoomsChanged = () => {
    refetchWorkspaces();
    refetchWards({ workspaceIds: [selection.workspaceId] });
    refetchRooms({ wardId: selection.wardId });
  };

  const [workspaceMutation] = useMutation<
    createWorkspaceMutation,
    createWorkspaceMutationVariables
  >(createWorkspace, { onCompleted: refetchWorkspaces });
  const [workspaceRemoveMutation] = useMutation<
    deleteWorkspaceMutation,
    deleteWorkspaceMutationVariables
  >(deleteWorkspace, { onCompleted: refetchWorkspaces });
  const [copyWorkspace] = useMutation<
    CopyWorkspaceMutation,
    CopyWorkspaceMutationVariables
  >(copyWorkspaceMutation, { onCompleted: refetchWorkspaces });
  const [wardCreateMutation] = useMutation<
    createWardMutation,
    createWardMutationVariables
  >(createWard, { onCompleted: handleWardsChanged });
  const [wardEditMutation] = useMutation<
    updateWardMutation,
    updateWardMutationVariables
  >(updateWard, { onCompleted: handleWardsChanged });
  const [wardRemoveMutation] = useMutation<
    deleteWardMutation,
    deleteWardMutationVariables
  >(deleteWard, { onCompleted: handleWardsChanged });
  const [roomCreateMutation] = useMutation<
    createRoomMutation,
    createRoomMutationVariables
  >(createRoom, { onCompleted: handleRoomsChanged });
  const [roomEditMutation] = useMutation<
    updateRoomMutation,
    updateRoomMutationVariables
  >(updateRoom, { onCompleted: handleRoomsChanged });
  const [roomRemoveMutation] = useMutation<
    deleteRoomMutation,
    deleteRoomMutationVariables
  >(deleteRoom, { onCompleted: handleRoomsChanged });

  const handleWorkspaceSelection = (workspaceId: string | null) => {
    setSelection((state) => ({
      ...state,
      wardId: null,
      roomId: null,
      workspaceId: workspaceId,
    }));
    workspaceId && refetchWards({ workspaceIds: [workspaceId] });
  };

  const handleCopyWorkspace = (workspaceId: string) => {
    copyWorkspace({
      variables: {
        workspaceId,
      },
    });
  };

  const handleWorkspaceNameChange = (workspaceId: string, name: string) => {
    workspaceMutation({
      variables: {
        workspace: {
          id: workspaceId,
          image: 1,
          name: name,
        },
      },
    });
  };

  const handleNewWorkspace = () => {
    workspaceMutation({
      variables: {
        workspace: {
          image: 1,
          name: '',
        },
      },
    });
  };

  const handleWorkspaceRemove = (workspaceId: string) => {
    workspaceRemoveMutation({ variables: { workspaceId } });
    if (workspaceId === selection.workspaceId) {
      handleWorkspaceSelection(null);
    }
  };

  const handleWardSelection = (wardId: string | null) => {
    setSelection((state) => ({ ...state, roomId: null, wardId: wardId }));
    wardId && refetchRooms({ wardId: wardId });
  };

  const handleWardNameChange = (wardId: string, name: string) => {
    wardEditMutation({
      variables: {
        ward: {
          name: name,
          id: wardId,
        },
      },
    });
  };

  const handleWardCreate = () => {
    if (selection.workspaceId) {
      wardCreateMutation({
        variables: {
          ward: {
            name: '',
            workspaceId: selection.workspaceId,
          },
        },
      });
    }
  };

  const handleWardRemove = (wardId: string) => {
    wardRemoveMutation({ variables: { wardId } });
    if (selection.wardId === wardId) {
      handleWardSelection(null);
    }
  };

  const handleRoomSelection = (roomId: string | null) => {
    setSelection((state) => ({ ...state, roomId: roomId }));
  };

  const handleRoomNameChange = (roomId: string, name: string) => {
    roomEditMutation({
      variables: {
        room: {
          name,
          id: roomId,
        },
      },
    });
  };

  const handleRoomCreate = () => {
    if (selection.wardId) {
      roomCreateMutation({
        variables: {
          room: {
            name: '',
            wardId: Number(selection.wardId),
          },
        },
      });
    }
  };

  const handleRoomRemove = (roomId: string) => {
    roomRemoveMutation({
      variables: {
        roomId,
      },
    });

    if (selection.roomId === roomId) {
      handleRoomSelection(null);
    }
  };

  const parkingPlaceData = useParkingPlaces(selection.roomId);

  return {
    loading: workspacesLoading || wardsLoading || roomsLoading,
    workspaces: workspaces,
    wards: wards,
    rooms: rooms,
    selection: selection,
    handleWorkspaceSelection,
    handleWorkspaceNameChange,
    handleWorkspaceRemove,
    handleCopyWorkspace,
    handleNewWorkspace,
    handleWardSelection,
    handleWardNameChange,
    handleWardCreate,
    handleWardRemove,
    handleRoomSelection,
    handleRoomNameChange,
    handleRoomCreate,
    handleRoomRemove,
    ...parkingPlaceData,
  };
};
