import { t } from '@lingui/macro';
import { MenuItem, TextField } from '@mui/material';
import Flex from 'src/components/Flex';
import { FilterParameter, ReportFilterParams } from '../types';
import {
  mapFilterParametersToTranslates,
  UpdateFilterFunction,
} from './useReportFilterParams';

interface Props {
  filter: ReportFilterParams;
  updateFilter: UpdateFilterFunction;
  onKeyParameterChange?: () => void;
}

export const FieldResultFilter = ({
  filter,
  updateFilter,
  onKeyParameterChange,
}: Props) => {
  const handleFilterChange = (field: number) => {
    updateFilter({ parameter: field });
    onKeyParameterChange?.();
  };
  const filterParametersTranslates = mapFilterParametersToTranslates();
  return (
    <Flex alignItems="center">
      <TextField
        value={filter.parameter}
        label={t`Key parameter`}
        variant="outlined"
        select
        onChange={(event) => handleFilterChange(Number(event.target.value))}
        sx={{ minWidth: '25ch' }}
      >
        {Object.keys(FilterParameter)
          .filter((field) => !isNaN(Number(field)))
          .map((field) => (
            <MenuItem key={field} value={field}>
              {filterParametersTranslates[field]}
            </MenuItem>
          ))}
      </TextField>
    </Flex>
  );
};
