import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { meQuery, wardQuery } from 'src/graphql/queries';
import {
  me,
  UpdateWardSafetyPreset,
  UpdateWardSafetyPresetVariables,
  WardQuery,
  WardQueryVariables,
  WardQuery_ward,
} from 'src/graphql/types';
import { getUniqueItems } from 'src/helpers';
import { updateWardSafetyPreset } from 'src/graphql/mutations';

interface WardDataProviderType {
  wardId: string | null;
  workspaceId: string | null;
  setWorkspaceId: (workspaceId?: string) => void;
  setWardId: (wardId?: string | null) => void;
  selectedWard: WardQuery_ward | null;
  wardOptions: { id: string; name: string }[];
  workspaceOptions: { id: string; name: string }[];
  updateSafetyPosition: (
    safetyPositionPresetId: string | null,
  ) => Promise<void>;
}

export const WardDataContext = React.createContext<WardDataProviderType>({
  wardId: null,
  workspaceId: null,
  setWorkspaceId: () => {},
  setWardId: () => {},
  selectedWard: null,
  wardOptions: [],
  workspaceOptions: [],
  updateSafetyPosition: async () => {},
});

interface Props {
  children?: React.ReactNode;
}

const WardDataProvider = ({ children }: Props) => {
  const [wardId, setWardId] = useState<string | null>(
    sessionStorage.safetyPortWardId,
  );
  const [workspaceId, setWorkspaceId] = useState<string | null>(
    sessionStorage.safetyPortWorkspaceId,
  );
  const [selectedWard, setSelectedWard] = useState<WardQuery_ward | null>(null);

  const [updateWard] = useMutation<
    UpdateWardSafetyPreset,
    UpdateWardSafetyPresetVariables
  >(updateWardSafetyPreset);
  const { data: meData } = useQuery<me>(meQuery, {
    onCompleted: (data) => {
      const userWardIds = data?.me?.accessibleWards?.map((ward) => ward.id);
      const firstWard = data?.me?.accessibleWards?.[0];
      if (userWardIds) {
        if (
          firstWard &&
          (wardId == null || (wardId && !userWardIds?.includes(wardId)))
        ) {
          handleSetWardId(firstWard?.id);
          handleSetWorkspaceId(firstWard?.workspace?.id);
        }
      }
    },
  });

  const workspaceOptions = useMemo(() => {
    return getUniqueItems(
      meData?.me?.accessibleWards?.map((ward) => ward.workspace) || [],
    );
  }, [meData?.me?.accessibleWards]);

  const wardOptions = useMemo(() => {
    return (
      meData?.me?.accessibleWards
        ?.filter((ward) => ward.workspace.id === workspaceId)
        .map((ward) => ({ id: ward.id, name: ward.name })) || []
    );
  }, [meData?.me?.accessibleWards, workspaceId]);

  useEffect(() => {
    if (!wardOptions.length) {
      setWardId(null);
      setSelectedWard(null);
    }
  }, [wardOptions.length, setWardId]);

  useQuery<WardQuery, WardQueryVariables>(wardQuery, {
    variables: {
      id: wardId!,
    },
    skip: !wardId,
    onCompleted: (data) => {
      setSelectedWard(data.ward);
    },
  });

  const handleSetWardId = (newWardId?: string | null) => {
    sessionStorage.safetyPortWardId = newWardId;
    setWardId(newWardId ?? null);
  };

  const handleSetWorkspaceId = (newWorkspaceId?: string) => {
    sessionStorage.safetyPortWorkspaceId = newWorkspaceId;
    setWorkspaceId(newWorkspaceId ?? null);

    const foundWorkspaceWard = meData?.me?.accessibleWards?.find(
      (ward) => ward.workspace.id === newWorkspaceId,
    );
    setWardId(foundWorkspaceWard?.id || null);
  };

  const updateSafetyPosition = async (
    safetyPositionPresetId: string | null,
  ) => {
    if (!wardId) {
      return;
    }
    await updateWard({
      variables: {
        input: [
          {
            safetyPositionPresetId,
            wardId,
          },
        ],
      },
    });
  };

  const context: WardDataProviderType = {
    wardId,
    setWardId: handleSetWardId,
    workspaceId,
    setWorkspaceId: handleSetWorkspaceId,
    workspaceOptions,
    wardOptions,
    selectedWard,
    updateSafetyPosition,
  };

  return (
    <WardDataContext.Provider value={context}>
      {children}
    </WardDataContext.Provider>
  );
};

export default WardDataProvider;

export const useWardData = () => {
  const context = useContext(WardDataContext);
  return context;
};
