import { useEffect } from 'react';
import { Box, Typography, useTheme, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Curve from './Curve';
import Bars from './Bars';
import {
  GraphType,
  PatientDataValuesType,
  graphView,
  graphLenght,
  getPatientViewTrans,
  isCurve,
  getGraphRangeTexts,
} from '../../helpers/patientView';
import { DataPointIcon } from '../../components/DatapointIcon';
import { Draggable } from 'react-beautiful-dnd';
import { isFromZeroType, getCodeByName } from '../../helpers/patientView';
import { add } from 'date-fns';
import { parseDate } from 'src/helpers';

interface Props {
  type: GraphType;
  data: PatientDataValuesType;
  index: number;
  visibleTimeline: number;
  showDetail?: (type: string, from: Date, to: Date) => void;
}

const DatapointView = ({
  type,
  data,
  index,
  visibleTimeline,
  showDetail,
}: Props) => {
  const fromZero = isFromZeroType(type);
  const theme = useTheme();

  useEffect(() => {
    const elements = document.getElementsByClassName('view-graph');
    const view = graphLenght / 24;
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      element.scrollLeft = visibleTimeline * view;
    }
  }, [visibleTimeline, data, index]);

  const handleCurveClick = (date: string) => {
    const from = parseDate(date);
    const prop = getCodeByName(type);
    if (from && prop && showDetail) {
      const to = add(from, { minutes: 5 });
      showDetail(prop, from, to);
    }
  };

  const handleBarClick = (from: Date, to: Date) => {
    const prop = getCodeByName(type);
    showDetail && prop && showDetail(prop, from, to);
  };

  return (
    <Draggable key={type} draggableId={type} index={index}>
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
        >
          <Container>
            <Box
              display="flex"
              flex={1}
              position="relative"
              alignItems="center"
            >
              <Box mx={2} width="25px" height="30px">
                <DataPointIcon type={type} color={theme.palette.text.primary} />
              </Box>
              <Typography sx={{ fontSize: '0.875rem', maxWidth: 250 }}>
                {getPatientViewTrans(type)}
              </Typography>

              <RangeTextBox my="auto">
                {getGraphRangeTexts(type)?.map((item) => (
                  <RangeText key={`view-item-${item}`}>{item}</RangeText>
                ))}
              </RangeTextBox>
            </Box>
            <Box position="relative">
              <Axis axisPosition={fromZero ? 'bottom' : 'middle'} />
              <GraphContainer className="view-graph">
                {isCurve(type) ? (
                  <Curve
                    type={type}
                    data={data}
                    handleClick={handleCurveClick}
                  />
                ) : (
                  <Bars type={type} data={data} handleClick={handleBarClick} />
                )}
              </GraphContainer>
            </Box>

            <Box
              pl={3}
              pr={2}
              display="flex"
              alignItems="center"
              {...draggableProvided.dragHandleProps}
            >
              <MenuIcon width="16px" height="16px" color="primary" />
            </Box>
          </Container>
        </div>
      )}
    </Draggable>
  );
};

export default DatapointView;

const Container = styled(Box)`
  border-radius: 10px;
  display: flex;
  flex: 1;
  height: 48px;
  margin: 4px 0;
  position: relative;
  background-color: ${(props) => props.theme.palette.grey[200]};
`;

const Axis = styled(Box)<{ axisPosition: 'bottom' | 'middle' }>(
  ({ theme, axisPosition }) => `
  border-top: 1px dashed ${theme.palette.text.primary};
  width: 100%;
  position: absolute;
  ${
    axisPosition === 'bottom'
      ? `
    bottom: 4;
  `
      : ``
  }
  ${
    axisPosition === 'middle'
      ? `
    top: calc(50% - 0.5px);
  `
      : ``
  }
`,
);

const GraphContainer = styled(Box)`
  scrollbar-width: none;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  height: 100%;
  width: ${graphView}px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const RangeText = styled(Typography)`
  width: 90px;
  text-align: right;
  font-size: 8px;
  padding: 4px 0px;
  text-transform: uppercase;
`;

const RangeTextBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: absolute;
  right: 5px;
`;
