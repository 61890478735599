import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import AppVersion from 'src/components/AppVersion';
import { ReactComponent as Settings } from 'src/images/Settings.svg';
import { Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import Flex from 'src/components/Flex';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

interface Props {
  isAdmin?: boolean;
  hospitalName: string;
}

const AdminBar = ({ isAdmin, hospitalName }: Props) => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const myProfile = () => {
    navigate('/my-profile');
  };

  const settings = () => {
    navigate('/admin/settings');
  };
  return (
    <AdminAppBar position="fixed">
      <Toolbar sx={{ paddingRight: '40px', paddingLeft: 0 }}>
        <Typography
          variant="subtitle1"
          color="inherit"
          noWrap={true}
          sx={{ marginLeft: '40px' }}
        >
          {hospitalName}
        </Typography>
        <Flex sx={{ flexGrow: 1 }} />
        <Flex>
          <Box
            sx={{
              marginRight: '20px',
              alignSelf: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            <AppVersion type="admin" />
          </Box>
          {isAdmin && (
            <>
              <IconButton
                onClick={settings}
                size="large"
                sx={{ color: 'currentColor' }}
              >
                <Settings />
                <TopButtonText>
                  <Trans>Settings</Trans>
                </TopButtonText>
              </IconButton>
              <IconButton
                onClick={myProfile}
                size="large"
                sx={{ color: 'currentColor' }}
              >
                <AccountCircleOutlinedIcon />
                <TopButtonText>
                  <Trans>My profile</Trans>
                </TopButtonText>
              </IconButton>
            </>
          )}
          <IconButton
            onClick={logout}
            size="large"
            sx={{ color: 'currentColor' }}
          >
            <LogoutIcon />
            <TopButtonText>
              <Trans>Log out</Trans>
            </TopButtonText>
          </IconButton>
        </Flex>
      </Toolbar>
    </AdminAppBar>
  );
};

export default AdminBar;

const AdminAppBar = styled(AppBar)(
  ({ theme }) => `
    z-index: ${theme.zIndex.drawer} + 1;
    width: calc(100% - 240px);
    margin-left: 240px;
    background-color: ${theme.palette.grey[200]};
    color: ${theme.palette.text.primary};
`,
);

const TopButtonText = styled(Typography)`
  font-size: 15px;
  margin-left: 10px;
`;
