import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  debounce,
  Divider,
  IconButton,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Flex from 'src/components/Flex';
import TextFieldAutosave from 'src/components/TextFieldAutosave/TextFieldAutosave';
import {
  changeParkingPlaceBedMutation,
  changeParkingPlaceIpAddressEthernetMutation,
  changeParkingPlaceModeMutation,
  changeParkingPlaceNameMutation,
  changeParkingPlaceTagMutation,
} from 'src/graphql/mutations';
import { beds as bedsQuery } from 'src/graphql/queries';
import {
  bedsQuery as BedsQuery,
  BedState,
  ChangeParkingPlaceBed,
  ChangeParkingPlaceBedVariables,
  ChangeParkingPlaceIpAddressEthernetMutation,
  ChangeParkingPlaceIpAddressEthernetMutationVariables,
  ChangeParkingPlaceMode,
  ChangeParkingPlaceModeVariables,
  ChangeParkingPlaceNameMutation,
  ChangeParkingPlaceNameMutationVariables,
  ChangeParkingPlaceTag,
  ChangeParkingPlaceTagVariables,
  ParkingPlaceMode,
} from 'src/graphql/types';
import LocationSourceButtonInput from './LocationSourceButtonInput';
import { normalizeNumberInt } from 'src/lib/normalize';
import LocationSourceButton from './LocationSourceButton';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';

interface Props {
  id: string;
  name: string;
  bedUnitId: string;
  bedName: string;
  bedState?: BedState | null;
  ipAddressEthernet?: string | null;
  tagId: string | null;
  mode: ParkingPlaceMode;
  removeParkingPlace: (id: string, name: string) => void;
}

const ParkingPlaceCard = ({
  id,
  name,
  bedUnitId,
  bedName,
  bedState,
  tagId,
  mode,
  ipAddressEthernet = null,
  removeParkingPlace,
}: Props) => {
  const { data } = useQuery<BedsQuery>(bedsQuery, {
    fetchPolicy: 'cache-first',
  });
  const [changeParkingPlaceMode] = useMutation<
    ChangeParkingPlaceMode,
    ChangeParkingPlaceModeVariables
  >(changeParkingPlaceModeMutation, {
    refetchQueries: [bedsQuery],
  });
  const [changeParkingPlaceTag] = useSnackbarMutation<
    ChangeParkingPlaceTag,
    ChangeParkingPlaceTagVariables
  >(changeParkingPlaceTagMutation);
  const [changeIpAddressEtherent] = useSnackbarMutation<
    ChangeParkingPlaceIpAddressEthernetMutation,
    ChangeParkingPlaceIpAddressEthernetMutationVariables
  >(changeParkingPlaceIpAddressEthernetMutation);
  const [changeParkingPlaceBed] = useMutation<
    ChangeParkingPlaceBed,
    ChangeParkingPlaceBedVariables
  >(changeParkingPlaceBedMutation, {
    refetchQueries: [bedsQuery],
  });
  const [changeParkingPlaceName] = useMutation<
    ChangeParkingPlaceNameMutation,
    ChangeParkingPlaceNameMutationVariables
  >(changeParkingPlaceNameMutation);
  const theme = useTheme();
  const value = useMemo(() => {
    return bedUnitId ? { id: bedUnitId, name: bedName } : null;
  }, [bedUnitId, bedName]);

  const handleTagChange = async (tagId: string | null) => {
    await changeParkingPlaceTag({
      variables: {
        parkingPlaceId: id,
        tagId: tagId || null,
      },
      optimisticResponse: {
        changeParkingPlaceTag: {
          id,
          __typename: 'ParkingPlace',
          name,
          tagId,
          ipAddressEthernet,
          mode,
          bed: value?.id
            ? {
                unitId: value.id,
                __typename: 'Bed',
                name: value.name,
              }
            : null,
        },
      },
    });
  };

  const handleEthernetIpChange = async (ipAddress?: string | null) => {
    changeIpAddressEtherent({
      variables: {
        parkingPlaceId: id,
        ipAddressEthernet: ipAddress || null,
      },
      optimisticResponse: {
        changeParkingPlaceIpAddressEthernet: {
          id,
          __typename: 'ParkingPlace',
          name,
          ipAddressEthernet: ipAddress || null,
          tagId,
          mode,
          bed: value?.id
            ? {
                unitId: value.id,
                __typename: 'Bed',
                name: value.name,
              }
            : null,
        },
      },
    });
  };

  const handleNameChange = async (name: string) => {
    changeParkingPlaceName({
      variables: {
        parkingPlaceId: id,
        name: name,
      },
      optimisticResponse: {
        changeParkingPlaceName: {
          id,
          __typename: 'ParkingPlace',
          name: name,
          tagId,
          ipAddressEthernet,
          mode,
          bed: value?.id
            ? {
                unitId: value.id,
                __typename: 'Bed',
                name: value.name,
              }
            : null,
        },
      },
    });
  };

  const handleBedChange = async (
    bed: { unitId: string; name: string | null } | null,
  ) => {
    await changeParkingPlaceBed({
      variables: {
        parkingPlaceId: id,
        bedUnitId: bed?.unitId || null,
      },
      optimisticResponse: {
        changeParkingPlaceBed: {
          id,
          __typename: 'ParkingPlace',
          name,
          tagId,
          ipAddressEthernet,
          mode,
          bed: bed
            ? {
                unitId: bed.unitId,
                __typename: 'Bed',
                name: bed.name,
              }
            : null,
        },
      },
    });
  };

  const handleModeChange = debounce(async (mode: ParkingPlaceMode) => {
    await changeParkingPlaceMode({
      variables: {
        parkingPlaceId: id,
        mode,
      },
      optimisticResponse: {
        changeParkingPlaceMode: {
          id,
          __typename: 'ParkingPlace',
          name,
          tagId,
          ipAddressEthernet,
          mode,
          bed: value?.id
            ? {
                unitId: value.id,
                __typename: 'Bed',
                name: value.name,
              }
            : null,
        },
      },
    });
  });

  const getAvailableBeds = () => {
    const beds =
      data?.beds
        .filter((bed) => !bed.parkingPlaces?.length)
        .map((bed) => ({ id: bed.unitId, name: bed.name })) || [];

    if (!value?.id) {
      return beds;
    }
    const hasSelectedBed = beds.find((bed) => bed.id === value.id);
    if (hasSelectedBed) {
      return beds;
    }
    beds.unshift(value);
    return beds;
  };

  const availableBeds = getAvailableBeds();

  const unassignBed = () => {
    changeParkingPlaceBed({
      variables: {
        parkingPlaceId: id,
        bedUnitId: null,
      },
    });
  };

  return (
    <Card sx={{ width: '360px', flexShrink: 0 }}>
      <CardActions>
        <Flex flexGrow={1} justifyContent="end">
          <IconButton
            onClick={() => removeParkingPlace(id, name)}
            title={t`Delete`}
          >
            <DeleteOutlineIcon htmlColor={theme.palette.grey[300]} />
          </IconButton>
        </Flex>
      </CardActions>
      <CardContent>
        <FieldContainer>
          <TextFieldAutosave
            variant="outlined"
            onAutoSave={handleNameChange}
            value={name}
            label={t`Parking place`}
            fullWidth
          />
        </FieldContainer>
        <FieldContainer mt="24px">
          <Autocomplete
            options={availableBeds}
            disabled={mode !== ParkingPlaceMode.MANUAL}
            value={value ?? null}
            getOptionLabel={(value) => value.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_, value) =>
              handleBedChange(
                value?.id ? { unitId: value.id, name: value.name } : null,
              )
            }
            renderInput={(params) => (
              <TextField
                variant="outlined"
                name="bed"
                label={t`Bed`}
                {...params}
              />
            )}
          />
        </FieldContainer>
        <ActionBar>
          {bedUnitId ? (
            <>
              <StyledLink to={`/admin/beds/${bedUnitId}`}>
                {t`Bed detail`}
              </StyledLink>
              <StyledLink to={`/admin/insight?unitId=${bedUnitId}`}>
                {t`Bed insight`}
              </StyledLink>
              <Button
                sx={{
                  visibility:
                    mode === ParkingPlaceMode.MANUAL ? 'visible' : 'hidden',
                  textTransform: 'none',
                  fontSize: '16px',
                  textDecoration: 'underline',
                }}
                color="error"
                variant="text"
                startIcon={<LinkOffIcon fontSize="small" />}
                onClick={unassignBed}
              >
                <Trans>Unassign</Trans>
              </Button>
            </>
          ) : null}
        </ActionBar>
        <Divider sx={{ marginTop: '16px', marginBottom: '16px' }} />

        <Flex flex={1} flexDirection="column">
          <Label>
            <Trans>Sources of Location</Trans>
          </Label>
          <LocationSourceButtonInput
            emptyMessage={t`No Tag`}
            active={mode === ParkingPlaceMode.TAG}
            onChange={handleTagChange}
            onChangeMode={handleModeChange}
            mode={ParkingPlaceMode.TAG}
            normalizeValue={normalizeNumberInt}
            value={tagId}
          />
          <LocationSourceButtonInput
            emptyMessage={t`No Ethernet IP`}
            value={ipAddressEthernet}
            active={mode === ParkingPlaceMode.IP_ETHERNET}
            onChange={handleEthernetIpChange}
            mode={ParkingPlaceMode.IP_ETHERNET}
            onChangeMode={handleModeChange}
          />
          <LocationSourceButton
            active={mode === ParkingPlaceMode.MANUAL}
            mode={ParkingPlaceMode.MANUAL}
            onChangeMode={handleModeChange}
          >
            <Trans>Manual</Trans>
          </LocationSourceButton>
        </Flex>
      </CardContent>
    </Card>
  );
};

export default ParkingPlaceCard;

const Label = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 8px;
`;

const ActionBar = styled(Flex)`
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
  min-height: 40px;
  justify-content: space-between;
`;

const FieldContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  & .MuiInputLabel-root {
    text-transform: uppercase;
    font-size: 1rem;
  }
`;

const StyledLink = styled(Link)(
  ({ theme: { palette } }) => `
  color: ${palette.text.primary};
  :hover {
    color: ${palette.primary.main};
  }
  text-decoration: underline;
`,
);
