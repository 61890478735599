import { Box, TextField, Typography, styled } from '@mui/material';
import SwitchBtn from 'src/components/SwitchBtn';
import { SafetyPortProps } from 'src/graphql/types';
import { propTranslationTabel } from 'src/helpers';
import { PropsIcons } from 'src/components/PropsIcon';
import { ControlStateType } from './globalSettingsHelper';

interface Props {
  datapointData: ControlStateType;
  setControlState: React.Dispatch<React.SetStateAction<ControlStateType[]>>;
  controlState: ControlStateType[];
}

const Datapoint = ({ datapointData, controlState, setControlState }: Props) => {
  const switchHandler = (checked: boolean) => {
    setControlState(
      controlState.map((state) =>
        state.type === datapointData.type
          ? { ...state, switchState: checked }
          : state,
      ),
    );
  };

  const changeThreshold = (value: string) => {
    setControlState(
      controlState.map((state) =>
        state.type === datapointData.type
          ? { ...state, threshold: parseInt(value) }
          : state,
      ),
    );
  };

  return (
    <>
      <DataPointBox py={2} px={1}>
        <Box
          display="flex"
          width="340px"
          alignItems="center"
          component="label"
          htmlFor={`datapoint-${datapointData.type}`}
        >
          <PropsIcons prop={datapointData.type as SafetyPortProps} size={24} />
          <Typography style={{ marginLeft: 4 }} component="span">
            {propTranslationTabel(datapointData.type)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <SwitchBtn
            id={`datapoint-${datapointData.type}`}
            state={datapointData.switchState}
            onChange={(checked) => switchHandler(checked)}
          />
          {datapointData.switchState && datapointData.thresholdAllowed && (
            <ThresholdInput
              InputProps={{ inputProps: { min: 1 } }}
              onChange={({ target }) =>
                target.value !== '0' ? changeThreshold(target.value) : undefined
              }
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              id="outlined-treshhold"
              variant="outlined"
              value={datapointData.threshold || ''}
            />
          )}
        </Box>
      </DataPointBox>
    </>
  );
};

export default Datapoint;

const DataPointBox = styled(Box)`
  position: relative;
  width: 340px;
  ::after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.23);
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
  }
`;

const ThresholdInput = styled(TextField)`
  margin-left: 32px;
  width: 70px;
  .MuiOutlinedInput-input {
    text-align: center;
    padding: 8px;
  }
`;
