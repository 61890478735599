import { FilterValue } from 'src/components/Filters';
import {
  BedFilters,
  BedFiltersVariables,
  TagsQuery_tags,
} from 'src/graphql/types';
import { uniqBy } from 'ramda';
import { notEmpty } from 'src/lib/notEmpty';
import { useQuery } from '@apollo/client';
import { bedFiltersQuery } from 'src/graphql/queries';
import { BatteryStatusCode, transBatteryStatus } from './BatteryStatus';

export const TAG_FILTER = 'tagFilter';

export type FilterProps = {
  workspace: FilterValue | null;
  ward: FilterValue | null;
  tag: FilterValue | null;
  bedName: FilterValue | null;
  room: FilterValue | null;
  batteryStatus: FilterValue | null;
};

const byId = (item: { id: string } | null) => item?.id;

export const useTagFilterOption = (
  filter: FilterProps,
  tags?: TagsQuery_tags[],
) => {
  const { data } = useQuery<BedFilters, BedFiltersVariables>(bedFiltersQuery, {
    variables: {
      workspaceIds: filter.workspace?.id ? [filter.workspace.id] : undefined,
    },
  });

  const tagOptions = uniqBy(
    byId,
    tags
      ? tags
          .map((tag) =>
            tag
              ? {
                  id: tag.id,
                  name: tag.id,
                }
              : null,
          )
          .filter(notEmpty)
      : [],
  );
  const bedNameOptions = uniqBy(
    byId,
    tags
      ? tags
          .map((tag) => {
            const bed = tag?.bed;
            if (!bed || !bed.name) {
              return null;
            }
            return {
              id: bed.name,
              name: bed.name,
            };
          })
          .filter(notEmpty)
      : [],
  );
  const statuses: BatteryStatusCode[] = ['unknown', 'low', 'medium', 'high'];
  const statusOptions = statuses.map((status) => ({
    id: status,
    name: transBatteryStatus(status),
  }));
  return {
    bedNameOptions,
    roomOptions: data?.rooms || [],
    wardOptions: data?.wards || [],
    workspaceOptions: data?.workspaces || [],
    tagOptions,
    statusOptions,
  };
};
