import { t } from '@lingui/macro';
import { Box, styled } from '@mui/material';
import { version } from '../../../package.json';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SwVersion = () => {
  return (
    <InfoBox>
      <InfoIconStyled />
      {`${t`Sw version:`} ${version}`}
    </InfoBox>
  );
};

export default SwVersion;

const InfoIconStyled = styled(InfoOutlinedIcon)(
  ({ theme: { spacing } }) => `
  margin-right: ${spacing(1)};
`,
);

const InfoBox = styled(Box)(
  ({ theme: { palette } }) => `
  white-space: nowrap;
  color: ${palette.text.secondary};
  display: flex;
  align-items: center;
`,
);
