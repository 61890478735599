import { t } from '@lingui/macro';
import { styled } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { createRoom as createRoomMutation } from '../../../graphql/mutations';
import { wardSelectQuery } from '../../../graphql/queries';
import {
  createRoomMutation as CreateRoom,
  createRoomMutationVariables as CreateRoomVariables,
  WardSelectQuery,
} from '../../../graphql/types';
import {
  ListPageContent,
  ListPageRoot,
  TopBar,
} from 'src/components/AdminList';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import Flex from 'src/components/Flex';
import ActionButtons from 'src/components/ActionButtons';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikAutocomplete from 'src/components/Formik/FormikAutocomplete';

const roomSchema = object({
  name: string().required(t`Required!`),
  ward: object()
    .nullable()
    .required(t`Required!`),
  workspace: object()
    .nullable()
    .required(t`Required!`),
});

interface FormData {
  name: string;
  ward?: { id: string } | null;
  workspace: { id: string } | null;
}

const NewRoom = () => {
  const { data } = useQuery<WardSelectQuery>(wardSelectQuery);
  const [createRoom] = useMutation<CreateRoom, CreateRoomVariables>(
    createRoomMutation,
  );
  const navigate = useNavigate();

  const onSubmit = async (values: FormData) => {
    const { ward, name } = values;
    await createRoom({
      variables: {
        room: {
          name,
          wardId: Number(ward?.id!),
        },
      },
    });
    navigate('/admin/rooms');
  };

  const formik = useFormik<FormData>({
    initialValues: {
      name: '',
      ward: null,
      workspace: null,
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: roomSchema,
  });

  const { workspace: selectedWorkspace } = formik.values;

  const workspaces = useMemo(() => {
    return (
      data?.workspaces?.map((workspace) => ({
        id: workspace.id,
        name: workspace.name,
        image: String(workspace.image),
      })) || []
    );
  }, [data?.workspaces]);

  const wards = useMemo(() => {
    const result: { id: string; name: string; workspaceId: string }[] = [];
    data?.workspaces?.forEach((workspace) => {
      if (selectedWorkspace?.id && selectedWorkspace.id !== workspace.id) {
        return;
      }
      workspace.wards?.forEach((ward) => {
        result.push({
          id: ward.id,
          name: ward.name,
          workspaceId: workspace.id,
        });
      });
    });
    return result;
  }, [selectedWorkspace, data?.workspaces]);

  return (
    <ListPageRoot>
      <TopBar>
        <ActionButtons
          onSave={formik.submitForm}
          onDiscard={formik.resetForm}
          saveButtonLabel={t`Create room`}
          backButton={t`Cancel`}
          isDirty={formik.dirty}
        />
      </TopBar>
      <ListPageContent sx={{ paddingTop: '20px' }}>
        <FormikForm formik={formik}>
          <FieldsContainer>
            <FormikTextField
              id="name"
              name="name"
              label={t`Name`}
              variant="outlined"
            />
            <div />
            <FormikAutocomplete
              options={workspaces}
              getOptionLabel={(value) => value.name || ''}
              name="workspace"
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              textFieldProps={{
                variant: 'outlined',
                name: 'workspace',
                label: t`Workspace`,
                InputLabelProps: {
                  shrink: true,
                },
                fullWidth: true,
              }}
              onChange={() => {
                formik.setFieldValue('ward', null);
              }}
            />
            <FormikAutocomplete
              options={wards}
              name="ward"
              getOptionLabel={(value) => value.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              textFieldProps={{
                variant: 'outlined',
                name: 'ward',
                label: t`Ward`,
                InputLabelProps: {
                  shrink: true,
                },
                fullWidth: true,
              }}
            />
          </FieldsContainer>
        </FormikForm>
      </ListPageContent>
    </ListPageRoot>
  );
};

export default NewRoom;

const FieldsContainer = styled(Flex)`
  display: grid;
  max-width: 1000px;
  grid-template-columns: 50% 50%;
  gap: 32px;
`;
