import { IconButton, Typography, useTheme } from '@mui/material';
import TextFieldAutosave from 'src/components/TextFieldAutosave/TextFieldAutosave';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { ParkingPlaceMode } from 'src/graphql/types';
import EditIcon from '@mui/icons-material/Edit';
import LocationSourceButton, {
  LocationSourceWrapper,
} from './LocationSourceButton';

interface Props {
  value?: string | null;
  onChange: (value: string | null) => void;
  onChangeMode: (type: ParkingPlaceMode) => void;
  mode: ParkingPlaceMode;
  active?: boolean;
  emptyMessage?: string;
  normalizeValue?: (value: string) => string;
}

const LocationSourceButtonInput = ({
  active = false,
  emptyMessage = '',
  onChange,
  onChangeMode,
  value,
  mode,
  normalizeValue,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const theme = useTheme();

  const handleChange = (value: string) => {
    onChange?.(value);
    setEditing(false);
  };

  if (!editing) {
    return (
      <LocationSourceButton
        onChangeMode={onChangeMode}
        mode={mode}
        active={active}
      >
        <Typography flex={1}>{value || emptyMessage}</Typography>
        {active ? (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setEditing(true);
            }}
          >
            <EditIcon htmlColor={theme.palette.primary.main} />
          </IconButton>
        ) : null}
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onChange?.(null);
          }}
        >
          <ClearIcon htmlColor={theme.palette.primary.main} />
        </IconButton>
      </LocationSourceButton>
    );
  }

  return (
    <LocationSourceWrapper>
      <TextFieldAutosave
        autoFocus
        variant="outlined"
        onAutoSave={handleChange}
        value={value || ''}
        normalizeValue={normalizeValue}
        fullWidth
      />
    </LocationSourceWrapper>
  );
};

export default LocationSourceButtonInput;
