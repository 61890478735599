import { useParams } from 'react-router-dom';
import ReportingGoalPage from './ReportingGoalPage';
import ReportingReportPage from './ReportingReportPage';
import { ReportingPathParams } from './types';
import ReportingBoardPage from './ReportingBoardPage';

const ReportingSectionsRoute = () => {
  const { section, id } = useParams<ReportingPathParams>();

  if (section === 'dashboards') {
    return <ReportingBoardPage id={id!} />;
  }
  if (section === 'reports' && id === 'create') {
    return <ReportingReportPage />;
  }
  if (section === 'reports') {
    return <ReportingReportPage id={id} />;
  }
  if (section === 'goals' && id === 'create') {
    return <ReportingGoalPage />;
  }
  if (section === 'goals') {
    return <ReportingGoalPage id={id} />;
  }
  return null;
};

export default ReportingSectionsRoute;
