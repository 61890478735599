import {
  LocationFilter,
  LocationType,
  NumberComparator,
  ReportBedAggregation,
  ReportLocationAggregation,
  ReportResultField,
  ReportTagFragment,
  TimeUnitType,
} from 'src/graphql/types';
import { DateOptionPreset } from 'src/lib/datePresetHelper';
import { REPORT_FILTER_FIELDS } from './components/useReportFilter';

export interface ReportingPathParams extends Record<string, string> {
  section: 'dashboards' | 'reports' | 'goals';
  id: string;
}

export type ReportGraphType =
  | 'bar-vertical'
  | 'bar-horizontal'
  | 'line'
  | 'scorecard'
  | 'pie';

export enum FilterParameter {
  OCCUPANCY,
  BED_EXIT_COUNT,
  WEIGHT,
  BED_EXIT_REACTION,
  ONLINE,
  ALOS,
  BED_EXIT_MONITORING_ON,
  SAFETY_POSITION,
  PATIENT_PRESENCE,
  LOWEST_POSTITION,
  BACKREST_ANGLE,
  BACKREST,
  BACKREST_30,
  BACKREST_45,
  LATERAL_TILT,
  ALT_PHASE,
  CALFREST,
  TRENDELENBURG,
  ALL_SIDERAILS,
  RIGHT_HEAD_SIDERAIL,
  LEFT_HEAD_SIDERAIL,
  RIGHT_SIDERAIL,
  LEFT_SIDERAIL,
}

export interface RawReportFilter {
  parameter: FilterParameter;
  period: ReportFilterDateOption;
  graphType: ReportGraphType;
  locationFilter?: LocationFilter | null;
  goalType?: GoalType;
  fieldFilters: ReportFieldFilter[];
  aggregation: ReportAggregation;
  viewByLocation: LocationType;
  viewByTime: {
    value: number;
    unit: TimeUnitType;
  };
}

export enum ReportAggregation {
  AVERAGE_COUNT,
  ABSOLUTE_COUNT,
  LIST,
  ABSOLUTE_DURATION,
  AVERAGE_DURATION,
  ABSOLUTE_LAST,
  AVERAGE_LAST,
  ABSOLUTE_MEAN,
  AVERAGE_MEAN,
}

export enum GoalType {
  OVER = 'over',
  UNDER = 'under',
}

export interface ReportFilterParams {
  variant: 'SINGLE_VALUE';
  type: ReportType;
  parameter: FilterParameter;
  resultField: ReportResultField;
  graphType: ReportGraphType;
  period: ReportFilterDateOption;
  locationFilter?: LocationFilter | null;
  fieldFilters: ReportFieldFilter[];
  aggregation: ReportAggregation;
  mainFilter: ReportFieldFilter;
  goalType?: GoalType;

  //graph params
  bedAggregation: ReportBedAggregation;
  locationAggregation: ReportLocationAggregation;
  viewByLocation: LocationType;
  viewByTime: {
    value: number;
    unit: TimeUnitType;
  };
}

export interface ReportFieldFilter {
  field: keyof typeof REPORT_FILTER_FIELDS;
  value: number | boolean;
  numberComparator: NumberComparator | null;
}

export interface ReportFilterDateOption {
  preset: ReportDatePreset;
  from: Date | null;
  to: Date | null;
}
interface ReportFieldBase {
  fieldName: string;
  label: () => string;
}

export type ReportFilterField =
  | ReportFilterFieldB
  | ReportFilterFieldN
  | ReportFilterFieldO;
interface ReportFilterFieldB extends ReportFieldBase {
  type: 'boolean';
  yes?: () => string;
  no?: () => string;
}

interface ReportFilterFieldN extends ReportFieldBase {
  type: 'number';
  min?: number;
  max?: number;
}
interface ReportFilterFieldO extends ReportFieldBase {
  type: 'option';
  options: () => { name: string; id: number }[];
}

export type ReportDatePreset = DateOptionPreset | 'custom';

export type ReportType = 'general';

export type ReportData = {
  tags: ReportTagFragment;
  columns: string[];
  values: ReportDataValue[];
};

export type ReportDataValue = {
  time: string;
  value: number;
  unit: string;
  total: number | null;
};
