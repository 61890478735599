import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { BedType, FallRisk } from 'src/graphql/types';
import { BedData, getBedTypeName } from '../helpers/dashboard';
import { ReactComponent as Lock } from 'src/images/safetyView/dashboard/lock.svg';
import { ReactComponent as Unlock } from 'src/images/safetyView/dashboard/unlock.svg';
import { ReactComponent as Plugg } from 'src/images/safetyView/dashboard/plugg.svg';
import { ReactComponent as Unplugg } from 'src/images/safetyView/dashboard/unplugg.svg';
import SiderailsIcon from '../components/SiderailsIcon';
import { t } from '@lingui/macro';
import WeightFormat from 'src/components/WeightFormat';
import { useContext, useMemo } from 'react';
import {
  DashboardSettingContext,
  SvDashboardSortingType,
} from '../Setting/DashboardSettingProvider';
import { useLingui } from '@lingui/react';
import { ErrorIcon } from '../components/Icons/ErrorIcon';
import { OkIcon } from '../components/Icons/OkIcon';
import { BedExitIcon } from '../components/Icons/BedExitIcon';
import { BedExitMonitoring } from '../components/Icons/BedExitMonitoring';
import { BatteryStatus } from '../components/Icons/BatteryStatus';
import { Charging } from '../components/Icons/Charging';
import { getButtonOptions } from 'src/pages/Admin/VirtualBed/ControllerDatapoints';
import { getErrorCodes } from 'src/pages/Admin/VirtualBed/AdditionalDatapoints';

interface Props {
  type: SvDashboardSortingType;
  bed: BedData;
  fontSize?: string;
}

const getMattressType = (type?: number | null) => {
  switch (type) {
    case 0:
      return '-';
    case 50:
    case 51:
      return 'Symbioso';
    case 52:
      return 'Opticare';
    case 53:
      return 'OptiCare-X';
    default:
      return;
  }
};

const formatTrendelenburgAngle = (angle?: number | null) => {
  if (angle == null) {
    return '--';
  }
  if (angle > 0) {
    return `ATX ${angle} °`;
  }
  if (angle < 0) {
    return `TX ${Math.abs(angle)} °`;
  }
  return `${angle} °`;
};

const withoutTrendelenburg = (bed: BedData) => {
  return (
    bed.type === BedType.E3XC_NO_TRX ||
    bed.type === BedType.E4_NO_TRX ||
    bed.type === BedType.E3_NO_TRX ||
    bed.type === BedType.E2
  );
};
export const DashboardDatapoint = ({ type, bed, fontSize }: Props) => {
  const theme = useTheme();
  const { palette } = theme;

  const { i18n } = useLingui();
  const {
    userSetting: { anonymized },
  } = useContext(DashboardSettingContext);

  const errorMessage = useMemo(() => {
    const errorCodes = getErrorCodes();
    const error = errorCodes.find(
      (err) => err.id === bed.data?.errorOrStopCode,
    );
    return error?.name || false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bed.data?.errorOrStopCode, i18n.locale]);
  const bothLeftSiderails =
    bed.data?.siderailBothLUp ||
    (bed.data?.siderailHeadLUp && bed.data.siderailMiddleLUp);
  const bothRightSiderails =
    bed.data?.siderailBothRUp ||
    (bed.data?.siderailHeadRUp && bed.data.siderailMiddleRUp);
  const patientIsOnBed = Boolean(bed.data?.patientOnBed);
  const paused = Boolean(bed.data?.paused);

  if (bed.inAnotherWard && type !== SvDashboardSortingType.location) {
    return <Box />;
  }

  const highFallRisk = bed.fallRisk === FallRisk.HIGH;
  const showFallRiskAlert =
    highFallRisk && !bed.data?.bedExitOn && patientIsOnBed;

  switch (type) {
    case SvDashboardSortingType.powerStatus:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.power ? (
            <Plugg
              color={theme.palette.text.primary}
              width="1em"
              height="1em"
            />
          ) : (
            <Unplugg
              color={theme.palette.text.primary}
              width="1em"
              height="1em"
            />
          )}
        </Box>
      );
    case SvDashboardSortingType.note:
      return (
        <Typography fontSize={fontSize} noWrap>
          {bed.note}
        </Typography>
      );
    case SvDashboardSortingType.bedExitMonitoringStatus:
      return (
        <BedExitMonitoring
          bedExitOn={bed.data?.bedExitOn}
          isZoneExit={bed.data?.isZoneExit}
          showFallRiskAlert={showFallRiskAlert}
          paused={paused}
          fontSize={fontSize}
        />
      );
    case SvDashboardSortingType.outOfBed:
      const bea = bed.data?.bedExitAlarm;
      const iconState = bea
        ? 'error'
        : !bed.data?.bedExitOn
        ? //bedExitOff
          showFallRiskAlert
          ? 'warning'
          : 'off'
        : //bedExitOn
        paused
        ? 'warning'
        : patientIsOnBed
        ? 'on'
        : 'error';
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <BedExitIcon
            state={iconState}
            patientInBed={patientIsOnBed}
            useEmFontSize
          />
        </Box>
      );
    case SvDashboardSortingType.spAndLock:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.safetyPosition ? (
            <OkIcon sx={{ width: fontSize, height: fontSize }} />
          ) : (
            <ErrorIcon sx={{ width: fontSize, height: fontSize }} />
          )}
        </Box>
      );
    case SvDashboardSortingType.siderails:
      return (
        <SiderailsIcon
          leftSiderailsUp={!!bothLeftSiderails}
          rightSiderailsUp={!!bothRightSiderails}
          leftTop={!!bed.data?.siderailHeadLUp}
          rightTop={!!bed.data?.siderailHeadRUp}
          leftBottom={!!bed.data?.siderailMiddleLUp}
          rightBottom={!!bed.data?.siderailMiddleRUp}
          bedType={bed.type}
          downColor={palette.grey[600]}
          upColor={palette.success.light}
          height="1.25em"
        />
      );
    case SvDashboardSortingType.brakes:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.brakesOff ? (
            <ErrorIcon sx={{ width: fontSize, height: fontSize }} />
          ) : (
            <OkIcon sx={{ width: fontSize, height: fontSize }} />
          )}
        </Box>
      );
    case SvDashboardSortingType.lowestPosition:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.heightLock && (
            <Lock
              color={theme.palette.text.primary}
              style={{ margin: '0 4px' }}
              height="1em"
            />
          )}
          {bed.data?.posIsLowest ? (
            <OkIcon sx={{ width: fontSize, height: fontSize }} />
          ) : (
            <ErrorIcon sx={{ width: fontSize, height: fontSize }} />
          )}
        </Box>
      );
    case SvDashboardSortingType.backrestAndLock:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.backrestLock && (
            <Lock
              color={theme.palette.text.primary}
              style={{ margin: '0 4px' }}
              height="1em"
            />
          )}
          <Typography fontSize={fontSize}>
            {bed.data?.zAxisDeg != null ? bed.data.zAxisDeg : '--'} °
          </Typography>
        </Box>
      );
    case SvDashboardSortingType.trendelenburgAngle:
      if (withoutTrendelenburg(bed)) {
        return <Typography fontSize={fontSize}>--</Typography>;
      }
      return (
        <Typography sx={{ margin: '0 4px', fontSize }}>
          {formatTrendelenburgAngle(bed?.data?.trendelenburgDeg)}
        </Typography>
      );
    case SvDashboardSortingType.weight:
      return (
        <Typography fontSize={fontSize}>
          <WeightFormat weight={bed.data?.weight} hideUnits={anonymized} />
        </Typography>
      );
    case SvDashboardSortingType.laterailTiltAndAlt:
      return (
        <Box display="flex" justifyContent="center">
          <Typography fontSize={fontSize}>
            {!!bed.data?.lateralTiltDeg &&
              (bed.data.lateralTiltDeg > 0 ? t`R` : t`L`)}{' '}
          </Typography>
          <Typography sx={{ margin: '0 4px', fontSize }}>
            {`${
              bed.data?.lateralTiltDeg != null
                ? Math.abs(bed.data.lateralTiltDeg)
                : '--'
            } °`}
          </Typography>
          {bed.data?.ALTPhase && bed.data.ALTPhase > 3 ? (
            <Typography fontSize={fontSize}>{t`ALT`}</Typography>
          ) : (
            ''
          )}
        </Box>
      );
    case SvDashboardSortingType.calfrestAngleAndLock:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.calfrestLock && (
            <Lock
              color={theme.palette.text.primary}
              style={{ margin: '0 4px' }}
              height="1em"
            />
          )}
          <Typography fontSize={fontSize}>
            {`${bed.data?.lAxisDeg != null ? bed.data.lAxisDeg : '--'} °`}
          </Typography>
        </Box>
      );
    case SvDashboardSortingType.thighrestLock:
      return (
        <>
          {bed.data?.thighrestLock ? (
            <Lock color={theme.palette.text.primary} height="1em" />
          ) : (
            <Unlock color={theme.palette.text.primary} height="1.5em" />
          )}
        </>
      );
    case SvDashboardSortingType.mattressType:
      return (
        <Typography fontSize={fontSize}>
          {getMattressType(bed.data?.mattressType)}
        </Typography>
      );
    case SvDashboardSortingType.bedType:
      return (
        <Typography fontSize={fontSize}>
          {getBedTypeName(bed.type) ?? '--'}
        </Typography>
      );
    case SvDashboardSortingType.sessionId:
      return (
        <Typography fontSize={fontSize}>{bed.patientId ?? '--'}</Typography>
      );
    case SvDashboardSortingType.sessionStart:
      return (
        <Typography fontSize={fontSize}>
          {bed.sessionStart
            ? i18n.date(bed.sessionStart, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })
            : '--'}
        </Typography>
      );
    case SvDashboardSortingType.bedName:
      return <Typography fontSize={fontSize}>{bed.bedName ?? '--'}</Typography>;
    case SvDashboardSortingType.tagCharge:
      return (
        <Typography fontSize={fontSize}>
          {bed.data?.tagCharge ? `${bed.data.tagCharge} %` : '--'}
        </Typography>
      );
    case SvDashboardSortingType.charging:
      return <Charging charging={!!bed.data?.charging} fontSize="1.25em" />;
    case SvDashboardSortingType.transfer:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.transfer ? (
            <OkIcon sx={{ width: fontSize, height: fontSize }} />
          ) : (
            <ErrorIcon sx={{ width: fontSize, height: fontSize }} />
          )}
        </Box>
      );
    case SvDashboardSortingType.heightOfRLegColumn:
      return (
        <Typography fontSize={fontSize}>
          {bed.data?.heightOfRLegColumn || '--'}
        </Typography>
      );
    case SvDashboardSortingType.heightOfLLegColumn:
      return (
        <Typography fontSize={fontSize}>
          {bed.data?.heightOfLLegColumn || '--'}
        </Typography>
      );
    case SvDashboardSortingType.heightOfHeadColumn:
      return (
        <Typography fontSize={fontSize}>
          {bed.data?.heightOfHeadColumn || '--'}
        </Typography>
      );
    case SvDashboardSortingType.mobiliftRight:
      return (
        <Mobilift
          fontSize={fontSize}
          mobiliftStatus={bed.data?.mobiliftRight}
        />
      );
    case SvDashboardSortingType.mobiliftLeft:
      return (
        <Mobilift fontSize={fontSize} mobiliftStatus={bed.data?.mobiliftLeft} />
      );
    case SvDashboardSortingType.bedExtension:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {bed.data?.bedExtension ? (
            <OkIcon sx={{ width: fontSize, height: fontSize }} />
          ) : (
            <ErrorIcon sx={{ width: fontSize, height: fontSize }} />
          )}
        </Box>
      );
    case SvDashboardSortingType.pressedBtnAndUsedController:
      return (
        <ButtonPressed
          fontSize={fontSize}
          buttonPressed={bed.data?.pressedBtnAndUsedController}
        />
      );
    case SvDashboardSortingType.rightLCDButtonPressed:
      return (
        <ButtonPressed
          fontSize={fontSize}
          buttonPressed={bed.data?.rightLCDButtonPressed}
        />
      );
    case SvDashboardSortingType.leftLCDButtonPressed:
      return (
        <ButtonPressed
          fontSize={fontSize}
          buttonPressed={bed.data?.leftLCDButtonPressed}
        />
      );
    case SvDashboardSortingType.legsControllerButtonPressed:
      return (
        <ButtonPressed
          fontSize={fontSize}
          buttonPressed={bed.data?.legsControllerButtonPressed}
        />
      );
    case SvDashboardSortingType.acpAndRoControllerButtonPressed:
      return (
        <ButtonPressed
          fontSize={fontSize}
          buttonPressed={bed.data?.acpAndRoControllerButtonPressed}
        />
      );
    case SvDashboardSortingType.digitalSiderailLeftButtonPressed:
      return (
        <ButtonPressed
          fontSize={fontSize}
          buttonPressed={bed.data?.digitalSiderailLeftButtonPressed}
        />
      );
    case SvDashboardSortingType.digitalSiderailRightButtonPressed:
      return (
        <ButtonPressed
          fontSize={fontSize}
          buttonPressed={bed.data?.digitalSiderailRightButtonPressed}
        />
      );
    case SvDashboardSortingType.ALTError:
      return (
        <Typography fontSize={fontSize}>
          {bed.data?.ALTError || '--'}
        </Typography>
      );
    case SvDashboardSortingType.errorOrStopCode:
      return (
        <Tooltip title={errorMessage}>
          <Typography fontSize={fontSize}>
            {bed.data?.errorOrStopCode || '--'}
          </Typography>
        </Tooltip>
      );
    case SvDashboardSortingType.batteryFailure:
      return (
        <BatteryStatus status={!!bed.data?.batteryFailure} fontSize="1.25em" />
      );
    default:
      return null;
  }
};

interface ButtonPressedProps {
  buttonPressed?: number | null;
  fontSize?: string;
}

export const ButtonPressed = ({
  buttonPressed,
  fontSize,
}: ButtonPressedProps) => {
  const buttons = getButtonOptions();
  const button = buttons.find((b) => b.id === buttonPressed);
  return (
    <Tooltip title={button?.name || false}>
      <Typography fontSize={fontSize}>
        {button?.id ? `${button.id} - ${button.name.substr(0, 3)}...` : '--'}
      </Typography>
    </Tooltip>
  );
};

interface ButtonPressedProps {
  mobiliftStatus?: number | null;
  fontSize?: string;
}

export const Mobilift = ({ mobiliftStatus, fontSize }: ButtonPressedProps) => {
  const mobiliftOptions = getMobiliftOptions();
  const mobilift = mobiliftOptions.find((m) => m.id === mobiliftStatus);
  return (
    <Typography
      sx={{
        whiteSpace: 'nowrap',
        fontSize,
        color: (theme) =>
          mobilift?.name
            ? theme.palette.success.light
            : theme.palette.grey[600],
      }}
    >
      {mobilift?.name || t`OFF`}
    </Typography>
  );
};

const getMobiliftOptions = () => [
  {
    id: 20,
    name: t`ON - Up`,
  },
  {
    id: 22,
    name: t`ON - Down`,
  },
];
