import gql from 'graphql-tag';
import { bedFragment, dashboardBedFragment } from './fragments';

export const bedAddedSubscription = gql`
  subscription BedAdded {
    bedAdded {
      ...BedFragment
    }
  }
  ${bedFragment}
`;

export const bedUpdatedSubscription = gql`
  subscription BedUpdated {
    bedUpdated {
      ...DashboardBedFragment
    }
  }
  ${dashboardBedFragment}
`;

export const roomUpdatedSubscription = gql`
  subscription RoomUpdated($wardId: ID, $roomId: ID) {
    roomUpdated(wardId: $wardId, roomId: $roomId) {
      id
      name
      parkingPlaces {
        id
        tagId
        name
        bed {
          ...DashboardBedFragment
        }
      }
    }
  }
  ${dashboardBedFragment}
`;

export const threeFOne = gql`
  subscription ThreeFOne($roomId: ID, $wardId: ID) {
    threeFOne(roomId: $roomId, wardId: $wardId) {
      unitId
      indication
      state
      pause
      devices {
        IM {
          indication
          monitoring
          state
        }
        CONTROL_UNIT {
          indication
          monitoring
          state
        }
        SERVER {
          indication
          monitoring
          state
        }
        WEIGHTS {
          indication
          monitoring
          state
        }
        BED_EXIT {
          indication
          monitoring
          state
        }
        VITAL_MONITOR {
          indication
          monitoring
          state
        }
        LOCALIZATION {
          indication
          monitoring
          state
        }
        SIDERAILS {
          indication
          monitoring
          state
        }
        BRAKES {
          indication
          monitoring
          state
        }
        BACK_SIDE_POS {
          indication
          monitoring
          state
        }
        BED_HEIGHT {
          indication
          monitoring
          state
        }
      }
    }
  }
`;

export const threeFF = gql`
  subscription ThreeFF($roomId: ID, $wardId: ID, $bedIds: [ID!]) {
    threeFF(roomId: $roomId, wardId: $wardId, bedIds: $bedIds) {
      unitId
      weight
      zAxisDeg
      power
      siderailBothRUp
      siderailBothLUp
      siderailHeadRUp
      siderailHeadLUp
      siderailMiddleRUp
      siderailMiddleLUp
      safetyPosition
      back30DegGt
      posIsLowest
      brakesOff
      bedExitOn
      ALTPhase
      isZoneExit
      calfrestLock
      backrestLock
      thighrestLock
      mattressType
      bedExitAlarm
      trendelenburgDeg
      lateralTiltDeg
      lAxisDeg
      heightLock
      patientOnBed
      paused
      tagId
      tagCharge

      charging
      transfer
      heightOfRLegColumn
      heightOfLLegColumn
      heightOfHeadColumn
      mobiliftRight
      mobiliftLeft

      bedExtension
      pressedBtnAndUsedController
      rightLCDButtonPressed
      leftLCDButtonPressed
      legsControllerButtonPressed
      acpAndRoControllerButtonPressed
      digitalSiderailRightButtonPressed
      digitalSiderailLeftButtonPressed
      ALTError
      errorOrStopCode
      batteryFailure
    }
  }
`;

export const hl7ConnectionStatus = gql`
  subscription HL7ConnectionStatus {
    hl7ConnectionStatus {
      id
      status
      length
    }
  }
`;
