import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  ModalProps,
} from '@mui/material';
import React from 'react';
import { ModalHeader } from './ModalHeader';

type Props = DialogProps & {
  title?: string;
  onClose: NonNullable<ModalProps['onClose']>;
  actions?: React.ReactNode;
};

const BaseModal = ({
  title,
  onClose,
  actions,
  children,
  ...dialogProps
}: Props) => {
  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogTitle>
        <ModalHeader
          title={title}
          onClose={() => onClose({}, 'escapeKeyDown')}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default BaseModal;
