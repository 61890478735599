import { t } from '@lingui/macro';
import {
  SessionDataQuery_sessionData,
  SafetyPortProps,
} from '../../../graphql/types';
import { sub } from 'date-fns';
import { notEmpty } from 'src/lib/notEmpty';

export enum GraphType {
  'weight' = 'weight',
  'safetyPosition' = 'safetyPosition',
  'trendelenburg' = 'trendelenburg',
  'backrestAngle' = 'backrestAngle',
  'leftBothSiderails' = 'leftBothSiderails',
  'rightBothSiderails' = 'rightBothSiderails',
  'brakes' = 'brakes',
  'lateral' = 'lateral',
  'bedExitStatus' = 'bedExitStatus',
  'bedExitAlarm' = 'bedExitAlarm',
  'isZoneExit' = 'isZoneExit',
  'lowestPosition' = 'lowestPosition',
  'outOfBed' = 'outOfBed',
  'leftHeadSiderail' = 'leftHeadSiderail',
  'rightHeadSiderail' = 'rightHeadSiderail',
  'leftSiderail' = 'leftSiderail',
  'rightSiderail' = 'rightSiderail',
  'calfrestAngle' = 'calfrestAngle',
  'calfrestLock' = 'calfrestLock',
  'backrestLock' = 'backrestLock',
  'heightLock' = 'heightLock',
  'thighrestLock' = 'thighrestLock',
  'maxMattressType' = 'maxMattressType',
  'maxALTPhase' = 'maxALTPhase',
}

export type PatientDataValuesType = ({
  time?: string | null;
  value?: null | number;
} | null)[];

export interface PatientDataType {
  type: GraphType;
  sortingPosition: number;
  values: PatientDataValuesType;
}

const invertDatapointValue = (value?: number | null) => {
  if (value != null) {
    return (value - 2) * -1;
  } else return null;
};

const getTypeData = (
  type: GraphType,
  data: SessionDataQuery_sessionData | null,
) => {
  switch (type) {
    case GraphType.leftBothSiderails:
      return { time: data?.time, value: data?.srblUp };
    case GraphType.rightBothSiderails:
      return { time: data?.time, value: data?.srbrUp };
    case GraphType.brakes:
      return { time: data?.time, value: data?.brakes };
    case GraphType.bedExitStatus:
      return { time: data?.time, value: data?.bedExitStatus };
    case GraphType.bedExitAlarm:
      return {
        time: data?.time,
        value: data?.bedExitAlarm && data.bedExitAlarm > 0 ? 1 : null,
      };
    case GraphType.isZoneExit:
      return {
        time: data?.time,
        value:
          data?.isZoneExit != null &&
          data.bedExitStatus &&
          data.bedExitStatus > 0
            ? data?.isZoneExit
            : null,
      };
    case GraphType.lowestPosition:
      return { time: data?.time, value: data?.lowestPosition };
    case GraphType.outOfBed:
      return {
        time: data?.time,
        value: invertDatapointValue(data?.outOfBed),
      };
    case GraphType.leftHeadSiderail:
      return { time: data?.time, value: data?.srlhUp };
    case GraphType.rightHeadSiderail:
      return { time: data?.time, value: data?.srrhUp };
    case GraphType.leftSiderail:
      return { time: data?.time, value: data?.srlUp };
    case GraphType.rightSiderail:
      return { time: data?.time, value: data?.srrUp };
    case GraphType.calfrestLock:
      return { time: data?.time, value: data?.calfrestLock };
    case GraphType.backrestLock:
      return { time: data?.time, value: data?.backrestLock };
    case GraphType.thighrestLock:
      return { time: data?.time, value: data?.thighrestLock };
    case GraphType.safetyPosition:
      return { time: data?.time, value: data?.safetyPosition };
    case GraphType.heightLock:
      return { time: data?.time, value: data?.heightLock };
    case GraphType.maxMattressType:
      return {
        time: data?.time,
        value:
          data?.maxMattressType != null
            ? data.maxMattressType > 2
              ? 2
              : 0
            : null,
      };
    case GraphType.maxALTPhase:
      return {
        time: data?.time,
        value:
          data?.maxALTPhase != null ? (data.maxALTPhase > 2 ? 2 : 0) : null,
      };
    default:
      return null;
  }
};

export const getGraphRangeTexts = (type: GraphType) => {
  switch (type) {
    case GraphType.leftBothSiderails:
      return [t`ALL UP`, t`ANY DOWN`];
    case GraphType.rightBothSiderails:
      return [t`ALL UP`, t`ANY DOWN`];
    case GraphType.brakes:
      return [t`ON`, t`OFF`];
    case GraphType.bedExitStatus:
      return [t`ON`, t`OFF`];
    case GraphType.bedExitAlarm:
      return [];
    case GraphType.isZoneExit:
      return [t`INNER`, t`OUTER`];
    case GraphType.lowestPosition:
      return [t`YES`, t`NO`];
    case GraphType.outOfBed:
      return [t`IN`, t`OUT`];
    case GraphType.leftHeadSiderail:
    case GraphType.rightHeadSiderail:
    case GraphType.leftSiderail:
    case GraphType.rightSiderail:
      return [t`UP`, t`DOWN`];
    case GraphType.calfrestLock:
    case GraphType.backrestLock:
    case GraphType.thighrestLock:
    case GraphType.heightLock:
      return [t`ON`, t`OFF`];
    case GraphType.maxMattressType:
      return [t`YES`, t`NO`];
    case GraphType.maxALTPhase:
      return [t`ON`, t`OFF`];
    case GraphType.backrestAngle:
      return [];
    case GraphType.lateral:
      return [t`R`, t`L`];
    case GraphType.trendelenburg:
      return [t`TX`, t`ATX`];
    case GraphType.calfrestAngle:
      return [t`+`, t`-`];
    case GraphType.safetyPosition:
      return [t`YES`, t`NO`];
    default:
      return [];
  }
};

export const preparedData = (data: PatientDataValuesType) => {
  return data
    .map((item) => {
      if (!item) {
        return null;
      }
      return {
        value: item.value ?? undefined,
        time: item.time,
      };
    })
    .filter(notEmpty);
};

const getMinMaxValues = (
  minValue: number | null,
  maxValue: number | null,
  date: Date,
  isSmartWeight?: boolean,
) => {
  let min;
  let max;
  if (isSmartWeight) {
    min = {
      time: sub(date, { minutes: 2 }).toISOString(),
      value: minValue !== null ? (minValue >= 0 ? minValue : 0) : null,
    };
    max = {
      time: date.toISOString(),
      value: maxValue !== null ? (maxValue >= 0 ? maxValue : 0) : null,
    };
  } else {
    min = {
      time: sub(date, { minutes: 2 }).toISOString(),
      value: minValue != null ? minValue : null,
    };
    max = {
      time: date.toISOString(),
      value: maxValue != null ? maxValue : null,
    };
  }
  return { min, max };
};

const getCurveData = (
  type: GraphType,
  data: SessionDataQuery_sessionData,
  time: string,
) => {
  const date = new Date(time);
  let result;
  if (type === GraphType.weight) {
    result = getMinMaxValues(data.minWeight, data.maxWeight, date, true);
  } else if (type === GraphType.backrestAngle) {
    result = getMinMaxValues(
      data.minBackrestAngle,
      data.maxBackrestAngle,
      date,
    );
  } else if (type === GraphType.trendelenburg) {
    result = getMinMaxValues(
      data.minTrendelenburg != null ? -data.minTrendelenburg : null, //changed trendelenburg value polarity
      data.maxTrendelenburg != null ? -data.maxTrendelenburg : null, //changed trendelenburg value polarity
      date,
    );
  } else if (type === GraphType.lateral) {
    result = getMinMaxValues(data.minLateralTilt, data.maxLateralTilt, date);
  } else if (type === GraphType.calfrestAngle) {
    result = getMinMaxValues(
      data.minCalfrestAngle,
      data.maxCalfrestAngle,
      date,
    );
  } else {
    return null;
  }
  return { min: result.min, max: result.max };
};

export const isCurve = (type: GraphType) => {
  switch (type) {
    case GraphType.weight:
    case GraphType.backrestAngle:
    case GraphType.trendelenburg:
    case GraphType.lateral:
    case GraphType.calfrestAngle:
      return true;

    default:
      return false;
  }
};

export const getPatientData = (
  data: (SessionDataQuery_sessionData | null)[] | undefined,
  order: string[] | undefined,
) => {
  const patientData: PatientDataType[] = [
    {
      type: GraphType.weight,
      sortingPosition: 0,
      values: [],
    },
    {
      type: GraphType.backrestAngle,
      sortingPosition: 1,
      values: [],
    },
    {
      type: GraphType.trendelenburg,
      sortingPosition: 2,
      values: [],
    },
    {
      type: GraphType.brakes,
      sortingPosition: 3,
      values: [],
    },
    {
      type: GraphType.lateral,
      sortingPosition: 4,
      values: [],
    },
    {
      type: GraphType.bedExitStatus,
      sortingPosition: 5,
      values: [],
    },
    {
      type: GraphType.bedExitAlarm,
      sortingPosition: 6,
      values: [],
    },
    {
      type: GraphType.leftBothSiderails,
      sortingPosition: 7,
      values: [],
    },
    {
      type: GraphType.rightBothSiderails,
      sortingPosition: 8,
      values: [],
    },
    {
      type: GraphType.isZoneExit,
      sortingPosition: 9,
      values: [],
    },
    {
      type: GraphType.lowestPosition,
      sortingPosition: 10,
      values: [],
    },
    {
      type: GraphType.outOfBed,
      sortingPosition: 11,
      values: [],
    },
    {
      type: GraphType.leftHeadSiderail,
      sortingPosition: 12,
      values: [],
    },
    {
      type: GraphType.rightHeadSiderail,
      sortingPosition: 13,
      values: [],
    },
    {
      type: GraphType.leftSiderail,
      sortingPosition: 14,
      values: [],
    },
    {
      type: GraphType.rightSiderail,
      sortingPosition: 15,
      values: [],
    },
    {
      type: GraphType.calfrestAngle,
      sortingPosition: 16,
      values: [],
    },
    {
      type: GraphType.calfrestLock,
      sortingPosition: 17,
      values: [],
    },
    {
      type: GraphType.backrestLock,
      sortingPosition: 18,
      values: [],
    },
    {
      type: GraphType.thighrestLock,
      sortingPosition: 19,
      values: [],
    },
    {
      type: GraphType.heightLock,
      sortingPosition: 20,
      values: [],
    },
    {
      type: GraphType.maxMattressType,
      sortingPosition: 21,
      values: [],
    },
    {
      type: GraphType.maxALTPhase,
      sortingPosition: 22,
      values: [],
    },
    {
      type: GraphType.safetyPosition,
      sortingPosition: 23,
      values: [],
    },
  ];
  const orderByType = (order || []).reduce((acc, val, idx) => {
    return { ...acc, [val]: idx };
  }, {} as Record<string, number>);

  data?.forEach((newData) => {
    patientData.forEach((data) => {
      if (isCurve(data.type)) {
        if (newData && newData.time != null) {
          const result = getCurveData(data.type, newData, newData.time);
          if (result) {
            data.values.push(result.min, result.max);
          }
        }
      } else {
        data.values.push(getTypeData(data.type, newData));
      }
    });
  });
  patientData.forEach((data) => {
    data.sortingPosition = orderByType[data.type];
  });
  return patientData.sort((record, otherRecord) => {
    return record.sortingPosition - otherRecord.sortingPosition;
  });
};

export const graphLenght = 1380;
export const graphView = 690;

export const getPatientViewTrans = (type: GraphType) => {
  switch (type) {
    case GraphType.weight:
      return t`LINIS0001`;
    case GraphType.leftBothSiderails:
      return t`LINIS0020_short`;
    case GraphType.rightBothSiderails:
      return t`LINIS0021_short`;
    case GraphType.backrestAngle:
      return t`LINIS0007`;
    case GraphType.calfrestAngle:
      return t`LINIS0022`;
    case GraphType.trendelenburg:
      return t`LINIS0003`;
    case GraphType.brakes:
      return t`LINIS0008`;
    case GraphType.safetyPosition:
      return t`LINIS0002`;
    case GraphType.lateral:
      return t`LINIS0004`;
    case GraphType.bedExitStatus:
      return t`LINIS0009_short`;
    case GraphType.bedExitAlarm:
      return t`LINIS0010`;
    case GraphType.isZoneExit:
      return t`Bed Exit Zone`;
    case GraphType.lowestPosition:
      return t`LINIS0011`;
    case GraphType.outOfBed:
      return t`LINIS0012`;
    case GraphType.leftHeadSiderail:
      return t`LINIS0014_short`;
    case GraphType.rightHeadSiderail:
      return t`LINIS0015_short`;
    case GraphType.leftSiderail:
      return t`LINIS0016`;
    case GraphType.rightSiderail:
      return t`LINIS0017`;
    case GraphType.calfrestLock:
      return t`LINIS0023`;
    case GraphType.backrestLock:
      return t`LINIS0024`;
    case GraphType.thighrestLock:
      return t`LINIS0026`;
    case GraphType.heightLock:
      return t`LINIS0025`;
    case GraphType.maxMattressType:
      return t`LINIS0035_dashboard`;
    case GraphType.maxALTPhase:
      return t`LINIS0039_short`;
    default:
      return null;
  }
};

export const getCodeByName = (type: GraphType) => {
  switch (type) {
    case GraphType.weight:
      return SafetyPortProps.LINIS0001;
    case GraphType.leftBothSiderails:
      return SafetyPortProps.LINIS0020;
    case GraphType.rightBothSiderails:
      return SafetyPortProps.LINIS0021;
    case GraphType.backrestAngle:
      return SafetyPortProps.LINIS0007;
    case GraphType.calfrestAngle:
      return SafetyPortProps.LINIS0022;
    case GraphType.trendelenburg:
      return SafetyPortProps.LINIS0003;
    case GraphType.brakes:
      return SafetyPortProps.LINIS0008;
    case GraphType.safetyPosition:
      return SafetyPortProps.LINIS0002;
    case GraphType.lateral:
      return SafetyPortProps.LINIS0004;
    case GraphType.bedExitStatus:
      return SafetyPortProps.LINIS0009;
    case GraphType.bedExitAlarm:
      return SafetyPortProps.LINIS0010;
    case GraphType.isZoneExit:
      return SafetyPortProps.LINIS0009;
    case GraphType.lowestPosition:
      return SafetyPortProps.LINIS0011;
    case GraphType.outOfBed:
      return SafetyPortProps.LINIS0012;
    case GraphType.leftHeadSiderail:
      return SafetyPortProps.LINIS0014;
    case GraphType.rightHeadSiderail:
      return SafetyPortProps.LINIS0015;
    case GraphType.leftSiderail:
      return SafetyPortProps.LINIS0016;
    case GraphType.rightSiderail:
      return SafetyPortProps.LINIS0017;
    case GraphType.calfrestLock:
      return SafetyPortProps.LINIS0023;
    case GraphType.backrestLock:
      return SafetyPortProps.LINIS0024;
    case GraphType.thighrestLock:
      return SafetyPortProps.LINIS0026;
    case GraphType.heightLock:
      return SafetyPortProps.LINIS0025;
    case GraphType.maxMattressType:
      return SafetyPortProps.LINIS0035;
    case GraphType.maxALTPhase:
      return SafetyPortProps.LINIS0039;
    default:
      return null;
  }
};

export const isFromZeroType = (type: GraphType) => {
  switch (type) {
    case GraphType.weight:
      return true;
    default:
      return false;
  }
};
