import { FormikProps } from 'formik';
import SafetyPortConfig from './SafetyPortConfig';
import { SafetyPortFormValues } from './superAdminHelper';

const ConfigForm = ({
  values,
  setFieldValue,
  handleBlur,
  handleChange,
}: FormikProps<SafetyPortFormValues>) => {
  return (
    <SafetyPortConfig
      values={values}
      setFieldValue={setFieldValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
    />
  );
};

export default ConfigForm;
