import { useQuery } from '@apollo/client';
import { CircularProgress, IconButton, Paper, styled } from '@mui/material';
import { reportingReportQuery, wardQuery } from 'src/graphql/queries';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Flex from 'src/components/Flex';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import {
  ReportingReportQuery,
  ReportingReportQueryVariables,
  WardQuery,
  WardQueryVariables,
} from 'src/graphql/types';
import ReportGraphCard from './ReportGraphCard';
import { useReportFilter } from './useReportFilter';
import { ReportFilterParams } from '../types';
import { getDateInputFormat } from 'src/helpers';
import { mapDateIntervalsToTranslates } from 'src/lib/datePresetHelper';
import { useMemo } from 'react';

interface Props {
  reportId: string;
  setDraggable: (drag: boolean) => void;
}

const getPeriod = (filterData: ReportFilterParams) => {
  const { from, to, preset } = filterData.period;
  if (filterData.period.to) {
    return `${getDateInputFormat(from)}-${getDateInputFormat(to)}`;
  }
  const dateIntervalTrans = mapDateIntervalsToTranslates();
  return dateIntervalTrans[preset];
};

const ReportItemGraph = ({
  filterConfig,
  reportName,
  reportGoal,
}: {
  filterConfig: string;
  reportName: string;
  reportGoal?: number | null;
}) => {
  const filterData = useMemo(() => JSON.parse(filterConfig), [filterConfig]);
  const { data, loading } = useReportFilter(filterData);
  const firstWardId = filterData.locationFilter?.wardIds?.[0];

  const { data: wardData } = useQuery<WardQuery, WardQueryVariables>(
    wardQuery,
    {
      variables: {
        id: firstWardId as string,
      },
      skip:
        firstWardId == null || filterData.locationFilter?.wardIds?.length !== 1,
    },
  );
  const infoText = `${getPeriod(filterData)}${
    wardData?.ward ? ` - ${wardData.ward.name}` : ''
  }`;

  return (
    <ItemContent>
      {loading || !data ? (
        <CircularProgress />
      ) : (
        <ReportGraphCard
          data={data}
          filter={filterData}
          goal={reportGoal}
          sx={{ width: '100%', height: '100%' }}
          title={reportName}
          subTitle={infoText}
        />
      )}
    </ItemContent>
  );
};

const ReportDraggableGraphItem = ({ reportId, setDraggable }: Props) => {
  const { data: reportingReportData } = useQuery<
    ReportingReportQuery,
    ReportingReportQueryVariables
  >(reportingReportQuery, {
    variables: {
      id: reportId,
    },
  });
  const goal = reportingReportData?.reportingReport.goal;
  const filterConfig = reportingReportData?.reportingReport.filterConfig;

  return (
    <Paper sx={{ height: '100%' }}>
      <TopBar>
        <IconButton
          sx={{ padding: '4px ' }}
          component={Link}
          to={
            goal == null
              ? `/reporting/reports/${reportId}`
              : `/reporting/goals/${reportId}`
          }
        >
          <EditIcon />
        </IconButton>
        <IconButton
          sx={{ padding: '4px ' }}
          onMouseLeave={() => setDraggable(false)}
          onMouseOver={() => setDraggable(true)}
        >
          <DragIndicatorIcon />
        </IconButton>
      </TopBar>
      {filterConfig && (
        <ReportItemGraph
          filterConfig={filterConfig}
          reportName={reportingReportData?.reportingReport?.name}
          reportGoal={reportingReportData?.reportingReport?.goal}
        />
      )}
    </Paper>
  );
};

export default ReportDraggableGraphItem;

const TopBar = styled(Flex)`
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px 8px;
`;

const ItemContent = styled(Flex)`
  height: 100%;
  justify-content: center;
  align-items: center;
`;
