// tslint:disable-next-line:ordered-imports
import 'core-js';
import 'react-app-polyfill/ie11';

import Raven from 'raven-js';
import { ApolloProvider } from '@apollo/client';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import App from './App';
import I18nLoader from './components/I18nLoader';
import apolloClient from './createApollo';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AuthProvider from './auth/AuthProvider';
import SnackbarProvider from './providers/SnackbarProvider';

Raven.config('https://13c7823b6d2b444db92e6ebc250c1765@sentry.io/1421674', {
  shouldSendCallback: (data) => {
    return (
      process.env.NODE_ENV === 'production' &&
      process.env.SENTRY_ENABLED === 'true'
    );
  },
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_RELEASE,
}).install();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <SnackbarProvider>
      <AuthProvider>
        <I18nLoader>
          <App />
        </I18nLoader>
      </AuthProvider>
    </SnackbarProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);

serviceWorkerRegistration.unregister();
