import {
  ConfigQuery_config,
  ConnectionsQuery_connections,
  ConnectionsQuery_connections_props,
} from '../../../graphql/types';

export enum Groups {
  patientSafety = 'patientSafety',
  bedManagement = 'bedManagement',
  respiratoryAndMobilization = 'respiratoryAndMobilization',
  utilization = 'utilization',
  connectionDetail = 'connectionDetail',
}

export interface SpEnabledGroupsType {
  patientEnabled: boolean;
  bedManagementEnabled: boolean;
  respiratoryMobilizationEnabled: boolean;
  utilizationEnabled: boolean;
}

export interface SettingsState {
  hospitalName: string;
  defaultPause: number;
  unitSystem: WeightUnits;
  noteConfirmationPeriod: number;
  logoURL?: string;
}

export interface ConnectionSettingsState {
  __typename: 'Connection';
  id: string;
  enabled: boolean;
  name: string;
  ip: string | null;
  port: number | null;
  synchronous: boolean;
  syncPeriod: number | null;
}

export interface GroupSettingState {
  threshold: number | null;
}

export enum WeightUnits {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
}

export interface ControlStateType {
  type: string;
  icon: string;
  switchState: boolean;
  thresholdAllowed?: boolean;
  threshold: number | null;
  group: Groups;
}

export const getGroupDatapoints = (data: ControlStateType[], type: Groups) => {
  if (data) return data.filter((control) => control.group === type);
  else return [];
};

export const isSettingsChanged = (
  config: ConfigQuery_config | null | undefined,
  settings: SettingsState,
) => {
  if (!config) {
    return false;
  }
  return (
    config.hospitalName !== settings.hospitalName ||
    config.defaultPause !== settings.defaultPause ||
    config.unitSystem !== settings.unitSystem ||
    config.noteConfirmationPeriod !== settings.noteConfirmationPeriod ||
    config.logoURL !== settings.logoURL
  );
};

export const isConnectionSettingsChanged = (
  config: ConnectionsQuery_connections | null | undefined,
  settings: ConnectionSettingsState | null,
  controlState: ControlStateType[],
) => {
  if (!config || !settings) {
    return false;
  }
  if (
    config.id !== settings.id ||
    config.enabled !== settings.enabled ||
    config.ip !== settings.ip ||
    config.port !== settings.port ||
    config.name !== settings.name ||
    config.syncPeriod !== settings.syncPeriod ||
    config.synchronous !== settings.synchronous
  ) {
    return true;
  }
  const propMap = config.props.reduce((acc, item) => {
    return { ...acc, [item.key]: item };
  }, {} as Record<string, ConnectionsQuery_connections_props>);

  return controlState.some((ctrl) => {
    const prop = propMap[ctrl.type];
    return (
      ctrl.switchState !== prop.enabled ||
      (ctrl.switchState && ctrl.threshold !== prop.threshold)
    );
  });
};

export const initSettingsState = {
  hospitalName: '',
  defaultPause: 0,
  unitSystem: WeightUnits.METRIC,
  noteConfirmationPeriod: 5,
  spLicenceState: {
    patientEnabled: false,
    mobilizationEnabled: false,
    servisEnabled: false,
    usageControlsEnabled: false,
  },
  spLicence: false,
};

export const initConnectionSettingsState = {
  id: '',
  name: '',
  ip: '',
  port: null,
  synchronous: true,
  syncPeriod: 5,
};
