import React, { forwardRef } from 'react';
import { LineChart, Line, DotProps, Rectangle, YAxis } from 'recharts';
import { isCurve, GraphType, PatientDataType } from '../helpers/patientView';
import { exportGraphLenght } from './PatientViewExport';
import { useTheme } from '@mui/material';

interface Props {
  item: PatientDataType;
}

const preparedExportData = (data: any, isCurve: boolean, type: any) => {
  const preparedData = data.map((item: any) => {
    if (isCurve) {
      return {
        value: item?.value ?? undefined,
        time: item?.time,
      };
    } else if (type === GraphType.bedExitAlarm) {
      return {
        center: item?.value && item.value > 0 ? 0 : undefined,
        time: item?.time,
      };
    } else {
      return {
        time: item?.time,
        up: item?.value === 2 ? 0.5 : undefined,
        center: item?.value === 1 ? 0 : undefined,
        down: item?.value === 0 ? -0.5 : undefined,
      };
    }
  });
  return preparedData;
};

const PrerenderedViewGraph = ({ item }: Props, ref: any) => {
  const theme = useTheme();
  return (
    <LineChart
      key={`exportGraph-${item.type}`}
      ref={ref}
      width={exportGraphLenght * 2}
      height={200}
      data={preparedExportData(item.values, isCurve(item.type), item.type)}
    >
      {item.type !== GraphType.weight &&
        (isCurve(item.type) ? (
          <YAxis
            hide
            type="number"
            domain={
              item.type === GraphType.backrestAngle
                ? [0, 65]
                : item.type === GraphType.trendelenburg
                ? [-20, 20]
                : [-30, 30]
            }
          />
        ) : (
          <YAxis type="number" hide domain={[-1, 1]} />
        ))}
      <Line
        isAnimationActive={false}
        dot={false}
        type="monotone"
        dataKey="value"
        stroke={theme.palette.common.graphLine}
        strokeWidth={2}
      />
      <Line
        isAnimationActive={false}
        dot={(dot) => CustomDot(dot, theme.palette.common.barsPrimary, 50)}
        type="monotone"
        dataKey="up"
        stroke={theme.palette.common.barsPrimary}
        strokeWidth={50}
      />
      <Line
        isAnimationActive={false}
        dot={(dot) =>
          CustomDot(
            dot,
            item.type === GraphType.bedExitAlarm
              ? theme.palette.error.main
              : theme.palette.primary.main,
            item.type === GraphType.bedExitAlarm ? 50 : 145,
          )
        }
        type="monotone"
        dataKey="center"
        stroke={
          item.type === GraphType.bedExitAlarm
            ? theme.palette.error.main
            : theme.palette.primary.main
        }
        strokeWidth={item.type === GraphType.bedExitAlarm ? 50 : 145}
      />
      <Line
        isAnimationActive={false}
        dot={(dot) => CustomDot(dot, theme.palette.common.barsPrimary, 50)}
        type="monotone"
        dataKey="down"
        stroke={theme.palette.common.barsPrimary}
        strokeWidth={50}
      />
    </LineChart>
  );
};

export default forwardRef(PrerenderedViewGraph);

const CustomDot = (
  dotData: DotProps & { payload: any },
  color: string,
  height: number,
) => {
  if (dotData.cy) {
    return (
      <Rectangle
        key={`dot-${dotData.cx}x${dotData.cy}`}
        fill={color}
        x={dotData.cx}
        y={dotData.cy - height / 2}
        height={height}
        width={4}
        color={color}
      />
    );
  }
  return <React.Fragment />;
};
