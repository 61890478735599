import { styled, TextField, TextFieldProps } from '@mui/material';
import { isValid } from 'date-fns';
import { getDateForDatetimeLocalInput } from 'src/helpers';

type Props = Omit<TextFieldProps, 'onChange' | 'defaultValue'> & {
  label?: string;
  min?: string | Date | null;
  defaultValue?: string | Date | null;
  max?: string | Date | null;
  value?: string | Date | null;
  onChange: (date: Date) => void;
};

const DateTimeInput = ({
  label,
  min,
  max,
  value,
  defaultValue,
  onChange,
  ...textFieldProps
}: Props) => {
  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const date = new Date(value);
    isValid(date) && onChange(date);
  };
  return (
    <StyledTextField
      {...textFieldProps}
      label={label}
      type="datetime-local"
      inputProps={{
        ...textFieldProps.inputProps,
        min: getDateForDatetimeLocalInput(min),
        max: getDateForDatetimeLocalInput(max),
      }}
      value={getDateForDatetimeLocalInput(value)}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        flexShrink: 0,
        alignItems: 'flex-start',
        '& .MuiOutlinedInput-root': {
          marginTop: 0,
        },
        ...textFieldProps.sx,
      }}
    />
  );
};

export default DateTimeInput;

const StyledTextField = styled(TextField)`
  .MuiInputBase-formControl {
    width: 100%;
  }
`;
