import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { createTheme } from '@mui/material/styles';
import { linetColors } from 'src/colors';
import { insetShadows } from 'src/lib/insetShadows';

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    gray: string;
    green: string;
    yellow: string;
    red: string;
    divider: string;
    datapointViewGraph: string;
    mobilizationBg: string;
    mobilizationLine: string;
    graphLine: string;
    barsPrimary: string;
    infoIcon: string;
    mobileTopBar: string;
    tooltipBg: string;
    tooltipText: string;
  }
}

export const lightTheme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      'GTAmerica',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    caption: {
      color: linetColors.greyDark,
      fontSize: '0.875rem',
      textTransform: 'uppercase',
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 1600,
      md: 1920,
      lg: 2560,
      xl: 3840,
      xxl: 4000,
    },
  },
  palette: {
    primary: {
      main: linetColors.blue,
      contrastText: linetColors.white,
    },
    error: {
      main: linetColors.red, // there should be light red
    },
    grey: {
      // 100: linetColors.bgGreyVeryLight,
      200: linetColors.bgGreyLight,
      // 300: linetColors.greyLighter,
      400: linetColors.greyLight,
      500: linetColors.greyMain,
      600: linetColors.greyMainDark,
      // 700: linetColors.greyDark,
      // 800: linetColors.greyDarker,
      // 900: linetColors.bgGreyDark2,
      // A100: linetColors.bgGreyDark,
    },
    success: {
      main: linetColors.green,
      light: linetColors.greenLight,
    },
    warning: {
      main: linetColors.orangeLight, // there should be light orange
    },
    text: {
      primary: linetColors.greyDark,
      secondary: linetColors.greyMain,
      disabled: linetColors.greyMain,
    },
    common: {
      divider: linetColors.greyMain,
      datapointViewGraph: linetColors.greyMain,
      mobilizationBg: linetColors.bgGreyLight,
      mobilizationLine: linetColors.greyLight,
      graphLine: linetColors.greyDarker,
      barsPrimary: linetColors.greyMainDark,
      white: linetColors.white,
      black: linetColors.black,
      green: linetColors.green,
      red: linetColors.red,
      yellow: linetColors.orange,
      gray: linetColors.greyMain,
      infoIcon: linetColors.greyLight,
      mobileTopBar: linetColors.bgGreyLight,
      tooltipBg: linetColors.greyMain,
      tooltipText: linetColors.bgGreyLight,
    },
    background: {
      default: linetColors.bgGreyVeryLight,
      paper: linetColors.bgGreyVeryLight,
    },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <KeyboardArrowDownIcon />,
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            marginTop: 0,
          },
        },
        popper: {
          paddingTop: '12px',
          paddingBottom: '12px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            border: `solid 2px ${linetColors.greyLight}`,
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
              {
                backgroundColor: linetColors.bgGreyLight,
                color: linetColors.blue,
              },
            '& .Mui-focused': {
              backgroundColor: linetColors.bgGreyLight,
              color: linetColors.blue,
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: linetColors.slider,
          '& .MuiSlider-thumb': {
            borderRadius: 0,
            color: linetColors.blue,
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root.MuiMenu-paper': {
            borderRadius: '10px',
            marginTop: '12px',
            marginBottom: '12px',
            border: `solid 2px ${linetColors.greyLight}`,
            '& .MuiMenu-list .MuiMenuItem-root[aria-selected="true"]': {
              backgroundColor: linetColors.bgGreyLight,
              color: linetColors.blue,
            },
            '& .MuiMenu-list .MuiMenuItem-root:hover': {
              backgroundColor: linetColors.bgGreyLight,
              color: linetColors.blue,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& ::-webkit-scrollbar': {
            width: '10px',
          },
          '& ::-webkit-scrollbar-track': {
            background: linetColors.greyLighter,
            border: '4px solid transparent',
            backgroundClip: 'content-box',
          },
          '& ::-webkit-scrollbar-thumb': {
            background: linetColors.blue,
            borderRadius: '30px',
          },
          '& ::-webkit-scrollbar-thumb:hover': {
            background: linetColors.blueDark,
          },
          '& .MuiDialog-paper': {
            backgroundImage: 'none',
            borderRadius: '10px',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          paddingLeft: '18px',
          paddingRight: '18px',
          color: linetColors.blue,
          borderColor: linetColors.blue,
          textTransform: 'capitalize',
          '&.Mui-selected': {
            backgroundColor: linetColors.blue,
            color: linetColors.white,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          letterSpacing: '0.03em',
          fontSize: '0.875rem',
          borderRadius: '40px',
          textTransform: 'none',
        },
        outlined: {
          borderWidth: '1.5px',
          '&:hover': {
            borderWidth: '1.5px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&&.Mui-disabled:before': {
            border: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: '0.875rem',
            color: linetColors.greyDark,
            transform: 'none',
            position: 'relative',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginTop: '0.25rem',
          borderRadius: '10px',
          '&.Mui-disabled': {
            backgroundColor: linetColors.bgGreyLight,
            color: linetColors.greyMain,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
            '& legend': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-track': {
            backgroundColor: linetColors.greyMain,
          },
        },
        switchBase: {
          color: linetColors.bgGreyLight,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root .MuiTableCell-root': {
            backgroundColor: linetColors.greyDark,
            color: linetColors.bgGreyVeryLight,
            textTransform: 'uppercase',
          },
          '& .MuiTableHead-root .MuiIconButton-root': {
            color: linetColors.bgGreyVeryLight,
          },
          '& .MuiTableBody-root .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
              backgroundColor: linetColors.bgGreyVeryLight,
            },
            '&:nth-of-type(even)': {
              backgroundColor: linetColors.bgGreyLight,
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: linetColors.greyDark,
          fontWeight: 500,
          fontSize: '1rem',
          borderColor: 'transparent',
          borderBottom: 0,
        },
      },
    },
  },
  insetShadows: insetShadows,
});

export const mobileLightTheme = createTheme(lightTheme, {
  typography: {
    caption: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            maxWidth: '100%',
            margin: '1rem',
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      'GTAmerica',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    caption: {
      color: linetColors.bgGreyLight,
      fontSize: '0.875rem',
      textTransform: 'uppercase',
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 1600,
      md: 1920,
      lg: 2560,
      xl: 3840,
      xxl: 4000,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: linetColors.blue,
      contrastText: linetColors.white,
    },
    error: {
      main: linetColors.redDark, // there should be dark red
    },
    grey: {
      // 100: linetColors.bgGreyVeryLight,
      200: linetColors.greyDark, //done
      // 300: linetColors.greyLighter,
      400: linetColors.greyLight, // done
      500: linetColors.greyMain, // done
      600: linetColors.greyDarker, // done
      // 700: linetColors.greyDark,
      // 800: linetColors.greyDarker,
      // 900: linetColors.bgGreyDark2,
      // A100: linetColors.bgGreyDark,
    },
    success: {
      main: linetColors.green,
      light: linetColors.greenDark,
    },
    warning: {
      main: linetColors.orange, // there should be dark orange
    },
    text: {
      primary: linetColors.bgGreyLight,
      secondary: linetColors.greyLight,
      disabled: linetColors.greyLight,
    },
    common: {
      divider: linetColors.black,
      datapointViewGraph: linetColors.greyLight,
      mobilizationBg: linetColors.greyMain,
      mobilizationLine: linetColors.bgGreyLight,
      graphLine: linetColors.greyDarker,
      barsPrimary: linetColors.greyMainDark,
      white: linetColors.white,
      black: linetColors.black,
      green: linetColors.green,
      red: linetColors.red,
      yellow: linetColors.orange,
      gray: linetColors.greyMain,
      infoIcon: linetColors.greyMain,
      mobileTopBar: linetColors.bgGreyDark,
      tooltipBg: linetColors.greyMain,
      tooltipText: linetColors.bgGreyLight,
    },
    background: {
      default: linetColors.bgDarkBackground,
      paper: linetColors.bgGreyDark,
    },
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <KeyboardArrowDownIcon />,
      },
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            marginTop: 0,
          },
        },
        popper: {
          paddingTop: '12px',
          paddingBottom: '12px',
          '& .MuiPaper-root': {
            borderRadius: '10px',
            border: `solid 2px ${linetColors.greyDarker}`,
            '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
              {
                backgroundColor: linetColors.bgGreyDark2,
                color: linetColors.blue,
              },
            '& .Mui-focused': {
              backgroundColor: linetColors.bgGreyDark2,
              color: linetColors.blue,
            },
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: linetColors.slider,
          '& .MuiSlider-thumb': {
            borderRadius: 0,
            color: linetColors.blue,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: linetColors.white,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-track': {
            backgroundColor: linetColors.greyLighter,
          },
        },
        switchBase: {
          color: linetColors.bgGreyLight,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root.MuiMenu-paper': {
            borderRadius: '10px',
            marginTop: '12px',
            marginBottom: '12px',
            border: `solid 2px ${linetColors.greyDarker}`,
            '& .MuiMenu-list .MuiMenuItem-root[aria-selected="true"]': {
              backgroundColor: linetColors.bgGreyDark2,
              color: linetColors.blue,
            },
            '& .MuiMenu-list .MuiMenuItem-root:hover': {
              backgroundColor: linetColors.bgGreyDark2,
              color: linetColors.blue,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& ::-webkit-scrollbar': {
            width: '10px',
          },
          '& ::-webkit-scrollbar-track': {
            background: linetColors.greyLighter,
            border: '6px solid transparent',
            backgroundClip: 'content-box',
          },
          '& ::-webkit-scrollbar-thumb': {
            background: linetColors.blue,
            borderRadius: '30px',
          },
          '& ::-webkit-scrollbar-thumb:hover': {
            background: linetColors.blueDark,
          },
          '& .MuiDialog-paper': {
            backgroundImage: 'none',
            borderRadius: '10px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          letterSpacing: '0.03em',
          fontSize: '0.875rem',
          borderRadius: '40px',
          textTransform: 'none',
        },
        outlined: {
          borderWidth: '1.5px',
          borderColor: linetColors.bgGreyLight,
          color: linetColors.bgGreyLight,
          '&:hover': {
            borderWidth: '1.5px',
            borderColor: linetColors.bgGreyLight,
            color: linetColors.bgGreyLight,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          marginTop: '0.25rem',
          borderRadius: '10px',
          backgroundColor: linetColors.bgGreyDark2,
          '&.Mui-disabled': {
            backgroundColor: linetColors.bgGreyDark,
            color: linetColors.greySecondaryTextDark,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0,
            '& legend': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiSvgIcon: {},
    MuiInputBase: {},
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            fontSize: '0.875rem',
            color: linetColors.bgGreyLight,
            transform: 'none',
            position: 'relative',
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root .MuiTableCell-root': {
            backgroundColor: linetColors.greyTableHeaderDark,
            color: linetColors.bgGreyLight,
            textTransform: 'uppercase',
          },
          '& .MuiTableHead-root .MuiIconButton-root': {
            color: linetColors.bgGreyLight,
          },
          '& .MuiTableBody-root .MuiTableRow-root': {
            '&:nth-of-type(odd)': {
              backgroundColor: linetColors.bgGreyDark,
            },
            '&:nth-of-type(even)': {
              backgroundColor: linetColors.bgGreyDark2,
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: linetColors.bgGreyLight,
          fontWeight: 500,
          fontSize: '1rem',
          borderColor: 'transparent',
          borderBottom: 0,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          paddingLeft: '18px',
          paddingRight: '18px',
          color: linetColors.white,
          borderColor: linetColors.white,
          backgroundColor: linetColors.bgGreyDark2,
          '&&&:not(:first-of-type)': {
            borderLeft: '1px solid white',
          },
          '&.Mui-selected': {
            borderColor: linetColors.blue,
            backgroundColor: linetColors.blue,
            color: linetColors.white,
          },
        },
      },
    },
  },
  insetShadows: insetShadows,
});

export const mobileDarkTheme = createTheme(darkTheme, {
  typography: {
    caption: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
        outlinedPrimary: {
          color: linetColors.white,
          borderColor: linetColors.white,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            maxWidth: '100%',
            margin: '1rem',
          },
        },
      },
    },
  },
});
