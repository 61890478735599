import { MobileApp } from './MobileApp';

export const Reporting = () => {
  return (
    <div style={styles.container}>
      <div style={styles.title}>Reporting</div>
      <div style={styles.nurseCallSystem}>
        <img
          alt="Reporting mac demo"
          style={{ width: 600 }}
          src={require('src/images/reporting-mac-demo.png').default}
        />
      </div>
      <div style={styles.mobile}>
        <MobileApp
          content={
            <img
              alt="Reporting iphone demo"
              style={{ width: 129 }}
              src={
                require('src/images/reporting-iphone-content-demo.png').default
              }
            />
          }
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative' as 'relative',
    width: '100%',
    height: 550,
  },
  title: {
    marginTop: 12,
    paddingLeft: 12,
  },
  mobile: {
    position: 'absolute' as 'absolute',
    top: 200,
    left: 480,
  },
  nurseCallSystem: {
    position: 'absolute' as 'absolute',
    top: 40,
  },
};
