import { useMutation } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import {
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Tabs,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { SyntheticEvent, useMemo, useState } from 'react';
import Flex from 'src/components/Flex';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikTextField from 'src/components/Formik/FormikTextField';
import SaveDiscardButtons from 'src/components/SaveDiscardButtons';
import SimpleTableStyle from 'src/components/Table/SimpleTableStyle';
import { updateWardSafetyPreset } from 'src/graphql/mutations';
import {
  UpdateWardSafetyPreset,
  UpdateWardSafetyPresetVariables,
  WardSafetyPositionQuery_workspaces as WorkSpace,
  WardSafetyPresetInput,
} from 'src/graphql/types';
import { useDirtyFormik } from 'src/hooks';
import { ThinTab } from '../../../components/ThinTab';

type FormData = Record<string, string>;
const DEFAULT_ID = '1';

interface PresetOption {
  value: string;
  label: string;
}

interface Props {
  workspace: WorkSpace;
  presets: PresetOption[];
}

const commonSelectProps: TextFieldProps = {
  select: true,
  variant: 'outlined',
  sx: { minWidth: '25ch' },
  SelectProps: {
    SelectDisplayProps: {
      style: {
        paddingTop: '9px',
        paddingBottom: '9px',
      },
    },
  },
};

const SpDashboardPresetWardAssignments = ({ workspace, presets }: Props) => {
  const { wards } = workspace;
  const [allValue, setAllValue] = useState(DEFAULT_ID);

  const [updateWardSafetyPresetMutation] = useMutation<
    UpdateWardSafetyPreset,
    UpdateWardSafetyPresetVariables
  >(updateWardSafetyPreset);

  const onSubmit = async (data: FormData, helpers: FormikHelpers<FormData>) => {
    const input: WardSafetyPresetInput[] = Object.entries(data).map(
      ([wardId, presetId]) => ({
        wardId: wardId,
        safetyPositionPresetId: presetId,
      }),
    );
    await updateWardSafetyPresetMutation({
      variables: {
        input: input,
      },
    });
  };

  const wardInitialValues = useMemo(
    () =>
      wards.reduce(
        (result, current) => ({
          ...result,
          [current.id]: current.safetyPositionPresetId || DEFAULT_ID,
        }),
        {},
      ),
    [wards],
  );

  const formik = useFormik<FormData>({
    initialValues: wardInitialValues,
    enableReinitialize: true,
    onSubmit,
  });
  useDirtyFormik(formik);

  const { values, setValues } = formik;

  const isAllSelected = !Object.values(values).find((val) => val !== allValue);

  const handleAllSelectedChange = (e: SyntheticEvent, checked: boolean) => {
    if (checked) {
      const newValues = wards.reduce(
        (result, current) => ({ ...result, [current.id]: allValue }),
        {},
      );
      setValues(newValues);
    }
  };

  const renderOptions = () => {
    return presets.map((o) => (
      <MenuItem key={o.value} value={o.value}>
        {o.label}
      </MenuItem>
    ));
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <Tabs value="selection">
          <ThinTab label={t`Preset selection`} value="selection" />
        </Tabs>
        <SaveDiscardButtons
          onSave={formik.submitForm}
          onCancel={formik.resetForm}
          disabled={!formik.dirty}
        />
      </Flex>
      <Divider sx={{ my: 2 }} />
      <FormikForm formik={formik}>
        <SimpleTableStyle>
          <thead>
            <tr>
              <th>
                <Trans>Unit</Trans>
              </th>
              <th>
                <Trans>Safety Position Preset</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={isAllSelected}
                  onChange={handleAllSelectedChange}
                  label={t`Apply to all units`}
                  componentsProps={{
                    typography: {
                      fontSize: '1em',
                    },
                  }}
                />
              </td>
              <td>
                <TextField
                  {...commonSelectProps}
                  value={allValue}
                  onChange={(e) => setAllValue(e.target.value)}
                >
                  {renderOptions()}
                </TextField>
              </td>
            </tr>
            {wards.map((ward) => (
              <tr key={ward.id}>
                <td>{ward.name}</td>
                <td>
                  <FormikTextField {...commonSelectProps} name={ward.id}>
                    {renderOptions()}
                  </FormikTextField>
                </td>
              </tr>
            ))}
          </tbody>
        </SimpleTableStyle>
      </FormikForm>
    </>
  );
};

export default SpDashboardPresetWardAssignments;
