import { Trans } from '@lingui/macro';

export interface ConnectionStatus {
  status: number | null;
  length: number | null;
  date: Date;
}

export const initConnectionMap = (prevState: any, connections: any) => {
  return connections.reduce((prev: any, cfg: any) => {
    const newState = prevState[cfg.id] || {
      id: cfg.id,
      status: null,
      length: null,
      date: new Date(),
    };
    return {
      ...prev,
      [cfg.id]: {
        ...newState,
        date: new Date(),
      },
    };
  }, {});
};

const OFFLINE_INTERVAL = 10;

const isOffline = (date: Date) => {
  const testDate = new Date(date);
  testDate.setSeconds(testDate.getSeconds() + OFFLINE_INTERVAL);

  return new Date() > testDate;
};

export const getStatus = (connection: ConnectionStatus) => {
  if (!connection) {
    return '...';
  }
  if (isOffline(connection.date)) {
    return <Trans>down</Trans>;
  }
  if (connection.status === null) {
    return '...';
  }
  if (connection.status === 2) {
    return <Trans>up</Trans>;
  }
  return <Trans>down</Trans>;
};

export const DEFAULT_SYNC_PERIOD = 5;
