import { t } from '@lingui/macro';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import { updateUserPasswordMutation } from 'src/graphql/mutations';
import {
  UpdateUserPassword,
  UpdateUserPasswordVariables,
  UserRole,
} from 'src/graphql/types';
import { object, string, ref as yupRef } from 'yup';
import { usePasswordValidator } from '../../../auth/passwordValidation';

interface Props {
  lowAuthority: boolean;
  onClose: () => void;
  open: boolean;
  userId: string;
  userRole: UserRole;
}

interface FormData {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const PasswordModal = ({
  lowAuthority,
  onClose,
  open,
  userId,
  userRole,
}: Props) => {
  const { passwordValidator, passwordRequirementsMessage } =
    usePasswordValidator();
  const [updateUserPassword] = useSnackbarMutation<
    UpdateUserPassword,
    UpdateUserPasswordVariables
  >(updateUserPasswordMutation, undefined, {
    successMessage: t`Password was changed`,
  });

  const userSchema = useMemo(
    () =>
      object({
        oldPassword: string().required(t`Required!`),
        newPassword: string()
          .required(t`Required!`)
          .test(
            'strength',
            passwordRequirementsMessage(userRole),
            function (password) {
              return passwordValidator(password || '', userRole);
            },
          ),
        repeatNewPassword: string()
          .required(t`Required!`)
          .test('equal', t`Passwords do not match!`, function (v) {
            const ref = yupRef('newPassword');
            return v === this.resolve(ref);
          }),
      }),
    [passwordValidator, passwordRequirementsMessage, userRole],
  );

  const adminUserSchema = useMemo(
    () =>
      object({
        newPassword: string()
          .required(t`Required!`)
          .test(
            'strength',
            passwordRequirementsMessage(userRole),
            function (password) {
              return passwordValidator(password || '', userRole);
            },
          ),
        repeatNewPassword: string()
          .required(t`Required!`)
          .test('equal', t`Passwords do not match!`, function (v) {
            const ref = yupRef('newPassword');
            return v === this.resolve(ref);
          }),
      }),
    [passwordValidator, passwordRequirementsMessage, userRole],
  );

  const onSubmit = (data: FormData) => {
    updateUserPassword({
      variables: {
        userId,
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      },
      onCompleted: () => {
        onClose();
      },
      onError: (error) => {
        formik.setFieldError('oldPassword', error.message);
      },
    });
  };

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const formik = useFormik<FormData>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    onSubmit,
    validationSchema: lowAuthority ? userSchema : adminUserSchema,
  });

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {lowAuthority ? t`Password change` : t`Password reset`}
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <FormikForm formik={formik}>
            {lowAuthority && (
              <>
                <FormikTextField
                  name="oldPassword"
                  fullWidth
                  type="password"
                  required
                  variant="outlined"
                  label={t`Current password`}
                />
                <Divider sx={{ my: 2 }} />
              </>
            )}
            <FormikTextField
              sx={{ mb: 2 }}
              name="newPassword"
              type="password"
              required
              fullWidth
              variant="outlined"
              label={t`New password`}
            />
            <FormikTextField
              name="repeatNewPassword"
              type="password"
              required
              fullWidth
              variant="outlined"
              label={t`Repeat new password`}
            />
          </FormikForm>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t`Cancel`}
        </Button>
        <Button color="primary" variant="contained" onClick={formik.submitForm}>
          {lowAuthority ? t`Change password` : t`Save password`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordModal;
