import { Typography, Box } from '@mui/material';
import { t } from '@lingui/macro';

const NoData = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '12.6px',
          opacity: 0.5,
        }}
      >
        {t`No data`}
      </Typography>
    </Box>
  );
};

export default NoData;
