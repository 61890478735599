import { move } from 'ramda';
import { PropsWithChildren } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { SvDashboardSortingDataType } from './DashboardSettingProvider';

interface Props extends PropsWithChildren<{}> {
  draggableDatapointsOrder: SvDashboardSortingDataType[];
  setDraggableDatapointsOrder?: (items: SvDashboardSortingDataType[]) => void;
  onDragEnded?: (items: SvDashboardSortingDataType[]) => void;
}

export const DatapointOrderingWrapper = ({
  draggableDatapointsOrder,
  onDragEnded,
  setDraggableDatapointsOrder,
  children,
}: Props) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = move(
      result.source.index,
      result.destination.index,
      draggableDatapointsOrder,
    );
    onDragEnded && onDragEnded(items);
    setDraggableDatapointsOrder && setDraggableDatapointsOrder(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction={'vertical'}>
        {(droppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {children}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
