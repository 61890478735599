import { useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { Box, Button, styled, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from 'src/components/ConfirmModal';
import Flex from 'src/components/Flex';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import {
  deleteReportingDashboardMutation,
  editReportingDashboardMutation,
} from 'src/graphql/mutations';
import {
  reportingDashboardQuery,
  reportingDashboardsQuery,
} from 'src/graphql/queries';
import {
  DeleteReportingDashboard,
  DeleteReportingDashboardVariables,
  EditReportingDashboard,
  EditReportingDashboardVariables,
  ReportingDashboardQuery,
  ReportingDashboardQueryVariables,
} from 'src/graphql/types';
import ReportDetailDashboardActionButtons from './components/ReportDetailDashboardActionButtons';
import ReportDraggableGraphsList from './components/ReportDraggableGraphsList';
import ReportingPageWrapper from './ReportingPageWrapper';

interface Props {
  id: string;
}

const ReportingBoardPage = ({ id }: Props) => {
  const [deleteConfirmOpen, setDeleteConfimOpen] = useState(false);
  const navigate = useNavigate();

  const { data: reportingDashboard } = useQuery<
    ReportingDashboardQuery,
    ReportingDashboardQueryVariables
  >(reportingDashboardQuery, {
    variables: {
      id,
    },
    skip: id == null,
  });

  const [removeFromDashboard] = useSnackbarMutation<
    DeleteReportingDashboard,
    DeleteReportingDashboardVariables
  >(deleteReportingDashboardMutation, undefined, {
    successMessage: t`Succesfully removed`,
  });

  const [editDashboard] = useSnackbarMutation<
    EditReportingDashboard,
    EditReportingDashboardVariables
  >(editReportingDashboardMutation, undefined, {
    successMessage: t`Saved`,
  });

  const handleEditDashboard = (name: string) => {
    editDashboard({
      variables: {
        input: {
          name,
          id,
        },
      },
      refetchQueries: [{ query: reportingDashboardsQuery }],
    });
  };

  const handleRemoveFromDashboard = () => {
    removeFromDashboard({
      variables: {
        id,
      },
      refetchQueries: [
        {
          query: reportingDashboardsQuery,
        },
      ],
      onCompleted: () => {
        navigate('/reporting');
      },
    });
  };

  const renderEmptyPage = () => (
    <EmptyContainer>
      <Typography
        fontSize={20}
      >{t`Add already done reports to Board or create new`}</Typography>
      <Button
        component={Link}
        color="primary"
        variant="contained"
        to="/reporting/reports/create"
        sx={{ marginTop: 2 }}
      >
        {t`Create new Report`}
      </Button>
    </EmptyContainer>
  );

  return (
    <ReportingPageWrapper
      title={reportingDashboard?.reportingDashboard.name || ''}
      onNameChanged={handleEditDashboard}
      actions={
        <ReportDetailDashboardActionButtons
          dashboardId={id}
          onOpenDeleteConfirm={() => setDeleteConfimOpen(true)}
          addedReportIds={reportingDashboard?.reportingDashboard?.reports?.map(
            (item) => item.reportingReportId,
          )}
        />
      }
    >
      {reportingDashboard?.reportingDashboard?.reports?.[0] ? (
        <Box
          id="reports-layout"
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[200],
            '&&& .MuiPaper-root': {
              boxShadow: 'unset',
            },
          })}
        >
          <ReportDraggableGraphsList
            items={reportingDashboard?.reportingDashboard?.reports}
          />
        </Box>
      ) : (
        renderEmptyPage()
      )}
      <ConfirmModal
        title={t`Delete board`}
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfimOpen(false)}
        message={
          <Trans>
            Do you really want to{' '}
            <b>delete board {reportingDashboard?.reportingDashboard.name}?</b>
          </Trans>
        }
        onConfirm={handleRemoveFromDashboard}
      />
    </ReportingPageWrapper>
  );
};

export default ReportingBoardPage;

const EmptyContainer = styled(Flex)`
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 45%;
`;
