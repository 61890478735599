import { BedType } from 'src/graphql/types';

interface Props {
  bedType?: string | null;
  leftSiderailsUp: boolean;
  rightSiderailsUp: boolean;
  leftTop: boolean;
  rightTop: boolean;
  leftBottom: boolean;
  rightBottom: boolean;
  downColor: string;
  upColor: string;
  width?: string;
  height?: string;
}

const hasSingleValueSiderails = (bedType?: string | null) => {
  return (
    bedType === BedType.E4 ||
    bedType === BedType.E4_NO_TRX ||
    bedType === BedType.E3XC ||
    bedType === BedType.E3XC_NO_TRX ||
    bedType === BedType.E3 ||
    bedType === BedType.E3_NO_TRX ||
    bedType === BedType.E2
  );
};

const SiderailsIcon = ({
  bedType,
  leftSiderailsUp,
  rightSiderailsUp,
  leftTop,
  rightTop,
  leftBottom,
  rightBottom,
  downColor,
  upColor,
  width,
  height,
}: Props) => {
  const iconHeight = height || '20';
  if (hasSingleValueSiderails(bedType)) {
    const siderailsColor =
      leftSiderailsUp && rightSiderailsUp ? upColor : downColor;
    return (
      <svg
        width={width}
        height={iconHeight}
        viewBox="0 0 40 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="6" rx="1" fill={siderailsColor} />
        <rect y="14" width="40" height="6" rx="1" fill={siderailsColor} />
        <circle cx="9" cy="10" r="3" fill={downColor} />
      </svg>
    );
  }

  return (
    <svg
      width={width}
      height={iconHeight}
      viewBox="0 0 40 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="6" rx="1" fill={leftTop ? upColor : downColor} />
      <rect
        x="22"
        y="14"
        width="18"
        height="6"
        rx="1"
        fill={rightBottom ? upColor : downColor}
      />
      <rect
        y="14"
        width="18"
        height="6"
        rx="1"
        fill={rightTop ? upColor : downColor}
      />
      <rect
        x="22"
        width="18"
        height="6"
        rx="1"
        fill={leftBottom ? upColor : downColor}
      />
      <circle cx="9" cy="10" r="3" fill={downColor} />
    </svg>
  );
};

export default SiderailsIcon;
