import { useQuery } from '@apollo/client';
import { upsertUser } from 'src/graphql/mutations';
import {
  meQuery,
  userQuery,
  usersQuery,
  wardsQuery,
} from 'src/graphql/queries';
import {
  me,
  upsertUserMutation,
  upsertUserMutationVariables,
  UserQuery,
  UserQueryVariables,
  UserRole,
  WardsQuery,
} from 'src/graphql/types';
import UserDetail from './UserDetail';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { t } from '@lingui/macro';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';

interface UserFormData {
  id: string | undefined;
  role: UserRole;
  firstName: string;
  password: string;
  lastName: string;
  email: string;
  username: string;
  wardIds: AutocompleteSelectValue[];
  workspaceId?: string;
  reportingModuleAccess: boolean;
  locale: string;
  serverIp: string;
  ssid: string;
  ssidKey: string;
  ssidKeyEncrypt: boolean;
  vbAccess: boolean;
  demoAccess: boolean;
}

interface AutocompleteSelectValue {
  label: string;
  value: string;
}

const userSchema = object({
  firstName: string().required(t`Required!`),
  lastName: string().required(t`Required!`),
  username: string().required(t`Required!`),
});

const EditUser = () => {
  const params = useParams<{ userId: string }>();
  const { data: meData } = useQuery<me>(meQuery);
  const { data: userData } = useQuery<UserQuery, UserQueryVariables>(
    userQuery,
    {
      variables: {
        userId: params.userId || '',
      },
      skip: params.userId == null,
    },
  );

  const { data: wardsData } = useQuery<WardsQuery>(wardsQuery);
  const [updateUser] = useSnackbarMutation<
    upsertUserMutation,
    upsertUserMutationVariables
  >(upsertUser, undefined, {
    successMessage: t`User was edited.`,
  });

  const handleSubmit = async (data: UserFormData) => {
    delete data.workspaceId;
    await updateUser({
      variables: {
        user: {
          ...data,
          wardIds: data.wardIds.map((ward) => ward.value),
          serverIp: data.serverIp === '' ? null : data.serverIp,
          ssid: data.ssid === '' ? null : data.ssid,
          ssidKey: data.ssidKey === '' ? null : data.ssidKey,
        },
      },
      refetchQueries: () => [{ query: usersQuery }],
    });
    return;
  };

  const userWards = userData?.user?.assignedWards || [];
  const allWards = wardsData?.wards || [];
  const selectedWorkspaceId =
    (userWards.length ? userWards : allWards)[0]?.workspace.id || '';
  const initialValues = {
    id: userData?.user?.id || '',
    role: userData?.user?.role || UserRole.REGULAR,
    firstName: userData?.user?.firstName || '',
    password: '',
    workspaceId: selectedWorkspaceId,
    wardIds:
      userData?.user?.assignedWards?.map((ward) => ({
        label: ward.name,
        value: ward.id,
      })) || [],
    lastName: userData?.user?.lastName || '',
    email: userData?.user?.email || '',
    username: userData?.user?.username || '',
    locale: userData?.user?.locale || '',
    serverIp: userData?.user?.serverIp || '',
    ssid: userData?.user?.ssid || '',
    ssidKey: userData?.user?.ssidKey || '',
    ssidKeyEncrypt: Boolean(userData?.user?.ssidKeyEncrypt),
    vbAccess: Boolean(userData?.user?.vbAccess),
    demoAccess: Boolean(userData?.user?.demoAccess),
    reportingModuleAccess: Boolean(userData?.user?.reportingModuleAccess),
  };

  return (
    <UserDetail<UserFormData>
      initialValues={initialValues}
      userWards={userData?.user?.assignedWards}
      features={{
        passwordModal: true,
        userDelete: true,
        editRole: meData?.me?.id === userData?.user?.id ? false : true,
        accessSwitchButtons: true,
      }}
      userId={userData?.user?.id}
      onSubmit={handleSubmit}
      validationSchema={userSchema}
    />
  );
};

export default EditUser;
