import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Button, TextField, Typography, styled } from '@mui/material';
import { useState } from 'react';
import CareNoteDeleteDialog from './CareNoteDeleteDialog';
import { CareNoteItemDataProps, useCareNoteData } from './useCareNoteData';

interface Props {
  disable?: boolean;
  patientSessionId: string;
  data: CareNoteItemDataProps;
}

const CareNoteItem = ({ disable, data, patientSessionId }: Props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { i18n } = useLingui();
  const {
    note,
    setNote,
    setEditNote,
    deleteNote,
    editNote,
    editable,
    saveNote,
  } = useCareNoteData({
    disable,
    patientSessionId,
    data,
  });

  return (
    <>
      <Container>
        <Box display="flex" alignItems="center" py={0.5}>
          <DateContainer>
            {data.date &&
              i18n.date(data.date, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
          </DateContainer>
          <DateContainer sx={{ marginLeft: '32px' }}>
            {data.date &&
              i18n.date(data.date, {
                hour: 'numeric',
                minute: 'numeric',
              })}
          </DateContainer>
          <Box sx={{ ml: 4, minWidth: 400, maxWidth: 680 }}>
            {!editNote ? (
              <Note>{data.note}</Note>
            ) : (
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={note}
                onChange={({ target }) => setNote(target.value)}
              />
            )}
          </Box>
        </Box>
        {!disable && editable && (
          <Box display="flex" alignItems="center">
            {!editNote ? (
              <>
                <Button
                  onClick={() => setEditNote(true)}
                  size="small"
                  variant="contained"
                >
                  <Trans>Edit</Trans>
                </Button>
                <Button
                  sx={{ marginLeft: 2 }}
                  size="small"
                  variant="outlined"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <Trans>Delete</Trans>
                </Button>
              </>
            ) : (
              <Button onClick={saveNote} size="small" variant="contained">
                <Trans>Save</Trans>
              </Button>
            )}
          </Box>
        )}
      </Container>
      <CareNoteDeleteDialog
        setOpen={setOpenDeleteDialog}
        open={openDeleteDialog}
        onDelete={deleteNote}
      />
    </>
  );
};

export default CareNoteItem;

const Container = styled(Box)(
  ({ theme: { spacing, palette } }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spacing(4)};
  border-radius: 10px;
  margin: ${spacing(0.5)} 0;
  min-height: 47px;
  background-color: ${palette.grey[200]};
`,
);

const DateContainer = styled(Typography)`
  font-weight: 500;
  font-size: 0.875rem;
`;
const Note = styled(Typography)`
  font-size: 0.875rem;
  word-break: break-word;
`;
