import { t } from '@lingui/macro';
import {
  Pagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { DashboardSettingContext } from '../Setting/DashboardSettingProvider';

interface Props {
  page: number;
  pageCount: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  changePageSize: (pageSize: number) => void;
}

export const BottomBar = ({
  page,
  pageCount,
  setPage,
  pageSize,
  changePageSize,
}: Props) => {
  const { currentDashboardOrder, userSetting, changeDashboardOrder } =
    useContext(DashboardSettingContext);
  const changePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Box display="flex" flex={1} flexDirection="row">
      <Box display="flex" alignItems="center" gap={1}>
        <Typography>{t`Display`}</Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{
            width: '10ch',
            '& input': {
              textAlign: 'center',
            },
          }}
          defaultValue={pageSize}
          onBlur={(event) => {
            const newPageSize = Number(event.target.value);
            if (newPageSize >= 5) {
              changePageSize(newPageSize);
            }
          }}
        />
        <Typography>{t`rows`}</Typography>
      </Box>
      <Pagination
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          '& .MuiButtonBase-root': {
            fontSize: '1rem',
          },
        }}
        count={pageCount}
        page={page}
        onChange={changePage}
      />
      <Box>
        <ToggleButtonGroup
          color="primary"
          value={currentDashboardOrder}
          exclusive
          onChange={(event, newAlignment) => {
            if (newAlignment) {
              changeDashboardOrder(newAlignment);
            }
          }}
        >
          <ToggleButton value="dashboardOrder">{t`View 1`}</ToggleButton>
          <ToggleButton
            value="dashboardOrder2"
            disabled={userSetting.dashboardOrder2.length === 0}
          >{t`View 2`}</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};
