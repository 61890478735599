import { Typography } from '@mui/material';
import { Breadcrumbs } from 'src/components/Breadcrumbs';
import {
  RoomsQuery_rooms as Room,
  WardsQuery_wards as Ward,
  WorkspacesQuery_workspaces as Workspace,
} from 'src/graphql/types';
import { WorkspaceSelection } from './useHospitalLayoutData';

interface Props {
  workspaces: Workspace[];
  rooms: Room[];
  wards: Ward[];
  selection: WorkspaceSelection;
}

const HospitalSelectionBreadcrumbs = ({
  selection,
  workspaces,
  rooms,
  wards,
}: Props) => {
  const workspace = workspaces.find((w) => w.id === selection.workspaceId);
  const ward = wards.find((w) => w.id === selection.wardId);
  const room = rooms.find((r) => r.id === selection.roomId);

  return (
    <Breadcrumbs sx={{ mb: 2 }}>
      {workspace && <Typography>{workspace.name}</Typography>}
      {ward && <Typography>{ward.name}</Typography>}
      {room && <Typography>{room.name}</Typography>}
    </Breadcrumbs>
  );
};

export default HospitalSelectionBreadcrumbs;
