import { t } from '@lingui/macro';
import { TextField, Autocomplete, SxProps } from '@mui/material';
import React from 'react';
import {
  getFilterPropertyNames,
  PropertyNameFilterType,
  propTranslationTabel,
} from 'src/helpers';
import DateTimeInput from 'src/components/DateTimeInput';

interface Props {
  setFromDate: React.Dispatch<React.SetStateAction<Date>>;
  setDataHistoryFilter: (value: React.SetStateAction<string | null>) => void;
  fromDate: Date;
  sessionStart?: string | null;
  prop?: string | null;
  dataHistoryFilter: string | null;
  sxDate?: SxProps;
  sxFilter?: SxProps;
}

export const MobileInsightFilter = ({
  fromDate,
  dataHistoryFilter,
  prop,
  setFromDate,
  setDataHistoryFilter,
  sxDate,
  sxFilter,
}: Props) => {
  const propertyNameFilter: PropertyNameFilterType[] = getFilterPropertyNames();

  const changeFromDate = (date: Date | null) => {
    if (date) {
      setFromDate(date);
    }
  };

  return (
    <>
      <DateTimeInput
        label={t`From`}
        value={fromDate || new Date()}
        sx={sxDate}
        fullWidth
        onChange={changeFromDate}
        variant="outlined"
        size="small"
      />
      <Autocomplete
        id="property-name-filter"
        options={propertyNameFilter}
        defaultValue={
          prop ? { value: prop, name: propTranslationTabel(prop) || '' } : null
        }
        value={
          propertyNameFilter.find(
            (filterItem) => filterItem.value === dataHistoryFilter,
          ) || null
        }
        getOptionLabel={(option: PropertyNameFilterType) => option.name}
        onChange={(_, value: PropertyNameFilterType | null) =>
          setDataHistoryFilter(value?.value ?? null)
        }
        renderInput={(params) => (
          <TextField
            variant="outlined"
            sx={sxFilter}
            {...params}
            label={t`Filter`}
            size="small"
          />
        )}
      />
    </>
  );
};
