import {
  Page,
  View,
  Document,
  StyleSheet,
  Text,
  Image,
  Font,
} from '@react-pdf/renderer';
import { t, Trans } from '@lingui/macro';
import { linetColors } from '../../../colors';
import { isFromZeroType } from '../helpers/patientView';
import { format } from 'date-fns';
import { dateTimePickerFormat } from '../../../helpers';
import { GraphsExportType } from '../helpers/export';

interface Props {
  graphs: GraphsExportType | null;
  sessionName: string | null;
  sessionId: string;
  exportedTime: Date;
  exportedDay: Date;
}

Font.register({
  family: 'GTAmerica',
  src: require('../../../font/GT-America-Standard-Regular.otf').default,
});

export const exportGraphLenght = 650;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    padding: 16,
    fontFamily: 'GTAmerica',
  },
  rangeBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 50,
    width: '100px',
    position: 'absolute',
    left: 40,
    top: 0,
  },
  graphBox: {
    position: 'relative',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    marginTop: 20,
    height: 50,
    marginBottom: 21,
  },
  titleBox: {
    position: 'absolute',
    width: '200px',
    height: 50,
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  rangeText: {
    textAlign: 'right',
    fontSize: 6,
    paddingTop: 4,
    paddingBottom: 4,
    textTransform: 'uppercase',
  },
  graph: {
    position: 'absolute',
    width: exportGraphLenght,
    height: 50,
    top: 0,
    right: 16,
    display: 'flex',
  },
});

const PatientViewExport = ({
  graphs,
  sessionName,
  sessionId,
  exportedTime,
  exportedDay,
}: Props) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View>
          <Text
            style={{
              fontSize: 8,
              textAlign: 'right',
              marginRight: 8,
              marginBottom: 8,
              fontFamily: 'GTAmerica',
            }}
          >
            {`${t`Session ID`}: ${sessionId}, ${t`Session name`}: ${sessionName}, ${t`Export day`}: ${format(
              exportedDay,
              'MM/dd/yyyy',
            )}, ${t`Export time`}: ${format(
              exportedTime,
              dateTimePickerFormat,
            )}`}
          </Text>
          <Text style={{ textAlign: 'center', marginTop: 16 }}>
            <Trans>24 hours view</Trans>
          </Text>
          {graphs &&
            graphs.data?.map((item, index) => (
              <>
                {(index === 5 ||
                  index === 11 ||
                  index === 17 ||
                  index === 23) && (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: 680,
                      position: 'relative',
                      right: -130,
                    }}
                  >
                    {graphs.timelineDates.map((date) => (
                      <Text style={{ fontSize: 7 }}>{date}</Text>
                    ))}
                  </View>
                )}
                <View
                  wrap={false}
                  key={`exportedGraph-${item.type}`}
                  style={styles.graphBox}
                >
                  <View
                    style={{
                      position: 'absolute',
                      borderBottomWidth: 1,
                      borderBottomStyle: 'dashed',
                      borderBottomColor: linetColors.greyDarker,
                      width: exportGraphLenght,
                      right: 16,
                      height: 1,
                      top: !isFromZeroType(item.type) ? 24.5 : undefined,
                      bottom: isFromZeroType(item.type) ? 0 : undefined,
                    }}
                  />
                  <View style={styles.rangeBox}>
                    {item.range.map((value) => (
                      <Text
                        key={`graphRange-${item.type}-${value}`}
                        style={styles.rangeText}
                      >
                        {value}
                      </Text>
                    ))}
                  </View>
                  <View style={styles.titleBox}>
                    <Text style={{ fontSize: 12 }}>{item.title}</Text>
                  </View>
                  <View style={styles.graph}>
                    <Image
                      style={{ width: exportGraphLenght, height: 50 }}
                      source={item.img}
                    />
                  </View>
                </View>
              </>
            ))}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 680,
              position: 'relative',
              right: -130,
            }}
          >
            {graphs &&
              graphs.timelineDates.map((date) => (
                <Text style={{ fontSize: 7 }}>{date}</Text>
              ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PatientViewExport;
