import { Box, styled } from '@mui/material';

export default styled(Box)`
  position: absolute;
  top: 65px;
  right: 0;
  display: flex;
  align-items: center;
  height: 82px;
  padding-right: 1rem;
  background-color: ${(props) => props.theme.palette.background.paper};
`;
