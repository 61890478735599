import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton, Typography } from '@mui/material';
import { BoxProps, styled } from '@mui/system';
import React from 'react';
import Flex from 'src/components/Flex';
import { HospitalLayoutNameCardPlaceholder } from './HospitalLayoutNameCard';

interface Props {
  title: string;
  active: boolean;
  children?: React.ReactNode;
  onAddClick?: () => void;
  sx?: BoxProps['sx'];
  expanded?: boolean;
}

const HospitalLayoutColumn = ({
  title,
  active,
  children,
  onAddClick,
  sx,
  expanded,
}: Props) => {
  return (
    <HospitalLayoutColumnStytled
      active={active}
      sx={sx}
      className={expanded ? 'expanded' : ''}
    >
      <Typography
        variant="h2"
        sx={{
          textTransform: 'uppercase',
          textAlign: 'center',
          my: 2,
          color: active ? 'primary.main' : 'text.primary',
        }}
      >
        {title}
      </Typography>
      <HospitalLayoutColumnStytledScroll
        flexDirection="row"
        flexWrap="wrap"
        px={1}
        flexShrink={1}
        alignItems="center"
        justifyContent={expanded ? '' : 'space-evenly'}
        minWidth="fit-content"
      >
        {children}
        <HospitalLayoutNameCardPlaceholder justifyContent="center" pb={1}>
          {onAddClick && (
            <IconButton size="large" onClick={onAddClick}>
              <AddCircleIcon fontSize="large" color="primary" />
            </IconButton>
          )}
        </HospitalLayoutNameCardPlaceholder>
      </HospitalLayoutColumnStytledScroll>
    </HospitalLayoutColumnStytled>
  );
};

export default HospitalLayoutColumn;

const HospitalLayoutColumnStytled = styled(Flex)<{ active: boolean }>(
  ({ active, theme: { palette, spacing } }) => `
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 20%;
    height: 100%;
    background-color: ${palette.grey[100]};
    border-radius: 5px;

    :not(:last-child) {
        margin-right: ${spacing(2)};
    }

    ${
      active
        ? `
      background-color: ${palette.grey[200]};
    `
        : ``
    }
`,
);

const HospitalLayoutColumnStytledScroll = styled(Flex)`
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }
`;
