import {
  Box,
  Button,
  Divider,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import { UserRole, WardsQuery, WardsQuery_wards } from 'src/graphql/types';
import { t } from '@lingui/macro';
import Flex from 'src/components/Flex';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikSwitch from 'src/components/Formik/FormikSwitch';
import { availableLocales } from 'src/components/I18nLoader';
import * as R from 'ramda';
import FormikAutocomplete from 'src/components/Formik/FormikAutocomplete';
import { getUniqueItems } from 'src/helpers';
import { FormikContextType } from 'formik';
import { useQuery } from '@apollo/client';
import { wardsQuery } from 'src/graphql/queries';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';

interface AutocompleteSelectValue {
  label: string;
  value: string;
}

export interface UserFormFeatures {
  editRole?: boolean;
  passwordModal?: boolean;
  userDelete?: boolean;
  accessSwitchButtons?: boolean;
}

interface Props<T> {
  features?: UserFormFeatures;
  formik: FormikContextType<T>;
  openSetPasswordModal: () => void;
  lowAuthority: boolean;
}

const userRoles = () => [
  {
    value: UserRole.REGULAR,
    text: t`Regular user`,
  },
  {
    value: UserRole.DEMO,
    text: t`Demo user`,
  },
  {
    value: UserRole.ADMIN,
    text: t`Admin user`,
  },
];

const UserForm = <
  T extends {
    wardIds: AutocompleteSelectValue[];
    workspaceId?: string;
    role: string;
    id?: string;
  },
>({
  features,
  formik,
  lowAuthority,
  openSetPasswordModal,
}: Props<T>) => {
  const { data: wardsData } = useQuery<WardsQuery>(wardsQuery);
  const { user, config } = useContext(AuthContext);

  const getWorkspaceWards = (
    wards: WardsQuery_wards[],
    workspaceId: string,
  ) => {
    const workspaceWards = wards.filter(
      (ward) => ward.workspace.id === workspaceId,
    );
    return workspaceWards.map((ward) => ({ label: ward.name, value: ward.id }));
  };

  const handleChangeWorkspace = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = e.target.value;
    if (value !== formik.values.workspaceId) {
      formik.setFieldValue('wardIds', []);
    }
  };

  const wardsWorkspaces = wardsData?.wards.map((ward) => ward.workspace);

  const availableWorkspaces = getUniqueItems(wardsWorkspaces || []).map(
    (item) => ({ label: item.name, value: item.id }),
  );
  const availableWards = getWorkspaceWards(
    wardsData?.wards || [],
    formik.values.workspaceId || '',
  );

  const isEdittingUserAdmin = user?.role === UserRole.ADMIN;

  return (
    <FormikForm formik={formik}>
      <SectionTitle>{t`Personal`}</SectionTitle>
      <SectionContainer>
        <FormikTextField
          name="firstName"
          fullWidth
          variant="outlined"
          label={t`Name`}
          required
        />
        <FormikTextField
          name="lastName"
          fullWidth
          variant="outlined"
          label={t`Surname`}
          required
        />
        <FormikTextField
          name="locale"
          variant="outlined"
          fullWidth
          select
          label={t`Language`}
          SelectProps={{
            displayEmpty: true,
          }}
          required
        >
          <MenuItem value="">{t`Use browser language settings`}</MenuItem>
          {R.values(
            R.mapObjIndexed(
              (label, value) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ),
              availableLocales,
            ),
          )}
        </FormikTextField>
      </SectionContainer>
      <StyledDivider />
      <SectionTitle>{t`Account`}</SectionTitle>
      <SectionContainer>
        <FormikTextField
          name="role"
          fullWidth
          disabled={!features?.editRole}
          select
          variant="outlined"
          label={t`Role`}
          required
        >
          {userRoles().map((user) => (
            <MenuItem value={user.value}>{user.text}</MenuItem>
          ))}
        </FormikTextField>
        <FormikTextField
          name="email"
          fullWidth
          variant="outlined"
          required
          label={t`Email`}
        />
        <FormikTextField
          name="username"
          variant="outlined"
          required
          fullWidth
          label={t`Login`}
        />
        {features?.passwordModal ? (
          <Flex
            alignItems="center"
            sx={{
              paddingTop: (theme) => theme.spacing(2.75),
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={() => openSetPasswordModal()}
            >
              {lowAuthority ? t`Change password` : t`Reset password`}
            </Button>
          </Flex>
        ) : (
          <>
            <Flex />
            <FormikTextField
              sx={{ mb: 2 }}
              name="password"
              type="password"
              required
              fullWidth
              variant="outlined"
              label={t`New password`}
            />
            <FormikTextField
              sx={{ mb: 2 }}
              name="repeatPassword"
              type="password"
              required
              fullWidth
              variant="outlined"
              label={t`Repeat password`}
            />
          </>
        )}
        {isEdittingUserAdmin && features?.accessSwitchButtons && (
          <Flex>
            <FormikSwitch name="vbAccess" label={t`Virtual Bed access`} />
          </Flex>
        )}
        {isEdittingUserAdmin && features?.accessSwitchButtons && (
          <Flex>
            <FormikSwitch name="demoAccess" label={t`Demo access`} />
          </Flex>
        )}
        {config?.superConfig?.reportingModuleAccess &&
          isEdittingUserAdmin &&
          features?.accessSwitchButtons && (
            <Flex>
              <FormikSwitch
                name="reportingModuleAccess"
                label={t`Reporting module access`}
              />
            </Flex>
          )}
      </SectionContainer>
      {isEdittingUserAdmin && formik.values.role === UserRole.ADMIN && (
        <>
          <StyledDivider />
          <SectionTitle>{t`Linis SetupKit Settings`}</SectionTitle>
          <SectionContainer>
            <FormikTextField
              name="serverIp"
              fullWidth
              variant="outlined"
              label={t`Server IP`}
            />
            <FormikTextField
              name="ssid"
              fullWidth
              variant="outlined"
              label={t`SSID`}
            />
            <FormikTextField
              name="ssidKey"
              variant="outlined"
              fullWidth
              label={t`SSID Key`}
            />
            <Flex>
              <FormikSwitch name="ssidKeyEncrypt" label={t`Encrypted`} />
            </Flex>
          </SectionContainer>
        </>
      )}
      <StyledDivider />
      <SectionTitle>{t`Location`}</SectionTitle>
      <SectionContainer>
        <FormikTextField
          name="workspaceId"
          fullWidth
          select
          onChange={handleChangeWorkspace}
          variant="outlined"
          label={t`Workspace`}
        >
          {availableWorkspaces.map((workspace) => (
            <MenuItem value={workspace.value}>{workspace.label}</MenuItem>
          ))}
        </FormikTextField>
        <FormikAutocomplete<AutocompleteSelectValue, true>
          name="wardIds"
          multiple
          options={availableWards.filter(
            (ward) =>
              !formik.values.wardIds.find(
                (selectedWard) => selectedWard.value === ward.value,
              ),
          )}
          textFieldProps={{
            variant: 'outlined',
            label: t`Ward`,
            fullWidth: true,
          }}
        />
      </SectionContainer>
    </FormikForm>
  );
};

export default UserForm;

const SectionTitle = styled(Typography)`
  padding-left: 0;
  margin-left: 0;
`;

SectionTitle.defaultProps = {
  variant: 'h3',
};

const StyledDivider = styled(Divider)`
  margin: 24px 0 32px 0;
`;

const SectionContainer = styled(Box)`
  display: grid;
  max-width: 1000px;
  grid-template-columns: 50% 50%;
  gap: 32px;
`;
