import { useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  styled,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import DateTimeInput from 'src/components/DateTimeInput';
import Flex from 'src/components/Flex';
import FormikForm from 'src/components/Formik/FormikForm';
import FormikTextField from 'src/components/Formik/FormikTextField';
import CloseIcon from '@mui/icons-material/Close';
import { useSafetyPositionPresets } from 'src/components/SafetyPositionPresetSettings/hooks';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import {
  endSession,
  startPatientSession,
  updatePatientSession,
} from 'src/graphql/mutations';
import { bedHeaderModalData, safetyPositionPresets } from 'src/graphql/queries';
import {
  BedHeaderModalData,
  BedHeaderModalDataVariables,
  BedHeaderModalData_bed,
  EditPatientSession,
  EditPatientSessionVariables,
  EndSession,
  EndSessionVariables,
  FallRisk,
  SafetyPositionPresets,
  StartPatientSession,
  StartPatientSessionVariables,
} from 'src/graphql/types';
import { closeModalProps } from '../BedDetailModal';
import DischargeDialog from './DischargeDialog';
import { OpenModalDataType, OpenModalType } from '../modalTypes';
import NewSessionDialog, { StartSessionProps } from '../NewSessionDialog';
import SessionEndedDialog from '../SessionEndedDialog';
import ValueInfo from './ValueInfo';
import { createSessionName, parseInitials } from '../helpers';
import { object, string } from 'yup';

interface Props {
  bed: BedHeaderModalData_bed;
  modalData?: OpenModalDataType;
  setOpenModal: (value: OpenModalType) => void;
  onClose?: () => void;
  dashboardSafetyPositionPresetId?: string | null;
}

interface FormData {
  firstName: string;
  lastName: string;
  note: string;
  safetyPositionPresetId: string | null;
  fallRisk: FallRisk | null;
  start: Date | null;
}

const schema = object({
  firstName: string().required(t`Name cannot be empty`),
});

const fallRiskTranslations = {
  [FallRisk.LOW]: () => t`Low`,
  [FallRisk.HIGH]: () => t`High`,
};

const Header = ({
  bed,
  modalData,
  setOpenModal,
  onClose,
  dashboardSafetyPositionPresetId,
}: Props) => {
  const [sessionEnded, setSessionEnded] = useState(false);
  const [newSession, setNewSession] = useState(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [discharge, setDischarge] = useState(false);

  const { data: safetyPositionPresetsData } = useQuery<SafetyPositionPresets>(
    safetyPositionPresets,
    {
      skip:
        dashboardSafetyPositionPresetId == null ||
        bed?.currentSession?.safetyPositionPresetId != null,
    },
  );

  const dashboardSafetyPositionPreset =
    safetyPositionPresetsData?.safetyPositionPresets?.find(
      (preset) => preset.id === dashboardSafetyPositionPresetId,
    );

  const { i18n } = useLingui();

  const [updatePatientMutation] = useSnackbarMutation<
    EditPatientSession,
    EditPatientSessionVariables
  >(updatePatientSession);
  const [endSessionMutation] = useSnackbarMutation<
    EndSession,
    EndSessionVariables
  >(endSession);
  const [startSessionMutation] = useSnackbarMutation<
    StartPatientSession,
    StartPatientSessionVariables
  >(startPatientSession);

  const saveSession = async (values: FormData) => {
    if (patientSession) {
      const {
        firstName,
        lastName,
        note,
        fallRisk,
        start,
        safetyPositionPresetId,
      } = values;
      updatePatientMutation({
        variables: {
          input: {
            name: createSessionName(firstName, lastName),
            note,
            start: start || new Date(),
            safetyPositionPresetId:
              safetyPositionPresetId === 'UNIT_PRESET'
                ? ''
                : safetyPositionPresetId,
            fallRisk,
            id: patientSession.id,
            unitId: bed.unitId,
          },
        },
      }).then((data) => {
        if (data) {
          setEditing(false);
        }
      });
    }
  };

  const correctData = modalData || bed?.currentSession;
  const [firstNameInital, lastNameInital] = parseInitials(
    correctData?.name || '',
  );
  const formik = useFormik<FormData>({
    initialValues: {
      firstName: firstNameInital || '',
      lastName: lastNameInital || '',
      note: correctData?.note || '',
      safetyPositionPresetId:
        correctData?.safetyPositionPresetId || 'UNIT_PRESET',
      fallRisk: correctData?.fallRisk || null,
      start: correctData?.sessionStart
        ? new Date(correctData?.sessionStart)
        : null,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: saveSession,
  });

  const { presets } = useSafetyPositionPresets();

  const patientSession = bed?.currentSession;
  const selectedPreset = useMemo(() => {
    if (
      !patientSession?.safetyPositionPresetId ||
      !presets?.safetyPositionPresets
    ) {
      return null;
    }
    return presets?.safetyPositionPresets.find(
      (preset) => preset.id === patientSession.safetyPositionPresetId,
    );
  }, [presets?.safetyPositionPresets, patientSession?.safetyPositionPresetId]);

  const openNewSessionModal = () => {
    if (sessionEnded) {
      setSessionEnded(false);
    }
    setNewSession(true);
  };

  const editHeader = () => {
    setEditing(true);
  };

  const onDischarge = async (date: Date) => {
    if (patientSession) {
      await endSessionMutation({
        variables: {
          id: patientSession.id,
          end: date,
        },
        refetchQueries: ['BedHeaderModalData'],
      });
      setSessionEnded(true);
    }
  };

  const onSessionStart = async ({ name, note, start }: StartSessionProps) => {
    await startSessionMutation({
      variables: {
        input: {
          name,
          note,
          unitId: bed.unitId,
          sessionStart: start,
        },
      },
      update: (proxy, result) => {
        const newPatientSession = result.data?.startSession;
        const bedQuery = proxy.readQuery<
          BedHeaderModalData,
          BedHeaderModalDataVariables
        >({
          query: bedHeaderModalData,
          variables: {
            unitId: bed.unitId,
          },
        });
        const bedData = bedQuery?.bed;
        if (!bedData || !newPatientSession) {
          return;
        }
        proxy.writeQuery<BedHeaderModalData, BedHeaderModalDataVariables>({
          query: bedHeaderModalData,
          variables: {
            unitId: bed.unitId,
          },
          data: {
            bed: {
              ...bedData,
              currentSession: {
                __typename: 'PatientSession' as 'PatientSession',
                id: newPatientSession.id,
                name: newPatientSession.name,
                fallRisk: newPatientSession.fallRisk,
                note: newPatientSession.note,
                sessionStart: newPatientSession.sessionStart,
                sessionEnd: null,
                safetyPositionPresetId: null,
              },
            },
          },
        });
        formik.setFieldValue('start', newPatientSession.sessionStart);
      },
    });
  };

  const { palette } = useTheme();
  const parkingPlace = bed?.parkingPlaces?.[0];
  const endDate = modalData?.sessionEnd ?? patientSession?.sessionEnd;
  const { values } = formik;

  return (
    <>
      <Flex flex={1}>
        <FormikForm formik={formik} sx={{ flex: 1 }}>
          <Grid pt={2} columnGap={4} container rowSpacing={2}>
            <Grid item xs={2.5}>
              <BedPlaceTitle>{`${parkingPlace?.room?.name || ''} [${
                parkingPlace?.name || '-'
              }]`}</BedPlaceTitle>
              <Typography fontSize="0.625rem">
                {bed?.unitId
                  ? `${bed?.type || 'none'} - ${bed?.unitId}`
                  : 'none'}
              </Typography>
              <Typography fontSize="0.625rem">{bed?.name}</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <ValueInfo
                label={t`Session ID`}
                value={modalData?.id ?? patientSession?.id}
                placeholder={t`No session`}
              />
            </Grid>
            <Grid item xs={2.5}>
              {editing ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                  }}
                >
                  <FormikTextField
                    label={t`Session name`}
                    sx={{
                      position: 'relative',
                      '& p.MuiFormHelperText-root.Mui-error': {
                        display: 'none',
                      },
                    }}
                    variant="outlined"
                    size="small"
                    name="firstName"
                    inputProps={{
                      maxLength: 1,
                    }}
                  />
                  <Box
                    sx={{
                      ml: 0.25,
                      mr: 1,
                    }}
                  >
                    .
                  </Box>
                  <FormikTextField
                    sx={{ position: 'relative' }}
                    variant="outlined"
                    size="small"
                    name="lastName"
                    inputProps={{
                      maxLength: 1,
                    }}
                  />
                  <Box
                    sx={{
                      ml: 0.25,
                      mr: 1,
                    }}
                  >
                    .
                  </Box>
                </Box>
              ) : (
                <ValueInfo
                  label={t`Session name`}
                  placeholder={t`No patient`}
                  value={patientSession?.name || ''}
                  valueSx={{
                    marginTop: '0.5rem',
                    minHeight: '40px',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={2.5}>
              {editing ? (
                <DateTimeInput
                  max={new Date()}
                  name="start"
                  label={t`Session started`}
                  value={formik.values.start}
                  variant="outlined"
                  size="small"
                  onChange={(date) => formik.setFieldValue('start', date)}
                />
              ) : (
                <ValueInfo
                  placeholder="--"
                  label={t`Session started`}
                  value={
                    values.start
                      ? i18n.date(values.start, {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                        })
                      : undefined
                  }
                />
              )}
            </Grid>
            {!!modalData ? (
              <Grid item xs={2.5}>
                <ValueInfo
                  label={t`Session ended`}
                  value={
                    endDate &&
                    i18n.date(endDate, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    })
                  }
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={2.5}
                sx={{
                  justifyContent: 'start',
                  alignItems: 'end',
                  display: 'flex',
                }}
              >
                <ActionButton
                  editing={editing}
                  session={patientSession}
                  edit={editHeader}
                  saveSession={formik.submitForm}
                  startSession={openNewSessionModal}
                />
              </Grid>
            )}
            <Grid item xs={2.5}>
              {editing ? (
                <FormikTextField
                  fullWidth
                  select
                  name="fallRisk"
                  label={t`Fall risk`}
                  variant="outlined"
                  size="small"
                >
                  <MenuItem value={FallRisk.LOW}>{t`Low`}</MenuItem>
                  <MenuItem value={FallRisk.HIGH}>{t`High`}</MenuItem>
                </FormikTextField>
              ) : (
                <ValueInfo
                  placeholder={t`Not selected`}
                  label={t`Fall risk`}
                  labelSx={
                    values.fallRisk === FallRisk.HIGH
                      ? { color: palette.error.main }
                      : undefined
                  }
                  value={
                    values.fallRisk
                      ? fallRiskTranslations[values.fallRisk]()
                      : null
                  }
                />
              )}
            </Grid>
            <Grid item xs={2.5}>
              {editing ? (
                <FormikTextField
                  fullWidth
                  select
                  name="safetyPositionPresetId"
                  label={t`Safety Position`}
                  variant="outlined"
                  size="small"
                >
                  <MenuItem
                    key="UNIT_PRESET"
                    value="UNIT_PRESET"
                  >{t`Use unit preset`}</MenuItem>
                  {presets?.safetyPositionPresets.map((preset) => (
                    <MenuItem key={preset.id} value={preset.id}>
                      {preset.name}
                    </MenuItem>
                  ))}
                </FormikTextField>
              ) : (
                <ValueInfo
                  placeholder={dashboardSafetyPositionPreset?.name || ''}
                  label={t`Safety Position`}
                  value={selectedPreset?.name}
                />
              )}
            </Grid>
            <Grid item xs={2.5}>
              {editing ? (
                <FormikTextField
                  fullWidth
                  name="note"
                  label={t`Note`}
                  variant="outlined"
                  size="small"
                />
              ) : (
                <ValueInfo
                  placeholder={t`Empty`}
                  value={values.note}
                  label={t`Note`}
                />
              )}
            </Grid>
          </Grid>
        </FormikForm>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ color: 'text.secondary' }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
          {patientSession && !modalData ? (
            <Button
              onClick={() => setDischarge(true)}
              variant="outlined"
              disabled={editing}
            >
              <Trans>Discharge</Trans>
            </Button>
          ) : null}
        </Box>
      </Flex>
      <DischargeDialog
        setOpen={setDischarge}
        open={discharge}
        sessionStart={bed?.currentSession?.sessionStart}
        onDischarge={onDischarge}
      />
      <NewSessionDialog
        setOpen={setNewSession}
        open={newSession}
        onSessionStart={onSessionStart}
      />
      <SessionEndedDialog
        onGoToDashboard={() => setOpenModal(closeModalProps)}
        onNewSession={openNewSessionModal}
        setOpen={setSessionEnded}
        open={sessionEnded}
      />
    </>
  );
};

interface SaveButtonProps {
  editing: boolean;
  session: any;
  edit: () => void;
  saveSession: () => void;
  startSession: () => void;
}

const ActionButton = ({
  editing,
  edit,
  session,
  saveSession,
  startSession,
}: SaveButtonProps) => {
  if (editing) {
    return (
      <Button onClick={saveSession} variant="contained">
        {t`Save`}
      </Button>
    );
  }
  if (session) {
    return (
      <Button onClick={edit} variant="contained">
        {t`Edit details`}
      </Button>
    );
  }
  return (
    <Button onClick={startSession} variant="contained">
      {t`New patient`}
    </Button>
  );
};

export default Header;

const BedPlaceTitle = styled(Typography)`
  font-weight: 500;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
