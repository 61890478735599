import { TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';

type Props = TextFieldProps & {
  value: string;
  onAutoSave: (value: string) => void;
  normalizeValue?: (value: string) => string;
};

const TextFieldAutosave = ({
  value: savedValue,
  onAutoSave,
  onBlur,
  onKeyUp,
  onChange,
  normalizeValue = (v) => v,
  ...textFieldProps
}: Props) => {
  const [value, setValue] = useState('');

  useEffect(() => setValue(savedValue), [savedValue]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onAutoSave(e.target.value.trim());
    onBlur && onBlur(e);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Escape':
        setValue(savedValue);
        break;
      case 'Enter':
        onAutoSave(value.trim());
        break;
      default:
        break;
    }
    onKeyUp && onKeyUp(e);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setValue(normalizeValue(e.target.value));
    onChange && onChange(e);
  };

  return (
    <TextField
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      {...textFieldProps}
    />
  );
};

export default TextFieldAutosave;
