import ExportData from '../../Export/components/ExportData';

interface Props {
  patientSessionId: string;
  sessionStart?: string | null;
  sessionEnd?: string | null;
  sessionName: string | null;
  unitId: string;
}

const MobileExport = ({
  patientSessionId,
  sessionStart,
  sessionEnd,
  sessionName,
  unitId,
}: Props) => {
  return (
    <ExportData
      sessionId={patientSessionId}
      sessionStart={sessionStart ? new Date(sessionStart) : undefined}
      sessionEnd={sessionEnd ? new Date(sessionEnd) : undefined}
      sessionName={sessionName}
      unitId={unitId}
      open={true}
    />
  );
};

export default MobileExport;
