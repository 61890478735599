export interface NurseCallState {
  safetyPositionWarning: boolean;
  codeBlue: boolean;
  bedExitAlarm: boolean;
  unplugged: boolean;
}

export interface DemoState {
  name: string;
  image: any;
  msgId: string;
  unitId: string;
  date: Date;
  values: Record<string, string>;
  messages: string[];
  legend: string[];
  state: NurseCallState;
  imageSize: {
    width: number;
    height: number;
  };
}

const demoStateOrder = [
  'base',
  'autocontour',
  'unplugged',
  'plugged',
  'antitrendelenburg',
  'backrest30',
  'backrest0',
  'trendelenburg',
  'mobilisation',
  'cardiacChair',
  'cpr',
  'lateralRight',
  'lateralLeft',
  'bedHighest',
  'bedLowest',
  'bedExit',
  'patientOnBedNS',
  'patientOnBedS',
  'unbraked',
  'legSiderailDown',
  'examination',
];

export const getPrevState = (currentState: string) => {
  const idx = demoStateOrder.findIndex((state) => state === currentState);
  return demoStateOrder[idx - 1];
};

export const getNextState = (currentState: string) => {
  const idx = demoStateOrder.findIndex((state) => state === currentState);
  return demoStateOrder[idx + 1];
};

export const demoStates: Record<string, DemoState> = {
  base: {
    name: 'Safety Position',
    image: require('src/images/safety-position-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    msgId: 'MSG-33338',
    unitId: 'S738F00129',
    values: {
      LINIS0002: '1',
      LINIS0005: '1',
      LINIS0006: '1',
      LINIS0007: '30',
      LINIS0008: '1',
      LINIS0011: '1',
      LINIS0014: '1',
      LINIS0015: '1',
      LINIS0016: '1',
      LINIS0017: '1',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103532+0000||ORU^R01|MSG-33338|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103532+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103532+0000',
      'OBX|2|NM|0002^LINIS||1||||||F|||20200428103532+0000',
      'OBX|5|NM|0005^LINIS||1||||||F|||20200428103532+0000',
      'OBX|6|NM|0006^LINIS||1||||||F|||20200428103532+0000',
      'OBX|7|NM|0007^LINIS||30|deg|||||F|||20200428103532+0000',
      'OBX|8|NM|0008^LINIS||1||||||F|||20200428103532+0000',
      'OBX|11|NM|0011^LINIS||1||||||F|||20200428103532+0000',
      'OBX|14|NM|0014^LINIS||1||||||F|||20200428103532+0000',
      'OBX|15|NM|0015^LINIS||1||||||F|||20200428103532+0000',
      'OBX|16|NM|0016^LINIS||1||||||F|||20200428103532+0000',
      'OBX|17|NM|0017^LINIS||1||||||F|||20200428103532+0000',
    ],
    legend: [
      'OBX|2| bed is in safety position',
      'OBX|5| all siderails are UP',
      'OBX|6| backrest is 30 degrees or higher',
      'OBX|7| backrest is 30 degrees',
      'OBX|8| bed is braked',
      'OBX|11| bed frame is in the lowest position',
      'OBX|14| left head siderail is UP',
      'OBX|15| right head siderail is UP',
      'OBX|16| left siderail is UP',
      'OBX|17| right siderail is UP',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: false,
      codeBlue: false,
      unplugged: false,
    },
  },
  autocontour: {
    name: 'Autocontour',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/autocontur-demo.png'),
    imageSize: {
      width: 400,
      height: 309,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0006: '1',
      LINIS0007: '30',
      LINIS0011: '1',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|6|NM|0006^LINIS||1||||||F|||20200428103314+0000',
      'OBX|7|NM|0007^LINIS||30|deg|||||F|||20200428103314+0000',
      'OBX|11|NM|0011^LINIS||1||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|6| backrest is 30 degrees or higher',
      'OBX|7| backrest is 30 degrees',
      'OBX|11| bed frame is in lowest position',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  unplugged: {
    name: 'Disconnected bed & Unplugged bed',
    msgId: 'MSG-33202',
    image: require('src/images/safety-position-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    unitId: 'S738F00129',
    values: {
      LINIS0018: '0',
      LINIS0019: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|18|NM|0018^LINIS||0||||||F|||20200428103314+0000',
      'OBX|19|NM|0019^LINIS||0||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|18| bed is not plugged into outlet',
      'OBX|19| bed is disconnected',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: false,
      codeBlue: false,
      unplugged: true,
    },
  },
  plugged: {
    name: 'Plugged & Connected bed (is charging)',
    msgId: 'MSG-33202',
    image: require('src/images/safety-position-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    unitId: 'S738F00129',
    values: {
      LINIS0018: '2',
      LINIS0019: '1',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|18|NM|0018^LINIS||2||||||F|||20200428103314+0000',
      'OBX|19|NM|0019^LINIS||1||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|18| bed is  plugged into outlet and battery is charging',
      'OBX|19| bed is connected',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: false,
      codeBlue: false,
      unplugged: false,
    },
  },
  antitrendelenburg: {
    name: 'Antitrendelenburg',
    msgId: 'MSG-33202',
    image: require('src/images/antitrendelenburg-demo.png'),
    imageSize: {
      width: 400,
      height: 287,
    },
    date: new Date('2020-04-28T10:35:32'),
    unitId: 'S738F00129',
    values: {
      LINIS0003: '1',
      LINIS0007: '30',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|3|NM|0003^LINIS||13|deg|||||F|||20200428103314+0000',
      'OBX|7|NM|0007^LINIS||15|deg|||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|3| antitrendelenburg is 13 degrees',
      'OBX|7| backrest is 15 degrees',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  backrest30: {
    name: 'Backrest 30',
    msgId: 'MSG-33202',
    image: require('src/images/backrest30-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    unitId: 'S738F00129',
    values: {
      LINIS0006: '1',
      LINIS0007: '30',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|6|NM|0006^LINIS||1||||||F|||20200428103314+0000',
      'OBX|7|NM|0007^LINIS||30|deg|||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|6| backrest is 30 degrees or higher',
      'OBX|7| backrest is 30 degrees',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: false,
      codeBlue: false,
      unplugged: false,
    },
  },
  backrest0: {
    name: 'Backrest 0',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/cpr-demo.png'),
    imageSize: {
      width: 400,
      height: 224,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0006: '0',
      LINIS0007: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|6|NM|0006^LINIS||0||||||F|||20200428103314+0000',
      'OBX|7|NM|0007^LINIS||0|deg|||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|6| backrest is not 30 degrees or higher',
      'OBX|7| backrest is 0 degrees',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  trendelenburg: {
    name: 'Trendelenburg',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/trendelenburg-demo.png'),
    date: new Date('2020-04-28T10:35:32'),
    imageSize: {
      width: 400,
      height: 273,
    },
    values: {
      LINIS0003: '-13',
      LINIS0007: '-10',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|3|NM|0003^LINIS||-13|deg|||||F|||20200428103314+0000',
      'OBX|7|NM|0007^LINIS||-10|deg|||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|3| trendelenburg is -13 degrees',
      'OBX|7| backrest is -10 degrees',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  mobilisation: {
    name: 'Mobilisation position',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/mobilisation-demo.png'),
    imageSize: {
      width: 400,
      height: 336,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0006: '1',
      LINIS0007: '63',
      LINIS0011: '1',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|6|NM|0006^LINIS||1||||||F|||20200428103314+0000',
      'OBX|7|NM|0007^LINIS||63|deg|||||F|||20200428103314+0000',
      'OBX|11|NM|0011^LINIS||1||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|6| backrest is 30 degrees or higher',
      'OBX|7| backrest is 63 degrees',
      'OBX|11|bed frame is in lowest position',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  cardiacChair: {
    name: 'Cardiac chair',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/cardiac-chair-demo.png'),
    imageSize: {
      width: 400,
      height: 339,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0003: '13',
      LINIS0007: '75',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|3|NM|0003^LINIS||13|deg|||||F|||20200428103314+0000',
      'OBX|7|NM|0007^LINIS||75|deg|||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|3| trendelenburg is 13 degrees',
      'OBX|7| backrest is 75 degrees',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  cpr: {
    name: 'CPR',
    msgId: 'MSG-33202',
    image: require('src/images/cpr-demo.png'),
    imageSize: {
      width: 400,
      height: 224,
    },
    date: new Date('2020-04-28T10:35:32'),
    unitId: 'S738F00129',
    values: {
      LINIS0003: '0',
      LINIS0004: '0',
      LINIS0006: '0',
      LINIS0007: '0',
      LINIS0011: '1',
      LINIS0014: '1',
      LINIS0015: '1',
      LINIS0016: '1',
      LINIS0017: '1',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103532+0000||ORU^R01|MSG-33338|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103532+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103532+0000',
      'OBX|3|NM|0003^LINIS||0|deg|||||F|||20200428103532+0000',
      'OBX|4|NM|0004^LINIS||0|deg|||||F|||20200428103532+0000',
      'OBX|6|NM|0006^LINIS||0||||||F|||20200428103532+0000',
      'OBX|7|NM|0007^LINIS||0|deg|||||F|||20200428103532+0000',
      'OBX|11|NM|0011^LINIS||1||||||F|||20200428103532+0000',
      'OBX|14|NM|0014^LINIS||1||||||F|||20200428103532+0000',
      'OBX|15|NM|0015^LINIS||1||||||F|||20200428103532+0000',
      'OBX|16|NM|0016^LINIS||1||||||F|||20200428103532+0000',
      'OBX|17|NM|0017^LINIS||1||||||F|||20200428103532+0000',
    ],
    legend: [
      'OBX|3| trendelenburg is 0 degrees',
      'OBX|4| lateral tilt is 0 degrees',
      'OBX|6| backrest is lower than 30 degrees',
      'OBX|7| backrest is 0 degree',
      'OBX|11| bed frame is in lowest position',
      'OBX|14| left head siderail is UP',
      'OBX|15| right head siderail is UP',
      'OBX|16| left siderail is UP',
      'OBX|17| right siderail is UP',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  lateralRight: {
    name: 'Lateral Right',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/lateral-right-demo.png'),
    imageSize: {
      width: 317,
      height: 340,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0004: '15',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|4|NM|0004^LINIS||15|deg|||||F|||20200428103314+0000',
    ],
    legend: ['OBX|4| lateral tilt is 15 degrees right'],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  lateralLeft: {
    name: 'Lateral LEFT',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/lateral-left-demo.png'),
    imageSize: {
      width: 317,
      height: 340,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0004: '-15',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|4|NM|0004^LINIS||-15|deg|||||F|||20200428103314+0000',
    ],
    legend: ['OBX|4| lateral tilt is 15 degrees left'],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  bedHighest: {
    name: 'Bed Highest position',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/bed-highest-demo.png'),
    imageSize: {
      width: 400,
      height: 242,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0011: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|11|NM|0011^LINIS||0||||||F|||20200428103314+0000',
    ],
    legend: ['OBX|11|bed frame is not in lowest position'],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  bedLowest: {
    name: 'Bed Lowest position',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/bed-lowest-demo.png'),
    imageSize: {
      width: 400,
      height: 170,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0011: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|11|NM|0011^LINIS||1||||||F|||20200428103314+0000',
    ],
    legend: ['OBX|11|bed frame is  in lowest position'],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  bedExit: {
    name: 'Bed Exit (outer)',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/siderail-down-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0009: '1',
      LINIS0010: '1',
      LINIS0012: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|9|NM|0006^LINIS||1||||||F|||20200428103314+0000',
      'OBX|10|NM|0007^LINIS||1||||||F|||20200428103314+0000',
      'OBX|12|NM|0012^LINIS||0||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|09|Outer bed exit monitoring is active',
      'OBX|10|Bed exit alarm occurred',
      'OBX|12|Patient is not on bed',
    ],
    state: {
      bedExitAlarm: true,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  patientOnBedNS: {
    name: 'Patient on bed (not safe position)',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/patient-on-bed-ns-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0001: '187',
      LINIS0012: '1',
      LINIS0017: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|1|NM|0001^LINIS||187|lbs|||||F|||20200428103314+0000',
      'OBX|2|NM|0002^LINIS||0||||||F|||20200428103314+0000',
      'OBX|12|NM|0012^LINIS||1||||||F|||20200428103314+0000',
      'OBX|17|NM|0017^LINIS||0||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|1|patient weight is 187 lbs',
      'OBX|2|bed not in safety position ',
      'OBX|12|patient on bed',
      'OBX|17|Right Siderail is DOWN',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  patientOnBedS: {
    name: 'Weight & Patient on bed (safe position)',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/patient-on-bed-s-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0001: '187',
      LINIS0002: '1',
      LINIS0012: '1',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|1|NM|0001^LINIS||187|lbs|||||F|||20200428103314+0000',
      'OBX|2|NM|0002^LINIS||1||||||F|||20200428103314+0000',
      'OBX|12|NM|0012^LINIS||1||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|1|patient weight is 187 lbs',
      'OBX|2|bed in safety position ',
      'OBX|12|patient on bed',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: false,
      codeBlue: false,
      unplugged: false,
    },
  },
  unbraked: {
    name: 'Bed unbraked  (not safe position)',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/unbraked-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0002: '0',
      LINIS0008: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|2|NM|0002^LINIS||0||||||F|||20200428103314+0000',
      'OBX|8|NM|0008^LINIS||0||||||F|||20200428103314+0000',
    ],
    legend: ['OBX|2| bed not in safety position ', 'OBX|8| bed is unbraked'],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  legSiderailDown: {
    name: 'Leg siderail down (not safe position)',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/siderail-down-demo.png'),
    imageSize: {
      width: 400,
      height: 237,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0002: '0',
      LINIS0017: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|2|NM|0002^LINIS||0||||||F|||20200428103314+0000',
      'OBX|17|NM|0008^LINIS||0||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|2| bed not in safety position ',
      'OBX|17| leg siderail is down',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
  examination: {
    name: 'Examination position',
    msgId: 'MSG-33202',
    unitId: 'S738F00129',
    image: require('src/images/bed-highest-demo.png'),
    imageSize: {
      width: 400,
      height: 243,
    },
    date: new Date('2020-04-28T10:35:32'),
    values: {
      LINIS0007: '0',
      LINIS0011: '0',
      LINIS0017: '0',
    },
    messages: [
      'MSH|^~\\&|LINISM|SafetyMonitor|||20200428103314+0000||ORU^R01|MSG-33202|P|2.5',
      'OBR|1|LINIS||LINIS^SafetyMonitor|||20200428103314+0000',
      'PV1||N|Acute care^Room 4^S738F00129|20200428103314+0000',
      'OBX|7|NM|0007^LINIS||0|deg|||||F|||20200428103314+0000',
      'OBX|11|NM|0011^LINIS||0||||||F|||20200428103314+0000',
      'OBX|17|NM|0017^LINIS||0||||||F|||20200428103314+0000',
    ],
    legend: [
      'OBX|7| backrest is 0 degrees',
      'OBX|11| bed frame is not in lowest position',
      'OBX|17| right siderail is down',
    ],
    state: {
      bedExitAlarm: false,
      safetyPositionWarning: true,
      codeBlue: false,
      unplugged: false,
    },
  },
};
