import { t } from '@lingui/macro';
import { useFormik } from 'formik';
import { useContext, useMemo } from 'react';
import { AuthContext } from '../../../auth/AuthProvider';
import {
  getFlagsFromOptions,
  getOptionsFromFlag,
} from '../../../auth/passwordValidation';
import ContentBlock from '../../../components/ContentBlock';
import FormikForm from '../../../components/Formik/FormikForm';
import { useSnackbarMutation } from '../../../graphql/apolloExtenstion';
import { upsertSuperConfig } from '../../../graphql/mutations';
import { configQuery } from '../../../graphql/queries';
import {
  upsertSuperConfigMutation,
  upsertSuperConfigMutationVariables,
} from '../../../graphql/types';
import { PasswordForm, PasswordSettingFormikProps } from './PasswordForm';

export const UserPasswordForm = () => {
  const { config } = useContext(AuthContext);
  const [updateConfig] = useSnackbarMutation<
    upsertSuperConfigMutation,
    upsertSuperConfigMutationVariables
  >(
    upsertSuperConfig,
    {
      refetchQueries: () => [{ query: configQuery }],
    },
    {
      successMessage: t`Admin password configuration saved.`,
    },
  );
  const passwordSettings = useMemo(() => {
    const passwordFlags = config?.superConfig?.passwordFlags || 0;
    const minLength = config?.superConfig?.passwordMinLength || 0;
    const passwordOptions = getOptionsFromFlag(passwordFlags);
    return {
      ...passwordOptions,
      minLength,
    };
  }, [config?.superConfig]);

  const formik = useFormik<PasswordSettingFormikProps>({
    initialValues: {
      alfaRequired: passwordSettings.alfaRequired || false,
      alfaLowerRequired: passwordSettings.alfaLowerRequired || false,
      alfaUpperRequired: passwordSettings.alfaUpperRequired || false,
      numericRequired: passwordSettings.numericRequired || false,
      symbolRequired: passwordSettings.symbolRequired || false,
      minLength: passwordSettings.minLength || 0,
    },
    enableReinitialize: true,
    onSubmit: (formProps) => {
      const passwordFlags = getFlagsFromOptions(formProps);
      const passwordMinLength = Math.max(formProps.minLength, 0);
      updateConfig({
        variables: {
          config: {
            passwordFlags,
            passwordMinLength,
          },
        },
      });
    },
  });

  return (
    <ContentBlock
      isSaveDisabled={!formik.dirty || formik.isSubmitting}
      onCancel={formik.resetForm}
      onSave={formik.submitForm}
      title=" "
    >
      <FormikForm name="user_settings_form" formik={formik}>
        <PasswordForm />
      </FormikForm>
    </ContentBlock>
  );
};
