import { SvgIcon, SvgIconProps } from '@mui/material';

export const UnpinIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <path
          d="M2 2.44727L21.553 22.0002"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.2069 12.5442L21.3603 8.38954M15.6095 2.63867L11.4573 6.7908L15.6095 2.63867ZM8.17296 8.62213L5.38571 9.66751L3.46875 11.5845L12.4145 20.5303L14.3315 18.6133L15.3794 15.8197L8.17296 8.62213Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.94228 16.0586L2.19141 21.8095"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9727 2L22.0015 9.02884"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};
