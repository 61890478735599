import { Dialog, Box } from '@mui/material';
import ExportData, { ExportDataProps } from './ExportData';

interface Props extends ExportDataProps {
  open: boolean;
  close: () => void;
}

const ExportDataDialog = ({
  open,
  close,
  sessionId,
  sessionStart,
  sessionEnd,
  content,
  sessionName,
  unitId,
}: Props) => {
  return (
    <Dialog open={open} onClose={() => close()}>
      <Box width={730} p={4}>
        <ExportData
          sessionId={sessionId}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
          content={content}
          sessionName={sessionName}
          unitId={unitId}
          open={open}
          close={close}
        />
      </Box>
    </Dialog>
  );
};

export default ExportDataDialog;
