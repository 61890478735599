import { Trans } from '@lingui/macro';
import { SiderailsPosition } from 'src/graphql/types';

interface Props {
  val: SiderailsPosition;
}

const SiderailPositionTrans = ({ val }: Props) => {
  switch (val) {
    case SiderailsPosition.ALL_UP:
      return <Trans>All up</Trans>;
    case SiderailsPosition.ANY_DOWN:
      return <Trans>Any one down</Trans>;
    case SiderailsPosition.ONE_BOTTOM_DOWN:
      return <Trans>One bottom down</Trans>;
    case SiderailsPosition.ONE_HEAD_DOWN:
      return <Trans>One head down</Trans>;
    case SiderailsPosition.ONE_SIDE_DOWN:
      return <Trans>Whole side down</Trans>;
    default:
      console.error('SiderailPositionTrans missing for val=', val);
      return val;
  }
};

export default SiderailPositionTrans;
