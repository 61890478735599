import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { insightQuery } from 'src/graphql/queries';
import {
  InsightQuery,
  InsightQueryVariables,
  SafetyPortProps,
} from 'src/graphql/types';

interface Params {
  unitId: string;
  from: Date;
  prop: SafetyPortProps | null;
}

const INISGHT_LIMIT = 50;

const getLast = (entries?: InsightQuery) => {
  const currentLength = entries?.insight?.data.length;
  if (!currentLength) {
    return null;
  }
  const last = entries?.insight?.data[currentLength - 1];
  if (!last) {
    return null;
  }
  return last;
};

export const useInsight = ({ unitId, from, prop }: Params) => {
  const {
    data: entries,
    refetch,
    loading,
    fetchMore,
  } = useQuery<InsightQuery, InsightQueryVariables>(insightQuery, {
    skip: !unitId,
    variables: {
      unitId: unitId || '',
      dateFrom: from,
      prop: prop ?? null,
      limit: INISGHT_LIMIT,
    },
  });
  const lastTime = getLast(entries)?.time;

  const loadMore = useCallback(() => {
    if (loading) {
      return;
    }
    if (!unitId || !from) {
      return;
    }
    if (!lastTime) {
      return;
    }

    fetchMore<InsightQuery, InsightQueryVariables>({
      variables: {
        unitId,
        prop,
        limit: INISGHT_LIMIT,
        skipFirst: true,
        dateFrom: new Date(lastTime),
      },
      updateQuery: (prevResult, currentResult) => {
        if (!currentResult.fetchMoreResult?.insight?.data) {
          return prevResult;
        }
        const newResult = prevResult.insight.data.concat(
          currentResult.fetchMoreResult.insight.data,
        );
        return {
          ...prevResult,
          insight: {
            ...prevResult.insight,
            data: newResult,
            hasMore: currentResult.fetchMoreResult.insight.hasMore,
          },
        };
      },
    });
  }, [unitId, from, prop, lastTime, loading, fetchMore]);

  return {
    entries,
    loading,
    getLast,
    loadMore,
    refetch,
    canLoadMore: entries?.insight.hasMore,
  };
};
