import { useCallback, useEffect, useRef, useState } from 'react';
import {
  SafetyViewQuery_wardParkingPlaces,
  SafetyViewWardBeds_wardBeds,
  ThreeFF_threeFF,
} from 'src/graphql/types';
import { BedData, BedWithHighlight } from '../../helpers/dashboard';

const updateBedData = (oldBeds: BedData[], data: ThreeFF_threeFF) => {
  const beds = [...oldBeds];
  const index = beds.findIndex((bed) => bed.unitId === data.unitId);
  if (beds[index]) {
    beds[index].data = data;
  }

  return beds;
};

const getBedsData = (
  parkingPlaces: SafetyViewQuery_wardParkingPlaces[],
  beds: SafetyViewWardBeds_wardBeds[],
  prevBedsData: BedData[],
) => {
  const prevData = (prevBedsData || []).reduce(
    (res, bedData) =>
      bedData.unitId ? { ...res, [bedData.unitId]: bedData.data } : res,
    {},
  );
  const parkingPlaceResult: BedData[] = parkingPlaces.map((pp) => ({
    unitId: pp.bed?.unitId,
    patientId: pp.bed?.currentSession?.id,
    sessionName: pp.bed?.currentSession?.name,
    note: pp.bed?.currentSession?.note,
    state: pp.bed?.state,
    sessionStart: pp.bed?.currentSession?.sessionStart,
    fallRisk: pp?.bed?.currentSession?.fallRisk,
    type: pp.bed?.type,
    bedName: pp.bed?.name,
    ethConnection: !!pp?.bed?.ipAddressEthernet,
    roomName: pp.room.name || '',
    ppName: pp.name || '',
    pinned: Boolean(pp.bed?.pinned),
    location: pp.name
      ? `${pp.room.name || ''} [${pp.name}]`
      : pp.room.name || '',
    data: pp.bed?.unitId ? prevData[pp.bed?.unitId] || null : null,
  }));

  const wardBedResult: BedData[] = beds.map((bed) => {
    const room = bed.parkingPlaces?.[0]?.room;
    const wardName = room?.ward?.name;
    return {
      unitId: bed.unitId,
      patientId: bed.currentSession?.id,
      sessionName: bed.currentSession?.name,
      sessionStart: bed.currentSession?.sessionStart,
      fallRisk: bed.currentSession?.fallRisk,
      note: bed.currentSession?.note,
      state: bed.state,
      bedName: bed.name,
      ethConnection: !!bed?.ipAddressEthernet,
      type: bed.type,
      roomName: null,
      ppName: null,
      pinned: Boolean(bed.pinned),
      location: wardName ? `${wardName} [${room.name}]` : null,
      data: prevData[bed.unitId] || null,
      inAnotherWard: true,
    };
  });
  return parkingPlaceResult.concat(wardBedResult);
};

export const updateHighlightedBeds = (
  beds: BedData[],
  bedsWithHighligth: BedWithHighlight[],
) => {
  return beds.reduce((prevBedsWithHighligth, bed) => {
    const highlightBed = prevBedsWithHighligth.find(
      (b) => b.unitId === bed.unitId,
    );

    if (highlightBed && !bed?.data?.bedExitAlarm) {
      return prevBedsWithHighligth.filter((b) => b.unitId !== bed.unitId);
    }
    if (!highlightBed && bed?.data?.bedExitAlarm) {
      const newHighlightedBeds = prevBedsWithHighligth.concat([
        {
          ...bed,
          startedAt: new Date(),
        },
      ]);
      return newHighlightedBeds.sort(sortBedsWithHighlight);
    }
    return prevBedsWithHighligth;
  }, bedsWithHighligth);
};

export const sortBedsWithHighlight = (
  bed: BedWithHighlight,
  otherHBed: BedWithHighlight,
) => {
  return bed.startedAt > otherHBed.startedAt
    ? -1
    : bed.startedAt < otherHBed.startedAt
    ? 1
    : 0;
};

export const useThrottleBedData = (interval: number) => {
  const [bedsData, setBedsData] = useState<BedData[]>([]);
  const intervalRef = useRef<NodeJS.Timeout | null>();
  const innerBedData = useRef<Record<string, ThreeFF_threeFF>>({});

  useEffect(() => {
    return () => {
      intervalRef.current && clearTimeout(intervalRef.current);
    };
  }, []);

  const throttleIncomingBedData = useCallback(
    (data: ThreeFF_threeFF) => {
      innerBedData.current[data.unitId] = data;
      if (intervalRef.current) {
        return;
      }
      intervalRef.current = setTimeout(() => {
        setBedsData((currentBedData) => {
          const newBedData = Object.keys(innerBedData.current).reduce(
            (prevBedData, incomingData) => {
              return updateBedData(
                prevBedData,
                innerBedData.current[incomingData],
              );
            },
            currentBedData,
          );
          innerBedData.current = {};
          return newBedData;
        });
        intervalRef.current = null;
      }, interval);
    },
    [interval, setBedsData],
  );

  const updateBedsData = useCallback(
    (
      wardParkingPlaces: SafetyViewQuery_wardParkingPlaces[],
      wardBeds: SafetyViewWardBeds_wardBeds[] | null | undefined,
    ) => {
      setBedsData((prevBedsData) =>
        getBedsData(wardParkingPlaces, wardBeds || [], prevBedsData),
      );
    },
    [setBedsData],
  );

  return {
    bedsData,
    throttleIncomingBedData,
    updateBedsData,
    setBedsData,
  };
};
