import { FormikContextType } from 'formik';
import type { Blocker, History, Transition } from 'history';
import { equals } from 'ramda';
import {
  ContextType,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Navigator as BaseNavigator,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';
import { useDirtyContext } from './providers/DirtyProvider';

export const useMediaQuery = (query: string) => {
  const mediaMatch = window.matchMedia(query);
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: any) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return matches;
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useDirty = <T>(
  value: T,
  onDiscard?: Function,
): {
  isDirty: boolean;
  resetDirty: () => void;
  initValue: T;
  setInitValue: (val: T) => void;
} => {
  const [initValue, setInitValue] = useState(value);
  const { setIsPageDirty, discardFncRef } = useDirtyContext();

  discardFncRef.current = onDiscard;

  const resetDirty = useCallback(() => {
    setInitValue(value);
  }, [value]);

  useEffect(() => {
    if (initValue == null && value != null) {
      setInitValue(value);
    }
  }, [value, initValue]);

  const isDirty = !equals(initValue, value);

  useEffect(() => {
    setIsPageDirty(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return { isDirty, resetDirty, initValue, setInitValue };
};

export const useDirtyFormik = <T = any>(formik: FormikContextType<T>) => {
  const { setIsPageDirty, discardFncRef } = useDirtyContext();
  const { dirty, resetForm } = formik;
  useEffect(() => {
    setIsPageDirty(dirty);
  }, [dirty, setIsPageDirty]);

  useEffect(() => {
    discardFncRef.current = resetForm;
  }, [discardFncRef, resetForm]);
};

interface Navigator extends BaseNavigator {
  block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator;
};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(
    NavigationContext,
  ) as NavigationContextWithBlock;

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(
  message:
    | string
    | ((
        location: Transition['location'],
        action: Transition['action'],
      ) => string),
  when = true,
) {
  const blocker = useCallback(
    (tx: Transition) => {
      let response;
      if (typeof message === 'function') {
        response = message(tx.location, tx.action);
        if (typeof response === 'string') {
          response = window.confirm(response);
        }
      } else {
        response = window.confirm(message);
      }
      if (response) {
        tx.retry();
      }
    },
    [message],
  );
  return useBlocker(blocker, when);
}
