import { t } from '@lingui/macro';
import { ThreeFF_threeFF, BedType, FallRisk } from '../../../graphql/types';
import { SvDashboardSortingType } from '../Setting/DashboardSettingProvider';

export interface BedData {
  unitId?: string;
  patientId?: string;
  sessionName?: string | null;
  inAnotherWard?: boolean;
  ethConnection?: boolean;
  location: string | null;
  bedName?: string | null;
  state?: string | null;
  sessionStart: Date | null;
  type?: string | null;
  note?: string | null;
  data: ThreeFF_threeFF | null;
  roomName: string | null;
  ppName: string | null;
  pinned: boolean;
  fallRisk?: FallRisk | null;
}

export interface BedWithHighlight extends BedData {
  startedAt: Date;
}

export type BedMaybeHighligth = BedData & Partial<BedWithHighlight>;

export const getAllBedsHeaderTranslation = (type: SvDashboardSortingType) => {
  switch (type) {
    case SvDashboardSortingType.location:
      return t`Location`;
    case SvDashboardSortingType.name:
      return t`Session Name`;
    case SvDashboardSortingType.note:
      return t`Note`;
    case SvDashboardSortingType.bedExitMonitoringStatus:
      return t`LINIS0009_short`;
    case SvDashboardSortingType.outOfBed:
      return t`LINIS0012`;
    case SvDashboardSortingType.spAndLock:
      return t`LINIS0002`;
    case SvDashboardSortingType.siderails:
      return t`Siderails`;
    case SvDashboardSortingType.brakes:
      return t`LINIS0008`;
    case SvDashboardSortingType.lowestPosition:
      return t`LINIS0011`;
    case SvDashboardSortingType.backrestAndLock:
      return t`LINIS0007`;
    case SvDashboardSortingType.trendelenburgAngle:
      return t`LINIS0003`;
    case SvDashboardSortingType.weight:
      return t`LINIS0001`;
    case SvDashboardSortingType.laterailTiltAndAlt:
      return t`LINIS0004`;
    case SvDashboardSortingType.calfrestAngleAndLock:
      return t`LINIS0022`;
    case SvDashboardSortingType.thighrestLock:
      return t`LINIS0026`;
    case SvDashboardSortingType.mattressType:
      return t`LINIS0035_dashboard`;
    case SvDashboardSortingType.bedType:
      return t`LINIS0045`;
    case SvDashboardSortingType.sessionId:
      return t`Session ID`;
    case SvDashboardSortingType.sessionStart:
      return t`Session started`;
    case SvDashboardSortingType.bedName:
      return t`Bed Name`;
    case SvDashboardSortingType.tagCharge:
      return t`Tag Charge`;
    case SvDashboardSortingType.powerStatus:
      return t`Bed Power Status`;
    case SvDashboardSortingType.charging:
      return t`Charging`;
    case SvDashboardSortingType.transfer:
      return t`Transfer`;
    case SvDashboardSortingType.heightOfRLegColumn:
      return t`Height of Right Leg Column`;
    case SvDashboardSortingType.heightOfLLegColumn:
      return t`Height of Left Leg Column`;
    case SvDashboardSortingType.heightOfHeadColumn:
      return t`Height of Head Column`;
    case SvDashboardSortingType.mobiliftRight:
      return t`Mobi-Lift Right`;
    case SvDashboardSortingType.mobiliftLeft:
      return t`Mobi-Lift Left`;
    case SvDashboardSortingType.bedExtension:
      return t`Bed Extension`;
    case SvDashboardSortingType.pressedBtnAndUsedController:
      return t`Function Pressed`;
    case SvDashboardSortingType.rightLCDButtonPressed:
      return t`Right LCD Controller`;
    case SvDashboardSortingType.leftLCDButtonPressed:
      return t`Left LCD Controller`;
    case SvDashboardSortingType.legsControllerButtonPressed:
      return t`Foot Controller`;
    case SvDashboardSortingType.acpAndRoControllerButtonPressed:
      return t`Acp/Ro Controller`;
    case SvDashboardSortingType.digitalSiderailRightButtonPressed:
      return t`Right Digital Siderail Controller`;
    case SvDashboardSortingType.digitalSiderailLeftButtonPressed:
      return t`Left Digital Siderail Controller`;
    case SvDashboardSortingType.ALTError:
      return t`ALT Error`;
    case SvDashboardSortingType.errorOrStopCode:
      return t`Error Or Stop Code`;
    case SvDashboardSortingType.batteryFailure:
      return t`Battery Failure`;

    default:
      return null;
  }
};

export const getBedTypeName = (type?: string | null) => {
  switch (type) {
    case BedType.MULTICARE:
      return t`MC`;
    case BedType.MULTICARE_LE:
      return t`MCLE`;
    case BedType.MULTICARE_X:
      return t`MCX`;
    case BedType.E5:
      return t`E5`;
    case BedType.E4:
    case BedType.E4_NO_TRX:
      return t`E4`;
    case BedType.E3XC:
    case BedType.E3XC_NO_TRX:
      return t`E3XC`;
    case BedType.E3:
    case BedType.E3_NO_TRX:
      return t`E3`;
    case BedType.E2:
      return t`E2`;
    default:
      return null;
  }
};
