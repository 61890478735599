import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton, Typography, SxProps } from '@mui/material';
import Flex from 'src/components/Flex';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { InfoPopover } from '../components/InfoPopover';
import { DatapointInfo } from '../components/Icons/datapointInfo';

interface Props {
  label: string | null;
  add?: () => void;
  remove?: () => void;
  draggableProps?: DraggableProvidedDragHandleProps;
  selected?: boolean;
  info?: DatapointInfo;
  sx?: SxProps;
}

export const DatapointItem = ({
  label,
  add,
  remove,
  draggableProps,
  info,
  sx,
}: Props) => {
  return (
    <Flex justifyContent="space-between" px={2} sx={sx}>
      <Flex alignItems="center">
        {draggableProps ? (
          <div style={{ display: 'flex' }} {...draggableProps}>
            <MenuIcon fontSize="small" color="primary" sx={{ mr: 0.5 }} />
          </div>
        ) : null}
        <Flex minWidth="220px">
          <Typography sx={{ mr: 1 }} noWrap>
            {label}
          </Typography>

          {info ? (
            <InfoPopover
              title={info.title}
              description={info.text}
              options={info.options}
            />
          ) : null}
        </Flex>
      </Flex>
      <Box>
        {add ? (
          <>
            <IconButton color="primary" size="small" onClick={add}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          </>
        ) : null}
        {remove ? (
          <>
            <IconButton color="primary" size="small" onClick={remove}>
              <RemoveCircleOutlineIcon color="primary" />
            </IconButton>
          </>
        ) : null}
      </Box>
    </Flex>
  );
};
