import { useQuery } from '@apollo/client';
import { FilterValue } from 'src/components/Filters';
import { roomNamesQuery } from 'src/graphql/queries';
import { RoomNamesQuery, RoomNamesQueryVariables } from 'src/graphql/types';
import { notEmpty } from 'src/lib/notEmpty';

export const SESSION_FILTER = 'sessionFilter';

export type FilterProps = {
  sessionName: FilterValue | null;
  room: FilterValue | null;
  sessionStarted: FilterValue | null;
  sessionEnded: FilterValue | null;
};

export const useSessionFilterOption = (wardId: string | null) => {
  const { data: roomData } = useQuery<RoomNamesQuery, RoomNamesQueryVariables>(
    roomNamesQuery,
    {
      variables: {
        wardId,
      },
    },
  );

  const roomOptions = roomData?.rooms
    ?.map((r) =>
      r
        ? {
            id: r.id,
            name: r.name,
          }
        : null,
    )
    .filter(notEmpty);

  return {
    roomOptions,
  };
};
