import { Trans } from '@lingui/macro';
import { Box, Button, styled, Typography } from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import { ThinTextField } from './components/ThinTextField';
import { FormikProps } from 'formik';
import { PasswordToggleAdornment } from 'src/components/PasswordInput/PasswordToggleAdornment';

export interface AdminLoginFormValues {
  password: string;
}

const AdminLoginForm = ({
  errors,
  touched,
  handleSubmit,
  isSubmitting,
  handleBlur,
  values,
  handleChange,
}: FormikProps<AdminLoginFormValues>) => {
  const [type, setType] = useState<'password' | 'text'>('password');

  const changeInputType: MouseEventHandler<HTMLSpanElement> = (event) => {
    event.preventDefault();
    setType((prev) => {
      return prev === 'password' ? 'text' : 'password';
    });
  };

  return (
    <Box sx={{ width: 300 }}>
      <form onSubmit={handleSubmit}>
        <Box>
          <InputLabel>
            <Trans>Password</Trans>
          </InputLabel>
          <ThinTextField
            type={type}
            name="password"
            onBlur={handleBlur}
            value={values.password}
            onChange={handleChange}
            variant="outlined"
            helperText={(touched.password && errors.password) || ''}
            error={touched.password && errors.password !== undefined}
            InputProps={{
              endAdornment: (
                <PasswordToggleAdornment toggle={changeInputType} type={type} />
              ),
            }}
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          sx={{ margin: '10px' }}
        >
          <Trans>Log in</Trans>
        </Button>
      </form>
    </Box>
  );
};

const InputLabel = styled(Typography)`
  font-weight: 900;
  font-size: 1rem;
  margin: 0px 10px;
`;

export default AdminLoginForm;
