import { t } from '@lingui/macro';
import { InputAdornment, Paper, TextField } from '@mui/material';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { RadioSelect } from 'src/components/RadioSelect';
import { FilterParameter, GoalType, RawReportFilter } from '../types';

interface Props {
  reportId?: string | null;
  goal?: number | null;
  parameter: FilterParameter;
  setGoal: React.Dispatch<React.SetStateAction<number>>;
  updateFilter: (partialFilter: Partial<RawReportFilter>) => void;
  goalType?: GoalType;
}

const translateUnit = {
  count: () => t`count`,
  number: () => t`number`,
  deg: () => t`deg`,
  kg: () => t`kg`,
};

const gType: Record<
  FilterParameter,
  'duration' | 'number' | 'deg' | 'count' | 'kg'
> = {
  [FilterParameter.ALL_SIDERAILS]: 'duration',
  [FilterParameter.ALOS]: 'duration',
  [FilterParameter.BACKREST]: 'duration',
  [FilterParameter.BACKREST_30]: 'duration',
  [FilterParameter.BACKREST_45]: 'duration',
  [FilterParameter.BACKREST_ANGLE]: 'deg',
  [FilterParameter.BED_EXIT_COUNT]: 'count',
  [FilterParameter.BED_EXIT_MONITORING_ON]: 'duration',
  [FilterParameter.BED_EXIT_REACTION]: 'duration',
  [FilterParameter.CALFREST]: 'duration',
  [FilterParameter.ALT_PHASE]: 'duration',
  [FilterParameter.LATERAL_TILT]: 'duration',
  [FilterParameter.LEFT_HEAD_SIDERAIL]: 'duration',
  [FilterParameter.LEFT_SIDERAIL]: 'duration',
  [FilterParameter.LOWEST_POSTITION]: 'duration',
  [FilterParameter.OCCUPANCY]: 'count',
  [FilterParameter.ONLINE]: 'duration',
  [FilterParameter.PATIENT_PRESENCE]: 'duration',
  [FilterParameter.RIGHT_HEAD_SIDERAIL]: 'duration',
  [FilterParameter.RIGHT_SIDERAIL]: 'duration',
  [FilterParameter.SAFETY_POSITION]: 'duration',
  [FilterParameter.TRENDELENBURG]: 'duration',
  [FilterParameter.WEIGHT]: 'kg',
};

export const ReportGoal = ({
  goal,
  goalType,
  parameter,
  setGoal,
  updateFilter,
}: Props) => {
  const [count, setCount] = useState(goal?.toString() || '');
  const [days, setDays] = useState(getDays(goal).toString());
  const [hours, setHours] = useState(getHours(goal).toString());
  const [minutes, setMinutes] = useState(getMinutes(goal).toString());

  const type = gType[parameter];

  useEffect(() => {
    setCount(goal?.toString() || '');
    setDays(getDays(goal).toString());
    setHours(getHours(goal).toString());
    setMinutes(getMinutes(goal).toString());
  }, [goal]);

  const handleGoalChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const eventValue = event.target.value;
    setCount(eventValue);
    if (eventValue === '') {
      return;
    }
    setGoal(Number(eventValue) || 0);
  };

  const handleDaysGoalChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const eventValue = event.target.value;
    setDays(eventValue);
    if (eventValue === '') {
      return;
    }
    const newGoal = parseDuration(
      Number(eventValue),
      Number(hours),
      Number(minutes),
    );
    setGoal(newGoal || 0);
  };

  const handleHoursGoalChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const eventValue = event.target.value;
    setHours(eventValue);
    if (eventValue === '') {
      return;
    }
    const newGoal = parseDuration(
      Number(days),
      Number(eventValue),
      Number(minutes),
    );
    setGoal(newGoal || 0);
  };

  const handleMinutesGoalChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const eventValue = event.target.value;
    setMinutes(eventValue);
    if (eventValue === '') {
      return;
    }
    const newGoal = parseDuration(
      Number(days),
      Number(hours),
      Number(eventValue),
    );
    setGoal(newGoal || 0);
  };

  const handleUpdateGoalType = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    updateFilter({ goalType: value as GoalType });
  };

  return (
    <Paper sx={{ mb: 2, p: 2, display: 'flex', alignItems: 'center' }}>
      {type === 'duration' ? (
        <>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{t`days`}</InputAdornment>
              ),
            }}
            sx={{ mr: 2 }}
            type="number"
            variant="outlined"
            label={t`Goal (days)`}
            value={days}
            onChange={handleDaysGoalChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{t`hours`}</InputAdornment>
              ),
            }}
            sx={{ mr: 2 }}
            type="number"
            variant="outlined"
            label={t`Goal (hours)`}
            value={hours}
            onChange={handleHoursGoalChange}
          />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{t`minutes`}</InputAdornment>
              ),
            }}
            type="number"
            variant="outlined"
            label={t`Goal (minutes)`}
            value={minutes}
            onChange={handleMinutesGoalChange}
          />
        </>
      ) : (
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {translateUnit[type]()}
              </InputAdornment>
            ),
          }}
          type="number"
          variant="outlined"
          label={t`Goal`}
          value={count}
          onChange={handleGoalChange}
        />
      )}
      <RadioSelect
        row
        value={goalType || GoalType.OVER}
        onChange={handleUpdateGoalType}
        options={[
          { label: t`Over`, value: GoalType.OVER },
          { label: t`Under`, value: GoalType.UNDER },
        ]}
        sx={{ ml: 2 }}
      />
    </Paper>
  );
};

const getDays = (duration?: number | null) => {
  if (!duration) {
    return 0;
  }
  return Math.floor(duration / 60 / 60 / 24);
};

const getHours = (duration?: number | null) => {
  if (!duration) {
    return 0;
  }
  return Math.floor(duration / 60 / 60) % 24;
};

const getMinutes = (duration?: number | null) => {
  if (!duration) {
    return 0;
  }
  return Math.floor(duration / 60) % 60;
};

const parseDuration = (days: number, hours: number, minutes: number) => {
  let result = 0;
  if (days) {
    const dayNum = Number(days);
    result += dayNum * 60 * 60 * 24;
  }
  if (hours) {
    const hoursNum = Number(hours);
    result += hoursNum * 60 * 60;
  }
  if (minutes) {
    const minutesNum = Number(minutes);
    result += minutesNum * 60;
  }
  return result;
};
