import { t } from '@lingui/macro';
import { Box, Divider, Tab, Tabs, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getAllBedsHeaderTranslation } from '../../helpers/dashboard';
import {
  DashboardItem,
  DashboardSettingContext,
  getChoicesFromOrder,
  SvDashboardSortChoice,
} from '../../Setting/DashboardSettingProvider';
import { DatapointItem } from '../../Setting/DatapointItem';
import { DatapointOrderingWrapper } from '../../Setting/DatapointOrderingWrapper';
import { DraggableDatapointItem } from '../../Setting/DraggableDatapointItem';

const MobileAvailableDatapoints = () => {
  const [datapointSearchText, setDatapointSearchText] = useState('');
  const savingRef = useRef(false);
  const { userSetting, currentDashboardOrder, changeUserSettings } = useContext(
    DashboardSettingContext,
  );
  const [currentTab, setCurrentTab] = useState<
    'dashboardOrder' | 'dashboardOrder2'
  >(currentDashboardOrder);
  const dashboardOrder =
    currentTab === 'dashboardOrder2'
      ? userSetting.dashboardOrder2
      : userSetting.dashboardOrder;
  const [choices, setChoices] = useState(
    getChoicesFromOrder(dashboardOrder.map((order) => order.type)),
  );
  const [draggableOrder, setDraggableOrder] = useState(dashboardOrder);

  useEffect(() => {
    setDraggableOrder(dashboardOrder);
    setChoices(getChoicesFromOrder(dashboardOrder.map((order) => order.type)));
  }, [dashboardOrder, setDraggableOrder, setChoices]);

  const removeDashboardItem = (dashboardItem: DashboardItem) => {
    if (savingRef.current) {
      return;
    }
    const choice = choices.find((choice) => choice.type === dashboardItem.type);
    if (choice) {
      changeVisibleStatus(choice);
    }
  };

  const changeVisibleStatus = async (choice: SvDashboardSortChoice) => {
    if (savingRef.current) {
      return;
    }
    if (choice.show) {
      const nextDashboardOrder = dashboardOrder.filter(
        (order) => order.type !== choice.type,
      );
      await handleSave(nextDashboardOrder);
    } else {
      const nextDashboardOrder = dashboardOrder.concat({
        type: choice.type,
        width: choice.width,
        align: choice.align,
      });
      await handleSave(nextDashboardOrder);
    }
  };

  const handleSave = async (nextDraggableOrder: DashboardItem[]) => {
    savingRef.current = true;

    const input =
      currentTab === 'dashboardOrder2'
        ? {
            dashboardOrder2: nextDraggableOrder
              .map((item) => item.type)
              .join(','),
          }
        : {
            dashboardOrder: nextDraggableOrder
              .map((item) => item.type)
              .join(','),
          };
    await changeUserSettings(input);
    savingRef.current = false;
  };

  const changeOrder = async (items: DashboardItem[]) => {
    setDraggableOrder(items);
    handleSave(items);
  };

  const changeTab = (_: any, value: string) => {
    setCurrentTab(
      value === 'dashboardOrder2' ? 'dashboardOrder2' : 'dashboardOrder',
    );
  };

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={changeTab}
        aria-label="settings-tabs"
        sx={{
          '& button:first-of-type': {
            marginLeft: '0',
          },
        }}
      >
        <Tab
          value="dashboardOrder"
          label={t`View 1`}
          sx={{ flex: 1 }}
          {...a11yProps('dashboardOrder')}
        />
        <Tab
          value="dashboardOrder2"
          label={t`View 2`}
          sx={{ flex: 1 }}
          {...a11yProps('dashboardOrder2')}
        />
      </Tabs>
      <Box mt={2}>
        <DatapointOrderingWrapper
          draggableDatapointsOrder={draggableOrder}
          setDraggableDatapointsOrder={changeOrder}
        >
          <>
            {draggableOrder.map((item, index, arr) => (
              <React.Fragment key={item.type}>
                <DraggableDatapointItem
                  id={item.type}
                  index={index}
                  label={getAllBedsHeaderTranslation(item.type)}
                  remove={() => removeDashboardItem(item)}
                  sx={{ px: 0 }}
                />
                {index === 8 && arr.length > 9 ? (
                  <Box textAlign="center" my={1}>
                    <Typography
                      sx={{
                        textTransform: 'none',
                        fontSize: '1rem',
                      }}
                      variant="caption"
                    >
                      {t`Following visible on desktop only`}
                    </Typography>
                  </Box>
                ) : null}
              </React.Fragment>
            ))}
            {!draggableOrder.length ? (
              <Typography textAlign="center" my={4}>
                {t`No datapoints selected.`}
              </Typography>
            ) : null}
          </>
        </DatapointOrderingWrapper>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Typography variant="caption">{t`Available datapoints`}</Typography>
      <Box mt={2}>
        <TextField
          placeholder={t`Find datapoint`}
          value={datapointSearchText}
          onChange={(e) => setDatapointSearchText(e.target.value)}
          fullWidth
          variant="outlined"
        />
      </Box>
      <Box mt={2}>
        {choices
          .filter((choice) => !choice.show)
          .filter((choice) =>
            datapointSearchText
              ? getAllBedsHeaderTranslation(choice.type)
                  ?.toLowerCase()
                  .includes(datapointSearchText.toLowerCase())
              : true,
          )
          .sort((choice, otherChoice) =>
            choice.type.localeCompare(otherChoice.type),
          )
          .map((choice) => (
            <DatapointItem
              key={choice.type}
              label={getAllBedsHeaderTranslation(choice.type)}
              add={() => changeVisibleStatus(choice)}
              sx={{ px: 0 }}
            />
          ))}
      </Box>
    </Box>
  );
};

export default MobileAvailableDatapoints;

const a11yProps = (tab: string) => {
  return {
    id: `mobile-dashboard-datapoint-tab-${tab}`,
    'aria-controls': `mobile-dashboard-datapoint-tabpanel-${tab}`,
  };
};
