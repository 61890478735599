import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import {
  Box,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Waypoint } from 'react-waypoint';
import {
  ListPageContent,
  ListPageRoot,
  Loading,
  TableWrapper,
  TopBar,
} from 'src/components/AdminList';
import { ActiveFilters, FilterValue, useFilter } from 'src/components/Filters';
import { TableHeader } from 'src/components/Table';
import { allRooms as fullRoomsQuery } from 'src/graphql/queries';
import {
  allRoomsQuery as FullRoomsQuery,
  allRoomsQuery_rooms as FullRoomsQuery_rooms,
  BedState,
} from 'src/graphql/types';
import {
  getSortState,
  NUMBER_COMPARATOR,
  SortKey,
  STRING_COMPARATOR,
  useCustomSorting,
} from 'src/lib/sortingHook';
import { FilterProps, ROOM_FILTER, useRoomFilterOption } from './filterOptions';

const WAYPOINT_OFFSET = 5;
const ROWS_INCREMENT = 30;

enum PlaceState {
  NO_BED = 0,
  BED_OFFLINE = 1,
  BED_ONLINE = 2,
}

const evaluators = {
  [SortKey.ID]: (room: FullRoomsQuery_rooms) => room?.id || 0,
  [SortKey.NAME]: (room: FullRoomsQuery_rooms) => room?.name || '',
  [SortKey.WARD]: (room: FullRoomsQuery_rooms) => room?.ward?.name || '',
  [SortKey.WORKSPACE]: (room: FullRoomsQuery_rooms) =>
    room?.workspace?.name || '',
  [SortKey.BED_COUNT]: (room: FullRoomsQuery_rooms) =>
    room ? getBedCount(room) : 0,
  [SortKey.BED_STATUS]: (room: FullRoomsQuery_rooms) =>
    room ? getStatusesValue(room) : 0,
};

const comparators = {
  [SortKey.ID]: NUMBER_COMPARATOR,
  [SortKey.NAME]: STRING_COMPARATOR,
  [SortKey.WARD]: STRING_COMPARATOR,
  [SortKey.WORKSPACE]: STRING_COMPARATOR,
  [SortKey.BED_COUNT]: NUMBER_COMPARATOR,
  [SortKey.BED_STATUS]: NUMBER_COMPARATOR,
};

const getBedCount = (room: FullRoomsQuery_rooms) => {
  return room?.parkingPlaces.filter((pp) => pp.bed).length;
};

const getStatusesValue = (room: FullRoomsQuery_rooms) => {
  return getStatuses(room).reduce((val, status) => {
    if (status === PlaceState.BED_ONLINE) {
      return val + 10000;
    }
    if (status === PlaceState.BED_OFFLINE) {
      return val + 100;
    }
    return val + 1;
  }, 0);
};

const getStatuses = (room: FullRoomsQuery_rooms) => {
  return room.parkingPlaces.map((pp) => {
    if (pp.bed) {
      return pp.bed.state === BedState.ONLINE
        ? PlaceState.BED_ONLINE
        : PlaceState.BED_OFFLINE;
    }
    return PlaceState.NO_BED;
  });
};

const Rooms = () => {
  const navigate = useNavigate();
  const { sorting, setSort } = useCustomSorting<FullRoomsQuery_rooms>(
    evaluators,
    comparators,
  );
  const getSortingState = (key: SortKey) => getSortState(sorting, key);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_INCREMENT);
  const { data, loading } = useQuery<FullRoomsQuery>(fullRoomsQuery);

  const { filter, activeFilters, setFilterValue, clear, clearAll } =
    useFilter<FilterProps>({
      name: ROOM_FILTER,
      types: {
        name: t`NAME`,
        ward: t`WARD`,
        workspace: t`WORKSPACE`,
      },
    });

  const { nameOptions, workspaceOptions, wardOptions } =
    useRoomFilterOption(filter);

  const getSortedRooms = (rooms?: FullRoomsQuery_rooms[]) => {
    if (!rooms) {
      return [];
    }
    const filteredRooms = rooms.filter(
      (room) =>
        (filter.name ? filter.name.name === room.name : true) &&
        (filter.ward ? filter.ward.id === room.ward?.id : true) &&
        (filter.workspace ? filter.workspace.id === room.workspace?.id : true),
    );
    if (sorting) {
      return filteredRooms.sort(sorting.sortFn);
    }
    return filteredRooms;
  };

  const handleClick = (room: FullRoomsQuery_rooms) => {
    navigate(`/admin/rooms/${room.id}`);
  };

  const sortedRooms = getSortedRooms(data && data.rooms);

  const loadMore = () => {
    setRowsPerPage((prev) => prev + ROWS_INCREMENT);
  };

  return (
    <>
      <ActiveFilters
        filters={activeFilters}
        clear={clear}
        clearAll={clearAll}
      />
      <ListPageRoot>
        <TopBar>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/admin/rooms/new')}
          >
             {t`Add room`}
          </Button>
        </TopBar>
        <ListPageContent sx={{ position: 'relative' }}>
          <TableWrapper>
            <Table stickyHeader sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableHeader
                      id="id"
                      sort={() => setSort(SortKey.ID)}
                      sortState={() => getSortingState(SortKey.ID)}
                      label={t`ID`}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="name"
                      sort={() => setSort(SortKey.NAME)}
                      sortState={() => getSortingState(SortKey.NAME)}
                      label={t`NAME`}
                      options={nameOptions}
                      value={filter.name}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('name', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="beds"
                      label={t`BEDS`}
                      sort={() => setSort(SortKey.BED_COUNT)}
                      sortState={() => getSortingState(SortKey.BED_COUNT)}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="status"
                      label={t`STATUS`}
                      sort={() => setSort(SortKey.BED_STATUS)}
                      sortState={() => getSortingState(SortKey.BED_STATUS)}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="workspace"
                      sort={() => setSort(SortKey.WORKSPACE)}
                      sortState={() => getSortingState(SortKey.WORKSPACE)}
                      label={t`WORKSPACE`}
                      options={workspaceOptions}
                      value={filter.workspace}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('workspace', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="ward"
                      sort={() => setSort(SortKey.WARD)}
                      sortState={() => getSortingState(SortKey.WARD)}
                      label={t`WARD`}
                      options={wardOptions}
                      value={filter.ward}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('ward', value)
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRooms.slice(0, rowsPerPage).map((room, idx, rooms) => (
                  <TableRow
                    key={room.id}
                    hover
                    sx={{
                      '&:hover': {
                        '& img': {
                          visibility: 'visible',
                        },
                      },
                    }}
                    onClick={() => handleClick(room)}
                  >
                    <TableCell>{room.id}</TableCell>
                    <TableCell>
                      {room.name}
                      {rooms.length - WAYPOINT_OFFSET === idx &&
                      rooms.length < sortedRooms.length ? (
                        <Waypoint onEnter={loadMore} />
                      ) : null}
                    </TableCell>
                    <TableCell>{getBedCount(room)}</TableCell>
                    <TableCell>
                      {getStatuses(room).map((status, idx) => (
                        <BedStatus key={idx} status={status} />
                      ))}
                    </TableCell>
                    <TableCell>
                      {room.workspace ? room.workspace?.name : ''}
                    </TableCell>
                    <TableCell>{room.ward ? room.ward.name : ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
          {loading && sortedRooms.length === 0 ? <Loading /> : null}
        </ListPageContent>
      </ListPageRoot>
    </>
  );
};

const BedStatus = styled(Box)<{ status: PlaceState }>(
  ({ status, theme: { palette } }) => `
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: ${
      status === PlaceState.BED_ONLINE
        ? palette.success.light
        : status === PlaceState.BED_OFFLINE
        ? palette.error.main
        : palette.grey[400]
    };
    border-radius: 16px;
    margin-right: 8px;
`,
);

export default Rooms;
