import { useQuery } from '@apollo/client';
import { uniq } from 'ramda';
import { FilterValue } from 'src/components/Filters';
import { wardNamesQuery, workspacesQuery } from 'src/graphql/queries';
import {
  UsersQuery_users,
  WardNamesQuery,
  WardNamesQueryVariables,
  WorkspacesQuery,
} from 'src/graphql/types';
import { notEmpty } from 'src/lib/notEmpty';

export const USER_FILTER = 'userFilter';

export type FilterProps = {
  name: FilterValue | null;
  surname: FilterValue | null;
  login: FilterValue | null;
  email: FilterValue | null;
  workspace: FilterValue | null;
  ward: FilterValue | null;
};

export const useUserFilterOption = (
  filter: FilterProps,
  users: UsersQuery_users[],
) => {
  const { data: workspacesData } = useQuery<WorkspacesQuery>(workspacesQuery);
  const { data: wardsData } = useQuery<WardNamesQuery, WardNamesQueryVariables>(
    wardNamesQuery,
    {
      variables: {
        workspaceId: filter.workspace?.id,
      },
    },
  );

  const nameOptions = uniq(
    users.map((user) => user.firstName).filter(notEmpty),
  ).map((value) => ({ id: value, name: value }));
  const surnameOptions = uniq(
    users.map((user) => user.lastName).filter(notEmpty),
  ).map((value) => ({ id: value, name: value }));
  const loginOptions = uniq(
    users.map((user) => user.username).filter(notEmpty),
  ).map((value) => ({ id: value, name: value }));
  const emailOptions = uniq(
    users.map((user) => user.email).filter(notEmpty),
  ).map((value) => ({ id: value, name: value }));
  const wardOptions = wardsData?.wards
    ?.map((w) =>
      w
        ? {
            id: w.id,
            name: w.name,
          }
        : null,
    )
    .filter(notEmpty);

  const workspaceOptions = workspacesData?.workspaces
    ?.map((ws) =>
      ws
        ? {
            id: ws.id,
            name: ws.name,
          }
        : null,
    )
    .filter(notEmpty);

  return {
    wardOptions,
    workspaceOptions,
    nameOptions,
    surnameOptions,
    loginOptions,
    emailOptions,
  };
};
