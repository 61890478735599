import { useState } from 'react';
import { Box, Dialog, Divider, Tabs } from '@mui/material';
import View from './View/PatientView';
import CareNotes from './CareNotes/CareNotes';
import Statistics from './Statistics/Statistics';
import Mobilization from './Mobilization/Mobilization';
import Header from './components/Header';
import { OpenModalType } from './modalTypes';
import { useQuery } from '@apollo/client';
import {
  BedHeaderModalData,
  BedHeaderModalDataVariables,
} from 'src/graphql/types';
import { bedHeaderModalData } from 'src/graphql/queries';
import ExportButton from './components/ExportButton';
import Insight from './Insight/Insight';
import { ThinTab } from 'src/components/ThinTab';
import { t } from '@lingui/macro';
import Flex from 'src/components/Flex';

interface Props {
  openModal: OpenModalType;
  setOpen: (value: OpenModalType) => void;
  dashboardSafetyPositionPresetId?: string | null;
}

export type ContentType =
  | 'patientView'
  | 'notes'
  | 'mobilization'
  | 'statistics'
  | 'insight';

export interface Content {
  type: ContentType;
  data?: {
    prop: string;
    from: Date;
    to: Date;
  };
}

const showContent = (
  content: Content,
  id?: string | null,
  bedId?: string | null,
  sessionEnd?: string | null,
  sessionStart?: string | null,
  showDetail?: (prop: string, from: Date, to: Date) => void,
) => {
  if (!!id) {
    switch (content.type) {
      case 'notes':
        return (
          <CareNotes
            sessionStart={sessionStart}
            disable={!!sessionEnd}
            patientSessionId={id}
          />
        );
      case 'mobilization':
        return (
          <Mobilization
            sessionEnd={sessionEnd}
            sessionStart={sessionStart}
            patientSessionId={id}
          />
        );
      case 'statistics':
        return (
          <Statistics
            sessionEnd={sessionEnd}
            sessionStart={sessionStart}
            patientSessionId={id}
          />
        );
      case 'insight':
        return (
          bedId && (
            <Insight
              sessionStart={sessionStart}
              sessionEnd={sessionEnd}
              bedId={bedId}
              prop={content.data?.prop}
              from={content.data?.from}
              to={content.data?.to}
            />
          )
        );
      case 'patientView':
        return (
          <View
            patientSessionId={id}
            sessionEnd={sessionEnd}
            sessionStart={sessionStart}
            showDetail={showDetail}
          />
        );
    }
  }
  return null;
};

export const closeModalProps = { open: false, id: null, bedId: null };

const BedDetailModal = ({
  openModal,
  setOpen,
  dashboardSafetyPositionPresetId,
}: Props) => {
  const [content, setContent] = useState<Content>({ type: 'patientView' });
  const { data } = useQuery<BedHeaderModalData, BedHeaderModalDataVariables>(
    bedHeaderModalData,
    {
      variables: {
        unitId: openModal.bedId || '',
      },
      skip: !openModal.bedId,
    },
  );

  const showDetail = (prop: string, from: Date, to: Date) => {
    setContent({
      type: 'insight',
      data: {
        prop,
        from,
        to,
      },
    });
  };

  const changeTab = (_: any, type: string) => {
    setContent({ type: type as ContentType });
  };

  return (
    <Dialog
      open={openModal.open}
      onClose={() => setOpen(closeModalProps)}
      aria-labelledby="session-dialog"
      aria-describedby="session-data-dialog"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          width: '1168px',
          height: '740px',
          // '& .MuiOutlinedInput-root': {
          //   color: 'text.secondary'
          // },
          // '& .MuiInputLabel-root': {
          //   color: 'text.secondary'
          // },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: 4,
          pt: 3,
          pb: 1.5,
        }}
      >
        {data?.bed ? (
          <>
            <Header
              setOpenModal={setOpen}
              onClose={() => setOpen(closeModalProps)}
              modalData={openModal.data}
              bed={data?.bed}
              dashboardSafetyPositionPresetId={dashboardSafetyPositionPresetId}
            />
            <Divider sx={{ pt: 3 }} />
          </>
        ) : null}
      </Box>
      <Box sx={{ mx: 4, mb: 2, position: 'relative' }}>
        <Tabs
          value={content.type}
          onChange={changeTab}
          aria-label="settings-tabs"
          sx={{
            '& button:first-of-type': {
              marginLeft: '0',
            },
          }}
        >
          <ThinTab value="patientView" label={t`24 hours view`} />
          <ThinTab value="notes" label={t`Care notes`} />
          <ThinTab value="mobilization" label={t`Mobilization`} />
          <ThinTab value="statistics" label={t`Statistics`} />
          <ThinTab value="insight" label={t`Insight`} />
        </Tabs>

        <Box sx={{ position: 'absolute', top: 6, right: 0 }}>
          <ExportButton
            unitId={openModal.bedId}
            content={content.type}
            sessionName={
              openModal.data
                ? openModal.data.name
                : data?.bed?.currentSession?.name ?? null
            }
            sessionId={openModal.data?.id ?? data?.bed?.currentSession?.id}
            sessionStart={
              openModal.data?.sessionStart ??
              data?.bed?.currentSession?.sessionStart
                ? new Date(data?.bed?.currentSession?.sessionStart)
                : undefined
            }
            sessionEnd={
              openModal.data?.sessionEnd
                ? new Date(openModal.data.sessionEnd)
                : undefined
            }
          />
        </Box>
      </Box>
      <Flex sx={{ flex: 1, px: 4, pb: 2 }}>
        {showContent(
          content,
          openModal.data?.id ?? data?.bed?.currentSession?.id,
          openModal.bedId,
          openModal.data?.sessionEnd,
          openModal.data?.sessionStart ??
            data?.bed?.currentSession?.sessionStart,
          showDetail,
        )}
      </Flex>
    </Dialog>
  );
};

export default BedDetailModal;
