import { t } from '@lingui/macro';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEventHandler, useMemo } from 'react';
import Flex from 'src/components/Flex';
import { NumberComparator, ReportResultField } from 'src/graphql/types';
import {
  ReportAggregation,
  ReportFieldFilter,
  ReportFilterDateOption,
  ReportFilterParams,
} from '../types';
import { FieldResultFilter } from './FieldResultFilter';
import ReportFilterDateSelect from './ReportFilterDateSelect';
import ReportFilterField from './ReportFilterField';
import ReportLocationFilter from './ReportLocationFilter';
import useAggregationOptions from './useAggregationOptions';
import {
  UpdateFieldFilterFunction,
  UpdateFilterFunction,
} from './useReportFilterParams';

interface Props {
  filter: ReportFilterParams;
  updateFilter: UpdateFilterFunction;
  updateFieldFilter: UpdateFieldFilterFunction;
  defaultExpanded: boolean;
  type?: 'goal' | 'report';
  onKeyParameterChange?: () => void;
}

const ReportFilterFinal = ({
  filter,
  updateFilter,
  updateFieldFilter,
  defaultExpanded,
  onKeyParameterChange,
  type,
}: Props) => {
  const showFieldFilters = useMemo(
    () =>
      filter.resultField === ReportResultField.DURATION ||
      filter.resultField === ReportResultField.WEIGHT,
    [filter.resultField],
  );
  const { aggregationOptions } = useAggregationOptions(filter);
  const handleFieldFilterChanged = (
    idx: number,
    fieldFilter: ReportFieldFilter,
  ) => {
    updateFieldFilter((fieldFilters) => {
      const fields = [...fieldFilters];
      fields[idx] = fieldFilter;
      return fields;
    });
  };

  const handleAddFieldFilter = () => {
    updateFieldFilter((fieldFilters) => {
      const fields = [...fieldFilters];
      fields.push({
        field: 'weight',
        numberComparator: NumberComparator.EQUALS,
        value: 60,
      });
      return fields;
    });
  };

  const onAggregationChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = Number(e.target.value) as ReportAggregation;
    updateFilter({ aggregation: newValue });
  };

  const handleRemoveFilter = (index: number) => {
    updateFieldFilter((fieldFilters) => {
      return fieldFilters.filter((f, idx) => idx !== index);
    });
  };

  const onDateFilterChange = (val: ReportFilterDateOption) => {
    updateFilter({ period: val });
  };

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <TuneIcon sx={{ mr: 1 }} />
        <Typography>
          {type === 'goal' ? t`Goal filters` : t`Report filters`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          boxShadow: 'inset 0px 8px 9px -9px rgba(0,0,0,0.25)',
          paddingTop: 2,
          paddingBottom: showFieldFilters ? 8 : 2,
          position: 'relative',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldResultFilter
              filter={filter}
              updateFilter={updateFilter}
              onKeyParameterChange={onKeyParameterChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              label={t`Aggregation`}
              value={filter.aggregation}
              sx={{ minWidth: '25ch', mr: 2 }}
              onChange={onAggregationChange}
              variant="outlined"
            >
              {aggregationOptions.map((aggr) => (
                <MenuItem key={aggr.id} value={aggr.id}>
                  {aggr.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <ReportFilterDateSelect
              filter={filter}
              value={filter.period}
              onChange={onDateFilterChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ReportLocationFilter
              resultField={filter.resultField}
              value={filter.locationFilter}
              onChange={(val) => updateFilter({ locationFilter: val })}
            />
          </Grid>
          {showFieldFilters &&
            filter.fieldFilters.map((f, idx) => (
              <Grid item xs={12} key={`field-filter-${idx}`}>
                <Flex alignItems="end">
                  <ReportFilterField
                    filter={f}
                    exclude={filter.mainFilter.field}
                    onChange={(field) => handleFieldFilterChanged(idx, field)}
                  />
                  <IconButton
                    onClick={() => handleRemoveFilter(idx)}
                    color="primary"
                    sx={{ ml: 2, mb: 1 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Flex>
              </Grid>
            ))}
        </Grid>
        {showFieldFilters && (
          <Button
            color="primary"
            size="large"
            startIcon={<AddCircleRoundedIcon />}
            onClick={handleAddFieldFilter}
            sx={{ position: 'absolute', left: '1rem', bottom: '0.75rem' }}
          >{t`Add condition`}</Button>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ReportFilterFinal;
