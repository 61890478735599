import { t } from '@lingui/macro';
import { DateRangePicker } from '@mui/lab';
import { Divider, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Flex from 'src/components/Flex';
import {
  ReportDatePreset,
  ReportFilterDateOption,
  ReportFilterParams,
} from '../types';
import { getDateSelectOptions } from './useDateSelectOptions';

interface Props {
  filter: ReportFilterParams;
  value: ReportFilterDateOption;
  onChange: (value: ReportFilterDateOption) => void;
}

const ReportFilterDateSelect = ({ filter, value, onChange }: Props) => {
  const dateSelectOptions = getDateSelectOptions(filter.parameter);

  const handlePresetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, preset: e.target.value as ReportDatePreset });
  };

  const handleDatesChanged = ([from, to]: [Date | null, Date | null]) => {
    onChange({ ...value, from, to });
  };

  return (
    <Flex>
      <TextField
        select
        variant="outlined"
        value={value.preset}
        onChange={handlePresetChange}
        sx={{ minWidth: '25ch', mr: 2 }}
        label={t`Period`}
      >
        {dateSelectOptions.map((dateOption) =>
          dateOption.type !== 'divider' ? (
            <MenuItem key={dateOption.id} value={dateOption.id}>
              {dateOption.name}
            </MenuItem>
          ) : (
            <Divider key={dateOption.id} />
          ),
        )}
      </TextField>
      {value.preset === 'custom' && (
        <DateRangePicker
          startText={t`From`}
          endText={t`To`}
          value={[value.from, value.to]}
          onChange={handleDatesChanged}
          renderInput={(startProps: any, endProps: any) => (
            <React.Fragment>
              <TextField variant="outlined" {...startProps} />
              <Box sx={{ mx: 1 }}>-</Box>
              <TextField variant="outlined" {...endProps} />
            </React.Fragment>
          )}
        />
      )}
    </Flex>
  );
};

export default ReportFilterDateSelect;
