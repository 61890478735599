import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from '@mui/material';
import SwitchBtn from '../../components/SwitchBtn';
import DataTransferConfig from './DataTransferConfig';
import SwVersion from './SwVersion';
import { Trans } from '@lingui/macro';
import okGrey from 'src/images/ok_grey.svg';
import { ReactComponent as OkRed } from 'src/images/ok_red.svg';
import { SubHeaderText } from './components/SubHeaderText';
import { ThinTextField } from './components/ThinTextField';
import { FormikProps } from 'formik';
import { SafetyPortFormValues } from './superAdminHelper';
import { HeaderText } from './components/HeaderText';
import Flex from 'src/components/Flex';

type Props = Pick<
  FormikProps<SafetyPortFormValues>,
  'values' | 'setFieldValue' | 'handleChange' | 'handleBlur'
>;

const SafetyPortConfig = ({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
}: Props) => {
  const onFieldChange = (name: string) => {
    setFieldValue(name, !values[name]);
  };

  return (
    <>
      <Divider sx={{ margin: '16px 0' }} />
      <Flex justifyContent="space-between" mt={4}>
        <Box>
          <Flex mb={3} justifyContent="space-between">
            <HeaderText>SafetyPort</HeaderText>
            <div onClick={(e) => e.stopPropagation()}>
              <SwitchBtn
                id="safety-port-enabled"
                state={values.spEnabled || false}
                onChange={() => onFieldChange('spEnabled')}
              />
            </div>
          </Flex>
          <Flex flexDirection="column" ml={3}>
            <FormControlLabel
              sx={{ flexDirection: 'row-reverse' }}
              control={
                <Checkbox
                  icon={<img alt="Unchecked" src={okGrey} />}
                  checkedIcon={<OkRed style={{ color: '#4cd963' }} />}
                  checked={values.syncCommunication}
                  value="enable async"
                  onChange={() => onFieldChange('syncCommunication')}
                />
              }
              label={<Trans>Synchronous communication</Trans>}
            />
            <FormControlLabel
              sx={{ flexDirection: 'row-reverse' }}
              control={
                <Checkbox
                  icon={<img alt="Unchecked" src={okGrey} />}
                  checkedIcon={<OkRed style={{ color: '#4cd963' }} />}
                  checked={values.hl7LogEnabled}
                  value="enable hl7LogEnabled"
                  onChange={() => onFieldChange('hl7LogEnabled')}
                />
              }
              label={<Trans>Hl7 Log enabled</Trans>}
            />
          </Flex>
        </Box>
        <DataTransferConfig
          setFieldValue={setFieldValue}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Box>
          <Box display="flex" ml={2} justifyContent="space-between">
            <SubHeaderText>Dashboard</SubHeaderText>
            <div onClick={(e) => e.stopPropagation()}>
              <SwitchBtn
                id="safety-port-dashboard-enabled"
                state={values.spDashboardEnabled || false}
                onChange={() => onFieldChange('spDashboardEnabled')}
              />
            </div>
          </Box>
          <Box>
            <Box display="flex" ml={2} position="relative">
              <ThinTextField
                type="text"
                name="spDashboardVersion"
                value={values.spDashboardVersion || ''}
                onChange={handleChange}
                variant="outlined"
              />
              <SwVersion />
            </Box>
            <Box mt={3} ml={2}>
              <Typography style={{ marginLeft: 12 }}>
                <Trans>Licence expiration date</Trans>
              </Typography>
              <ThinTextField
                type="date"
                name="spDashboardLicenceExpiration"
                value={values.spDashboardLicenceExpiration || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Box>
          </Box>
        </Box>
        <Flex justifyContent="space-between">
          <HeaderText mr={3}>Reporting module access</HeaderText>
          <div onClick={(e) => e.stopPropagation()}>
            <SwitchBtn
              id="reporting-enabled"
              state={values.reportingModuleAccess || false}
              onChange={() => onFieldChange('reportingModuleAccess')}
            />
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default SafetyPortConfig;
