import { t } from '@lingui/macro';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  ModalProps,
} from '@mui/material';
import React from 'react';
import { ModalHeader } from '../BaseModal/ModalHeader';

type Props = Omit<DialogProps, 'children'> & {
  title?: string;
  message?: React.ReactNode;
  onClose: NonNullable<ModalProps['onClose']>;
  onConfirm: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
};

const ConfirmModal = ({
  title,
  message,
  onClose,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  cancelButtonProps = {},
  confirmButtonProps = {},
  ...dialogProps
}: Props) => {
  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogTitle>
        <ModalHeader
          title={title}
          onClose={() => onClose({}, 'escapeKeyDown')}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ wordBreak: 'break-word' }}>
          {message || t`Are you sure?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          {...cancelButtonProps}
          onClick={() => onClose({}, 'escapeKeyDown')}
        >
          {cancelButtonText ? cancelButtonText : t`Cancel`}
        </Button>
        <Button
          color="primary"
          {...confirmButtonProps}
          variant="contained"
          onClick={onConfirm}
        >
          {confirmButtonText ? confirmButtonText : t`Confirm`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
