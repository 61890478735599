import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  Operation,
  FetchResult,
  Observable,
  split,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { print, GraphQLError } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';
import { clearAccessToken, getAccessToken } from './auth/AuthProvider';
import { isUnauthenticatedError } from './lib/errorsHelper';

class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`,
                ),
              );
            }
            if ((err as any).length) {
              return sink.error(
                new Error(
                  (err as GraphQLError[])
                    .map(({ message }) => message)
                    .join(', '),
                ),
              );
            }
            return sink.error(
              // reason will be available on clean closes
              new Error(`Unknown error ${err}`),
            );
          },
        },
      );
    });
  }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      console.error('[GraphQL error]:', error);
      if (isUnauthenticatedError(error)) {
        clearAccessToken();
      }
    });
  }
  if (networkError) {
    console.error('[Network error]:', networkError);
  }
});

const GRAPHQL_URI = '/api/graphql';

const fetchWithAuth = async (uri: string, options: any) => {
  const accessToken = getAccessToken();
  if (accessToken != null) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }
  return fetch(uri, options);
};

const httpLink = new HttpLink({
  uri: GRAPHQL_URI,
  fetch: fetchWithAuth,
});

const uploadLink = createUploadLink({
  uri: GRAPHQL_URI,
  headers: {
    'X-Requested-With': 'fetch',
  },
  fetch: fetchWithAuth,
});

const getCorrectProtocol = () =>
  window.location.protocol === 'http:' ? 'ws' : 'wss';

const getCorretHost = () =>
  window.location.port === '3002'
    ? `${window.location.hostname}:3600`
    : window.location.host;

const getWsUri = (path: string) =>
  `${getCorrectProtocol()}://${getCorretHost()}${path}`;

const wsLink = new WebSocketLink({
  url: getWsUri('/api/graphql'),
  connectionParams: () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      return {};
    }
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  },
});

const loggingLink = new ApolloLink((operation, forward) => {
  if (forward == null) {
    return null;
  }
  if (forward(operation).map != null) {
    return forward(operation).map((data) => {
      return data;
    });
  } else {
    return Observable.from(forward(operation)).map((data) => {
      return data;
    });
  }
});

const requestLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const isFile = (value: any) =>
  (typeof File !== 'undefined' && value instanceof File) ||
  (typeof Blob !== 'undefined' && value instanceof Blob);

const isUpload = ({ variables }: any) => {
  return Object.values(variables).some(isFile);
};

const terminalLink = split(isUpload, uploadLink, requestLink);

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Bed: {
        keyFields: ['unitId'],
      },
      UserSetting: {
        keyFields: ['userId'],
      },
      SuperConfig: {
        merge: true,
      },
      Config: {
        merge: true,
      },
    },
  }),
  link: ApolloLink.from([errorLink, loggingLink, terminalLink]),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      errorPolicy: 'all',
    },
  },
});

export default client;
