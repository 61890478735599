import { t } from '@lingui/macro';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { FormikErrors } from 'formik';
import FormikTextField from 'src/components/Formik/FormikTextField';
import { Mobilift, PowerStatus } from 'src/graphql/types';
import { Label } from './ToggleButtonLabel';
import { VirtualBedForm } from './VirtualBedForm';

interface Props {
  values: VirtualBedForm;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<VirtualBedForm>>;
  setFieldTouched: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<VirtualBedForm>>;
}

export const AdditionalDatapoints = ({
  values,
  setFieldValue,
  setFieldTouched,
}: Props) => {
  const altPhaseOptions = getAltPhase();
  const errorCodeOptions = getErrorCodes();
  const mattressOptions = getMattressType();

  return (
    <Grid
      container
      columnSpacing={4}
      rowSpacing={4}
      columns={17}
      sx={{ maxWidth: '1250px', minWidth: '950px' }}
    >
      <Grid item xs={5}>
        <FormControl sx={{ display: 'flex' }}>
          <Label>{t`Bed power status`}</Label>
          <ToggleButtonGroup
            color="primary"
            value={values.powerStatus}
            exclusive
            onChange={(event, newStatus) => {
              if (newStatus) {
                setFieldValue('powerStatus', newStatus);
              }
            }}
          >
            <ToggleButton
              sx={{ flex: 1, pr: 1, width: '33%' }}
              value={PowerStatus.ON}
            >
              <ToggleButtonText>{t`Plugged`}</ToggleButtonText>
            </ToggleButton>
            <ToggleButton
              sx={{ flex: 1, px: 1, width: '33%' }}
              value={PowerStatus.OFF}
            >
              <ToggleButtonText>{t`Unplugged`}</ToggleButtonText>
            </ToggleButton>
            <ToggleButton
              sx={{ flex: 1, pl: 1, width: '33%' }}
              value={PowerStatus.CHARGING}
            >
              <ToggleButtonText>{t`Charging`}</ToggleButtonText>
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="lateralTilt"
          fullWidth
          variant="outlined"
          label={t`Lateral tilt angle`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{t`°`}</InputAdornment>
            ),
          }}
          onBlur={() => setFieldTouched('lateralTilt', true)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="rightLegColumn"
          fullWidth
          variant="outlined"
          label={t`Height of right leg column`}
          onBlur={() => setFieldTouched('rightLegColumn', true)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="mattressType"
          select
          variant="outlined"
          label={t`Mattress type`}
          fullWidth
        >
          {mattressOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
            >{`${option.id} (${option.name})`}</MenuItem>
          ))}
        </FormikTextField>
      </Grid>
      <Grid item xs={5}>
        <FormControl sx={{ display: 'flex' }}>
          <Label>{t`Mobilift right`}</Label>
          <ToggleButtonGroup
            color="primary"
            value={values.mobiliftRight}
            exclusive
            onChange={(event, newStatus) => {
              if (newStatus) {
                setFieldValue('mobiliftRight', newStatus);
              }
            }}
          >
            <ToggleButton
              sx={{ flex: 1 }}
              value={Mobilift.UP}
            >{t`Up`}</ToggleButton>
            <ToggleButton
              sx={{ flex: 1 }}
              value={Mobilift.DOWN}
            >{t`Down`}</ToggleButton>
            <ToggleButton
              sx={{ flex: 1 }}
              value={Mobilift.NA}
            >{t`N/A`}</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="trendelenburg"
          fullWidth
          variant="outlined"
          label={t`Trendelenburg angle`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{t`°`}</InputAdornment>
            ),
          }}
          onBlur={() => setFieldTouched('trendelenburg', true)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="leftLegColumn"
          fullWidth
          variant="outlined"
          label={t`Height of left leg column`}
          onBlur={() => setFieldTouched('leftLegColumn', true)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="altPhase"
          select
          variant="outlined"
          label={t`ALT Phase`}
          fullWidth
        >
          {altPhaseOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
            >{`${option.id} (${option.name})`}</MenuItem>
          ))}
        </FormikTextField>
      </Grid>
      <Grid item xs={5}>
        <FormControl sx={{ display: 'flex' }}>
          <Label>{t`Mobilift left`}</Label>
          <ToggleButtonGroup
            color="primary"
            value={values.mobiliftLeft}
            exclusive
            onChange={(event, newStatus) => {
              if (newStatus) {
                setFieldValue('mobiliftLeft', newStatus);
              }
            }}
          >
            <ToggleButton
              sx={{ flex: 1 }}
              value={Mobilift.UP}
            >{t`Up`}</ToggleButton>
            <ToggleButton
              sx={{ flex: 1 }}
              value={Mobilift.DOWN}
            >{t`Down`}</ToggleButton>
            <ToggleButton
              sx={{ flex: 1 }}
              value={Mobilift.NA}
            >{t`N/A`}</ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="calfrestAngle"
          fullWidth
          variant="outlined"
          label={t`Calfrest angle`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{t`°`}</InputAdornment>
            ),
          }}
          onBlur={() => setFieldTouched('calfrestAngle', true)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="headColumn"
          fullWidth
          variant="outlined"
          label={t`Height of head column`}
          onBlur={() => setFieldTouched('headColumn', true)}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name="errorCode"
          select
          variant="outlined"
          fullWidth
          label={t`Error or stop code`}
        >
          {errorCodeOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
            >{`${option.id} (${option.name})`}</MenuItem>
          ))}
        </FormikTextField>
      </Grid>
    </Grid>
  );
};

const ToggleButtonText = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

ToggleButtonText.defaultProps = {
  component: 'span',
};

export const getErrorCodes = () => [
  {
    id: 0,
    name: t`No error.`,
  },
  {
    id: 1,
    name: t`Unexpected lateral tilt detected by main control box motherboard accelerometer.`,
  },
  {
    id: 2,
    name: t`Foot control pressed after STOP.`,
  },
  {
    id: 3,
    name: t`Display button pressed after stop.`,
  },
  {
    id: 4,
    name: t`Two buttons pushed simultaneously longer than 1 second.`,
  },
  {
    id: 5,
    name: t`Keyboard not released after system start.`,
  },
  {
    id: 6,
    name: t`Main control box temperature exceeded.`,
  },
  {
    id: 7,
    name: t`Column current overload 0.`,
  },
  {
    id: 8,
    name: t`Column current overload 1.`,
  },
  {
    id: 9,
    name: t`Column current overload 2.`,
  },
  {
    id: 10,
    name: t`Actuator current overload - backrest.`,
  },
  {
    id: 11,
    name: t`Actuator current overload - thighrest.`,
  },
  {
    id: 12,
    name: t`Stopped due to resistance on the same function.`,
  },
  {
    id: 13,
    name: t`Short circuiton on analog control (RC detection).`,
  },
  {
    id: 101,
    name: t`GO button not active.`,
  },
  {
    id: 102,
    name: t`Locked function.`,
  },
  {
    id: 103,
    name: t`X-ray cassette inserted incorrectly.`,
  },
  {
    id: 104,
    name: t`Siderails folded down during lateral tilt.`,
  },
  {
    id: 105,
    name: t`Backrest angle greater than 30° during lateral tilt.`,
  },
  {
    id: 106,
    name: t`Lateral tilt stopped at 15° when using foot control.`,
  },
  {
    id: 111,
    name: t`Function blocked during one fault state.`,
  },
  {
    id: 112,
    name: t`Function blocked during active ALT.`,
  },
  {
    id: 113,
    name: t`Stopped due to risk of collision when bed is tilted.`,
  },
  {
    id: 114,
    name: t`Stopped in zero position.`,
  },
  {
    id: 115,
    name: t`Collision when exceeding threshold limit of calfrest and footrest.`,
  },
  {
    id: 116,
    name: t`Scale overload - tilt only to 15°.`,
  },
  {
    id: 121,
    name: t`Column potentiometer defect.`,
  },
  {
    id: 122,
    name: t`Stopped at 15° during lateral movement patient transfer.`,
  },
  {
    id: 123,
    name: t`Absolute scale overload.`,
  },
  {
    id: 124,
    name: t`Starting or running ALT on battery.`,
  },
  {
    id: 125,
    name: t`Use manual mattress CPR (not stopping, only pop up).`,
  },
  {
    id: 126,
    name: t`Safestop.`,
  },
];

export const getAltPhase = () => [
  {
    id: 0,
    name: t`Inactive`,
  },
  {
    id: 1,
    name: t`Going to the initial position`,
  },
  {
    id: 2,
    name: t`Ongoing Settings of angles and time in different positions`,
  },
  {
    id: 3,
    name: t`Going from the neutral position to first left position`,
  },
  {
    id: 4,
    name: t`Standing still the first left position (when going to the left)`,
  },
  {
    id: 5,
    name: t`Going from the first left position to second left position`,
  },
  {
    id: 6,
    name: t`Standing still in the second left position`,
  },
  {
    id: 7,
    name: t`Going from the second left position back to first left position`,
  },
  {
    id: 8,
    name: t`Standing still in the first left position (when going to the right)`,
  },
  {
    id: 9,
    name: t`Going from the first left position back to the neutral position`,
  },
  {
    id: 10,
    name: t`Standing still in the neutral position in the middle of the cycle`,
  },
  {
    id: 11,
    name: t`Going from the neutral position to the first right position`,
  },
  {
    id: 12,
    name: t`Standing still in the first right position (when going to the right)`,
  },
  {
    id: 13,
    name: t`Going from the first right position to the second right position`,
  },
  {
    id: 14,
    name: t`Standing still in the second right position`,
  },
  {
    id: 15,
    name: t`Going from the second right position back to the first right position`,
  },
  {
    id: 16,
    name: t`Standing still in the first right position (when going back to neutral)`,
  },
  {
    id: 17,
    name: t`Going from the first right right position back to the neutral position`,
  },
  {
    id: 18,
    name: t`Standing still in the neutral position in the end of the cycle`,
  },
];

export const getMattressType = () => [
  {
    id: 0,
    name: t`No integrated mattress`,
  },
  {
    id: 50,
    name: t`Symbioso (old version)`,
  },
  {
    id: 51,
    name: t`S200 (Symbioso))`,
  },
  {
    id: 52,
    name: t`S300 (OptiCare mattress)`,
  },
  {
    id: 53,
    name: t`S300-X (OptiCare-X or alternating mattress)`,
  },
];
