import {
  Dialog,
  Box,
  Typography,
  Button,
  DialogActions,
  styled,
} from '@mui/material';
import { t } from '@lingui/macro';
import { isMobile } from 'src/lib/mobileCheck';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  onNewSession: () => void;
  onGoToDashboard?: () => void;
}

const SessionEndedDialog = ({
  open,
  setOpen,
  onNewSession,
  onGoToDashboard,
}: Props) => {
  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="session-ended-dialog"
      open={open}
    >
      <Box p={2}>
        <Title>{t`Session ended`}</Title>
        <Text sx={isMobile ? undefined : { width: '300px' }}>
          {t`You can find all recorded data in the history module on the left panel.`}
        </Text>
        <DialogActions
          sx={{
            mt: 4,
            display: 'flex',
            padding: 0,
            justifyContent: 'space-between',
            alignItems: 'stretch',
          }}
        >
          <Button
            sx={{ marginRight: '8px', width: '220px' }}
            variant="outlined"
            onClick={onGoToDashboard}
          >
            {t`Back to dashboard`}
          </Button>
          <Button
            onClick={onNewSession}
            sx={{ marginRight: '8px', width: '220px' }}
            variant="contained"
            type="submit"
          >
            {t`New patient`}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SessionEndedDialog;

const Title = styled(Typography)`
  font-weight: 500;
  text-align: center;
  font-size: 24px;
`;

const Text = styled(Typography)`
  font-size: '0.875rem';
  text-align: center;
  margin: 32px auto;
`;
