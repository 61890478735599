import { useQuery } from '@apollo/client';
import { FilterValue } from 'src/components/Filters';
import { bedsQuery, ConnectionNamesQuery } from 'src/graphql/types';
import { getFilterPropertyNames, PropertyNameFilterType } from 'src/helpers';
import { notEmpty } from 'src/lib/notEmpty';
import { connectionNamesQuery, beds } from '../../../graphql/queries';

export const HL7_FILTER = 'hl7Filter';

export type FilterProps = {
  connection: FilterValue | null;
  prop: FilterValue | null;
  bedId: FilterValue | null;
};

export const useHl7FilterOption = () => {
  const { data: connectionsData } =
    useQuery<ConnectionNamesQuery>(connectionNamesQuery);
  const { data: bedData } = useQuery<bedsQuery>(beds);
  const propertyNameFilter: PropertyNameFilterType[] = getFilterPropertyNames();

  const bedIdOptions =
    bedData?.beds
      ?.map((bed) =>
        bed.unitId != null ? { id: bed.unitId, name: bed.unitId } : null,
      )
      .filter(notEmpty) || [];

  return {
    bedIdOptions,
    propOptions: propertyNameFilter.map((prop) => ({
      id: prop.value,
      name: prop.name,
    })),
    connectionOptions: connectionsData?.connections
      ? connectionsData.connections.filter(notEmpty)
      : [],
  };
};
