import { Box, Typography, useTheme } from '@mui/material';
import Switch from 'react-switch';

interface IProps {
  handleSpState?: (value: boolean, name: string) => void;
  sp?: boolean;
  state: boolean;
  setState?: React.Dispatch<React.SetStateAction<boolean>>;
  icon?: string;
  label?: string | React.ReactNode;
  small?: boolean;
  onChange?: (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => void;
  name?: string;
  preventDefault?: boolean;
  id: string;
  disabled?: boolean;
}

const SwitchBtn = ({
  sp,
  handleSpState,
  state,
  setState,
  preventDefault,
  name,
  small,
  onChange,
  label,
  id,
  disabled,
}: IProps) => {
  const theme = useTheme();
  const handleChange = (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string,
  ) => {
    if (sp && handleSpState !== undefined) {
      handleSpState(!state, name || '');
    } else if (setState !== undefined) {
      setState((prevState: boolean) => !prevState);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {label && (
        <Typography
          component="label"
          htmlFor={id}
          onClick={handleClick}
          sx={{
            marginBottom: 1.5,
            fontSize: '0.875rem',
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {label}
        </Typography>
      )}
      <Switch
        checked={state || false}
        onChange={onChange ?? handleChange}
        onColor={theme.palette.success.light}
        onHandleColor={theme.palette.common.white}
        offColor={theme.palette.grey[400]}
        handleDiameter={small ? 20 : 30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={small ? 20 : 30}
        width={small ? 33 : 50}
        className="react-switch"
        id={id}
        disabled={disabled}
      />
    </Box>
  );
};

export default SwitchBtn;
