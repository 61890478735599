import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MobileDashboardSettings from '../Settings/MobileDashboardSettings';
import { getMobileOverlayTitle } from './mobileOverlay';
import MobileInsight from '../Insight/MobileInsight';
import { useQuery } from '@apollo/client';
import { MobileBedOverlay, MobileBedOverlayVariables } from 'src/graphql/types';
import { mobileBedOverlay } from 'src/graphql/queries';
import MobileCareNotes from '../CareNotes/MobileCareNotes';
import MobileExport from '../Export/MobileExport';
import MobileAvailableDatapoints from '../Settings/MobileAvailableDatapoints';
import { MobileModalContext, Modal } from './MobileModalProvider';
import { useContext } from 'react';

interface Props {
  data: Modal;
}

interface RenderContentProps {
  data: Modal;
  sessionStart?: string | null;
  sessionEnd?: string | null;
  patientSessionId?: string;
  sessionName?: string | null;
}

const renderContent = ({
  data,
  sessionStart,
  sessionEnd,
  sessionName,
  patientSessionId,
}: RenderContentProps) => {
  switch (data.type) {
    case 'settings':
      return <MobileDashboardSettings />;
    case 'insight':
      return (
        <MobileInsight
          bedId={data.bedId}
          sessionEnd={sessionEnd}
          sessionStart={sessionStart}
        />
      );
    case 'notes':
      return (
        patientSessionId && (
          <MobileCareNotes
            sessionStart={sessionStart}
            patientSessionId={patientSessionId}
          />
        )
      );
    case 'datapoints':
      return <MobileAvailableDatapoints />;
    case 'export':
      return (
        patientSessionId &&
        data?.bedId && (
          <MobileExport
            sessionName={sessionName || null}
            sessionStart={sessionStart}
            sessionEnd={sessionEnd}
            unitId={data.bedId}
            patientSessionId={patientSessionId}
          />
        )
      );
    default:
      return null;
  }
};

const MobileDashbordOverlay = ({ data }: Props) => {
  const { hideModal } = useContext(MobileModalContext);

  const { data: overlayBedData } = useQuery<
    MobileBedOverlay,
    MobileBedOverlayVariables
  >(mobileBedOverlay, {
    variables: {
      unitId: data?.bedId || '',
    },
    skip:
      !data?.bedId ||
      data.type === 'settings' ||
      data.type === 'datapoints' ||
      !data.type,
  });
  if (!data.type) return null;

  return (
    <Box width={1}>
      <Box
        display="flex"
        alignItems="center"
        bgcolor="common.mobileTopBar"
        px={3}
        py={2}
        mb={3}
      >
        <IconButton
          sx={{ marginRight: '0.5rem', color: 'text.primary' }}
          size="small"
          onClick={hideModal}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography noWrap>
          {getMobileOverlayTitle(data.type)}
          {overlayBedData?.bed?.parkingPlaces[0].name &&
            ` ${overlayBedData?.bed?.parkingPlaces[0].name} - ${overlayBedData?.bed?.currentSession?.name}`}
        </Typography>
      </Box>
      <Box px={3}>
        {renderContent({
          data,
          sessionEnd: overlayBedData?.bed?.currentSession?.sessionEnd,
          sessionStart: overlayBedData?.bed?.currentSession?.sessionStart,
          patientSessionId: overlayBedData?.bed?.currentSession?.id,
          sessionName: overlayBedData?.bed?.currentSession?.name,
        })}
      </Box>
    </Box>
  );
};

export default MobileDashbordOverlay;
