import { Box } from '@mui/material';
import { Position } from 'src/graphql/types';

interface Props {
  position: Position;
}

export const SiderailIndicator = ({ position }: Props) => {
  return (
    <Box
      sx={{
        height: '14px',
        width: '150px',
        borderRadius: '14px',
        backgroundColor: (theme) =>
          position === Position.UP
            ? theme.palette.success.light
            : theme.palette.grey[600],
      }}
    />
  );
};
