import { t } from '@lingui/macro';
import { useState } from 'react';
import { Column, TableOddEven } from 'src/components/TableOddEven';
import { AdminPasswordForm } from './AdminPasswordForm';
import { UserPasswordForm } from './UserPasswordForm';

interface Tabs {
  id: PasswordSettingTab;
  label: string;
}

enum PasswordSettingTab {
  'PasswordSettings' = 'password_settings',
  'AdminPasswordSettings' = 'admin_password_settings',
}

const columns: Column<Tabs>[] = [
  {
    header: t``,
    key: 'id',
    cellProps: {
      sx: {
        fontWeight: 'bold',
      },
    },
    renderValue: (row) => row.label,
  },
];

export const PasswordSettings = () => {
  const [selectedSetting, setSelectedSetting] = useState<string | null>(null);
  const rowProps = (item: Tabs) => ({
    onClick: () =>
      setSelectedSetting(selectedSetting === item.id ? null : item.id),
    selected: item.id === selectedSetting,
    hover: true,
  });

  const renderExpanded = ({ id }: Tabs) => {
    if (id === PasswordSettingTab.PasswordSettings) {
      return <UserPasswordForm />;
    }
    if (id === PasswordSettingTab.AdminPasswordSettings) {
      return <AdminPasswordForm />;
    }
    return null;
  };

  const data = [
    {
      id: PasswordSettingTab.PasswordSettings,
      label: t`PasswordSettings`,
    },
    {
      id: PasswordSettingTab.AdminPasswordSettings,
      label: t`AdminPasswordSettings`,
    },
  ];
  return (
    <TableOddEven
      rowKey="id"
      renderExpanded={renderExpanded}
      data={data}
      columns={columns}
      rowProps={rowProps}
    />
  );
};
