interface Props {
  content: any;
}

export const MobileApp = ({ content }: Props) => {
  return (
    <div>
      <img
        alt="Smartphone"
        style={{ width: 160 }}
        src={require('src/images/mobile.png').default}
      />
      <div style={styles.content}>{content}</div>
    </div>
  );
};

const styles = {
  content: {
    position: 'absolute' as 'absolute',
    top: 30,
    left: 11,
    width: 121,
    height: 226,
    padding: 8,
    backgroundColor: '#fff',
    borderRadius: 4,
  },
};
