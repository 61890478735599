import { useState } from 'react';
import AdminLoginDialog from './AdminLoginDialog';
import { SuperAdminTopBar } from './SuperAdminTopBar';
import { Box, styled } from '@mui/material';

const SuperAdminBar = () => {
  const [superAdminOpen, setSuperAdminOpen] = useState(false);
  let buttonPressTimer: any = null;

  const handleButtonPress = () => {
    buttonPressTimer = setTimeout(() => {
      setSuperAdminOpen(true);
    }, 1000);
  };

  const handleButtonRelease = () => {
    clearTimeout(buttonPressTimer);
  };

  const hide = () => {
    setSuperAdminOpen(false);
  };

  return (
    <Box>
      <SuperAdminTopBar
        handleButtonPress={handleButtonPress}
        handleButtonRelease={handleButtonRelease}
      />
      {superAdminOpen && (
        <Container>
          <Content>
            <AdminLoginDialog hide={hide} />
          </Content>
        </Container>
      )}
    </Box>
  );
};

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  min-height: 100;
  z-index: 200;
`;

const Content = styled(Box)(
  ({ theme: { spacing } }) => `
  width: 400px;
  min-height: 270px;
  padding-top: ${spacing(3.75)};
  padding-bottom: ${spacing(3.75)};
  display: flex;
  flex-direction: column;
  align-items: center;
`,
);

export default SuperAdminBar;
