import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';

interface Props {
  charging: boolean;
  fontSize?: string;
}

export const Charging = ({ charging, fontSize }: Props) => {
  return charging ? (
    <BatteryChargingFullIcon
      sx={(theme) => ({ fontSize, color: theme.palette.success.light })}
    />
  ) : (
    <Battery0BarIcon sx={{ fontSize }} />
  );
};
