import { useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { concat } from 'ramda';
import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import {
  ListPageContent,
  ListPageRoot,
  TableWrapper,
  TopBar,
} from 'src/components/AdminList';
import { ActiveFilters, FilterValue, useFilter } from 'src/components/Filters';
import { TableHeader } from 'src/components/Table';
import { hl7Log } from 'src/graphql/queries';
import {
  Hl7LogQuery,
  Hl7LogQueryVariables,
  SafetyPortProps,
} from 'src/graphql/types';
import { propTranslationTabel } from 'src/helpers';
import { PropsIcons } from 'src/components/PropsIcon';
import { getSortState, SortKey, useSorting } from 'src/lib/sortingHook';
import { FilterProps, HL7_FILTER, useHl7FilterOption } from './filterOptions';

const getAck = (ack: number) => {
  if (ack === 1) {
    return t`CONFIRMED`;
  }
  if (ack === 2) {
    return t`ERROR`;
  }
  if (ack === 3) {
    return t`REJECTED`;
  }
  return t`UNCONFIRMED`;
};

const WAYPOINT_OFFSET = 5;
const ROWS_INCREMENT = 30;

const Hl7Log = () => {
  const { sorting, setSort } = useSorting();
  const [searchParams] = useSearchParams();
  const paramLoaded = useRef(false);
  const { i18n } = useLingui();
  const { filter, activeFilters, setFilterValue, clear, clearAll } =
    useFilter<FilterProps>({
      name: HL7_FILTER,
      types: {
        connection: t`CONNECTION`,
        bedId: t`INTEGRATION MODULE ID`,
        prop: t`SAFETY PORT PROP`,
      },
    });
  const { connectionOptions, bedIdOptions, propOptions } = useHl7FilterOption();
  const searchUnitId = searchParams.get('unitId');
  const connectionById = useMemo(
    () =>
      connectionOptions.reduce((res, item) => {
        return {
          ...res,
          [item.id]: item.name,
        };
      }, {}),
    [connectionOptions],
  );
  useEffect(() => {
    if (paramLoaded.current) {
      return;
    }
    paramLoaded.current = true;
    if (searchUnitId) {
      clearAll();
      setFilterValue('bedId', { id: searchUnitId, name: searchUnitId });
    }
  }, [searchUnitId, setFilterValue, clearAll]);

  const {
    data: entries,
    refetch,
    fetchMore,
  } = useQuery<Hl7LogQuery, Hl7LogQueryVariables>(hl7Log, {
    variables: {
      bedUnitId: filter.bedId?.id,
      connectionId: filter.connection?.id,
      first: ROWS_INCREMENT,
      after: null,
      filter: filter.prop?.id ? SafetyPortProps[filter.prop.id] : null,
    },
  });

  const handleRefetch = () => {
    refetch({
      bedUnitId: filter.bedId?.id,
      connectionId: filter.connection?.id,
      first: ROWS_INCREMENT,
      after: null,
      filter: filter.prop?.id ? SafetyPortProps[filter.prop.id] : null,
    });
  };

  const loadMore = async () => {
    const totalCount = entries?.dataHistory?.totalCount || 0;
    const loadedCount = entries?.dataHistory?.edges?.length || 0;
    if (totalCount <= loadedCount) {
      return;
    }

    await fetchMore({
      variables: {
        after: entries?.dataHistory?.pageInfo?.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult
          ? fetchMoreResult.dataHistory.edges
          : null;
        const pageInfo = fetchMoreResult
          ? fetchMoreResult.dataHistory.pageInfo
          : null;
        const totalCount = fetchMoreResult
          ? fetchMoreResult.dataHistory.totalCount +
            previousResult.dataHistory.edges.length
          : null;

        return newEdges !== null && newEdges.length
          ? {
              // Put the new comments at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              dataHistory: {
                __typename: previousResult.dataHistory.__typename,
                edges: concat(previousResult.dataHistory.edges, newEdges),
                pageInfo:
                  pageInfo !== null
                    ? pageInfo
                    : previousResult.dataHistory.pageInfo,
                totalCount:
                  totalCount !== null
                    ? totalCount
                    : previousResult.dataHistory.totalCount,
              },
            }
          : previousResult;
      },
    });
  };

  const getSortedEntries = (entries: Hl7LogQuery) => {
    if (sorting) {
      return [...entries.dataHistory.edges].sort(sorting.sortFn);
    }
    return entries.dataHistory.edges;
  };

  const getSortingState = (key: SortKey) => getSortState(sorting, key);
  return (
    <>
      <ActiveFilters
        filters={activeFilters}
        clear={clear}
        clearAll={clearAll}
      />
      <ListPageRoot>
        <TopBar>
          <RefreshIcon
            onClick={handleRefetch}
            style={{ cursor: 'pointer', marginRight: 10 }}
          />
        </TopBar>
        <ListPageContent>
          <TableWrapper>
            <Table stickyHeader sx={{ minWidth: 500 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableHeader
                      id="bedId"
                      sort={() => setSort(SortKey.BEDUNITID)}
                      sortState={() => getSortingState(SortKey.BEDUNITID)}
                      label={t`INTEGRATION MODULE ID`}
                      options={bedIdOptions}
                      value={filter.bedId}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('bedId', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="msgid"
                      sort={() => setSort(SortKey.MSGID)}
                      sortState={() => getSortingState(SortKey.MSGID)}
                      label={t`MESSAGE ID`}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="prop"
                      sort={() => setSort(SortKey.HL7PROP)}
                      sortState={() => getSortingState(SortKey.HL7PROP)}
                      label={t`SAFETY PORT PROP`}
                      options={propOptions}
                      value={filter.prop}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('prop', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Trans>VALUE</Trans>{' '}
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="timestamp"
                      sort={() => setSort(SortKey.HL7TIMESTAMP)}
                      sortState={() => getSortingState(SortKey.HL7TIMESTAMP)}
                      label={t`TIMESTAMP`}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="ack"
                      sort={() => setSort(SortKey.ACK)}
                      sortState={() => getSortingState(SortKey.ACK)}
                      label={t`ACK`}
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="connection"
                      sort={() => setSort(SortKey.ACK)}
                      sortState={() => getSortingState(SortKey.ACK)}
                      label={t`CONNECTION`}
                      options={connectionOptions}
                      value={filter.connection}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('connection', value)
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entries != null && entries.dataHistory
                  ? getSortedEntries(entries).map((edge, index, edges) => {
                      const value = edge.node.value || '';
                      const totalCount = entries?.dataHistory?.totalCount;
                      return (
                        <TableRow key={index}>
                          <TableCell>{edge.node.bedUnitId}</TableCell>
                          <TableCell>{edge.node.msgId}</TableCell>
                          <TableCell title={edge.node.prop || undefined}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {edge.node.prop !== null && (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  style={{ marginRight: 8 }}
                                >
                                  <PropsIcons
                                    prop={edge.node.prop}
                                    size="1.6rem"
                                  />
                                </Box>
                              )}
                              {propTranslationTabel(edge.node.prop)}
                              {edges.length - WAYPOINT_OFFSET === index &&
                              edges.length < totalCount ? (
                                <Waypoint onEnter={loadMore} />
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell
                            title={value.length > 10 ? value : undefined}
                          >
                            {value.length > 10
                              ? `...${value.substring(
                                  value.length - 8,
                                  value.length,
                                )}`
                              : value}
                          </TableCell>
                          <TableCell>
                            {edge.node.timestamp
                              ? i18n.date(edge.node.timestamp, {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  second: 'numeric',
                                })
                              : null}
                          </TableCell>
                          <TableCell>
                            {edge.node.ack
                              ? getAck(edge.node.ack)
                              : t`UNCONFIRMED`}
                          </TableCell>
                          <TableCell>
                            {edge.node.connectionId
                              ? connectionById[edge.node.connectionId]
                              : null}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableWrapper>
        </ListPageContent>
      </ListPageRoot>
    </>
  );
};

export default Hl7Log;
