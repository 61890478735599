import { t } from '@lingui/macro';
import { styled, Typography } from '@mui/material';
import { Link, useMatch } from 'react-router-dom';
import Flex from 'src/components/Flex';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useQuery } from '@apollo/client';
import {
  BedQuery,
  BedQueryVariables,
  roomQuery,
  roomQueryVariables,
  UserQuery,
  UserQueryVariables,
} from 'src/graphql/types';
import { bed, room, userQuery } from 'src/graphql/queries';

const TopNavigator = () => {
  const profileMatch = useMatch('/my-profile');

  const hospitalLayoutMatch = useMatch('/admin/hospital-layout');
  const roomsMatch = useMatch('/admin/rooms');
  const roomMatch = useMatch('/admin/rooms/:roomId');
  const bedsMatch = useMatch('/admin/beds');
  const bedMatch = useMatch('/admin/beds/:unitId');
  const vbMatch = useMatch('/admin/beds/:unitId/edit');
  const vbSimulationMatch = useMatch('/admin/beds/:unitId/simulate');
  const insightMatch = useMatch('/admin/insight');
  const hl7LogMatch = useMatch('/admin/hl7-log');
  const usersMatch = useMatch('/admin/users');
  const userMatch = useMatch('/admin/users/:userId');
  const settingsMatch = useMatch('/admin/settings');
  const tagsMatch = useMatch('/admin/tags');

  const getBreadcrumbs = () => {
    if (vbMatch && vbMatch.params.unitId) {
      return (
        <>
          <Breadcrumb label={t`Beds`} url="/admin/beds" index={0} />
          <NavigateSeparator />
          <BedBreadcrumb
            unitId={vbMatch.params.unitId}
            url={`/admin/beds/${vbMatch.params.unitId}`}
            index={1}
          />
          <NavigateSeparator />
          <Breadcrumb label={t`Virtual Bed`} index={2} />
        </>
      );
    }
    if (vbSimulationMatch && vbSimulationMatch.params.unitId) {
      return (
        <>
          <Breadcrumb label={t`Beds`} url="/admin/beds" index={0} />
          <NavigateSeparator />
          <BedBreadcrumb
            unitId={vbSimulationMatch.params.unitId}
            url={`/admin/beds/${vbSimulationMatch.params.unitId}`}
            index={1}
          />
          <NavigateSeparator />
          <Breadcrumb label={t`Data simulation`} index={2} />
        </>
      );
    }
    if (bedMatch && bedMatch.params.unitId) {
      return (
        <>
          <Breadcrumb label={t`Beds`} url="/admin/beds" index={0} />
          <NavigateSeparator />
          <BedBreadcrumb unitId={bedMatch.params.unitId} index={1} />
        </>
      );
    }
    if (bedsMatch) {
      return <Breadcrumb label={t`Beds`} index={0} />;
    }
    if (profileMatch) {
      return <Breadcrumb label={t`My profile`} index={0} />;
    }
    if (insightMatch) {
      return <Breadcrumb label={t`Insight`} index={0} />;
    }
    if (hl7LogMatch) {
      return <Breadcrumb label={t`Hl7 Log`} index={0} />;
    }
    if (tagsMatch) {
      return <Breadcrumb label={t`Tags`} index={0} />;
    }
    if (userMatch && userMatch.params.userId) {
      return (
        <>
          <Breadcrumb label={t`Users`} url="/admin/user" index={0} />
          <NavigateSeparator />
          <UserBreadcrumb userId={userMatch.params.userId} index={1} />
        </>
      );
    }
    if (usersMatch) {
      return <Breadcrumb label={t`Users`} index={0} />;
    }
    if (settingsMatch) {
      return <Breadcrumb label={t`Settings`} index={0} />;
    }
    if (hospitalLayoutMatch) {
      return <Breadcrumb label={t`Hospital Layout`} index={0} />;
    }
    if (roomMatch && roomMatch.params.roomId) {
      return (
        <>
          <Breadcrumb label={t`Rooms`} url="/admin/rooms" index={0} />
          <NavigateSeparator />
          <RoomBreadcrumb roomId={roomMatch.params.roomId} index={1} />
        </>
      );
    }
    if (roomsMatch) {
      return <Breadcrumb label={t`Rooms`} index={0} />;
    }
    return [];
  };

  return <Breadcrumbs>{getBreadcrumbs()}</Breadcrumbs>;
};

export default TopNavigator;

interface BreadcrumbProps {
  url?: string;
  label: string;
  index: number;
}

const Breadcrumb = ({ url, label, index }: BreadcrumbProps) => {
  return (
    <Flex alignItems="center" key={label}>
      {url ? (
        <Link to={url}>
          <BreadcrumbItem variant="h5" index={index}>
            {label}
          </BreadcrumbItem>
        </Link>
      ) : (
        <CurrentBreadcrumb variant="h5" index={index}>
          {label}
        </CurrentBreadcrumb>
      )}
    </Flex>
  );
};

interface BedBreadcrumbProps {
  unitId: string;
  index: number;
  url?: string;
}

const BedBreadcrumb = ({ unitId, url, index }: BedBreadcrumbProps) => {
  const { data } = useQuery<BedQuery, BedQueryVariables>(bed, {
    variables: {
      unitId,
    },
    fetchPolicy: 'cache-only',
  });
  return (
    <Breadcrumb index={index} label={data?.bed?.name || unitId} url={url} />
  );
};

interface RoomBreadcrumbProps {
  roomId: string;
  index: number;
  url?: string;
}

const RoomBreadcrumb = ({ roomId, url, index }: RoomBreadcrumbProps) => {
  const { data } = useQuery<roomQuery, roomQueryVariables>(room, {
    variables: {
      roomId,
    },
    fetchPolicy: 'cache-only',
  });
  return (
    <Breadcrumb index={index} label={data?.room?.name || roomId} url={url} />
  );
};

interface UserBreadcrumbProps {
  userId: string;
  index: number;
}

const UserBreadcrumb = ({ userId, index }: UserBreadcrumbProps) => {
  const { data } = useQuery<UserQuery, UserQueryVariables>(userQuery, {
    variables: {
      userId,
    },
    fetchPolicy: 'cache-only',
  });
  return (
    <Breadcrumb
      index={index}
      label={data?.user ? `${data.user.firstName} ${data.user.lastName}` : ''}
    />
  );
};

const Breadcrumbs = styled(Flex)`
  position: relative;
  margin: 0 40px;
  align-items: center;
  white-space: nowrap;
`;

const CurrentBreadcrumb = styled(Typography)<{ index: number }>(
  ({ index, theme: { palette } }) => `
  font-weight: normal;
  margin-top: 26px;
  margin-bottom: 26px;

  font-stretch: expanded;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${index === 0 ? palette.primary.main : palette.text.primary};
`,
);

const BreadcrumbItem = styled(Typography)<{ index: number }>(
  ({ index, theme: { palette } }) => `
  font-weight: normal;
  margin-top: 26px;
  margin-bottom: 26px;
  font-stretch: expanded;

  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: ${index === 0 ? palette.primary.main : palette.text.primary};
`,
);

const NavigateSeparator = styled(NavigateNextIcon)`
  margin-left: ${(props) => props.theme.spacing(1)};
  margin-right: ${(props) => props.theme.spacing(1)};
  color: ${(props) => props.theme.palette.text.primary};
`;
