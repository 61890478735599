import { t } from '@lingui/macro';
import { Box, FormControl, styled, TextField } from '@mui/material';
import ContentBlock from 'src/components/ContentBlock';
import Flex from 'src/components/Flex';
import { useDirty } from 'src/hooks';
import { SettingsState } from 'src/pages/Admin/GlobalSettings/globalSettingsHelper';
import InfoBox from 'src/components/InfoBox';
import { DEFAULT_SYNC_PERIOD } from '../DataTransferSettings/helpers';

interface Props {
  noteConfirmationPeriod: number;
  setSettings: React.Dispatch<React.SetStateAction<SettingsState>>;
  onSave: () => null | Promise<any>;
}

const SpDashboardCareNotes = ({
  noteConfirmationPeriod,
  setSettings,
  onSave,
}: Props) => {
  const { isDirty, resetDirty, initValue } = useDirty({
    noteConfirmationPeriod,
  });

  const handleNCPChange = (value: string) => {
    setSettings((prev) => ({
      ...prev,
      noteConfirmationPeriod: parseInt(value),
    }));
  };

  const handleDiscard = () => {
    setSettings((prev) => ({
      ...prev,
      noteConfirmationPeriod: initValue.noteConfirmationPeriod,
    }));
  };

  const handleSave = () => {
    try {
      onSave();
      resetDirty();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ContentBlock
      title={t`Care Notes Editability Period`}
      onSave={handleSave}
      onCancel={handleDiscard}
      isSaveDisabled={!isDirty}
    >
      <Flex alignItems="center">
        <StyledFormControl fullWidth={true} variant="outlined">
          <TextField
            fullWidth
            onChange={({ target }) => handleNCPChange(target.value)}
            value={noteConfirmationPeriod || ''}
            label={t`Ediability period / min`}
            id="outlined-syncPeriod"
            margin="normal"
            variant="outlined"
          />
        </StyledFormControl>
        <Box px={2}>
          <InfoBox
            color="gray"
            text={t`LINIS SafetyPort will send all enabled parameters when CHANGED, or if the last value was sent ${DEFAULT_SYNC_PERIOD} minutes ago`}
          />
        </Box>
      </Flex>
    </ContentBlock>
  );
};

const StyledFormControl = styled(FormControl)`
  width: 170px;
`;

export default SpDashboardCareNotes;
