import React, { forwardRef } from 'react';
import { LineChart, Line, DotProps, Rectangle, YAxis, XAxis } from 'recharts';
import {
  exportMobilizationHeight,
  exportMobilizationWidth,
} from '../helpers/export';
import {
  getDatapointColor,
  getDatapointDotValue,
  getWidthOfSwimLine,
  SelectedDatapointsEnum,
} from '../helpers/mobilization';
import { MobilizationDataType } from './mobilizationHook';

interface Props {
  item: MobilizationDataType;
}

const PrerenderedMobilizationChart = ({ item }: Props, ref: any) => {
  const countOfSelectedDatapoints = 7;
  const lineWidth = getWidthOfSwimLine(countOfSelectedDatapoints);

  return (
    <LineChart
      key={`exportMobilization-day-${item.day}`}
      layout="vertical"
      width={exportMobilizationWidth * 2}
      ref={ref}
      height={exportMobilizationHeight * 2}
      data={item.dayData}
    >
      <XAxis hide domain={[0, countOfSelectedDatapoints + 1]} type="number" />
      <YAxis hide type="category" />
      <Line
        dot={(dot) => CustomDot(dot, SelectedDatapointsEnum.alt, lineWidth)}
        dataKey="altPosition"
        strokeWidth={lineWidth}
        stroke="transparent"
      />
      <Line
        dot={(dot) => CustomDot(dot, SelectedDatapointsEnum.matress, lineWidth)}
        dataKey="matressPosition"
        strokeWidth={lineWidth}
        stroke="transparent"
      />
      <Line
        dot={(dot) =>
          CustomDot(dot, SelectedDatapointsEnum.mobilift, lineWidth)
        }
        dataKey="mobiliftPosition"
        strokeWidth={lineWidth}
        stroke="transparent"
      />
      <Line
        dot={(dot) =>
          CustomDot(dot, SelectedDatapointsEnum.trendelenburg, lineWidth)
        }
        dataKey="trendelenburgPosition"
        strokeWidth={lineWidth}
        stroke="transparent"
      />
      <Line
        dot={(dot) => CustomDot(dot, SelectedDatapointsEnum.lateral, lineWidth)}
        dataKey="lateralPosition"
        strokeWidth={lineWidth}
        stroke="transparent"
      />
      <Line
        dot={(dot) =>
          CustomDot(dot, SelectedDatapointsEnum.backrest30, lineWidth)
        }
        dataKey="backrest30Position"
        strokeWidth={lineWidth}
        stroke="transparent"
      />
      <Line
        dot={(dot) =>
          CustomDot(dot, SelectedDatapointsEnum.backrest45, lineWidth)
        }
        dataKey="backrest45Position"
        strokeWidth={lineWidth}
        stroke="transparent"
      />
    </LineChart>
  );
};

export default forwardRef(PrerenderedMobilizationChart);

const CustomDot = (
  dotData: DotProps & { payload: any },
  type: SelectedDatapointsEnum,
  width: number,
) => {
  if (dotData.cx && dotData.cy) {
    const value = getDatapointDotValue(type, dotData.payload);
    return (
      <Rectangle
        key={`dot-${type}-${dotData.cx}x${dotData.cy}`}
        width={(exportMobilizationWidth * 2) / 9}
        x={dotData.cx - (exportMobilizationWidth * 2) / 9 / 2}
        y={dotData.cy - ((exportMobilizationHeight * 2) / 96 + 0.5) / 2}
        height={(exportMobilizationHeight * 2) / 96 + 0.5}
        fill={value === 1 ? getDatapointColor(type) : 'transparent'}
      ></Rectangle>
    );
  }
  return <React.Fragment />;
};
