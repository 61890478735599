import { Box, Typography, styled } from '@mui/material';
import { MobileBedCellValue } from './MobileBedCellValue';
import {
  BedData,
  getAllBedsHeaderTranslation,
} from '../../../helpers/dashboard';
import { SvDashboardSortingType } from 'src/pages/SafetyView/Setting/DashboardSettingProvider';

interface Props {
  anonymized: boolean;
  cellType: SvDashboardSortingType;
  bed: BedData;
}

const MobileBedCell = ({ anonymized, cellType, bed }: Props) => {
  return (
    <Container key={`patient-data-${cellType}`}>
      <Box
        mb={1}
        height={26}
        position="relative"
        display="flex"
        alignItems="center"
      >
        <MobileBedCellValue type={cellType} bed={bed} anonymized={anonymized} />
      </Box>
      <Typography color="textSecondary" fontSize="0.75rem">
        {getAllBedsHeaderTranslation(cellType)}
      </Typography>
    </Container>
  );
};

export default MobileBedCell;

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  '& p': {
    width: 'fit-content',
    maxWidth: 90,
  },
});
