import ActionButtons from 'src/components/ActionButtons';
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  AddReportingReportToDashboard,
  AddReportingReportToDashboardVariables,
  RemoveReportingReportFromDashboard,
  RemoveReportingReportFromDashboardVariables,
  ReportingDashboardsQuery,
  ReportingReportQuery_reportingReport_reportingDashboards,
} from 'src/graphql/types';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { t } from '@lingui/macro';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  reportingDashboardsQuery,
  reportingReportQuery,
} from 'src/graphql/queries';
import Flex from 'src/components/Flex';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import {
  addReportingReportToDashboard,
  removeReportingReportFromDashboard,
} from 'src/graphql/mutations';

interface Props {
  isDirty: boolean;
  onSave: () => void;
  onDiscard: () => void;
  reportDashboards:
    | ReportingReportQuery_reportingReport_reportingDashboards[]
    | null
    | undefined;
  reportId?: string;
  onOpenSaveAs: () => void;
  onOpenDeleteConfirm: () => void;
}

const ReportDetailActionButtons = ({
  isDirty,
  onDiscard,
  onSave,
  reportDashboards,
  reportId,
  onOpenSaveAs,
  onOpenDeleteConfirm,
}: Props) => {
  const { data: dashboardsData } = useQuery<ReportingDashboardsQuery>(
    reportingDashboardsQuery,
  );
  const [moreMenuAnchor, setMoreMenuAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [dashboardMenuEl, setDashboardMenuEl] =
    useState<HTMLButtonElement | null>(null);

  const [addToDashboard] = useSnackbarMutation<
    AddReportingReportToDashboard,
    AddReportingReportToDashboardVariables
  >(addReportingReportToDashboard, undefined, {
    successMessage: t`Succesfully added`,
  });
  const [removeFromDashboard] = useSnackbarMutation<
    RemoveReportingReportFromDashboard,
    RemoveReportingReportFromDashboardVariables
  >(removeReportingReportFromDashboard, undefined, {
    successMessage: t`Succesfully removed`,
  });

  const handleRemoveFromDashboard = (dashboardId: string) => {
    if (reportId) {
      removeFromDashboard({
        variables: {
          input: {
            reportId: reportId as string,
            dashboardId,
          },
        },
        refetchQueries: [
          {
            query: reportingReportQuery,
            variables: { id: reportId },
          },
        ],
      });
    }
  };

  const handleAddToDashboard = (dashboardId: string) => {
    if (reportId) {
      addToDashboard({
        variables: {
          input: {
            reportId: reportId,
            dashboardId,
          },
        },
        refetchQueries: [
          {
            query: reportingReportQuery,
            variables: { id: reportId },
          },
        ],
      });
    }
  };

  const handleEditDashboard = (dashboardId: string) => {
    if (reportDashboards?.map((rep) => rep.id).includes(dashboardId)) {
      handleRemoveFromDashboard(dashboardId);
      return;
    }
    handleAddToDashboard(dashboardId);
  };

  return (
    <Flex alignItems="center">
      <ActionButtons
        isDirty={reportId ? isDirty : true}
        saveButtonLabel={t`Save`}
        onDiscard={reportId ? onDiscard : undefined}
        backButton={reportId ? undefined : t`Back`}
        backButtonVisibility={reportId ? 'default' : 'always'}
        onSave={onSave}
        rightButtons={
          reportId
            ? [
                {
                  id: 'add-to-board',
                  onClick: (e) => setDashboardMenuEl(e.currentTarget),
                  variant: 'outlined',
                  label: t`Add to board`,
                  endIcon: <KeyboardArrowDownIcon />,
                },
                {
                  id: 'show-more',
                  onClick: (e) => setMoreMenuAnchor(e.currentTarget),
                  variant: 'outlined',
                  label: <MoreHorizIcon />,
                  sx: { px: 1, minWidth: 'auto' },
                },
              ]
            : []
        }
      />

      <Menu
        anchorEl={dashboardMenuEl}
        open={!!dashboardMenuEl}
        onClose={() => setDashboardMenuEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {dashboardsData?.reportingDashboards.map((item) => (
          <MenuItem key={item.id} onClick={() => handleEditDashboard(item.id)}>
            <Checkbox
              checked={reportDashboards
                ?.map((rep) => rep.id)
                ?.includes(item.id)}
            />
            {item.name}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={moreMenuAnchor}
        open={!!moreMenuAnchor}
        onClose={() => setMoreMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onOpenSaveAs}>
          <ListItemIcon>
            <SaveAsIcon />
          </ListItemIcon>
          <ListItemText>{t`Save as`}</ListItemText>
        </MenuItem>
        {reportId && (
          <MenuItem onClick={onOpenDeleteConfirm}>
            <ListItemIcon>
              <DeleteForeverIcon />
            </ListItemIcon>
            <ListItemText>{t`Delete`}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Flex>
  );
};

export default ReportDetailActionButtons;
