import { t } from '@lingui/macro';
import { Grid } from '@mui/material';
import FormikSwitch from 'src/components/Formik/FormikSwitch';

export const AdditionalSwitchDatapoints = () => {
  return (
    <Grid
      container
      columnSpacing={4}
      rowSpacing={4}
      sx={{ maxWidth: '850px', minWidth: '650px' }}
    >
      <Grid item xs={3}>
        <FormikSwitch name="backrestLock" label={t`Backrest lock`} />
      </Grid>
      <Grid item xs={3}>
        <FormikSwitch name="heightLock" label={t`Height lock`} />
      </Grid>
      <Grid item xs={3}>
        <FormikSwitch name="bedExtension" label={t`Bed extension`} />
      </Grid>
      <Grid item xs={3}>
        <FormikSwitch name="transfer" label={t`Transfer`} />
      </Grid>
      <Grid item xs={3}>
        <FormikSwitch name="calfrestLock" label={t`Calfrest lock`} />
      </Grid>
      <Grid item xs={3}>
        <FormikSwitch name="thighrestLock" label={t`Foot control lock`} />
      </Grid>
      <Grid item xs={3}>
        <FormikSwitch name="batteryFailure" label={t`Battery failure`} />
      </Grid>
    </Grid>
  );
};
