import { alpha, Box, styled } from '@mui/material';
import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Rectangle,
  DotProps,
  Tooltip,
} from 'recharts';
import Flex from 'src/components/Flex';
import {
  DayDataType,
  getDatapointColor,
  getDatapointDotValue,
  getWidthOfSwimLine,
  mobilizationdayHeight,
  mobilizationDayWidth,
  SelectedDatapointsEnum,
  SelectedDatapointsType,
  getCountOfSelectedDatapoints,
  getDatapointSelectorItemText,
} from '../../helpers/mobilization';

interface Props {
  dayData: DayDataType[];
  selectedDatapoints: SelectedDatapointsType;
}

const setTooltipVisible = (type: SelectedDatapointsEnum, data: any) => {
  if (
    (type === SelectedDatapointsEnum.alt && data.alt === 0) ||
    (type === SelectedDatapointsEnum.matress && data.matress === 0) ||
    (type === SelectedDatapointsEnum.mobilift && data.mobilift === 0) ||
    (type === SelectedDatapointsEnum.trendelenburg &&
      data.trendelenburg === 0) ||
    (type === SelectedDatapointsEnum.lateral && data.lateral === 0) ||
    (type === SelectedDatapointsEnum.backrest30 && data.backrest30 === 0) ||
    (type === SelectedDatapointsEnum.backrest45 && data.backrest45 === 0)
  )
    return false;
  else return true;
};

const MobilizationDay = ({ dayData, selectedDatapoints }: Props) => {
  const countOfSelectedDatapoints =
    getCountOfSelectedDatapoints(selectedDatapoints);
  const [activeLine, setActiveLine] = useState<SelectedDatapointsEnum | null>(
    null,
  );
  const lineWidth = getWidthOfSwimLine(countOfSelectedDatapoints);
  const lines = [
    {
      dataKey: 'altPosition',
      type: SelectedDatapointsEnum.alt,
      show: selectedDatapoints.alt,
    },
    {
      dataKey: 'matressPosition',
      type: SelectedDatapointsEnum.matress,
      show: selectedDatapoints.matress,
    },
    {
      dataKey: 'mobiliftPosition',
      type: SelectedDatapointsEnum.mobilift,
      show: selectedDatapoints.mobilift,
    },
    {
      dataKey: 'trendelenburgPosition',
      type: SelectedDatapointsEnum.trendelenburg,
      show: selectedDatapoints.trendelenburg,
    },
    {
      dataKey: 'lateralPosition',
      type: SelectedDatapointsEnum.lateral,
      show: selectedDatapoints.lateral,
    },
    {
      dataKey: 'backrest30Position',
      type: SelectedDatapointsEnum.backrest30,
      show: selectedDatapoints.backrest30,
    },
    {
      dataKey: 'backrest45Position',
      type: SelectedDatapointsEnum.backrest45,
      show: selectedDatapoints.backrest45,
    },
  ];
  return (
    <Container mx={1}>
      <LineChart
        layout="vertical"
        width={mobilizationDayWidth}
        height={mobilizationdayHeight}
        data={dayData}
      >
        <XAxis hide domain={[0, countOfSelectedDatapoints + 1]} type="number" />
        <YAxis hide type="category" />
        <Tooltip
          isAnimationActive={false}
          cursor={false}
          content={() => CustomTooltip(activeLine)}
        />
        {lines
          .filter((line) => !!line.show)
          .map((line) => (
            <Line
              activeDot={(dot: any) =>
                CustomActiveDot(dot, line.type, lineWidth, setActiveLine)
              }
              key={`line-${line.dataKey}`}
              dot={(dot) => CustomDot(dot, line.type, lineWidth)}
              dataKey={line.dataKey}
              strokeWidth={lineWidth + 2}
              stroke="transparent"
              opacity={0.3}
            ></Line>
          ))}
      </LineChart>
    </Container>
  );
};

export default MobilizationDay;

const CustomTooltip = (type: SelectedDatapointsEnum | null) => {
  return (
    !!type && (
      <CustomTooltipWrapper>
        <p>{getDatapointSelectorItemText(type)}</p>
      </CustomTooltipWrapper>
    )
  );
};

const CustomDot = (
  dotData: DotProps & { payload: any },
  type: SelectedDatapointsEnum,
  width: number,
) => {
  if (dotData.cx && dotData.cy) {
    const value = getDatapointDotValue(type, dotData.payload);
    return (
      <Rectangle
        key={`dot-${type}-${dotData.cx}x${dotData.cy}`}
        width={width + 2}
        x={dotData.cx - width / 2 - 1}
        y={dotData.cy - 3.85 / 2}
        height={7.7}
        fill={value === 1 ? getDatapointColor(type) : 'transparent'}
      ></Rectangle>
    );
  }
  return <React.Fragment />;
};

const CustomActiveDot = (
  dotData: DotProps & { payload: any },
  type: SelectedDatapointsEnum,
  width: number,
  setActiveLine: React.Dispatch<
    React.SetStateAction<SelectedDatapointsEnum | null>
  >,
) => {
  if (setTooltipVisible(type, dotData.payload) && dotData.cx && dotData.cy) {
    const value = getDatapointDotValue(type, dotData.payload);
    return (
      <Rectangle
        onMouseOver={() => setActiveLine(type)}
        onMouseLeave={() => setActiveLine(null)}
        width={width + 2}
        x={dotData.cx - width / 2 - 1}
        y={dotData.cy - 3.85 / 2}
        height={7.7}
        fill={value === 1 ? getDatapointColor(type) : 'transparent'}
      ></Rectangle>
    );
  }
  return <React.Fragment />;
};

const Container = styled(Box)(
  ({ theme: { spacing, palette } }) => `
  margin: 0 ${spacing(0.5)};
  background-color: ${palette.common.mobilizationBg};
  width: ${mobilizationDayWidth}px;
  height: ${mobilizationdayHeight}px;
  border-radius: 5px;
`,
);

const CustomTooltipWrapper = styled(Flex)(
  ({ theme: { spacing } }) => `
  border-radius: 4px;
  padding: ${spacing(0.5)} ${spacing(1)};
  background-color: ${alpha('#616161', 0.9)};
  color: #fff;
  font-weight: bold;
  font-size: 0.625rem;
`,
);
