import { useLingui } from '@lingui/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, styled, ThemeProvider } from '@mui/material';
import { cs, de, es, fr, ptBR } from 'date-fns/locale';
import { useContext, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from 'src/auth/AuthProvider';
import Flex from 'src/components/Flex';
import { UserRole } from 'src/graphql/types';
import { isMobile } from 'src/lib/mobileCheck';
import { adminTheme } from 'src/themes';
import MobileSafetyPortLayout from '../mobile/MobileSafetyPortLayout';
import NavigationBar from '../components/NavigationBar';
import {
  darkTheme,
  lightTheme,
  mobileDarkTheme,
  mobileLightTheme,
} from '../themes';
import TopBar from '../components/TopBar';
import DashboardSettingProvider from '../Setting/DashboardSettingProvider';
import WardDataProvider from '../providers/WardDataProvider';
import MobileModalProvider from '../mobile/ModalOverlay/MobileModalProvider';
import MobileDashboardProvider from '../mobile/Settings/MobilDashboardProvider';

const locales = { cs, fr, de, es, 'pt-br': ptBR };

const SafetyPortLayout = () => {
  const { user, config } = useContext(AuthContext);
  const { i18n } = useLingui();
  const theme = user?.userSetting?.svTheme === 'dark' ? 'dark' : 'light';

  let selectedTheme = useMemo(() => {
    if (isMobile) {
      return theme === 'dark' ? mobileDarkTheme : mobileLightTheme;
    }
    return theme === 'dark' ? darkTheme : lightTheme;
  }, [theme]);

  useEffect(() => {
    return () => {
      document.documentElement.style.fontSize = 'initial';
    };
  }, []);

  return (
    <DashboardSettingProvider>
      <WardDataProvider>
        <ThemeProvider theme={selectedTheme}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            locale={locales[i18n.locale]}
          >
            <Container>
              {isMobile ? (
                <MobileDashboardProvider>
                  <MobileModalProvider>
                    <MobileSafetyPortLayout />
                  </MobileModalProvider>
                </MobileDashboardProvider>
              ) : (
                <DashboardSettingProvider>
                  <ThemeProvider theme={adminTheme}>
                    <NavigationBar
                      isAdmin={user?.role === UserRole.ADMIN}
                      reportingAccess={
                        user?.reportingModuleAccess &&
                        config?.superConfig?.reportingModuleAccess
                      }
                    />
                  </ThemeProvider>
                  <Content>
                    <TopBar />
                    <Box py={2}>
                      <Outlet />
                    </Box>
                  </Content>
                </DashboardSettingProvider>
              )}
            </Container>
          </LocalizationProvider>
        </ThemeProvider>
      </WardDataProvider>
    </DashboardSettingProvider>
  );
};

export default SafetyPortLayout;

const Container = styled(Flex)(
  ({ theme: { palette } }) => `
  min-height: 100vh;
  background-color: ${palette.background.default};
  color: ${palette.text.primary};
`,
);

const Content = styled(Box)`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;
