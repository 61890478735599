import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  alpha,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { BedData, getAllBedsHeaderTranslation } from '../helpers/dashboard';
import { OpenModalType } from '../BedDetailModal/modalTypes';
import {
  DashboardItem,
  DashboardSettingContext,
  SvDashboardSortingType,
} from '../Setting/DashboardSettingProvider';
import { DashboardDatapoint } from './DashboardDatapoint';
import { t } from '@lingui/macro';
import { BedState, FallRisk } from 'src/graphql/types';
import { BedStatus } from 'src/components/BedStatus';

interface Props {
  bedsData: BedData[];
  dashboardOrder: DashboardItem[];
  setOpenModal: React.Dispatch<React.SetStateAction<OpenModalType>>;
  contentFontSize: string;
}

export interface OpenModalDataType {
  id: string | null;
  note: string | null;
  name: string | null;
  sessionStart: string | null;
  sessionEnd: string | null;
  safetyPositionPresetId: string | null;
}

const getAlarmState = (bed: BedData) => {
  if (bed.inAnotherWard) {
    return 'none';
  }
  return bed.data?.bedExitAlarm
    ? 'alarm'
    : bed.data?.bedExitOn === false && bed.fallRisk === FallRisk.HIGH
    ? 'warning'
    : 'none';
};

const isFirstOffline = (beds: BedData[], index: number) => {
  return Boolean(
    beds[index].inAnotherWard && index > 0 && !beds[index - 1].inAnotherWard,
  );
};

const NewBedTable = ({
  bedsData,
  dashboardOrder,
  setOpenModal,
  contentFontSize,
}: Props) => {
  const {
    userSetting: { anonymized },
  } = useContext(DashboardSettingContext);

  const getDatapointHeader = (type: SvDashboardSortingType) => {
    if (type === SvDashboardSortingType.weight && anonymized) {
      return '';
    }
    return getAllBedsHeaderTranslation(type);
  };

  return (
    <TableContainer
      sx={{
        borderRadius: '10px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        marginBottom: 4,
      }}
      component={Paper}
    >
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="bed dashboard"
      >
        <TableHead>
          <TableRow>
            <StickyTableCell
              scope="row"
              sx={{
                '&::before': {
                  position: 'absolute',
                  content: "''",
                  width: '1px',
                  backgroundColor: (theme) => theme.palette.common.divider,
                  top: 0,
                  bottom: 0,
                  left: 'calc(50% + 1.25rem)',
                },
              }}
            >
              <StickyTableCellContent>
                <Typography
                  sx={{
                    '&&': {
                      paddingLeft: (theme) => theme.spacing(7),
                    },
                  }}
                >
                  {t`Location`}
                </Typography>
                <Typography>{t`Session name`}</Typography>
              </StickyTableCellContent>
            </StickyTableCell>
            {dashboardOrder.map((datapoint) => (
              <TableCell
                key={datapoint.type}
                align="center"
                sx={{
                  minWidth: datapoint.width,
                  borderRight: (theme) =>
                    `1px solid ${theme.palette.common.divider}`,
                }}
              >
                <Typography>{getDatapointHeader(datapoint.type)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ fontSize: contentFontSize }}>
          {bedsData.map((bed, index, array) => (
            <React.Fragment key={`${bed.location}-${bed.unitId}`}>
              {isFirstOffline(array, index) ? (
                <>
                  <TableRow>
                    <Divider colSpan={dashboardOrder.length + 2} />
                  </TableRow>
                  <TableRow />
                </>
              ) : null}
              <BedTableRow
                alert={getAlarmState(bed)}
                sx={{
                  fontSize: '1em',
                  cursor: bed.unitId ? 'pointer' : undefined,
                }}
                onClick={() => {
                  if (!bed.unitId) {
                    return;
                  }
                  setOpenModal({
                    open: true,
                    bedId: bed.unitId,
                  });
                }}
              >
                <StickyTableCell scope="row">
                  {bed.inAnotherWard ? <OpacityBackground /> : null}
                  <StickyTableCellContent>
                    {bed.unitId ? (
                      <BedStatus
                        status={bed.state === 'ONLINE' ? 'ONLINE' : 'OFFLINE'}
                        ethernet={bed.ethConnection}
                      />
                    ) : (
                      <BedStatusPlaceholder />
                    )}
                    <Typography fontSize="1em" noWrap>
                      {bed.location || t`Unknown`}
                    </Typography>
                    <Typography fontSize="1em" noWrap>
                      {bed.sessionName || t`Not assigned`}
                    </Typography>
                  </StickyTableCellContent>
                </StickyTableCell>
                <BedTableCells dashboardOrder={dashboardOrder} bed={bed} />
              </BedTableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NewBedTable;

const isBedOffline = (bed: BedData) => {
  return bed.state === BedState.OFFLINE;
};

interface BedTableCellsProps {
  dashboardOrder: DashboardItem[];
  bed: BedData;
}

const BedTableCells = ({ dashboardOrder, bed }: BedTableCellsProps) => {
  if (bed.inAnotherWard) {
    return (
      <TableCell
        colSpan={dashboardOrder.length}
        sx={{ fontSize: '1em', textAlign: 'center', position: 'relative' }}
      >
        <OpacityBackground />
        {bed.location == null
          ? t`Unit is unknown`
          : t`Bed is used by another unit`}
      </TableCell>
    );
  }

  if (isBedOffline(bed)) {
    return (
      <TableCell
        colSpan={dashboardOrder.length}
        sx={{
          fontSize: '1em',
          textAlign: 'center',
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {t`Bed Offline`}
      </TableCell>
    );
  }

  if (!bed.unitId) {
    return (
      <TableCell
        colSpan={dashboardOrder.length}
        sx={{
          fontSize: '1em',
          textAlign: 'center',
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {t`No bed`}
      </TableCell>
    );
  }
  if (!bed.data) {
    return (
      <TableCell
        colSpan={dashboardOrder.length}
        sx={{
          fontSize: '1em',
          textAlign: 'center',
          color: (theme) => theme.palette.grey[500],
        }}
      >
        {t`No data`}
      </TableCell>
    );
  }
  return (
    <>
      {dashboardOrder.map((datapoint) => (
        <TableCell
          key={datapoint.type}
          sx={{
            fontSize: '1em',
            maxWidth:
              datapoint.type === SvDashboardSortingType.note
                ? '121px'
                : undefined,
            minWidth: '121px',
            textAlign: 'center',
            py: datapoint.padding,
          }}
        >
          <DashboardDatapoint fontSize="1em" type={datapoint.type} bed={bed} />
        </TableCell>
      ))}
    </>
  );
};

const Divider = styled(TableCell)`
  border-top: 1px solid ${(props) => props.theme.palette.common.divider};
  height: 4px;
  padding: 0;
  background-color: ${(props) => props.theme.palette.common.divider};
`;

const OpacityBackground = styled(Box)`
  background-color: ${(props) =>
    alpha(props.theme.palette.background.default, 0.6)};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StickyTableCellContent = styled(Box)`
  display: flex;
  align-items: center;
  & svg {
    padding-top: ${(props) => props.theme.spacing(2)};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-bottom: ${(props) => props.theme.spacing(2)};
    padding-right: 0;
  }
  & p {
    padding: ${(props) => props.theme.spacing(2)};
    width: 50%;
  }
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.palette.common.divider};
  }
`;

const StickyTableCell = styled(TableCell)`
  position: sticky;
  left: 0;
  background: inherit;
  z-index: 800;
  padding: 0;
  min-width: 380px;
  max-width: 520px;
  font-size: 1em;
  text-align: left;
`;

const BedTableRow = styled(TableRow)<{ alert: 'none' | 'warning' | 'alarm' }>(
  ({ theme, alert }) => `
  border-top: 1px solid ${theme.palette.common.divider};
  ${
    alert === 'alarm'
      ? `
      &&& {
        border-top: 1px solid ${theme.palette.error.main};
        border-top: 1px solid ${theme.palette.error.main};
        background-image: linear-gradient(to right, ${alpha(
          theme.palette.error.main,
          0.1,
        )} , ${alpha(theme.palette.error.main, 0.1)});
      }
      &&& + * {
        border-top: 1px solid ${theme.palette.error.main};
      }
    `
      : ``
  }
`,
);

const BedStatusPlaceholder = styled(Box)`
  width: 2rem;
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(2)};
  padding-right: 0;
`;
