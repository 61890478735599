import CircularProgress from '@mui/material/CircularProgress';
import { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthContext } from './auth/AuthProvider';
import Flex from './components/Flex';
import { UserRole } from './graphql/types';
import { isSpDashboardEnabled, isSpDataEnabled } from './lib/configHelper';
import { isMobile } from './lib/mobileCheck';
import AdminLayout from './pages/Admin/AdminLayout';
import BedDetail from './pages/Admin/Bed/Bed';
import Beds from './pages/Admin/Bed/Beds';
import Hl7Log from './pages/Admin/DataHistory/Hl7Log';
import GlobalSettings from './pages/Admin/GlobalSettings/GlobalSettings';
import { HospitalLayoutPage } from './pages/Admin/HospitalLayout';
import Insight from './pages/Admin/Insight/Insight';
import NewRoom from './pages/Admin/RoomNew/NewRoom';
import RoomDetail from './pages/Admin/RoomNew/RoomDetail';
import Rooms from './pages/Admin/RoomNew/Rooms';
import Tags from './pages/Admin/Tag/Tags';
import CreateUser from './pages/Admin/User/CreateUser';
import EditUser from './pages/Admin/User/EditUser';
import MyProfile from './pages/Admin/User/MyProfile';
import Users from './pages/Admin/User/Users';
import DefaultLayout from './pages/DefaultLayout';
import { DemoScreen } from './pages/Demo/DemoScreen';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import LicenceExpiration from './pages/LicenceExpiration/LicenceExpiration';
import LoginScreen from './pages/Login/LoginScreen';
import ReportingLayout from './pages/Reporting/ReportingLayout';
import ReportingSectionsRoute from './pages/Reporting/ReportingSectionsRoute';
import SafetyViewLayout from './pages/SafetyView/layout/SafetyPortLayout';
import ProductSetting from './pages/SuperAdmin/ProductSetting';
import NewSafetyPortDashboard from './pages/SafetyView/Dashboard/NewSafetyPortDashboard';
import NewSessionHistory from './pages/SafetyView/History/NewSessionHistory';
import { VirtualBed } from './pages/Admin/VirtualBed/VirtualBed';
import { DataSimulation } from './pages/Admin/DataSimulation/DataSimulation';

const App = () => {
  const { user, userLoading, isUnauthenticated, config, configLoading } =
    useContext(AuthContext);

  if (configLoading || userLoading) {
    return (
      <Flex
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Flex>
    );
  }
  const error = !user;
  const spDataEnabled = isSpDataEnabled(config);
  const spDashboardEnabled = isSpDashboardEnabled(config);
  const licenced = spDataEnabled || spDashboardEnabled;
  const licencedAndAuthenticated = Boolean(
    licenced && !isUnauthenticated && !error,
  );
  const demoAccess = user?.demoAccess || user?.role === UserRole.DEMO;

  const redirectLocation = isUnauthenticated
    ? '/login'
    : !user
    ? '/error'
    : '/licence-expired';

  const hasRoles = (roles: UserRole[]) => {
    return roles.some((role) => role === user?.role);
  };
  const hl7LogEnabled = !!config?.superConfig?.hl7LogEnabled;
  const hospitalName = config?.config?.hospitalName || '';
  const isAdmin = hasRoles([UserRole.ADMIN]);
  const hasVirtualBedAccess = user?.vbAccess && hasRoles([UserRole.ADMIN]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/safety-port"
          element={
            licencedAndAuthenticated &&
            hasRoles([UserRole.REGULAR, UserRole.ADMIN]) ? (
              <SafetyViewLayout />
            ) : (
              <Navigate to={redirectLocation} />
            )
          }
        >
          <Route
            path="/safety-port/dashboard"
            element={<NewSafetyPortDashboard />}
          />
          <Route path="/safety-port/history" element={<NewSessionHistory />} />
        </Route>
        <Route
          path="/reporting"
          element={
            user?.reportingModuleAccess &&
            config?.superConfig?.reportingModuleAccess ? (
              <ReportingLayout />
            ) : (
              <Navigate to={redirectLocation} />
            )
          }
        >
          <Route path="/reporting" element={<ReportingSectionsRoute />} />
          <Route
            path="/reporting/:section/:id"
            element={<ReportingSectionsRoute />}
          />
        </Route>
        <Route
          element={
            licencedAndAuthenticated ? (
              <AdminLayout
                isAdmin={isAdmin}
                demoAccess={demoAccess}
                spDashboardAccess={spDashboardEnabled}
                hl7LogEnabled={hl7LogEnabled}
                hospitalName={hospitalName}
              />
            ) : (
              <Navigate to={redirectLocation} />
            )
          }
        >
          <Route path="/my-profile" element={<MyProfile />} />
        </Route>
        <Route
          path="/admin"
          element={
            licencedAndAuthenticated && hasRoles([UserRole.ADMIN]) ? (
              <AdminLayout
                isAdmin={isAdmin}
                demoAccess={demoAccess}
                spDashboardAccess={spDashboardEnabled}
                hl7LogEnabled={hl7LogEnabled}
                hospitalName={hospitalName}
              />
            ) : (
              <Navigate to={redirectLocation} />
            )
          }
        >
          <Route
            path="/admin/hospital-layout"
            element={<HospitalLayoutPage />}
          />
          <Route path="/admin/rooms" element={<Rooms />} />
          <Route path="/admin/rooms/new" element={<NewRoom />} />
          <Route path="/admin/rooms/:roomId" element={<RoomDetail />} />
          <Route path="/admin/tags" element={<Tags />} />
          <Route path="/admin/beds" element={<Beds />} />
          <Route
            path="/admin/beds/:unitId/edit"
            element={
              hasVirtualBedAccess ? (
                <VirtualBed />
              ) : (
                <Navigate to="/admin/beds" />
              )
            }
          />
          <Route
            path="/admin/beds/:unitId/simulate"
            element={
              hasVirtualBedAccess ? (
                <DataSimulation />
              ) : (
                <Navigate to="/admin/beds" />
              )
            }
          />
          <Route path="/admin/beds/:unitId" element={<BedDetail />} />
          <Route path="/admin/insight" element={<Insight />} />
          <Route path="/admin/hl7-log" element={<Hl7Log />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/users/new" element={<CreateUser />} />
          <Route path="/admin/users/:userId" element={<EditUser />} />
          <Route path="/admin/settings" element={<GlobalSettings />} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route
            path="/login"
            element={
              isUnauthenticated ? (
                <LoginScreen />
              ) : licenced ? (
                <Navigate to="/" />
              ) : error ? (
                <ErrorPage />
              ) : (
                <Navigate to="/licence-expired" />
              )
            }
          />
          <Route
            path="/licence-expired"
            element={
              isUnauthenticated ? (
                <Navigate to="/login" />
              ) : licenced ? (
                <Navigate to="/" />
              ) : error ? (
                <ErrorPage />
              ) : (
                <LicenceExpiration />
              )
            }
          />
          <Route
            path="/error"
            element={
              isUnauthenticated ? (
                <Navigate to="/login" />
              ) : licenced ? (
                <Navigate to="/" />
              ) : error ? (
                <ErrorPage />
              ) : (
                <Navigate to="/licence-expired" />
              )
            }
          />
          <Route
            path="/demo"
            element={
              licencedAndAuthenticated && demoAccess ? (
                <DemoScreen />
              ) : (
                <Navigate to={redirectLocation} />
              )
            }
          />
          <Route
            path="/product-setting"
            element={
              hasRoles([UserRole.SUPERADMIN]) ? (
                <ProductSetting />
              ) : (
                <Navigate to={redirectLocation} />
              )
            }
          />
        </Route>

        {/* redirects> */}
        <Route path="/insight" element={<Navigate to="/admin/insight" />} />
        <Route path="/users" element={<Navigate to="/admin/users" />} />
        <Route path="/beds" element={<Navigate to="/admin/beds" />} />
        <Route path="/my_profile" element={<Navigate to="/my-profile" />} />

        <Route
          path="/"
          element={
            hasRoles([UserRole.DEMO]) ? (
              <Navigate to="/demo" />
            ) : hasRoles([UserRole.REGULAR]) || isMobile ? (
              <Navigate to="/safety-port/dashboard" />
            ) : hasRoles([UserRole.SUPERADMIN]) ? (
              <Navigate to="/product-setting" />
            ) : (
              <Navigate to="/admin/beds" />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
