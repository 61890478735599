import { t } from '@lingui/macro';
import {
  ReportBedAggregation,
  ReportLocationAggregation,
} from 'src/graphql/types';
import {
  FilterParameter,
  ReportAggregation,
  RawReportFilter,
  ReportFilterParams,
} from '../types';

const mapReportAggregationToTranslates = () => ({
  [ReportAggregation.ABSOLUTE_COUNT]: t`Absolute count`,
  [ReportAggregation.AVERAGE_COUNT]: t`Average count`,
  [ReportAggregation.ABSOLUTE_MEAN]: t`Absolute duration`,
  [ReportAggregation.AVERAGE_MEAN]: t`Average duration`,
  [ReportAggregation.AVERAGE_LAST]: t`Amount count`,
  [ReportAggregation.ABSOLUTE_LAST]: t`Absolute amount count`,
  [ReportAggregation.LIST]: t`List duration`,
  [ReportAggregation.ABSOLUTE_DURATION]: t`Absolute duration`,
  [ReportAggregation.AVERAGE_DURATION]: t`Average duration`,
});

const getBedAggregation = (
  aggregation: ReportAggregation,
): ReportBedAggregation => {
  if (aggregation === ReportAggregation.ABSOLUTE_LAST) {
    return ReportBedAggregation.LAST;
  }
  if (aggregation === ReportAggregation.AVERAGE_LAST) {
    return ReportBedAggregation.LAST;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_COUNT) {
    return ReportBedAggregation.COUNT;
  }
  if (aggregation === ReportAggregation.AVERAGE_COUNT) {
    return ReportBedAggregation.COUNT;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_MEAN) {
    return ReportBedAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.AVERAGE_MEAN) {
    return ReportBedAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_DURATION) {
    return ReportBedAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_DURATION) {
    return ReportBedAggregation.SUM;
  }
  if (aggregation === ReportAggregation.LIST) {
    return ReportBedAggregation.NONE;
  }
  return ReportBedAggregation.MEAN;
};

const getLocationAggregation = (
  aggregation: ReportAggregation,
): ReportLocationAggregation => {
  if (aggregation === ReportAggregation.ABSOLUTE_LAST) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_LAST) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_COUNT) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_COUNT) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_MEAN) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_MEAN) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.ABSOLUTE_DURATION) {
    return ReportLocationAggregation.SUM;
  }
  if (aggregation === ReportAggregation.AVERAGE_DURATION) {
    return ReportLocationAggregation.MEAN;
  }
  if (aggregation === ReportAggregation.LIST) {
    return ReportLocationAggregation.MEAN;
  }
  return ReportLocationAggregation.MEAN;
};

const updateAggregation = (
  newValue: ReportAggregation,
  filter: ReportFilterParams,
) => {
  return {
    ...filter,
    aggregation: newValue,
    bedAggregation: getBedAggregation(newValue),
    locationAggregation: getLocationAggregation(newValue),
  };
};

export const getAggregationOptions = (parameter: FilterParameter) => {
  const result: ReportAggregation[] = [];
  if (parameter === FilterParameter.WEIGHT) {
    result.push(ReportAggregation.AVERAGE_LAST);
  } else if (parameter === FilterParameter.BACKREST_ANGLE) {
    result.push(ReportAggregation.AVERAGE_LAST);
  } else if (parameter === FilterParameter.BED_EXIT_COUNT) {
    result.push(
      ReportAggregation.AVERAGE_COUNT,
      ReportAggregation.ABSOLUTE_COUNT,
    );
  } else if (parameter === FilterParameter.BED_EXIT_REACTION) {
    result.push(
      ReportAggregation.AVERAGE_MEAN,
      ReportAggregation.ABSOLUTE_MEAN,
      ReportAggregation.LIST,
    );
  } else if (parameter === FilterParameter.ALOS) {
    result.push(ReportAggregation.LIST);
  } else if (parameter === FilterParameter.OCCUPANCY) {
    result.push(ReportAggregation.ABSOLUTE_LAST);
  } else {
    result.push(
      ReportAggregation.AVERAGE_DURATION,
      ReportAggregation.ABSOLUTE_DURATION,
    );
  }
  const reportAggregationTranslates = mapReportAggregationToTranslates();
  return result.map((avg) => ({
    id: avg,
    name: reportAggregationTranslates[avg],
  }));
};

const useAggregationOptions = (filter: RawReportFilter) => {
  const aggregationOptions = getAggregationOptions(filter.parameter);
  return {
    aggregationOptions,
    updateAggregation,
  };
};

export default useAggregationOptions;
