import { t } from '@lingui/macro';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { ReactComponent as Check } from 'src/images/safetyView/check.svg';
import {
  ExportType,
  getExportTypeText,
  SelectedExportDayType,
  SelectedExportPeriodType,
} from '../../helpers/export';
import { ExportDataDialogItemProps } from './ExportDataItem';
import InfoBox from 'src/components/InfoBox';
import { add, isValid } from 'date-fns';
import DateInput from 'src/components/DateInput';
import DateTimeInput from 'src/components/DateTimeInput';

interface Props extends ExportDataDialogItemProps {}

const DesktopExportDataItem = ({
  data,
  setEndOfPeriod,
  visibleHandler,
  setAllDay,
  changeFromDate,
  setStartofPeriod,
  type,
  sessionEnd,
  textColor,
  allDay,
  sessionStart,
}: Props) => {
  const theme = useTheme();
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box sx={{ width: '230px', margin: '32px 8px 32px 0' }}>
          <Button
            onClick={visibleHandler}
            variant="text"
            sx={{ color: 'text.primary' }}
          >
            <Check
              style={{ marginRight: '8px' }}
              color={
                data?.show
                  ? theme.palette.success.light
                  : theme.palette.common.graphLine
              }
            />
            <Typography>{getExportTypeText(type)}</Typography>
          </Button>
        </Box>

        {data?.show && (
          <Box display="flex" mr={1}>
            {allDay ? (
              <DateInput
                label={type === ExportType.mobilization ? t`Week` : t`All Day`}
                min={sessionStart ? new Date(sessionStart) : undefined}
                max={sessionEnd ? new Date(sessionEnd) : new Date()}
                variant="outlined"
                size="small"
                value={new Date((data as SelectedExportDayType).date)}
                onChange={setAllDay}
              />
            ) : type === ExportType.insight ? (
              <>
                <DateTimeInput
                  label={t`From`}
                  value={new Date((data as SelectedExportPeriodType).from)}
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                  size="small"
                  onChange={(date) =>
                    changeFromDate(
                      date,
                      sessionEnd && isValid(sessionEnd)
                        ? sessionEnd
                        : new Date(),
                    )
                  }
                />
                <DateTimeInput
                  label={t`To`}
                  min={new Date((data as SelectedExportPeriodType).from)}
                  max={add(new Date((data as SelectedExportPeriodType).from), {
                    hours: 4,
                  })}
                  variant="outlined"
                  size="small"
                  value={new Date((data as SelectedExportPeriodType).to)}
                  onChange={setEndOfPeriod}
                />
              </>
            ) : (
              <>
                <DateInput
                  label={t`From`}
                  min={sessionStart}
                  max={new Date((data as SelectedExportPeriodType).to)}
                  value={new Date((data as SelectedExportPeriodType).from)}
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                  size="small"
                  onChange={setStartofPeriod}
                />
                <DateInput
                  label={t`To`}
                  min={new Date((data as SelectedExportPeriodType).from)}
                  max={sessionEnd ? new Date(sessionEnd) : new Date()}
                  value={new Date((data as SelectedExportPeriodType).to)}
                  variant="outlined"
                  size="small"
                  onChange={setEndOfPeriod}
                />
              </>
            )}
          </Box>
        )}
        {textColor && data?.show && (
          <Box pl={1} pb={2.5} alignSelf="end">
            <InfoBox color={textColor} text={t`Select last day of the week`} />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          height: '2px',
          width: '100%',
          background: (theme) => theme.palette.common.graphLine,
          borderRadius: 6,
          transform: 'matrix(1, 0, 0, -1, 0, 0)',
        }}
      />
    </Box>
  );
};

export default DesktopExportDataItem;
