import { t, Trans } from '@lingui/macro';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import { DashboardSettingContext } from '../../Setting/DashboardSettingProvider';
import LogoutIcon from '@mui/icons-material/Logout';
import { MobileSwitch } from '../components/MobileSwitch';

const MobileDashboardSettings = () => {
  const { logout } = useContext(AuthContext);
  const {
    userSetting,
    changeUserSettings,
    currentDashboardOrder,
    changeDashboardOrder,
  } = useContext(DashboardSettingContext);

  return (
    <Box mb={2}>
      <Box my={2}>
        <Typography variant="caption" color="text.primary">
          <Trans>appearance</Trans>
        </Typography>
      </Box>
      <Box>
        <TextField
          select
          value={currentDashboardOrder}
          fullWidth
          size="small"
          sx={{
            mb: 1,
            '& label.MuiInputLabel-root': {
              fontSize: '1rem',
              color: 'text.primary',
            },
          }}
          variant="outlined"
          label={t`View`}
          disabled={!userSetting.dashboardOrder2?.length}
          onChange={(event) => {
            changeDashboardOrder(event.target.value);
          }}
          SelectProps={{
            multiple: false,
          }}
        >
          <MenuItem value="dashboardOrder">{t`View 1`}</MenuItem>
          <MenuItem value="dashboardOrder2">{t`View 2`}</MenuItem>
        </TextField>
        <MobileSwitch
          value={userSetting.singleLineLayout}
          label={t`Single-line layout`}
          onChange={(checked) =>
            changeUserSettings({ singleLineLayout: Boolean(checked) })
          }
        />
        <MobileSwitch
          value={userSetting.svTheme === 'dark'}
          label={t`Dark mode`}
          onChange={(checked) =>
            changeUserSettings({ svTheme: checked ? 'dark' : 'light' })
          }
        />
        <MobileSwitch
          value={!!userSetting?.anonymized}
          label={t`Anonymized weight`}
          onChange={(checked) => changeUserSettings({ anonymized: checked })}
        />
        <MobileSwitch
          value={!!userSetting?.showOwnedBeds}
          label={t`Show all beds`}
          onChange={(checked) => changeUserSettings({ showOwnedBeds: checked })}
        />
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box py={2}>
        <Typography variant="caption" color="text.primary">
          <Trans>account</Trans>
        </Typography>
      </Box>
      <Button
        sx={{ textTransform: 'capitalize', color: 'text.primary' }}
        onClick={logout}
        startIcon={<LogoutIcon />}
      >
        <Trans>Logout</Trans>
      </Button>
    </Box>
  );
};

export default MobileDashboardSettings;
