import { t } from '@lingui/macro';

export enum DATAPOINT_TYPE {
  PERCENTAGE,
  COUNT_WITH_AVERAGE,
  VARIANCE,
  LOCATION,
}

/// TODO
export const getDatapointInfo = (datapointId: DatapointId) => {
  switch (datapointId) {
    case 'bedExitOn':
      return t`Percentage of time when was Bed Exit Monitoring On while bed was connected`;
    case 'bedExitAlarm':
      return t`Number of bed exit events. Event can only start at the start of an hour`;
    case 'connected':
      return t`Percentage of time when the bed was connected.`;
    case 'charging':
      return t`Percentage of time when the bed was charging while bed was connected.`;
    case 'isZoneExit':
      return t`Percentage of time when the Bed Exit Inner Zone is on while Bed Exit Monitoring was on.`;
    case 'posIsLowest':
      return t`Percentage of time when the bed is in lowest position while bed was connected.`;
    case 'safetyPosition':
      return t`Percentage of time when the bed is in safety position while bed was connected.`;
    case 'siderailHeadLUp':
      return t`Percentage of time when the siderail is up while bed was connected.`;
    case 'siderailHeadRUp':
      return t`Percentage of time when the siderail is up while bed was connected.`;
    case 'siderailMiddleLUp':
      return t`Percentage of time when the siderail is up while bed was connected.`;
    case 'siderailMiddleRUp':
      return t`Percentage of time when the siderail is up while bed was connected.`;
  }
  return '';
};

export const datapointTypeLabel = (datapoint: Datapoint) => {
  if (datapoint.type === DATAPOINT_TYPE.PERCENTAGE) {
    return t`Percentage time`;
  }
  if (datapoint.type === DATAPOINT_TYPE.VARIANCE) {
    return t`Start Value`;
  }
  if (datapoint.id === 'bedExitAlarm') {
    return t`Number of alarms`;
  }
  if (datapoint.id === 'brakes') {
    return t`Number of brakes off`;
  }
  return '';
};

export const getUnit = (datapoint: Datapoint) => {
  if (datapoint.id === 'weight') {
    return t`kg`;
  }
  if (datapoint.id === 'bedExitAlarm') {
    return '';
  }
  if (datapoint.id === 'brakes') {
    return '';
  }
  if (datapoint.type === DATAPOINT_TYPE.PERCENTAGE) {
    return '%';
  }
  if (datapoint.type === DATAPOINT_TYPE.VARIANCE) {
    return '°';
  }
  return '';
};
export const getSecondaryUnit = (datapoint: Datapoint) => {
  if (datapoint.id === 'weight') {
    return t`kg`;
  }
  if (datapoint.id === 'bedExitAlarm') {
    return t`min`;
  }
  if (datapoint.id === 'brakes') {
    return t`min`;
  }
  if (datapoint.type === DATAPOINT_TYPE.VARIANCE) {
    return '°';
  }
  return '';
};
export const getDatapointLabel = (datapointId: DatapointId) => {
  switch (datapointId) {
    case 'backrest':
      return t`Backrest Angle`;
    case 'bedExitOn':
      return t`Bed Exit On`;
    case 'bedExitAlarm':
      return t`Bed Exit Alarm`;
    case 'connected':
      return t`Connected`;
    case 'brakes':
      return t`Breaks off`;
    case 'calfrest':
      return t`Calfrest Angle`;
    case 'charging':
      return t`Power charging`;
    case 'isZoneExit':
      return t`Bed Exit Zone Inner`;
    case 'lateralTiltDeg':
      return t`Lateral Tilt`;
    case 'posIsLowest':
      return t`Lowest position`;
    case 'safetyPosition':
      return t`Safety Position`;
    case 'siderailHeadLUp':
      return t`Left Head Siderail Up`;
    case 'siderailHeadRUp':
      return t`Right Head Siderail Up`;
    case 'siderailMiddleLUp':
      return t`Left Siderail Up`;
    case 'siderailMiddleRUp':
      return t`Right Siderail Up`;
    case 'trendelenburgDeg':
      return t`Trendelenburg Angle`;
    case 'weight':
      return t`Weight`;
    case 'parkingPlace':
      return t`Location`;
  }
};

export const datapointTypeSecondaryLabel = (datapointType: DATAPOINT_TYPE) => {
  switch (datapointType) {
    case DATAPOINT_TYPE.PERCENTAGE:
      return '';
    case DATAPOINT_TYPE.VARIANCE:
      return t`Variance`;
    case DATAPOINT_TYPE.COUNT_WITH_AVERAGE:
      return t`Duration`;
    case DATAPOINT_TYPE.LOCATION:
      return '';
  }
};

export type DatapointId =
  | 'connected'
  | 'bedExitOn'
  | 'bedExitAlarm'
  | 'weight'
  | 'lateralTiltDeg'
  | 'trendelenburgDeg'
  | 'backrest'
  | 'calfrest'
  | 'charging'
  | 'siderailHeadRUp'
  | 'siderailHeadLUp'
  | 'siderailMiddleRUp'
  | 'siderailMiddleLUp'
  | 'posIsLowest'
  | 'brakes'
  | 'isZoneExit'
  | 'safetyPosition'
  | 'parkingPlace';

export interface Datapoint {
  id: DatapointId;
  type: DATAPOINT_TYPE;
}

export const DATAPOINTS: Datapoint[] = [
  { id: 'connected', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'bedExitOn', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'bedExitAlarm', type: DATAPOINT_TYPE.COUNT_WITH_AVERAGE },
  { id: 'weight', type: DATAPOINT_TYPE.VARIANCE },
  { id: 'lateralTiltDeg', type: DATAPOINT_TYPE.VARIANCE },
  { id: 'trendelenburgDeg', type: DATAPOINT_TYPE.VARIANCE },
  { id: 'backrest', type: DATAPOINT_TYPE.VARIANCE },
  { id: 'calfrest', type: DATAPOINT_TYPE.VARIANCE },
  { id: 'charging', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'siderailHeadRUp', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'siderailHeadLUp', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'siderailMiddleRUp', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'siderailMiddleLUp', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'posIsLowest', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'brakes', type: DATAPOINT_TYPE.COUNT_WITH_AVERAGE },
  { id: 'isZoneExit', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'safetyPosition', type: DATAPOINT_TYPE.PERCENTAGE },
  { id: 'parkingPlace', type: DATAPOINT_TYPE.LOCATION },
];
