import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import { IconButton } from '@mui/material';
import Flex from 'src/components/Flex';
import { FilterParameter, ReportFilterParams, ReportGraphType } from '../types';
import { DateOptionPreset } from 'src/lib/datePresetHelper';
import { UpdateFilterFunction } from './useReportFilterParams';

interface Props {
  filter: ReportFilterParams;
  updateFilter: UpdateFilterFunction;
}

const getAvailableGraphTypes = (filter: ReportFilterParams) => {
  if (filter.period.preset === DateOptionPreset.now) {
    return {
      scorecard:
        filter.parameter === FilterParameter.OCCUPANCY ||
        filter.parameter === FilterParameter.BACKREST_ANGLE ||
        filter.parameter === FilterParameter.WEIGHT,
    };
  }
  return {
    barHorizontal: true,
    barVertical: true,
    line: true,
    pie: true,
  };
};

const ReportGraphTypeSelect = ({ filter, updateFilter }: Props) => {
  const handleChangeClick = (type: ReportGraphType) => () => {
    updateFilter({ graphType: type });
  };
  const availableGraphTypes = getAvailableGraphTypes(filter);
  return (
    <Flex>
      <IconButton
        onClick={handleChangeClick('bar-horizontal')}
        color={filter.graphType === 'bar-horizontal' ? 'primary' : undefined}
        disabled={!availableGraphTypes.barHorizontal}
      >
        <BarChartIcon />
      </IconButton>
      <IconButton
        onClick={handleChangeClick('bar-vertical')}
        color={filter.graphType === 'bar-vertical' ? 'primary' : undefined}
        disabled={!availableGraphTypes.barVertical}
      >
        <BarChartIcon sx={{ transform: 'rotate(90deg)' }} />
      </IconButton>
      <IconButton
        onClick={handleChangeClick('line')}
        color={filter.graphType === 'line' ? 'primary' : undefined}
        disabled={!availableGraphTypes.line}
      >
        <StackedLineChartIcon />
      </IconButton>
      <IconButton
        onClick={handleChangeClick('pie')}
        color={filter.graphType === 'pie' ? 'primary' : undefined}
        disabled={!availableGraphTypes.pie}
      >
        <PieChartIcon />
      </IconButton>
      <IconButton
        onClick={handleChangeClick('scorecard')}
        color={filter.graphType === 'scorecard' ? 'primary' : undefined}
        disabled={!availableGraphTypes.scorecard}
      >
        <LooksOneOutlinedIcon />
      </IconButton>
    </Flex>
  );
};

export default ReportGraphTypeSelect;
