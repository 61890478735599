import { t } from '@lingui/macro';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { InputProps, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent } from 'react';

type Props = TextFieldProps & {
  onValueChange?: (value: string) => void;
};

const SearchField = ({
  onValueChange,
  onChange,
  InputProps,
  value,
  ...textFieldProps
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (onValueChange) {
      onValueChange(e.target.value);
    }
  };

  const SearchInputProps: Partial<InputProps> = {
    endAdornment: value ? (
      <ClearIcon
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => onValueChange && onValueChange('')}
      />
    ) : (
      <SearchIcon />
    ),
    ...InputProps,
  };

  return (
    <TextField
      placeholder={t`Search`}
      onChange={handleChange}
      size="small"
      value={value}
      {...textFieldProps}
      InputProps={SearchInputProps}
    />
  );
};
export default SearchField;
