import { Box } from '@mui/material';
import { ParkingPlacesQuery_room_parkingPlaces as ParkingPlace } from 'src/graphql/types';
import ParkingPlaceCard from '../RoomNew/ParkingPlaceCard';

interface Props {
  data: ParkingPlace;
  onRemoveClick: () => Promise<void>;
}

const HospitalLayoutParkingPlaceCard = ({ data, onRemoveClick }: Props) => {
  return (
    <Box m={1}>
      <ParkingPlaceCard
        id={data.id}
        name={data.name || ''}
        bedUnitId={data.bed?.unitId || ''}
        bedName={data.bed?.name || ''}
        ipAddressEthernet={data?.ipAddressEthernet}
        bedState={data.bed?.state}
        tagId={data.tagId}
        mode={data.mode}
        removeParkingPlace={onRemoveClick}
      />
    </Box>
  );
};

export default HospitalLayoutParkingPlaceCard;
