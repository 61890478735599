import { Box, Typography, styled, useTheme } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import {
  StatisticsDataType,
  getStatisticsTranslations,
  StatisticsDataValuesType,
  StatisticsType,
  getStatisticsValuesText,
} from '../../helpers/statistics';
import { COLORS } from '../../helpers/statistics';
import Flex from 'src/components/Flex';

interface Props {
  pieData: StatisticsDataType;
}

const StatisticsItem = ({ pieData }: Props) => {
  return (
    <Container m={1}>
      <TextBox>
        <Typography align="center" sx={{ maxWidth: '130px' }}>
          {getStatisticsTranslations(pieData.type)}
        </Typography>
      </TextBox>
      <ValueBoxWrapper top="40px" left="20px" textAlign="right">
        <ValueBox type={pieData.type} data={pieData.values[0]} />
      </ValueBoxWrapper>
      <ValueBoxWrapper top="40px" right="20px">
        <ValueBox
          variant="second"
          type={pieData.type}
          data={pieData.values[1]}
        />
      </ValueBoxWrapper>
      {pieData.type === StatisticsType.bedExitMonitorStatus && (
        <ValueBoxWrapper bottom={40} right={20}>
          <ValueBox
            variant="third"
            type={pieData.type}
            data={pieData.values[2]}
          />
        </ValueBoxWrapper>
      )}
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={pieData.values}
            cx="50%"
            startAngle={90}
            endAngle={450}
            cy="50%"
            isAnimationActive={false}
            innerRadius={70}
            outerRadius={80}
            dataKey="value"
          >
            {pieData.values.map((_, index) => (
              <Cell
                stroke="none"
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default StatisticsItem;

interface ValueBoxProps {
  data: StatisticsDataValuesType;
  type: StatisticsType;
  variant?: 'second' | 'third';
}

const ValueBox = ({ data, type, variant }: ValueBoxProps) => {
  const theme = useTheme();
  const color =
    variant === 'second'
      ? theme.palette.success.light
      : variant === 'third'
      ? theme.palette.warning.main
      : theme.palette.grey[500];
  return (
    <>
      {data && (
        <Box width={120}>
          <Box
            display="flex"
            justifyContent={!!variant ? 'flex-start' : 'flex-end'}
            alignItems="center"
          >
            <Box
              sx={{
                width: '12px',
                height: '12px',
                borderRadius: '3px',
                backgroundColor: color,
              }}
            />
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontSize: '0.75rem',
                fontWeight: 300,
                marginLeft: '8px',
              }}
              color="textPrimary"
            >
              {getStatisticsValuesText(type, variant)}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '1.875rem',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
            }}
          >
            {`${data.value} %`}
          </Typography>
          <Typography sx={{ fontSize: '0.625rem', fontWeight: 500 }}>
            {data.time}
          </Typography>
        </Box>
      )}
    </>
  );
};

const Container = styled(Box)`
  position: relative;
  border-radius: 10px;
  width: calc(50% - 16px);
  height: 279px;
  background-color: ${(props) => props.theme.palette.grey[200]};
`;

const ValueBoxWrapper = styled(Box)`
  position: absolute;
`;

const TextBox = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
