import { useQuery } from '@apollo/client';
import { FilterValue } from 'src/components/Filters';
import { bedFiltersQuery } from 'src/graphql/queries';
import { BedFilters, BedFiltersVariables } from 'src/graphql/types';

export const ROOM_FILTER = 'roomFilter';

export type FilterProps = {
  name: FilterValue | null;
  workspace: FilterValue | null;
  ward: FilterValue | null;
};

export const useRoomFilterOption = (filter: FilterProps) => {
  const { data } = useQuery<BedFilters, BedFiltersVariables>(bedFiltersQuery, {
    variables: {
      workspaceIds: filter.workspace?.id ? [filter.workspace.id] : undefined,
    },
  });

  return {
    nameOptions: data?.rooms || [],
    wardOptions: data?.wards || [],
    workspaceOptions: data?.workspaces || [],
  };
};
