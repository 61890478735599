import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import {
  Button,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {
  ListPageContent,
  ListPageRoot,
  TableWrapper,
  TopBar,
} from 'src/components/AdminList';
import Flex from 'src/components/Flex';
import { TableHeader } from 'src/components/Table';
import { addVirtualBed, deleteBedsMutation } from 'src/graphql/mutations';
import {
  ActiveFilters,
  FilterValue,
  useFilter,
} from '../../../components/Filters';
import { beds, meQuery } from '../../../graphql/queries';
import {
  bedAddedSubscription,
  bedUpdatedSubscription,
} from '../../../graphql/subscriptions';
import {
  addVirtualBed as AddVirtualBedQuery,
  bedsQuery,
  bedsQuery_beds,
  DeleteBedsMutation,
  DeleteBedsMutationVariables,
  me,
  UserRole,
} from '../../../graphql/types';
import { getSortState, SortKey, useSorting } from '../../../lib/sortingHook';
import BedItem from './BedItem';
import { BED_FILTER, FilterProps, useBedFilterOption } from './filterOptions';
import { ConfirmModal } from 'src/components/ConfirmModal';

const WAYPOINT_OFFSET = 5;
const ROWS_INCREMENT = 30;

const Beds = () => {
  const navigate = useNavigate();
  const { sorting, setSort } = useSorting();
  const getSortingState = (key: SortKey) => getSortState(sorting, key);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_INCREMENT);
  const { data: meData } = useQuery<me>(meQuery);
  const [addVirtualBedAction] = useMutation<AddVirtualBedQuery>(addVirtualBed);
  const { data: bedData, subscribeToMore } = useQuery<bedsQuery>(beds);
  const [allBedSelected, setAllBedSelected] = useState(false);
  const [selectedBeds, setSelectedBeds] = useState<string[]>([]);
  const [deleteBeds] = useMutation<
    DeleteBedsMutation,
    DeleteBedsMutationVariables
  >(deleteBedsMutation);
  const [deleteBedsConfirmation, setDeleteBedsConfirmation] = useState(false);

  const { filter, activeFilters, setFilterValue, clear, clearAll } =
    useFilter<FilterProps>({
      name: BED_FILTER,
      types: {
        bedId: t`INTEGR. MODULE ID`,
        bedName: t`BED NAME`,
        workspace: t`WORKSPACE`,
        mode: `LOCATION SOURCE`,
        ward: t`WARD`,
        room: t`ROOM`,
        status: t`NETWORK`,
      },
    });

  const {
    bedIdOptions,
    bedNameOptions,
    workspaceOptions,
    wardOptions,
    roomOptions,
    statusOptions,
    locationSourceOptions,
  } = useBedFilterOption(filter, bedData?.beds);

  useEffect(() => {
    const unsubUpdated = subscribeToMore({
      document: bedUpdatedSubscription,
    });
    const unsubAdded = subscribeToMore({
      document: bedAddedSubscription,
    });

    return () => {
      unsubUpdated && unsubUpdated();
      unsubAdded && unsubAdded();
    };
  }, [subscribeToMore]);

  const addSelectedBed = (unitId: string) => {
    if (selectedBedsByUnitId[unitId]) {
      setSelectedBeds((prev) =>
        prev.filter((bedUnitId) => bedUnitId !== unitId),
      );
    } else {
      setSelectedBeds((prev) => [...prev, unitId]);
    }
  };

  const onAllBedSelectChanged = () => {
    if (allBedSelected) {
      setSelectedBeds([]);
    } else {
      setSelectedBeds(filteredBeds.map((bed) => bed.unitId));
    }
    setAllBedSelected((prev) => !prev);
  };

  const confirmDeleteBeds = () => {
    deleteSelectedBeds();
    setDeleteBedsConfirmation(false);
  };

  const selectedBedsByUnitId = useMemo(() => {
    return selectedBeds.reduce((res, unitId) => {
      res[unitId] = true;
      return res;
    }, {});
  }, [selectedBeds]);

  const getFilteredBeds = (beds: bedsQuery_beds[]) => {
    const result = beds
      .filter((bed) => {
        return (
          (filter.workspace
            ? bed?.workspace && bed.workspace.id === filter.workspace.id
            : true) &&
          (filter.ward ? bed?.ward && bed.ward.id === filter.ward.id : true) &&
          (filter.bedId
            ? bed?.unitId && bed.unitId === filter.bedId.id
            : true) &&
          (filter.bedName
            ? bed?.name && bed.name === filter.bedName.id
            : true) &&
          (filter.room ? bed?.room && bed.room.id === filter.room.id : true) &&
          (filter.mode
            ? bed?.parkingPlaces?.[0]?.mode === filter.mode.id
            : true) &&
          (filter.room ? bed?.room && bed.room.id === filter.room.id : true) &&
          (filter.status ? bed?.state && bed.state === filter.status.id : true)
        );
      })
      .sort(sorting ? sorting.sortFn : undefined);

    return result;
  };

  const handleAddVirtualBed = async () => {
    const queryData = await addVirtualBedAction();
    if (!queryData || !queryData.data || !queryData.data.addVirtualBed) {
      return;
    }
    const unitId = queryData.data.addVirtualBed.unitId;
    if (!unitId) {
      return;
    }
    navigate(`/admin/beds/${unitId}/edit`);
  };

  const canAdjustVB = Boolean(
    meData &&
      meData.me &&
      meData.me.role === UserRole.ADMIN &&
      meData.me.vbAccess,
  );

  const filteredBeds = getFilteredBeds(bedData?.beds || []);

  const bedsOnPage = useMemo(
    () => filteredBeds.slice(0, rowsPerPage),
    [filteredBeds, rowsPerPage],
  );

  const loadMore = () => {
    setRowsPerPage((prev) => prev + ROWS_INCREMENT);
  };

  const deleteSelectedBeds = async () => {
    await deleteBeds({
      variables: {
        bedUnitIds: selectedBeds,
      },
      refetchQueries: () => [{ query: beds }],
    });
  };

  return (
    <>
      <ActiveFilters
        filters={activeFilters}
        clear={clear}
        clearAll={clearAll}
      />
      <Flex
        justifyContent="space-between"
        flexDirection="row-reverse"
        alignItems="center"
        pl={2}
        mt={2}
        mr={5}
        ml={3}
        minHeight="40px"
        fontWeight={700}
      >
        <Trans>Results: {filteredBeds.length}</Trans>
        {selectedBeds.length > 0 ? (
          <Button
            variant="text"
            startIcon={<DeleteIcon />}
            onClick={() => setDeleteBedsConfirmation(true)}
          >{t`Delete`}</Button>
        ) : null}
      </Flex>
      <Divider sx={{ marginTop: 2 }} />
      <ListPageRoot>
        <TopBar>
          {canAdjustVB ? (
            <Button
              sx={{ marginLeft: '1rem' }}
              startIcon={<AddIcon />}
              onClick={handleAddVirtualBed}
              color="primary"
              variant="contained"
            >
              <Trans>Add virtual bed</Trans>
            </Button>
          ) : null}
        </TopBar>
        {bedData && bedData.beds ? (
          <ListPageContent>
            <TableWrapper>
              <Table stickyHeader sx={{ minWidth: 500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={allBedSelected}
                        onChange={onAllBedSelectChanged}
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        id="bedName"
                        sort={() => setSort(SortKey.BEDNAME)}
                        sortState={() => getSortingState(SortKey.BEDNAME)}
                        label={t`BED NAME`}
                        options={bedNameOptions}
                        value={filter.bedName}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('bedName', value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        id="workspace"
                        sort={() => setSort(SortKey.WORKSPACE)}
                        sortState={() => getSortingState(SortKey.WORKSPACE)}
                        label={t`WORKSPACE`}
                        options={workspaceOptions}
                        value={filter.workspace}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('workspace', value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        id="ward"
                        sort={() => setSort(SortKey.WARD)}
                        sortState={() => getSortingState(SortKey.WARD)}
                        label={t`WARD`}
                        options={wardOptions}
                        value={filter.ward}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('ward', value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        id="room"
                        sort={() => setSort(SortKey.ROOM)}
                        sortState={() => getSortingState(SortKey.ROOM)}
                        label={t`ROOM`}
                        options={roomOptions}
                        value={filter.room}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('room', value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        id="mode"
                        sx={{
                          justifyContent: 'center',
                        }}
                        sort={() => setSort(SortKey.MODE)}
                        sortState={() => getSortingState(SortKey.MODE)}
                        label={t`LOCATION SOURCE`}
                        options={locationSourceOptions}
                        value={filter.mode}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('mode', value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        id="bedId"
                        sort={() => setSort(SortKey.UNITID)}
                        sortState={() => getSortingState(SortKey.UNITID)}
                        label={t`INTEGR. MODULE ID`}
                        options={bedIdOptions}
                        value={filter.bedId}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('bedId', value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader
                        id="status"
                        sx={{
                          justifyContent: 'center',
                        }}
                        sort={() => setSort(SortKey.BED_STATUS)}
                        sortState={() => getSortingState(SortKey.BED_STATUS)}
                        label={t`NETWORK`}
                        options={statusOptions}
                        value={filter.status}
                        setValue={(value: FilterValue | null) =>
                          setFilterValue('status', value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TableHeader id="hl7" label={t`HL7 LOG`} />
                    </TableCell>
                    <TableCell>
                      <TableHeader id="insight" label={t`INSIGHT`} />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bedData.beds.length !== 0 ? (
                    bedsOnPage.map((bed, idx, beds) => (
                      <BedItem
                        bed={bed}
                        key={bed.unitId}
                        selected={Boolean(selectedBedsByUnitId[bed.unitId])}
                        onSelectionChanged={() => addSelectedBed(bed.unitId)}
                        waypoint={
                          beds.length - WAYPOINT_OFFSET === idx &&
                          beds.length < filteredBeds.length ? (
                            <Waypoint onEnter={loadMore} />
                          ) : null
                        }
                        canAdjustVirtual={canAdjustVB}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Trans>No items</Trans>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableWrapper>
          </ListPageContent>
        ) : null}
      </ListPageRoot>
      <ConfirmModal
        open={deleteBedsConfirmation}
        onClose={() => setDeleteBedsConfirmation(false)}
        title={t`Delete beds`}
        message={
          <Trans>
            Do you really want to delete Beds {selectedBeds.join(', ')}?
          </Trans>
        }
        onConfirm={confirmDeleteBeds}
      />
    </>
  );
};

export default Beds;
