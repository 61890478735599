import { useMutation, useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import {
  ListPageContent,
  ListPageRoot,
  TableWrapper,
} from 'src/components/AdminList';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { ActiveFilters, FilterValue, useFilter } from 'src/components/Filters';
import { TableHeader } from 'src/components/Table';
import { deleteTagMutation } from 'src/graphql/mutations';
import { tagsQuery } from '../../../graphql/queries';
import {
  DeleteTag,
  DeleteTagVariables,
  TagsQuery,
  TagsQuery_tags,
} from '../../../graphql/types';
import {
  getSortState,
  NUMBER_COMPARATOR,
  SortKey,
  STRING_COMPARATOR,
  useCustomSorting,
} from '../../../lib/sortingHook';
import { CircularButtonContainer } from '../components/CircularButtonContainer';
import { BatteryStatus, getBatteryStatus } from './BatteryStatus';
import { FilterProps, TAG_FILTER, useTagFilterOption } from './filterOptions';

const evaluators = {
  [SortKey.ID]: (tag: TagsQuery_tags) => tag?.id || 0,
  [SortKey.BEDNAME]: (tag: TagsQuery_tags) => tag?.bed?.name || '',
  [SortKey.ROOM]: (tag: TagsQuery_tags) => tag.parkingPlace?.room?.name || '',
  [SortKey.WARD]: (tag: TagsQuery_tags) =>
    tag.parkingPlace?.room?.ward?.name || '',
  [SortKey.WORKSPACE]: (tag: TagsQuery_tags) =>
    tag.parkingPlace?.room?.ward?.workspace?.name || '',
  [SortKey.CHARGE]: (tag: TagsQuery_tags) => tag.charge || 0,
};

const comparators = {
  [SortKey.ID]: NUMBER_COMPARATOR,
  [SortKey.BEDNAME]: STRING_COMPARATOR,
  [SortKey.ROOM]: STRING_COMPARATOR,
  [SortKey.WARD]: STRING_COMPARATOR,
  [SortKey.WORKSPACE]: STRING_COMPARATOR,
  [SortKey.CHARGE]: NUMBER_COMPARATOR,
};

const WAYPOINT_OFFSET = 5;
const ROWS_INCREMENT = 30;

const Tags = () => {
  const { sorting, setSort } = useCustomSorting<TagsQuery_tags>(
    evaluators,
    comparators,
  );
  const getSortingState = (key: SortKey) => getSortState(sorting, key);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_INCREMENT);
  const { data } = useQuery<TagsQuery>(tagsQuery);
  const [deleteTag] = useMutation<DeleteTag, DeleteTagVariables>(
    deleteTagMutation,
  );
  const [tagToDelete, setTagToDelete] = useState<null | string>(null);

  const { filter, activeFilters, setFilterValue, clear, clearAll } =
    useFilter<FilterProps>({
      name: TAG_FILTER,
      types: {
        bedName: t`BED NAME`,
        workspace: t`WORKSPACE`,
        ward: t`WARD`,
        room: t`ROOM`,
        tag: t`TAG`,
        batteryStatus: t`BATTERY STATUS`,
      },
    });

  const {
    bedNameOptions,
    workspaceOptions,
    wardOptions,
    roomOptions,
    tagOptions,
    statusOptions,
  } = useTagFilterOption(filter, data?.tags || []);

  useEffect(() => {
    if (sessionStorage.length > 0) {
      setRowsPerPage(
        (prevState) => Number(sessionStorage.rowsPerPage) || prevState,
      );
    }
  }, []);

  const getSortedTags = (tags?: TagsQuery_tags[]) => {
    if (!tags) {
      return [];
    }
    const result = tags.filter((tag) => {
      return (
        (filter.tag ? filter.tag.id === tag.id : true) &&
        (filter.room ? filter.room.id === tag.parkingPlace?.room.id : true) &&
        (filter.ward
          ? filter.ward.id === tag.parkingPlace?.room?.ward.id
          : true) &&
        (filter.workspace
          ? filter.workspace.id === tag.parkingPlace?.room?.ward?.workspace?.id
          : true) &&
        (filter.bedName ? filter.bedName.id === tag.bed?.name : true) &&
        (filter.batteryStatus
          ? filter.batteryStatus.id === getBatteryStatus(tag.charge)
          : true)
      );
    });

    if (sorting) {
      return result.sort(sorting.sortFn);
    }
    return result;
  };

  const loadMore = () => {
    setRowsPerPage((prev) => prev + ROWS_INCREMENT);
  };

  const handleDeleteTag = async () => {
    if (!tagToDelete) {
      return;
    }
    await deleteTag({
      variables: {
        tagId: tagToDelete,
      },
      refetchQueries: () => [{ query: tagsQuery }],
    });
    setTagToDelete(null);
  };

  const sortedTags = getSortedTags(data && data.tags);
  const tagsOnPage = sortedTags.slice(0, rowsPerPage);

  return (
    <>
      <ActiveFilters
        filters={activeFilters}
        clear={clear}
        clearAll={clearAll}
      />
      <ListPageRoot>
        <ListPageContent>
          <TableWrapper>
            <Table
              stickyHeader
              sx={{
                minWidth: '500px',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableHeader
                      id="tag"
                      sort={() => setSort(SortKey.ID)}
                      sortState={() => getSortingState(SortKey.ID)}
                      label={t`TAG`}
                      options={tagOptions}
                      value={filter.tag}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('tag', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="room"
                      sort={() => setSort(SortKey.ROOM)}
                      sortState={() => getSortingState(SortKey.ROOM)}
                      label={t`ROOM`}
                      options={roomOptions}
                      value={filter.room}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('room', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="bedName"
                      sort={() => setSort(SortKey.BEDNAME)}
                      sortState={() => getSortingState(SortKey.BEDNAME)}
                      label={t`BED NAME`}
                      options={bedNameOptions}
                      value={filter.bedName}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('bedName', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="workspace"
                      sort={() => setSort(SortKey.WORKSPACE)}
                      sortState={() => getSortingState(SortKey.WORKSPACE)}
                      label={t`WORKSPACE`}
                      options={workspaceOptions}
                      value={filter.workspace}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('workspace', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="ward"
                      sort={() => setSort(SortKey.WARD)}
                      sortState={() => getSortingState(SortKey.WARD)}
                      label={t`WARD`}
                      options={wardOptions}
                      value={filter.ward}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('ward', value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TableHeader
                      id="batteryStatus"
                      label={t`BATTERY STATUS`}
                      sort={() => setSort(SortKey.CHARGE)}
                      sortState={() => getSortingState(SortKey.CHARGE)}
                      options={statusOptions}
                      value={filter.batteryStatus}
                      setValue={(value: FilterValue | null) =>
                        setFilterValue('batteryStatus', value)
                      }
                    />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {tagsOnPage.map((tag, idx, tags) => (
                  <TableRow hover key={tag.id}>
                    <TableCell>{tag.id}</TableCell>
                    <TableCell>
                      {tag?.parkingPlace?.room?.name}
                      {tags.length - WAYPOINT_OFFSET === idx &&
                      tags.length < sortedTags.length ? (
                        <Waypoint onEnter={loadMore} />
                      ) : null}
                    </TableCell>
                    <TableCell>{tag?.bed?.name}</TableCell>
                    <TableCell>
                      {tag?.parkingPlace?.room?.ward?.workspace?.name}
                    </TableCell>
                    <TableCell>{tag?.parkingPlace?.room?.ward?.name}</TableCell>
                    <TableCell>
                      <BatteryStatus charge={tag?.charge} />
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      <CircularButtonContainer>
                        <IconButton
                          sx={{ color: 'currentColor' }}
                          size="small"
                          onClick={() => setTagToDelete(tag?.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CircularButtonContainer>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </ListPageContent>
        <ConfirmModal
          title={t`Delete tag`}
          message={t`Are you sure you want to delete tag ${tagToDelete}?`}
          confirmButtonText={t`Delete tag`}
          cancelButtonText={t`Cancel`}
          onConfirm={handleDeleteTag}
          open={!!tagToDelete}
          onClose={() => setTagToDelete(null)}
        />
      </ListPageRoot>
    </>
  );
};

export default Tags;
