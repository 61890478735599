import { useTheme } from '@mui/material';
import { indexBy } from 'ramda';
import { useContext, useMemo } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AuthContext } from 'src/auth/AuthProvider';
import { WeightUnits } from 'src/graphql/types';
import useDateFormat from 'src/lib/useDateFormat';
import { convertToPounds } from 'src/lib/weightConverter';
import { ReportData, ReportFilterParams } from '../types';
import {
  getReportTag,
  getReportValueForTime,
  prepareGraphTimeTicks,
} from './graphHelpers';
import useGraphColors from './useGraphColors';
import useGraphTimeAxisFormat from './useGraphTimeAxisFormat';
import useReportTagNames from './useReportTagNames';

interface Props {
  data: ReportData[];
  filter: ReportFilterParams;
}

const ReportGraphLine = ({ data, filter }: Props) => {
  const { formatDuration } = useDateFormat();
  const { getColor } = useGraphColors();
  const theme = useTheme();
  const { config } = useContext(AuthContext);
  const isImperial = config?.config?.unitSystem === WeightUnits.IMPERIAL;
  const { formatTimeAxisLabel, getTimeAxisLabel } = useGraphTimeAxisFormat();
  const { resolveName } = useReportTagNames();

  const groupTimes = data
    .map((row) => row.values.map((val) => val.time))
    .flat()
    .sort(
      (time, otherTime) =>
        new Date(time).getTime() - new Date(otherTime).getTime(),
    )
    .filter((val, idx, self) => self.indexOf(val) === idx);
  const unit = data?.[0]?.values?.[0]?.unit || '';
  const tags = indexBy((row) => getReportTag(row.tags) || '', data);

  const graphData = groupTimes.map((time) => ({
    time: formatTimeAxisLabel(time, filter),
    ...Object.keys(tags)
      .filter((key) => !!key)
      .reduce(
        (result, tag) => ({
          ...result,
          unit: getReportValueForTime(time, 'unit')(tags[tag]),
          [tag]: getReportValueForTime(time, 'value')(tags[tag]),
        }),
        {},
      ),
  }));

  const maxValue = Math.max(
    ...graphData.map((val: Record<string, number | string>) =>
      Math.max(
        ...Object.values(val).map((val) => (typeof val === 'number' ? val : 0)),
      ),
    ),
  );

  const ticks = useMemo(
    () => (unit === 'seconds' ? prepareGraphTimeTicks(maxValue) : undefined),
    [maxValue, unit],
  );

  const formatValue = (val: string | number) => {
    if (unit === 'seconds') {
      return formatDuration(Number(val) * 1000);
    }
    if (!unit || unit === 'count') {
      return `${val}`;
    }
    if (unit === 'Kg' && isImperial) {
      return `${convertToPounds(val)} lb`;
    }
    return `${val} ${unit}`;
  };

  return (
    <ResponsiveContainer height={320} width="100%">
      <LineChart
        layout="horizontal"
        data={graphData}
        margin={{ left: 50, right: 50, bottom: 20 }}
      >
        <XAxis
          dataKey="time"
          label={{
            value: getTimeAxisLabel(filter),
            dy: 16,
            fill: theme.palette.text.primary,
          }}
        />
        <YAxis
          type="number"
          scale="linear"
          ticks={ticks}
          tickFormatter={formatValue}
          padding={{ top: 10 }}
        />
        <Legend
          align="right"
          verticalAlign="top"
          layout="vertical"
          iconType="circle"
          wrapperStyle={{
            paddingLeft: 10,
            maxWidth: '180px',
            maxHeight: '260px',
            overflowY: 'auto',
          }}
        />
        <CartesianGrid vertical={false} strokeWidth={1} strokeDasharray="3" />
        <Tooltip
          allowEscapeViewBox={{ x: true }}
          formatter={formatValue}
          wrapperStyle={{ zIndex: 1000 }}
        />
        {Object.keys(tags).map((tag, idx) => (
          <Line
            key={tag}
            dataKey={tag}
            stroke={getColor(idx)}
            name={resolveName(tags[tag].tags)}
            strokeWidth={2}
            type="monotone"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ReportGraphLine;
