import { useState } from 'react';
import {
  Autocomplete,
  Box,
  IconButton,
  Popover,
  TextField,
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import { FilterValue } from './filters';
import { styled } from '@mui/material';

interface Props {
  id: string;
  options: FilterValue[];
  value: FilterValue | null;
  setValue: (value: FilterValue | null) => void;
}

export const FilterSelect = ({ id, options, value, setValue }: Props) => {
  const [filterAnchor, setFilterAnchor] = useState<HTMLButtonElement | null>(
    null,
  );

  return (
    <>
      <SelectedButton
        size="small"
        onClick={(event) => setFilterAnchor(event.currentTarget)}
        selected={!!value}
      >
        <FilterIcon />
      </SelectedButton>
      <Popover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={(event) => {
          setFilterAnchor(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            borderRadius: '10px',
          },
        }}
      >
        <Box sx={{ width: 200 }}>
          <Autocomplete
            id={id}
            options={options ?? []}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            value={value ?? null}
            getOptionLabel={(value) => value.name}
            onChange={(_, value) => setValue(value ?? null)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </Box>
      </Popover>
    </>
  );
};

const SelectedButton = styled(IconButton)<{ selected?: boolean }>(
  ({ theme: { spacing, palette }, selected }) => `
  color: ${palette.text.primary};
  ${
    selected
      ? `
    background-color: ${palette.primary.main};
    color: ${palette.primary.contrastText};
    :hover {
      background-color: ${palette.primary.dark};
    }
  `
      : null
  }
  margin-left: ${spacing(1)};
`,
);
