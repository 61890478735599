import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';

interface Props {
  type: 'left' | 'right';
  disabled?: boolean;
  handleClick: () => void;
}

export const Arrow = ({ type, disabled, handleClick }: Props) => {
  const onClick = () => {
    if (!disabled) {
      handleClick();
    }
  };
  const color = disabled ? 'lightgrey' : 'grey';
  return (
    <div style={{ ...styles.circle, borderColor: color }}>
      {type === 'left' ? (
        <ArrowBackIcon style={{ color, paddingRight: 3 }} onClick={onClick} />
      ) : (
        <ArrowForwardIcon style={{ color, paddingLeft: 3 }} onClick={onClick} />
      )}
    </div>
  );
};

const styles = {
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    borderWidth: 2,
    borderStyle: 'solid',
    width: 30,
    margin: '0 12px',
    height: 30,
    cursor: 'pointer',
  },
};
