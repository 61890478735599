import { Trans } from '@lingui/macro';
import { Button, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { useContext } from 'react';
import { BedData } from '../../helpers/dashboard';
import { MobileModalContext } from '../ModalOverlay/MobileModalProvider';

interface Props {
  bed: BedData;
  openNewSessionDailog: () => void;
  openDischargeDialog: () => void;
}

export const MobileButtonBar = ({
  bed,
  openNewSessionDailog,
  openDischargeDialog,
}: Props) => {
  const { showModal } = useContext(MobileModalContext);
  const bedWithoutSession = !bed.patientId;

  return (
    <>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Button
          fullWidth
          sx={{ marginRight: '0.5rem' }}
          disabled={bedWithoutSession}
          onClick={() => showModal('notes', bed.unitId)}
          variant="outlined"
          color="primary"
        >
          <Trans>Notes</Trans>
        </Button>
        <Button
          sx={{ marginLeft: '0.5rem' }}
          onClick={() => showModal('insight', bed.unitId)}
          variant="outlined"
          fullWidth
          color="primary"
        >
          <Trans>Insight</Trans>
        </Button>
      </Box>
      <Divider sx={{ borderColor: (theme) => theme.palette.grey[500] }} />
      <Box mt={2} display="flex">
        {bed?.patientId ? (
          <Button
            sx={{
              marginRight: '0.5rem',
            }}
            color="error"
            fullWidth
            variant="contained"
            onClick={openDischargeDialog}
          >
            <Trans>End session</Trans>
          </Button>
        ) : (
          <Button
            sx={{
              marginRight: '0.5rem',
            }}
            fullWidth
            variant="contained"
            onClick={openNewSessionDailog}
          >
            <Trans>Start session</Trans>
          </Button>
        )}
        <Button
          sx={{ marginLeft: '0.5rem' }}
          fullWidth
          disabled={bedWithoutSession}
          onClick={() => showModal('export', bed.unitId)}
          variant="contained"
        >
          <Trans>Export</Trans>
        </Button>
      </Box>
    </>
  );
};
