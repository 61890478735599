import { Trans } from '@lingui/macro';
import { Box, Typography, styled } from '@mui/material';
import Flex from 'src/components/Flex';
import {
  getDatapointSelectorItemText,
  mobilizationViewWidth,
  SummaryDataType,
} from '../../helpers/mobilization';

interface Props {
  summaryData: SummaryDataType[][] | null;
}

const SummaryBox = ({ summaryData }: Props) => {
  return (
    <Container>
      {summaryData &&
        summaryData.map((data, idx) => <SummaryItem data={data} key={idx} />)}
    </Container>
  );
};

export default SummaryBox;

interface SummaryBoxProps {
  data: SummaryDataType[];
}

const SummaryItem = ({ data }: SummaryBoxProps) => {
  return (
    <Box width={165} textAlign="center">
      {data.length > 0 ? (
        data.map((value) => (
          <Typography
            key={`summaryValue-${value.type}`}
            align="center"
            sx={{ fontSize: '0.625rem', whiteSpace: 'nowrap' }}
          >
            <strong>{value.time} </strong>{' '}
            {` ${getDatapointSelectorItemText(value.type)}`}
          </Typography>
        ))
      ) : (
        <Typography
          sx={{ fontSize: '0.625rem', fontWeight: 500 }}
          align="center"
          color="textSecondary"
        >
          <Trans>No Data</Trans>
        </Typography>
      )}
    </Box>
  );
};

const Container = styled(Flex)(
  ({ theme: { spacing, palette } }) => `
  height: 97px;
  width: ${mobilizationViewWidth}px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: ${spacing(2)};
  margin-left: ${spacing(4)};
  background-color: ${palette.grey[200]};
`,
);
