import Flex from 'src/components/Flex';
import { FilterValue } from '../Filters';
import { SortingState } from '../Sorting/SortingState';
import { FilterSearch } from '../Filters/FilterSearch';

interface Props {
  id: string;
  label: string;
  options?: FilterValue[];
  value?: FilterValue | null;
  setValue?: (value: FilterValue | null) => void;
  sort?: () => void;
  sortState?: () => any;
}

export const SearchTableHeader = ({
  id,
  label,
  value,
  setValue,
  sort,
  sortState,
}: Props) => {
  const sortEnabled = !!sort && !!sortState;
  const filterEnabled = !!setValue;
  return (
    <Flex alignItems="center">
      <Flex>
        <Flex
          alignItems="center"
          sx={{ cursor: sortEnabled ? 'pointer' : null }}
          onClick={sort}
        >
          {label}
          {sortEnabled && sortState ? (
            <SortingState state={sortState()} />
          ) : null}
        </Flex>
      </Flex>
      {filterEnabled && (
        <FilterSearch
          id={`${id}-filter`}
          value={value ?? null}
          setValue={setValue}
        />
      )}
    </Flex>
  );
};
