import { ReportTagFragment } from 'src/graphql/types';
import { ReportData } from '../types';

const DAY = 24 * 60 * 60;
const HOUR = 60 * 60;
const HALF_HOUR = 30 * 60;
const QUATER_HOUR = 15 * 60;
const MINUTE = 60;

export const prepareGraphTicks = (max: number, step: number) => {
  const ticks: number[] = [];
  do {
    ticks.push((ticks.length + 1) * step);
  } while (ticks[ticks.length - 1] < max);
  return ticks;
};

export const prepareGraphTimeTicks = (maxValueSeconds: number) => {
  const days = maxValueSeconds / DAY;
  const hours = maxValueSeconds / HOUR;
  const halfHours = maxValueSeconds / HALF_HOUR;
  const quaterHours = maxValueSeconds / QUATER_HOUR;
  const minutes = maxValueSeconds / MINUTE;

  if (days > 4) {
    const step = Math.ceil(days / 12) * DAY;
    return prepareGraphTicks(maxValueSeconds, step);
  }

  if (hours > 4) {
    const step = Math.ceil(hours / 12) * HOUR;
    return prepareGraphTicks(maxValueSeconds, step);
  }

  if (halfHours > 4) {
    const step = Math.ceil(halfHours / 12) * HALF_HOUR;
    return prepareGraphTicks(maxValueSeconds, step);
  }

  if (quaterHours > 4) {
    const step = Math.ceil(quaterHours / 12) * QUATER_HOUR;
    return prepareGraphTicks(maxValueSeconds, step);
  }
  if (minutes > 4) {
    const step = Math.ceil(minutes / 12) * MINUTE;
    return prepareGraphTicks(maxValueSeconds, step);
  }

  const step = Math.ceil(maxValueSeconds / 12);
  return prepareGraphTicks(maxValueSeconds, step);
};

export const getReportTag = (tags: ReportTagFragment) => {
  return (
    tags.parkingPlaceId ||
    tags.unitId ||
    tags.roomId ||
    tags.wardId ||
    tags.workspaceId ||
    tags.shift ||
    tags.type ||
    tags.custom
  );
};

export const getReportValueForTime =
  (time: string, field: string) => (report: ReportData) => {
    return report.values.find((val) => val.time === time)?.[field];
  };
