import { ConfigQuery_superConfig } from '../../graphql/types';

export interface SafetyPortFormValues {
  spDashboardLicenceExpiration?: string | null;
  spEnabled: boolean | null;
  spVersion?: string | null;
  spDashboardEnabled: boolean | null;
  spDashboardVersion?: string | null;
  patientEnabled: boolean | null;
  patientLicenceExpiration?: string | null;
  bedManagementEnabled: boolean | null;
  bedManagementLicenceExpiration?: string | null;
  respiratoryMobilizationEnabled: boolean | null;
  respiratoryMobilizationLicenceExpiration?: string | null;
  utilizationEnabled: boolean | null;
  utilizationLicenceExpiration?: string | null;
  syncCommunication: boolean;
  hl7LogEnabled: boolean;
  reportingModuleAccess?: boolean;
}

export const getSuperAdminInitState = (
  superConfig?: ConfigQuery_superConfig | null,
) => {
  return {
    spDashboardLicenceExpiration:
      superConfig && superConfig.spDashboardLicenceExpiration
        ? superConfig.spDashboardLicenceExpiration.substr(0, 10)
        : undefined,
    spEnabled: superConfig?.spEnabled || false,
    spVersion: superConfig?.spVersion || undefined,
    spDashboardEnabled: superConfig?.spDashboardEnabled || false,
    spDashboardVersion: superConfig?.spDashboardVersion || undefined,
    patientEnabled: superConfig?.patientEnabled || false,
    patientLicenceExpiration: superConfig?.patientLicenceExpiration
      ? superConfig.patientLicenceExpiration.substr(0, 10)
      : undefined,
    bedManagementEnabled: superConfig?.bedManagementEnabled || false,
    bedManagementLicenceExpiration: superConfig?.bedManagementLicenceExpiration
      ? superConfig.bedManagementLicenceExpiration.substr(0, 10)
      : undefined,
    respiratoryMobilizationEnabled:
      superConfig?.respiratoryMobilizationEnabled || false,
    respiratoryMobilizationLicenceExpiration:
      superConfig?.respiratoryMobilizationLicenceExpiration
        ? superConfig.respiratoryMobilizationLicenceExpiration.substr(0, 10)
        : undefined,
    utilizationEnabled: superConfig?.utilizationEnabled || false,
    utilizationLicenceExpiration: superConfig?.utilizationLicenceExpiration
      ? superConfig.utilizationLicenceExpiration.substr(0, 10)
      : undefined,
    syncCommunication: superConfig?.syncCommunication || false,
    hl7LogEnabled: superConfig?.hl7LogEnabled || false,
    reportingModuleAccess: superConfig?.reportingModuleAccess,
  };
};
