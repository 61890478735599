import axios from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { getAccessToken } from 'src/auth/AuthProvider';

export const doBedExport = () => {
  const accessToken = getAccessToken();
  axios
    .get<ArrayBuffer>('/api/download/bed_list', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const fileBlob = new Blob([response.data]);
      const fileName = `bed_list_(${format(new Date(), 'yyyy/MM/dd')}).xlsx`;
      saveAs(fileBlob, fileName);
    });
  return {};
};
