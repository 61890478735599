import { Box, Divider, MenuItem, styled, Typography } from '@mui/material';
import { WardsQuery, BedType } from 'src/graphql/types';
import { t } from '@lingui/macro';
import FormikTextField from 'src/components/Formik/FormikTextField';
import FormikForm from 'src/components/Formik/FormikForm';
import { FormikContextType } from 'formik';
import { useQuery } from '@apollo/client';
import { wardsQuery } from 'src/graphql/queries';
import { uniqBy } from 'ramda';

interface Props<T> {
  formik: FormikContextType<T>;
}

const bedTypes = () => [
  {
    value: BedType.MULTICARE,
    text: t`Multicare`,
  },
  {
    value: BedType.MULTICARE_LE,
    text: t`Multicare LE`,
  },
  {
    value: BedType.MULTICARE_X,
    text: t`Multicare X`,
  },
  {
    value: BedType.E5,
    text: t`E5`,
  },
  {
    value: BedType.E4,
    text: t`E4`,
  },
  {
    value: BedType.E4_NO_TRX,
    text: t`E4 (No Tx)`,
  },
  {
    value: BedType.E3XC,
    text: t`E3XC`,
  },
  {
    value: BedType.E3XC_NO_TRX,
    text: t`E3XC (No Tx)`,
  },
  {
    value: BedType.E3,
    text: t`E3`,
  },
  {
    value: BedType.E3_NO_TRX,
    text: t`E3 (No Tx)`,
  },
  {
    value: BedType.E2,
    text: t`E2`,
  },
];

const BedForm = <
  T extends {
    ownerWardId?: string | null;
    ownerWorkspaceId?: string | null;
  },
>({
  formik,
}: Props<T>) => {
  const { data: wardsData } = useQuery<WardsQuery>(wardsQuery);
  const { ownerWorkspaceId } = formik.values;

  const workspaces = uniqBy(
    (item) => item.id,
    (wardsData?.wards || []).map((ward) => ward.workspace),
  );
  const workspaceWards = wardsData?.wards.filter(
    (ward) => ward.workspace.id === ownerWorkspaceId,
  );

  return (
    <FormikForm formik={formik}>
      <SectionTitle>{t`General`}</SectionTitle>
      <SectionContainer>
        <FormikTextField
          name="name"
          fullWidth
          variant="outlined"
          label={t`Bed name`}
          required
        />
        <FormikTextField
          name="state"
          fullWidth
          disabled
          variant="outlined"
          label={t`Network`}
        />
        <FormikTextField
          name="unitId"
          fullWidth
          disabled
          variant="outlined"
          label={t`Integration modul ID`}
          required
        />
        <FormikTextField
          name="ipAddress"
          fullWidth
          disabled
          variant="outlined"
          label={t`WLAN IP Address`}
        />
        <FormikTextField
          name="type"
          variant="outlined"
          fullWidth
          select
          label={t`Type of Bed`}
        >
          {bedTypes().map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </FormikTextField>
        <FormikTextField
          name="macAddress"
          fullWidth
          disabled
          variant="outlined"
          label={t`WLAN MAC Address`}
        />
        <FormikTextField
          name="bedSn"
          fullWidth
          disabled
          variant="outlined"
          label={t`Bed SN`}
        />
        <FormikTextField
          name="ipAddressEthernet"
          fullWidth
          disabled
          variant="outlined"
          label={t`Ethernet IP Address`}
        />
        <FormikTextField
          name="unitSn"
          fullWidth
          disabled
          variant="outlined"
          label={t`Control Unit SN`}
        />
        <FormikTextField
          name="macAddressEthernet"
          fullWidth
          disabled
          variant="outlined"
          label={t`Ethernet MAC Address`}
        />
        <FormikTextField
          name="ident"
          fullWidth
          variant="outlined"
          label={t`Note`}
          sx={{
            gridColumn: 'span 2',
          }}
        />
      </SectionContainer>
      <StyledDivider />
      <SectionTitle>{t`Owner`}</SectionTitle>
      <SectionContainer>
        <FormikTextField
          name="ownerWorkspaceId"
          variant="outlined"
          fullWidth
          select
          label={t`Facility`}
        >
          {workspaces.map((workspace) => (
            <MenuItem key={workspace.id} value={workspace.id}>
              {workspace.name}
            </MenuItem>
          ))}
        </FormikTextField>
        <FormikTextField
          name="ownerWardId"
          variant="outlined"
          fullWidth
          select
          label={t`Ward`}
          disabled={!workspaceWards?.length}
        >
          {workspaceWards?.length ? (
            workspaceWards.map((ward) => (
              <MenuItem key={ward.id} value={ward.id}>
                {ward.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" />
          )}
        </FormikTextField>
      </SectionContainer>
      <StyledDivider />
      <SectionTitle>{t`Location`}</SectionTitle>
      <SectionContainer>
        <FormikTextField
          name="workspace"
          fullWidth
          disabled
          variant="outlined"
          label={t`Workspace`}
        />
        <FormikTextField
          name="ward"
          fullWidth
          disabled
          variant="outlined"
          label={t`Ward`}
        />
        <FormikTextField
          name="room"
          fullWidth
          disabled
          variant="outlined"
          label={t`Room`}
        />
      </SectionContainer>
      <StyledDivider />
      <SectionTitle>{t`Place and Source of location`}</SectionTitle>
      <SectionContainer>
        <FormikTextField
          name="parkingPlaceName"
          fullWidth
          disabled
          variant="outlined"
          label={t`Parking Place`}
        />
        <div />
        <FormikTextField
          name="parkingPlaceTag"
          fullWidth
          disabled
          variant="outlined"
          label={t`Tag`}
        />
        <FormikTextField
          name="parkingPlaceIpAddressEthernet"
          fullWidth
          disabled
          variant="outlined"
          label={t`Ethernet IP`}
        />
      </SectionContainer>
    </FormikForm>
  );
};

export default BedForm;

const SectionTitle = styled(Typography)(
  ({ theme: { spacing } }) => `
  padding-left: 0;
  margin-left: 0;
`,
);

SectionTitle.defaultProps = {
  variant: 'h3',
};

const StyledDivider = styled(Divider)`
  margin: 24px 0 32px 0;
`;

const SectionContainer = styled(Box)`
  display: grid;
  max-width: 1000px;
  grid-template-columns: 50% 50%;
  gap: 32px;
`;
