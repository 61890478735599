import { useQuery } from '@apollo/client';
import { deleteBed, updateBed } from 'src/graphql/mutations';
import { bed as bedQuery, beds } from 'src/graphql/queries';
import {
  BedQuery,
  BedQueryVariables,
  BedQuery_bed,
  BedState,
  BedType,
  deleteBedMutation,
  deleteBedMutationVariables,
  updateBedMutation,
  updateBedMutationVariables,
  UserRole,
} from 'src/graphql/types';
import { object, string } from 'yup';
import { t, Trans } from '@lingui/macro';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ListPageContent,
  ListPageRoot,
  TopBar,
} from 'src/components/AdminList';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@mui/material';
import { ConfirmModal } from 'src/components/ConfirmModal';
import BedForm from './BedForm';
import ActionButtons from 'src/components/ActionButtons';
import { AuthContext } from 'src/auth/AuthProvider';

interface BedFormData {
  unitId?: string;
  name?: string | null;
  ident?: string | null;
  type?: BedType | null;
  ownerWardId?: string | null;
  macAddress?: string | null;
  macAddressEthernet?: string | null;
  ipAddress?: string | null;
  ipAddressEthernet?: string | null;
  bedSn?: string | null;
  unitSn?: string | null;
  state?: string;
  ownerWorkspaceId?: string;
  workspace?: string;
  ward?: string;
  room?: string;
  parkingPlaceName?: string | null;
  parkingPlaceTag?: string | null;
  parkingPlaceIpAddressEthernet?: string | null;
}

const bedSchema = object({
  name: string().required(t`Required!`),
});

const BedDetail = () => {
  const params = useParams<{ unitId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const canAdjustVB = Boolean(
    user && user.role === UserRole.ADMIN && user.vbAccess,
  );

  const [update] = useSnackbarMutation<
    updateBedMutation,
    updateBedMutationVariables
  >(updateBed, undefined, {
    successMessage: t`Saved.`,
  });
  const [remove] = useSnackbarMutation<
    deleteBedMutation,
    deleteBedMutationVariables
  >(deleteBed, undefined, {
    successMessage: t`Removed.`,
  });

  const { data: bedData } = useQuery<BedQuery, BedQueryVariables>(bedQuery, {
    variables: {
      unitId: params.unitId || '',
    },
  });

  const handleSubmit = (values: BedFormData) => {
    const { unitId, name, ident, type, ownerWardId } = values;
    if (unitId) {
      update({
        variables: {
          bed: {
            unitId,
            name,
            ident,
            type,
            ownerWardId,
          },
        },
        refetchQueries: () => [
          {
            query: bedQuery,
            variables: {
              unitId: params.unitId || '',
            },
          },
        ],
        onCompleted: () => {
          formik.resetForm();
        },
      });
    }
  };

  const handleNavigateToVirtualBed = () => {
    navigate(`/admin/beds/${params.unitId}/edit`);
  };

  const handleRemoveBed = () => {
    if (params.unitId) {
      remove({
        variables: {
          bedUnitId: params.unitId,
        },
        onCompleted: () => {
          navigate('/admin/beds');
        },
        refetchQueries: () => [
          {
            query: beds,
          },
        ],
      });
    }
  };

  const getCorrectState = (bed: BedQuery_bed | null | undefined) => {
    if (bed) {
      const { state, ipAddressEthernet } = bed;
      if (state === BedState.ONLINE) {
        return ipAddressEthernet ? t`Ethernet Online` : t`WLAN Online`;
      }
      return state;
    }
    return BedState.OFFLINE;
  };

  const initialValues: BedFormData = {
    unitId: bedData?.bed?.unitId,
    name: bedData?.bed?.name,
    state: getCorrectState(bedData?.bed),
    ident: bedData?.bed?.ident,
    ownerWorkspaceId: bedData?.bed?.ownerWorkspace?.id,
    ownerWardId: bedData?.bed?.ownerWardId,
    type: bedData?.bed?.type,
    macAddress: bedData?.bed?.macAddress,
    macAddressEthernet: bedData?.bed?.macAddressEthernet,
    ipAddress: bedData?.bed?.ipAddress,
    ipAddressEthernet: bedData?.bed?.ipAddressEthernet,
    bedSn: bedData?.bed?.bedSn,
    unitSn: bedData?.bed?.unitSn,
    ward: bedData?.bed?.ward?.name,
    workspace: bedData?.bed?.workspace?.name,
    room: bedData?.bed?.room?.name,
    parkingPlaceName: bedData?.bed?.parkingPlaces[0]?.name,
    parkingPlaceTag: bedData?.bed?.parkingPlaces[0]?.tagId,
    parkingPlaceIpAddressEthernet:
      bedData?.bed?.parkingPlaces[0]?.ipAddressEthernet,
  };

  const formik = useFormik<BedFormData>({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
    validationSchema: bedSchema,
  });

  return (
    <ListPageRoot pb={4}>
      <TopBar>
        <ActionButtons
          onSave={formik.submitForm}
          onDiscard={formik.resetForm}
          isDirty={formik.dirty}
          onDelete={() => setShowDeleteModal(true)}
          leftButtons={
            bedData?.bed?.isVirtual && canAdjustVB
              ? [
                  {
                    id: 'update-virtual-bed',
                    label: t`Update virtual bed`,
                    color: 'primary',
                    variant: 'contained',
                    showWhen: 'notDirty',
                    onClick: handleNavigateToVirtualBed,
                  },
                ]
              : []
          }
        />
      </TopBar>
      <ListPageContent>
        <StyledDivider />
        <BedForm<BedFormData> formik={formik} />
      </ListPageContent>
      <ConfirmModal
        title={t`Delete Bed`}
        message={
          <Trans>
            Do you really want to <b>delete Bed {bedData?.bed?.name}?</b>
          </Trans>
        }
        confirmButtonText={t`Yes, I want to delete`}
        cancelButtonText={t`Cancel`}
        onConfirm={handleRemoveBed}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </ListPageRoot>
  );
};

export default BedDetail;

const StyledDivider = styled(Divider)`
  margin: 24px 0 32px 0;
`;
