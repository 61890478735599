import { Trans } from '@lingui/macro';
import {
  Box,
  BoxProps,
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps,
  Chip,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Flex from '../Flex';

interface Props extends BoxProps {
  breadCrumbsProps?: BreadcrumbsProps;
}

const Breadcrumbs = ({ children, breadCrumbsProps, ...boxProps }: Props) => {
  return (
    <Flex {...boxProps}>
      <Chip
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.grey[200],
          borderRadius: '14px',
          '&:hover': {
            backgroundColor: theme.palette.grey[200],
          },
        })}
        label={
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography mr="1ch" fontWeight="bold">
              <Trans>Location:</Trans>
            </Typography>
            <MuiBreadcrumbs
              {...breadCrumbsProps}
              color="text.secondary"
              separator={<NavigateNextIcon fontSize="small" />}
            >
              {children}
            </MuiBreadcrumbs>
          </Box>
        }
      />
    </Flex>
  );
};

export default Breadcrumbs;
