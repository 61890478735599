import { t } from '@lingui/macro';
import { add, sub, startOfDay } from 'date-fns';
import { linetColors } from '../../../colors';
import {
  SessionTrends,
  SessionTrends_fifthDay,
  SessionTrends_firstDay,
  SessionTrends_fourthDay,
  SessionTrends_secondDay,
  SessionTrends_seventhDay,
  SessionTrends_sixthDay,
  SessionTrends_thirdDay,
} from '../../../graphql/types';

export const mobilizationDayWidth = 140;
export const mobilizationdayHeight = 720;
const mobilizationSwimLineWidth = 12;
export const mobilizationViewWidth = 1030;

export interface SelectedDatapointsType {
  alt: boolean;
  matress: boolean;
  mobilift: boolean;
  trendelenburg: boolean;
  lateral: boolean;
  backrest30: boolean;
  backrest45: boolean;
}

export const selectedDatapointsInitState: SelectedDatapointsType = {
  alt: true,
  matress: true,
  mobilift: true,
  trendelenburg: true,
  lateral: true,
  backrest30: true,
  backrest45: true,
};

export enum SelectedDatapointsEnum {
  'alt' = 'alt',
  'matress' = 'matress',
  'mobilift' = 'mobilift',
  'trendelenburg' = 'trendelenburg',
  'lateral' = 'lateral',
  'backrest30' = 'backrest30',
  'backrest45' = 'backrest45',
}

export const getDatapointSelectorItemText = (type: SelectedDatapointsEnum) => {
  switch (type) {
    case SelectedDatapointsEnum.alt:
      return t`ALT`;
    case SelectedDatapointsEnum.matress:
      return t`Matress`;
    case SelectedDatapointsEnum.mobilift:
      return t`Mobi-Lift`;
    case SelectedDatapointsEnum.trendelenburg:
      return t`Trendelenburg`;
    case SelectedDatapointsEnum.lateral:
      return t`Lateral`;
    case SelectedDatapointsEnum.backrest30:
      return t`Backrest 30°`;
    case SelectedDatapointsEnum.backrest45:
      return t`Backrest 45°`;
    default:
      return null;
  }
};

export interface SummaryDataType {
  time: string;
  type: SelectedDatapointsEnum;
}

export const getMobilizationSummaryItemTime = (seconds?: number) => {
  if (seconds != null) {
    const minutes = seconds / 60;
    const hours = Math.trunc(minutes / 60);
    const resultMins = Math.round(minutes % 60);
    if (resultMins === 0 || resultMins === 60)
      return `${resultMins === 60 ? hours + 1 : hours} h`;
    else return `${hours} h ${resultMins} m`;
  } else return '--';
};

export type IncommingDayDataType =
  | SessionTrends_firstDay
  | SessionTrends_secondDay
  | SessionTrends_thirdDay
  | SessionTrends_fourthDay
  | SessionTrends_fifthDay
  | SessionTrends_sixthDay
  | SessionTrends_seventhDay;

export const getSummaryDataValues = (
  selected: SelectedDatapointsType,
  data?: IncommingDayDataType,
) => {
  if (!!data) {
    const result: SummaryDataType[] = [];
    if (!!selected.alt && data.alt?.duration) {
      result.push({
        time: getMobilizationSummaryItemTime(data.alt?.duration),
        type: SelectedDatapointsEnum.alt,
      });
    }
    if (!!selected.matress && data.mattressType?.duration) {
      result.push({
        time: getMobilizationSummaryItemTime(data.mattressType?.duration),
        type: SelectedDatapointsEnum.matress,
      });
    }
    if (!!selected.mobilift && data.mobilift?.duration) {
      result.push({
        time: getMobilizationSummaryItemTime(data.mobilift?.duration),
        type: SelectedDatapointsEnum.mobilift,
      });
    }
    if (!!selected.trendelenburg && data.trendelenburg?.duration) {
      result.push({
        time: getMobilizationSummaryItemTime(data.trendelenburg?.duration),
        type: SelectedDatapointsEnum.trendelenburg,
      });
    }
    if (!!selected.lateral && data.lateralTilt?.duration) {
      result.push({
        time: getMobilizationSummaryItemTime(data.lateralTilt?.duration),
        type: SelectedDatapointsEnum.lateral,
      });
    }
    if (!!selected.backrest30 && data.backrest30?.duration) {
      result.push({
        time: getMobilizationSummaryItemTime(data.backrest30?.duration),
        type: SelectedDatapointsEnum.backrest30,
      });
    }
    if (!!selected.backrest45 && data.backrest45?.duration) {
      result.push({
        time: getMobilizationSummaryItemTime(data.backrest45?.duration),
        type: SelectedDatapointsEnum.backrest45,
      });
    }
    return result;
  } else return [];
};

export const getSummaryData = (
  selected: SelectedDatapointsType,
  daysData: SessionTrends,
) => {
  const data: SummaryDataType[][] = [
    getSummaryDataValues(selected, daysData.firstDay),
    getSummaryDataValues(selected, daysData.secondDay),
    getSummaryDataValues(selected, daysData.thirdDay),
    getSummaryDataValues(selected, daysData.fourthDay),
    getSummaryDataValues(selected, daysData.fifthDay),
    getSummaryDataValues(selected, daysData.sixthDay),
    getSummaryDataValues(selected, daysData.seventhDay),
  ];
  return data;
};

export interface DayDataType {
  time?: string | null;
  alt: number;
  matress: number;
  mobilift: number;
  trendelenburg: number;
  lateral: number;
  backrest30: number;
  backrest45: number;
  altPosition: number | null;
  matressPosition: number | null;
  mobiliftPosition: number | null;
  trendelenburgPosition: number | null;
  lateralPosition: number | null;
  backrest30Position: number | null;
  backrest45Position: number | null;
}

const getDayDataValue = (value?: number | null) => {
  if (value) {
    return 1;
  } else {
    return 0;
  }
};

const getDayDataPosition = (selected: SelectedDatapointsType) => {
  const positions: (number | null)[] = new Array(
    Object.keys(selected).length,
  ).fill(null);
  let position = 0;
  const result = positions.map((_, index) => {
    if (index === 0) {
      if (!selected.alt) return null;
      else {
        return (position += 1);
      }
    }
    if (index === 1) {
      if (!selected.matress) return null;
      else {
        return (position += 1);
      }
    }
    if (index === 2) {
      if (!selected.mobilift) return null;
      else {
        return (position += 1);
      }
    }
    if (index === 3) {
      if (!selected.trendelenburg) return null;
      else {
        return (position += 1);
      }
    }
    if (index === 4) {
      if (!selected.lateral) return null;
      else {
        return (position += 1);
      }
    }
    if (index === 5) {
      if (!selected.backrest30) return null;
      else {
        return (position += 1);
      }
    }
    if (index === 6) {
      if (!selected.backrest45) return null;
      else {
        return (position += 1);
      }
    }
    return null;
  });

  return result;
};

export const getCountOfSelectedDatapoints = (
  selected: SelectedDatapointsType,
) => {
  return Object.values(selected).filter((item) => !!item).length;
};

const getDayData = (
  selected: SelectedDatapointsType,
  data?: IncommingDayDataType,
) => {
  if (!!data) {
    const positions = getDayDataPosition(selected);
    const result: DayDataType[] = Array.from(Array(96).keys()).map(
      (_, index) => {
        return {
          time: data.alt?.data[index]?.time ?? null,
          alt: getDayDataValue(data.alt?.data[index]?.value),
          matress: getDayDataValue(data.mattressType?.data[index]?.value),
          mobilift: getDayDataValue(data.mobilift?.data[index]?.value),
          trendelenburg: getDayDataValue(
            data.trendelenburg?.data[index]?.value,
          ),
          lateral: getDayDataValue(data.lateralTilt?.data[index]?.value),
          backrest30: getDayDataValue(data.backrest30?.data[index]?.value),
          backrest45: getDayDataValue(data.backrest45?.data[index]?.value),
          altPosition: positions[0],
          matressPosition: positions[1],
          mobiliftPosition: positions[2],
          trendelenburgPosition: positions[3],
          lateralPosition: positions[4],
          backrest30Position: positions[5],
          backrest45Position: positions[6],
        };
      },
    );
    return result;
  } else return [];
};

export const getDaysData = (
  selected: SelectedDatapointsType,
  days: SessionTrends,
) => {
  const daysData: DayDataType[][] = [
    getDayData(selected, days.firstDay),
    getDayData(selected, days.secondDay),
    getDayData(selected, days.thirdDay),
    getDayData(selected, days.fourthDay),
    getDayData(selected, days.fifthDay),
    getDayData(selected, days.sixthDay),
    getDayData(selected, days.seventhDay),
  ];
  return daysData;
};

export const getDatapointColor = (type: SelectedDatapointsEnum) => {
  switch (type) {
    case SelectedDatapointsEnum.alt:
      return linetColors.mobilization.alt;
    case SelectedDatapointsEnum.matress:
      return linetColors.mobilization.matress;
    case SelectedDatapointsEnum.mobilift:
      return linetColors.mobilization.mobilift;
    case SelectedDatapointsEnum.trendelenburg:
      return linetColors.mobilization.trendelenburg;
    case SelectedDatapointsEnum.lateral:
      return linetColors.mobilization.lateral;
    case SelectedDatapointsEnum.backrest30:
      return linetColors.mobilization.backrest30;
    case SelectedDatapointsEnum.backrest45:
      return linetColors.mobilization.backrest45;
    default:
      return 'transparent';
  }
};

export const getDatapointDotValue = (
  type: SelectedDatapointsEnum,
  data: DayDataType,
) => {
  switch (type) {
    case SelectedDatapointsEnum.alt:
      return data.alt;
    case SelectedDatapointsEnum.matress:
      return data.matress;
    case SelectedDatapointsEnum.mobilift:
      return data.mobilift;
    case SelectedDatapointsEnum.trendelenburg:
      return data.trendelenburg;
    case SelectedDatapointsEnum.lateral:
      return data.lateral;
    case SelectedDatapointsEnum.backrest30:
      return data.backrest30;
    case SelectedDatapointsEnum.backrest45:
      return data.backrest45;
    default:
      return null;
  }
};

export const getMobilizationTimelineValues = () => {
  const date = startOfDay(new Date());
  const timelineArray = new Array(25).fill(0);
  const result = timelineArray.map((_, index) => {
    if (index === 0) {
      return date;
    }
    return add(date, { hours: index });
  });
  return result;
};

export const getMobilizationWeekDates = (date: Date) => {
  const result: Date[] = new Array(7).fill(0).map((_, index) => {
    if (index === 0) {
      return date;
    } else {
      return sub(date, { days: index });
    }
  });
  return result.reverse();
};

export const getWidthOfSwimLine = (count: number) => {
  const totalWidth = 7 * mobilizationSwimLineWidth;
  return totalWidth / count;
};
