/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: meLocale
// ====================================================

export interface meLocale_me {
  __typename: 'User';
  id: string;
  locale: string | null;
}

export interface meLocale_superConfig {
  __typename: 'SuperConfig';
  spEnabled: boolean | null;
  spVersion: string | null;
  spDashboardVersion: string | null;
  patientEnabled: boolean | null;
  spDashboardEnabled: boolean | null;
  spDashboardLicenceExpiration: any | null;
  respiratoryMobilizationEnabled: boolean | null;
  bedManagementEnabled: boolean | null;
  patientLicenceExpiration: any | null;
  bedManagementLicenceExpiration: any | null;
  respiratoryMobilizationLicenceExpiration: any | null;
  utilizationEnabled: boolean | null;
  utilizationLicenceExpiration: any | null;
  syncCommunication: boolean | null;
  hl7LogEnabled: boolean | null;
  reportingModuleAccess: boolean;
  passwordFlags: number | null;
  passwordMinLength: number | null;
  adminPasswordFlags: number | null;
  adminPasswordMinLength: number | null;
}

export interface meLocale {
  me: meLocale_me | null;
  superConfig: meLocale_superConfig | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createWorkspaceMutation
// ====================================================

export interface createWorkspaceMutation {
  createWorkspace: boolean | null;
}

export interface createWorkspaceMutationVariables {
  workspace?: WorkspaceInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteWorkspaceMutation
// ====================================================

export interface deleteWorkspaceMutation {
  deleteWorkspace: boolean | null;
}

export interface deleteWorkspaceMutationVariables {
  workspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertUserMutation
// ====================================================

export interface upsertUserMutation {
  upsertUser: boolean;
}

export interface upsertUserMutationVariables {
  user: UserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteUserMutation
// ====================================================

export interface deleteUserMutation {
  deleteUser: boolean;
}

export interface deleteUserMutationVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertConfigMutation
// ====================================================

export interface upsertConfigMutation_upsertConfig {
  __typename: 'Config';
  hospitalName: string | null;
}

export interface upsertConfigMutation {
  upsertConfig: upsertConfigMutation_upsertConfig;
}

export interface upsertConfigMutationVariables {
  config: ConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: upsertSuperConfigMutation
// ====================================================

export interface upsertSuperConfigMutation_upsertSuperConfig {
  __typename: 'SuperConfig';
  spEnabled: boolean | null;
  spVersion: string | null;
  spDashboardVersion: string | null;
  spDashboardLicenceExpiration: any | null;
  patientEnabled: boolean | null;
  spDashboardEnabled: boolean | null;
  respiratoryMobilizationEnabled: boolean | null;
  bedManagementEnabled: boolean | null;
  patientLicenceExpiration: any | null;
  bedManagementLicenceExpiration: any | null;
  respiratoryMobilizationLicenceExpiration: any | null;
  utilizationEnabled: boolean | null;
  utilizationLicenceExpiration: any | null;
  syncCommunication: boolean | null;
  hl7LogEnabled: boolean | null;
  passwordFlags: number | null;
  passwordMinLength: number | null;
  adminPasswordFlags: number | null;
  adminPasswordMinLength: number | null;
}

export interface upsertSuperConfigMutation {
  upsertSuperConfig: upsertSuperConfigMutation_upsertSuperConfig;
}

export interface upsertSuperConfigMutationVariables {
  config: SuperConfigInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddConnection
// ====================================================

export interface AddConnection_addConnection_props {
  __typename: 'ConnectionProp';
  key: SafetyPortProps;
  enabled: boolean;
  threshold: number | null;
}

export interface AddConnection_addConnection {
  __typename: 'Connection';
  id: string;
  name: string;
  enabled: boolean;
  ip: string | null;
  port: number | null;
  synchronous: boolean;
  syncPeriod: number | null;
  props: AddConnection_addConnection_props[];
}

export interface AddConnection {
  addConnection: AddConnection_addConnection;
}

export interface AddConnectionVariables {
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditConnection
// ====================================================

export interface EditConnection_editConnection_props {
  __typename: 'ConnectionProp';
  key: SafetyPortProps;
  enabled: boolean;
  threshold: number | null;
}

export interface EditConnection_editConnection {
  __typename: 'Connection';
  id: string;
  name: string;
  enabled: boolean;
  ip: string | null;
  port: number | null;
  synchronous: boolean;
  syncPeriod: number | null;
  props: EditConnection_editConnection_props[];
}

export interface EditConnection {
  editConnection: EditConnection_editConnection | null;
}

export interface EditConnectionVariables {
  connection: ConnectionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteConnection
// ====================================================

export interface DeleteConnection {
  deleteConnection: boolean;
}

export interface DeleteConnectionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createWardMutation
// ====================================================

export interface createWardMutation_createWard {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface createWardMutation {
  createWard: createWardMutation_createWard;
}

export interface createWardMutationVariables {
  ward?: WardInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteWardMutation
// ====================================================

export interface deleteWardMutation {
  deleteWard: boolean | null;
}

export interface deleteWardMutationVariables {
  wardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateWardMutation
// ====================================================

export interface updateWardMutation {
  updateWard: number;
}

export interface updateWardMutationVariables {
  ward?: WardInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeUserSetting
// ====================================================

export interface ChangeUserSetting_changeUserSetting {
  __typename: 'UserSetting';
  userId: string;
  svTheme: string | null;
  dashboardOrder: string[];
  dashboardOrder2: string[] | null;
  dashboardPageSize: number;
  dashboardFontSize: FontSize;
  showOwnedBeds: boolean;
  anonymized: boolean;
  singleLineLayout: boolean;
}

export interface ChangeUserSetting {
  changeUserSetting: ChangeUserSetting_changeUserSetting | null;
}

export interface ChangeUserSettingVariables {
  input?: UserSettingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createRoomMutation
// ====================================================

export interface createRoomMutation_createRoom {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface createRoomMutation {
  createRoom: createRoomMutation_createRoom;
}

export interface createRoomMutationVariables {
  room: RoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteRoomMutation
// ====================================================

export interface deleteRoomMutation {
  deleteRoom: boolean | null;
}

export interface deleteRoomMutationVariables {
  roomId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateRoomMutation
// ====================================================

export interface updateRoomMutation {
  updateRoom: boolean;
}

export interface updateRoomMutationVariables {
  room: UpdateRoomInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: removeParkingPlaceMutation
// ====================================================

export interface removeParkingPlaceMutation {
  removeParkingPlace: string;
}

export interface removeParkingPlaceMutationVariables {
  parkingPlaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: unassignBedMutation
// ====================================================

export interface unassignBedMutation {
  unassignBed: boolean;
}

export interface unassignBedMutationVariables {
  bedUnitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBedMutation
// ====================================================

export interface updateBedMutation_updateBed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  ident: string | null;
  type: BedType | null;
  ownerWardId: string | null;
}

export interface updateBedMutation {
  updateBed: updateBedMutation_updateBed;
}

export interface updateBedMutationVariables {
  bed: BedEditInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteBedMutation
// ====================================================

export interface deleteBedMutation {
  deleteBed: boolean;
}

export interface deleteBedMutationVariables {
  bedUnitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteBedsMutation
// ====================================================

export interface DeleteBedsMutation {
  deleteBeds: boolean;
}

export interface DeleteBedsMutationVariables {
  bedUnitIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setDevice
// ====================================================

export interface setDevice {
  setDevice: boolean;
}

export interface setDeviceVariables {
  bedUnitIds?: string[] | null;
  operation: number;
  deviceId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateWardSafetyPreset
// ====================================================

export interface UpdateWardSafetyPreset_updateWardSafetyPreset {
  __typename: 'Ward';
  id: string;
  name: string;
  safetyPositionPresetId: string | null;
}

export interface UpdateWardSafetyPreset {
  updateWardSafetyPreset: UpdateWardSafetyPreset_updateWardSafetyPreset[];
}

export interface UpdateWardSafetyPresetVariables {
  input: WardSafetyPresetInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetWardSetting
// ====================================================

export interface SetWardSetting_setWardSetting {
  __typename: 'WardSetting';
  breaksAlertSound: boolean;
  heightAlertSound: boolean;
  backrestAlertSound: boolean;
  siderailAlertSound: boolean;
}

export interface SetWardSetting {
  setWardSetting: SetWardSetting_setWardSetting;
}

export interface SetWardSettingVariables {
  wardId: string;
  breaksAlertSound?: boolean | null;
  heightAlertSound?: boolean | null;
  backrestAlertSound?: boolean | null;
  siderailAlertSound?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSafetyPositionPreset
// ====================================================

export interface CreateSafetyPositionPreset_createSafetyPositionPreset {
  __typename: 'SafetyPositionPreset';
  id: string;
  name: string;
  editable: boolean;
  backrestAngleEnabled: boolean;
  backrestAngleMin: number;
  backrestAngleMax: number;
  posIsLowestEnabled: boolean;
  brakesEnabled: boolean;
  siderailPositionEnabled: boolean;
  siderailPosition: SiderailsPosition;
  bedExitEnabled: boolean;
  bedExitStatus: BedExitStatus;
}

export interface CreateSafetyPositionPreset {
  createSafetyPositionPreset: CreateSafetyPositionPreset_createSafetyPositionPreset;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditSafetyPositionPreset
// ====================================================

export interface EditSafetyPositionPreset_editSafetyPositionPreset {
  __typename: 'SafetyPositionPreset';
  id: string;
  name: string;
  editable: boolean;
  backrestAngleEnabled: boolean;
  backrestAngleMin: number;
  backrestAngleMax: number;
  posIsLowestEnabled: boolean;
  brakesEnabled: boolean;
  siderailPositionEnabled: boolean;
  siderailPosition: SiderailsPosition;
  bedExitEnabled: boolean;
  bedExitStatus: BedExitStatus;
}

export interface EditSafetyPositionPreset {
  editSafetyPositionPreset: EditSafetyPositionPreset_editSafetyPositionPreset;
}

export interface EditSafetyPositionPresetVariables {
  input: EditSafetyPositionPresetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSafetyPositionPreset
// ====================================================

export interface DeleteSafetyPositionPreset {
  deleteSafetyPositionPreset: boolean;
}

export interface DeleteSafetyPositionPresetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setState
// ====================================================

export interface setState {
  setState: boolean;
}

export interface setStateVariables {
  bedUnitId: string;
  state: number;
  pause?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: pauseTimer
// ====================================================

export interface pauseTimer {
  pauseTimer: boolean;
}

export interface pauseTimerVariables {
  bedUnitId: string;
  seconds: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: addVirtualBed
// ====================================================

export interface addVirtualBed_addVirtualBed {
  __typename: 'Bed';
  unitId: string;
}

export interface addVirtualBed {
  addVirtualBed: addVirtualBed_addVirtualBed;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPauseMutation
// ====================================================

export interface ResetPauseMutation {
  resetPause: boolean;
}

export interface ResetPauseMutationVariables {
  virtualBedId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateVirtualBed
// ====================================================

export interface updateVirtualBed_updateVirtualBed {
  __typename: 'VirtualBed';
  unitId: string;
  smPause: any | null;
}

export interface updateVirtualBed {
  updateVirtualBed: updateVirtualBed_updateVirtualBed;
}

export interface updateVirtualBedVariables {
  input: UpdateVirtualBedInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddCareNoteMutation
// ====================================================

export interface AddCareNoteMutation_addNote {
  __typename: 'PatientNote';
  id: string;
  note: string | null;
  dateCreated: any;
  dateConfirmed: any | null;
}

export interface AddCareNoteMutation {
  addNote: AddCareNoteMutation_addNote;
}

export interface AddCareNoteMutationVariables {
  sessionId: string;
  note: string;
  date: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCareNoteMutation
// ====================================================

export interface DeleteCareNoteMutation {
  deleteNote: boolean;
}

export interface DeleteCareNoteMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditCareNoteMutation
// ====================================================

export interface EditCareNoteMutation_editNote {
  __typename: 'PatientNote';
  id: string;
  note: string | null;
}

export interface EditCareNoteMutation {
  editNote: EditCareNoteMutation_editNote;
}

export interface EditCareNoteMutationVariables {
  id: string;
  note: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: StartPatientSession
// ====================================================

export interface StartPatientSession_startSession {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  note: string | null;
  fallRisk: FallRisk;
  sessionStart: any;
}

export interface StartPatientSession {
  startSession: StartPatientSession_startSession;
}

export interface StartPatientSessionVariables {
  input: StartSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPatientSession
// ====================================================

export interface EditPatientSession_editSession {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  note: string | null;
  sessionStart: any;
  fallRisk: FallRisk;
  safetyPositionPresetId: string | null;
}

export interface EditPatientSession {
  editSession: EditPatientSession_editSession | null;
}

export interface EditPatientSessionVariables {
  input: EditPatientSessionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EndSession
// ====================================================

export interface EndSession {
  endSession: boolean;
}

export interface EndSessionVariables {
  id: string;
  end: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangePwEndpointOrder
// ====================================================

export interface ChangePwEndpointOrder_changePwEndpointOrder {
  __typename: 'UserSetting';
  userId: string;
  pwEndpointOrder: string[];
}

export interface ChangePwEndpointOrder {
  changePwEndpointOrder: ChangePwEndpointOrder_changePwEndpointOrder | null;
}

export interface ChangePwEndpointOrderVariables {
  orderString: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PinBed
// ====================================================

export interface PinBed_pinBed {
  __typename: 'Bed';
  unitId: string;
  pinned: boolean;
}

export interface PinBed {
  pinBed: PinBed_pinBed | null;
}

export interface PinBedVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnpinBed
// ====================================================

export interface UnpinBed_unpinBed {
  __typename: 'Bed';
  unitId: string;
  pinned: boolean;
}

export interface UnpinBed {
  unpinBed: UnpinBed_unpinBed;
}

export interface UnpinBedVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadLogoMutation
// ====================================================

export interface UploadLogoMutation {
  uploadLogo: boolean;
}

export interface UploadLogoMutationVariables {
  file: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteLogoMutation
// ====================================================

export interface DeleteLogoMutation {
  deleteLogo: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CopyWorkspaceMutation
// ====================================================

export interface CopyWorkspaceMutation_copyWorkspace {
  __typename: 'Workspace';
  id: string;
}

export interface CopyWorkspaceMutation {
  copyWorkspace: CopyWorkspaceMutation_copyWorkspace;
}

export interface CopyWorkspaceMutationVariables {
  workspaceId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateParkingPlace
// ====================================================

export interface CreateParkingPlace_createParkingPlace {
  __typename: 'ParkingPlace';
  id: string;
  name: string | null;
}

export interface CreateParkingPlace {
  createParkingPlace: CreateParkingPlace_createParkingPlace;
}

export interface CreateParkingPlaceVariables {
  roomId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeParkingPlaceMode
// ====================================================

export interface ChangeParkingPlaceMode_changeParkingPlaceMode_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface ChangeParkingPlaceMode_changeParkingPlaceMode {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
  bed: ChangeParkingPlaceMode_changeParkingPlaceMode_bed | null;
}

export interface ChangeParkingPlaceMode {
  changeParkingPlaceMode: ChangeParkingPlaceMode_changeParkingPlaceMode | null;
}

export interface ChangeParkingPlaceModeVariables {
  parkingPlaceId: string;
  mode: ParkingPlaceMode;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeParkingPlaceTag
// ====================================================

export interface ChangeParkingPlaceTag_changeParkingPlaceTag_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface ChangeParkingPlaceTag_changeParkingPlaceTag {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
  bed: ChangeParkingPlaceTag_changeParkingPlaceTag_bed | null;
}

export interface ChangeParkingPlaceTag {
  changeParkingPlaceTag: ChangeParkingPlaceTag_changeParkingPlaceTag | null;
}

export interface ChangeParkingPlaceTagVariables {
  parkingPlaceId: string;
  tagId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeParkingPlaceNameMutation
// ====================================================

export interface ChangeParkingPlaceNameMutation_changeParkingPlaceName_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface ChangeParkingPlaceNameMutation_changeParkingPlaceName {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
  bed: ChangeParkingPlaceNameMutation_changeParkingPlaceName_bed | null;
}

export interface ChangeParkingPlaceNameMutation {
  changeParkingPlaceName: ChangeParkingPlaceNameMutation_changeParkingPlaceName | null;
}

export interface ChangeParkingPlaceNameMutationVariables {
  parkingPlaceId: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeParkingPlaceIpAddressEthernetMutation
// ====================================================

export interface ChangeParkingPlaceIpAddressEthernetMutation_changeParkingPlaceIpAddressEthernet_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface ChangeParkingPlaceIpAddressEthernetMutation_changeParkingPlaceIpAddressEthernet {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
  bed: ChangeParkingPlaceIpAddressEthernetMutation_changeParkingPlaceIpAddressEthernet_bed | null;
}

export interface ChangeParkingPlaceIpAddressEthernetMutation {
  changeParkingPlaceIpAddressEthernet: ChangeParkingPlaceIpAddressEthernetMutation_changeParkingPlaceIpAddressEthernet;
}

export interface ChangeParkingPlaceIpAddressEthernetMutationVariables {
  parkingPlaceId: string;
  ipAddressEthernet?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeParkingPlaceBed
// ====================================================

export interface ChangeParkingPlaceBed_changeParkingPlaceBed_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface ChangeParkingPlaceBed_changeParkingPlaceBed {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
  bed: ChangeParkingPlaceBed_changeParkingPlaceBed_bed | null;
}

export interface ChangeParkingPlaceBed {
  changeParkingPlaceBed: ChangeParkingPlaceBed_changeParkingPlaceBed | null;
}

export interface ChangeParkingPlaceBedVariables {
  parkingPlaceId: string;
  bedUnitId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserPassword
// ====================================================

export interface UpdateUserPassword_updateUserPassword {
  __typename: 'User';
  id: string;
}

export interface UpdateUserPassword {
  updateUserPassword: UpdateUserPassword_updateUserPassword | null;
}

export interface UpdateUserPasswordVariables {
  userId: string;
  oldPassword: string;
  newPassword: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReportingReport
// ====================================================

export interface CreateReportingReport_createReportingReport_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface CreateReportingReport_createReportingReport {
  __typename: 'ReportingReport';
  id: string;
  name: string;
  goal: number | null;
  filterConfig: string;
  reportingDashboards:
    | CreateReportingReport_createReportingReport_reportingDashboards[]
    | null;
}

export interface CreateReportingReport {
  createReportingReport: CreateReportingReport_createReportingReport;
}

export interface CreateReportingReportVariables {
  input: CreateReportingReportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReportingGoal
// ====================================================

export interface CreateReportingGoal_createReportingGoal_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface CreateReportingGoal_createReportingGoal {
  __typename: 'ReportingReport';
  id: string;
  name: string;
  goal: number | null;
  filterConfig: string;
  reportingDashboards:
    | CreateReportingGoal_createReportingGoal_reportingDashboards[]
    | null;
}

export interface CreateReportingGoal {
  createReportingGoal: CreateReportingGoal_createReportingGoal;
}

export interface CreateReportingGoalVariables {
  input: CreateReportingGoalInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditReportingReport
// ====================================================

export interface EditReportingReport_editReportingReport_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface EditReportingReport_editReportingReport {
  __typename: 'ReportingReport';
  id: string;
  name: string;
  goal: number | null;
  filterConfig: string;
  reportingDashboards:
    | EditReportingReport_editReportingReport_reportingDashboards[]
    | null;
}

export interface EditReportingReport {
  editReportingReport: EditReportingReport_editReportingReport;
}

export interface EditReportingReportVariables {
  input: EditReportingReportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditReportingGoal
// ====================================================

export interface EditReportingGoal_editReportingGoal_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface EditReportingGoal_editReportingGoal {
  __typename: 'ReportingReport';
  id: string;
  name: string;
  goal: number | null;
  filterConfig: string;
  reportingDashboards:
    | EditReportingGoal_editReportingGoal_reportingDashboards[]
    | null;
}

export interface EditReportingGoal {
  editReportingGoal: EditReportingGoal_editReportingGoal;
}

export interface EditReportingGoalVariables {
  input: EditReportingGoalInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteReportingReport
// ====================================================

export interface DeleteReportingReport {
  deleteReportingReport: boolean;
}

export interface DeleteReportingReportVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateReportingDashboard
// ====================================================

export interface CreateReportingDashboard_createReportingDashboard {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface CreateReportingDashboard {
  createReportingDashboard: CreateReportingDashboard_createReportingDashboard;
}

export interface CreateReportingDashboardVariables {
  input: CreateReportingDashboardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditReportingDashboard
// ====================================================

export interface EditReportingDashboard_editReportingDashboard {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface EditReportingDashboard {
  editReportingDashboard: EditReportingDashboard_editReportingDashboard;
}

export interface EditReportingDashboardVariables {
  input: EditReportingDashboardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteReportingDashboard
// ====================================================

export interface DeleteReportingDashboard {
  deleteReportingDashboard: boolean;
}

export interface DeleteReportingDashboardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveReportingReportFromDashboard
// ====================================================

export interface RemoveReportingReportFromDashboard {
  removeReportingReportFromDashboard: boolean;
}

export interface RemoveReportingReportFromDashboardVariables {
  input: RemoveReportingReportFromDashboardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddReportingReportToDashboard
// ====================================================

export interface AddReportingReportToDashboard_addReportingReportToDashboard_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface AddReportingReportToDashboard_addReportingReportToDashboard {
  __typename: 'ReportingReport';
  id: string;
  reportingDashboards:
    | AddReportingReportToDashboard_addReportingReportToDashboard_reportingDashboards[]
    | null;
}

export interface AddReportingReportToDashboard {
  addReportingReportToDashboard: AddReportingReportToDashboard_addReportingReportToDashboard;
}

export interface AddReportingReportToDashboardVariables {
  input: AddReportingReportToDashboardInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateReportingDashboardReportPosition
// ====================================================

export interface UpdateReportingDashboardReportPosition {
  updateReportingDashboardReportPosition: boolean;
}

export interface UpdateReportingDashboardReportPositionVariables {
  input: UpdateReportingDashboardReportPositionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTag
// ====================================================

export interface DeleteTag {
  deleteTag: boolean;
}

export interface DeleteTagVariables {
  tagId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ClearDataMutation
// ====================================================

export interface ClearDataMutation {
  clearDataHistory: boolean;
}

export interface ClearDataMutationVariables {
  input: ClearDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveSimulationPreset
// ====================================================

export interface SaveSimulationPreset_saveSimulationPreset {
  __typename: 'SimulationPreset';
  id: string;
  name: string;
  config: string;
}

export interface SaveSimulationPreset {
  saveSimulationPreset: SaveSimulationPreset_saveSimulationPreset | null;
}

export interface SaveSimulationPresetVariables {
  input: SaveSimulationPresetInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSimulationPreset
// ====================================================

export interface DeleteSimulationPreset {
  deleteSimulationPreset: boolean;
}

export interface DeleteSimulationPresetVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SimulateData
// ====================================================

export interface SimulateData {
  simulateData: boolean;
}

export interface SimulateDataVariables {
  input: SimulateDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InsightQuery
// ====================================================

export interface InsightQuery_insight_data {
  __typename: 'InsightData';
  unitId: string;
  type: string;
  value: string;
  time: any;
}

export interface InsightQuery_insight {
  __typename: 'Insight';
  data: InsightQuery_insight_data[];
  hasMore: boolean;
}

export interface InsightQuery {
  insight: InsightQuery_insight;
}

export interface InsightQueryVariables {
  unitId?: string | null;
  dateFrom: any;
  limit: number;
  skipFirst?: boolean | null;
  prop?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserQuery
// ====================================================

export interface UserQuery_user_accessibleWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface UserQuery_user_accessibleWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: UserQuery_user_accessibleWards_workspace;
}

export interface UserQuery_user_assignedWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface UserQuery_user_assignedWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: UserQuery_user_assignedWards_workspace;
}

export interface UserQuery_user {
  __typename: 'User';
  id: string;
  role: UserRole;
  accessibleWards: UserQuery_user_accessibleWards[] | null;
  assignedWards: UserQuery_user_assignedWards[] | null;
  username: string;
  firstName: string | null;
  lastName: string | null;
  locale: string | null;
  email: string;
  lastAccess: any | null;
  vbAccess: boolean;
  demoAccess: boolean;
  serverIp: string | null;
  ssid: string | null;
  ssidKey: string | null;
  ssidKeyEncrypt: boolean | null;
  reportingModuleAccess: boolean;
}

export interface UserQuery {
  user: UserQuery_user | null;
}

export interface UserQueryVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UsersQuery
// ====================================================

export interface UsersQuery_users_accessibleWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface UsersQuery_users_accessibleWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: UsersQuery_users_accessibleWards_workspace;
}

export interface UsersQuery_users_assignedWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface UsersQuery_users_assignedWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: UsersQuery_users_assignedWards_workspace;
}

export interface UsersQuery_users {
  __typename: 'User';
  id: string;
  role: UserRole;
  accessibleWards: UsersQuery_users_accessibleWards[] | null;
  assignedWards: UsersQuery_users_assignedWards[] | null;
  username: string;
  firstName: string | null;
  lastName: string | null;
  locale: string | null;
  email: string;
  lastAccess: any | null;
  vbAccess: boolean;
  demoAccess: boolean;
  serverIp: string | null;
  ssid: string | null;
  ssidKey: string | null;
  ssidKeyEncrypt: boolean | null;
  reportingModuleAccess: boolean;
}

export interface UsersQuery {
  users: UsersQuery_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TagIdsQuery
// ====================================================

export interface TagIdsQuery_tags {
  __typename: 'Tag';
  id: string;
}

export interface TagIdsQuery {
  tags: TagIdsQuery_tags[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TagsQuery
// ====================================================

export interface TagsQuery_tags_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface TagsQuery_tags_parkingPlace_room_ward_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface TagsQuery_tags_parkingPlace_room_ward {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: TagsQuery_tags_parkingPlace_room_ward_workspace;
}

export interface TagsQuery_tags_parkingPlace_room {
  __typename: 'Room';
  id: string;
  name: string;
  ward: TagsQuery_tags_parkingPlace_room_ward;
}

export interface TagsQuery_tags_parkingPlace {
  __typename: 'ParkingPlace';
  id: string;
  name: string | null;
  room: TagsQuery_tags_parkingPlace_room;
}

export interface TagsQuery_tags {
  __typename: 'Tag';
  id: string;
  charge: number | null;
  bed: TagsQuery_tags_bed | null;
  parkingPlace: TagsQuery_tags_parkingPlace | null;
}

export interface TagsQuery {
  tags: TagsQuery_tags[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BedQuery
// ====================================================

export interface BedQuery_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface BedQuery_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  bedsCount: number;
  roomsCount: number;
  wards: BedQuery_bed_workspace_wards[];
}

export interface BedQuery_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface BedQuery_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface BedQuery_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
}

export interface BedQuery_bed_ownerWorkspace {
  __typename: 'Workspace';
  id: string;
}

export interface BedQuery_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  state: BedState;
  unitSn: string | null;
  bedSn: string | null;
  power: PowerState;
  ipAddress: string | null;
  ipAddressEthernet: string | null;
  macAddress: string | null;
  macAddressEthernet: string | null;
  isVirtual: boolean | null;
  ident: string | null;
  type: BedType | null;
  tagId: string | null;
  ownerWardId: string | null;
  workspace: BedQuery_bed_workspace | null;
  ward: BedQuery_bed_ward | null;
  room: BedQuery_bed_room | null;
  parkingPlaces: BedQuery_bed_parkingPlaces[];
  ownerWorkspace: BedQuery_bed_ownerWorkspace | null;
}

export interface BedQuery {
  bed: BedQuery_bed | null;
}

export interface BedQueryVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: bedsQuery
// ====================================================

export interface bedsQuery_beds_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface bedsQuery_beds_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  bedsCount: number;
  roomsCount: number;
  wards: bedsQuery_beds_workspace_wards[];
}

export interface bedsQuery_beds_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface bedsQuery_beds_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface bedsQuery_beds_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
}

export interface bedsQuery_beds {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  state: BedState;
  unitSn: string | null;
  bedSn: string | null;
  power: PowerState;
  ipAddress: string | null;
  ipAddressEthernet: string | null;
  macAddress: string | null;
  macAddressEthernet: string | null;
  isVirtual: boolean | null;
  ident: string | null;
  type: BedType | null;
  tagId: string | null;
  ownerWardId: string | null;
  workspace: bedsQuery_beds_workspace | null;
  ward: bedsQuery_beds_ward | null;
  room: bedsQuery_beds_room | null;
  parkingPlaces: bedsQuery_beds_parkingPlaces[];
}

export interface bedsQuery {
  beds: bedsQuery_beds[];
}

export interface bedsQueryVariables {
  roomId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WorkspacesQuery
// ====================================================

export interface WorkspacesQuery_workspaces {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  roomsCount: number;
  bedsCount: number;
  wardsCount: number;
}

export interface WorkspacesQuery {
  workspaces: WorkspacesQuery_workspaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WardsQuery
// ====================================================

export interface WardsQuery_wards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface WardsQuery_wards {
  __typename: 'Ward';
  id: string;
  name: string;
  roomsCount: number;
  bedsCount: number;
  workspace: WardsQuery_wards_workspace;
}

export interface WardsQuery {
  wards: WardsQuery_wards[];
}

export interface WardsQueryVariables {
  workspaceIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WardNamesQuery
// ====================================================

export interface WardNamesQuery_wards {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface WardNamesQuery {
  wards: WardNamesQuery_wards[];
}

export interface WardNamesQueryVariables {
  workspaceId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: wardsQuery
// ====================================================

export interface wardsQuery_wards_setting {
  __typename: 'WardSetting';
  breaksAlertSound: boolean;
  heightAlertSound: boolean;
  backrestAlertSound: boolean;
  siderailAlertSound: boolean;
}

export interface wardsQuery_wards_rooms_parkingPlaces_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface wardsQuery_wards_rooms_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  bed: wardsQuery_wards_rooms_parkingPlaces_bed | null;
}

export interface wardsQuery_wards_rooms {
  __typename: 'Room';
  id: string;
  name: string;
  parkingPlaces: wardsQuery_wards_rooms_parkingPlaces[];
}

export interface wardsQuery_wards_workspace {
  __typename: 'Workspace';
  id: string;
}

export interface wardsQuery_wards {
  __typename: 'Ward';
  id: string;
  name: string;
  setting: wardsQuery_wards_setting | null;
  rooms: wardsQuery_wards_rooms[];
  workspace: wardsQuery_wards_workspace;
}

export interface wardsQuery {
  wards: wardsQuery_wards[];
}

export interface wardsQueryVariables {
  workspaceIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoomsQuery
// ====================================================

export interface RoomsQuery_rooms {
  __typename: 'Room';
  id: string;
  name: string;
  bedsCount: number;
}

export interface RoomsQuery {
  rooms: RoomsQuery_rooms[];
}

export interface RoomsQueryVariables {
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoomNamesQuery
// ====================================================

export interface RoomNamesQuery_rooms {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface RoomNamesQuery {
  rooms: RoomNamesQuery_rooms[];
}

export interface RoomNamesQueryVariables {
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConfigQuery
// ====================================================

export interface ConfigQuery_config {
  __typename: 'Config';
  hospitalName: string | null;
  defaultPause: number | null;
  unitSystem: WeightUnits;
  noteConfirmationPeriod: number | null;
  logoURL: string | null;
  serverIp: string | null;
}

export interface ConfigQuery_superConfig {
  __typename: 'SuperConfig';
  spEnabled: boolean | null;
  spVersion: string | null;
  spDashboardVersion: string | null;
  patientEnabled: boolean | null;
  spDashboardEnabled: boolean | null;
  spDashboardLicenceExpiration: any | null;
  respiratoryMobilizationEnabled: boolean | null;
  bedManagementEnabled: boolean | null;
  patientLicenceExpiration: any | null;
  bedManagementLicenceExpiration: any | null;
  respiratoryMobilizationLicenceExpiration: any | null;
  utilizationEnabled: boolean | null;
  utilizationLicenceExpiration: any | null;
  syncCommunication: boolean | null;
  hl7LogEnabled: boolean | null;
  reportingModuleAccess: boolean;
  passwordFlags: number | null;
  passwordMinLength: number | null;
  adminPasswordFlags: number | null;
  adminPasswordMinLength: number | null;
}

export interface ConfigQuery {
  config: ConfigQuery_config | null;
  superConfig: ConfigQuery_superConfig | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConnectionsQuery
// ====================================================

export interface ConnectionsQuery_connections_props {
  __typename: 'ConnectionProp';
  key: SafetyPortProps;
  enabled: boolean;
  threshold: number | null;
}

export interface ConnectionsQuery_connections {
  __typename: 'Connection';
  id: string;
  name: string;
  enabled: boolean;
  ip: string | null;
  port: number | null;
  synchronous: boolean;
  syncPeriod: number | null;
  props: ConnectionsQuery_connections_props[];
}

export interface ConnectionsQuery {
  connections: ConnectionsQuery_connections[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConnectionNamesQuery
// ====================================================

export interface ConnectionNamesQuery_connections {
  __typename: 'Connection';
  id: string;
  name: string;
}

export interface ConnectionNamesQuery {
  connections: ConnectionNamesQuery_connections[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: safetyPortPropsQuery
// ====================================================

export interface safetyPortPropsQuery_safetyPortProps {
  __typename: 'SafetyPortProp';
  id: SafetyPortProps;
  enabled: boolean;
  threshold: number | null;
}

export interface safetyPortPropsQuery {
  safetyPortProps: safetyPortPropsQuery_safetyPortProps[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VirtualBed
// ====================================================

export interface VirtualBed_virtualBed {
  __typename: 'VirtualBed';
  weight: number;
  tag: string | null;
  id: string;
  unitId: string;
  trendelenburg: number;
  smPause: any | null;
  lateralTilt: number;
  backrestAngle: number;
  bedExitStatus: BedExitStatus;
  bedExitAlarm: boolean;
  brakes: boolean;
  lowestPosition: boolean;
  serverConnection: ServerConnection;
  powerStatus: PowerStatus;
  leftHeadSiderail: Position;
  rightHeadSiderail: Position;
  leftSiderail: Position;
  rightSiderail: Position;
  mobiliftRight: Mobilift;
  mobiliftLeft: Mobilift;
  calfrestAngle: number;
  leftLegColumn: number;
  rightLegColumn: number;
  headColumn: number;
  mattressType: number;
  altPhase: number;
  errorCode: number;
  backrestLock: boolean;
  heightLock: boolean;
  calfrestLock: boolean;
  thighrestLock: boolean;
  bedExtension: boolean;
  transfer: boolean;
  batteryFailure: boolean;
  controller: number;
  buttonPressed: number;
}

export interface VirtualBed {
  virtualBed: VirtualBed_virtualBed;
}

export interface VirtualBedVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: roomQuery
// ====================================================

export interface roomQuery_room_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
}

export interface roomQuery_room_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface roomQuery_room_parkingPlaces_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface roomQuery_room_parkingPlaces_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface roomQuery_room_parkingPlaces_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface roomQuery_room_parkingPlaces_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface roomQuery_room_parkingPlaces_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: roomQuery_room_parkingPlaces_bed_workspace_wards[];
  bedsCount: number;
  roomsCount: number;
}

export interface roomQuery_room_parkingPlaces_bed {
  __typename: 'Bed';
  tagId: string | null;
  unitId: string;
  name: string | null;
  bedSn: string | null;
  unitSn: string | null;
  state: BedState;
  ownerWardId: string | null;
  isVirtual: boolean | null;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  room: roomQuery_room_parkingPlaces_bed_room | null;
  ward: roomQuery_room_parkingPlaces_bed_ward | null;
  parkingPlaces: roomQuery_room_parkingPlaces_bed_parkingPlaces[];
  workspace: roomQuery_room_parkingPlaces_bed_workspace | null;
}

export interface roomQuery_room_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  ipAddressEthernet: string | null;
  mode: ParkingPlaceMode;
  bed: roomQuery_room_parkingPlaces_bed | null;
}

export interface roomQuery_room {
  __typename: 'Room';
  id: string;
  name: string;
  workspace: roomQuery_room_workspace;
  ward: roomQuery_room_ward;
  parkingPlaces: roomQuery_room_parkingPlaces[];
}

export interface roomQuery {
  room: roomQuery_room | null;
}

export interface roomQueryVariables {
  roomId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ParkingPlaceNamesQuery
// ====================================================

export interface ParkingPlaceNamesQuery_parkingPlaces_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface ParkingPlaceNamesQuery_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  name: string | null;
  room: ParkingPlaceNamesQuery_parkingPlaces_room;
}

export interface ParkingPlaceNamesQuery {
  parkingPlaces: ParkingPlaceNamesQuery_parkingPlaces[];
}

export interface ParkingPlaceNamesQueryVariables {
  roomId?: string | null;
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ParkingPlacesQuery
// ====================================================

export interface ParkingPlacesQuery_room_parkingPlaces_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface ParkingPlacesQuery_room_parkingPlaces_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface ParkingPlacesQuery_room_parkingPlaces_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface ParkingPlacesQuery_room_parkingPlaces_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface ParkingPlacesQuery_room_parkingPlaces_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: ParkingPlacesQuery_room_parkingPlaces_bed_workspace_wards[];
  bedsCount: number;
  roomsCount: number;
}

export interface ParkingPlacesQuery_room_parkingPlaces_bed {
  __typename: 'Bed';
  tagId: string | null;
  unitId: string;
  name: string | null;
  bedSn: string | null;
  unitSn: string | null;
  state: BedState;
  ownerWardId: string | null;
  isVirtual: boolean | null;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  room: ParkingPlacesQuery_room_parkingPlaces_bed_room | null;
  ward: ParkingPlacesQuery_room_parkingPlaces_bed_ward | null;
  parkingPlaces: ParkingPlacesQuery_room_parkingPlaces_bed_parkingPlaces[];
  workspace: ParkingPlacesQuery_room_parkingPlaces_bed_workspace | null;
}

export interface ParkingPlacesQuery_room_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  ipAddressEthernet: string | null;
  mode: ParkingPlaceMode;
  bed: ParkingPlacesQuery_room_parkingPlaces_bed | null;
}

export interface ParkingPlacesQuery_room {
  __typename: 'Room';
  id: string;
  name: string;
  parkingPlaces: ParkingPlacesQuery_room_parkingPlaces[];
}

export interface ParkingPlacesQuery {
  room: ParkingPlacesQuery_room | null;
}

export interface ParkingPlacesQueryVariables {
  roomId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: allRoomsQuery
// ====================================================

export interface allRoomsQuery_rooms_parkingPlaces_bed {
  __typename: 'Bed';
  unitId: string;
  state: BedState;
}

export interface allRoomsQuery_rooms_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  bed: allRoomsQuery_rooms_parkingPlaces_bed | null;
}

export interface allRoomsQuery_rooms_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
}

export interface allRoomsQuery_rooms_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface allRoomsQuery_rooms {
  __typename: 'Room';
  id: string;
  name: string;
  parkingPlaces: allRoomsQuery_rooms_parkingPlaces[];
  workspace: allRoomsQuery_rooms_workspace;
  ward: allRoomsQuery_rooms_ward;
}

export interface allRoomsQuery {
  rooms: allRoomsQuery_rooms[];
}

export interface allRoomsQueryVariables {
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MeWithSetting
// ====================================================

export interface MeWithSetting_me_accessibleWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface MeWithSetting_me_accessibleWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: MeWithSetting_me_accessibleWards_workspace;
}

export interface MeWithSetting_me_assignedWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface MeWithSetting_me_assignedWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: MeWithSetting_me_assignedWards_workspace;
}

export interface MeWithSetting_me_userSetting {
  __typename: 'UserSetting';
  userId: string;
  svTheme: string | null;
  anonymized: boolean;
  dashboardOrder: string[];
  dashboardOrder2: string[] | null;
  dashboardPageSize: number;
  dashboardFontSize: FontSize;
  showOwnedBeds: boolean;
  singleLineLayout: boolean;
}

export interface MeWithSetting_me {
  __typename: 'User';
  id: string;
  role: UserRole;
  accessibleWards: MeWithSetting_me_accessibleWards[] | null;
  assignedWards: MeWithSetting_me_assignedWards[] | null;
  username: string;
  firstName: string | null;
  lastName: string | null;
  locale: string | null;
  email: string;
  lastAccess: any | null;
  vbAccess: boolean;
  demoAccess: boolean;
  serverIp: string | null;
  ssid: string | null;
  ssidKey: string | null;
  ssidKeyEncrypt: boolean | null;
  reportingModuleAccess: boolean;
  userSetting: MeWithSetting_me_userSetting | null;
}

export interface MeWithSetting_superConfig {
  __typename: 'SuperConfig';
  spDashboardVersion: string | null;
}

export interface MeWithSetting {
  me: MeWithSetting_me | null;
  superConfig: MeWithSetting_superConfig | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentBedExitEvents
// ====================================================

export interface CurrentBedExitEvents_currentBedExitEvents {
  __typename: 'BedExitEvent';
  unitId: string | null;
  duration: number | null;
  startedAt: any | null;
}

export interface CurrentBedExitEvents {
  currentBedExitEvents: CurrentBedExitEvents_currentBedExitEvents[];
}

export interface CurrentBedExitEventsVariables {
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: me
// ====================================================

export interface me_me_accessibleWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface me_me_accessibleWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: me_me_accessibleWards_workspace;
}

export interface me_me_assignedWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface me_me_assignedWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: me_me_assignedWards_workspace;
}

export interface me_me_userSetting {
  __typename: 'UserSetting';
  userId: string;
  svTheme: string | null;
  anonymized: boolean;
  dashboardOrder: string[];
  dashboardOrder2: string[] | null;
  dashboardPageSize: number;
  dashboardFontSize: FontSize;
  showOwnedBeds: boolean;
  singleLineLayout: boolean;
}

export interface me_me {
  __typename: 'User';
  id: string;
  role: UserRole;
  accessibleWards: me_me_accessibleWards[] | null;
  assignedWards: me_me_assignedWards[] | null;
  username: string;
  firstName: string | null;
  lastName: string | null;
  locale: string | null;
  email: string;
  lastAccess: any | null;
  vbAccess: boolean;
  demoAccess: boolean;
  serverIp: string | null;
  ssid: string | null;
  ssidKey: string | null;
  ssidKeyEncrypt: boolean | null;
  reportingModuleAccess: boolean;
  userSetting: me_me_userSetting | null;
}

export interface me {
  me: me_me | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: parkingPlacesQuery
// ====================================================

export interface parkingPlacesQuery_parkingPlaces_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface parkingPlacesQuery_parkingPlaces_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface parkingPlacesQuery_parkingPlaces_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface parkingPlacesQuery_parkingPlaces_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface parkingPlacesQuery_parkingPlaces_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: parkingPlacesQuery_parkingPlaces_bed_workspace_wards[];
  bedsCount: number;
  roomsCount: number;
}

export interface parkingPlacesQuery_parkingPlaces_bed {
  __typename: 'Bed';
  tagId: string | null;
  unitId: string;
  name: string | null;
  bedSn: string | null;
  unitSn: string | null;
  state: BedState;
  ownerWardId: string | null;
  isVirtual: boolean | null;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  room: parkingPlacesQuery_parkingPlaces_bed_room | null;
  ward: parkingPlacesQuery_parkingPlaces_bed_ward | null;
  parkingPlaces: parkingPlacesQuery_parkingPlaces_bed_parkingPlaces[];
  workspace: parkingPlacesQuery_parkingPlaces_bed_workspace | null;
}

export interface parkingPlacesQuery_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  ipAddressEthernet: string | null;
  mode: ParkingPlaceMode;
  bed: parkingPlacesQuery_parkingPlaces_bed | null;
}

export interface parkingPlacesQuery {
  parkingPlaces: parkingPlacesQuery_parkingPlaces[];
}

export interface parkingPlacesQueryVariables {
  roomId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardParkingPlacesQuery
// ====================================================

export interface DashboardParkingPlacesQuery_parkingPlaces_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface DashboardParkingPlacesQuery_parkingPlaces_bed_ward_setting {
  __typename: 'WardSetting';
  breaksAlertSound: boolean;
  heightAlertSound: boolean;
  backrestAlertSound: boolean;
  siderailAlertSound: boolean;
}

export interface DashboardParkingPlacesQuery_parkingPlaces_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
  setting: DashboardParkingPlacesQuery_parkingPlaces_bed_ward_setting | null;
}

export interface DashboardParkingPlacesQuery_parkingPlaces_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface DashboardParkingPlacesQuery_parkingPlaces_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface DashboardParkingPlacesQuery_parkingPlaces_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: DashboardParkingPlacesQuery_parkingPlaces_bed_workspace_wards[];
  bedsCount: number;
  roomsCount: number;
}

export interface DashboardParkingPlacesQuery_parkingPlaces_bed {
  __typename: 'Bed';
  tagId: string | null;
  unitId: string;
  name: string | null;
  bedSn: string | null;
  unitSn: string | null;
  state: BedState;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  breaksAlertSoundMuted: boolean | null;
  heightAlertSoundMuted: boolean | null;
  backrestAlertSoundMuted: boolean | null;
  siderailAlertSoundMuted: boolean | null;
  room: DashboardParkingPlacesQuery_parkingPlaces_bed_room | null;
  ward: DashboardParkingPlacesQuery_parkingPlaces_bed_ward | null;
  parkingPlaces: DashboardParkingPlacesQuery_parkingPlaces_bed_parkingPlaces[];
  workspace: DashboardParkingPlacesQuery_parkingPlaces_bed_workspace | null;
}

export interface DashboardParkingPlacesQuery_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  bed: DashboardParkingPlacesQuery_parkingPlaces_bed | null;
}

export interface DashboardParkingPlacesQuery {
  parkingPlaces: DashboardParkingPlacesQuery_parkingPlaces[];
}

export interface DashboardParkingPlacesQueryVariables {
  roomId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SafetyViewWardBeds
// ====================================================

export interface SafetyViewWardBeds_wardBeds_currentSession {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  note: string | null;
  sessionStart: any;
  safetyPositionPresetId: string | null;
  fallRisk: FallRisk;
}

export interface SafetyViewWardBeds_wardBeds_parkingPlaces_room_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface SafetyViewWardBeds_wardBeds_parkingPlaces_room {
  __typename: 'Room';
  id: string;
  name: string;
  ward: SafetyViewWardBeds_wardBeds_parkingPlaces_room_ward;
}

export interface SafetyViewWardBeds_wardBeds_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  name: string | null;
  room: SafetyViewWardBeds_wardBeds_parkingPlaces_room;
}

export interface SafetyViewWardBeds_wardBeds {
  __typename: 'Bed';
  unitId: string;
  bedSn: string | null;
  name: string | null;
  ident: string | null;
  state: BedState;
  type: BedType | null;
  isVirtual: boolean | null;
  pinned: boolean;
  ipAddressEthernet: string | null;
  currentSession: SafetyViewWardBeds_wardBeds_currentSession | null;
  parkingPlaces: SafetyViewWardBeds_wardBeds_parkingPlaces[];
}

export interface SafetyViewWardBeds {
  wardBeds: SafetyViewWardBeds_wardBeds[];
}

export interface SafetyViewWardBedsVariables {
  wardId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SafetyViewQuery
// ====================================================

export interface SafetyViewQuery_wardParkingPlaces_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface SafetyViewQuery_wardParkingPlaces_bed_currentSession {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  note: string | null;
  sessionStart: any;
  safetyPositionPresetId: string | null;
  fallRisk: FallRisk;
}

export interface SafetyViewQuery_wardParkingPlaces_bed_previousSession {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  note: string | null;
  sessionStart: any;
  sessionEnd: any | null;
  safetyPositionPresetId: string | null;
  fallRisk: FallRisk;
}

export interface SafetyViewQuery_wardParkingPlaces_bed {
  __typename: 'Bed';
  unitId: string;
  bedSn: string | null;
  name: string | null;
  ident: string | null;
  state: BedState;
  type: BedType | null;
  isVirtual: boolean | null;
  ipAddressEthernet: string | null;
  pinned: boolean;
  currentSession: SafetyViewQuery_wardParkingPlaces_bed_currentSession | null;
  previousSession: SafetyViewQuery_wardParkingPlaces_bed_previousSession | null;
}

export interface SafetyViewQuery_wardParkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  name: string | null;
  room: SafetyViewQuery_wardParkingPlaces_room;
  bed: SafetyViewQuery_wardParkingPlaces_bed | null;
}

export interface SafetyViewQuery_me_userSetting {
  __typename: 'UserSetting';
  userId: string;
  dashboardOrder: string[];
  dashboardOrder2: string[] | null;
  dashboardPageSize: number;
  dashboardFontSize: FontSize;
  showOwnedBeds: boolean;
  anonymized: boolean;
  singleLineLayout: boolean;
}

export interface SafetyViewQuery_me {
  __typename: 'User';
  id: string;
  userSetting: SafetyViewQuery_me_userSetting | null;
}

export interface SafetyViewQuery {
  wardParkingPlaces: SafetyViewQuery_wardParkingPlaces[];
  me: SafetyViewQuery_me | null;
}

export interface SafetyViewQueryVariables {
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SessionStatistics
// ====================================================

export interface SessionStatistics_statistics_outOfBed {
  __typename: 'EndpointStatistics';
  onTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics_safetyPosition {
  __typename: 'EndpointStatistics';
  onTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics_allSiderailsCombined {
  __typename: 'EndpointStatistics';
  onTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics_lowestPosition {
  __typename: 'EndpointStatistics';
  onTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics_brakes {
  __typename: 'EndpointStatistics';
  onTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics_backrest30 {
  __typename: 'EndpointStatistics';
  onTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics_bedExitStatus {
  __typename: 'BedExitStatistics';
  izTime: number | null;
  ozTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics_power {
  __typename: 'EndpointStatistics';
  onTime: number | null;
  offTime: number | null;
}

export interface SessionStatistics_statistics {
  __typename: 'SessionStatistics';
  outOfBed: SessionStatistics_statistics_outOfBed | null;
  safetyPosition: SessionStatistics_statistics_safetyPosition | null;
  allSiderailsCombined: SessionStatistics_statistics_allSiderailsCombined | null;
  lowestPosition: SessionStatistics_statistics_lowestPosition | null;
  brakes: SessionStatistics_statistics_brakes | null;
  backrest30: SessionStatistics_statistics_backrest30 | null;
  bedExitStatus: SessionStatistics_statistics_bedExitStatus | null;
  power: SessionStatistics_statistics_power | null;
}

export interface SessionStatistics {
  statistics: SessionStatistics_statistics;
}

export interface SessionStatisticsVariables {
  id: string;
  from: any;
  to: any;
  timezoneOffset: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SessionTrends
// ====================================================

export interface SessionTrends_firstDay_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_firstDay_backrest30 {
  __typename: 'EndpointTrend';
  data: SessionTrends_firstDay_backrest30_data[];
  duration: number;
}

export interface SessionTrends_firstDay_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_firstDay_backrest45 {
  __typename: 'EndpointTrend';
  data: SessionTrends_firstDay_backrest45_data[];
  duration: number;
}

export interface SessionTrends_firstDay_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_firstDay_alt {
  __typename: 'EndpointTrend';
  data: SessionTrends_firstDay_alt_data[];
  duration: number;
}

export interface SessionTrends_firstDay_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_firstDay_trendelenburg {
  __typename: 'EndpointTrend';
  data: SessionTrends_firstDay_trendelenburg_data[];
  duration: number;
}

export interface SessionTrends_firstDay_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_firstDay_lateralTilt {
  __typename: 'EndpointTrend';
  data: SessionTrends_firstDay_lateralTilt_data[];
  duration: number;
}

export interface SessionTrends_firstDay_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_firstDay_mobilift {
  __typename: 'EndpointTrend';
  data: SessionTrends_firstDay_mobilift_data[];
  duration: number;
}

export interface SessionTrends_firstDay_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_firstDay_mattressType {
  __typename: 'EndpointTrend';
  data: SessionTrends_firstDay_mattressType_data[];
  duration: number;
}

export interface SessionTrends_firstDay {
  __typename: 'SessionTrends';
  backrest30: SessionTrends_firstDay_backrest30 | null;
  backrest45: SessionTrends_firstDay_backrest45 | null;
  alt: SessionTrends_firstDay_alt | null;
  trendelenburg: SessionTrends_firstDay_trendelenburg | null;
  lateralTilt: SessionTrends_firstDay_lateralTilt | null;
  mobilift: SessionTrends_firstDay_mobilift | null;
  mattressType: SessionTrends_firstDay_mattressType | null;
}

export interface SessionTrends_secondDay_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_secondDay_backrest30 {
  __typename: 'EndpointTrend';
  data: SessionTrends_secondDay_backrest30_data[];
  duration: number;
}

export interface SessionTrends_secondDay_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_secondDay_backrest45 {
  __typename: 'EndpointTrend';
  data: SessionTrends_secondDay_backrest45_data[];
  duration: number;
}

export interface SessionTrends_secondDay_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_secondDay_alt {
  __typename: 'EndpointTrend';
  data: SessionTrends_secondDay_alt_data[];
  duration: number;
}

export interface SessionTrends_secondDay_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_secondDay_trendelenburg {
  __typename: 'EndpointTrend';
  data: SessionTrends_secondDay_trendelenburg_data[];
  duration: number;
}

export interface SessionTrends_secondDay_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_secondDay_lateralTilt {
  __typename: 'EndpointTrend';
  data: SessionTrends_secondDay_lateralTilt_data[];
  duration: number;
}

export interface SessionTrends_secondDay_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_secondDay_mobilift {
  __typename: 'EndpointTrend';
  data: SessionTrends_secondDay_mobilift_data[];
  duration: number;
}

export interface SessionTrends_secondDay_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_secondDay_mattressType {
  __typename: 'EndpointTrend';
  data: SessionTrends_secondDay_mattressType_data[];
  duration: number;
}

export interface SessionTrends_secondDay {
  __typename: 'SessionTrends';
  backrest30: SessionTrends_secondDay_backrest30 | null;
  backrest45: SessionTrends_secondDay_backrest45 | null;
  alt: SessionTrends_secondDay_alt | null;
  trendelenburg: SessionTrends_secondDay_trendelenburg | null;
  lateralTilt: SessionTrends_secondDay_lateralTilt | null;
  mobilift: SessionTrends_secondDay_mobilift | null;
  mattressType: SessionTrends_secondDay_mattressType | null;
}

export interface SessionTrends_thirdDay_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_thirdDay_backrest30 {
  __typename: 'EndpointTrend';
  data: SessionTrends_thirdDay_backrest30_data[];
  duration: number;
}

export interface SessionTrends_thirdDay_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_thirdDay_backrest45 {
  __typename: 'EndpointTrend';
  data: SessionTrends_thirdDay_backrest45_data[];
  duration: number;
}

export interface SessionTrends_thirdDay_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_thirdDay_alt {
  __typename: 'EndpointTrend';
  data: SessionTrends_thirdDay_alt_data[];
  duration: number;
}

export interface SessionTrends_thirdDay_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_thirdDay_trendelenburg {
  __typename: 'EndpointTrend';
  data: SessionTrends_thirdDay_trendelenburg_data[];
  duration: number;
}

export interface SessionTrends_thirdDay_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_thirdDay_lateralTilt {
  __typename: 'EndpointTrend';
  data: SessionTrends_thirdDay_lateralTilt_data[];
  duration: number;
}

export interface SessionTrends_thirdDay_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_thirdDay_mobilift {
  __typename: 'EndpointTrend';
  data: SessionTrends_thirdDay_mobilift_data[];
  duration: number;
}

export interface SessionTrends_thirdDay_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_thirdDay_mattressType {
  __typename: 'EndpointTrend';
  data: SessionTrends_thirdDay_mattressType_data[];
  duration: number;
}

export interface SessionTrends_thirdDay {
  __typename: 'SessionTrends';
  backrest30: SessionTrends_thirdDay_backrest30 | null;
  backrest45: SessionTrends_thirdDay_backrest45 | null;
  alt: SessionTrends_thirdDay_alt | null;
  trendelenburg: SessionTrends_thirdDay_trendelenburg | null;
  lateralTilt: SessionTrends_thirdDay_lateralTilt | null;
  mobilift: SessionTrends_thirdDay_mobilift | null;
  mattressType: SessionTrends_thirdDay_mattressType | null;
}

export interface SessionTrends_fourthDay_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fourthDay_backrest30 {
  __typename: 'EndpointTrend';
  data: SessionTrends_fourthDay_backrest30_data[];
  duration: number;
}

export interface SessionTrends_fourthDay_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fourthDay_backrest45 {
  __typename: 'EndpointTrend';
  data: SessionTrends_fourthDay_backrest45_data[];
  duration: number;
}

export interface SessionTrends_fourthDay_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fourthDay_alt {
  __typename: 'EndpointTrend';
  data: SessionTrends_fourthDay_alt_data[];
  duration: number;
}

export interface SessionTrends_fourthDay_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fourthDay_trendelenburg {
  __typename: 'EndpointTrend';
  data: SessionTrends_fourthDay_trendelenburg_data[];
  duration: number;
}

export interface SessionTrends_fourthDay_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fourthDay_lateralTilt {
  __typename: 'EndpointTrend';
  data: SessionTrends_fourthDay_lateralTilt_data[];
  duration: number;
}

export interface SessionTrends_fourthDay_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fourthDay_mobilift {
  __typename: 'EndpointTrend';
  data: SessionTrends_fourthDay_mobilift_data[];
  duration: number;
}

export interface SessionTrends_fourthDay_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fourthDay_mattressType {
  __typename: 'EndpointTrend';
  data: SessionTrends_fourthDay_mattressType_data[];
  duration: number;
}

export interface SessionTrends_fourthDay {
  __typename: 'SessionTrends';
  backrest30: SessionTrends_fourthDay_backrest30 | null;
  backrest45: SessionTrends_fourthDay_backrest45 | null;
  alt: SessionTrends_fourthDay_alt | null;
  trendelenburg: SessionTrends_fourthDay_trendelenburg | null;
  lateralTilt: SessionTrends_fourthDay_lateralTilt | null;
  mobilift: SessionTrends_fourthDay_mobilift | null;
  mattressType: SessionTrends_fourthDay_mattressType | null;
}

export interface SessionTrends_fifthDay_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fifthDay_backrest30 {
  __typename: 'EndpointTrend';
  data: SessionTrends_fifthDay_backrest30_data[];
  duration: number;
}

export interface SessionTrends_fifthDay_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fifthDay_backrest45 {
  __typename: 'EndpointTrend';
  data: SessionTrends_fifthDay_backrest45_data[];
  duration: number;
}

export interface SessionTrends_fifthDay_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fifthDay_alt {
  __typename: 'EndpointTrend';
  data: SessionTrends_fifthDay_alt_data[];
  duration: number;
}

export interface SessionTrends_fifthDay_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fifthDay_trendelenburg {
  __typename: 'EndpointTrend';
  data: SessionTrends_fifthDay_trendelenburg_data[];
  duration: number;
}

export interface SessionTrends_fifthDay_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fifthDay_lateralTilt {
  __typename: 'EndpointTrend';
  data: SessionTrends_fifthDay_lateralTilt_data[];
  duration: number;
}

export interface SessionTrends_fifthDay_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fifthDay_mobilift {
  __typename: 'EndpointTrend';
  data: SessionTrends_fifthDay_mobilift_data[];
  duration: number;
}

export interface SessionTrends_fifthDay_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_fifthDay_mattressType {
  __typename: 'EndpointTrend';
  data: SessionTrends_fifthDay_mattressType_data[];
  duration: number;
}

export interface SessionTrends_fifthDay {
  __typename: 'SessionTrends';
  backrest30: SessionTrends_fifthDay_backrest30 | null;
  backrest45: SessionTrends_fifthDay_backrest45 | null;
  alt: SessionTrends_fifthDay_alt | null;
  trendelenburg: SessionTrends_fifthDay_trendelenburg | null;
  lateralTilt: SessionTrends_fifthDay_lateralTilt | null;
  mobilift: SessionTrends_fifthDay_mobilift | null;
  mattressType: SessionTrends_fifthDay_mattressType | null;
}

export interface SessionTrends_sixthDay_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_sixthDay_backrest30 {
  __typename: 'EndpointTrend';
  data: SessionTrends_sixthDay_backrest30_data[];
  duration: number;
}

export interface SessionTrends_sixthDay_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_sixthDay_backrest45 {
  __typename: 'EndpointTrend';
  data: SessionTrends_sixthDay_backrest45_data[];
  duration: number;
}

export interface SessionTrends_sixthDay_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_sixthDay_alt {
  __typename: 'EndpointTrend';
  data: SessionTrends_sixthDay_alt_data[];
  duration: number;
}

export interface SessionTrends_sixthDay_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_sixthDay_trendelenburg {
  __typename: 'EndpointTrend';
  data: SessionTrends_sixthDay_trendelenburg_data[];
  duration: number;
}

export interface SessionTrends_sixthDay_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_sixthDay_lateralTilt {
  __typename: 'EndpointTrend';
  data: SessionTrends_sixthDay_lateralTilt_data[];
  duration: number;
}

export interface SessionTrends_sixthDay_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_sixthDay_mobilift {
  __typename: 'EndpointTrend';
  data: SessionTrends_sixthDay_mobilift_data[];
  duration: number;
}

export interface SessionTrends_sixthDay_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_sixthDay_mattressType {
  __typename: 'EndpointTrend';
  data: SessionTrends_sixthDay_mattressType_data[];
  duration: number;
}

export interface SessionTrends_sixthDay {
  __typename: 'SessionTrends';
  backrest30: SessionTrends_sixthDay_backrest30 | null;
  backrest45: SessionTrends_sixthDay_backrest45 | null;
  alt: SessionTrends_sixthDay_alt | null;
  trendelenburg: SessionTrends_sixthDay_trendelenburg | null;
  lateralTilt: SessionTrends_sixthDay_lateralTilt | null;
  mobilift: SessionTrends_sixthDay_mobilift | null;
  mattressType: SessionTrends_sixthDay_mattressType | null;
}

export interface SessionTrends_seventhDay_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_seventhDay_backrest30 {
  __typename: 'EndpointTrend';
  data: SessionTrends_seventhDay_backrest30_data[];
  duration: number;
}

export interface SessionTrends_seventhDay_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_seventhDay_backrest45 {
  __typename: 'EndpointTrend';
  data: SessionTrends_seventhDay_backrest45_data[];
  duration: number;
}

export interface SessionTrends_seventhDay_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_seventhDay_alt {
  __typename: 'EndpointTrend';
  data: SessionTrends_seventhDay_alt_data[];
  duration: number;
}

export interface SessionTrends_seventhDay_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_seventhDay_trendelenburg {
  __typename: 'EndpointTrend';
  data: SessionTrends_seventhDay_trendelenburg_data[];
  duration: number;
}

export interface SessionTrends_seventhDay_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_seventhDay_lateralTilt {
  __typename: 'EndpointTrend';
  data: SessionTrends_seventhDay_lateralTilt_data[];
  duration: number;
}

export interface SessionTrends_seventhDay_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_seventhDay_mobilift {
  __typename: 'EndpointTrend';
  data: SessionTrends_seventhDay_mobilift_data[];
  duration: number;
}

export interface SessionTrends_seventhDay_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface SessionTrends_seventhDay_mattressType {
  __typename: 'EndpointTrend';
  data: SessionTrends_seventhDay_mattressType_data[];
  duration: number;
}

export interface SessionTrends_seventhDay {
  __typename: 'SessionTrends';
  backrest30: SessionTrends_seventhDay_backrest30 | null;
  backrest45: SessionTrends_seventhDay_backrest45 | null;
  alt: SessionTrends_seventhDay_alt | null;
  trendelenburg: SessionTrends_seventhDay_trendelenburg | null;
  lateralTilt: SessionTrends_seventhDay_lateralTilt | null;
  mobilift: SessionTrends_seventhDay_mobilift | null;
  mattressType: SessionTrends_seventhDay_mattressType | null;
}

export interface SessionTrends {
  firstDay: SessionTrends_firstDay;
  secondDay: SessionTrends_secondDay;
  thirdDay: SessionTrends_thirdDay;
  fourthDay: SessionTrends_fourthDay;
  fifthDay: SessionTrends_fifthDay;
  sixthDay: SessionTrends_sixthDay;
  seventhDay: SessionTrends_seventhDay;
}

export interface SessionTrendsVariables {
  id: string;
  timezoneOffset: number;
  firstDate: any;
  secondDate: any;
  thirdDate: any;
  fourthDate: any;
  fifthDate: any;
  sixthDate: any;
  seventhDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SessionDataQuery
// ====================================================

export interface SessionDataQuery_sessionData {
  __typename: 'SessionData';
  maxWeight: number | null;
  minWeight: number | null;
  maxBackrestAngle: number | null;
  minBackrestAngle: number | null;
  maxTrendelenburg: number | null;
  minTrendelenburg: number | null;
  brakes: number | null;
  maxLateralTilt: number | null;
  minLateralTilt: number | null;
  bedExitStatus: number | null;
  bedExitAlarm: number | null;
  srbrUp: number | null;
  srblUp: number | null;
  isZoneExit: number | null;
  lowestPosition: number | null;
  outOfBed: number | null;
  srlhUp: number | null;
  srrhUp: number | null;
  srlUp: number | null;
  srrUp: number | null;
  maxCalfrestAngle: number | null;
  minCalfrestAngle: number | null;
  calfrestLock: number | null;
  backrestLock: number | null;
  heightLock: number | null;
  thighrestLock: number | null;
  safetyPosition: number | null;
  maxMattressType: number | null;
  maxALTPhase: number | null;
  time: string | null;
}

export interface SessionDataQuery_me_userSetting {
  __typename: 'UserSetting';
  userId: string;
  pwEndpointOrder: string[];
}

export interface SessionDataQuery_me {
  __typename: 'User';
  id: string;
  userSetting: SessionDataQuery_me_userSetting | null;
}

export interface SessionDataQuery {
  sessionData: (SessionDataQuery_sessionData | null)[];
  me: SessionDataQuery_me | null;
}

export interface SessionDataQueryVariables {
  id: string;
  date?: any | null;
  timezoneOffset: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BedHeaderModalData
// ====================================================

export interface BedHeaderModalData_bed_parkingPlaces_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface BedHeaderModalData_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  name: string | null;
  room: BedHeaderModalData_bed_parkingPlaces_room;
}

export interface BedHeaderModalData_bed_currentSession {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  note: string | null;
  sessionStart: any;
  sessionEnd: any | null;
  fallRisk: FallRisk;
  safetyPositionPresetId: string | null;
}

export interface BedHeaderModalData_bed {
  __typename: 'Bed';
  unitId: string;
  type: BedType | null;
  name: string | null;
  ident: string | null;
  bedSn: string | null;
  parkingPlaces: BedHeaderModalData_bed_parkingPlaces[];
  currentSession: BedHeaderModalData_bed_currentSession | null;
}

export interface BedHeaderModalData {
  bed: BedHeaderModalData_bed | null;
}

export interface BedHeaderModalDataVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MobileBedOverlay
// ====================================================

export interface MobileBedOverlay_bed_parkingPlaces_room {
  __typename: 'Room';
  name: string;
}

export interface MobileBedOverlay_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  name: string | null;
  room: MobileBedOverlay_bed_parkingPlaces_room;
}

export interface MobileBedOverlay_bed_currentSession {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  sessionStart: any;
  fallRisk: FallRisk;
  sessionEnd: any | null;
  safetyPositionPresetId: string | null;
}

export interface MobileBedOverlay_bed {
  __typename: 'Bed';
  unitId: string;
  parkingPlaces: MobileBedOverlay_bed_parkingPlaces[];
  currentSession: MobileBedOverlay_bed_currentSession | null;
}

export interface MobileBedOverlay {
  bed: MobileBedOverlay_bed | null;
}

export interface MobileBedOverlayVariables {
  unitId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SafetyPositionPresets
// ====================================================

export interface SafetyPositionPresets_safetyPositionPresets {
  __typename: 'SafetyPositionPreset';
  id: string;
  name: string;
  editable: boolean;
  backrestAngleEnabled: boolean;
  backrestAngleMin: number;
  backrestAngleMax: number;
  posIsLowestEnabled: boolean;
  brakesEnabled: boolean;
  siderailPositionEnabled: boolean;
  siderailPosition: SiderailsPosition;
  bedExitEnabled: boolean;
  bedExitStatus: BedExitStatus;
}

export interface SafetyPositionPresets {
  safetyPositionPresets: SafetyPositionPresets_safetyPositionPresets[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HistorySessions
// ====================================================

export interface HistorySessions_patientSessions_edges_node {
  __typename: 'PatientSession';
  id: string;
  name: string | null;
  note: string | null;
  unitId: string;
  sessionStart: any;
  fallRisk: FallRisk;
  sessionEnd: any | null;
  safetyPositionPresetId: string | null;
  locationHistory: string | null;
}

export interface HistorySessions_patientSessions_edges {
  __typename: 'PatientSessionEdge';
  node: HistorySessions_patientSessions_edges_node;
  cursor: string;
}

export interface HistorySessions_patientSessions_pageInfo {
  __typename: 'PageInfo';
  hasNextPage: boolean;
  endCursor: string | null;
}

export interface HistorySessions_patientSessions {
  __typename: 'PatientSessionsConnection';
  edges: HistorySessions_patientSessions_edges[];
  pageInfo: HistorySessions_patientSessions_pageInfo;
  totalCount: number;
}

export interface HistorySessions {
  patientSessions: HistorySessions_patientSessions;
}

export interface HistorySessionsVariables {
  input: PatientSessionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PatientCareNotes
// ====================================================

export interface PatientCareNotes_patientSession_careNotes {
  __typename: 'PatientNote';
  id: string;
  note: string | null;
  dateCreated: any;
  dateConfirmed: any | null;
}

export interface PatientCareNotes_patientSession {
  __typename: 'PatientSession';
  id: string;
  careNotes: (PatientCareNotes_patientSession_careNotes | null)[];
}

export interface PatientCareNotes {
  patientSession: PatientCareNotes_patientSession | null;
}

export interface PatientCareNotesVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BedFilters
// ====================================================

export interface BedFilters_tags {
  __typename: 'Tag';
  id: string;
}

export interface BedFilters_wards {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface BedFilters_workspaces {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface BedFilters_rooms {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface BedFilters {
  tags: BedFilters_tags[];
  wards: BedFilters_wards[];
  workspaces: BedFilters_workspaces[];
  rooms: BedFilters_rooms[];
}

export interface BedFiltersVariables {
  workspaceIds?: string[] | null;
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Hl7LogQuery
// ====================================================

export interface Hl7LogQuery_dataHistory_edges_node {
  __typename: 'SafetyPortData';
  id: string;
  msgId: string | null;
  bedUnitId: string | null;
  prop: SafetyPortProps | null;
  value: string | null;
  timestamp: any | null;
  connectionId: string | null;
  ack: number | null;
}

export interface Hl7LogQuery_dataHistory_edges {
  __typename: 'SafetyPortDataEdge';
  cursor: string;
  node: Hl7LogQuery_dataHistory_edges_node;
}

export interface Hl7LogQuery_dataHistory_pageInfo {
  __typename: 'SPPageInfo';
  endCursor: string | null;
  hasNextPage: boolean;
}

export interface Hl7LogQuery_dataHistory {
  __typename: 'SafetyPortDataConnection';
  totalCount: number;
  edges: Hl7LogQuery_dataHistory_edges[];
  pageInfo: Hl7LogQuery_dataHistory_pageInfo;
}

export interface Hl7LogQuery {
  dataHistory: Hl7LogQuery_dataHistory;
}

export interface Hl7LogQueryVariables {
  bedUnitId?: string | null;
  connectionId?: string | null;
  first?: number | null;
  after?: string | null;
  filter?: SafetyPortProps | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LogoQuery
// ====================================================

export interface LogoQuery_config {
  __typename: 'Config';
  logoURL: string | null;
}

export interface LogoQuery {
  config: LogoQuery_config | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WardSelectQuery
// ====================================================

export interface WardSelectQuery_workspaces_wards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface WardSelectQuery_workspaces_wards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: WardSelectQuery_workspaces_wards_workspace;
}

export interface WardSelectQuery_workspaces {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: WardSelectQuery_workspaces_wards[];
}

export interface WardSelectQuery {
  workspaces: WardSelectQuery_workspaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WardSafetyPositionQuery
// ====================================================

export interface WardSafetyPositionQuery_workspaces_wards {
  __typename: 'Ward';
  id: string;
  name: string;
  safetyPositionPresetId: string | null;
}

export interface WardSafetyPositionQuery_workspaces {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: WardSafetyPositionQuery_workspaces_wards[];
}

export interface WardSafetyPositionQuery {
  workspaces: WardSafetyPositionQuery_workspaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GenerateReportQuery
// ====================================================

export interface GenerateReportQuery_generateReport_tags {
  __typename: 'Tags';
  unitId: string | null;
  workspaceId: string | null;
  wardId: string | null;
  roomId: string | null;
  parkingPlaceId: string | null;
  type: string | null;
  shift: string | null;
  custom: string | null;
}

export interface GenerateReportQuery_generateReport_values {
  __typename: 'ReportValue';
  time: any;
  value: number;
  total: number | null;
  unit: string;
}

export interface GenerateReportQuery_generateReport {
  __typename: 'Report';
  tags: GenerateReportQuery_generateReport_tags;
  columns: string[];
  values: GenerateReportQuery_generateReport_values[];
}

export interface GenerateReportQuery {
  generateReport: GenerateReportQuery_generateReport[];
}

export interface GenerateReportQueryVariables {
  input?: ReportInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportingReportsQuery
// ====================================================

export interface ReportingReportsQuery_reportingReports {
  __typename: 'ReportingReportLitItem';
  id: string;
  name: string;
  goal: number | null;
}

export interface ReportingReportsQuery {
  reportingReports: ReportingReportsQuery_reportingReports[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportingDashboardsQuery
// ====================================================

export interface ReportingDashboardsQuery_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface ReportingDashboardsQuery {
  reportingDashboards: ReportingDashboardsQuery_reportingDashboards[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportingReportQuery
// ====================================================

export interface ReportingReportQuery_reportingReport_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface ReportingReportQuery_reportingReport {
  __typename: 'ReportingReport';
  id: string;
  name: string;
  goal: number | null;
  filterConfig: string;
  reportingDashboards:
    | ReportingReportQuery_reportingReport_reportingDashboards[]
    | null;
}

export interface ReportingReportQuery {
  reportingReport: ReportingReportQuery_reportingReport;
}

export interface ReportingReportQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportingDashboardQuery
// ====================================================

export interface ReportingDashboardQuery_reportingDashboard_reports {
  __typename: 'ReportingDashboardReports';
  reportingReportId: string;
  x: string;
  y: string;
}

export interface ReportingDashboardQuery_reportingDashboard {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
  reports: ReportingDashboardQuery_reportingDashboard_reports[] | null;
}

export interface ReportingDashboardQuery {
  reportingDashboard: ReportingDashboardQuery_reportingDashboard;
}

export interface ReportingDashboardQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReportDataQuery
// ====================================================

export interface ReportDataQuery_reportData_tags {
  __typename: 'Tags';
  unitId: string | null;
  workspaceId: string | null;
  wardId: string | null;
  roomId: string | null;
  parkingPlaceId: string | null;
  type: string | null;
  shift: string | null;
  custom: string | null;
}

export interface ReportDataQuery_reportData_values {
  __typename: 'ReportValue';
  time: any;
  value: number;
  total: number | null;
  unit: string;
}

export interface ReportDataQuery_reportData {
  __typename: 'Report';
  tags: ReportDataQuery_reportData_tags;
  columns: string[];
  values: ReportDataQuery_reportData_values[];
}

export interface ReportDataQuery {
  reportData: ReportDataQuery_reportData[];
}

export interface ReportDataQueryVariables {
  filterConfig: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WardQuery
// ====================================================

export interface WardQuery_ward {
  __typename: 'Ward';
  id: string;
  name: string;
  safetyPositionPresetId: string | null;
}

export interface WardQuery {
  ward: WardQuery_ward | null;
}

export interface WardQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SimulationPresetQuery
// ====================================================

export interface SimulationPresetQuery_simulationPresets {
  __typename: 'SimulationPreset';
  id: string;
  name: string;
  config: string;
}

export interface SimulationPresetQuery {
  simulationPresets: SimulationPresetQuery_simulationPresets[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: BedAdded
// ====================================================

export interface BedAdded_bedAdded_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface BedAdded_bedAdded_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  bedsCount: number;
  roomsCount: number;
  wards: BedAdded_bedAdded_workspace_wards[];
}

export interface BedAdded_bedAdded_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface BedAdded_bedAdded_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface BedAdded_bedAdded_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
}

export interface BedAdded_bedAdded {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  state: BedState;
  unitSn: string | null;
  bedSn: string | null;
  power: PowerState;
  ipAddress: string | null;
  ipAddressEthernet: string | null;
  macAddress: string | null;
  macAddressEthernet: string | null;
  isVirtual: boolean | null;
  ident: string | null;
  type: BedType | null;
  tagId: string | null;
  ownerWardId: string | null;
  workspace: BedAdded_bedAdded_workspace | null;
  ward: BedAdded_bedAdded_ward | null;
  room: BedAdded_bedAdded_room | null;
  parkingPlaces: BedAdded_bedAdded_parkingPlaces[];
}

export interface BedAdded {
  bedAdded: BedAdded_bedAdded;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: BedUpdated
// ====================================================

export interface BedUpdated_bedUpdated_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface BedUpdated_bedUpdated_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  bedsCount: number;
  roomsCount: number;
  wards: BedUpdated_bedUpdated_workspace_wards[];
}

export interface BedUpdated_bedUpdated_ward_setting {
  __typename: 'WardSetting';
  breaksAlertSound: boolean;
  heightAlertSound: boolean;
  backrestAlertSound: boolean;
  siderailAlertSound: boolean;
}

export interface BedUpdated_bedUpdated_ward {
  __typename: 'Ward';
  id: string;
  name: string;
  setting: BedUpdated_bedUpdated_ward_setting | null;
}

export interface BedUpdated_bedUpdated_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface BedUpdated_bedUpdated_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface BedUpdated_bedUpdated {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  state: BedState;
  bedSn: string | null;
  unitSn: string | null;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  tagId: string | null;
  breaksAlertSoundMuted: boolean | null;
  heightAlertSoundMuted: boolean | null;
  backrestAlertSoundMuted: boolean | null;
  siderailAlertSoundMuted: boolean | null;
  workspace: BedUpdated_bedUpdated_workspace | null;
  ward: BedUpdated_bedUpdated_ward | null;
  room: BedUpdated_bedUpdated_room | null;
  parkingPlaces: BedUpdated_bedUpdated_parkingPlaces[];
}

export interface BedUpdated {
  bedUpdated: BedUpdated_bedUpdated;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: RoomUpdated
// ====================================================

export interface RoomUpdated_roomUpdated_parkingPlaces_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface RoomUpdated_roomUpdated_parkingPlaces_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  bedsCount: number;
  roomsCount: number;
  wards: RoomUpdated_roomUpdated_parkingPlaces_bed_workspace_wards[];
}

export interface RoomUpdated_roomUpdated_parkingPlaces_bed_ward_setting {
  __typename: 'WardSetting';
  breaksAlertSound: boolean;
  heightAlertSound: boolean;
  backrestAlertSound: boolean;
  siderailAlertSound: boolean;
}

export interface RoomUpdated_roomUpdated_parkingPlaces_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
  setting: RoomUpdated_roomUpdated_parkingPlaces_bed_ward_setting | null;
}

export interface RoomUpdated_roomUpdated_parkingPlaces_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface RoomUpdated_roomUpdated_parkingPlaces_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface RoomUpdated_roomUpdated_parkingPlaces_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  state: BedState;
  bedSn: string | null;
  unitSn: string | null;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  tagId: string | null;
  breaksAlertSoundMuted: boolean | null;
  heightAlertSoundMuted: boolean | null;
  backrestAlertSoundMuted: boolean | null;
  siderailAlertSoundMuted: boolean | null;
  workspace: RoomUpdated_roomUpdated_parkingPlaces_bed_workspace | null;
  ward: RoomUpdated_roomUpdated_parkingPlaces_bed_ward | null;
  room: RoomUpdated_roomUpdated_parkingPlaces_bed_room | null;
  parkingPlaces: RoomUpdated_roomUpdated_parkingPlaces_bed_parkingPlaces[];
}

export interface RoomUpdated_roomUpdated_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  bed: RoomUpdated_roomUpdated_parkingPlaces_bed | null;
}

export interface RoomUpdated_roomUpdated {
  __typename: 'Room';
  id: string;
  name: string;
  parkingPlaces: RoomUpdated_roomUpdated_parkingPlaces[];
}

export interface RoomUpdated {
  roomUpdated: RoomUpdated_roomUpdated;
}

export interface RoomUpdatedVariables {
  wardId?: string | null;
  roomId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ThreeFOne
// ====================================================

export interface ThreeFOne_threeFOne_devices_IM {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_CONTROL_UNIT {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_SERVER {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_WEIGHTS {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_BED_EXIT {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_VITAL_MONITOR {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_LOCALIZATION {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_SIDERAILS {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_BRAKES {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_BACK_SIDE_POS {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices_BED_HEIGHT {
  __typename: 'Device';
  indication: DevIndication;
  monitoring: boolean;
  state: DevState;
}

export interface ThreeFOne_threeFOne_devices {
  __typename: 'Devices';
  IM: ThreeFOne_threeFOne_devices_IM | null;
  CONTROL_UNIT: ThreeFOne_threeFOne_devices_CONTROL_UNIT | null;
  SERVER: ThreeFOne_threeFOne_devices_SERVER | null;
  WEIGHTS: ThreeFOne_threeFOne_devices_WEIGHTS | null;
  BED_EXIT: ThreeFOne_threeFOne_devices_BED_EXIT | null;
  VITAL_MONITOR: ThreeFOne_threeFOne_devices_VITAL_MONITOR | null;
  LOCALIZATION: ThreeFOne_threeFOne_devices_LOCALIZATION | null;
  SIDERAILS: ThreeFOne_threeFOne_devices_SIDERAILS | null;
  BRAKES: ThreeFOne_threeFOne_devices_BRAKES | null;
  BACK_SIDE_POS: ThreeFOne_threeFOne_devices_BACK_SIDE_POS | null;
  BED_HEIGHT: ThreeFOne_threeFOne_devices_BED_HEIGHT | null;
}

export interface ThreeFOne_threeFOne {
  __typename: 'ThreeFOne';
  unitId: string;
  indication: SMIndication;
  state: SMState;
  pause: number;
  devices: ThreeFOne_threeFOne_devices;
}

export interface ThreeFOne {
  threeFOne: ThreeFOne_threeFOne;
}

export interface ThreeFOneVariables {
  roomId?: string | null;
  wardId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ThreeFF
// ====================================================

export interface ThreeFF_threeFF {
  __typename: 'ThreeFF';
  unitId: string;
  weight: number | null;
  zAxisDeg: number | null;
  power: boolean;
  siderailBothRUp: boolean | null;
  siderailBothLUp: boolean | null;
  siderailHeadRUp: boolean | null;
  siderailHeadLUp: boolean | null;
  siderailMiddleRUp: boolean | null;
  siderailMiddleLUp: boolean | null;
  safetyPosition: boolean | null;
  back30DegGt: boolean | null;
  posIsLowest: boolean | null;
  brakesOff: boolean | null;
  bedExitOn: boolean | null;
  ALTPhase: number | null;
  isZoneExit: boolean | null;
  calfrestLock: boolean | null;
  backrestLock: boolean | null;
  thighrestLock: boolean | null;
  mattressType: number | null;
  bedExitAlarm: boolean | null;
  trendelenburgDeg: number | null;
  lateralTiltDeg: number | null;
  lAxisDeg: number | null;
  heightLock: boolean | null;
  patientOnBed: boolean | null;
  paused: boolean | null;
  tagId: number | null;
  tagCharge: number | null;
  charging: boolean | null;
  transfer: boolean | null;
  heightOfRLegColumn: number | null;
  heightOfLLegColumn: number | null;
  heightOfHeadColumn: number | null;
  mobiliftRight: number | null;
  mobiliftLeft: number | null;
  bedExtension: boolean | null;
  pressedBtnAndUsedController: number | null;
  rightLCDButtonPressed: number | null;
  leftLCDButtonPressed: number | null;
  legsControllerButtonPressed: number | null;
  acpAndRoControllerButtonPressed: number | null;
  digitalSiderailRightButtonPressed: number | null;
  digitalSiderailLeftButtonPressed: number | null;
  ALTError: number | null;
  errorOrStopCode: number | null;
  batteryFailure: boolean | null;
}

export interface ThreeFF {
  threeFF: ThreeFF_threeFF;
}

export interface ThreeFFVariables {
  roomId?: string | null;
  wardId?: string | null;
  bedIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: HL7ConnectionStatus
// ====================================================

export interface HL7ConnectionStatus_hl7ConnectionStatus {
  __typename: 'HL7ConnectionStatus';
  id: string;
  status: number;
  length: number;
}

export interface HL7ConnectionStatus {
  hl7ConnectionStatus: HL7ConnectionStatus_hl7ConnectionStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BedFragment
// ====================================================

export interface BedFragment_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface BedFragment_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  bedsCount: number;
  roomsCount: number;
  wards: BedFragment_workspace_wards[];
}

export interface BedFragment_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface BedFragment_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface BedFragment_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
}

export interface BedFragment {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  state: BedState;
  unitSn: string | null;
  bedSn: string | null;
  power: PowerState;
  ipAddress: string | null;
  ipAddressEthernet: string | null;
  macAddress: string | null;
  macAddressEthernet: string | null;
  isVirtual: boolean | null;
  ident: string | null;
  type: BedType | null;
  tagId: string | null;
  ownerWardId: string | null;
  workspace: BedFragment_workspace | null;
  ward: BedFragment_ward | null;
  room: BedFragment_room | null;
  parkingPlaces: BedFragment_parkingPlaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DashboardBedFragment
// ====================================================

export interface DashboardBedFragment_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface DashboardBedFragment_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  bedsCount: number;
  roomsCount: number;
  wards: DashboardBedFragment_workspace_wards[];
}

export interface DashboardBedFragment_ward_setting {
  __typename: 'WardSetting';
  breaksAlertSound: boolean;
  heightAlertSound: boolean;
  backrestAlertSound: boolean;
  siderailAlertSound: boolean;
}

export interface DashboardBedFragment_ward {
  __typename: 'Ward';
  id: string;
  name: string;
  setting: DashboardBedFragment_ward_setting | null;
}

export interface DashboardBedFragment_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface DashboardBedFragment_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface DashboardBedFragment {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
  state: BedState;
  bedSn: string | null;
  unitSn: string | null;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  tagId: string | null;
  breaksAlertSoundMuted: boolean | null;
  heightAlertSoundMuted: boolean | null;
  backrestAlertSoundMuted: boolean | null;
  siderailAlertSoundMuted: boolean | null;
  workspace: DashboardBedFragment_workspace | null;
  ward: DashboardBedFragment_ward | null;
  room: DashboardBedFragment_room | null;
  parkingPlaces: DashboardBedFragment_parkingPlaces[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParkingPlaceSmallFragment
// ====================================================

export interface ParkingPlaceSmallFragment_bed {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

export interface ParkingPlaceSmallFragment {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  mode: ParkingPlaceMode;
  ipAddressEthernet: string | null;
  bed: ParkingPlaceSmallFragment_bed | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: WardSafetyPositionFragment
// ====================================================

export interface WardSafetyPositionFragment {
  __typename: 'Ward';
  id: string;
  name: string;
  safetyPositionPresetId: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ParkingPlaceFragment
// ====================================================

export interface ParkingPlaceFragment_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface ParkingPlaceFragment_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
}

export interface ParkingPlaceFragment_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface ParkingPlaceFragment_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface ParkingPlaceFragment_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: ParkingPlaceFragment_bed_workspace_wards[];
  bedsCount: number;
  roomsCount: number;
}

export interface ParkingPlaceFragment_bed {
  __typename: 'Bed';
  tagId: string | null;
  unitId: string;
  name: string | null;
  bedSn: string | null;
  unitSn: string | null;
  state: BedState;
  ownerWardId: string | null;
  isVirtual: boolean | null;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  room: ParkingPlaceFragment_bed_room | null;
  ward: ParkingPlaceFragment_bed_ward | null;
  parkingPlaces: ParkingPlaceFragment_bed_parkingPlaces[];
  workspace: ParkingPlaceFragment_bed_workspace | null;
}

export interface ParkingPlaceFragment {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  ipAddressEthernet: string | null;
  mode: ParkingPlaceMode;
  bed: ParkingPlaceFragment_bed | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DashboardParkingPlaceFragment
// ====================================================

export interface DashboardParkingPlaceFragment_bed_room {
  __typename: 'Room';
  id: string;
  name: string;
}

export interface DashboardParkingPlaceFragment_bed_ward_setting {
  __typename: 'WardSetting';
  breaksAlertSound: boolean;
  heightAlertSound: boolean;
  backrestAlertSound: boolean;
  siderailAlertSound: boolean;
}

export interface DashboardParkingPlaceFragment_bed_ward {
  __typename: 'Ward';
  id: string;
  name: string;
  setting: DashboardParkingPlaceFragment_bed_ward_setting | null;
}

export interface DashboardParkingPlaceFragment_bed_parkingPlaces {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
}

export interface DashboardParkingPlaceFragment_bed_workspace_wards {
  __typename: 'Ward';
  id: string;
}

export interface DashboardParkingPlaceFragment_bed_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
  image: number;
  wards: DashboardParkingPlaceFragment_bed_workspace_wards[];
  bedsCount: number;
  roomsCount: number;
}

export interface DashboardParkingPlaceFragment_bed {
  __typename: 'Bed';
  tagId: string | null;
  unitId: string;
  name: string | null;
  bedSn: string | null;
  unitSn: string | null;
  state: BedState;
  power: PowerState;
  ipAddress: string | null;
  macAddress: string | null;
  ident: string | null;
  type: BedType | null;
  breaksAlertSoundMuted: boolean | null;
  heightAlertSoundMuted: boolean | null;
  backrestAlertSoundMuted: boolean | null;
  siderailAlertSoundMuted: boolean | null;
  room: DashboardParkingPlaceFragment_bed_room | null;
  ward: DashboardParkingPlaceFragment_bed_ward | null;
  parkingPlaces: DashboardParkingPlaceFragment_bed_parkingPlaces[];
  workspace: DashboardParkingPlaceFragment_bed_workspace | null;
}

export interface DashboardParkingPlaceFragment {
  __typename: 'ParkingPlace';
  id: string;
  tagId: string | null;
  name: string | null;
  bed: DashboardParkingPlaceFragment_bed | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserFragment
// ====================================================

export interface UserFragment_accessibleWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface UserFragment_accessibleWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: UserFragment_accessibleWards_workspace;
}

export interface UserFragment_assignedWards_workspace {
  __typename: 'Workspace';
  id: string;
  name: string;
}

export interface UserFragment_assignedWards {
  __typename: 'Ward';
  id: string;
  name: string;
  workspace: UserFragment_assignedWards_workspace;
}

export interface UserFragment {
  __typename: 'User';
  id: string;
  role: UserRole;
  accessibleWards: UserFragment_accessibleWards[] | null;
  assignedWards: UserFragment_assignedWards[] | null;
  username: string;
  firstName: string | null;
  lastName: string | null;
  locale: string | null;
  email: string;
  lastAccess: any | null;
  vbAccess: boolean;
  demoAccess: boolean;
  serverIp: string | null;
  ssid: string | null;
  ssidKey: string | null;
  ssidKeyEncrypt: boolean | null;
  reportingModuleAccess: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ConfigFragment
// ====================================================

export interface ConfigFragment {
  __typename: 'Config';
  hospitalName: string | null;
  defaultPause: number | null;
  unitSystem: WeightUnits;
  noteConfirmationPeriod: number | null;
  logoURL: string | null;
  serverIp: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SafetyPositionPresetFragment
// ====================================================

export interface SafetyPositionPresetFragment {
  __typename: 'SafetyPositionPreset';
  id: string;
  name: string;
  editable: boolean;
  backrestAngleEnabled: boolean;
  backrestAngleMin: number;
  backrestAngleMax: number;
  posIsLowestEnabled: boolean;
  brakesEnabled: boolean;
  siderailPositionEnabled: boolean;
  siderailPosition: SiderailsPosition;
  bedExitEnabled: boolean;
  bedExitStatus: BedExitStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SuperConfigFragment
// ====================================================

export interface SuperConfigFragment {
  __typename: 'SuperConfig';
  spEnabled: boolean | null;
  spVersion: string | null;
  spDashboardVersion: string | null;
  patientEnabled: boolean | null;
  spDashboardEnabled: boolean | null;
  spDashboardLicenceExpiration: any | null;
  respiratoryMobilizationEnabled: boolean | null;
  bedManagementEnabled: boolean | null;
  patientLicenceExpiration: any | null;
  bedManagementLicenceExpiration: any | null;
  respiratoryMobilizationLicenceExpiration: any | null;
  utilizationEnabled: boolean | null;
  utilizationLicenceExpiration: any | null;
  syncCommunication: boolean | null;
  hl7LogEnabled: boolean | null;
  reportingModuleAccess: boolean;
  passwordFlags: number | null;
  passwordMinLength: number | null;
  adminPasswordFlags: number | null;
  adminPasswordMinLength: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TrendsFragment
// ====================================================

export interface TrendsFragment_backrest30_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface TrendsFragment_backrest30 {
  __typename: 'EndpointTrend';
  data: TrendsFragment_backrest30_data[];
  duration: number;
}

export interface TrendsFragment_backrest45_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface TrendsFragment_backrest45 {
  __typename: 'EndpointTrend';
  data: TrendsFragment_backrest45_data[];
  duration: number;
}

export interface TrendsFragment_alt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface TrendsFragment_alt {
  __typename: 'EndpointTrend';
  data: TrendsFragment_alt_data[];
  duration: number;
}

export interface TrendsFragment_trendelenburg_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface TrendsFragment_trendelenburg {
  __typename: 'EndpointTrend';
  data: TrendsFragment_trendelenburg_data[];
  duration: number;
}

export interface TrendsFragment_lateralTilt_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface TrendsFragment_lateralTilt {
  __typename: 'EndpointTrend';
  data: TrendsFragment_lateralTilt_data[];
  duration: number;
}

export interface TrendsFragment_mobilift_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface TrendsFragment_mobilift {
  __typename: 'EndpointTrend';
  data: TrendsFragment_mobilift_data[];
  duration: number;
}

export interface TrendsFragment_mattressType_data {
  __typename: 'TrendData';
  time: string | null;
  value: number | null;
}

export interface TrendsFragment_mattressType {
  __typename: 'EndpointTrend';
  data: TrendsFragment_mattressType_data[];
  duration: number;
}

export interface TrendsFragment {
  __typename: 'SessionTrends';
  backrest30: TrendsFragment_backrest30 | null;
  backrest45: TrendsFragment_backrest45 | null;
  alt: TrendsFragment_alt | null;
  trendelenburg: TrendsFragment_trendelenburg | null;
  lateralTilt: TrendsFragment_lateralTilt | null;
  mobilift: TrendsFragment_mobilift | null;
  mattressType: TrendsFragment_mattressType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReportTagFragment
// ====================================================

export interface ReportTagFragment {
  __typename: 'Tags';
  unitId: string | null;
  workspaceId: string | null;
  wardId: string | null;
  roomId: string | null;
  parkingPlaceId: string | null;
  type: string | null;
  shift: string | null;
  custom: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReportingReportFragment
// ====================================================

export interface ReportingReportFragment_reportingDashboards {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

export interface ReportingReportFragment {
  __typename: 'ReportingReport';
  id: string;
  name: string;
  goal: number | null;
  filterConfig: string;
  reportingDashboards: ReportingReportFragment_reportingDashboards[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReportingDashboardDetailFragment
// ====================================================

export interface ReportingDashboardDetailFragment_reports {
  __typename: 'ReportingDashboardReports';
  reportingReportId: string;
  x: string;
  y: string;
}

export interface ReportingDashboardDetailFragment {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
  reports: ReportingDashboardDetailFragment_reports[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReportingDashboardListItemFragment
// ====================================================

export interface ReportingDashboardListItemFragment {
  __typename: 'ReportingDashboard';
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserNameFragment
// ====================================================

export interface UserNameFragment {
  __typename: 'User';
  id: string;
  firstName: string | null;
  lastName: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BedNameFragment
// ====================================================

export interface BedNameFragment {
  __typename: 'Bed';
  unitId: string;
  name: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: RoomNameFragment
// ====================================================

export interface RoomNameFragment {
  __typename: 'Room';
  id: string;
  name: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BedExitStatus {
  INNER = 'INNER',
  NA = 'NA',
  OUTER = 'OUTER',
}

export enum BedState {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum BedType {
  E2 = 'E2',
  E3 = 'E3',
  E3XC = 'E3XC',
  E3XC_NO_TRX = 'E3XC_NO_TRX',
  E3_NO_TRX = 'E3_NO_TRX',
  E4 = 'E4',
  E4_NO_TRX = 'E4_NO_TRX',
  E5 = 'E5',
  MULTICARE = 'MULTICARE',
  MULTICARE_LE = 'MULTICARE_LE',
  MULTICARE_X = 'MULTICARE_X',
}

export enum DevIndication {
  ALARM = 'ALARM',
  CONFIRMED_ALERT = 'CONFIRMED_ALERT',
  ERROR = 'ERROR',
  OFF = 'OFF',
  OK = 'OK',
  PAUSE = 'PAUSE',
  UNCOFIRMED_ALERT = 'UNCOFIRMED_ALERT',
}

export enum DevState {
  ERROR = 'ERROR',
  OFF = 'OFF',
  OFFLINE = 'OFFLINE',
  ON_ALARM = 'ON_ALARM',
  ON_NOK = 'ON_NOK',
  ON_OK = 'ON_OK',
  PAUSE = 'PAUSE',
  RESERVE = 'RESERVE',
}

export enum FallRisk {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum FontSize {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

export enum LocationType {
  BED = 'BED',
  BED_TYPE = 'BED_TYPE',
  PARKING_PLACE = 'PARKING_PLACE',
  ROOM = 'ROOM',
  SHIFT = 'SHIFT',
  WARD = 'WARD',
  WORKSPACE = 'WORKSPACE',
}

export enum Mobilift {
  DOWN = 'DOWN',
  NA = 'NA',
  UP = 'UP',
}

export enum NumberComparator {
  EQUALS = 'EQUALS',
  GREATER_THEN = 'GREATER_THEN',
  LESSER_THEN = 'LESSER_THEN',
  NOT_EQUALS = 'NOT_EQUALS',
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ParkingPlaceMode {
  IP = 'IP',
  IP_ETHERNET = 'IP_ETHERNET',
  MANUAL = 'MANUAL',
  TAG = 'TAG',
}

export enum PatientSessionOrderBy {
  NAME = 'NAME',
  SESSION_END = 'SESSION_END',
  SESSION_START = 'SESSION_START',
}

export enum Position {
  DOWN = 'DOWN',
  UP = 'UP',
}

export enum PowerState {
  OFF = 'OFF',
  ON = 'ON',
}

export enum PowerStatus {
  CHARGING = 'CHARGING',
  OFF = 'OFF',
  ON = 'ON',
}

export enum ReportBedAggregation {
  COUNT = 'COUNT',
  LAST = 'LAST',
  MEAN = 'MEAN',
  NONE = 'NONE',
  SUM = 'SUM',
}

export enum ReportLocationAggregation {
  MEAN = 'MEAN',
  SUM = 'SUM',
}

export enum ReportResultField {
  BACKREST = 'BACKREST',
  BED_EXIT = 'BED_EXIT',
  BED_TYPE = 'BED_TYPE',
  DURATION = 'DURATION',
  OCCUPANCY = 'OCCUPANCY',
  SESSION = 'SESSION',
  WEIGHT = 'WEIGHT',
}

export enum SMIndication {
  ALARM = 'ALARM',
  ERROR = 'ERROR',
  OFF = 'OFF',
  OK = 'OK',
  PAUSE = 'PAUSE',
}

export enum SMState {
  ERROR = 'ERROR',
  OFF = 'OFF',
  ON = 'ON',
  PAUSE = 'PAUSE',
}

export enum SafetyPortProps {
  LINIS0001 = 'LINIS0001',
  LINIS0002 = 'LINIS0002',
  LINIS0003 = 'LINIS0003',
  LINIS0004 = 'LINIS0004',
  LINIS0005 = 'LINIS0005',
  LINIS0006 = 'LINIS0006',
  LINIS0007 = 'LINIS0007',
  LINIS0008 = 'LINIS0008',
  LINIS0009 = 'LINIS0009',
  LINIS0010 = 'LINIS0010',
  LINIS0011 = 'LINIS0011',
  LINIS0012 = 'LINIS0012',
  LINIS0013 = 'LINIS0013',
  LINIS0014 = 'LINIS0014',
  LINIS0015 = 'LINIS0015',
  LINIS0016 = 'LINIS0016',
  LINIS0017 = 'LINIS0017',
  LINIS0018 = 'LINIS0018',
  LINIS0019 = 'LINIS0019',
  LINIS0020 = 'LINIS0020',
  LINIS0021 = 'LINIS0021',
  LINIS0022 = 'LINIS0022',
  LINIS0023 = 'LINIS0023',
  LINIS0024 = 'LINIS0024',
  LINIS0025 = 'LINIS0025',
  LINIS0026 = 'LINIS0026',
  LINIS0028 = 'LINIS0028',
  LINIS0030 = 'LINIS0030',
  LINIS0031 = 'LINIS0031',
  LINIS0032 = 'LINIS0032',
  LINIS0033 = 'LINIS0033',
  LINIS0034 = 'LINIS0034',
  LINIS0035 = 'LINIS0035',
  LINIS0036 = 'LINIS0036',
  LINIS0037 = 'LINIS0037',
  LINIS0038 = 'LINIS0038',
  LINIS0039 = 'LINIS0039',
  LINIS0041 = 'LINIS0041',
  LINIS0042 = 'LINIS0042',
  LINIS0045 = 'LINIS0045',
}

export enum ServerConnection {
  OFF = 'OFF',
  ON = 'ON',
}

export enum SiderailsPosition {
  ALL_UP = 'ALL_UP',
  ANY_DOWN = 'ANY_DOWN',
  ONE_BOTTOM_DOWN = 'ONE_BOTTOM_DOWN',
  ONE_HEAD_DOWN = 'ONE_HEAD_DOWN',
  ONE_SIDE_DOWN = 'ONE_SIDE_DOWN',
}

export enum TimeUnitType {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  DEMO = 'DEMO',
  REGULAR = 'REGULAR',
  SUPERADMIN = 'SUPERADMIN',
}

export enum WeightUnits {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

export interface AddReportingReportToDashboardInput {
  reportId: string;
  dashboardId: string;
}

export interface BedEditInput {
  unitId: string;
  name?: string | null;
  ident?: string | null;
  type?: BedType | null;
  ownerWardId?: string | null;
}

export interface BoolFilter {
  prop: string;
  value: boolean;
}

export interface ClearDataInput {
  unitId: string;
  dateFrom: any;
  dateTo: any;
}

export interface ConfigInput {
  hospitalName?: string | null;
  defaultPause?: number | null;
  hl7Server?: string | null;
  port?: number | null;
  unitSystem: WeightUnits;
  noteConfirmationPeriod?: number | null;
  syncPeriod?: number | null;
}

export interface ConnectionInput {
  id?: string | null;
  name: string;
  enabled: boolean;
  ip?: string | null;
  port?: number | null;
  synchronous: boolean;
  syncPeriod?: number | null;
  props: ConnectionPropsInput[];
}

export interface ConnectionPropsInput {
  id: SafetyPortProps;
  enabled: boolean;
  threshold?: number | null;
}

export interface CreateReportingDashboardInput {
  name: string;
}

export interface CreateReportingGoalInput {
  name: string;
  goal: number;
  filterConfig: string;
}

export interface CreateReportingReportInput {
  name: string;
  filterConfig: string;
}

export interface EditPatientSessionInput {
  id: string;
  name: string;
  note: string;
  fallRisk?: FallRisk | null;
  safetyPositionPresetId?: string | null;
  start: any;
  unitId: string;
}

export interface EditReportingDashboardInput {
  id: string;
  name?: string | null;
}

export interface EditReportingGoalInput {
  id: string;
  name?: string | null;
  goal?: number | null;
  filterConfig?: string | null;
}

export interface EditReportingReportInput {
  id: string;
  name?: string | null;
  filterConfig?: string | null;
}

export interface EditSafetyPositionPresetInput {
  id: string;
  name?: string | null;
  backrestAngleEnabled?: boolean | null;
  backrestAngleMin?: number | null;
  backrestAngleMax?: number | null;
  posIsLowestEnabled?: boolean | null;
  brakesEnabled?: boolean | null;
  siderailPositionEnabled?: boolean | null;
  siderailPosition?: SiderailsPosition | null;
  bedExitEnabled?: boolean | null;
  bedExitStatus?: BedExitStatus | null;
}

export interface LocationFilter {
  unitIds?: string[] | null;
  parkingPlaceIds?: string[] | null;
  wardIds?: string[] | null;
  workspaceIds?: string[] | null;
}

export interface NumberFilter {
  prop: string;
  comparator: NumberComparator;
  value: number;
}

export interface PatientSessionsInput {
  id?: string | null;
  wardId?: string | null;
  location?: string | null;
  parkingPlaceId?: string | null;
  roomId?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  name?: string | null;
  first?: number | null;
  offset?: number | null;
  order?: Order | null;
  orderBy?: PatientSessionOrderBy | null;
}

export interface RemoveReportingReportFromDashboardInput {
  reportId: string;
  dashboardId: string;
}

export interface ReportInput {
  resultField: ReportResultField;
  bedAggregation: ReportBedAggregation;
  locationAggregation: ReportLocationAggregation;
  from: any;
  to: any;
  locationFilter?: LocationFilter | null;
  viewByTime: TimeUnit;
  viewByLocation: LocationType;
  timezone: string;
  numberFilters?: NumberFilter[] | null;
  boolFilters?: BoolFilter[] | null;
}

export interface ReportPositionsInput {
  reportId: string;
  x: string;
  y: string;
}

export interface RoomInput {
  id?: string | null;
  name: string;
  wardId: number;
}

export interface SaveSimulationPresetInput {
  id?: string | null;
  name: string;
  config: string;
}

export interface SimulateDataInput {
  unitId: string;
  dateFrom: any;
  dateTo: any;
  config: string;
}

export interface StartSessionInput {
  name: string;
  note?: string | null;
  sessionStart: any;
  unitId: string;
}

export interface SuperConfigInput {
  smEnabled?: boolean | null;
  spEnabled?: boolean | null;
  smVersion?: string | null;
  spVersion?: string | null;
  spDashboardVersion?: string | null;
  smLicenceExpiration?: any | null;
  spDashboardLicenceExpiration?: any | null;
  patientEnabled?: boolean | null;
  spDashboardEnabled?: boolean | null;
  respiratoryMobilizationEnabled?: boolean | null;
  bedManagementEnabled?: boolean | null;
  patientLicenceExpiration?: any | null;
  bedManagementLicenceExpiration?: any | null;
  respiratoryMobilizationLicenceExpiration?: any | null;
  utilizationEnabled?: boolean | null;
  utilizationLicenceExpiration?: any | null;
  syncCommunication?: boolean | null;
  hl7LogEnabled?: boolean | null;
  reportingModuleAccess?: boolean | null;
  passwordMinLength?: number | null;
  passwordFlags?: number | null;
  adminPasswordMinLength?: number | null;
  adminPasswordFlags?: number | null;
}

export interface TimeUnit {
  value: number;
  unit: TimeUnitType;
}

export interface UpdateReportingDashboardReportPositionInput {
  reportPositions: ReportPositionsInput[];
  dashboardId: string;
}

export interface UpdateRoomInput {
  id?: string | null;
  name: string;
  wardId?: number | null;
}

export interface UpdateVirtualBedInput {
  id: string;
  unitId: string;
  tag?: string | null;
  weight: number;
  trendelenburg: number;
  lateralTilt: number;
  backrestAngle: number;
  bedExitStatus: BedExitStatus;
  bedExitAlarm: boolean;
  brakes: boolean;
  lowestPosition: boolean;
  powerStatus: PowerStatus;
  serverConnection: ServerConnection;
  leftHeadSiderail: Position;
  rightHeadSiderail: Position;
  leftSiderail: Position;
  rightSiderail: Position;
  mobiliftRight: Mobilift;
  mobiliftLeft: Mobilift;
  calfrestAngle: number;
  leftLegColumn: number;
  rightLegColumn: number;
  headColumn: number;
  mattressType: number;
  altPhase: number;
  errorCode: number;
  backrestLock: boolean;
  heightLock: boolean;
  calfrestLock: boolean;
  thighrestLock: boolean;
  bedExtension: boolean;
  transfer: boolean;
  batteryFailure: boolean;
  controller: number;
  buttonPressed: number;
}

export interface UserInput {
  id?: string | null;
  role: UserRole;
  wardIds: string[];
  username: string;
  password?: string | null;
  email: string;
  firstName: string;
  lastName: string;
  locale?: string | null;
  vbAccess?: boolean | null;
  demoAccess?: boolean | null;
  serverIp?: string | null;
  ssid?: string | null;
  ssidKey?: string | null;
  ssidKeyEncrypt?: boolean | null;
  reportingModuleAccess: boolean;
}

export interface UserSettingInput {
  userId?: string | null;
  svTheme?: string | null;
  dashboardOrder?: string | null;
  dashboardOrder2?: string | null;
  dashboardPageSize?: number | null;
  dashboardFontSize?: FontSize | null;
  showOwnedBeds?: boolean | null;
  anonymized?: boolean | null;
  singleLineLayout?: boolean | null;
}

export interface WardInput {
  id?: string | null;
  name: string;
  workspaceId?: string | null;
}

export interface WardSafetyPresetInput {
  wardId: string;
  safetyPositionPresetId?: string | null;
}

export interface WorkspaceInput {
  name: string;
  image: number;
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
