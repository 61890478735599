import { t } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';

interface Props {
  paused?: boolean | null;
  showFallRiskAlert?: boolean | null;
  bedExitOn?: boolean | null;
  isZoneExit?: boolean | null;
  fontSize?: string;
}

export const BedExitMonitoring = ({
  paused,
  showFallRiskAlert,
  bedExitOn,
  isZoneExit,
  fontSize,
}: Props) => {
  const theme = useTheme();
  const color =
    paused || showFallRiskAlert
      ? theme.palette.warning.main
      : bedExitOn
      ? theme.palette.success.light
      : theme.palette.grey[600];

  return (
    <Typography
      sx={{
        color,
        fontSize,
      }}
      noWrap
    >
      {paused ? (
        <Box component="span" sx={{ textTransform: 'uppercase' }}>
          {t`PAUSE`}
        </Box>
      ) : bedExitOn && isZoneExit ? (
        t`ON - Inner`
      ) : bedExitOn && !isZoneExit ? (
        t`ON - Outer`
      ) : (
        <>
          <Box component="span" sx={{ textTransform: 'uppercase' }}>
            {t`OFF`}
          </Box>
          {showFallRiskAlert ? (
            <Box component="span" sx={{ ml: 0.5 }}>
              !
            </Box>
          ) : null}
        </>
      )}
    </Typography>
  );
};
