import { t } from '@lingui/macro';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import {
  Button,
  Card,
  CardContent,
  CardProps,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/AuthProvider';
import Flex from 'src/components/Flex';
import useDateFormat from 'src/lib/useDateFormat';
import useTableExport from 'src/lib/useTableExport';
import { convertToPounds } from 'src/lib/weightConverter';
import { WeightUnits } from 'src/pages/Admin/GlobalSettings/globalSettingsHelper';
import {
  FilterParameter,
  ReportAggregation,
  ReportData,
  ReportFilterParams,
} from '../types';
import { getReportTag } from './graphHelpers';
import useGraphTimeAxisFormat from './useGraphTimeAxisFormat';
import useReportTagNames, { hasNotFoundError } from './useReportTagNames';

interface Props {
  data: ReportData[];
  filter: ReportFilterParams;
  sx?: CardProps['sx'];
  reportName?: string;
}

const ReportTableCard = ({ data, filter, sx, reportName }: Props) => {
  const { formatDuration } = useDateFormat();
  const { config } = useContext(AuthContext);
  const isImperial = config?.config?.unitSystem === WeightUnits.IMPERIAL;
  const { formatTimeAxisLabel } = useGraphTimeAxisFormat();
  const { resolveName } = useReportTagNames();
  const { tableRef, handleExportClick } = useTableExport(reportName);
  if (!data.length) {
    return null;
  }

  const tags = data[0].tags;
  if (hasNotFoundError(tags)) {
    return null;
  }
  const unit = data?.[0]?.values?.[0]?.unit || '';

  const label = tags.unitId
    ? t`Bed`
    : tags.parkingPlaceId
    ? t`Parking place`
    : tags.wardId
    ? t`Unit`
    : tags.workspaceId
    ? t`Facility`
    : t`Unknown`;

  const rows = data.map((d) => ({
    tag: getReportTag(d.tags),
    tags: d.tags,
    values: d.values,
  }));

  const formatValue = (val: string | number) => {
    if (filter.parameter === FilterParameter.OCCUPANCY) {
      return val ? t`on bed` : t`out of bed`;
    }
    if (unit === 'seconds') {
      return formatDuration(Number(val) * 1000);
    }
    if (!unit || unit === 'count') {
      return `${val}`;
    }
    if (unit === 'Kg' && isImperial) {
      return `${convertToPounds(val)} lb`;
    }
    return `${val} ${unit}`;
  };

  const renderHeader = () => {
    if (filter.aggregation === ReportAggregation.LIST) {
      return (
        <TableRow>
          <TableCell>{label}</TableCell>
          <TableCell>{t`Time`}</TableCell>
          <TableCell>{t`Value`}</TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell>{label}</TableCell>
        {rows[0].values.map((column) => (
          <TableCell key={column.time}>
            {formatTimeAxisLabel(column.time, filter)}
          </TableCell>
        ))}
      </TableRow>
    );
  };
  const renderRows = () => {
    if (filter.aggregation === ReportAggregation.LIST) {
      return rows.map(
        (row) =>
          row.values[0] && (
            <TableRow key={row.tag}>
              <TableCell>{resolveName(row.tags)}</TableCell>
              <TableCell>
                {formatTimeAxisLabel(row.values[0].time, filter)}
              </TableCell>
              <TableCell>{formatValue(row.values[0].value)}</TableCell>
            </TableRow>
          ),
      );
    }
    return rows.map((row) => (
      <TableRow key={row.tag}>
        <TableCell>{resolveName(row.tags)}</TableCell>
        {row.values.map((column) => (
          <TableCell key={column.time}>
            {formatValue(column.value || 0)}
          </TableCell>
        ))}
      </TableRow>
    ));
  };
  return (
    <Card sx={sx}>
      <CardContent>
        <Flex alignItems="center" justifyContent="space-between">
          <TableChartOutlinedIcon />
          <Button
            onClick={handleExportClick}
            variant="outlined"
            color="primary"
          >{t`Export`}</Button>
        </Flex>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <TableContainer>
          <Table ref={tableRef}>
            <TableHead>{renderHeader()}</TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ReportTableCard;
