import { styled } from '@mui/material';

const SimpleTableStyle = styled('table')(
  ({ theme: { palette, spacing } }) => `
      & th, & td {
          padding: ${spacing(1)} ${spacing(2)};
          border-bottom: 1px dashed ${palette.grey[800]};
          width: 1%;
          white-space: nowrap;
      }
      & th:last-of-type, & td:last-of-type {
          width: auto;
          white-space: normal;
      }
      & th {
          font-weight: bold;
          text-transform: uppercase;
      }
  
      & tr:last-of-type > td {
         border-bottom: 0;
      }
  `,
);

export default SimpleTableStyle;
