import { useState } from 'react';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  Box,
  styled,
  TableRow,
  TableContainer,
} from '@mui/material';
import { Trans } from '@lingui/macro';
import { SafetyPortProps } from 'src/graphql/types';
import { parseDate } from 'src/helpers';
import InsightItem from './InsightItem';
import { sub } from 'date-fns';
import { Waypoint } from 'react-waypoint';
import InsightFilter from '../../components/InsightFilter';
import { useInsight } from 'src/components/Insight/useInsight';

interface Props {
  bedId: string;
  sessionStart?: string | null;
  sessionEnd?: string | null;
  prop?: string | null;
  from?: Date | null;
  to?: Date | null;
}

const Insight = ({ bedId, sessionStart, sessionEnd, from, prop }: Props) => {
  const [dataHistoryFilter, setDataHistoryFilter] = useState<string | null>(
    prop || null,
  );
  const [fromDate, setFromDate] = useState(
    from ? from : sub(parseDate(sessionEnd) || new Date(), { hours: 4 }),
  );
  const {
    entries: data,
    loadMore,
    canLoadMore,
  } = useInsight({
    unitId: bedId,
    from: fromDate,
    prop: (dataHistoryFilter && SafetyPortProps[dataHistoryFilter]) || null,
  });

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="flex-end">
        <InsightFilter
          prop={prop}
          sessionStart={sessionStart}
          setDataHistoryFilter={setDataHistoryFilter}
          fromDate={fromDate}
          dataHistoryFilter={dataHistoryFilter}
          setFromDate={setFromDate}
          sxDate={{ mr: 2 }}
          sxFilter={{ width: 200 }}
        />
      </Box>
      <Container>
        <TableContainer
          sx={{
            overflowY: 'auto',
            width: 'calc(100% - 1rem)',
            maxHeight: '340px',
            pr: '1rem',
          }}
        >
          <InsightTable stickyHeader>
            <TableHead>
              <TableRow>
                <HeaderCell>
                  <Trans>Date</Trans>
                </HeaderCell>
                <HeaderCell>
                  <Trans>Time</Trans>
                </HeaderCell>
                <HeaderCell>
                  <Trans>Value name</Trans>
                </HeaderCell>
                <HeaderCell>
                  <Trans>Value</Trans>
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.insight?.data.map((item, index, insightData) => {
                return (
                  <>
                    <InsightItem
                      key={`${item.type}-${item.time}`}
                      data={item}
                    />
                    {Math.max(0, insightData.length - 5) === index &&
                    canLoadMore ? (
                      <Waypoint onEnter={loadMore} />
                    ) : null}
                  </>
                );
              })}
            </TableBody>
          </InsightTable>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default Insight;

const Container = styled(Box)(
  ({ theme: { spacing } }) => `
  margin-top: ${spacing(1)};
  height: 340px;
`,
);

const InsightTable = styled(Table)(
  ({ theme: { palette, spacing } }) => `
  &&& {
    border-spacing: 0 ${spacing(0.5)};
  }
  &&& .MuiTableHead-root .MuiTableCell-root {
    color: ${palette.text.primary};
    background-color:  ${palette.background.paper};
  }
  &&& .MuiTableBody-root .MuiTableRow-root {
    background-color: transparent;
    td:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    td:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  &&& .MuiTableBody-root .MuiTableCell-root {
    background-color: ${palette.grey[200]};
  }
`,
);

const HeaderCell = styled(TableCell)`
  padding-left: ${(props) => props.theme.spacing(6)};
  font-size: '1.25rem';
  color: ${(props) => props.theme.palette.text.primary};
  text-transform: uppercase;
  border: none;
`;
