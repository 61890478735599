import { t, Trans } from '@lingui/macro';
import { FormikValues, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import ActionButtons from 'src/components/ActionButtons';
import {
  ListPageContent,
  ListPageRoot,
  TopBar,
} from 'src/components/AdminList';
import { BasicData } from './BasicData';
import { DatapointData } from './DatapointData';
import FormikForm from 'src/components/Formik/FormikForm';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { useParams } from 'react-router';
import { object, date, number } from 'yup';
import { useSnackbarMutation } from 'src/graphql/apolloExtenstion';
import {
  SaveSimulationPreset,
  SaveSimulationPresetVariables,
  ClearDataMutation,
  ClearDataMutationVariables,
  DeleteSimulationPreset,
  DeleteSimulationPresetVariables,
  SimulateData,
  SimulateDataVariables,
  SimulationPresetQuery,
  SimulationPresetQuery_simulationPresets,
} from 'src/graphql/types';
import { simulationPresetsQuery } from 'src/graphql/queries';
import {
  saveSimulationPresetMutation,
  deleteSimulationPresetMutation,
  clearDataMutation,
  simulateDataQuery,
} from 'src/graphql/mutations';
import SavePresetDialog from './SavePresetDialog';
import { useQuery } from '@apollo/client';
import { Datapoint, DATAPOINTS, DATAPOINT_TYPE } from './helper';
import { notEmpty } from 'src/lib/notEmpty';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Divider } from '@mui/material';
import { equals } from 'ramda';

export interface DataSimulationForm {
  unitId: string;
  dateFrom?: Date | null;
  dateTo?: Date | null;
}

const getConfig = (values: FormikValues) => {
  const { unitId, dateFrom, dateTo, ...rest } = values;
  const json = JSON.stringify(rest);
  return {
    unitId,
    dateFrom,
    dateTo,
    json,
  };
};

interface PresetOption {
  id: string;
  name: string;
}

export const DataSimulation = () => {
  const params = useParams<{ unitId: string }>();
  const [selectedPreset, setSelectedPreset] =
    useState<SimulationPresetQuery_simulationPresets | null>(null);

  const [clearData] = useSnackbarMutation<
    ClearDataMutation,
    ClearDataMutationVariables
  >(
    clearDataMutation,
    {},
    {
      successMessage: t`Data for ${params.unitId} successfully deleted.`,
    },
  );

  const { data: simulationData } = useQuery<SimulationPresetQuery>(
    simulationPresetsQuery,
  );

  const [saveSimulationPreset] = useSnackbarMutation<
    SaveSimulationPreset,
    SaveSimulationPresetVariables
  >(saveSimulationPresetMutation, {
    refetchQueries: [{ query: simulationPresetsQuery }],
  });

  const [deleteSimulationPreset] = useSnackbarMutation<
    DeleteSimulationPreset,
    DeleteSimulationPresetVariables
  >(deleteSimulationPresetMutation, {
    refetchQueries: [{ query: simulationPresetsQuery }],
  });

  const [simulateData] = useSnackbarMutation<
    SimulateData,
    SimulateDataVariables
  >(
    simulateDataQuery,
    {},
    {
      successMessage: t`Data for ${params.unitId} successfully simulated.`,
    },
  );

  const [datapoints, setDatapoints] = useState<Datapoint[]>([DATAPOINTS[0]]);
  const validationSchema = useMemo(() => {
    const schemaBuilder = {};
    datapoints.forEach((datapoint) => {
      if (datapoint.type === DATAPOINT_TYPE.PERCENTAGE) {
        schemaBuilder[datapoint.id] = number()
          .required(t`Value is required`)
          .min(0, `${t`Min value is`} 0`)
          .max(100, `${t`Max value is`} 100`)
          .typeError(t`Value has to be number`);
      } else if (datapoint.id === 'weight') {
        schemaBuilder[datapoint.id] = number()
          .required(t`Value is required`)
          .min(0, `${t`Min value is`} 0`)
          .max(999, `${t`Max value is`} 999`)
          .typeError(t`Value has to be number`);
      } else if (
        datapoint.id === 'calfrest' ||
        datapoint.id === 'backrest' ||
        datapoint.id === 'lateralTiltDeg' ||
        datapoint.id === 'trendelenburgDeg'
      ) {
        schemaBuilder[datapoint.id] = number()
          .required(t`Value is required`)
          .min(-99, `${t`Min value is`} -99`)
          .max(99, `${t`Max value is`} 99`)
          .typeError(t`Value has to be number`);
      } else if (datapoint.type !== DATAPOINT_TYPE.LOCATION) {
        schemaBuilder[datapoint.id] = number()
          .required(t`Value is required`)
          .typeError(t`Value has to be number`);
      }
      if (
        datapoint.type !== DATAPOINT_TYPE.PERCENTAGE &&
        datapoint.type !== DATAPOINT_TYPE.LOCATION
      ) {
        schemaBuilder[`${datapoint.id}Alt`] = number()
          .required(t`Value is required`)
          .typeError(t`Value has to be number`);
      }
    });

    return object({
      dateFrom: date().required(t`Date from required`),
      dateTo: date()
        .required(t`Date to required`)
        .when('dateFrom', (dateFrom, yup) => {
          if (!dateFrom) {
            return undefined;
          }
          const nextData = new Date(dateFrom);
          nextData.setDate(nextData.getDate() + 1);
          return yup.min(nextData, t`Cannot end before start`);
        }),
      ...schemaBuilder,
    });
  }, [datapoints]);

  const formik = useFormik<DataSimulationForm>({
    initialValues: {
      unitId: params.unitId || '',
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit: (values) => {
      simulate(values);
    },
  });

  const [confirmClearAll, setConfirmClearAll] = useState(false);
  const [savePresetOpen, setSavePresetOpen] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState<PresetOption | null>(
    null,
  );

  const simulate = async (values: DataSimulationForm) => {
    const res = getConfig(values);
    await simulateData({
      variables: {
        input: {
          unitId: values.unitId,
          dateFrom: values.dateFrom,
          dateTo: values.dateTo,
          config: res.json,
        },
      },
    });
  };

  const clearAll = async () => {
    const { unitId, dateFrom, dateTo } = formik.values;
    if (unitId && dateFrom && dateTo) {
      await clearData({
        variables: {
          input: {
            unitId,
            dateFrom,
            dateTo,
          },
        },
      });
    }
    setConfirmClearAll(false);
  };

  const handleDeletePreset = async (id: string) => {
    await deleteSimulationPreset({
      variables: {
        id,
      },
    });
    formik.setFieldValue('preset', null);
    setSelectedPreset(null);
  };

  const openSavePreset = () => {
    const errors = Object.keys(formik.errors).filter(
      (field) => field !== 'dateFrom' && field !== 'dateTo',
    );
    if (errors.length > 0) {
      formik.setTouched(
        errors.reduce((prev, current) => ({ ...prev, [current]: true }), {}),
      );
      return;
    }
    setSavePresetOpen(true);
  };

  const handleSavePreset = async (name: string) => {
    const res = getConfig(formik.values);

    if (selectedPreset?.id) {
      await saveSimulationPreset({
        variables: {
          input: {
            id: selectedPreset.id,
            name,
            config: res.json,
          },
        },
        onCompleted: (data) => {
          setSelectedPreset(data.saveSimulationPreset);
        },
      });
    } else {
      await saveSimulationPreset({
        variables: {
          input: {
            name,
            config: res.json,
          },
        },
        onCompleted: (data) => {
          setSelectedPreset(data.saveSimulationPreset);
        },
      });
    }
  };

  // set touched to all fields with error after submit
  useEffect(() => {
    const touched = Object.keys(formik.touched).reduce(
      (prev, current) => ({ ...prev, [current]: true }),
      {},
    );
    const errors = Object.keys(formik.errors).reduce(
      (prev, current) => ({ ...prev, [current]: true }),
      {},
    );
    if (formik.submitCount > 0 && !equals(touched, errors)) {
      formik.setTouched(errors);
    }
  }, [formik]);

  const presetOptions = useMemo(() => {
    const res = (simulationData?.simulationPresets || []).map((sp) => ({
      id: sp.id,
      name: sp.name,
    }));
    return res;
  }, [simulationData?.simulationPresets]);

  const handleSelectPreset = (id: string) => {
    const preset =
      simulationData?.simulationPresets.find((sp) => sp.id === id) ?? null;
    setSelectedPreset(preset);
    if (preset?.config) {
      const presetDatapoints = JSON.parse(preset.config);

      formik.setValues({
        unitId: formik.values.unitId,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        ...presetDatapoints,
      });
      setDatapoints(
        Object.keys(presetDatapoints)
          .map((dpId) => {
            return DATAPOINTS.find((dp) => dp.id === dpId);
          })
          .filter(notEmpty),
      );
    }
  };

  const confirmDeletePreset = (id: string) => {
    const preset = presetOptions.find((p) => p.id === id);
    setPresetToDelete(preset || null);
  };
  console.log(formik.errors);
  return (
    <ListPageRoot>
      <TopBar>
        <ActionButtons
          backButton={datapoints.length ? t`Back` : t`Back to virtual bed`}
          rightButtons={
            datapoints.length
              ? [
                  {
                    id: 'save-preset',
                    label: t`Save preset`,
                    startIcon: <SaveOutlinedIcon />,
                    variant: 'outlined',
                    onClick: openSavePreset,
                  },
                  {
                    id: 'simulate-data',
                    label: t`Simulate data`,
                    variant: 'contained',
                    onClick: async () => {
                      await formik.submitForm();
                    },
                  },
                ]
              : undefined
          }
        />
      </TopBar>
      <ListPageContent>
        <FormikForm formik={formik} sx={{ flexDirection: 'column' }}>
          <BasicData
            formik={formik}
            presetOptions={presetOptions}
            preset={selectedPreset}
            selectPreset={handleSelectPreset}
            deletePreset={confirmDeletePreset}
            clearAll={() => setConfirmClearAll(true)}
          />
          <Divider sx={{ mt: 4, mb: 4 }} />
          <DatapointData
            datapoints={datapoints}
            setDatapoints={setDatapoints}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
          />
        </FormikForm>
      </ListPageContent>
      <ConfirmModal
        title={t`Clear all confirmation`}
        open={confirmClearAll}
        onClose={() => setConfirmClearAll(false)}
        message={
          <Trans>
            Do you really want to <b>clear all data in selected period?</b>
          </Trans>
        }
        onConfirm={() => clearAll()}
      />
      <ConfirmModal
        title={t`Delete Data Preset`}
        open={!!presetToDelete?.id}
        onClose={() => setPresetToDelete(null)}
        message={
          <Trans>
            Do you really want to <b>delete {presetToDelete?.name}?</b>
          </Trans>
        }
        confirmButtonText={t`Yes, I want to delete`}
        onConfirm={() => {
          presetToDelete?.id && handleDeletePreset(presetToDelete.id);
          setPresetToDelete(null);
        }}
      />
      <SavePresetDialog
        open={savePresetOpen}
        onClose={() => setSavePresetOpen(false)}
        id={selectedPreset?.id}
        name={selectedPreset?.name || ''}
        onSave={async (props) => {
          handleSavePreset(props.name);
        }}
      />
    </ListPageRoot>
  );
};
