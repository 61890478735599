import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import React, { useState } from 'react';
import { PasswordToggleAdornment } from '../PasswordInput/PasswordToggleAdornment';

type Props = TextFieldProps & {
  name: string;
  normalize?: (val: string) => string;
};

const FormikTextField = ({
  name,
  normalize = (v) => v,
  ...textFieldProps
}: Props) => {
  const [inputProps, metaProps] = useField(name);
  const [inputType, setInputType] = useState(textFieldProps.type || 'text');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = normalize(e.target.value);
    textFieldProps.onChange?.(e);
    inputProps.onChange({
      ...e,
      target: { ...e.target, name: inputProps.name, value: value },
    });
  };

  const toggleInputType = () => {
    setInputType((prevState) => {
      if (prevState === 'password') {
        return 'text';
      }
      return 'password';
    });
  };

  const { error, touched } = metaProps;
  const isPasswordType = textFieldProps.type === 'password';

  return (
    <TextField
      {...textFieldProps}
      {...inputProps}
      value={inputProps.value ?? ''}
      type={inputType}
      onBlur={textFieldProps.onBlur}
      onChange={handleChange}
      error={touched && !!error}
      helperText={touched && !!error && error}
      InputProps={
        textFieldProps.InputProps || {
          endAdornment: isPasswordType ? (
            <PasswordToggleAdornment
              toggle={toggleInputType}
              type={inputType as 'password' | 'text'}
            />
          ) : null,
        }
      }
    />
  );
};

export default FormikTextField;
