import { t } from '@lingui/macro';
import {
  LocationFilter,
  LocationType,
  ReportResultField,
  TimeUnitType,
} from 'src/graphql/types';
import { ReportFilterParams } from '../types';
import { getTimeUnits } from './useReportFilterParams';

const mapViewByTimeToTranslates = () => ({
  [TimeUnitType.WEEK]: t`Week`,
  [TimeUnitType.DAY]: t`Day`,
  [TimeUnitType.HOUR]: t`Hour`,
  [TimeUnitType.MINUTE]: t`Minute`,
  [TimeUnitType.MONTH]: t`Month`,
});

const mapViewByLocationToTranslates = () => ({
  [LocationType.BED]: t`Bed`,
  [LocationType.PARKING_PLACE]: t`Parking space`,
  [LocationType.ROOM]: t`Room`,
  [LocationType.WARD]: t`Unit`,
  [LocationType.WORKSPACE]: t`Facility`,
  [LocationType.BED_TYPE]: t`Bed type`,
});

const getViewByLocationOptions = (filter: ReportFilterParams) => {
  const viewByLocationTranslates = mapViewByLocationToTranslates();
  return getLocationUnits(filter).map((timeUnitType) => ({
    id: timeUnitType,
    name: viewByLocationTranslates[timeUnitType],
  }));
};

const getViewByTimeOptions = (filter: ReportFilterParams) => {
  const viewByTimeTranslates = mapViewByTimeToTranslates();
  return getTimeUnits(filter.period).map((timeUnitType) => ({
    id: timeUnitType,
    name: viewByTimeTranslates[timeUnitType],
  }));
};

export const getLocationUnits = (filter: ReportFilterParams) => {
  if (filter.resultField === ReportResultField.OCCUPANCY) {
    return [
      LocationType.BED,
      LocationType.ROOM,
      LocationType.WARD,
      LocationType.WORKSPACE,
      LocationType.BED_TYPE,
    ];
  }
  if (filter.resultField === ReportResultField.BED_EXIT) {
    return [
      LocationType.BED,
      LocationType.ROOM,
      LocationType.WARD,
      LocationType.WORKSPACE,
    ];
  }
  if (
    filter.resultField === ReportResultField.WEIGHT ||
    filter.resultField === ReportResultField.BACKREST
  ) {
    return [LocationType.BED];
  }
  if (filter.resultField === ReportResultField.DURATION) {
    return [
      LocationType.BED,
      LocationType.ROOM,
      LocationType.WARD,
      LocationType.WORKSPACE,
    ];
  }
  if (filter.resultField === ReportResultField.SESSION) {
    return [
      LocationType.BED,
      LocationType.ROOM,
      LocationType.WARD,
      LocationType.WORKSPACE,
    ];
  }
  return [
    LocationType.BED,
    LocationType.PARKING_PLACE,
    LocationType.ROOM,
    LocationType.WARD,
    LocationType.WORKSPACE,
    LocationType.BED_TYPE,
  ];
};

export const canSelectViewByBed = (locationFilter?: LocationFilter | null) => {
  const bedSelected = locationFilter?.unitIds?.length || 0;
  return bedSelected > 0 && bedSelected < 10;
};

const useViewByOptions = (filter: ReportFilterParams) => {
  const viewByTimeOptions = getViewByTimeOptions(filter);
  const viewByLocationOptions = getViewByLocationOptions(filter);
  return {
    viewByLocationOptions,
    viewByTimeOptions,
  };
};

export default useViewByOptions;
