import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { configQuery } from '../graphql/queries';
import { ConfigQuery, WeightUnits } from '../graphql/types';

interface IProps {
  weight: number | undefined | null;
  hideUnits?: boolean;
}

const getWeightFormat = (weight: number, unitSystem?: WeightUnits | null) => {
  if (unitSystem === 'IMPERIAL') {
    return Number(((weight / 10) * 2.2046).toFixed(1));
  }
  return Number((weight / 10).toFixed(1));
};

const WeightFormat: React.FC<IProps> = ({ weight, hideUnits }) => {
  const { i18n } = useLingui();
  const { data } = useQuery<ConfigQuery>(configQuery, {
    fetchPolicy: 'cache-first',
  });
  if (weight == null) {
    return <>{'--'}</>;
  }
  if (hideUnits) {
    return (
      <>{i18n.number(getWeightFormat(weight, data?.config?.unitSystem))}</>
    );
  }
  return (
    <>
      {i18n.number(getWeightFormat(weight, data?.config?.unitSystem))}
      {` `}
      {data?.config?.unitSystem === 'METRIC' ? (
        <Trans>kg</Trans>
      ) : (
        <Trans>lb</Trans>
      )}
    </>
  );
};

export const useWeightFormatter = () => {
  const { i18n } = useLingui();
  const { data } = useQuery<ConfigQuery>(configQuery, {
    fetchPolicy: 'cache-first',
  });

  const formatWeight = useCallback(
    (rawWeight: number | undefined | null, hideUnits?: boolean) => {
      if (rawWeight == null) {
        return <>{'--'}</>;
      }
      if (hideUnits) {
        return `${i18n.number(
          getWeightFormat(rawWeight, data?.config?.unitSystem),
        )}`;
      }

      return `${i18n.number(
        getWeightFormat(rawWeight, data?.config?.unitSystem),
      )} ${data?.config?.unitSystem === 'IMPERIAL' ? t`lb` : t`kg`}`;
    },
    [i18n, data?.config?.unitSystem],
  );

  return {
    formatWeight,
  };
};

export default WeightFormat;
