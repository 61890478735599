import { useState } from 'react';
import { Dialog, Box, Button, Typography, DialogActions } from '@mui/material';
import { t } from '@lingui/macro';
import { isMobile } from 'src/lib/mobileCheck';
import { parseDate } from 'src/helpers';
import DateTimeInput from 'src/components/DateTimeInput';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  sessionStart: string;
  onDischarge: (value: Date) => Promise<void>;
  stretchButtons?: boolean;
}

const DischargeDialog = ({
  open,
  setOpen,
  onDischarge,
  sessionStart,
  stretchButtons,
}: Props) => {
  const [date, setDate] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);

  const discharge = async () => {
    try {
      setError(null);
      await onDischarge(date || new Date());
    } catch (err) {
      const errorMessage = (err as any).graphQLErrors[0]?.message;
      if (errorMessage) {
        setError(errorMessage);
      } else {
        setError(t`Unknown error occured`);
      }
      return;
    }

    setOpen(false);
  };

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="discharge-dialog"
      open={open}
    >
      <Box p={2}>
        <Typography
          pt={1}
          pb={3}
          sx={isMobile ? undefined : { width: '350px' }}
        >
          {t`Do you really want to discharge the patient from bed and stop recording their data?`}
        </Typography>
        {error ? (
          <Typography sx={{ color: (theme) => theme.palette.error.main }}>
            {error}
          </Typography>
        ) : null}
        <DateTimeInput
          min={parseDate(sessionStart)}
          max={new Date()}
          label={t`Discharge date`}
          value={date || new Date()}
          variant="outlined"
          fullWidth
          size="small"
          onChange={setDate}
        />
        <DialogActions
          sx={{
            mt: 4,
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}
        >
          <Button
            variant="outlined"
            sx={stretchButtons ? { flex: 1 } : undefined}
            onClick={() => {
              setError(null);
              setOpen(false);
            }}
          >
            {t`Cancel`}
          </Button>
          <Button
            variant="contained"
            sx={stretchButtons ? { flex: 1 } : undefined}
            onClick={discharge}
          >
            {t`Confirm`}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DischargeDialog;
