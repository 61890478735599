import { useQuery } from '@apollo/client';
import { configQuery } from 'src/graphql/queries';
import { ReactComponent as SpDashboardLogo } from 'src/images/sp_dashboard_logo.svg';
import { ReactComponent as SpLogo } from 'src/images/sp_logo.svg';
import { ConfigQuery } from 'src/graphql/types';
import { Box, styled, Typography } from '@mui/material';
import { isSpDashboardEnabled, isSpDataEnabled } from 'src/lib/configHelper';

interface Props {
  type?: 'admin' | 'dashboard' | 'reporting';
}

const AppVersion = ({ type }: Props) => {
  const { data } = useQuery<ConfigQuery>(configQuery, {
    fetchPolicy: 'cache-first',
  });
  if (!data || !data.superConfig) {
    return null;
  }
  const spVersion = isSpDataEnabled(data) ? data.superConfig.spVersion : null;
  const spDashboardVersion = isSpDashboardEnabled(data)
    ? data.superConfig.spDashboardVersion
    : null;
  const spVersionVisible =
    spVersion && (type === 'admin' || type === 'reporting');
  const spDashboardVersionVisible =
    spDashboardVersion && (type === 'admin' || type === 'dashboard');
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {spVersionVisible ? (
        <>
          <SafetyPortLogo />
          <Typography fontSize="0.8125rem">{`v${'\u00A0'}${spVersion}`}</Typography>
        </>
      ) : null}
      {spDashboardVersionVisible ? (
        <>
          <SafetyPortDashboardLogo />
          <Typography fontSize="0.8125rem">{`v${'\u00A0'}${spDashboardVersion}`}</Typography>
        </>
      ) : null}
    </Box>
  );
};

export default AppVersion;

const SafetyPortDashboardLogo = styled(SpDashboardLogo)(
  ({ theme: { spacing } }) => `
  height: 1rem;
  margin-right: ${spacing(0.75)};
  margin-left: ${spacing(2)};
  position: relative;
  bottom: -1.5px;
`,
);

const SafetyPortLogo = styled(SpLogo)(
  ({ theme: { spacing } }) => `
  height: 0.875rem;
  margin-right: ${spacing(0.75)};
  margin-left: ${spacing(2)};
  position: relative;
  bottom: -1.5px;
`,
);
