import { ThemeOptions } from '@mui/material';
import { createTheme, lighten } from '@mui/material/styles';
import { InsetShadows, insetShadows } from './lib/insetShadows';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { linetColors } from './colors';

const isTheme = (x: ThemeOptions) => x;

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxl: true;
  }
  interface Theme {
    insetShadows: InsetShadows;
  }
  interface ThemeOptions {
    insetShadows?: InsetShadows;
  }
}

export const defaultTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: linetColors.blue,
      contrastText: linetColors.white,
    },
    error: {
      main: linetColors.red,
    },
    grey: {
      100: linetColors.bgGreyVeryLight,
      200: linetColors.bgGreyLight,
      300: linetColors.greyLighter,
      400: linetColors.greyMain,
      500: linetColors.greyDark,
      600: linetColors.greyMainDark,
    },
    success: {
      main: linetColors.green,
      light: linetColors.greenLight,
    },
    warning: {
      main: linetColors.orangeLight,
    },
    text: {
      primary: linetColors.greyDark,
      secondary: linetColors.greyMain,
      disabled: linetColors.greyMain,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1800,
    },
  },
  insetShadows: insetShadows,
  typography: {
    fontFamily: [
      'GTAmerica',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      fontStretch: 'expanded',
      textTransform: 'uppercase',
      marginBottom: '2rem',
    },
  },
});

export const adminTheme = createTheme(
  defaultTheme,
  isTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            marginTop: '0.25rem',
            borderRadius: '10px',
            '&.Mui-disabled': {
              backgroundColor: defaultTheme.palette.grey[200],
              color: defaultTheme.palette.text.disabled,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              top: 0,
              '& legend': {
                display: 'none',
              },
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            height: '48px',
            '&:nth-of-type(odd) > td': {
              backgroundColor: defaultTheme.palette.grey[200],
            },
            '& > td:first-of-type , & > th:first-of-type': {
              borderTopLeftRadius: defaultTheme.spacing(0.625),
              borderBottomLeftRadius: defaultTheme.spacing(0.625),
            },
            '& > td:last-of-type, & > th:last-of-type': {
              borderTopRightRadius: defaultTheme.spacing(0.625),
              borderBottomRightRadius: defaultTheme.spacing(0.625),
            },
            '&.MuiTableRow-hover': {
              '&:hover > td': {
                backgroundColor: lighten(
                  defaultTheme.palette.primary.light,
                  0.8,
                ),
              },
            },
            '&.Mui-selected, &.Mui-selected > td, &.Mui-selected:hover > td': {
              backgroundColor: defaultTheme.palette.primary.light,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 'none',
            padding: '2px 10px',
          },
          head: {
            textTransform: 'uppercase',
            fontWeight: defaultTheme.typography.fontWeightBold,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          filled: {
            backgroundColor: defaultTheme.palette.primary.main,
            color: defaultTheme.palette.primary.contrastText,
            borderRadius: '16px',
            marginRight: '0.5rem',
            fontWeight: 600,
            '&.Mui-selected, &:hover': {
              backgroundColor: defaultTheme.palette.primary.light,
            },
            '& .MuiChip-deleteIcon': {
              color: 'currentColor',
              ':hover': {
                color: 'currentColor',
              },
            },
            '&.Mui-selected': {
              boxShadow: defaultTheme.insetShadows[1],
            },
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root.MuiMenu-paper': {
              borderRadius: '10px',
              marginTop: '12px',
              marginBottom: '12px',
              border: `solid 2px ${linetColors.greyLight}`,
              '& .MuiMenu-list .MuiMenuItem-root[aria-selected="true"]': {
                backgroundColor: linetColors.bgGreyLight,
                color: linetColors.blue,
              },
              '& .MuiMenu-list .MuiMenuItem-root:hover': {
                backgroundColor: linetColors.bgGreyLight,
                color: linetColors.blue,
              },
            },
          },
        },
      },
      MuiAutocomplete: {
        defaultProps: {
          popupIcon: <KeyboardArrowDownIcon />,
        },
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              marginTop: 0,
            },
          },
          popper: {
            paddingTop: '12px',
            paddingBottom: '12px',
            '& .MuiPaper-root': {
              borderRadius: '10px',
              border: `solid 2px ${linetColors.greyLight}`,
              '& .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]':
                {
                  backgroundColor: linetColors.bgGreyLight,
                  color: linetColors.blue,
                },
              '& .Mui-focused': {
                backgroundColor: linetColors.bgGreyLight,
                color: linetColors.blue,
              },
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: KeyboardArrowDownIcon,
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: '1.5rem',
            lineHeight: 1.334,
            fontWeight: 'bold',
            fontStretch: 'expanded',
          },
          h2: {
            fontSize: '1.25rem',
            lineHeight: 1.334,
            fontWeight: 500,
            fontStretch: 'expanded',
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          InputLabelProps: {
            shrink: true,
          },
        },
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              fontSize: '0.875rem',
              color: defaultTheme.palette.text.primary,
              transform: 'none',
              position: 'relative',
              '&.Mui-focused': {
                color: defaultTheme.palette.primary.main,
              },
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
            paddingLeft: '18px',
            paddingRight: '18px',
            textTransform: 'capitalize',
            color: linetColors.greyMain,
            '&.Mui-selected': {
              backgroundColor: linetColors.white,
              borderColor: linetColors.blue,
              '&&&': {
                border: `solid 1px ${linetColors.blue}`,
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontStretch: 'expanded',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            letterSpacing: '0.03em',
            fontSize: '0.875rem',
            borderRadius: '40px',
            textTransform: 'none',
          },
          contained: {
            '&.Mui-disabled': {
              backgroundColor: '#D0D0D0',
              color: defaultTheme.palette.common.white,
            },
          },
          outlined: {
            borderWidth: '1.5px',
            '&:hover': {
              borderWidth: '1.5px',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialog-paper': {
              borderRadius: '10px',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textTransform: 'uppercase',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: defaultTheme.palette.text.primary,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '1.25rem',
          },
        },
      },
    },
  }),
);
