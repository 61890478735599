import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

export interface Option {
  label: string | number | React.ReactElement;
  value: string;
}

export interface RadioSelectProps extends RadioGroupProps {
  label?: string;
  options: Option[];
  disabled?: boolean;
}

const RadioSelect = ({
  id: tmpId,
  name,
  label,
  options,
  disabled,
  ...radioGroupProps
}: RadioSelectProps) => {
  const id = tmpId ? tmpId : name;
  return (
    <FormControl>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <RadioGroup id={id} name={name} {...radioGroupProps}>
        {options.map((o) => {
          return (
            <FormControlLabel
              key={`option-${o.value}`}
              disabled={disabled}
              value={o.value}
              control={<Radio />}
              label={o.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioSelect;
