import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';

interface Props {
  status: boolean;
  fontSize?: string;
}

export const BatteryStatus = ({ status, fontSize }: Props) => {
  return status ? (
    <BatteryAlertIcon
      sx={(theme) => ({ fontSize, color: theme.palette.error.main })}
    />
  ) : (
    <BatteryFullIcon
      sx={(theme) => ({ fontSize, color: theme.palette.success.light })}
    />
  );
};
