import { t, Trans } from '@lingui/macro';
import { Box, Button, Typography, useTheme, styled } from '@mui/material';
import axios from 'axios';
import { format, max, sub, startOfDay } from 'date-fns';
import { saveAs } from 'file-saver';
import { useEffect, useRef, useState } from 'react';
import { ContentType } from '../../BedDetailModal/BedDetailModal';
import PatientViewExport from '../PatientViewExport';
import { ExportType, SelectedExportType } from '../../helpers/export';
import ExportDataItem from './ExportDataItem';
import StatisticsExport from '../StatisticsExport';
import MobilizationExport from '../MobilizationExport';
import { pdf } from '@react-pdf/renderer';
import { I18nProvider, useLingui } from '@lingui/react';
import InfoBox from 'src/components/InfoBox';
import { isMobile } from 'src/lib/mobileCheck';
import PrerenderedViewGraph from '../PrerenderedViewGraph';
import PrerenderedStatisticsChart from '../PrerenderedStatisticsChart';
import PrerenderedMobilizationChart from '../PrerenderedMobilizationChart';
import { useMobilizationData } from '../mobilizationHook';
import { useStatisticsData } from '../statisticsHook';
import { usePatientViewData } from '../viewPatientHook';
import { getAccessToken } from 'src/auth/AuthProvider';

export interface ExportDataProps {
  sessionId?: string;
  sessionStart?: Date;
  sessionEnd?: Date;
  content?: ContentType;
  sessionName: string | null;
  unitId: string;
  open: boolean;
  close?: () => void;
}

interface Props extends ExportDataProps {}

const dateToExportFormat = (date: Date) => {
  return format(date, 'yyyy/MM/dd');
};

const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

const ExportData = ({
  sessionEnd,
  sessionStart,
  content,
  sessionName,
  unitId,
  open,
  close,
  sessionId,
}: Props) => {
  const [yesterday, setYesterday] = useState(getYesterday());
  const [today, setToday] = useState(new Date());
  const [loading, setLoading] = useState(true);

  const { i18n } = useLingui();
  const [selectedExportData, setSelectedExportData] =
    useState<SelectedExportType>({
      view: {
        show: false,
        date: sessionEnd ?? startOfDay(today),
      },
      careNotes: {
        show: false,
        to: sessionEnd ?? today,
        from: sessionStart ?? yesterday,
      },
      mobilization: {
        show: false,
        date: sessionEnd ?? today,
      },
      statistics: {
        show: false,
        to: sessionEnd ?? today,
        from: sessionStart ?? yesterday,
      },
      insight: {
        show: false,
        to: sessionEnd ?? today,
        from: max([
          sub(sessionEnd ? new Date(sessionEnd) : new Date(), { hours: 4 }),
          sessionStart ? new Date(sessionStart) : sub(new Date(), { hours: 4 }),
        ]),
      },
    });

  const { viewPatientData, viewPatientLoaded, getViewPatientGraphData } =
    usePatientViewData({ sessionId, date: selectedExportData.view.date });
  const { statisticsData, statisticsLoaded, getStatisticsGraphData } =
    useStatisticsData({
      sessionId,
      from: selectedExportData.statistics.from,
      to: selectedExportData.statistics.to,
    });
  const { mobilizationData, mobilizationLoaded, getMobilizationGraphData } =
    useMobilizationData({
      sessionId,
      date: selectedExportData.mobilization.date,
    });

  const theme = useTheme() as any;
  const viewPatientImageRefs = useRef<any>({});
  const statisticsImageRefs = useRef<any>({});
  const mobilizationImageRefs = useRef<any>({});

  useEffect(() => {
    const loaded =
      (!selectedExportData.view.show || viewPatientLoaded) &&
      (!selectedExportData.statistics.show || statisticsLoaded) &&
      (!selectedExportData.mobilization.show || mobilizationLoaded);
    setLoading(!loaded);
  }, [
    viewPatientLoaded,
    mobilizationLoaded,
    statisticsLoaded,
    selectedExportData,
  ]);

  useEffect(() => {
    if (open) {
      setToday(new Date());
      setYesterday(getYesterday());
    }
  }, [open]);

  useEffect(() => {
    setSelectedExportData({
      view: {
        show: content === 'patientView',
        date: sessionEnd ?? startOfDay(today),
      },
      careNotes: {
        show: content === 'notes',
        to: sessionEnd ?? today,
        from: sessionStart ?? yesterday,
      },
      mobilization: {
        show: content === 'mobilization',
        date: sessionEnd ?? today,
      },
      statistics: {
        show: content === 'statistics',
        to: sessionEnd ?? today,
        from: sessionStart ?? yesterday,
      },
      insight: {
        show: content === 'insight',
        to: sessionEnd ?? today,
        from: max([
          sub(sessionEnd || new Date(), { hours: 4 }),
          sessionStart || sub(new Date(), { hours: 4 }),
        ]),
      },
    });
  }, [content, sessionEnd, sessionStart, today, yesterday]);

  const exportHandler = async (
    sessionId: string | null,
    selectedExport: SelectedExportType,
  ) => {
    if (sessionId) {
      if (selectedExport.careNotes.show) {
        const accessToken = getAccessToken();
        axios
          .get<ArrayBuffer>(
            `/api/download/care_notes/${sessionId}/${sessionName}/${
              selectedExport.careNotes.from
            }/${
              selectedExport.careNotes.to
            }/${today}/${today.getTimezoneOffset()}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              },
              responseType: 'blob',
            },
          )
          .then((response) => {
            const fileBlob = new Blob([response.data]);
            const fileName = `${sessionId}_careNotes_from(${dateToExportFormat(
              selectedExport.careNotes.from,
            )})_to(${dateToExportFormat(selectedExport.careNotes.to)}).xlsx`;
            saveAs(fileBlob, fileName);
          });
      }
      if (selectedExport.insight.show) {
        const accessToken = getAccessToken();
        axios
          .get<ArrayBuffer>(
            `/api/download/insight/${unitId}/${selectedExport.insight.from}/${
              selectedExport.insight.to
            }/${today}/${today.getTimezoneOffset()}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type':
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              },
              responseType: 'blob',
            },
          )
          .then((response) => {
            const fileBlob = new Blob([response.data]);
            const fileName = `${sessionId}_insight_from(${dateToExportFormat(
              selectedExport.insight.from,
            )})_to(${dateToExportFormat(selectedExport.insight.to)}).xlsx`;
            saveAs(fileBlob, fileName);
          });
      }
      if (selectedExport.statistics.show && statisticsData) {
        const result = await getStatisticsGraphData(statisticsImageRefs);

        const file = (
          <I18nProvider i18n={i18n}>
            <StatisticsExport
              sessionName={sessionName}
              sessionId={sessionId}
              exportedTime={today}
              exportedFrom={selectedExport.statistics.from}
              exportedTo={selectedExport.statistics.to}
              charts={result}
            />
          </I18nProvider>
        );
        const fileBlob = await pdf(file).toBlob();
        const fileName = `${sessionId}_statistics_from(${dateToExportFormat(
          selectedExport.statistics.from,
        )})_to(${dateToExportFormat(selectedExport.statistics.to)}).pdf`;
        saveAs(fileBlob, fileName);
      }
      if (selectedExport.mobilization.show && mobilizationData) {
        const result = await getMobilizationGraphData(mobilizationImageRefs);
        const file = (
          <I18nProvider i18n={i18n}>
            <MobilizationExport
              sessionName={sessionName}
              sessionId={sessionId}
              exportedTime={today}
              exportedDay={selectedExport.mobilization.date}
              graphs={result}
            />
          </I18nProvider>
        );
        const fileBlob = await pdf(file).toBlob();
        const fileName = `${sessionId}_mobilization_date(${dateToExportFormat(
          selectedExport.mobilization.date,
        )}).pdf`;
        saveAs(fileBlob, fileName);
      }
      if (selectedExport.view.show && viewPatientData) {
        const result = await getViewPatientGraphData(viewPatientImageRefs);
        const file = (
          <I18nProvider i18n={i18n}>
            <PatientViewExport
              sessionName={sessionName}
              sessionId={sessionId}
              exportedTime={today}
              exportedDay={selectedExport.view.date}
              graphs={result}
            />
          </I18nProvider>
        );
        const fileBlob = await pdf(file).toBlob();
        const fileName = `${sessionId}_24HourView_date(${dateToExportFormat(
          selectedExport.view.date,
        )}).pdf`;
        saveAs(fileBlob, fileName);
      }
    }
  };
  const allowExport =
    selectedExportData.statistics.show ||
    selectedExportData.view.show ||
    selectedExportData.careNotes.show ||
    selectedExportData.mobilization.show ||
    selectedExportData.insight.show;

  return (
    <>
      {isMobile ? (
        <Box mb={2}>
          <Typography variant="caption">
            <Trans>Tabs to export</Trans>
          </Typography>
        </Box>
      ) : (
        <>
          <Typography>
            <Trans>What to export:</Trans>
          </Typography>
          <InfoBox
            color={theme.palette.text.primary}
            text={i18n._(t`Select tabs to export`)}
          />
        </>
      )}
      <Box>
        <ExportDataItem
          type={ExportType.view}
          setSelectedExportData={setSelectedExportData}
          selectedExportData={selectedExportData}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
        />
        <ExportDataItem
          type={ExportType.careNotes}
          setSelectedExportData={setSelectedExportData}
          selectedExportData={selectedExportData}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
        />
        <ExportDataItem
          type={ExportType.mobilization}
          textColor={theme.palette.text.primary}
          setSelectedExportData={setSelectedExportData}
          selectedExportData={selectedExportData}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
        />
        <ExportDataItem
          type={ExportType.statistics}
          setSelectedExportData={setSelectedExportData}
          selectedExportData={selectedExportData}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
        />
        <ExportDataItem
          type={ExportType.insight}
          setSelectedExportData={setSelectedExportData}
          selectedExportData={selectedExportData}
          sessionStart={sessionStart}
          sessionEnd={sessionEnd}
        />
      </Box>
      <Box mt={3} display="flex" width="100%" flexDirection="row-reverse">
        <Button
          disabled={!allowExport || loading}
          onClick={() => exportHandler(sessionId ?? null, selectedExportData)}
          variant="contained"
          fullWidth={isMobile ? true : false}
        >
          <Trans>Export</Trans>
        </Button>

        {!isMobile && (
          <Button
            onClick={() => close && close()}
            sx={{ marginRight: 1 }}
            color="primary"
            variant="outlined"
          >
            <Trans>Cancel</Trans>
          </Button>
        )}
      </Box>
      <PrerenderContainer>
        {selectedExportData.view.show &&
          viewPatientData.map((item) => (
            <PrerenderedViewGraph
              ref={(ref) => (viewPatientImageRefs.current[item.type] = ref)}
              key={`view-item-${item.type}`}
              item={item}
            />
          ))}
        {selectedExportData.statistics.show &&
          statisticsData.map((item) => (
            <PrerenderedStatisticsChart
              ref={(ref) => (statisticsImageRefs.current[item.type] = ref)}
              key={`statistics-item-${item.type}`}
              item={item}
            />
          ))}
        {selectedExportData.mobilization.show &&
          mobilizationData.map((item) => (
            <PrerenderedMobilizationChart
              ref={(ref) => (mobilizationImageRefs.current[item.day] = ref)}
              key={`mobilization-item-${item.day}`}
              item={item}
            />
          ))}
      </PrerenderContainer>
    </>
  );
};

export default ExportData;

const PrerenderContainer = styled(Box)`
  height: 1px;
  overflow: hidden;
`;
