import { Box, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Flex from 'src/components/Flex';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

interface Props {
  title: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  name?: string;
  onSave?: (name: string) => void;
  onNameChange?: (name: string) => void;
  onNameChanged?: (name: string) => void;
}

const ReportingPageWrapper = ({
  title,
  actions,
  children,
  onNameChange,
  onNameChanged,
}: Props) => {
  const [input, setInput] = useState({
    editable: false,
    value: title,
  });

  useEffect(() => {
    setInput((prevState) => ({
      ...prevState,
      value: title,
    }));
  }, [title]);

  const handleSave = () => {
    setInput((prevState) => ({
      ...prevState,
      editable: false,
    }));
    onNameChanged?.(input.value);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onNameChange?.(e.target.value);
    setInput((prevState) => ({
      ...prevState,
      value: e.target.value,
    }));
  };

  return (
    <Box pt={2}>
      <Flex justifyContent="space-between" mb={2}>
        <TitleContainer>
          {input.editable ? (
            <TextField
              id="standard-basic"
              label="Standard"
              autoFocus
              variant="standard"
              value={input.value}
              onChange={handleChange}
              onBlur={handleSave}
            />
          ) : (
            <Typography variant="h2">{input.value}</Typography>
          )}
          {(onNameChange || onNameChanged) && (
            <IconButton
              onClick={() =>
                setInput((prevState) => ({ ...prevState, editable: true }))
              }
              sx={{ marginLeft: 1 }}
            >
              <EditIcon
                sx={(theme) => ({ color: theme.palette.primary.main })}
              />
            </IconButton>
          )}
        </TitleContainer>
        <Flex>{actions}</Flex>
      </Flex>
      <Box>{children}</Box>
    </Box>
  );
};

export default ReportingPageWrapper;

const TitleContainer = styled(Flex)`
  align-items: center;
  height: 32px;
  svg {
    display: none;
  }

  :hover {
    svg {
      display: initial;
    }
  }
`;
